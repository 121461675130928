import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { User } from 'src/app/auth/models/user.model';
import { AppState } from 'src/app/auth/reducer';
import { getUser } from 'src/app/auth/store/auth.selectors';
import { Select } from 'src/app/models/select';
import * as actions from '../../../auth/store/auth.action';

@Component({
  selector: 'modal-edit-profile',
  templateUrl: './modal-edit-profile.component.html',
  styleUrls: ['./modal-edit-profile.component.scss'],
})
export class ModalEditProfileComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  editForm?: UntypedFormGroup;

  user$?: Observable<User | null>;
  user?: User;
  countries?: Select[];

  constructor(
    private api: ApiService,
    private store: Store<AppState>,
    private translate: TranslateService,
    public modalRef: MdbModalRef<ModalEditProfileComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.editForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
      surname: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl({ value: null, disabled: true }, [
        Validators.email,
        Validators.required,
      ]),
      phone: new UntypedFormControl(null),
      country: new UntypedFormControl(null, [Validators.required]),
      gender: new UntypedFormControl(null, [Validators.required]),
      yearBirth: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]{4}'),
      ]),
    });

    this.store.select(getUser).subscribe((user) => {
      if (user) this.user = user;
    });

    this.getCountries();

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.getCountries();
    });

    this.populateForm();
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  getCountries() {
    this.api.getCountries().subscribe((countries) => {
      this.countries = countries as Select[];
    });
  }

  getUser() {
    this.store.dispatch(new actions.GetUser());
    this.user$ = this.store.select(getUser);
    this.user$.subscribe((user) => (this.user = user as User));
  }

  populateForm() {
    this.name?.setValue(this.user?.name);
    this.surname?.setValue(this.user?.surname);
    this.email?.setValue(this.user?.email);
    this.country?.setValue(this.user?.countryID);
    this.yearBirth?.setValue(this.user?.birthYear);
    this.gender?.setValue(this.user?.gender);
    if (this.user?.phone) this.phone?.setValue(this.user?.phone);
  }

  updateLocalUser() {
    if (this.user) {
      this.user.name = this.editForm?.get('name')?.value;
      this.user.surname = this.editForm?.get('surname')?.value;
      this.user.phone = this.editForm?.get('phone')?.value;
      this.user.countryID = this.editForm?.get('country')?.value;
      this.user.birthYear = this.editForm?.get('yearBirth')?.value;
      this.user.gender = this.editForm?.get('gender')?.value;
    }
  }

  updateProfile() {
    if (this.editForm?.invalid) {
      this.editForm.markAllAsTouched();
    } else {
      this.api.updateInfo(this.editForm?.value).subscribe(
        () => {
          this.getUser();
        },
        (error) => {}
      );
    }
  }

  get name() {
    return this.editForm?.get('name');
  }
  get surname() {
    return this.editForm?.get('surname');
  }
  get email() {
    return this.editForm?.get('email');
  }
  get country() {
    return this.editForm?.get('country');
  }
  get gender() {
    return this.editForm?.get('gender');
  }
  get yearBirth() {
    return this.editForm?.get('yearBirth');
  }
  get phone() {
    return this.editForm?.get('phone');
  }
}
