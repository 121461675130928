<div class="modal-body w-100 bg-danger m-0 p-0" style="height: 100vh">
  <div
    class="bg-white w-100 h-100 d-flex flex-column justify-content-between py-0 my-0"
  >
    <div class="mx-3 d-flex flex-column bg-white text-primary h-100">
      <div class="d-flex flex-column pt-4 bg-white sticky-top">
        <div class="d-flex align-items-center">
          <i class="bi bi-geo-fill h2 my-auto"></i>
          <h2 class="mb-1 mx-2 my-auto">
            {{ "CHANGE_COUNTRY.CHANGE_COUNTRY" | translate }}
          </h2>
        </div>
        <change-country-card
          class="w-100"
          [role]="user?.currentRole"
          [isSelected]="true"
        ></change-country-card>
        <div class="bg-primary mb-0" style="height: 2px"></div>
      </div>
      <div
        class="d-flex flex-column align-items-center w-100 h-100"
        style="overflow-y: auto"
      >
        <div class="w-100">
          <change-country-card
            class="w-100"
            *ngFor="let role of roles"
            [role]="role"
            (onRoleClick)="roleChanged($event)"
          ></change-country-card>
        </div>
        <add-country-card
          class="w-100 mt-2 mb-4"
          [userID]="user?.userID"
          *ngIf="user?.currentRole?.role === 'USER'"
        ></add-country-card>
      </div>
      <div class="mt-2 pt-2 pb-4 bg-white w-100 d-flex justify-content-center">
        <u class="hover">
          <p (click)="modalRef.close()" class="h5 mx-auto">
            {{ "CHANGE_COUNTRY.CLOSE" | translate }}
          </p>
        </u>
      </div>
    </div>
  </div>
</div>
