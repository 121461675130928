<div class="modal-header">{{ "OT_CLEANING.EDIT_INFO" | translate }}</div>
<div class="modal-body" [formGroup]="editForm!">
  <span>{{ "OT_CLEANING.QUANTITY" | translate }}</span>
  <input class="form-control mb-2" type="text" formControlName="quantityForm" />
  <span>{{ "OT_CLEANING.MEASURE" | translate }}</span>
  <div>
    <ng-select
      class="w-100"
      formControlName="measureForm"
      [items]="measureList ?? []"
      bindLabel="label"
      bindValue="value"
      [searchable]="false"
      [clearable]="false"
    ></ng-select>
  </div>
  <div class="py-3 bg-error mt-4 rounded text-center" *ngIf="error">
    <h6 class="my-auto">{{ "OT_CLEANING.ERROR" | translate }} [{{ error }}]</h6>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.BACK" | translate }}
  </div>
  <div class="btn btn-success" (click)="edit()">
    {{ "BUTTONS.SAVE" | translate }}
  </div>
</div>
