import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Descriptor } from 'src/app/models/descriptor';
import { Select } from 'src/app/models/select';
import {
  AnswerInfo,
  VarietyInfo,
} from 'src/app/projects/grid-system-page/grid-system-page.component';
import { WorkingArea } from 'src/app/working-area/answering-page/answering-page.component';

@Component({
  selector: 'modal-cleaning-descriptors',
  templateUrl: './modal-cleaning-descriptors.component.html',
  styleUrls: ['./modal-cleaning-descriptors.component.scss'],
})
export class ModalCleaningDescriptorsComponent implements OnInit {
  modifyForm?: UntypedFormGroup;
  filterForm?: UntypedFormGroup;

  projectID?: string;
  familyID?: string;
  varietiesSelectedIDs?: string[];

  workingArea?: WorkingArea;

  types?: any[];

  columnHeaders: any[] = [];
  isHeaderEmpty?: boolean;

  rows: any[] = [];

  loading?: boolean;

  varietyInfo?: VarietyInfo;
  answerInfo?: any[];

  selectedRow: number = -1;
  selectedRows: number[] = [];
  selectedColumn: number = -1;
  selectedColumns: number[] = [];
  selectedAnswer: any;

  isGeneralInfosOpened: boolean = false;
  isLegendOpened: boolean = false;
  isDetailOpened: boolean = false;

  descriptor?: Descriptor;
  selectedType?: string;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalCleaningDescriptorsComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      type: new UntypedFormControl(null, []),
    });

    this.api
      .getDescriptorCategoriesByProject(this.projectID ?? '')
      .subscribe((value) => {
        this.types = value as Select[];
      });

    this.type?.valueChanges.subscribe((type) => {
      if (type) {
        this.selectedType = type as string;
        this.getTableStructure();
      }
    });
  }

  ngOnDestroy(): void {}

  getTableStructure() {
    if (this.selectedType) {
      this.cleanTable();
      this.isDetailOpened = false;
      this.loading = true;
      this.api
        .getDescriptorsByProject(this.selectedType, this.familyID ?? '')
        .subscribe((value) => {
          this.columnHeaders = value as any;
          this.isHeaderEmpty = this.columnHeaders.length == 0;
          if (!this.isHeaderEmpty) {
            this.api
              .getAnswerDescriptorByProject(
                this.projectID ?? '',
                this.selectedType ?? '',
                this.varietiesSelectedIDs ?? [],
                this.familyID ?? ''
              )
              .subscribe((value) => {
                this.rows = value as any;
                this.loading = false;
              });
          } else {
            this.loading = false;
          }
        });
    }
  }

  getColumnHeaderLivestock(): any[] {
    let newColumns: any[] = [];
    this.columnHeaders.forEach((item, i) => {
      if (i > 0) {
        if (item.refID == newColumns[newColumns.length - 1].refID) {
          newColumns[newColumns.length - 1].span += 1;
        } else {
          item.span = 1;
          newColumns.push(item);
        }
      } else {
        item.span = 1;
        newColumns.push(item);
      }
    });
    return newColumns;
  }

  // save() {
  //   const body = this.modifyForm?.value;
  //   body.descriptorID = this.columnHeaders[this.selectedColumns[0]].refID;
  //   body.varietyID = this.rows[this.selectedRow].refID;
  //   body.refID = this.workingArea?.workID;
  //   body.answerID = this.answerInfo?.answerID;
  //   body.age = this.answerInfo?.age;
  //   body.gender = this.answerInfo?.gender;

  //   this.api.updateAnswerByWorkingArea(body).subscribe((value) => {
  //     const answer = value as Answer;
  //     this.rows[this.selectedRow].answers[this.selectedColumns[0]] = answer;
  //     this.selectedAnswer = answer;
  //   });
  // }

  selectColumn(columnIndex: number) {
    this.selectedRow = -1;
    this.selectedColumn = columnIndex;
    this.selectedColumns = [];
    let max = this.getColumnHeaderLivestock()[columnIndex].span;
    let min = 0;
    if (columnIndex > 0) {
      this.getColumnHeaderLivestock().forEach((item, i) => {
        if (i < columnIndex) min += item.span;
      });
    }
    for (let i = 0; i < max; i++) {
      this.selectedColumns.push(min + i);
    }

    this.cleanTableUtilities();
  }

  getDescriptorByGA(columnIndex: number, descritorID: string) {
    this.selectedRow = -1;
    let selectedCol = -1;
    this.getColumnHeaderLivestock().forEach((item, i) => {
      if (item.refID === descritorID) selectedCol = i;
    });
    this.selectedColumn = selectedCol;
    this.selectedColumns = [];
    this.selectedColumns.push(columnIndex);
    this.selectedRows = [];
    this.selectedRows.push(columnIndex);
    this.cleanTableUtilities();
    //
    this.api.getDescriptor(descritorID).subscribe((value) => {
      this.descriptor = value as Descriptor;
      this.cleanTable();
      this.isDetailOpened = false;
      this.isLegendOpened = true;
    });
  }

  getGeneralInfos(projectID: string, refID: string, rowIndex: number) {
    this.selectedColumn = -1;
    this.selectedAnswer = null;
    this.selectedRow = rowIndex;
    this.selectedColumns = [];
    this.cleanTableUtilities();
    this.api
      .getVarietyDetailByProject(
        this.projectID ?? '',
        this.rows[rowIndex].refID
      )
      .subscribe((value) => {
        this.answerInfo = value as any[];
        this.isDetailOpened = true;
      });
  }

  cleanTableUtilities() {
    this.isGeneralInfosOpened = false;
    this.isLegendOpened = false;
    this.isDetailOpened = false;
  }

  cleanTable() {
    this.columnHeaders = [];
    this.rows = [];
    this.selectedRow = -1;
    this.selectedRows = [];
    this.selectedColumn = -1;
    this.selectedColumns = [];
  }

  getDetail(answer: any, rowIndex: number, answerIndex: number) {
    this.selectedRow = rowIndex;
    this.selectedAnswer = answer;
    this.selectedColumns = [];
    this.selectedColumns.push(answerIndex);
    let selectedCol = -1;

    this.getColumnHeaderLivestock().forEach((item, i) => {
      if (item.refID === answer.descriptorID) selectedCol = i;
    });
    this.selectedColumn = selectedCol;
    // const varietyID = this.rows[rowIndex].refID;
    this.cleanTableUtilities();
    this.api
      .getAnswerDetailByProject(
        this.projectID ?? '',
        answer.refID,
        answer.descriptorID
      )
      .subscribe((value) => {
        this.answerInfo = value as any[];
        this.isDetailOpened = true;
      });
  }

  getBackgroundCell(rowIndex: number, answerIndex: number): string {
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'cell-selected text-white border-primary';
    }
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.indexOf(answerIndex) === -1
    ) {
      return 'full-row';
    }
    if (
      this.selectedRow != rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'full-row';
    }
    return 'bg-white';
  }

  goBack() {
    window.history.back();
  }

  switchGenderColor(gender: string): string {
    switch (gender) {
      case 'F':
        return 'bg-female';
      case 'M':
        return 'bg-male';
      default:
        return 'bg-white';
    }
  }

  getToolTip(item: any): string {
    const gender = item.gender === 'F' ? 'FEMALE' : 'MALE';
    const age = item.age === 'A' ? 'ADULT' : 'JUVENILE';
    return 'WORKING_AREA.' + age + '_' + gender;
  }

  get type() {
    return this.filterForm?.get('type');
  }
}
