import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Goal } from 'src/app/models/goal';
import { SiteGoal, SiteGoalImp } from 'src/app/models/project';
import { Species } from 'src/app/models/species';
import { Subgoal } from 'src/app/models/subgoal';

@Component({
  selector: 'modal-add-goal',
  templateUrl: './modal-add-goal.component.html',
  styleUrls: ['./modal-add-goal.component.scss'],
})
export class ModalAddGoalComponent implements OnInit {
  ref?: string;
  refIDs?: string[];

  species?: Species[];
  goalForm?: UntypedFormGroup;

  formState: number = -1;

  goals?: Goal[];
  subGoals?: Subgoal[];
  families?: string[] = [];
  familyLabels?: string[] = [];

  selectedGoal?: string;
  selectedSubGoal?: string;
  selectedFamilies?: string[];

  siteGoal?: SiteGoal;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalAddGoalComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.goalForm = new UntypedFormGroup({
      goalID: new UntypedFormControl(null),
      goalLabel: new UntypedFormControl(null),
      subgoalID: new UntypedFormControl(null),
      subgoalLabel: new UntypedFormControl(null),
    });

    this.api.getGoals(this.ref!).subscribe((value) => {
      this.goals = value as Goal[];
      this.formState = 0;
    });

    this.goal?.valueChanges.subscribe((value) => {
      const goal = this.goals?.filter((g) => g.goalID == value)[0];
      this.goalLabel?.setValue(goal?.titolo);
      this.subGoals = goal?.subgoals;
      this.formState = 1;
    });

    this.subGoal?.valueChanges.subscribe((value) => {
      const goal = this.subGoals?.filter((g) => g.subgoalID == value)[0];
      this.selectedSubGoal = value;
      this.subGoalLabel?.setValue(goal?.sottogoal);
      this.formState = 2;
    });

  }

  addSpecie(specie: Species) {
    if (this.families?.includes(specie.speciesID)) {
      const index = this.families.indexOf(specie.speciesID);
      this.families.splice(index, 1);
      this.familyLabels!.splice(index, 1);
      return;
    }
    this.families?.push(specie.speciesID);
    this.familyLabels?.push(specie.species);
  }

  onSave() {
    const body = {
      ...this.goalForm?.value,
      familyIDs: this.families,
      familyLabels: this.familyLabels,
    };
    this.modalRef.close(body);
  }

  get goal() {
    return this.goalForm?.get('goalID');
  }
  get goalLabel() {
    return this.goalForm?.get('goalLabel');
  }
  get subGoal() {
    return this.goalForm?.get('subgoalID');
  }
  get subGoalLabel() {
    return this.goalForm?.get('subgoalLabel');
  }
  get ids() {
    return this.goalForm?.get('ids');
  }
  get idsLabel() {
    return this.goalForm?.get('idsLabel');
  }
}
