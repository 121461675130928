import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'file-analysis-aquaculture',
  templateUrl: './file-analysis-aquaculture.component.html',
  styleUrls: ['./file-analysis-aquaculture.component.scss']
})
export class FileAnalysisAquacultureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
