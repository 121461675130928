<div class="modal-body">
  <div class="d-flex flex-row">
    <i class="bi bi-key-fill h3"></i>
    <h4 class="mx-1 h3 mb-0">{{ "SETTINGS.CHANGE_PASSWORD" | translate }}</h4>
  </div>
  <div class="bg-primary w-100" style="height: 2px"></div>

  <form class="py-3" [formGroup]="passwordForm!">
    <div class="w-50 my-4">
      <div class="d-flex flex-column">
        <div class="d-flex">
          <i class="bi bi-key-fill h5"></i>
          <h5 class="pb-1 m-0 mx-2">
            {{ "USER.CURRENT_PASSWORD" | translate }}
          </h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="currentPassword?.invalid && currentPassword?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div>
          <input
            class="form-control"
            type="password"
            formControlName="currentPassword"
          />
        </div>
      </div>
    </div>

    <div class="row my-4">
      <div class="col d-flex flex-column">
        <div class="d-flex">
          <i class="bi bi-key-fill h5"></i>
          <h5 class="pb-1 m-0 mx-2">
            {{ "USER.NEW_PASSWORD" | translate }}
          </h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="password?.invalid && password?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div>
          <input
            class="form-control"
            type="password"
            formControlName="password"
          />
        </div>
      </div>
      <div class="col d-flex flex-column">
        <div class="d-flex">
          <i class="bi bi-key-fill h5"></i>
          <h5 class="pb-1 m-0 mx-2">
            {{ "USER.CONFIRM_NEW_PASSWORD" | translate }}
          </h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="confirmPassword?.invalid && confirmPassword?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div>
          <input
            class="form-control"
            type="password"
            formControlName="confirmPassword"
          />
        </div>
      </div>
    </div>
  </form>

  <div class="modal-footer">
    <p class="mx-auto text-danger" *ngIf="error !== ''">{{ error }}</p>
    <button type="button" class="btn btn-primary" (click)="modalRef.close()">
      {{ "BUTTONS.CLOSE" | translate }}
    </button>
    <button type="button" class="btn btn-success" (click)="updatePassword()">
      {{ "BUTTONS.SAVE_CHANGES" | translate }}
    </button>
  </div>
</div>
