import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Variety } from 'src/app/models/variety';

@Component({
  selector: 'modal-import-variety',
  templateUrl: './modal-import-variety.component.html',
  styleUrls: ['./modal-import-variety.component.scss'],
})
export class ModalImportVarietyComponent implements OnInit {
  refID?: string;
  partnerRef?: string;
  otherVarieties?: any[];
  loading: boolean = false;
  title: string = '';

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalImportVarietyComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.switchTitle();
    this.getItems();
  }

  getItems() {
    if (this.refID) {
      switch (this.partnerRef) {
        case 'c':
          this.loading = true;
          this.api.getVarietiesOtherCountries(this.refID).subscribe(
            (value) => {
              this.otherVarieties = value as any[];
              this.loading = false;
            },
            (eror) => {
              this.loading = false;
            }
          );
          break;
        case 'l':
          this.loading = true;
          this.api.getBreedsOtherCountries(this.refID).subscribe(
            (value) => {
              this.otherVarieties = value as any[];
              this.loading = false;
            },
            (eror) => {
              this.loading = false;
            }
          );
          break;
        default:
          break;
      }
    }
  }

  viewItems(variety: any) {
    switch (this.partnerRef) {
      case 'CROPS':
        const cropUrl =
          'https://webportal.datar-par.org/u/database/v/' +
          this.refID +
          '-' +
          variety.varietyID;
        window.open(cropUrl, '_blank');
        break;
      case 'LIVESTOCK':
        const livestockUrl =
          'https://webportal.datar-par.org/u/database/b/' +
          this.refID +
          '-' +
          variety.breedID;
        window.open(livestockUrl, '_blank');
        break;
      default:
        break;
    }
  }

  importItems(variety: any) {
    this.loading = true;
    switch (this.partnerRef) {
      case 'c':
        this.api.importVarietyToMyCountry(variety.varietyID).subscribe(
          () => {
            this.loading = false;
            this.modalRef.close(true);
          },
          (error) => {
            this.loading = false;
          }
        );
        break;
      case 'l':
        this.api.importBreedToMyCountry(variety.breedID).subscribe(
          () => {
            this.loading = false;
            this.modalRef.close(true);
          },
          (error) => {
            this.loading = false;
          }
        );
        break;
      default:
        break;
    }
  }

  switchTitle() {
    switch (this.partnerRef) {
      case 'c':
        this.title = 'WORKING_AREA.IMPORT_VARIETY';
        break;
      case 'l':
        this.title = 'WORKING_AREA.IMPORT_BREED';
        break;
      default:
        break;
    }
  }
}
