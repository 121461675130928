import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'navigation-card',
  templateUrl: './navigation-card.component.html',
  styleUrls: ['./navigation-card.component.scss'],
})
export class NavigationCardComponent implements OnInit {
  @Input() title?: string;
  @Input() image: string = '';

  constructor() {}

  ngOnInit(): void {}
}
