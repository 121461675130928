<div class="modal-body">
  <div class="d-flex flex-row">
    <i class="bi bi-person-fill h3"></i>
    <h4 class="mx-1 h3 mb-0">{{ "SETTINGS.EDIT_PROFILE" | translate }}</h4>
  </div>
  <div class="bg-primary w-100" style="height: 2px"></div>

  <form class="py-3" [formGroup]="editForm!">
    <div class="row my-4">
      <div class="col d-flex flex-column">
        <div class="d-flex">
          <i class="bi bi-person-fill h5"></i>
          <h5 class="pb-1 m-0 mx-2">{{ "USER.NAME" | translate }}</h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="name?.invalid && name?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div>
          <input class="form-control" formControlName="name" />
        </div>
      </div>
      <div class="col d-flex flex-column">
        <div class="d-flex">
          <i class="bi bi-person-fill h5"></i>
          <h5 class="pb-1 m-0 mx-2">{{ "USER.SURNAME" | translate }}</h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="surname?.invalid && surname?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div>
          <input class="form-control" formControlName="surname" />
        </div>
      </div>
    </div>

    <div class="row my-4">
      <div class="col d-flex flex-column">
        <div class="d-flex">
          <i class="bi bi-envelope-fill h5"></i>
          <h5 class="pb-1 m-0 mx-2">{{ "USER.EMAIL" | translate }}</h5>
        </div>
        <div>
          <input class="form-control" formControlName="email" />
        </div>
      </div>
      <div class="col d-flex flex-column">
        <div class="d-flex">
          <i class="bi bi-calendar-fill h5"></i>
          <h5 class="pb-1 m-0 mx-2">{{ "USER.BIRTH_YEAR" | translate }}</h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="yearBirth?.invalid && yearBirth?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div>
          <input class="form-control" formControlName="yearBirth" />
        </div>
      </div>
    </div>

    <div class="row my-4">
      <div class="col d-flex flex-column">
        <div class="d-flex">
          <i class="bi bi-geo-fill h5"></i>
          <h5 class="pb-1 m-0 mx-2">{{ "USER.COUNTRY" | translate }}</h5>
        </div>
        <div>
          <ng-select
            class="control w-100"
            formControlName="country"
            [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
            [clearable]="false"
          >
            <ng-option
              *ngFor="let country of countries"
              [value]="country.value"
            >
              {{ country.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="col d-flex flex-column">
        <div class="d-flex">
          <i class="bi bi-telephone-fill h5"></i>
          <h5 class="pb-1 m-0 mx-2">{{ "USER.PHONE" | translate }}</h5>
        </div>
        <div>
          <input class="form-control" formControlName="phone" />
        </div>
      </div>
    </div>

    <div class="d-flex flex-row w-100 pt-2 align-items-center">
      <h5 class="text-primary my-auto">{{ "USER.GENDER" | translate }}:</h5>
      <div class="d-flex w-50 my-auto">
        <div class="form-check mx-2">
          <input
            class="form-check-input"
            type="radio"
            id="male"
            value="M"
            formControlName="gender"
          />
          <label class="form-check-label" for="Male">
            {{ "REGISTER.MALE" | translate }}
          </label>
        </div>
        <div class="form-check mx-2">
          <input
            class="form-check-input"
            type="radio"
            id="female"
            value="F"
            formControlName="gender"
          />
          <label class="form-check-label" for="female">
            {{ "REGISTER.FEMALE" | translate }}
          </label>
        </div>
        <div class="form-check mx-2">
          <input
            class="form-check-input"
            type="radio"
            id="fluid"
            value="O"
            formControlName="gender"
          />
          <label class="form-check-label" for="fluid">
            {{ "REGISTER.DIVERSE" | translate }}
          </label>
        </div>
      </div>
    </div>
  </form>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modalRef.close()">
      {{ "BUTTONS.CLOSE" | translate }}
    </button>
    <button type="button" class="btn btn-success" (click)="updateProfile()">
      {{ "BUTTONS.SAVE_CHANGES" | translate }}
    </button>
  </div>
</div>
