<div class="card col" style="height: 40rem; overflow-y: auto">
  <div class="mb-2 pb-0 sticky-top bg-white">
    <h3 class="mx-2 pt-3">{{ "NAVIGATION.DESCRIPTORS" | translate }}</h3>
    <div class="row mx-2">
      <p class="col-4">{{ "NAVIGATION.TYPE" | translate }}</p>
      <p class="col-4">{{ "NAVIGATION.SUB_TYPE" | translate }}</p>
      <p class="col-4">{{ "NAVIGATION.DESCRIPTORS" | translate }}</p>
    </div>
    <div class="mx-2 w-90 bg-primary" style="height: 2px"></div>
  </div>

  <div class="d-flex flex-column mx-2 pb-1 pt-2">
    <div
      class="row align-items-center mx-2 rounded py-3 my-1 hover"
      style="cursor: pointer"
      *ngFor="let descriptor of descriptors; index as i"
      [ngClass]="i % 2 == 0 ? 'bg-light' : 'bg-white'"
      (click)="openDescriptor(descriptor.traitID)"
    >
      <div class="col-4">
        <h5 class="mx-auto">
          {{ descriptor.traitType }}
        </h5>
      </div>
      <div class="col-4">
        <h5 class="mx-auto">
          {{ descriptor.traitSubtype }}
        </h5>
      </div>
      <div class="col-4">
        <h5 class="mx-auto">
          {{ descriptor.trait }}
        </h5>
      </div>
    </div>
  </div>
  <loading class="mask" *ngIf="loading"></loading>
</div>
