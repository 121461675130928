import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { OtherDb } from 'src/app/models/other-db';
import { Pageable } from 'src/app/models/pageable';

@Component({
  selector: 'country-social-ethnic',
  templateUrl: './country-social-ethnic.component.html',
  styleUrls: ['./country-social-ethnic.component.scss'],
})
export class CountrySocialEthnicComponent implements OnInit, OnDestroy {
  langSub?: Subscription;
  filterForm?: UntypedFormGroup;

  type: string = 'SOCIAL_ETHNIC_GROUP';
  countryID?: string;
  inputFilter: string = '';
  page: number = 0;
  sort: string = 'localName';
  direction: string = 'ASC';

  loading: boolean = false;

  dataSource?: Pageable;
  items: OtherDb[] = [];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null),
    });
    this.getCountry();
    this.filterItems();

    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.getCountry();
      this.filterItems();
    });

    this.filter?.valueChanges.pipe(debounceTime(500)).subscribe((filter) => {
      this.inputFilter = filter;
      this.filterItems();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  get filter() {
    return this.filterForm?.get('filter');
  }

  getCountry() {
    this.countryID = this.route.parent?.snapshot.paramMap.get('id') || '';
  }

  filterItems() {
    this.loading = true;
    if (this.countryID) {
      this.api
        .getOtherDb(
          this.type,
          this.countryID,
          this.page,
          this.inputFilter,
          this.sort,
          this.direction
        )
        .subscribe(
          (dataSource) => {
            this.dataSource = dataSource as Pageable;
            this.items = this.dataSource.content;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
    }
  }

  onPageClick(page: number) {
    this.loading = true;
    this.page = page;
    this.filterItems();
  }
}
