import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbTableDirective } from 'mdb-angular-ui-kit/table';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { Pageable } from 'src/app/models/pageable';
import { Site } from 'src/app/models/project';

@Component({
  selector: 'project-ot',
  templateUrl: './project-ot.component.html',
  styleUrls: ['./project-ot.component.scss'],
})
export class ProjectOtComponent implements OnInit {
  @ViewChild('tableSearch') searchTable?: MdbTableDirective<any>;

  filterForm?: FormGroup;

  projectID?: string;
  entriesOption: number[] = [];

  loading: boolean = true;

  ref?: string;

  activities?: any[];
  sites?: Site[];

  headers = ['Name', 'Surname', 'E-Mail', 'Role', 'Status'];

  dataSource?: Pageable;
  page: number = 0;

  loaded = false;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectID = this.route.parent?.snapshot.paramMap.get('id') as string;
    this.ref = this.route.parent?.snapshot.paramMap.get('ref') as string;

    this.filterForm = new FormGroup({
      activityType: new FormControl('FGD'),
      siteID: new FormControl(null),
      filter: new FormControl(null),
    });

    this.getSites();

    this.loadActivities();

    this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      this.loadActivities();
    });
  }

  getSites() {
    this.api.getSites(this.projectID ?? '').subscribe((value) => {
      this.sites = value as Site[];
    });
  }

  loadActivities() {
    const body = {
      refID: this.projectID,
      page: this.page,
      ...this.filterForm?.value,
    };

    this.api.getAllActivities(body).subscribe((value) => {
      this.dataSource = value as Pageable;
      this.loaded = true;
      this.activities = this.dataSource.content as any[];
      if (this.dataSource?.totalPages < 2 && this.page > 0) this.onPageClick(0);
    });
  }

  onPageClick(page: number) {
    this.page = page;
    this.loadActivities();
  }

  goActivity(activity: any) {
    this.router.navigateByUrl(
      'ot/' + this.projectID + '/' + activity.type + '/' + activity.activityID
    );
  }
}
