import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { CalendarSideBarComponent } from '../components/calendar-side-bar/calendar-side-bar.component';
import { rtlLangs } from '../../models/global-variables';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SideNavComponent } from 'src/app/layout/side-nav/side-nav.component';
import { MdbSidenavComponent } from 'mdb-angular-ui-kit/sidenav';
import { DashboardListener } from 'src/app/models/global-helper';
import { MdbVectorMapComponent } from 'mdb-angular-vector-maps';
import { StatsSideBarComponent } from '../components/stats-side-bar/stats-side-bar.component';

@Component({
  selector: 'dashboard-v2-page',
  templateUrl: './dashboard-v2-page.component.html',
  styleUrls: ['./dashboard-v2-page.component.scss'],
})
export class DashboardV2PageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  dashboardHelper$?: Subscription;
  lang$?: Subscription;
  advancedColorMap: any[] = [];

  @ViewChild('myMap') myMap?: MdbVectorMapComponent;

  hideStatistics: boolean = true;
  hideCalendar: boolean = true;

  isRtl?: boolean;
  dashboardType?: string;

  countries: any[] = [];

  legendMin: string | null = null;
  legendMax: string | null = null;

  activeField: any = 'total';

  firstList?: string[];
  secondList?: string[];

  appVersion: any;

  partners: string[] = [
    'alliance.jpg',
    'gef.png',
    'ifad.png',
    'resilient.png',
    'sapienza.png',
    'sara.png',
    'swiss.png',
    'unep.jpg',
    'raffaella.jpg',
  ];

  colors: any = {
    total: [
      '#80B6DA',
      '#60A4D1',
      '#4092C8',
      '#207FBE',
      '#006DB5',
      '#005F9E',
      '#005288',
      '#004471',
      '#00375A',
    ],
    projectCrops: [
      '#F6B993',
      '#F4A777',
      '#F2955C',
      '#EF8441',
      '#ED7226',
      '#CF6421',
      '#B2561D',
      '#944718',
      '#763913',
    ],
    projectAquatics: [
      '#C1DDAB',
      '#B2D495',
      '#A2CC80',
      '#93C36B',
      '#83BB56',
      '#73A44B',
      '#628C41',
      '#527536',
      '#425D2B',
    ],
    projectLivestock: [
      '#A3B59B',
      '#8CA381',
      '#759168',
      '#5E7E4F',
      '#476C36',
      '#3E5F2F',
      '#355129',
      '#2C4422',
      '#24361B',
    ],
  };

  constructor(
    private api: ApiService,
    private modalService: MdbModalService,
    private router: Router,
    private dashboardListner: DashboardListener,
    private translationService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getLists();
    this.api.getAppStatus().subscribe((value) => {
      this.appVersion = value as any;
    });
    this.api.getStatsCountries().subscribe((value) => {
      this.countries = value as any[];
      this.advancedColorMap = [...this.getColorMap()];
    });

    this.dashboardHelper$ = this.dashboardListner.data.subscribe((data) => {
      if (data.userRole) {
        this.dashboardType = data.userRole as string;
      }
    });
  }

  ngAfterViewInit(): void {
    this.hideStatistics = false;
    this.myMap!._mapUnits.forEach((value) => {
      if (value.id === 'MK') value.title = 'north ' + value.title;
      if (value.id !== 'EH') value.title = value.title.toUpperCase();
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
    this.dashboardHelper$?.unsubscribe();
  }

  getLists() {
    if (this.partners) {
      this.partners = this.shuffle(this.partners);
      this.firstList = this.partners.slice(0, 4);
      this.firstList = this.shuffle(this.firstList);
      this.secondList = this.partners.slice(4, 9);
      this.secondList = this.shuffle(this.secondList);
    }
  }

  shuffle(array: string[]) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  private getColorMap() {
    const countriesEnable = this.countries.filter(
      (value) => value[this.activeField] > 0
    );
    const values = countriesEnable.map((entry: any) => entry[this.activeField]);

    let max = Math.max(...values);
    let min = Math.min(...values);

    if (min === -max) {
      min = 1;
      max = 10;
    }
    if (min < 9) min = 1;

    if (max - min < 9) {
      max = min + 9;
    }

    this.legendMin = `${min}`;
    this.legendMax = `${max}`;

    const step = Math.floor(
      (max - min) / (this.colors[this.activeField].length - 1)
    );

    const colorMap: any[] = this.colors[this.activeField].map(
      (color: string, i: number) => {
        return {
          fill: color,
          regions: [],
        };
      }
    );

    values.forEach((value, i) => {
      const color = Math.floor((value - min) / 9);

      colorMap[color].regions?.push({
        id: countriesEnable[i].cnIso2,
        tooltip: value + (value == 1 ? ' Project' : ' Projects'),
        ...countriesEnable[i],
      });
    });

    return colorMap;
  }

  getField() {
    switch (this.activeField) {
      case 'total':
        return 'Total';
      case 'projectCrops':
        return 'Crop';
      case 'projectLivestock':
        return 'Livestock';
      case 'projectAquatics':
        return 'Aquaculture';
      default:
        return 'Total';
    }
  }

  changeField(field: string) {
    this.activeField = field;
    this.advancedColorMap = [...this.getColorMap()];
  }

  openCalendar() {
    // const animation = this.isRtl ? 'left' : 'right';
    // const position = this.isRtl ? 'modal-bottom-left' : 'modal-bottom-right';
    this.modalService.open(CalendarSideBarComponent, {
      containerClass: 'right',
      modalClass: 'modal-side modal-bottom-right modal-calendar rounded',
    });
  }

  openStatistics() {
    const direction =
      this.translationService.currentLang == 'ar' ? 'start-0' : 'end-0';
    this.modalService.open(StatsSideBarComponent, {
      containerClass: '',
      modalClass: 'modal-side top-0 ' + direction,
    });
  }

  openPartners() {
    this.router.navigateByUrl('u/partners');
  }
}
