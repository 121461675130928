<div
  class="modal-header"
  (click)="datePickerEnd?.close(); datePickerStart?.close()"
>
  {{ title ?? "" | translate }}
</div>
<div class="modal-body d-flex flex-col" [formGroup]="valueForm!">
  <div [ngSwitch]="colID" class="w-100">
    <div *ngSwitchCase="'expression'">
      <input type="text" class="w-100 form-control" formControlName="value" />
    </div>
    <div *ngSwitchCase="'agroZoneArea'">
      <input
        type="number"
        class="w-100 form-control"
        formControlName="numericValue"
      />
    </div>
    <div *ngSwitchCase="'agroZonePopulation'">
      <input
        type="number"
        class="w-100 form-control"
        formControlName="numericValue"
      />
    </div>
    <div *ngSwitchCase="'interventionArea'">
      <input
        type="number"
        class="w-100 form-control"
        formControlName="numericValue"
      />
    </div>
    <div *ngSwitchCase="'directBeneficiariesGender'">
      <div class="d-flex flex-column">
        <div class="d-flex flex-column mb-2">
          <span>
            {{ "REGISTER.FEMALE" | translate }}
            {{ "(" + this.cell.women + ")" }}
          </span>
          <div
            class="d-flex flex-row align-items-center justify-content-evenly"
          >
            <div class="w-100 d-flex justify-content-center">
              <span>&lt; 25</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="womenLo"
            />
            <div class="w-100 d-flex justify-content-center">
              <span>25 - 55</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="womenIn"
            />
            <div class="w-100 d-flex justify-content-center">
              <span>&gt; 55</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="womenGt"
            />
            <div class="w-100 d-flex justify-content-center mx-2">
              <span>{{ "ME.NOT_KNOWN" | translate }}</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="womenNk"
            />
          </div>
        </div>
        <div class="d-flex flex-column mb-2">
          <span
            >{{ "REGISTER.MALE" | translate }}
            {{ "(" + this.cell.men + ")" }}</span
          >
          <div
            class="d-flex flex-row align-items-center justify-content-evenly"
          >
            <div class="w-100 d-flex justify-content-center">
              <span>&lt; 25</span>
            </div>
            <input type="number" class="form-control" formControlName="menLo" />
            <div class="w-100 d-flex justify-content-center">
              <span>25 - 55</span>
            </div>
            <input type="number" class="form-control" formControlName="menIn" />
            <div class="w-100 d-flex justify-content-center">
              <span>&gt; 55</span>
            </div>
            <input type="number" class="form-control" formControlName="menGt" />
            <div class="w-100 d-flex justify-content-center mx-2">
              <span>Not Known</span>
            </div>
            <input type="number" class="form-control" formControlName="menNk" />
          </div>
        </div>
        <div class="d-flex flex-column mb-2">
          <span
            >{{ "REGISTER.DIVERSE" | translate }}
            {{ "(" + this.cell.other + ")" }}</span
          >
          <div
            class="d-flex flex-row align-items-center justify-content-evenly"
          >
            <div class="w-100 d-flex justify-content-center">
              <span>&lt; 25</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="otherLo"
            />
            <div class="w-100 d-flex justify-content-center">
              <span>25 - 55</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="otherIn"
            />
            <div class="w-100 d-flex justify-content-center">
              <span>&gt; 55</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="otherGt"
            />
            <div class="w-100 d-flex justify-content-center mx-2">
              <span>Not Known</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="otherNk"
            />
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'indirectBeneficiariesGender'">
      <div class="d-flex flex-column">
        <div class="d-flex flex-column mb-2">
          <span>
            {{ "REGISTER.FEMALE" | translate }}
            {{ "(" + this.cell.women + ")" }}
          </span>
          <div
            class="d-flex flex-row align-items-center justify-content-evenly"
          >
            <div class="w-100 d-flex justify-content-center">
              <span>&lt; 25</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="womenLo"
            />
            <div class="w-100 d-flex justify-content-center">
              <span>25 - 55</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="womenIn"
            />
            <div class="w-100 d-flex justify-content-center">
              <span>&gt; 55</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="womenGt"
            />
            <div class="w-100 d-flex justify-content-center mx-2">
              <span>Not Known</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="womenNk"
            />
          </div>
        </div>
        <div class="d-flex flex-column mb-2">
          <span
            >{{ "REGISTER.MALE" | translate }}
            {{ "(" + this.cell.men + ")" }}</span
          >
          <div
            class="d-flex flex-row align-items-center justify-content-evenly"
          >
            <div class="w-100 d-flex justify-content-center">
              <span>&lt; 25</span>
            </div>
            <input type="number" class="form-control" formControlName="menLo" />
            <div class="w-100 d-flex justify-content-center">
              <span>25 - 55</span>
            </div>
            <input type="number" class="form-control" formControlName="menIn" />
            <div class="w-100 d-flex justify-content-center">
              <span>&gt; 55</span>
            </div>
            <input type="number" class="form-control" formControlName="menGt" />
            <div class="w-100 d-flex justify-content-center mx-2">
              <span>Not Known</span>
            </div>
            <input type="number" class="form-control" formControlName="menNk" />
          </div>
        </div>
        <div class="d-flex flex-column mb-2">
          <span
            >{{ "REGISTER.DIVERSE" | translate }}
            {{ "(" + this.cell.other + ")" }}</span
          >
          <div
            class="d-flex flex-row align-items-center justify-content-evenly"
          >
            <div class="w-100 d-flex justify-content-center">
              <span>&lt; 25</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="otherLo"
            />
            <div class="w-100 d-flex justify-content-center">
              <span>25 - 55</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="otherIn"
            />
            <div class="w-100 d-flex justify-content-center">
              <span>&gt; 55</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="otherGt"
            />
            <div class="w-100 d-flex justify-content-center mx-2">
              <span>Not Known</span>
            </div>
            <input
              type="number"
              class="form-control"
              formControlName="otherNk"
            />
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'directBeneficiariesAge'">
      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="LO"
          value="LO"
          formControlName="value"
        />
        <label class="form-check-label" for="LO"> &lt; 25 </label>
      </div>

      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="IN"
          value="IN"
          formControlName="value"
        />
        <label class="form-check-label" for="IN"> 25 - 55 </label>
      </div>
      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="GT"
          value="GT"
          formControlName="value"
        />
        <label class="form-check-label" for="GT"> &gt; 55 </label>
      </div>
      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="A"
          value="A"
          formControlName="value"
        />
        <label class="form-check-label" for="A"> All </label>
      </div>
    </div>
    <div *ngSwitchCase="'indirectBeneficiariesAge'">
      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="LO"
          value="LO"
          formControlName="value"
        />
        <label class="form-check-label" for="LO"> &lt; 25 </label>
      </div>
      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="IN"
          value="IN"
          formControlName="value"
        />
        <label class="form-check-label" for="IN"> 25 - 55 </label>
      </div>
      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="GT"
          value="GT"
          formControlName="value"
        />
        <label class="form-check-label" for="GT"> &gt; 55 </label>
      </div>
      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="A"
          value="A"
          formControlName="value"
        />
        <label class="form-check-label" for="A"> All </label>
      </div>
    </div>
    <div *ngSwitchCase="'satisfaction'">
      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="LOW"
          value="LOW"
          formControlName="value"
        />
        <label class="form-check-label" for="LOW"> LOW </label>
      </div>
      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="MEDIUM"
          value="MEDIUM"
          formControlName="value"
        />
        <label class="form-check-label" for="MEDIUM"> MEDIUM </label>
      </div>
      <div class="form-check mb-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="value"
          id="HIGH"
          value="HIGH"
          formControlName="value"
        />
        <label class="form-check-label" for="HIGH"> HIGH </label>
      </div>
    </div>
    <div *ngSwitchCase="'note'">
      <textarea
        type="text"
        rows="4"
        formControlName="value"
        style="resize: none"
      ></textarea>
    </div>
    <div *ngSwitchCase="'start'">
      <div class="d-flex">
        <input
          [mdbDatepicker]="datepickerStart"
          type="text"
          id="date"
          class="form-control mx-2"
          formControlName="dateValue"
          (click)="datePickerStart?.open()"
        />
        <i class="bi-calendar-fill mx-2 my-auto"></i>
        <mdb-datepicker #datepickerStart [inline]="true"></mdb-datepicker>
      </div>
    </div>
    <div *ngSwitchCase="'end'">
      <div class="d-flex">
        <input
          [mdbDatepicker]="datepickerEnd"
          type="text"
          id="date"
          class="form-control mx-2"
          formControlName="dateValue"
          (click)="datePickerEnd?.open()"
        />
        <i class="bi-calendar-fill mx-2 my-auto"></i>
        <mdb-datepicker #datepickerEnd [inline]="true"></mdb-datepicker>
      </div>
    </div>
    <div *ngSwitchDefault></div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
  <div class="btn btn-success" (click)="save()">
    {{ "BUTTONS.SAVE" | translate }}
  </div>
</div>
