<div class="modal-header">
  <div class="d-flex flex-row">
    <i class="bi bi-cloud-arrow-down-fill h3"></i>
    <h4 class="mx-1 h3 mb-0">{{ title | translate }}</h4>
  </div>
</div>
<div class="modal-body mb-0">
  <div class="d-flex flex-column" style="height: 30rem">
    <div class="row bg-white mx-2">
      <div class="col-3">
        <h6>{{ "WORKING_AREA.COUNTRY" | translate }}</h6>
      </div>
      <div class="col-3">
        <h6>{{ "WORKING_AREA.VARIETY" | translate }}</h6>
      </div>
      <div class="col-3">
        <h6>{{ "WORKING_AREA.SPECIES" | translate }}</h6>
      </div>
      <div class="col-3"></div>
    </div>
    <div class="w-100 bg-primary pb-1" style="height: 1px"></div>
    <div class="d-flex flex-column py-2" style="overflow-y: scroll">
      <div
        class="row py-2 mx-2 rounded align-items-center"
        [ngClass]="
          i % 2 == 0 ? 'bg-white text-primary' : 'bg-secondary text-white'
        "
        *ngFor="let variety of otherVarieties; index as i"
      >
        <div class="col-3 my-auto">
          <h6 class="my-auto">
            {{ variety.country }}
          </h6>
        </div>
        <div class="col-3 my-auto">
          <h6 class="my-auto">
            {{ variety.name }}
          </h6>
        </div>
        <div class="col-3 my-auto">
          <h6 class="my-auto">
            {{ variety.species.specie }}
          </h6>
        </div>
        <div class="col-3 d-flex">
          <div
            class="btn btn-primary my-auto me-1"
            (click)="importItems(variety)"
          >
            {{ "BUTTONS.IMPORT" | translate }}
          </div>
          <div
            class="btn btn-primary my-auto ms-1"
            (click)="viewItems(variety)"
          >
            {{ "BUTTONS.VIEW" | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row"></div>
  </div>
  <loading class="mask w-100 h-100" *ngIf="loading"></loading>
</div>
<div class="modal-footer mt-0">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
</div>
