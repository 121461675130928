import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-decline-request',
  templateUrl: './modal-decline-request.component.html',
  styleUrls: ['./modal-decline-request.component.scss'],
})
export class ModalDeclineRequestComponent implements OnInit {
  projectID?: string;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalDeclineRequestComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}

  declineRequest() {
    if (this.projectID) {
      this.api.rejectProjectUser(this.projectID).subscribe(() => {
        this.modalRef.close(true);
      });
    }
  }
}
