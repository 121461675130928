<div class="d-flex flex-row">
  <div class="d-flex flex-column w-100 mx-5 mt-3">
    <div class="d-flex align-items-center mb-2">
      <div class="d-flex align-items-center mt-2">
        <i
          class="bi bi-arrow-left-square-fill h1 pointer my-auto"
          (click)="goBack()"
        ></i>
        <h1 class="fw-bold my-auto mx-3">
          {{ type }} - {{ info?.uuid }} - {{ info?.name ?? "/" }} -
          {{ info.location }}
        </h1>
      </div>
    </div>
  </div>
</div>
<div class="d-flex flex-row mt-3 mx-5">
  <div
    class="border-primary rounded"
    style="
      width: 75%;
      border: solid 2px;
      position: relative;
      overflow: scroll;
      min-height: 80vh;
      max-height: 80vh;
    "
  >
    <div
      class="d-flex flex-column align-items-center text-center mt-4"
      *ngIf="columnHeaders.length == 0 && rows.length == 0"
    >
      <img src="/assets/images/logo.png" style="height: 15rem; width: 15rem" />
      <div class="btn btn-primary mt-3" (click)="getTableStructure()">
        Generate Table
      </div>
    </div>

    <table *ngIf="columnHeaders.length != 0 && rows.length != 0 && !loading">
      <thead>
        <tr *ngIf="columnHeaders">
          <th
            class="bg-light border-primary rounded-5 shadow-2"
            style="z-index: 20"
          ></th>
          <th
            class="pointer px-2 py-0 rounded-5 shadow-2"
            style="max-height: 4rem; min-height: 4rem"
            *ngFor="let column of columnHeaders; index as columnIndex"
            [ngClass]="
              selectedColumn == columnIndex
                ? 'cell-selected text-white'
                : 'bg-white text-primary'
            "
          >
            <div
              class="py-2 text-center"
              style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
            >
              {{ column.name }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="rows">
        <tr *ngFor="let row of rows; index as rowIndex">
          <th
            class="rounded-5 shadow-2 pointer"
            style="z-index: 10"
            [ngClass]="
              selectedRow == rowIndex
                ? 'cell-selected text-white'
                : 'bg-white text-primary'
            "
          >
            <div class="d-flex flex-column justify-content-center">
              <span
                class="my-auto"
                [ngClass]="!last ? '' : ''"
                *ngFor="let name of row.name.split('~'); last as last"
              >
                {{ name }}
              </span>
            </div>
          </th>
          <td
            class="text-center pointer m-1 rounded-5 shadow-2"
            *ngFor="let answer of row.answers; index as answerIndex"
          >
            <div
              class="d-flex align-items-center"
              style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
            >
              <span class="my-auto w-100 text-center">
                {{ answer.name }}
              </span>
              <i
                class="bi bi-chat-left-text-fill my-auto"
                *ngIf="answer.hasNote"
              ></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    class="border-primary rounded d-flex flex-column align-items-center justify-content-between mx-2"
    style="width: 25%; border: 2px solid"
  >
    <div class="d-flex flex-column w-100 px-4">
      <h5 class="my-3 mx-auto">Select Output Table</h5>
      <div [ngSwitch]="type">
        <div *ngSwitchCase="'FGD'" class="d-flex flex-column">
          <div
            class="btn btn-primary mb-2"
            *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            [class.active]="item == selectedOt"
            (click)="selectOt(item)"
          >
            <i class="fas fa-check" *ngIf="item == selectedOt"></i>
            OT {{ item }}
          </div>
        </div>
        <div *ngSwitchCase="'HHS'" class="d-flex flex-column">
          <div
            class="btn btn-primary mb-2"
            *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8]"
            [class.active]="item == selectedOt"
            (click)="selectOt(item)"
          >
            <i class="fas fa-check" *ngIf="item == selectedOt"></i>
            OT {{ item }}
          </div>
        </div>
      </div>
      <hr />
      <div class="btn btn-primary mt-3" (click)="getTableStructure()">
        Generate Table
      </div>
    </div>
  </div>
  <div class="mask" style="z-index: 99999" *ngIf="loading">
    <loading></loading>
  </div>
</div>
