import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss'],
})
export class ListHeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() color: string = 'bg-white';

  constructor() {}

  ngOnInit(): void {}
}
