import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileAnalysisPageComponent } from './file-analysis-page/file-analysis-page.component';
import { MdbModule } from '../mdb/mdb.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalsModule } from '../modals/modals.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DatabaseModule } from '../database/database.module';
import { FileAnalysisTypeComponent } from './components/file-analysis-type/file-analysis-type.component';
import { FileAnalysisCropComponent } from './components/file-analysis-crop/file-analysis-crop.component';
import { FileAnalysisLivestockComponent } from './components/file-analysis-livestock/file-analysis-livestock.component';
import { FileAnalysisAquacultureComponent } from './components/file-analysis-aquaculture/file-analysis-aquaculture.component';
import { LayoutModule } from '../layout/layout.module';

@NgModule({
  declarations: [
    FileAnalysisPageComponent,
    FileAnalysisTypeComponent,
    FileAnalysisCropComponent,
    FileAnalysisLivestockComponent,
    FileAnalysisAquacultureComponent,
  ],
  imports: [
    CommonModule,
    MdbModule,
    ReactiveFormsModule,
    FormsModule,
    ModalsModule,
    RouterModule,
    TranslateModule,
    DatabaseModule,
    LayoutModule,
  ],
  exports: [FileAnalysisPageComponent],
})
export class FileAnalysisModule {}
