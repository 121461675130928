<div
  class="card"
  [ngClass]="deleted ? 'border-success' : 'border-danger'"
  style="border: 3px solid"
>
  <div class="modal-body">
    <h4>
      {{
        (deleted ? "PERIOD.ENABLE_TITLE" : "PERIOD.DISABLE_TITLE") | translate
      }}
    </h4>
    <h5>
      {{
        (deleted ? "PERIOD.ENABLE_SUBTITLE" : "PERIOD.DISABLE_SUBTITLE")
          | translate
      }}
    </h5>
    <div class="py-3 bg-error mt-4 rounded text-center" *ngIf="error">
      <h6 class="my-auto">
        {{ "OT_CLEANING.ERROR" | translate }} [{{ error }}]
      </h6>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" (click)="modalRef.close(false)">
      {{ "BUTTONS.CLOSE" | translate }}
    </div>
    <div
      class="btn"
      [ngClass]="deleted ? 'btn-success' : 'btn-danger'"
      (click)="switchState()"
    >
      {{ (deleted ? "BUTTONS.ENABLE" : "BUTTONS.DISABLE") | translate }}
    </div>
  </div>
</div>
