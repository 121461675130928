import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { Country } from 'src/app/auth/models/country.model';
import { Role } from 'src/app/auth/models/role.model';
import { User } from 'src/app/auth/models/user.model';
import { AppState } from 'src/app/auth/reducer';
import { GetUser } from 'src/app/auth/store/auth.action';
import { getError, getUser } from 'src/app/auth/store/auth.selectors';
import * as actions from 'src/app/auth/store/auth.action';
import { ApiService } from 'src/app/api.service';
import * as fromAuth from '../../auth/store/auth.action';
import { Router } from '@angular/router';
import { MdbSidenavComponent } from 'mdb-angular-ui-kit/sidenav';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ModalChangeRoleComponent } from 'src/app/modals/settings/modal-change-role/modal-change-role.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  DashboardHelper,
  DashboardListener,
  SidenavHelper,
  SidenavListener,
} from 'src/app/models/global-helper';
import { SettingsComponent } from '../settings/settings.component';
import { ChangeCountryComponent } from '../change-country/change-country.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @Input() sideNav?: MdbSidenavComponent;

  dashboardHelper: DashboardHelper = new DashboardHelper();
  refForm?: UntypedFormGroup;
  error$?: Observable<string | null>;

  user$?: Subscription;
  sidenavHelper: SidenavHelper = new SidenavHelper();
  user?: User;
  roles?: Array<string>;
  country?: Country;
  partnerTypes?: any[];

  selectedRef?: string;

  flagClass: string = '';

  isAlive: boolean = false;
  showSettings: boolean = false;
  showChangeCountry: boolean = false;

  constructor(
    private store: Store<AppState>,
    private api: ApiService,
    private router: Router,
    private modalService: MdbModalService,
    private dashboardListener: DashboardListener,
    private sidenavListener: SidenavListener,
    private translationService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getUser();

    this.refForm = new UntypedFormGroup({
      ref: new UntypedFormControl(this.selectedRef),
    });

    this.ref?.valueChanges.subscribe((value) => {
      this.api.changeRoleRef(this.ref?.value).subscribe(() => {
        this.sidenavHelper.userRef = this.ref?.value;
        this.sidenavListener.setData(this.sidenavHelper);
      });
    });
  }

  getUser() {
    this.store.select(getUser).subscribe((user) => {
      if (user) {
        this.user = user as User;
        this.roles = this.user?.currentRole.roles as Array<string>;
        this.country = this.user?.currentRole.country;
        this.flagClass = 'fi-' + this.country?.cnIsoDue?.toLowerCase();
        this.dashboardHelper.userRole = this.user.currentRole.role;
        this.dashboardListener.setData(this.dashboardHelper);
        if (this.user.currentRole.refs) {
          this.partnerTypes = this.user.currentRole.refs;
          this.user.currentRole.refs.forEach((item: any) => {
            if (item.selected == true) {
              this.selectedRef = item.ref;
              this.ref?.setValue(item.ref);
              this.sidenavHelper.userRef = item.ref;
              this.sidenavListener.setData(this.sidenavHelper);
            }
          });
        }
      }
    });
  }

  get ref() {
    return this.refForm?.get('ref');
  }

  onSelect() {}

  onClick() {
    if (window.innerWidth <= 1500) {
      this.sideNav?.hide();
    }
  }

  // openSettings() {
  //   this.showSettings = true;
  // }

  openSettings() {
    const direction =
      this.translationService.currentLang == 'ar' ? 'end-0' : 'start-0';
    const modalRef = this.modalService.open(SettingsComponent, {
      containerClass: '',
      modalClass: 'modal-side top-0 modal-sm ' + direction,
    });

    modalRef.onClose.subscribe((value) => {
      if (value === 'CHANGE_COUNTRY') {
        this.openChangeCountry();
      }
    });
  }

  // openChangeCountry() {
  //   this.showChangeCountry = true;
  // }

  openChangeCountry() {
    const direction =
      this.translationService.currentLang == 'ar' ? 'end-0' : 'start-0';
    const modalRef = this.modalService.open(ChangeCountryComponent, {
      containerClass: '',
      modalClass: 'modal-side top-0 modal-sm ' + direction,
    });

    modalRef.onClose.subscribe((value) => {
      this.store.dispatch(new actions.GetUser());
    });
  }

  onSettingsClosed(val: boolean) {
    this.showSettings = val;
  }

  onChangeCoutryClosed(val: boolean) {
    this.showChangeCountry = val;
  }

  logOut() {
    this.store.dispatch(new fromAuth.LogoutRequested({ user: this.user! }));
    this.error$ = this.store.pipe(
      select(getError),
      map((error: any) => {
        this.router.navigateByUrl('/');
        return null;
      })
    );
  }

  openChangeRole() {
    const modalRef = this.modalService.open(ModalChangeRoleComponent, {
      containerClass: 'top',
      data: { user: this.user },
    });
    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.store.dispatch(new actions.GetUser());
        this.ref?.setValue(this.ref?.value === 'USER' ? 'PARTNERS' : 'USER');
        this.router.navigateByUrl('/u/dashboard');
      }
    });
  }
}
