import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'database-list-card',
  templateUrl: './database-list-card.component.html',
  styleUrls: ['./database-list-card.component.scss'],
})
export class DatabaseListCardComponent implements OnInit {
  @Input() name: string = '';
  @Input() image: string = '';
  @Input() isReady: boolean = false;

  myBorder: string = '';

  constructor() {}

  ngOnInit(): void {
    this.myBorder = this.isReady ? '3px solid #006db5' : '3px solid #ed7226';
  }

  // ReadyOrNot(isReady: boolean): string {
  //   if (isReady) return "{{'DATABASE.READY_FOR'|translate}}";
  // }
}
