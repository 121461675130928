import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Descriptor } from 'src/app/models/descriptor';

@Component({
  selector: 'modal-descriptor',
  templateUrl: './modal-descriptor.component.html',
  styleUrls: ['./modal-descriptor.component.scss'],
})
export class ModalDescriptorComponent implements OnInit {
  descriptorID?: string;
  descriptor?: Descriptor;
  measures?: Array<any>;

  constructor(
    public modalRef: MdbModalRef<ModalDescriptorComponent>,
    private api: ApiService,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.api.getDescriptor(this.descriptorID!).subscribe((value) => {
      this.descriptor = value as Descriptor;
      if (this.descriptor.answerType === 'NUMBER') {
        this.api.getMeasureSelect().subscribe((value) => {
          this.measures = value as Array<any>;
        });
      }
    });
  }
}
