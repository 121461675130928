<div class="modal-header">
  <div class="row w-100">
    <div class="col-4 d-flex flex-row">
      <i class="bi bi-gear-fill h3"></i>
      <h4 class="mx-1 h3 mb-0">
        {{ "WORKING_AREA.MANAGE_DESCRIPTORS" | translate }}
      </h4>
    </div>
    <div class="col-6" [formGroup]="filterForm!">
      <div class="mx-3">
        <input
          type="text"
          class="form-control my-auto"
          placeholder="{{ 'PLACEHOLDER.FILTER_BY_NAME' | translate }}"
          formControlName="filter"
        />
      </div>
    </div>
  </div>
</div>

<div class="modal-body" style="height: 80vh; overflow-y: scroll">
  <mdb-accordion [flush]="true">
    <mdb-accordion-item *ngFor="let category of wholeList">
      <ng-template mdbAccordionItemHeader>
        <dir class="d-flex"></dir>
        <span class="me-1">
          {{ category.name }}
        </span>
        <span class="badge ms-1 bg-primary my-auto px-2">
          {{ category.count }}/{{ category.descriptors.length }}
        </span>
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="d-flex flex-column py-2">
          <div
            class="d-flex py-2 mx-2 rounded hover"
            [ngClass]="i % 2 ? 'bg-light' : 'bg-transparent'"
            *ngFor="let descriptor of category.descriptors; index as i"
            (click)="onSelect(category, descriptor)"
          >
            <i
              class="bi mx-2"
              [ngClass]="
                selectedList.includes(descriptor.refID)
                  ? 'bi-check-square-fill'
                  : 'bi-square'
              "
            ></i>
            <span class="ms-1">{{ descriptor.name }}</span>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
  <loading class="mask w-100 h-100" *ngIf="loading"></loading>
</div>

<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
  <div class="btn btn-success" (click)="save()">
    {{ "BUTTONS.SAVE" | translate }}
  </div>
</div>
