<div class="card mt-3 mx-1 pb-3" style="width: 50vw; overflow: hidden">
  <mdb-accordion flush="true">
    <mdb-accordion-item *ngFor="let category of categories">
      <ng-template mdbAccordionItemHeader>
        {{ category.categoryLang }}
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div style="max-height: 17rem; overflow-y: auto">
          <div class="pb-2" *ngFor="let sub of category.subCategories">
            <div class="row pt-2 mb-2 px-2 mx-2 sticky-top bg-white">
              <div class="col">
                {{ sub.categoryLang }}
              </div>
              <div class="bg-primary" style="height: 2px"></div>
            </div>
            <div
              class="row py-2 px-2 mx-4 rounded"
              *ngFor="let desc of sub.descriptors; index as i"
              [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
            >
              <div class="col">{{ desc.descriptorLang }}</div>
            </div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
