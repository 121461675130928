<div class="modal-body bg-light p-0 m-0">
  <div class="w-100 my-auto" style="min-height: 100vh">
    <img
      class="w-100 position-relative my-auto"
      style="object-fit: contain; max-height: 90vh"
      [src]="images[position].img"
    />
    <div
      class="w-100 h-100 position-absolute left-0 bottom-0 d-flex justify-content-between"
    >
      <div
        class="h-100 d-flex justify-content-center align-items-center gradient-left hover-arrows"
        style="width: 10rem"
        (click)="prev()"
      >
        <i class="bi bi-caret-left-fill text-white" style="font-size: 3rem"></i>
      </div>
      <div
        class="h-100 d-flex flex-column justify-content-end text-center"
        style="width: 30vw"
      >
        <h4 class="m-0">{{ images[position].owner }}</h4>
        <div class="mb-1">
          <h4>
            {{ "WORKING_AREA.PHOTO" | translate }} {{ (position || 0) + 1 }}
            {{ "WORKING_AREA.OF" | translate }}
            {{ images.length }}
          </h4>
        </div>

        <div class="btn btn-primary mb-4 mx-auto" (click)="modalRef.close()">
          {{ "BUTTONS.CLOSE" | translate }}
        </div>
      </div>
      <div
        class="h-100 d-flex justify-content-center align-items-center gradient-right hover-arrows"
        style="width: 10rem"
        (click)="next()"
      >
        <i
          class="bi bi-caret-right-fill text-white"
          style="font-size: 3rem"
        ></i>
      </div>
    </div>
  </div>
</div>
