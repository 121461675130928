<div class="border-danger card" style="border: 3px solid">
  <div class="modal-body">
    <h4>{{ "VARIETY.DECLINE_REQUEST_TITLE" | translate }}</h4>
    <h5>
      {{ "VARIETY.DECLINE_REQUEST_SUBTITLE" | translate }}
    </h5>
  </div>
  <div class="modal-footer justify-content-center">
    <button
      type="button"
      class="btn btn-primary"
      (click)="modalRef.close(false)"
    >
      {{ "BUTTONS.CLOSE" | translate }}
    </button>
    <button type="button" class="btn btn-danger" (click)="declineRequest()">
      {{ "BUTTONS.DECLINE" | translate }}
    </button>
  </div>
</div>
