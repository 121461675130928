<div class="d-flex flex-wrap mt-3 mb-5 w-100 px-5">
  <div class="d-flex justify-content-center w-100 mt-5" *ngIf="!sites">
    <loading [glass]="false"></loading>
  </div>
  <div
    class="card mb-3 me-3 p-2 d-flex flex-column"
    [ngClass]="site.deleted ? 'bg-danger-opacity' : ''"
    style="height: 13rem; width: 25rem"
    *ngFor="let site of sites"
  >
    <div style="height: 2.4rem; overflow-y: auto">
      <h3 class="mb-2">{{ site.name }}</h3>
    </div>
    <h5 class="mb-2 text-secondary">
      {{ site.fgds + " FGD(s)" }} - {{ site.hhs + " HHS(s)" }} -
      {{ site.kis + " KI(s)" }} -
      {{ site.vdm + " VDM(s)" }}
    </h5>
    <h5 class="mb-2">{{ site.province }}</h5>
    <h5 class="mb-2" *ngIf="site.agroEcoZoneId !== 'OTHER'">
      {{ site.agroEcoZone }}
    </h5>
    <h5 class="" *ngIf="site.agroEcoZoneId === 'OTHER'">
      {{ site.agroEcoZoneOther }}
    </h5>
    <div
      class="d-flex flex-row align-items-center mt-auto mb-2"
      [ngClass]="
        projectPermissions?.editSite
          ? 'justify-content-between'
          : 'justify-content-end'
      "
    >
      <div
        class="my-auto"
        (click)="openEditSite(site)"
        *ngIf="projectPermissions?.editSite"
      >
        <h6 class="my-auto" style="cursor: pointer">
          <u> {{ "BUTTONS.EDIT" | translate }} </u>
        </h6>
      </div>
      <button class="btn btn-primary" (click)="openSite(site.siteID || '')">
        {{ "BUTTONS.ENTER" | translate }}
      </button>
    </div>
  </div>
</div>
