<div class="card col" style="height: 40rem; overflow-y: auto">
  <div class="mb-2 pb-0 sticky-top bg-white">
    <form class="d-flex flex-row py-4" [formGroup]="filterForm!">
      <h3 class="mx-3 my-auto">{{ header | translate }}</h3>
      <div class="my-auto mx-2">
        <input
          type="text"
          class="form-control"
          placeholder="{{ filterPlaceholder | translate }}"
          formControlName="filter"
        />
      </div>
      <div class="mx-2 my-auto">
        <ng-select
          class="control"
          placeholder="{{ 'PLACEHOLDER.COUNTRY' | translate }}"
          formControlName="country"
          [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
        >
          <ng-option *ngFor="let country of countries" [value]="country.value">
            {{ country.label }}
          </ng-option>
        </ng-select>
      </div>
    </form>
    <div class="mx-2 w-90 bg-primary" style="height: 2px"></div>
  </div>
  <div *ngIf="!loading">
    <div
      class="d-flex flex-row my-1 mx-3 py-3 rounded hover"
      style="cursor: pointer"
      *ngFor="let variety of varieties; index as i"
      [ngClass]="i % 2 == 0 ? 'bg-light' : 'bg-white'"
      (click)="openVariety(variety)"
    >
      <div class="d-flex align-items-center">
        <h5 class="my-auto mx-2">{{ variety.name }}</h5>
        <p class="my-auto mx-2">|</p>
        <p class="my-auto">{{ variety.countries }}</p>
      </div>
      <i class="bi bi-info-circle-fill h5 my-auto mx-3"></i>
    </div>
  </div>
  <loading class="mask" *ngIf="loading"></loading>
</div>
