<!-- <div class="d-flex flex-column w-100 mt-3">
  <div class="sticky-top bg-light" #stickyNav>
    <div class="d-flex flex-row mt-3 align-items-center">
      <div style="height: 5rem; width: 5rem">
        <img
          class="w-100 h-100"
          style="object-fit: contain"
          src="../../assets/images/aquaculture.svg"
        />
      </div>
      <h1 class="fw-bold mx-2 my-auto">{{ "NAVIGATION.AQUACULTURE" | translate }}</h1>
    </div>
  </div>
</div> -->
<work-in-progress></work-in-progress>
