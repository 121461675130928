import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'modal-livestock-answers',
  templateUrl: './modal-livestock-answers.component.html',
  styleUrls: ['./modal-livestock-answers.component.scss'],
})
export class ModalLivestockAnswersComponent implements OnInit {
  descriptor?: any;
  answers: any[] = [];
  constructor(
    public modalRef: MdbModalRef<ModalLivestockAnswersComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    if (this.descriptor.answerTextFA) {
      this.answers.push({
        gender: 'FEMALE',
        age: 'ADULT',
        note: this.descriptor.noteFA,
        value: this.descriptor.answerTextFA,
      });
    }
    if (this.descriptor.answerTextMA) {
      this.answers.push({
        gender: 'MALE',
        age: 'ADULT',
        note: this.descriptor.noteMA,
        value: this.descriptor.answerTextMA,
      });
    }
    if (this.descriptor.answerTextFY) {
      this.answers.push({
        gender: 'FEMALE',
        age: 'JOUVENILE',
        note: this.descriptor.noteFY,
        value: this.descriptor.answerTextFY,
      });
    }
    if (this.descriptor.answerTextMY) {
      this.answers.push({
        gender: 'MALE',
        age: 'JOUVENILE',
        note: this.descriptor.noteMY,
        value: this.descriptor.answerTextMY,
      });
    }
  }
}
