import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'diversity-benefit-card',
  templateUrl: './diversity-benefit-card.component.html',
  styleUrls: ['./diversity-benefit-card.component.scss'],
})
export class DiversityBenefitCardComponent implements OnInit {
  @Input() last?: boolean;
  @Input() i?: number;
  @Input() period?: any;
  @Input() familyID?: any;

  values = [];

  constructor(private api: ApiService) {}

  ngOnInit(): void {}
}
