import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { FiltersHelper, FiltersListener } from 'src/app/models/filter-list';

@Component({
  selector: 'database-list',
  templateUrl: './database-list.component.html',
  styleUrls: ['./database-list.component.scss'],
})
export class DatabaseListComponent implements OnInit, OnDestroy {
  databaseType: string = '';
  langSub?: Subscription;
  filterList$?: Subscription;
  list?: Array<any>;
  filtersHelper?: FiltersHelper;

  loaded: boolean = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService,
    private filtersListener: FiltersListener
  ) {}

  ngOnInit(): void {
    this.getCurrentLocation();
    
    this.filterList$ = this.filtersListener.data.subscribe((filter) => {
      this.filtersHelper = filter;
      this.getPost();
    });

    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.getPost();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
    this.filterList$?.unsubscribe();
  }

  getCurrentLocation() {
    const path = this.router.url;
    this.databaseType = path.split('/')[3];
  }

  getPost = () => {
    switch (this.databaseType) {
      case 'crop':
        this.getCrops();
        break;
      case 'livestock':
        this.getLivestock();
        break;
      case 'aquaculture':
        this.getAquaculture();
        break;
      default:
        break;
    }
  };

  getCrops() {
    this.api
      .getFitlteredCrops(
        this.filtersHelper?.filter,
        this.filtersHelper?.ready,
        this.filtersHelper?.notReady,
        this.filtersHelper?.type
      )
      .subscribe((value) => {
        this.list = value as Array<any>;
      });
    this.loaded = true;
  }

  getLivestock() {
    this.api
      .getFilteredLivestocks(
        this.filtersHelper?.filter,
        this.filtersHelper?.ready,
        this.filtersHelper?.notReady,
        this.filtersHelper?.type
      )
      .subscribe((value) => {
        this.list = value as Array<any>;
      });
    this.loaded = true;
  }

  getAquaculture() {
    this.api
      .getFilteredAquaculture(
        this.filtersHelper?.filter,
        this.filtersHelper?.ready,
        this.filtersHelper?.notReady,
        this.filtersHelper?.type
      )
      .subscribe((value) => {
        this.list = value as Array<any>;
      });
    this.loaded = true;
  }

  getName(item: any) {
    if (item.crop) return item.crop;
    if (item.aquatic) return item.aquatic;
    if (item.livestock) return item.livestock;
  }

  goToItem(item: any) {
    switch (this.databaseType) {
      case 'crop':
        this.router.navigateByUrl('u/database/c/' + item.cropID);
        break;
      case 'livestock':
        this.router.navigateByUrl('u/database/l/' + item.livestockID);
        break;
      case 'aquaculture':
        this.router.navigateByUrl('u/database/a/' + item.aquaticID);
        break;
      default:
        break;
    }
  }
}
