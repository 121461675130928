import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalSiteDataCollectedComponent } from 'src/app/modals/projects/modal-site-activity/modal-site-activity.component';
import { ModalEditActivityComponent } from 'src/app/modals/sites/modal-edit-activity/modal-edit-activity.component';
import { ActivityPreview } from '../site-data-collected/site-data-collected.component';

@Component({
  selector: 'project-surveys',
  templateUrl: './project-surveys.component.html',
  styleUrls: ['./project-surveys.component.scss'],
})
export class ProjectSurveysComponent implements OnInit {
  refID?: string;
  activities?: ActivityPreview[];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.getDatabaseType();
    this.apiCall();
  }

  getDatabaseType() {
    this.refID = this.route.parent?.snapshot.paramMap.get('id') || '';
  }

  apiCall() {
    if (this.refID) {
      this.api.getSurveys(this.refID).subscribe((value) => {
        this.activities = value as ActivityPreview[];
      });
    }
  }

  show(refID: string, activityType: string) {
    this.modalService.open(ModalSiteDataCollectedComponent, {
      modalClass: 'modal-lg',
      data: {
        refID: refID,
        activityType: activityType,
      },
    });
  }

  openEditModal(activity: ActivityPreview, index: number) {
    const modalRef = this.modalService.open(ModalEditActivityComponent, {
      data: {
        activity: activity,
      },
    });
    modalRef.onClose.subscribe((newActivity: ActivityPreview) => {
      if (newActivity) {
        this.activities![index] = newActivity;
      }
    });
  }
}
