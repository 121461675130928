<loading [glass]="false" *ngIf="loading"></loading>
<div class="mx-5 mb-5 pb-5 d-flex flex-column" *ngIf="!loading">
  <div class="d-flex justify-content-between mb-3">
    <div class="btn btn-primary" (click)="back()">
      {{ "BUTTONS.BACK" | translate }}
    </div>

    <div class="btn btn-info" (click)="openManageInterventions()">
      {{ "ME.VIEW_CONSTRAINTS" | translate }}
    </div>
    <div class="btn btn-success" (click)="openAnswerInterventions()">
      {{ "ME.IMPLEMENT_CONSTRAINTS" | translate }}
    </div>
  </div>

  <div class="d-flex flex-wrap mb-2" [formGroup]="filterForm!">
    <ng-select
      [placeholder]="'Species'"
      class="control mx-2 mb-2"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      formControlName="refID"
      [clearable]="false"
    >
      <ng-option *ngFor="let option of species" [value]="option.value">
        {{ option.label }}
      </ng-option>
    </ng-select>
    <ng-select
      [placeholder]="'Site'"
      class="control mx-2 mb-2"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      formControlName="siteID"
      [clearable]="false"
    >
      <ng-option *ngFor="let option of sites" [value]="option.siteID">
        {{ option.name }}
      </ng-option>
    </ng-select>
    <ng-select
      [placeholder]="'Agroeco Zone'"
      class="control mx-2 mb-2"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      formControlName="agroecoZone"
    >
      <ng-option *ngFor="let option of []" [value]=""> option.name </ng-option>
    </ng-select>
    <ng-select
      [placeholder]="'Categories'"
      class="control mx-2 mb-2"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      formControlName="categoryID"
    >
      <ng-option *ngFor="let option of [1, 1]" [value]="">
        option.label
      </ng-option>
    </ng-select>
    <ng-select
      [placeholder]="'Gender'"
      class="control mx-2 mb-2"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      formControlName="gender"
    >
      <ng-option value="M"> {{ "REGISTER.MALE" | translate }} </ng-option>
      <ng-option value="F"> {{ "REGISTER.FEMALE" | translate }} </ng-option>
      <ng-option value="D"> {{ "REGISTER.DIVERSE" | translate }} </ng-option>
    </ng-select>
    <ng-select
      [placeholder]="'Age'"
      class="control mx-2 mb-2"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      formControlName="age"
    >
      <ng-option value="LO"> &lt; 25 </ng-option>
      <ng-option value="IN"> 25 - 55 </ng-option>
      <ng-option value="GT"> &gt; 55 </ng-option>
      <ng-option value="NK"> {{ "ME.NOT_KNOWN" | translate }} </ng-option>
    </ng-select>
  </div>

  <div class="mt-4 mb-3 py-2 border border-primary rounded-2">
    <div class="row px-2">
      <h4>
        {{ "ME.SITE_STATISTICS" | translate }}
      </h4>
    </div>
    <div class="row gap-2 justify-content-around">
      <div
        class="card d-flex flex-column rounded col-2 p-0 m-0"
        style="overflow: hidden"
      >
        <div class="bg-primary w-100 text-white">
          <h5 class="p-3 my-auto text-center">
            {{ "ME.NUMBER_INTERVENTION" | translate }}
          </h5>
        </div>
        <div
          class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center h-100"
          style="border: solid 2px"
        >
          <h1 *ngIf="numberInterventions != undefined">
            {{ numberInterventions }}
          </h1>
          <h1 *ngIf="numberInterventions == undefined">
            {{ placeholderNumber }}
          </h1>
        </div>
      </div>

      <div
        class="card d-flex flex-column rounded col-2 m-0 p-0"
        style="overflow: hidden"
      >
        <div class="bg-primary w-100 text-white">
          <h5 class="p-3 my-auto text-center">
            {{ "ME.NUMBER_INTERVENTION_AGE" | translate }}
          </h5>
        </div>
        <div
          class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center h-100"
          style="border: solid 2px"
        >
          <canvas
            mdbChart
            [type]="'pie'"
            [datasets]="
              datasetAge == undefined ? datasetPlaceholder : datasetAge
            "
            [labels]="
              datasetAge == undefined
                ? datasetPlaceholder[0].labels
                : datasetAge[0].labels
            "
          ></canvas>
        </div>
      </div>
      <div
        class="card d-flex flex-column rounded col-2 m-0 p-0"
        style="overflow: hidden"
      >
        <div class="bg-primary w-100 text-white">
          <h5 class="p-3 my-auto text-center">
            {{ "ME.NUMBER_INTERVENTION_GENDER" | translate }}
          </h5>
        </div>
        <div
          class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center h-100"
          style="border: solid 2px"
        >
          <canvas
            mdbChart
            [type]="'pie'"
            [datasets]="
              datasetGender == undefined ? datasetPlaceholder : datasetGender
            "
            [labels]="
              datasetGender == undefined
                ? datasetPlaceholder[0].labels
                : datasetGender[0].labels
            "
          ></canvas>
        </div>
      </div>
      <div
        class="card d-flex flex-column rounded col-2 p-0 m-0"
        style="overflow: hidden"
      >
        <div class="bg-primary w-100 text-white">
          <h5 class="p-3 my-auto text-center">
            {{ "ME.NUMBER_INTERVENTION_AREA" | translate }}
          </h5>
        </div>
        <div
          class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center h-100"
          style="border: solid 2px"
        >
          <h1 *ngIf="numberArea != undefined">
            {{ numberArea }}
          </h1>
          <h1 *ngIf="numberArea == undefined">
            {{ placeholderNumber }}
          </h1>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-4 border border-primary py-2 rounded-2">
    <div class="row px-2">
      <h4>
        {{ "ME.PROJECT_STATISTICS" | translate }}
      </h4>
    </div>
    <div class="row gap-2 justify-content-around">
      <div
        class="card d-flex flex-column rounded col-2 p-0 m-0"
        style="overflow: hidden"
      >
        <div class="bg-primary w-100 text-white">
          <h5 class="p-3 my-auto text-center">
            {{ "ME.NUMBER_INTERVENTION" | translate }}
          </h5>
        </div>
        <div
          class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center h-100"
          style="border: solid 2px"
        >
          <h1 *ngIf="numberInterventionsProject != undefined">
            {{ numberInterventionsProject }}
          </h1>
          <h1 *ngIf="numberInterventionsProject == undefined">
            {{ placeholderNumber }}
          </h1>
        </div>
      </div>

      <div
        class="card d-flex flex-column rounded col-2 m-0 p-0"
        style="overflow: hidden"
      >
        <div class="bg-primary w-100 text-white">
          <h5 class="p-3 my-auto text-center">
            {{ "ME.NUMBER_INTERVENTION_AGE" | translate }}
          </h5>
        </div>
        <div
          class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center h-100"
          style="border: solid 2px"
        >
          <canvas
            mdbChart
            [type]="'pie'"
            [datasets]="
              datasetAgeProject == undefined
                ? datasetPlaceholder
                : datasetAgeProject
            "
            [labels]="
              datasetAgeProject == undefined
                ? datasetPlaceholder[0].labels
                : datasetAgeProject[0].labels
            "
          ></canvas>
        </div>
      </div>
      <div
        class="card d-flex flex-column rounded col-2 m-0 p-0"
        style="overflow: hidden"
      >
        <div class="bg-primary w-100 text-white">
          <h5 class="p-3 my-auto text-center">
            {{ "ME.NUMBER_INTERVENTION_GENDER" | translate }}
          </h5>
        </div>
        <div
          class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center h-100"
          style="border: solid 2px"
        >
          <canvas
            mdbChart
            [type]="'pie'"
            [datasets]="
              datasetGenderProject == undefined
                ? datasetPlaceholder
                : datasetGenderProject
            "
            [labels]="
              datasetGenderProject == undefined
                ? datasetPlaceholder[0].labels
                : datasetGenderProject[0].labels
            "
          ></canvas>
        </div>
      </div>
      <div
        class="card d-flex flex-column rounded col-2 p-0 m-0"
        style="overflow: hidden"
      >
        <div class="bg-primary w-100 text-white">
          <h5 class="p-3 my-auto text-center">
            {{ "ME.NUMBER_INTERVENTION_AREA" | translate }}
          </h5>
        </div>
        <div
          class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center h-100"
          style="border: solid 2px"
        >
          <h1 *ngIf="numberAreaProject != undefined">
            {{ numberAreaProject }}
          </h1>
          <h1 *ngIf="numberAreaProject == undefined">
            {{ placeholderNumber }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="height: 100px"></div>
