<div class="container-fluid p-0 m-0 bg-light" style="height: 100vh">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-3">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <!-- TITLE -->
        <div class="d-flex align-items-center mt-2">
          <i
            class="bi bi-arrow-left-square-fill h1 pointer my-auto"
            (click)="goBack()"
          ></i>
          <div class="mx-3" style="height: 5rem; width: 5rem">
            <img
              [src]="workingArea?.imageUrl"
              class="w-100 h-100"
              style="object-fit: contain"
            />
          </div>
          <h1 class="fw-bold my-auto">
            {{ workingArea?.familyName }} {{ "WORKING_AREA.IN" | translate }}
            {{ workingArea?.country }}
          </h1>
        </div>
        <!-- TITLE -->
        <!-- FILTERS -->
        <div class="d-flex align-items-center" [formGroup]="filterForm!">
          <div class="d-flex">
            <div class="mx-1">
              <ng-select
                placeholder="{{ 'PLACEHOLDER.TYPE' | translate }}"
                formControlName="type"
                [searchable]="false"
                [clearable]="false"
              >
                <ng-option
                  *ngFor="let type of types"
                  [value]="type.traitsTypeID"
                  [mdbTooltip]="type.traitsType"
                >
                  {{ type.traitsType }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <!-- FILTERS -->
      </div>
    </div>
  </div>
  <div class="d-flex flex-row mt-3 mx-5">
    <div
      class="border-primary rounded"
      style="
        height: 50rem;
        width: 75%;
        border: solid 3px;
        position: relative;
        overflow: scroll;
      "
    >
      <div
        class="d-flex w-100 h-100 justify-content-center"
        *ngIf="rows.length == 0 && !loading && !isHeaderEmpty"
      >
        <disclaimer
          class="mt-4"
          text="{{ 'WORKING_AREA.SELECT_A_TYPE' | translate }}"
        ></disclaimer>
      </div>

      <div
        class="d-flex w-100 h-100 justify-content-center"
        *ngIf="isHeaderEmpty && !loading"
      >
        <disclaimer class="mt-4" text="WORKING_AREA.DISCLAIMER_1"></disclaimer>
      </div>

      <table *ngIf="columnHeaders.length != 0 && rows.length != 0 && !loading">
        <thead>
          <tr *ngIf="columnHeaders">
            <th
              class="bg-light border-primary rounded-5 shadow-2"
              style="z-index: 20"
            ></th>
            <th
              class="pointer px-2 py-0 rounded-5 shadow-2"
              *ngFor="
                let column of getColumnHeaderLivestock();
                index as columnIndex
              "
              [attr.colspan]="column.span"
              [ngClass]="
                selectedColumn == columnIndex
                  ? 'cell-selected text-white'
                  : 'bg-white'
              "
              (click)="getDescriptor(columnIndex)"
            >
              <div
                class="py-2 text-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
              >
                {{ column.name }}
              </div>
            </th>
          </tr>

          <tr
            *ngIf="
              columnHeaders &&
              workingArea?.ref === 'LIVESTOCK' &&
              columnHeaders[0].gender
            "
          >
            <th
              class="th-ga rounded-5 shadow-2 bg-white"
              style="z-index: 20"
            ></th>
            <th
              class="th-ga text-white border-primary pointer px-2 py-0"
              *ngFor="let column of columnHeaders; index as columnIndex"
              [ngClass]="
                selectedColumns.includes(columnIndex)
                  ? 'cell-selected text-white'
                  : switchGenderColor(column.gender)
              "
              (click)="getDescriptorByGA(columnIndex, column.refID)"
            >
              <div
                class="py-2 text-center d-flex align-items-center justify-content-center"
                style="max-height: 2rem; min-height: 2rem"
              >
                <span
                  class="my-auto"
                  [mdbTooltip]="getToolTip(column) | translate"
                >
                  {{
                    column.age === "A"
                      ? ("WORKING_AREA.ADULT" | translate)
                      : ("WORKING_AREA.JUVENILE" | translate)
                  }}
                  <i
                    class="bi"
                    [ngClass]="
                      column.gender === 'F'
                        ? 'bi-gender-female'
                        : 'bi-gender-male'
                    "
                  ></i>
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="rows">
          <tr *ngFor="let row of rows; index as rowIndex">
            <th
              class="rounded-5 shadow-2 pointer"
              style="z-index: 10"
              [ngClass]="
                selectedRow == rowIndex
                  ? 'cell-selected text-white'
                  : 'bg-white'
              "
              (click)="getGeneralInfos(row.fgdID, row.refID, rowIndex)"
            >
              <h6 class="my-auto">{{ row.name }}</h6>
            </th>
            <td
              class="text-center pointer m-1 rounded-5 shadow-2"
              [ngClass]="getBackgroundCell(rowIndex, answerIndex)"
              *ngFor="let answer of row.answers; index as answerIndex"
              (click)="getModify(answer, rowIndex, answerIndex)"
            >
              <div
                class="d-flex align-items-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
              >
                <span class="my-auto w-100 text-center">
                  {{ answer.answer }}
                </span>
                <i
                  class="bi bi-chat-left-text-fill my-auto"
                  *ngIf="answer.hasNote"
                ></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <loading class="w-100 h-100 mask" *ngIf="loading"></loading>
    </div>
    <div
      class="border-primary rounded d-flex flex-column align-items-center justify-content-between px-3 mx-2"
      style="width: 25%; height: 50rem; border: 3px solid"
    >
      <div class="d-flex flex-column w-100">
        <h4 class="mx-auto mt-2">{{ "GRID.TABLE_TITLE" | translate }}</h4>
        <div class="bg-primary w-100" style="height: 2px"></div>
        <div
          class="d-flex flex-column"
          *ngIf="!isGeneralInfosOpened && !isLegendOpened && !isModifyOpened"
        >
          <h4 class="mx-auto mt-2">{{ "GRID.INSTRUCTIONS" | translate }}</h4>
          <ul>
            <li class="h5 mt-2">{{ "WORKING_AREA.POINT_1" | translate }}</li>
            <li class="h5 mt-4">
              {{
                (workingArea!.ref === "CROPS"
                  ? "WORKING_AREA.POINT_2"
                  : "WORKING_AREA.POINT_2_BREED"
                ) | translate
              }}
            </li>
            <li class="h5 mt-4">
              {{ "WORKING_AREA.POINT_3" | translate }}
            </li>
            <li class="h5 mt-4">
              {{ "WORKING_AREA.POINT_4" | translate }}
            </li>
            <li class="h5 mt-4">
              {{
                (workingArea!.ref === "CROPS"
                  ? "WORKING_AREA.POINT_5"
                  : "WORKING_AREA.POINT_5_BREED"
                ) | translate
              }}
            </li>
            <li class="h5 mt-4">
              {{ "WORKING_AREA.POINT_6" | translate }}
            </li>
            <li class="h5 mt-4">
              {{ "WORKING_AREA.POINT_7" | translate }}
            </li>
          </ul>
        </div>
        <div
          class="d-flex flex-column mt-2 text-center"
          *ngIf="isGeneralInfosOpened"
        >
          <i class="bi bi-dot h3 mb-0"></i>
          <h6>{{ "GRID.AGRO_ZONE" | translate }}:</h6>
          <h6>{{ varietyInfo?.agroZone }}</h6>
          <i class="bi bi-dot h3 mt-2 mb-0"></i>
          <h6>{{ "GRID.SITE_NAME" | translate }}:</h6>
          <h6>{{ varietyInfo?.siteName }}</h6>
          <i class="bi bi-dot h3 mt-2 mb-0"></i>
          <h6>{{ "GRID.FGD_ID" | translate }}:</h6>
          <h6>{{ varietyInfo?.fgdID }}</h6>
          <i class="bi bi-dot h3 mt-2 mb-0"></i>
          <h6>{{ "GRID.FGD_TYPE" | translate }}:</h6>
          <h6>{{ varietyInfo?.fgdAge }} - {{ varietyInfo?.fgdGender }}</h6>
          <i class="bi bi-dot h3 mt-2 mb-0"></i>
          <h6>{{ varietyInfo?.brought }}</h6>
          <i class="bi bi-dot h3 mt-2 mb-0"></i>
          <h6>{{ varietyInfo?.varietyType }}</h6>
        </div>

        <div class="d-flex flex-column mt-2 text-center" *ngIf="isLegendOpened">
          <answering-descriptor-legend [descriptor]="descriptor">
          </answering-descriptor-legend>
        </div>

        <div
          class="w-100 h-100 d-flex flex-column mt-2"
          *ngIf="isModifyOpened"
          [ngSwitch]="answerInfo?.answerType"
          [formGroup]="modifyForm!"
        >
          <div class="d-flex flex-column mt-1">
            <h4 class="text-center">{{ "GRID.MODIFY_ANSWER" | translate }}</h4>

            <div class="px-2" *ngSwitchCase="'MULTIPLE'">
              <div
                class="form-check py-1"
                *ngFor="let value of answerInfo?.values"
              >
                <label class="form-check-label" style="white-space: nowrap">
                  <input
                    mdbRadio
                    class="form-check-input"
                    type="radio"
                    [value]="value.uuid"
                    formControlName="choiceValue"
                  />
                  <span class="" style="white-space: normal">
                    {{ value.valueText }}
                  </span>
                </label>
              </div>
            </div>

            <div
              class="px-2"
              *ngSwitchCase="'MULTIPLE_LIST'"
              formArrayName="choiceMultiple"
            >
              <div *ngFor="let input of choiceMultiple.controls; index as i">
                <div class="d-flex" [formGroupName]="i">
                  <input
                    mdbCheckbox
                    class="form-check-input"
                    type="checkbox"
                    formControlName="selected"
                  />
                  <span class="ps-1 my-auto">
                    {{ input.get("label")?.value }}
                  </span>
                </div>
              </div>
            </div>

            <div
              class="px-2"
              *ngSwitchCase="'MULTIPLE_LIST_SUSCEPTIBILITY'"
              formArrayName="choiceMultiple"
            >
              <div
                *ngFor="let input of choiceMultiple.controls; index as i"
                [formGroupName]="i"
              >
                <div class="d-flex flex-column">
                  <div class="d-flex">
                    <input
                      mdbCheckbox
                      class="form-check-input"
                      type="checkbox"
                      formControlName="selected"
                    />
                    <span class="ps-1 my-auto">
                      {{ input.get("label")?.value }}
                    </span>
                  </div>
                  <div
                    class="d-flex align-items-center mb-2"
                    *ngIf="input.get('selected')?.value"
                  >
                    <span class="me-2 my-auto">
                      {{ "WORKING_AREA.SUSCEP" | translate }}
                    </span>
                    <div class="my-auto">
                      <ng-select
                        class="ms-2"
                        formControlName="quantity"
                        [clearable]="false"
                        [searchable]="false"
                      >
                        <ng-option value="L">
                          {{ "WORKING_AREA.LOW" | translate }}
                        </ng-option>
                        <ng-option value="M">
                          {{ "WORKING_AREA.MEDIUM" | translate }}
                        </ng-option>
                        <ng-option value="H">
                          {{ "WORKING_AREA.HIGHT" | translate }}
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="px-2"
              *ngSwitchCase="'MULTIPLE_LIST_CONTENT'"
              formArrayName="choiceMultiple"
            >
              <div
                *ngFor="let input of choiceMultiple.controls; index as i"
                [formGroupName]="i"
              >
                <div class="d-flex flex-column">
                  <div class="d-flex">
                    <input
                      mdbCheckbox
                      class="form-check-input"
                      type="checkbox"
                      formControlName="selected"
                    />
                    <span class="ps-1 my-auto">
                      {{ input.get("label")?.value }}
                    </span>
                  </div>
                  <div
                    class="d-flex align-items-center mb-2"
                    *ngIf="input.get('selected')?.value"
                  >
                    <span class="me-2 my-auto">
                      {{ "WORKING_AREA.CONTENT" | translate }}
                    </span>
                    <div class="my-auto">
                      <ng-select
                        class="ms-2"
                        formControlName="quantity"
                        [searchable]="false"
                        [clearable]="false"
                      >
                        <ng-option value="NO_LOW">
                          {{ "WORKING_AREA.NO_LOW" | translate }}
                        </ng-option>
                        <ng-option value="GOOD">
                          {{ "WORKING_AREA.GOOD" | translate }}
                        </ng-option>
                        <ng-option value="DONT">
                          {{ "WORKING_AREA.DONT" | translate }}
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'TIME'">
              <div>
                <input
                  type="text"
                  class="form-control"
                  formControlName="textValue"
                />
              </div>
            </div>

            <div *ngSwitchCase="'TEXT'">
              <div>
                <input
                  type="text"
                  class="form-control"
                  formControlName="textValue"
                />
              </div>
            </div>

            <div *ngSwitchCase="'NUMBER'">
              <div>
                <input
                  type="number"
                  class="form-control"
                  formControlName="nValue"
                />
              </div>
            </div>

            <div
              class="d-flex justify-content-center"
              *ngSwitchCase="'BOOLEAN'"
            >
              <div class="form-check form-check-inline">
                <input
                  mdbCheckbox
                  class="form-check-input"
                  type="radio"
                  id="checkYes"
                  [value]="true"
                />
                <label class="form-check-label" for="checkYes">
                  {{ "GRID.YES" | translate }}
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  mdbradio
                  class="form-check-input"
                  type="radio"
                  id="checkNo"
                  [value]="false"
                />
                <label class="form-check-label" for="checkNo">
                  {{ "GRID.NO" | translate }}
                </label>
              </div>
            </div>

            <div class="mt-2">
              <textarea
                class="form-control"
                placeholder="{{ 'PLACEHOLDER.LEAVE_NOTE' | translate }}"
                rows="2"
                formControlName="note"
              ></textarea>
            </div>

            <div class="btn btn-success mx-5 my-3" (click)="save()">
              {{ "BUTTONS.SAVE" | translate }}
            </div>
            <div *ngIf="selectedAnswer?.lastUpdateBy">
              <p class="mb-0">
                Last Edit on: {{ selectedAnswer?.lastUpdate | date: "short" }}
              </p>
              <p class="mb-0">By: {{ selectedAnswer?.lastUpdateBy }}</p>
            </div>
          </div>
          <div class="bg-primary w-100 mx-auto my-3" style="height: 2px"></div>
        </div>
      </div>
      <div class="d-flex justify-content-center mb-4">
        <div class="btn btn-primary me-2" (click)="openManageDescriptors()">
          {{ "WORKING_AREA.MANAGE_DESCRIPTORS" | translate }}
        </div>
        <div
          class="btn btn-primary ms-2"
          (click)="
            openManageVarieties(
              workingArea!.ref === 'CROPS'
                ? 'WORKING_AREA.MANAGE_VARIETIES'
                : 'WORKING_AREA.MANAGE_BREEDS'
            )
          "
        >
          {{
            (workingArea!.ref === "CROPS"
              ? "WORKING_AREA.MANAGE_VARIETIES"
              : "WORKING_AREA.MANAGE_BREEDS"
            ) | translate
          }}
        </div>
      </div>
    </div>
  </div>
</div>
