import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-diversity-benefit-answer',
  templateUrl: './modal-diversity-benefit-answer.component.html',
  styleUrls: ['./modal-diversity-benefit-answer.component.scss'],
})
export class ModalDiversityBenefitAnswerComponent implements OnInit {
  valueForm?: FormGroup;
  url?: string;
  title?: string;
  refID?: string;
  value: any;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalDiversityBenefitAnswerComponent>
  ) {}

  ngOnInit(): void {
    this.valueForm = new FormGroup({
      value: new FormControl(this.value),
    });
  }

  saveValue() {
    this.api
      .setDiversityBenefitAnswer(this.url!, this.refID!, this.v?.value)
      .subscribe((value) => {
        this.modalRef.close(this.v?.value);
      });
  }

  get v() {
    return this.valueForm?.get('value');
  }
}
