import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalMeInterventionAnswersComponent } from 'src/app/modals/projects/modal-me-intervention-answers/modal-me-intervention-answers.component';
import { Specie } from 'src/app/models/project';
import { Select } from 'src/app/models/select';
import { ProjectDiversityBenefitComponent } from '../components/project-diversity-benefit/project-diversity-benefit.component';

interface InterventionMe {
  columns: any[];
}

@Component({
  selector: 'project-me',
  templateUrl: './project-me.component.html',
  styleUrls: ['./project-me.component.scss'],
})
export class ProjectMeComponent implements OnInit {
  filterForm?: FormGroup;
  state = 0;
  loading = false;
  species?: Select[];
  projectID?: string;

  dashboard: any;

  kHeight = 15;
  kMargin = 0.25;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.projectID = this.route.parent?.snapshot.paramMap.get('id') ?? '';
    this.filterForm = new FormGroup({
      specie: new FormControl(null),
    });
    this.getSpecies();
  }

  apiCall() {
    this.loading = true;
    this.api
      .getDashboardME(this.projectID!, this.specie?.value)
      .subscribe((value) => {
        this.loading = false;
        this.dashboard = value as any;
      });
  }

  getSpecies() {
    this.api.getFamiliesByProject(this.projectID!).subscribe((value) => {
      this.species = value as Select[];
      this.specie?.setValue(this.species[0].value);
      this.apiCall();
    });
  }

  onStateChange(value: number) {
    // console.log(value);
    this.state = value;
  }

  openDiversityBenefit() {
    this.modalService.open(ProjectDiversityBenefitComponent, {
      modalClass: 'modal-fullscreen',
      data: {
        families: this.species,
        familySelected: this.specie?.value!,
        periods: this.dashboard.periods,
        projectID: this.projectID,
      },
    });
  }

  openInterventionAnswers(refID: string, title: string) {
    this.api.getMeIntervention(refID).subscribe((value: any) => {
      this.modalService.open(ModalMeInterventionAnswersComponent, {
        data: {
          answers: value.answers,
          title: title,
        },
        modalClass: 'modal-lg',
      });
    });
  }

  get specie() {
    return this.filterForm?.get('specie');
  }
}
