<div class="container-fluid p-5 m-0 bg-light d-flex flex-column">
  <div class="d-flex align-items-center mb-3" [formGroup]="filterForm!">
    <i class="bi bi-tools h1 my-auto"></i>
    <h1 class="fw-bold mx-2 my-auto">
      {{ "WORKING_AREA.WORKING_AREA" | translate }}
    </h1>
    <div
      class="btn btn-primary my-auto mx-2"
      *ngIf="phase == 'phase1'"
      (click)="backToCrop()"
    >
      <i class="bi bi-caret-left-fill me-1"></i>
      {{ partnerRefItems?.backButton || "" | translate }}
    </div>
    <div class="my-auto">
      <input
        type="text"
        class="form-control"
        placeholder="{{
          (selectedCrop
            ? partnerRefItems?.filterPhase1 || ''
            : partnerRefItems?.filterPhase0 || ''
          ) | translate
        }}"
        formControlName="filter"
      />
    </div>
  </div>
  <div class="d-flex flex-wrap w-100">
    <div
      class="bg-white shadow rounded p-3 m-4"
      style="width: 23rem; height: 10rem; position: relative; overflow: hidden"
      *ngFor="let entity of entities"
    >
      <div
        class="w-100 h-100 d-flex position-absolute top-1 flex-row justify-content-end align-items-end p-3"
        style="position: absolute; z-index: 9"
      >
        <img
          [src]="entity.imageUrl"
          style="height: 9rem; width: 9rem; opacity: 0.3"
        />
      </div>
      <div
        class="d-flex flex-column h-100 position-relative"
        style="z-index: 10"
      >
        <h4>{{ entity.name }}</h4>
        <div
          class="btn btn-primary mt-auto"
          [routerLink]="'/u/wa/' + entity.ref + '/' + entity.refID"
        >
          {{
            entity.ref == "c"
              ? ("WORKING_AREA.SELECT_VARIETY" | translate)
              : ("WORKING_AREA.SELECT_BREED" | translate)
          }}
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mt-5">
      <div
        class="h-100 mt-3 mx-5"
        style="max-width: 65rem; min-width: 65rem; overflow: hidden"
      >
        <div class="d-flex flex-row w-100">
          <div
            class="h-100 pb-2"
            style="min-width: 25rem; max-width: 25rem"
            [ngClass]="getAnimationClass()"
          >
            <div
              class="d-flex flex-column"
              style="height: 47rem; border-bottom: 3px solid rgb(79, 79, 79)"
            >
              <div class="d-flex flex-row mt-3 align-items-center">
                <div style="height: 5rem; width: 5rem">
                  <img
                    class="w-100 h-100"
                    style="object-fit: contain"
                    [src]="'../../assets/images/' + partnerRefItems?.icon"
                  />
                </div>
                <h2 class="fw-bold mx-2 my-auto">
                  {{ partnerRefItems?.title || "" | translate }}
                </h2>
              </div>
              <div
                class="bg-primary mt-3 mx-auto pb-1 w-100"
                style="height: 3px"
              ></div>
              <div class="d-flex flex-column py-2" style="overflow-y: scroll">
                <div
                  class="d-flex flex-row align-items-center justify-content-between p-2 mt-2 mx-3 rounded hover"
                  *ngFor="let crop of crops; index as i"
                  (click)="onCropSelected(crop, i)"
                >
                  <div class="d-flex">
                    <div class="my-auto" style="height: 3.2rem; width: 3.2rem">
                      <img
                        class="w-100 h-100"
                        [src]="crop.imageUrl"
                        style="object-fit: contain"
                      />
                    </div>
                    <h4 class="mx-2 my-auto h5">{{ crop.name }}</h4>
                  </div>
                  <i class="bi bi-caret-right-fill h4 my-auto"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            class="h-100 pb-2"
            style="min-width: 25rem; max-width: 25rem"
            [ngClass]="getAnimationClass()"
          >
            <div
              class="d-flex flex-column"
              style="height: 47rem; border-bottom: 3px solid rgb(79, 79, 79)"
            >
              <div class="d-flex flex-column">
                <div class="d-flex flex-row mt-3 align-items-center">
                  <div
                    class="d-flex justify-content-center align-items-center mx-2"
                    style="height: 5rem; width: 5rem; border-radius: 50%"
                  >
                    <img
                      [src]="selectedCrop.imageUrl"
                      class="w-100 h-100"
                      style="object-fit: cover"
                      *ngIf="selectedCrop"
                    />
                  </div>
                  <h2 class="fw-bold my-auto" *ngIf="selectedCrop">
                    {{ selectedCrop.name }}
                  </h2>
                </div>
                <div class="d-flex mt-3 fade-buttons" *ngIf="phase == 'phase1'">
                  <div
                    class="btn btn-primary my-auto me-1"
                    (click)="openImportVariety()"
                  >
                    {{ partnerRefItems?.importButton || "" | translate }}
                  </div>
                  <div
                    class="btn btn-success my-auto ms-1"
                    (click)="openAddVariety(selectedCrop?.refID || '')"
                  >
                    {{ partnerRefItems?.addButton || "" | translate }}
                  </div>
                </div>
              </div>
              <div
                class="bg-primary mt-3 mx-auto w-100 pb-1"
                style="height: 3px"
              ></div>
              <div
                class="d-flex flex-column py-2"
                style="overflow-y: scroll"
                *ngIf="varieties"
              >
                <div
                  class="d-flex flex-row pointer align-items-center my-2"
                  *ngFor="let variety of varieties; index as i"
                >
                  <i
                    class="bi bi-x-square-fill text-danger my-auto hover-button"
                    (click)="openDeleteVariety(variety, i)"
                  ></i>
                  <div
                    class="d-flex flex-row w-100 align-items-center justify-content-between p-2 ms-1 me-3 rounded my-auto"
                    [ngClass]="
                      variety.selected ? 'text-white bg-secondary' : 'hover'
                    "
                    (click)="onVarietySelect(variety, i)"
                  >
                    <h4 class="mx-2 my-auto h5">
                      {{ variety.name }} &nbsp;
                      <em class="h6" *ngIf="variety.varietyType">
                        ({{ variety.varietyType }})
                      </em>
                    </h4>
                    <i class="bi bi-caret-right-fill h4 my-auto"></i>
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-items-center justify-content-center h-100"
                *ngIf="phase === 'phase0'"
              >
                <table-placeholder
                  [text]="partnerRefItems?.selectItem"
                  [image]="'/assets/images/' + partnerRefItems?.icon || ''"
                ></table-placeholder>
              </div>
              <div
                class="d-flex align-items-center justify-content-center h-100"
                *ngIf="varieties?.length == 0 && phase === 'phase1'"
              >
                <disclaimer [text]="partnerRefItems?.noItem || ''"></disclaimer>
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column justify-content-end pb-2"
            style="min-width: 40rem; max-width: 40rem"
            [ngClass]="getAnimationClass()"
            *ngIf="selectedVarieties.length > 0"
          >
            <div class="d-flex flex-column">
              <form class="d-flex flex-column ms-3" [formGroup]="editingForm!">
                <div class="d-flex flex-row align-items-center mb-4">
                  <h3 class="ms-2 my-auto">
                    {{ "WORKING_AREA.EDITING" | translate }}
                  </h3>
                  <div class="mx-3 my-auto">
                    <ng-select
                      formControlName="editVariety"
                      [items]="selectedVarieties"
                      bindLabel="name"
                      bindValue="varietyID"
                      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
                      (change)="editSelectedVariety($event)"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="d-flex flex-column px-2 mb-2">
                  <h4>{{ switchItemEditName() | translate }}</h4>
                  <div class="d-flex align-items-start">
                    <input
                      type="text"
                      class="form-control w-100"
                      formControlName="varietyName"
                    />
                    <div
                      class="btn btn-success my-auto ms-2 flex"
                      (click)="editName()"
                    >
                      {{ "BUTTONS.EDIT" | translate }}
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column px-2">
                  <h4>{{ switchItemEditType() | translate }}</h4>
                  <div class="d-flex align-items-start">
                    <ng-select
                      class="w-100"
                      formControlName="varietyType"
                      [searchable]="false"
                      [clearable]="false"
                    >
                      <ng-option
                        *ngFor="let material of materials"
                        [value]="material"
                      >
                        {{ material.typeLang }}
                      </ng-option>
                    </ng-select>
                    <div
                      class="btn btn-success my-auto ms-2"
                      (click)="editName()"
                    >
                      {{ "BUTTONS.EDIT" | translate }}
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row my-4 ms-2">
                  <div
                    class="btn btn-primary me-1"
                    (click)="openChangeLocalNames()"
                  >
                    {{ "WORKING_AREA.CHANGE_LOCAL" | translate }}
                  </div>
                  <div
                    class="btn btn-primary ms-1"
                    (click)="openChangePhotos()"
                  >
                    {{ "WORKING_AREA.CHANGE_PHOTO" | translate }}
                  </div>
                </div>
              </form>
              <div
                class="bg-secondary my-3 mx-auto w-100"
                style="height: 3px"
              ></div>
              <div class="d-flex flex-column align-items-center mt-2">
                <div class="mx-auto" style="height: 6rem; width: 6rem">
                  <img
                    [src]="'/assets/images/' + partnerRefItems?.icon || ''"
                    class="w-100 h-100"
                    style="object-fit: contain"
                  />
                </div>
                <h2 class="my-2 mx-auto">
                  {{ "WORKING_AREA.SELECTED" | translate }}
                  {{ selectedVarieties.length }}
                  {{
                    selectedVarieties.length == 1
                      ? (partnerRefItems?.itemType || "" | translate)
                      : (partnerRefItems?.itemTypes || "" | translate)
                  }}
                </h2>
              </div>
            </div>
            <div
              class="btn btn-success mx-auto my-2"
              (click)="startAnswering()"
            >
              {{ "WORKING_AREA.ANSWERING" | translate }}
            </div>
            <div
              class="bg-primary mt-3 mx-auto w-100"
              style="height: 3px"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
