<!-- <div class="btn btn-primary" *ngIf="isRtl"></div> -->

<div class="container-fluid m-0 p-0 d-flex flex-row bg-light">
  <side-nav *ngIf="showSideNav"></side-nav>

  <div style="height: 100vh">
    <mdb-sidenav-layout *ngIf="!showSideNav" class="h-100">
      <mdb-sidenav #sidenav="mdbSidenav" [width]="280">
        <side-nav [sideNav]="sidenav"></side-nav>
      </mdb-sidenav>
      <mdb-sidenav-content #sidenavContent>
        <!-- Toggler -->
        <div
          class="btn btn-primary p-0 px-2 mx-3 my-3"
          (click)="sidenav.toggle()"
        >
          <i class="bi bi-list h3"></i>
        </div>
        <!-- Toggler -->
      </mdb-sidenav-content>
    </mdb-sidenav-layout>
  </div>

  <div class="h-100" style="width: 100vw">
    <router-outlet class="h-100" style="width: 100vw"></router-outlet>
  </div>
</div>
