import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';
import { Variety } from 'src/app/models/variety';
import { WorkingArea } from 'src/app/working-area/answering-page/answering-page.component';

@Component({
  selector: 'modal-manage-varieties',
  templateUrl: './modal-manage-varieties.component.html',
  styleUrls: ['./modal-manage-varieties.component.scss'],
})
export class ModalManageVarietiesComponent implements OnInit {
  workingArea?: WorkingArea;
  title?: string;
  loading: boolean = false;
  varieties: Select[] = [];
  selectedVarietiesID: string[] = [];

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalManageVarietiesComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.getVarieties();
  }

  getVarieties() {
    if (this.workingArea) {
      this.api
        .getVarietiesByWorkingArea(this.workingArea?.workID)
        .subscribe((value) => {
          this.varieties = value as Select[];
        });
    }
  }

  onSelect(i: number, varietyID: string) {
    this.varieties[i].selected = !this.varieties[i].selected;
  }

  save() {
    this.selectedVarietiesID = [];
    this.varieties.forEach((item) => {
      if (item.selected) {
        this.selectedVarietiesID.push(item.value);
      }
    });
    this.api
      .setVarietiesByWorkingArea(
        this.workingArea!.workID,
        this.selectedVarietiesID
      )
      .subscribe(() => {
        this.modalRef.close(true);
      });
  }
}
