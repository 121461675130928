import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { rtlLangs } from 'src/app/models/global-variables';

@Component({
  selector: 'calendar-side-bar',
  templateUrl: './calendar-side-bar.component.html',
  styleUrls: ['./calendar-side-bar.component.scss'],
})
export class CalendarSideBarComponent implements OnInit {
  days: number[] = [];
  direction?: string;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.loopWeek();
  }

  checkRtl() {
    const currentLang = this.translate.currentLang;
    if (rtlLangs.includes(currentLang)) {
      this.direction = 'rtl';
    } else {
      this.direction = 'ltr';
    }
  }

  loopWeek() {
    const myDate = new Date();
    let newDate: any;
    for (let i = 0; i < 15; i++) {
      newDate = new Date();
      newDate = newDate.setDate(myDate.getDate() + i);
      this.days.push(newDate);
    }
  }
}
