<div class="modal-content">
  <div class="modal-body m-0 p-0" style="height: 100vh">
    <div class="bg-white h-100 d-flex flex-column justify-content-between py-5">
      <div class="mx-3 d-flex flex-column mb-2 justify-content-between">
        <div class="d-flex align-items-center">
          <i class="bi bi-gear-fill h2 my-auto"></i>
          <h2 class="mb-1 mx-2 my-auto">
            {{ "SETTINGS.SETTINGS" | translate }}
          </h2>
        </div>
        <div class="bg-primary w-75 m-0" style="height: 2px"></div>
      </div>
      <div class="d-flex flex-column my-2 mx-3">
        <div class="btn btn-primary my-2" (click)="openQrCode()">
          {{ "SETTINGS.ID&QR" | translate }}
        </div>
        <div class="btn btn-primary my-2" (click)="openEditProfile()">
          {{ "SETTINGS.EDIT_PROFILE" | translate }}
        </div>

        <div class="btn btn-primary my-2" (click)="openChangePassword()">
          {{ "SETTINGS.CHANGE_PASSWORD" | translate }}
        </div>

        <div class="btn btn-primary my-2" (click)="openCountries()">
          {{ "SETTINGS.CHANGE_COUNTRY" | translate }}
        </div>

        <!-- <p class="h5">Change Language:</p> -->
        <div
          class="btn btn-primary my-2"
          (click)="openLang()"
          *ngIf="!isLangOpen"
        >
          {{ "SETTINGS.CHANGE_LANGUAGE" | translate }}
        </div>
        <div *ngIf="isLangOpen" class="my-2">
          <select
            class="form-select"
            [(ngModel)]="currentLang"
            (ngModelChange)="onSelect($event)"
          >
            <option *ngFor="let lan of langs" [value]="lan">
              {{ "LANG." + lan | translate }}
            </option>
          </select>
        </div>

        <u>
          <a [href]="goToPrivacy()" target="_blank">
            <p class="h6 hover mt-2">{{ "SETTINGS.PRIVACY" | translate }}</p>
          </a>
        </u>
        <u>
          <a [href]="goToTerms()" target="_blank">
            <p class="h6 hover">{{ "SETTINGS.TERMS" | translate }}</p>
          </a>
        </u>
      </div>
      <div class="mx-auto mt-2">
        <u class="hover">
          <p (click)="modalRef.close()" class="h5">
            {{ "SETTINGS.CLOSE" | translate }}
          </p>
        </u>
      </div>
    </div>
  </div>
</div>
