import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { ModalFileRequestedComponent } from 'src/app/modals/projetcs/modal-file-requested/modal-file-requested.component';
import { FileCreated } from 'src/app/models/file-created';
import { Pageable } from 'src/app/models/pageable';
import {
  ProjectPageListener,
  ProjectPermissions,
} from 'src/app/models/project-page-helper';
import { RequestFile } from 'src/app/models/request-file';

export interface RequestFileCategories {
  GENERAL: RequestFile[];
  FGD: RequestFile[];
  HHS: RequestFile[];
  KIS: RequestFile[];
}

@Component({
  selector: 'project-data-collected-list',
  templateUrl: './project-data-collected-list.component.html',
  styleUrls: ['./project-data-collected-list.component.scss'],
})
export class ProjectDataCollectedListComponent implements OnInit, OnDestroy {
  filterForm?: UntypedFormGroup;
  requestFileForm?: UntypedFormGroup;

  lang$?: Subscription;

  projectID?: string;
  fileType: string = 'xlsx';

  requesGeneralFiles: RequestFile[] = [];
  requesFGDFiles: RequestFile[] = [];
  requesHHSFiles: RequestFile[] = [];

  pageable?: Pageable;
  fileCreated?: FileCreated[];

  projectPageHelper$?: Subscription;
  projectPermissions?: ProjectPermissions;

  page: number = 0;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private modalService: MdbModalService,
    private projectPageListener: ProjectPageListener
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null, []),
      filterFile: new UntypedFormControl(null, []),
    });

    this.requestFileForm = new UntypedFormGroup({
      select: new UntypedFormControl(null, []),
    });

    this.projectPageHelper$ = this.projectPageListener.data.subscribe(
      (data) => {
        if (data.projectPermissions) {
          this.projectPermissions = data.projectPermissions;
        }
      }
    );

    this.projectID = this.route.parent?.snapshot.paramMap.get('id') as string;

    this.apiCall();

    this.filterFile?.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      if (this.projectID) this.apiCall();
    });

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.apiCall();
    });
  }

  ngOnDestroy(): void {
    this.projectPageHelper$?.unsubscribe();
    this.lang$?.unsubscribe();
  }

  apiCall() {
    this.pageable = undefined;
    if (this.projectID) {
      this.api
        .getFileCreated(this.projectID, this.page, this.filterFile?.value)
        .subscribe((value) => {
          this.pageable = value as Pageable;
          this.fileCreated = this.pageable.content;
          if (this.page > this.pageable.totalPages) {
            this.page = 0;
            this.apiCall();
          }
          // this.fileCreated = list.reverse();
        });
    }
  }

  requestAgain(
    refID: string,
    index: number,
    cleaned: boolean,
    showNote: boolean
  ) {
    this.api.requestAgainFile(refID, showNote, cleaned).subscribe((value) => {
      this.fileCreated![index] = value as FileCreated;
    });
  }

  openRequestedFile(
    fileExt: string,
    projectFileTypeID: string,
    refID: string,
    speciesID: string | null
  ) {
    this.modalService.open(ModalFileRequestedComponent, {
      modalClass: 'modal-lg',
      data: {
        fileExt: fileExt,
        projectFileTypeID: projectFileTypeID,
        refID: refID,
        speciesID: speciesID,
      },
    });
  }

  onPageClick(page: number, el: HTMLElement) {
    this.page = page;
    this.apiCall();
    // el.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0, 0);
  }

  selectFileTypeHHS(i: number, ext: string) {
    this.requesHHSFiles[i].selectedExtension = ext;
  }

  selectFileTypeFGD(i: number, ext: string) {
    this.requesFGDFiles[i].selectedExtension = ext;
  }

  selectFileTypeGeneral(i: number, ext: string) {
    this.requesGeneralFiles[i].selectedExtension = ext;
  }

  get filter() {
    return this.filterForm?.get('filter');
  }

  get filterFile() {
    return this.filterForm?.get('filterFile');
  }

  get select() {
    return this.requestFileForm?.get('select');
  }
}
