<div class="modal-header">{{ "OT_CLEANING.HISTORY" | translate }}</div>
<div
  class="modal-body d-flex flex-column"
  style="max-height: 35rem; overflow-y: auto"
>
  <div
    class="d-flex flex-column w-100"
    *ngFor="let item of history; last as last"
  >
    <span>{{ "OT_CLEANING.USER" | translate }}: {{ item.user }}</span>
    <span class="mb-2">
      {{ "OT_CLEANING.DATE" | translate }}:
      {{ item.date | date: "MMM dd, YYYY HH:mm:ss" }}
    </span>
    <div class="d-flex justify-content-around w-100">
      <div class="d-flex flex-column">
        <span class="text-center">{{ "OT_CLEANING.FROM" | translate }}:</span>
        <div class="rounded px-4 py-2 bg-light">
          <span> {{ item.from }} </span>
        </div>
      </div>
      <i class="bi-arrow-right h4 my-auto"></i>
      <div class="d-flex flex-column">
        <span class="text-center">{{ "OT_CLEANING.TO" | translate }}:</span>
        <div class="rounded px-4 py-2 bg-light">
          <span> {{ item.to }} </span>
        </div>
      </div>
    </div>
    <hr class="mt-2 mb-4" *ngIf="!last" />
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.BACK" | translate }}
  </div>
</div>
