import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { State, Store } from '@ngrx/store';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { AppState } from 'src/app/auth/reducer';
import { getUser } from 'src/app/auth/store/auth.selectors';

@Component({
  selector: 'modal-qr-code',
  templateUrl: './modal-qr-code.component.html',
  styleUrls: ['./modal-qr-code.component.scss'],
})
export class ModalQrCodeComponent implements OnInit {
  uuid?: string;

  constructor(
    private store: Store<AppState>,
    public modalRef: MdbModalRef<ModalQrCodeComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  elementType: NgxQrcodeElementTypes = NgxQrcodeElementTypes.URL;
  correction: NgxQrcodeErrorCorrectionLevels =
    NgxQrcodeErrorCorrectionLevels.HIGH;

  ngOnInit(): void {
    this.store.select(getUser).subscribe((user) => {
      this.uuid = user?.uuid;
    });
  }
}
