import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'stats-side-bar',
  templateUrl: './stats-side-bar.component.html',
  styleUrls: ['./stats-side-bar.component.scss'],
})
export class StatsSideBarComponent implements OnInit {
  count: any;

  constructor(private api: ApiService, private router: Router) {}

  ngOnInit(): void {
    this.api.getDashboardCount().subscribe((value) => {
      this.count = value;
    });
  }

  datasets = [
    {
      label: 'Projects',
      data: [2112, 2343, 2545, 3423, 2365, 1985, 987],
      backgroundColor: [
        'rgba(200, 117, 54, 0.5)',
        'rgba(53, 90, 42, 0.5)',
        'rgba(103, 177, 67, 0.5)',
        'rgba(200, 117, 54, 0.5)',
        'rgba(53, 90, 42, 0.5)',
        'rgba(103, 177, 67, 0.5)',
        'rgba(67, 76, 164, 0.2)',
      ],
    },
  ];

  labels = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  goToCrop() {
    this.router.navigateByUrl('/u/database/crop');
  }

  goToLivestock() {
    this.router.navigateByUrl('/u/database/livestock');
  }

  goToAquaculture() {
    this.router.navigateByUrl('/u/database/aquaculture');
  }
}
