import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'network-institution',
  templateUrl: './network-institution.component.html',
  styleUrls: ['./network-institution.component.scss'],
})
export class NetworkInstitutionComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  types: any[] = [];
  subTypes: any[] = [];

  constructor(private api: ApiService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.apiCall();

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.apiCall();
    });
  }

  apiCall() {
    this.api.getInstitutionType().subscribe((data) => {
      this.types = data as any[];
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  selectCategory(i: number) {
    this.types?.filter((value) => {
      value.selected = false;
    });
    this.subTypes?.filter((value) => {
      value.selected = false;
    });
    this.types[i].selected = true;
    this.subTypes = this.types[i].subtypes;
  }
}
