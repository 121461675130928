import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { VarietyCleaning } from 'src/app/models/variety-cleaning.types';

@Component({
  selector: 'modal-rename-cleaning',
  templateUrl: './modal-rename-cleaning.component.html',
  styleUrls: ['./modal-rename-cleaning.component.scss'],
})
export class ModalRenameCleaningComponent implements OnInit {
  renameForm?: UntypedFormGroup;

  projectID?: string;
  varietiesSelected?: VarietyCleaning[];

  error?: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private api: ApiService,
    public modalRef: MdbModalRef<ModalRenameCleaningComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.renameForm = new UntypedFormGroup({
      projectID: new UntypedFormControl(this.projectID),
      rename: this.formBuilder.array(this.populateForm()),
    });
  }

  populateForm() {
    let list: any[] = [];
    this.varietiesSelected?.forEach((e: VarietyCleaning) => {
      const varietyGroup = this.formBuilder.group({
        name: e.name,
        label: e.name,
        value: e.projectVarietyID,
      });
      list.push(varietyGroup);
    });
    return list;
  }

  save() {
    this.api.renameVarieties(this.renameForm?.value).subscribe({
      complete: () => {
        this.modalRef.close(true);
      },
      error: (error) => {
        this.error = error.status;
      },
    });
  }

  get rename() {
    return this.renameForm?.get('rename') as UntypedFormArray;
  }
}
