import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Country } from '../auth/models/country.model';
import { User } from '../auth/models/user.model';
import { AppState } from '../auth/reducer';
import { getUser } from '../auth/store/auth.selectors';
import { rtlLangs } from '../models/global-variables';

@Component({
  selector: 'web-portal',
  templateUrl: './web-portal.component.html',
  styleUrls: ['./web-portal.component.scss'],
})
export class WebPortalComponent implements OnInit, OnDestroy {
  user$?: Observable<User | null>;

  showSideNav?: boolean;
  sideNavRight: boolean = false;

  user?: User;
  roles?: Array<string>;
  country?: Country;

  isRtl?: boolean;
  direction?: string;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (window.innerHeight > 768) {
      this.showSideNav = true;
    } else {
      this.showSideNav = false;
    }
    window.addEventListener('resize', this.onResize);

    this.user$ = this.store.select(getUser);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  }

  checkRtl() {
    const currentLang = this.translate.currentLang;
    if (rtlLangs.includes(currentLang)) {
      this.direction = 'rtl';
    } else {
      this.direction = 'ltr';
    }
    this.isRtl = false;
  }

  onResize = () => {
    if (window.innerWidth > 1500) {
      this.showSideNav = true;
    } else {
      this.showSideNav = false;
    }
  };
}
