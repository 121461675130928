<div class="border-success card" style="border: 3px solid">
  <div class="modal-header">
    <div class="d-flex flex-row align-items-center">
      <i class="bi bi-pencil-square h4 my-auto"></i>
      <h4 class="modal-title my-auto mx-2">
        {{ "PROJECTS.EDIT_TITLE" | translate }}
      </h4>
    </div>
  </div>

  <div class="modal-body">
    <form [formGroup]="editTitleForm!">
      <div class="d-flex">
        <h5 class="mb-1">{{ "PROJECTS.TITLE" | translate }}</h5>
        <span class="text-danger mx-3" *ngIf="title?.touched && title?.invalid">
          {{ "REGISTER.REQUIRED" | translate }}
        </span>
      </div>
      <div class="my-2">
        <input type="text" class="form-control" formControlName="title" />
      </div>
    </form>
  </div>
  <loading class="mask" *ngIf="loading"></loading>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="modalRef.close(false)"
    >
      {{ "BUTTONS.CLOSE" | translate }}
    </button>
    <button type="button" class="btn btn-success" (click)="updateTitle()">
      {{ "BUTTONS.EDIT" | translate }}
    </button>
  </div>
</div>
