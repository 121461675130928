import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/api.service';
import { AppState } from 'src/app/auth/reducer';
import { getIsLoading } from 'src/app/auth/store/auth.selectors';
import { Select } from 'src/app/models/select';
import * as actions from '../../../auth/store/auth.action';

@Component({
  selector: 'add-country-card',
  templateUrl: './add-country-card.component.html',
  styleUrls: ['./add-country-card.component.scss'],
})
export class AddCountryCardComponent implements OnInit {
  @Input() userID?: string;

  addCountryForm?: UntypedFormGroup;

  isFlipped: boolean = false;
  cardWidth: string = 'w-75';

  countrySelect?: Array<Select>;

  constructor(private store: Store<AppState>, private api: ApiService) {}

  ngOnInit(): void {
    this.store.select(getIsLoading).subscribe(() => {
      this.loadCountries();
    });

    this.addCountryForm = new UntypedFormGroup({
      country: new UntypedFormControl('', [Validators.required]),
    });
  }

  get country() {
    return this.addCountryForm?.get('country');
  }

  loadCountries() {
    this.api.getCountries().subscribe(
      (next) => {
        this.countrySelect = next as Array<Select>;
        this.store.dispatch(new actions.SetLoading({ loading: false }));
      },
      (error) => {
        this.store.dispatch(new actions.SetLoading({ loading: false }));
      }
    );
  }

  flip() {
    if (!this.isFlipped) this.isFlipped = true;
    this.cardWidth = 'w-100';
  }

  add() {
    if (this.addCountryForm?.invalid) {
    } else {
      const body = {
        countryID: this.country?.value,
        role: 'USER',
        profileID: this.userID,
      };
      this.api.updateRole(body).subscribe(
        () => {
          this.isFlipped = false;
          this.cardWidth = 'w-100';
          this.store.dispatch(new actions.GetUser());
        },
        (error) => {
        }
      );
    }
  }
}
