import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { ApiService } from '../../api.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private api: ApiService) {}

  login(email: string, password: string) {
    return from(this.api.login(email, password));
  }

  getAuthState() {
    return from(this.api.isLoggedIn());
  }

  logout() {
    return from(this.api.logout());
  }

  saveUser(user: User) {
    return from(this.api.updateInfo(user.lang));
  }
}
