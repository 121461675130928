import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyProjectsPageComponent } from './projects-page/projects-page.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectDetailedComponent } from './project-detailed-page/project-detailed-page.component';
import { ProjectSitesComponent } from './components/project-sites/project-sites.component';
import { LayoutModule } from '../layout/layout.module';
import { ProjectUsersComponent } from './components/project-users/project-users.component';
import { MdbModule } from '../mdb/mdb.module';
import { ProjectDataCollectedComponent } from './components/project-data-collected/project-data-collected.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DownloadCardComponent } from './components/download-card/download-card.component';
import { CurrentProject } from '../models/project';
import { SiteDetailedPageComponent } from '../projects/site-detailed-page/site-detailed-page.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ProjectMapComponent } from './project-map/project-map.component';
import { SiteDataCollectedComponent } from './components/site-data-collected/site-data-collected.component';
import { ProjectConstraintsComponent } from './components/project-constraints/project-constraints.component';
import { ProjectInterventionsComponent } from './components/project-interventions/project-interventions.component';
import { PeriodGoalsComponent } from './components/period-goals/period-goals.component';
import { ProjectImpactComponent } from './components/project-impact/project-impact.component';
import { SiteMapComponent } from './components/site-map/site-map.component';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { DeclinedRequestsPageComponent } from './declined-requests-page/declined-requests-page.component';
import { GridSystemPageComponent } from './grid-system-page/grid-system-page.component';
import { ProjectTimelineComponent } from './project-timeline/project-timeline.component';
import { ProjectDataCollectedListComponent } from './components/project-data-collected-list/project-data-collected-list.component';
import { PeriodDetailedPageComponent } from './period-detailed-page/period-detailed-page.component';
import { PeriodDataCollectedComponent } from './components/period-data-collected/period-data-collected.component';
import { PeriodDataAnalysisComponent } from './components/period-data-analysis/period-data-analysis.component';
import { ProjectCleaningComponent } from './project-cleaning/project-cleaning.component';
import { PeriodDataCleaningComponent } from './period-data-cleaning/period-data-cleaning.component';
import { ProjectRequestsComponent } from './components/project-requests/project-requests.component';
import { FdgOt4Component } from './components/fdg-ot4/fdg-ot4.component';
import { HhsOt1Component } from './components/hhs-ot1/hhs-ot1.component';
import { PeriodCleanedDataDownloadComponent } from './components/period-cleaned-data-download/period-cleaned-data-download.component';
import { ProjectMeComponent } from './project-me/project-me.component';
import { ProjectDiversityBenefitComponent } from './components/project-diversity-benefit/project-diversity-benefit.component';
import { ProjectDevelopmentBenefitComponent } from './components/project-development-benefit/project-development-benefit.component';
import { PeriodDiversityTableComponent } from './components/period-diversity-table/period-diversity-table.component';
import { DiversityBenefitCardComponent } from './components/diversity-benefit-card/diversity-benefit-card.component';
import { DiversityBenefitRowComponent } from './components/diversity-benefit-row/diversity-benefit-row.component';
import { DevelopmentBenefitCardComponent } from './components/development-benefit-card/development-benefit-card.component';
import { DevelopmentBenefitRowComponent } from './components/development-benefit-row/development-benefit-row.component';
import { ProjectSurveysComponent } from './components/project-surveys/project-surveys.component';
import { PeriodInterventionsComponent } from './components/period-interventions/period-interventions.component';
import { PeriodConstraintsComponent } from './components/period-constraints/period-constraints.component';
import { ProjectOtComponent } from './project-ot/project-ot.component';
import { ActivityDetailsComponent } from './components/activity-details/activity-details.component';
import { DashboardModule } from "../dashboard/dashboard.module";

@NgModule({
  declarations: [
    MyProjectsPageComponent,
    ProjectDetailedComponent,
    ProjectSitesComponent,
    ProjectUsersComponent,
    ProjectDataCollectedComponent,
    DownloadCardComponent,
    SiteDetailedPageComponent,
    ProjectMapComponent,
    SiteDataCollectedComponent,
    ProjectConstraintsComponent,
    ProjectInterventionsComponent,
    PeriodGoalsComponent,
    ProjectImpactComponent,
    SiteMapComponent,
    ProjectCardComponent,
    DeclinedRequestsPageComponent,
    GridSystemPageComponent,
    ProjectTimelineComponent,
    ProjectDataCollectedListComponent,
    PeriodDetailedPageComponent,
    PeriodDataCollectedComponent,
    PeriodDataAnalysisComponent,
    ProjectCleaningComponent,
    PeriodDataCleaningComponent,
    ProjectRequestsComponent,
    FdgOt4Component,
    HhsOt1Component,
    PeriodCleanedDataDownloadComponent,
    ProjectMeComponent,
    ProjectDiversityBenefitComponent,
    ProjectDevelopmentBenefitComponent,
    PeriodDiversityTableComponent,
    DiversityBenefitCardComponent,
    DiversityBenefitRowComponent,
    DevelopmentBenefitCardComponent,
    DevelopmentBenefitRowComponent,
    ProjectSurveysComponent,
    PeriodInterventionsComponent,
    PeriodConstraintsComponent,
    ProjectOtComponent,
    ActivityDetailsComponent,
  ],
  providers: [CurrentProject],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    LayoutModule,
    MdbModule,
    ReactiveFormsModule,
    FormsModule,
    LeafletModule,
    DashboardModule
],
  exports: [
    MyProjectsPageComponent,
    ProjectDetailedComponent,
    SiteDetailedPageComponent,
    DeclinedRequestsPageComponent,
    GridSystemPageComponent,
    PeriodDetailedPageComponent,
    FdgOt4Component,
    HhsOt1Component,
  ],
})
export class ProjectsModule {}
