<div class="d-flex flex-column">
  <div class="d-flex flex-wrap px-5 mt-3" [formGroup]="filterForm!">
    <div>
      <input
        type="text"
        class="form-control"
        formControlName="filter"
        placeholder="{{ 'PLACEHOLDER.FILTER' | translate }}"
      />
    </div>
    <div class="mx-2">
      <ng-select
        class="control"
        formControlName="activityType"
        placeholder="{{ 'PLACEHOLDER.ACTIVITY' | translate }}"
        [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      >
        <ng-option *ngFor="let type of activitiesList" [value]="type.value">
          {{ type.label }}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="d-flex flex-wrap px-5 mt-3 mb-5">
    <div
      class="card mb-3 me-3 p-2 d-flex flex-column border border-2"
      [ngClass]="activity.deleted ? 'border-danger' : ''"
      style="height: 13rem; width: 25rem"
      *ngFor="let activity of activities; index as i"
    >
      <div style="height: 2.7rem; overflow-y: auto">
        <h3 class="mb-2">{{ activity.name || activity.location }}</h3>
      </div>
      <h6 class="mb-2 text-secondary">{{ activity.uuid }}</h6>
      <h5 class="mb-2">{{ activity.location }}</h5>
      <h5 class="mb-2">{{ activity.type }}</h5>
      <div
        class="d-flex flex-row justify-content-between align-items-center mt-auto mb-2"
      >
        <span class="my-auto">{{ activity.date.split("T")[0] }}</span>
        <div
          class="d-flex ms-auto"
          style="text-decoration: underline; cursor: pointer"
        >
          <h6 class="me-3 my-auto" (click)="openEditModal(activity, i)">
            {{ "BUTTONS.EDIT" | translate }}
          </h6>
          <button
            class="btn btn-primary"
            (click)="show(activity.activityID, activity.type)"
          >
            {{ "BUTTONS.SHOW" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
