import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AppState } from '../../reducer';
import { select, Store } from '@ngrx/store';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import * as actions from '../../store/auth.action';
import { Observable, Subscription } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import { getError, getIsLoading } from '../../store/auth.selectors';
import { Lang } from 'src/app/models/lang.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm?: UntypedFormGroup;

  currentLang?: string;
  langs?: Array<string>;

  error$?: Observable<string | null>;
  loading$?: Subscription;
  loading: boolean = false;

  passwordVisibility: boolean = false;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select(getIsLoading).subscribe((loading) => {
      this.loading = loading;
    });

    this.langs = Object.keys(Lang)
      .filter((key) => isNaN(Number(key)))
      .map((value) => value.toLowerCase());

    this.currentLang = this.translate.currentLang;

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      password: new UntypedFormControl(null, [Validators.required]),
    });

    this.error$ = this.store.pipe(
      select(getError),
      map((error: any) => {
        this.loading = false;
        if (error && error === 401) {
          return 'Invalid e-mail or password';
        } else if (error && error === 406) {
          return 'Please use Admin console to Login';
        } else {
          return null;
        }
      })
    );
  }

  showPassword() {
    this.passwordVisibility = !this.passwordVisibility;
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    this.currentLang = lang;
  }

  ngOnDestroy(): void {
    this.loading$?.unsubscribe();
  }

  get email() {
    return this.loginForm!.get('email');
  }
  get password() {
    return this.loginForm!.get('password');
  }

  logIn = () => {
    if (this.loginForm!.valid) {
      this.store.dispatch(new actions.SetLoading({ loading: true }));
      this.store.dispatch(new actions.LoginRequested(this.loginForm?.value));
    } else {
      this.loginForm?.markAllAsTouched();
    }
  };
}
