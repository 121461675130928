<div class="container-fluid p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-5">
      <div class="d-flex">
        <i class="bi bi-kanban-fill h1"></i>
        <h1 class="fw-bold mx-2">{{ "NAVIGATION.DASHBOARD" | translate }}</h1>
      </div>
      <div class="row gap-2 my-3">
        <dashboard-side-bar class="col-3"></dashboard-side-bar>
        <div class="col">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <h4>
              <u> {{ "DASHBOARD.MAP_TITLE" | translate }} </u>
            </h4>
            <div
              class="card shadow-0 bg-transparent"
              style="overflow: hidden; width: 50vw; height: 48rem"
            >
              <div
                class="w-100 p-4 pb-5 text-primary rounded"
                style="border-radius: 0.5rem 0.5rem 0 0; overflow: hidden"
              >
                <mdb-vector-map
                  [stroke]="'#37474F'"
                  [readonly]="true"
                  [hover]="false"
                  [btnClass]="'btn-light'"
                  [colorMap]="advancedColorMap"
                ></mdb-vector-map>
              </div>
              <div
                class="bg-primary mx-auto"
                style="height: 2px; width: 95%"
              ></div>
              <div
                id="map-advanced-legend"
                class="d-flex flex-wrap justify-content-center btn-transparent text-primary mt-2 py-2 mx-auto legend"
              >
                <small class="mt-5">{{ legendMin }}</small>
                <div
                  *ngFor="let color of colors[activeField]"
                  [style.height.px]="30"
                  [style.width.px]="30"
                  [style.background-color]="color"
                ></div>
                <small class="mt-5">{{ legendMax }}</small>
              </div>
              <div class="d-flex py-3 justify-content-center btn-transparent">
                <a
                  class="btn btn-primary mx-2"
                  style="background-color: rgb(55, 94, 176)"
                  (click)="changeField('total')"
                >
                  {{ "DASHBOARD.TOTAL" | translate }}
                </a>
                <a
                  class="btn btn-primary mx-2"
                  style="background-color: rgb(220, 120, 53)"
                  (click)="changeField('projectCrops')"
                >
                  {{ "NAVIGATION.CROP" | translate }}
                </a>
                <a
                  class="btn btn-primary mx-2"
                  style="background-color: rgb(55, 100, 44)"
                  (click)="changeField('projectLivestock')"
                >
                  {{ "NAVIGATION.LIVESTOCK" | translate }}
                </a>
                <a
                  class="btn btn-primary mx-2"
                  style="background-color: rgb(107, 184, 68)"
                  (click)="changeField('projectAquatics')"
                >
                  {{ "NAVIGATION.AQUACULTURE" | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
