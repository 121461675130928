<div class="d-flex flex-column">
  <h4 class="text-primary">
    <u> {{ "DASHBOARD.COUNT" | translate }} </u>
  </h4>
  <div class="d-flex flex-row justify-content-center mt-1">
    <counter
      class="select-hover"
      [counter]="count?.crops"
      image="/assets/images/crop.svg"
      (click)="goToCrop()"
    ></counter>
    <counter
      class="mx-5 select-hover"
      [counter]="count?.livestock"
      image="/assets/images/livestock.svg"
      (click)="goToLivestock()"
    ></counter>
    <counter
      class="select-hover"
      [counter]="count?.aquatics"
      image="/assets/images/aquaculture.svg"
      (click)="goToAquaculture()"
    ></counter>
  </div>

  <h4 class="text-primary mt-4">
    <u> {{ "DASHBOARD.EVENT" | translate }} </u>
  </h4>
  <div
    class="d-flex flex-row card w-100 mt-1 select-hover"
    style="height: 8rem"
  >
    <div class="d-flex flex-row mx-3">
      <div class="card calendar my-auto">
        <h1 class="text-primary mt-auto mx-auto">15</h1>
        <div class="mask d-flex month text-white w-100">
          <h6 class="mx-auto my-auto">FEB</h6>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column my-auto">
      <h4>Web Portal Release</h4>
      <p class="mb-0">Global release of new portal's version</p>
    </div>
  </div>
  <h4 class="text-primary mt-4">
    <u> {{ "DASHBOARD.NEWS" | translate }} </u>
  </h4>
  <div
    class="d-flex flex-row justify-content-center card select-hover w-100 mt-1 mx-2"
    style="height: 8rem"
  >
    <div class="my-auto" style="width: 6.2rem; height: 6.2rem">
      <img
        class="w-100 h-100 img-fluid"
        style="object-fit: contain"
        src="../../../../assets/images/logo.png"
      />
    </div>
    <div class="d-flex flex-column my-auto mx-2">
      <h4>Something is Available!</h4>
      <p class="mb-0">Check here to understand more.</p>
    </div>
  </div>

  <!-- <div class="d-flex flex-column mx-1"> -->
  <!-- <div class="mt-4 btn btn-primary">datar-par.org</div> -->
  <!-- <div class="mt-2 btn btn-primary">Know About Our Partners</div> -->
  <!-- <div class="mt-2 btn btn-primary">more features</div> -->
  <!-- </div> -->

  <h4 class="text-primary mt-4">
    <u> {{ "DASHBOARD.PARTNERS" | translate }} </u>
  </h4>
  <div
    class="card w-100 d-flex flex-row select-hover"
    style="overflow: hidden; height: 7rem"
    (click)="openPartners()"
  >
    <div class="my-auto w-50 mx-3">
      <mdb-carousel
        [controls]="false"
        [indicators]="false"
        [animation]="'fade'"
      >
        <mdb-carousel-item *ngFor="let item of firstList">
          <div class="w-100 h-100">
            <img
              [src]="'../../../../assets/images/partners/' + item.img"
              class="w-100 h-100 p-4 my-auto"
              style="object-fit: contain; height: 7rem"
            />
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
    <div class="my-auto w-50 mx-3">
      <mdb-carousel
        [controls]="false"
        [indicators]="false"
        [animation]="'fade'"
      >
        <mdb-carousel-item *ngFor="let item of secondList">
          <div class="w-100 h-100">
            <img
              [src]="'../../../../assets/images/partners/' + item.img"
              class="w-100 h-100 p-4 my-auto"
              style="object-fit: contain; height: 7rem"
            />
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </div>
</div>
