import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-ot-history',
  templateUrl: './modal-ot-history.component.html',
  styleUrls: ['./modal-ot-history.component.scss'],
})
export class ModalOtHistoryComponent implements OnInit {
  link?: string;
  refID?: string;

  history?: any[];

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalOtHistoryComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.api.historyOT(this.link!, this.refID!).subscribe((value) => {
      this.history = value as any[];
    });
  }
}
