import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-constraint-tree',
  templateUrl: './modal-constraint-tree.component.html',
  styleUrls: ['./modal-constraint-tree.component.scss'],
})
export class ModalConstraintTreeComponent implements OnInit {
  constraitID?: string;
  title?: string;
  interventions?: Array<any>;

  colors = [
    '#ebc000',
    '#ed7d31',
    '#4270bf',
    '#f9f906',
    '#c55a11',
    '#00b0f0',
    '#92d050',
    '#f4b0a4',
    '#f71152',
    '#1deb57',
  ];

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalConstraintTreeComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.api
      .getInterventionsForConstraint('CROPS', this.constraitID!)
      .subscribe((value) => {
        this.interventions = value as Array<any>;
        this.checkLength();
      });
  }

  checkLength() {
    let rest = this.interventions!.length % 3;
    for (let index = 0; index <= rest; index++) {
      this.interventions?.push({ empty: true });
    }
  }
}
