import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { ModalInterventionsComponent } from 'src/app/modals/database/modal-interventions/modal-interventions.component';

@Component({
  selector: 'database-intervention',
  templateUrl: './database-intervention.component.html',
  styleUrls: ['./database-intervention.component.scss'],
})
export class DatabaseInterventionComponent implements OnInit, OnDestroy {
  types: Array<any> = [];
  descriptors: Array<any> = [];
  langSub?: Subscription;

  databaseType: string = '';

  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.getTypes();
    this.translate.onLangChange.subscribe(() => {
      this.getTypes();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  getTypes() {
    this.databaseType = this.getCurrentLocation().toUpperCase();
    if (this.databaseType === 'CROP') this.databaseType = 'CROPS';
    if (this.databaseType === 'AQUACULTURE') this.databaseType = 'AQUATICS';

    this.api.getInterventionCategories(this.databaseType).subscribe((value) => {
      this.types = value as Array<any>;
    });
  }

  getCurrentLocation() {
    const path = this.router.url;
    return path.split('/')[3];
  }

  getImage() {
    const base: string = '../../../assets/images/';
    const location: string = this.getCurrentLocation();
    const image = base + location + '.svg';
    return image;
  }

  onSelectType = (i: number) => {
    this.types.filter((value) => {
      value.selected = false;
    });
    this.types[i].selected = true;
    this.descriptors = [];
    this.descriptors = this.types[i].interventions;
  };

  openSubType(interventionID: string) {
    this.modalService.open(ModalInterventionsComponent, {
      containerClass: 'right',
      modalClass: 'modal-side modal-bottom-right',
      data: { interventionID: interventionID, databaseType: this.databaseType },
    });
  }
}
