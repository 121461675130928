import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'modal-add-descriptor-benefit',
  templateUrl: './modal-add-descriptor-benefit.component.html',
  styleUrls: ['./modal-add-descriptor-benefit.component.scss'],
})
export class ModalAddDescriptorBenefitComponent implements OnInit {
  titolo?: string;
  refID?: string;
  familyID?: string;
  periodID?: string;

  descriptors?: Select[];
  refIDs: string[] = [];

  descriptorID?: string;

  loading: boolean = true;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalAddDescriptorBenefitComponent>
  ) {}

  ngOnInit(): void {
    this.api
      .getAddDescriptorsDevelopmentBenefit(
        this.refID!,
        this.familyID!,
        this.periodID!
      )
      .subscribe((value) => {
        this.descriptors = value as Select[];
        this.loading = false;
      });
  }

  addRef(value: string) {
    this.descriptorID = value;
    // if (this.refIDs.includes(value)) {
    //   const index = this.refIDs.indexOf(value);
    //   this.refIDs.splice(index, 1);
    // } else {
    //   this.refIDs.push(value);
    // }
  }

  save() {
    this.descriptors?.forEach((e) => {
      if (e.value == this.descriptorID) {
        this.modalRef.close(e);
      }
    });
  }
}
