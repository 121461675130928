import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { rtlLangs } from 'src/app/models/global-variables';
import { Lang } from 'src/app/models/lang.enum';

@Component({
  selector: 'change-lang',
  templateUrl: './change-lang.component.html',
  styleUrls: ['./change-lang.component.scss'],
})
export class ChangeLangComponent implements OnInit {
  currentLang?: string;
  langs?: Array<string>;
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.langs = Object.keys(Lang)
      .filter((key) => isNaN(Number(key)))
      .map((value) => value.toLowerCase());

    this.currentLang = this.translate.currentLang;
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    this.currentLang = lang;
  }
}
