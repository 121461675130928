import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { FiltersListener, FiltersHelper } from 'src/app/models/filter-list';
import { Select } from 'src/app/models/select';
import { Variety } from 'src/app/models/variety';

export interface VarietyInfo {
  countryName: string;
  countryOrigin: string;
  creationDate: string;
  datarID: string;
  showCountryOrigin: string;
}

@Component({
  selector: 'variety-page',
  templateUrl: './variety-page.component.html',
  styleUrls: ['./variety-page.component.scss'],
})
export class VarietyPageComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  filterListener$?: Subscription;
  filterHelper = new FiltersHelper();
  databaseType?: String;
  variety?: any;
  countryID?: string;
  varietyInfo?: VarietyInfo;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private filterListener: FiltersListener
  ) {}

  ngOnInit(): void {
    this.getDatabaseType();
    this.getVariety();

    this.filterListener$ = this.filterListener.data.subscribe((data) => {
      this.filterHelper = data;
      if (data.varietyCountry !== '') {
        this.countryID = data.varietyCountry;
        this.getVarietyInfo();
      }
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
    this.filterListener$?.unsubscribe();
  }

  getDatabaseType() {
    const path = this.router.url;
    this.databaseType = path.split('/')[3];
  }

  getLabelCountry() {
    this.api
      .getCountriesForVariety('CROPS', this.variety.varietyID)
      .subscribe((value) => {
        const countries = value as Select[];
        const countryID = countries[0].value;
        this.filterHelper.varietyCountry = countryID;
        this.filterListener.setData(this.filterHelper);
      });
  }

  getVariety() {
    const stringIDs = this.route.snapshot.paramMap.get('id');
    const IDs = stringIDs?.split('-') || [];
    switch (this.databaseType) {
      case 'v':
        this.api.getVariety(IDs[1], IDs[0]).subscribe((value) => {
          this.variety = value as any;
          this.getLabelCountry();
        });
        break;
      case 'b':
        this.api.getBreed(IDs[1], IDs[0]).subscribe((value) => {
          this.variety = value as any;
        });
        break;
      case 'f':
        break;
      default:
        break;
    }
  }

  getVarietyInfo() {
    const stringIDs = this.route.snapshot.paramMap.get('id');
    const IDs = stringIDs?.split('-') || [];
    const varietyID = IDs[1];
    switch (this.databaseType) {
      case 'v':
        this.api
          .getVarietyInfo('CROPS', varietyID, this.countryID || '')
          .subscribe((value) => {
            this.varietyInfo = value as VarietyInfo;
          });
        break;
      case 'b':
        this.api
          .getVarietyInfo('LIVESTOCK', varietyID, this.countryID || '')
          .subscribe((value) => {
            this.varietyInfo = value as VarietyInfo;
          });
        break;
      case 'f':
        break;
      default:
        break;
    }
  }

  getImage(): string {
    switch (this.databaseType) {
      case 'v':
        return this.variety.crop.imageUrl;
      case 'b':
        return this.variety.livestock.imageUrl;
      case 'f':
        return this.variety.aquatic.imageUrl;
      default:
        return '';
    }
  }

  getItemName() {
    switch (this.databaseType) {
      case 'v':
        return this.variety.crop;
      case 'b':
        return this.variety.livestock;
      case 'f':
        return this.variety.aquatic;
      default:
        break;
    }
  }

  getDatabaseNavigatorName(): string {
    switch (this.databaseType) {
      case 'v':
        return 'CROP';
      case 'b':
        return 'LIVESTOCK';
      case 'f':
        return 'AQUACULTURE';
      default:
        return '';
    }
  }
}
