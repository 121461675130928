import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Site } from 'src/app/models/project';
import { Select } from 'src/app/models/select';
import { ModalAnswerInterventionsCellComponent } from '../modal-answer-interventions-cell/modal-answer-interventions-cell.component';
import { ModalConfirmDeleteInterventionComponent } from '../modal-confirm-delete-intervention/modal-confirm-delete-intervention.component';

@Component({
  selector: 'modal-answer-interventions',
  templateUrl: './modal-answer-interventions.component.html',
  styleUrls: ['./modal-answer-interventions.component.scss'],
})
export class ModalAnswerInterventionsComponent implements OnInit {
  refID?: string;
  species?: Select[];
  projectID?: string;
  siteID?: string;
  sites?: Site[];

  familySelected?: string;
  families?: Select[];
  periods?: any[];

  filterForm?: FormGroup;
  ref?: string;
  rows: any[] = [];
  columnHeaders: any[] = [];
  answerInfo: any;

  selectedRow: number = -1;
  selectedRows: number[] = [];
  selectedColumn: number = -1;
  selectedColumns: number[] = [];
  selectedAnswer: any;

  map: Map<string, any> = new Map();

  loading: boolean = false;

  lastCall = 0;
  constructor(
    private api: ApiService,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<ModalAnswerInterventionsComponent>
  ) {}

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      specie: new FormControl(this.refID),
      site: new FormControl(this.siteID),
    });

    this.getTableStructure();

    this.filterForm.valueChanges.subscribe((value) => {
      this.getTableStructure();
    });
  }

  getTableStructure() {
    this.loading = true;
    this.columnHeaders = columnHeaders;
    this.api
      .getMeInterventionStructure(
        this.projectID!,
        this.specie?.value,
        this.site?.value
      )
      .subscribe((value) => {
        this.rows = value as any;
        this.loading = false;
      });
  }

  getBackgroundCell(rowIndex: number, answerIndex: number): string {
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'cell-selected text-white border-primary';
    }
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.indexOf(answerIndex) === -1
    ) {
      return 'full-row';
    }
    if (
      this.selectedRow != rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'full-row';
    }
    return 'bg-white';
  }

  cellClicked(e: any, columnIndex: number) {
    if (!e.clickable) return;
    const modalRef = this.modalService.open(
      ModalAnswerInterventionsCellComponent,
      {
        modalClass: 'modal-lg',
        data: {
          refID: e.rowID,
          colID: e.colID,
          value: e.name,
          title: columnHeaders[columnIndex],
          cell: e,
        },
      }
    );
    modalRef.onClose.subscribe((value) => {
      if (!value) return;
      this.getTableStructure();
    });
  }

  modalConfirmDelete(row: any) {
    const modalRef = this.modalService.open(
      ModalConfirmDeleteInterventionComponent
    );
    modalRef.onClose.subscribe((value) => {
      if (value != true) return;
      this.deleteRow(row);
    });
  }

  deleteRow(row: any) {
    this.api.deleteMeIntervention(row.rowID).subscribe((value) => {
      this.getTableStructure();
    });
  }

  get specie() {
    return this.filterForm?.get('specie');
  }

  get site() {
    return this.filterForm?.get('site');
  }
}

const columnHeaders = [
  'ME.I_COL_1',
  'ME.I_COL_2',
  'ME.I_COL_3',
  'ME.I_COL_4',
  'ME.I_COL_5',
  'ME.I_COL_6',
  'ME.I_COL_7',
  'ME.I_COL_8',
  'ME.I_COL_9',
  'ME.I_COL_10',
  'ME.I_COL_11',
  'ME.I_COL_12',
  'BUTTONS.DELETE',
];
