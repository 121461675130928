import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalModule, MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'modal-gaussian',
  templateUrl: './modal-gaussian.component.html',
  styleUrls: ['./modal-gaussian.component.scss'],
})
export class ModalGaussianComponent implements OnInit {
  points?: number[];

  datasets?: any[];

  labels: string[] = [];

  constructor(
    public modalRef: MdbModalRef<ModalGaussianComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.points?.forEach((value, index) => {
      this.labels.push(index.toString());
    });
    this.datasets = [
      {
        label: '',
        data: this.points,
      },
    ];
  }
}
