import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { AppState } from 'src/app/auth/reducer';
import { ModalManageDescriptorsComponent } from 'src/app/modals/working-area/modal-manage-descriptors/modal-manage-descriptors.component';
import { ModalManageVarietiesComponent } from 'src/app/modals/working-area/modal-manage-varieties/modal-manage-varieties.component';
import { Answer } from 'src/app/models/answer';
import { Descriptor } from 'src/app/models/descriptor';
import * as actions from '../../auth/store/auth.action';
import {
  AnswerInfo,
  VarietyInfo,
} from 'src/app/projects/grid-system-page/grid-system-page.component';

export interface WorkingArea {
  workID: string;
  familyID: string;
  familyName: string;
  imageUrl: string;
  countVarieties: number;
  country: string;
  countryIso: string;
  hasDescriptors: boolean;
  lastUpdate: Date;
  ref: string;
}

@Component({
  selector: 'answering-page',
  templateUrl: './answering-page.component.html',
  styleUrls: ['./answering-page.component.scss'],
})
export class AnsweringPageComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  modifyForm?: UntypedFormGroup;
  filterForm?: UntypedFormGroup;

  workingArea?: WorkingArea;

  types?: any[];

  columnHeaders: any[] = [];
  isHeaderEmpty?: boolean;

  rows: any[] = [];

  loading?: boolean;

  varietyInfo?: VarietyInfo;
  answerInfo?: AnswerInfo;

  selectedRow: number = -1;
  selectedRows: number[] = [];
  selectedColumn: number = -1;
  selectedColumns: number[] = [];
  selectedAnswer: any;

  isGeneralInfosOpened: boolean = false;
  isLegendOpened: boolean = false;
  isModifyOpened: boolean = false;

  descriptor?: Descriptor;
  selectedType?: string;

  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: MdbModalService,
    private fb: UntypedFormBuilder,
    private transalte: TranslateService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      type: new UntypedFormControl(null, []),
    });

    this.modifyForm = new UntypedFormGroup({
      choiceValue: new UntypedFormControl(null, []),
      textValue: new UntypedFormControl(null, []),
      nValue: new UntypedFormControl(null, []),
      boolValue: new UntypedFormControl(null, []),
      note: new UntypedFormControl(null, []),
      choiceMultiple: this.fb.array([]),
    });

    const workID = this.route.snapshot.paramMap.get('id') || '';

    this.api.getWorkingArea(workID).subscribe((value) => {
      this.workingArea = value as WorkingArea;
      this.api.getDescriptors(this.workingArea.ref).subscribe((value) => {
        this.types = value as any[];
      });
      this.getUser();
    });

    this.type?.valueChanges.subscribe((type) => {
      if (type) {
        this.selectedType = type as string;
        this.getTableStructure();
      }
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  getUser() {
    this.store.dispatch(new actions.GetUser());
  }

  getTableStructure() {
    if (this.selectedType) {
      this.cleanTable();
      this.loading = true;
      this.api
        .getDescriptorsSelectedByWorkingArea(
          this.workingArea!.workID,
          this.selectedType
        )
        .subscribe((value) => {
          this.columnHeaders = value as any;
          this.isHeaderEmpty = this.columnHeaders.length == 0;
          if (!this.isHeaderEmpty) {
            this.api
              .getAnswersByWorkingArea(
                this.workingArea!.workID,
                this.selectedType || ''
              )
              .subscribe((value) => {
                this.rows = value as any;
                this.loading = false;
              });
          } else {
            this.loading = false;
          }
        });
    }
  }

  getColumnHeaderLivestock(): any[] {
    let newColumns: any[] = [];
    this.columnHeaders.forEach((item, i) => {
      if (i > 0) {
        if (item.refID == newColumns[newColumns.length - 1].refID) {
          newColumns[newColumns.length - 1].span += 1;
        } else {
          item.span = 1;
          newColumns.push(item);
        }
      } else {
        item.span = 1;
        newColumns.push(item);
      }
    });
    return newColumns;
  }

  addChoiceMultiple(item: any) {
    const choice = new UntypedFormGroup({
      label: new UntypedFormControl(item.valueText, []),
      uuid: new UntypedFormControl(item.uuid, []),
      selected: new UntypedFormControl(item.selected, []),
      quantity: new UntypedFormControl(item.quantity, []),
    });

    this.choiceMultiple.push(choice);
  }

  backToWorkingArea() {
    this.router.navigateByUrl('/u/working-area');
  }

  save() {
    const body = this.modifyForm?.value;
    body.descriptorID = this.columnHeaders[this.selectedColumns[0]].refID;
    body.varietyID = this.rows[this.selectedRow].refID;
    body.refID = this.workingArea?.workID;
    body.answerID = this.answerInfo?.answerID;
    body.age = this.answerInfo?.age;
    body.gender = this.answerInfo?.gender;

    this.api.updateAnswerByWorkingArea(body).subscribe((value) => {
      const answer = value as Answer;
      this.rows[this.selectedRow].answers[this.selectedColumns[0]] = answer;
      this.selectedAnswer = answer;
    });
  }

  getDescriptor(columnIndex: number) {
    this.selectedRow = -1;
    this.selectedAnswer = -1;
    this.selectedColumn = columnIndex;
    this.selectedColumns = [];
    let max = this.getColumnHeaderLivestock()[columnIndex].span;
    let min = 0;
    if (columnIndex > 0) {
      this.getColumnHeaderLivestock().forEach((item, i) => {
        if (i < columnIndex) min += item.span;
      });
    }
    for (let i = 0; i < max; i++) {
      this.selectedColumns.push(min + i);
    }

    this.cleanTableUtilities();
    //
    this.api
      .getDescriptor(this.columnHeaders[columnIndex].refID)
      .subscribe((value) => {
        this.descriptor = value as Descriptor;
        this.isLegendOpened = true;
      });
  }

  getDescriptorByGA(columnIndex: number, descritorID: string) {
    this.selectedAnswer = null;
    this.selectedRow = -1;
    let selectedCol = -1;
    this.getColumnHeaderLivestock().forEach((item, i) => {
      if (item.refID === descritorID) selectedCol = i;
    });
    this.selectedColumn = selectedCol;
    this.selectedColumns = [];
    this.selectedColumns.push(columnIndex);
    this.selectedRows = [];
    this.selectedRows.push(columnIndex);
    this.cleanTableUtilities();
    //
    this.api.getDescriptor(descritorID).subscribe((value) => {
      this.descriptor = value as Descriptor;
      this.isLegendOpened = true;
    });
  }

  getGeneralInfos(projectID: string, refID: string, rowIndex: number) {
    this.selectedColumn = -1;
    this.selectedAnswer = null;
    this.selectedRow = rowIndex;
    this.selectedColumns = [];
    this.cleanTableUtilities();
    // this.api.getFgdVarietyInfo(projectID, refID).subscribe((value) => {
    //   this.varietyInfo = value as VarietyInfo;
    //   this.isGeneralInfosOpened = true;
    // });
  }

  cleanTableUtilities() {
    this.isGeneralInfosOpened = false;
    this.isLegendOpened = false;
    this.isModifyOpened = false;
  }

  cleanTable() {
    this.columnHeaders = [];
    this.rows = [];
  }

  getModify(answer: any, rowIndex: number, answerIndex: number) {
    this.selectedRow = rowIndex;
    this.selectedAnswer = answer;
    this.selectedColumns = [];
    this.selectedColumns.push(answerIndex);
    let selectedCol = -1;

    this.getColumnHeaderLivestock().forEach((item, i) => {
      if (item.refID === answer.descriptorID) selectedCol = i;
    });
    this.selectedColumn = selectedCol;
    // const varietyID = this.rows[rowIndex].refID;
    this.cleanTableUtilities();

    this.api
      .getAnswerInfoByWorkingArea(
        answer.descriptorID,
        answer.refID,
        answer.age,
        answer.gender
      )
      .subscribe((value) => {
        this.answerInfo = value as AnswerInfo;
        this.setFormValue();
        this.isModifyOpened = true;
      });
  }

  getBackgroundCell(rowIndex: number, answerIndex: number): string {
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'cell-selected text-white';
    }
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.indexOf(answerIndex) === -1
    ) {
      return 'full-row';
    }
    if (
      this.selectedRow != rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'full-row';
    }
    return 'bg-white';
  }

  setFormValue() {
    // Object.keys(this.modifyForm!.controls).forEach((c) => {
    // this.modifyForm!.get(c)!.setValue(null);
    // });
    this.modifyForm?.reset();
    this.note?.setValue(this.answerInfo?.note);
    switch (this.answerInfo?.answerType) {
      case 'MULTIPLE':
        this.choiceValue?.setValue(this.answerInfo.choiceValue);
        break;
      case 'MULTIPLE_LIST':
      case 'MULTIPLE_LIST_SUSCEPTIBILITY':
      case 'MULTIPLE_LIST_CONTENT':
        this.choiceMultiple.clear();
        this.answerInfo.values.forEach((item: any) => {
          this.addChoiceMultiple(item);
        });
        break;
      case 'NUMBER':
        this.nValue?.setValue(this.answerInfo.nValue);
        break;
      case 'TEXT':
        this.textValue?.setValue(this.answerInfo.textValue);
        break;
      case 'BOOLEAN':
        this.boolValue?.setValue(this.answerInfo.boolValue);
        break;
      default:
        break;
    }
  }

  openManageDescriptors() {
    const modalRef = this.modalService.open(ModalManageDescriptorsComponent, {
      modalClass: 'modal-dialog-centered modal-xl',
      data: { workID: this.workingArea?.workID },
    });

    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.getTableStructure();
      }
    });
  }

  openManageVarieties(title: string) {
    const modalRef = this.modalService.open(ModalManageVarietiesComponent, {
      modalClass: 'modal-dialog-centered modal-lg',
      data: { workingArea: this.workingArea, title: title },
    });

    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.getTableStructure();
      }
    });
  }

  goBack() {
    window.history.back();
  }

  switchGenderColor(gender: string): string {
    switch (gender) {
      case 'F':
        return 'bg-female';
      case 'M':
        return 'bg-male';
      default:
        return 'bg-white';
    }
  }

  getToolTip(item: any): string {
    const gender = item.gender === 'F' ? 'FEMALE' : 'MALE';
    const age = item.age === 'A' ? 'ADULT' : 'JUVENILE';
    return 'WORKING_AREA.' + age + '_' + gender;
  }

  get crop() {
    return this.filterForm?.get('crop');
  }

  get type() {
    return this.filterForm?.get('type');
  }

  get choiceValue() {
    return this.modifyForm?.get('choiceValue');
  }

  get nValue() {
    return this.modifyForm?.get('nValue');
  }

  get choiceMultiple(): UntypedFormArray {
    // return this.modifyForm?.get('choiceMultiple');
    return this.modifyForm?.controls['choiceMultiple'] as UntypedFormArray;
  }

  get textValue() {
    return this.modifyForm?.get('textValue');
  }

  get boolValue() {
    return this.modifyForm?.get('boolValue');
  }

  get note() {
    return this.modifyForm?.get('note');
  }
}
