import { Component, OnInit } from '@angular/core';
import { Partner } from 'src/app/models/partner';

@Component({
  selector: 'partners-page',
  templateUrl: './partners-page.component.html',
  styleUrls: ['./partners-page.component.scss'],
})
export class PartnersPageComponent implements OnInit {
  partners: Partner[] = Partner.getPartners();

  constructor() {}

  ngOnInit(): void {}

  openLink(link: string) {
    window.open(link, '_blank');
  }
}
