import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/api.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'instructions-page',
  templateUrl: './instructions-page.component.html',
  styleUrls: ['./instructions-page.component.scss'],
})
export class InstructionsPageComponent implements OnInit, OnDestroy {
  filterForm?: UntypedFormGroup;

  lang$?: Subscription;
  pathItems: string[] = ['home'];

  folders: any[] = [];
  foldersPath?: any[];

  files?: any[];
  pdfFiles: any[] = [];
  videoFiles: any[] = [];
  ref?: string;

  showFilter: boolean = false;

  path?: string;

  queryParams?: string;

  obj: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null, []),
    });

    this.getStructure();

    this.filter?.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.getFiles(value);
    });

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.getStructure();
    });
  }
  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  getStructure() {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params['q'];
      this.api.getFolders(params['q']).subscribe((folders) => {
        this.folders = folders as any[];
      });

      this.api.getParentFolders(params['q']).subscribe((parentFolders) => {
        this.foldersPath = parentFolders as any[];
      });

      this.api.getFilesByFolder(params['q'], null).subscribe((dataFiles) => {
        const files = dataFiles as any[];
        this.pdfFiles = [];
        this.videoFiles = [];
        if (files) {
          this.showFilter = files[0].type === 'DOCUMENT';
          files.forEach((item) => {
            if (this.getType(item.type) === 'pdf') {
              this.pdfFiles.push(item);
            } else {
              this.videoFiles.push(item);
            }
          });
        }
      });
    });
  }

  getFiles(filter: string | null) {
    if (this.queryParams) {
      this.api
        .getFilesByFolder(this.queryParams, filter)
        .subscribe((dataFiles) => {
          const files = dataFiles as any[];
          this.pdfFiles = [];
          this.videoFiles = [];
          this.showFilter = files[0].type === 'DOCUMENT';
          files.forEach((item) => {
            if (this.getType(item.type) === 'pdf') {
              this.pdfFiles.push(item);
            } else {
              this.videoFiles.push(item);
            }
          });
        });
    }
  }

  getType(type: String) {
    switch (type) {
      case 'GUIDELINE':
        return 'pdf';
      case 'APP':
        return 'pdf';
      case 'DOCUMENT':
        return 'pdf';
      case 'VIDEO':
        return 'video';
      case 'APP_VIDEO':
        return 'video';
      default:
        return 'pdf';
    }
  }

  getPath(fullPath: string) {
    if (fullPath) {
      const items: string[] = fullPath.split('/');
      this.pathItems = items;
      this.pathItems.splice(0, 0, 'home');
      this.pathItems.pop();
    } else {
    }
  }

  navigateHome() {
    this.router.navigateByUrl('u/instructions/home');
  }

  navigateTo(fullPath: string) {
    this.ref = fullPath;
    this.router.navigateByUrl('u/instructions/home?q=' + fullPath);
  }

  openFolder(fullPath: string) {
    this.ref = fullPath;
    this.router.navigateByUrl('u/instructions/home?q=' + fullPath);
  }

  get filter() {
    return this.filterForm?.get('filter');
  }
}
