<div class="card mt-3 mx-1 pb-3" style="width: 50vw; overflow: hidden">
  <mdb-accordion flush="true">
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>
        {{ "NAVIGATION.POINT_OF_SELLING" | translate }}
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="pb-2" style="height: 17rem; overflow-y: auto">
          <div class="row pt-2 mb-2 px-2 mx-2 sticky-top bg-white">
            <div class="col">
              {{ "NAVIGATION.NAME" | translate }}
            </div>
            <div class="bg-primary" style="height: 2px"></div>
          </div>
          <div
            class="row py-2 px-2 mx-4 rounded"
            *ngFor="let point of points; index as i"
            [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
          >
            <div class="col">{{ point.nameLang }}</div>
            <div class="col d-flex flex-row justify-content-end">
              <div class="mx-2" style="height: 1.5rem; width: 1.5rem">
                <img
                  *ngIf="point.refs.includes('CROPS')"
                  class="w-100 h-100"
                  style="object-fit: contain"
                  src="../../../../assets/images/crop.svg"
                />
              </div>
              <div class="mx-2" style="height: 1.5rem; width: 1.5rem">
                <img
                  *ngIf="point.refs.includes('LIVESTOCK')"
                  class="w-100 h-100"
                  style="object-fit: contain"
                  src="../../../../assets/images/livestock.svg"
                />
              </div>
              <div class="mx-2" style="height: 1.5rem; width: 1.5rem">
                <img
                  *ngIf="point.refs.includes('AQUATICS')"
                  class="w-100 h-100"
                  style="object-fit: contain"
                  src="../../../../assets/images/aquaculture.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
