import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'modal-me-intervention-answers',
  templateUrl: './modal-me-intervention-answers.component.html',
  styleUrls: ['./modal-me-intervention-answers.component.scss'],
})
export class ModalMeInterventionAnswersComponent implements OnInit {
  answers?: any[];
  title?: string;

  constructor(
    public modalRef: MdbModalRef<ModalMeInterventionAnswersComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}
}
