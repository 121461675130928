import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit {
  advancedColorMap: any[] = [];

  countries: any[] = [];

  legendMin: string | null = null;
  legendMax: string | null = null;

  activeField: any = 'total';

  colors: any = {
    total: [
      '#80B6DA',
      '#60A4D1',
      '#4092C8',
      '#207FBE',
      '#006DB5',
      '#005F9E',
      '#005288',
      '#004471',
      '#00375A',
    ],
    projectCrops: [
      '#F6B993',
      '#F4A777',
      '#F2955C',
      '#EF8441',
      '#ED7226',
      '#CF6421',
      '#B2561D',
      '#944718',
      '#763913',
    ],
    projectAquatics: [
      '#C1DDAB',
      '#B2D495',
      '#A2CC80',
      '#93C36B',
      '#83BB56',
      '#73A44B',
      '#628C41',
      '#527536',
      '#425D2B',
    ],
    projectLivestock: [
      '#A3B59B',
      '#8CA381',
      '#759168',
      '#5E7E4F',
      '#476C36',
      '#3E5F2F',
      '#355129',
      '#2C4422',
      '#24361B',
    ],
  };

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.api.getStatsCountries().subscribe((value) => {
      this.countries = value as any[];
      this.advancedColorMap = [...this.getColorMap()];
    });
  }

  private getColorMap() {
    const countriesEnable = this.countries.filter(
      (value) => value[this.activeField] > 0
    );
    const values = countriesEnable.map((entry: any) => entry[this.activeField]);

    let max = Math.max(...values);
    let min = Math.min(...values);

    if (min === -max) {
      min = 1;
      max = 10;
    }

    if (max - min < 9) max = min + 9;

    this.legendMin = `${min}`;
    this.legendMax = `${max}`;

    const step = Math.floor(
      (max - min) / (this.colors[this.activeField].length - 1)
    );

    const colorMap: any[] = this.colors[this.activeField].map(
      (color: string, i: number) => {
        return {
          fill: color,
          regions: [],
        };
      }
    );

    values.forEach((value, i) => {
      const color = Math.floor((value - min) / step);
      colorMap[color].regions.push({
        id: countriesEnable[i].cnIso2,
        tooltip: value + (value == 1 ? ' Project' : ' Projects'),
        ...countriesEnable[i],
      });
    });

    return colorMap;
  }

  getField() {
    switch (this.activeField) {
      case 'total':
        return 'Total';
      case 'projectCrops':
        return 'Crop';
      case 'projectLivestock':
        return 'Livestock';
      case 'projectAquatics':
        return 'Aquaculture';
      default:
        return 'Total';
    }
  }

  changeField(field: string) {
    this.activeField = field;
    this.advancedColorMap = [...this.getColorMap()];
  }
}
