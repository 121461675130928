<div class="modal-header">
  <h2 class="modal-title mx-auto text-primary">{{ uuid }}</h2>
</div>
<div class="modal-body">
  <div class="w-100 d-flex flex-row justify-items-center">
    <ngx-qrcode
      class="mx-auto"
      [elementType]="elementType"
      [value]="uuid || ''"
      cssClass="mx-auto"
      [errorCorrectionLevel]="correction"
    >
    </ngx-qrcode>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </button>
</div>
