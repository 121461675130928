import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { Crop } from 'src/app/models/crop';

@Component({
  selector: 'item-page',
  templateUrl: './item-page.component.html',
  styleUrls: ['./item-page.component.scss'],
})
export class ItemPageComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  header: string = '';
  databaseType?: string;
  cropID: string = '';
  livestockID: string = '';
  aquacultureID: string = '';

  item: any;
  species: Array<any> = [];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getDatabaseType();
    this.getItem();
    this.getSpecies();

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.getItem();
      this.getSpecies();
    });
  }
  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  getDatabaseType() {
    const path = this.router.url;
    this.databaseType = path.split('/')[3];
  }

  getSpecies() {
    this.api
      .getSpecies(this.cropID, this.aquacultureID, this.livestockID)
      .subscribe((value) => {
        this.species = value as Array<any>;
      });
  }

  getItem() {
    switch (this.databaseType) {
      case 'c':
        this.header = 'ITEM.VARIETIES';
        this.cropID = this.route.snapshot.paramMap.get('id') || '';
        this.api.getCrop(this.cropID).subscribe((value) => {
          this.item = value as any;
        });
        break;
      case 'l':
        this.header = 'ITEM.BREEDS';
        this.livestockID = this.route.snapshot.paramMap.get('id') || '';
        this.api.getLivestock(this.livestockID).subscribe((value) => {
          this.item = value as any;
        });
        break;
      case 'a':
        this.header = 'ITEM.FARMED_TYPES';
        this.aquacultureID = this.route.snapshot.paramMap.get('id') || '';
        this.api.getAquatic(this.aquacultureID).subscribe((value) => {
          this.item = value as any;
        });
        break;

      default:
        break;
    }
  }

  getDatabaseNavigatorName(): string {
    switch (this.databaseType) {
      case 'c':
        return 'CROP';
      case 'l':
        return 'LIVESTOCK';
      case 'a':
        return 'AQUACULTURE';
      default:
        return '';
    }
  }

  getName(): string {
    switch (this.databaseType) {
      case 'c':
        return this.item?.crop;
      case 'l':
        return this.item?.livestock;
      case 'a':
        return this.item?.aquatic;
      default:
        return '';
    }
  }
}
