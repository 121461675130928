<div class="modal-body vh-100 p-0 m-0">
  <div class="d-flex flex-column justify-content-between w-100 h-100 bg-light">
    <div class="m-3 mb-0">
      <div class="d-flex flex-row">
        <i class="bi bi-send-plus-fill h2 mx-1"></i>
        <h2 class="mx-1">{{ "PROJECTS.REQUESTS" | translate }}:</h2>
      </div>
      <div class="bg-primary mx-1" style="height: 2px"></div>
    </div>

    <div class="h-100" style="overflow-y: auto">
      <div
        class="card mx-4 my-2 p-3 d-flex d-flex-column justify-content-between"
        style="height: 12rem"
        *ngFor="let request of requests"
      >
        <h4 [mdbTooltip]="request.title || ''">
          {{ shrinkRequest(request.title) }}
        </h4>
        <h6>ID: {{ request.uuid }}</h6>
        <h6>{{ "PROJECTS.FROM" | translate }}: {{ request.sharedBy }}</h6>
        <h6>
          {{ "USER.ROLE" | translate }}:
          {{ "PROJECTS." + request.projectRole | translate }}
        </h6>
        <div class="d-flex justify-content-between">
          <div
            class="btn btn-danger"
            (click)="openDeclineRequest(request.projectID || '')"
          >
            {{ "BUTTONS.DECLINE" | translate }}
          </div>
          <div
            class="btn btn-success"
            (click)="acceptRequest(request.projectID || '')"
          >
            {{ "BUTTONS.ACCEPT" | translate }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="btn btn-primary mx-2 mb-2 mb-4"
      (click)="goToDeclinedRequests()"
    >
      {{ "PROJECTS.SEE_ALL_DECLINED" | translate }}
    </div>
  </div>
</div>
