<div class="w-100 d-flex flex-row justify-content-center align-items-center">
  <div class="mx-2" style="height: 8rem; width: 8rem">
    <img
      src="../../../../assets/images/logo.png"
      class="w-100 h-100"
      style="object-fit: contain"
    />
  </div>
  <div>
    <h4 class="mx-2 my-auto">{{ text | translate }}</h4>
  </div>
</div>
