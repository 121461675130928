<div class="modal-body">
  <div class="d-flex flex-row">
    <i class="bi bi-shield-lock-fill h3"></i>
    <h4 class="mx-1 h3 mb-0">{{ "ROLES.CHANGE" | translate }}</h4>
  </div>
  <div class="bg-primary w-100" style="height: 2px"></div>
  <div class="d-flex flex-column my-2 align-items-center">
    <h5>{{ "ROLES.DISCLAIMER" | translate }}:</h5>
    <h4>
      {{
        (this.user?.currentRole?.role === "USER"
          ? "ROLES.PARTNERS"
          : "ROLES.USER"
        ) | translate
      }}
    </h4>
  </div>
  <div class="d-flex flex-row justify-content-between">
    <div class="btn btn-primary mx-1" (click)="modalRef.close(false)">
      {{ "BUTTONS.CLOSE" | translate }}
    </div>
    <div class="btn btn-success mx-1" (click)="changeRole()">
      {{ "BUTTONS.YES" | translate }}
    </div>
  </div>
</div>
