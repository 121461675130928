import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { WorkingArea } from 'src/app/working-area/answering-page/answering-page.component';

@Component({
  selector: 'dashboard-partner',
  templateUrl: './dashboard-partner.component.html',
  styleUrls: ['./dashboard-partner.component.scss'],
})
export class DashboardPartnerComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  latestWorkingAreas: WorkingArea[] = [];

  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.apiCall();

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.apiCall();
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  apiCall() {
    this.api.getMyLastWorkingAreas().subscribe((value) => {
      this.latestWorkingAreas = value as WorkingArea[];
    });
  }

  openWorkingArea(workID: string) {
    this.router.navigateByUrl('/working-area/' + workID);
  }

  switchCardIcon(ref: string): string {
    switch (ref) {
      case 'CROPS':
        return 'crop.svg';
      case 'LIVESTOCK':
        return 'livestock.svg';
      default:
        return 'crop.svg';
    }
  }

  getNumberOf(count: number, ref: string): string {
    switch (ref) {
      case 'CROPS':
        return count == 1 ? 'DASHBOARD.VARIETY' : 'DASHBOARD.VARIETIES';
      case 'LIVESTOCK':
        return count == 1 ? 'DASHBOARD.BREED' : 'DASHBOARD.BREEDS';
      default:
        return count == 1 ? 'DASHBOARD.VARIETY' : 'DASHBOARD.VARIETIES';
    }
  }
}
