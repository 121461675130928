import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { Pageable } from 'src/app/models/pageable';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'period-data-analysis',
  templateUrl: './period-data-analysis.component.html',
  styleUrls: ['./period-data-analysis.component.scss'],
})
export class PeriodDataAnalysisComponent implements OnInit {
  selectForm?: UntypedFormGroup;
  filterForm?: UntypedFormGroup;
  pathRef?: string;

  refIDs?: Select[];
  sites?: Select[];

  pageable?: Pageable;
  currentPage: number = 0;
  stats?: any[];

  periodID?: string;
  valueChanging: boolean = false;

  // titles: string[] = [
  //   'FGD: Total number of varieties by type (traditional, modern, improved)',
  //   'FGD: Number varieties that require no or low levels of pesticides and herbicides',
  //   'HHS: Total number of varieties supplied by type (traditional, modern, improved)',
  //   'FGD: Enough planting material (from FGD): total number of answer and % enough planting material',
  //   'FGD: Constraints to access: total number of answers (when at least one answer for one supplier) and (%) list of all constraints selected when total % not zero',
  // ];

  activitiesList: any[] = [
    { label: 'Focus Group Discussion', value: 'FGD' },
    { label: 'House Hold Survey', value: 'HHS' },
    { label: 'Key Informant Survey', value: 'KIS' },
  ];

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.body.scrollHeight
    ) {
      if (this.currentPage < (this.pageable?.totalPages ?? 1) - 1) {
        this.currentPage++;
        this.api
          .getProjectStats(
            this.periodID!,
            this.filter?.value,
            this.activity?.value,
            this.currentPage
          )
          .subscribe((value) => {
            const v = value as Pageable;
            this.stats = this.stats?.concat(v.content);
          });
      }
      return;
    }
  }

  ngOnInit(): void {
    this.periodID = this.route.parent?.snapshot.paramMap.get('id') as string;
    this.pathRef = this.route.parent?.snapshot.paramMap.get('ref') as string;

    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null),
      activity: new UntypedFormControl(null),
    });

    this.selectForm = new UntypedFormGroup({
      familyID: new UntypedFormControl(null),
      site: new UntypedFormControl(null),
    });

    this.api.getFamiliesByPeriod(this.periodID).subscribe((value) => {
      this.refIDs = value as Select[];
      this.familyID?.setValue(this.refIDs[0].value);
    });

    this.api.getSitesByPeriod(this.periodID).subscribe((value) => {
      this.sites = value as Select[];
    });

    this.api
      .getProjectStats(this.periodID, null, null, this.currentPage)
      .subscribe((value) => {
        this.pageable = value as Pageable;
        this.stats = this.pageable.content;
      });

    this.selectForm.valueChanges.subscribe(() => {
      this.valueChanging = true;
      setTimeout(() => (this.valueChanging = false), 100);
    });

    this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      this.currentPage = 0;
      this.api
        .getProjectStats(
          this.periodID!,
          this.filter?.value,
          this.activity?.value
        )
        .subscribe((value) => {
          this.pageable = value as Pageable;
          this.stats = this.pageable.content;
        });
    });

    window.addEventListener('window.scroll', this.loadMore);
  }

  loadMore = (event: any): void => {
    console.log(event);
  };

  get familyID() {
    return this.selectForm?.get('familyID');
  }

  get site() {
    return this.selectForm?.get('site');
  }

  get filter() {
    return this.filterForm?.get('filter');
  }

  get activity() {
    return this.filterForm?.get('activity');
  }
}
