<div class="d-flex flex-column align-items-center justify-content-center">
  <div
    class="card py-3 d-flex flex-column justify-content-center align-items-center bg-light hoverClass"
    [ngClass]="cardWidth"
    style="height: 10rem"
    (click)="flip()"
  >
    <div id="front" class="w-100" *ngIf="!isFlipped">
      <div
        class="d-flex align-items-center justify-content-center bg-secondary mb-2 text-white mx-auto"
        style="
          height: 3.7rem;
          width: 3.7rem;
          border-radius: 50%;
          border: 2.2px solid rgb(119, 124, 153);
          overflow: hidden;
        "
      >
        <i class="bi bi-plus h1 my-auto"></i>
      </div>
      <div class="d-flex mb-2 text-center w-75 mx-auto">
        <p class="p-0 m-0 mx-auto h5">{{ "CHANGE_COUNTRY.ADD" | translate }}</p>
      </div>
    </div>
    <div id="back px-0 mx-0" *ngIf="isFlipped">
      <div
        class="d-flex flex-column justify-content-between align-items-center w-100"
      >
        <p class="p-0 mb-1 mx-auto">{{ "CHANGE_COUNTRY.ADD" | translate }}:</p>
        <form [formGroup]="addCountryForm!">
          <div class="px-2">
            <ng-select
              class="control"
              formControlName="country"
              [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
            >
              <ng-option
                *ngFor="let country of countrySelect"
                [value]="country.value"
              >
                {{ country.label }}
              </ng-option>
            </ng-select>
          </div>
        </form>
        <div
          class="btn btn-primary text-white mt-2"
          style="background-color: green"
          (click)="add()"
        >
          Add
        </div>
      </div>
    </div>
  </div>
</div>
