import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DashboardListener {
  private dataSource: BehaviorSubject<DashboardHelper> =
    new BehaviorSubject<DashboardHelper>(new DashboardHelper());

  data: Observable<DashboardHelper> = this.dataSource.asObservable();

  constructor() {}

  setData(data: DashboardHelper) {
    this.dataSource.next(data);
  }
}

export class DashboardHelper {
  userRole?: string;

  constructor() {
    this.userRole;
  }
}

@Injectable({ providedIn: 'root' })
export class SidenavListener {
  private dataSource: BehaviorSubject<SidenavHelper> =
    new BehaviorSubject<SidenavHelper>(new SidenavHelper());

  data: Observable<SidenavHelper> = this.dataSource.asObservable();

  constructor() {}

  setData(data: SidenavHelper) {
    this.dataSource.next(data);
  }
}

export class SidenavHelper {
  userRef?: string;

  constructor() {
    this.userRef;
  }
}
