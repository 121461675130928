import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { User } from 'src/app/auth/models/user.model';
import { AppState } from 'src/app/auth/reducer';
import { getUser } from 'src/app/auth/store/auth.selectors';
import { ModalDeclineRequestComponent } from 'src/app/modals/projects/modal-decline-request/modal-decline-request.component';
import { ModalDeleteProjectComponent } from 'src/app/modals/projects/modal-delete-project/modal-delete-project.component';
import { ModalNewProjectComponent } from 'src/app/modals/projects/modal-new-project/modal-new-project.component';
import {
  ProjectPageHelper,
  ProjectPageListener,
} from 'src/app/models/project-page-helper';
import { Project } from 'src/app/models/project';
import { ProjectRequestsComponent } from '../components/project-requests/project-requests.component';

@Component({
  selector: 'projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss'],
})
export class MyProjectsPageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  lang$?: Subscription;
  user?: User | null;
  projects?: Array<Project>;
  country?: string;

  requests?: Project[];
  hideRequests: boolean = true;

  projectPageHelper$?: Subscription;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private api: ApiService,
    private modalService: MdbModalService,
    private projectPageListener: ProjectPageListener,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store.select(getUser).subscribe((user) => {
      this.user = user;
      this.country = user?.currentRole.country?.shortName;
    });

    this.getProjects();

    this.projectPageHelper$ = this.projectPageListener.data.subscribe(
      (data: ProjectPageHelper) => {
        if (data.countryChanges || data.projectRemoved) {
          this.getProjects();
        }
      }
    );

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.getProjects();
    });
  }

  ngAfterViewInit(): void {
    this.hideRequests = false;
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
    this.projectPageHelper$?.unsubscribe();
  }

  getProjects() {
    this.api.getProjectsWithDetail().subscribe((value) => {
      this.projects = value as Array<Project>;
    });
    this.api.getPendingRequests().subscribe((value) => {
      this.requests = value as Project[];
    });
  }

  createNewProject() {
    const modalRef = this.modalService.open(ModalNewProjectComponent, {
      data: {
        currentCountry: this.user?.currentRole.country?.countryID,
      },
      modalClass: 'modal-lg',
      ignoreBackdropClick: true,
    });

    modalRef.onClose.subscribe((refresh: boolean) => {
      if (refresh) {
        this.api.getProjectsWithDetail().subscribe((value) => {
          this.projects = value as Array<Project>;
        });
      }
    });
  }

  deleteProject(project: Project) {
    const projectID = project.projectID;
    const modalRef = this.modalService.open(ModalDeleteProjectComponent, {
      data: {
        projectID: projectID,
      },
      modalClass: 'modal-lg',
    });

    modalRef.onClose.subscribe((refresh: boolean) => {
      if (refresh) {
        this.api.getProjectsWithDetail().subscribe((value) => {
          this.projects = value as Array<Project>;
        });
      }
    });
  }

  shrinkString(string: string | undefined) {
    if (string?.length && string.length <= 60) return string;
    return string?.substring(0, 57) + ' ...';
  }

  shrinkRequest(string: string | undefined) {
    if (string?.length && string.length <= 30) return string;
    return string?.substring(0, 27) + ' ...';
  }

  openRequests() {
    const modalRef = this.modalService.open(ProjectRequestsComponent, {
      containerClass: '',
      modalClass: 'modal-side end-0 top-0',
    });

    modalRef.onClose.subscribe((value) => {
      if (value) this.getProjects();
    });
  }

  openDeclinRequest(projectID: string) {
    const modalRef = this.modalService.open(ModalDeclineRequestComponent, {
      data: {
        projectID: projectID,
      },
      modalClass: 'modal-lg',
    });

    modalRef.onClose.subscribe((refresh: boolean) => {
      if (refresh) {
        this.api.getPendingRequests().subscribe((value) => {
          this.requests = value as Project[];
        });
      }
    });
  }

  acceptRequest(projectID: string) {
    this.api.acceptProjectUser(projectID).subscribe(
      () => {
        this.getProjects();
      },
      (error) => {}
    );
  }

  declineRequest(projectID: string) {
    this.api.rejectProjectUser(projectID).subscribe(() => {
      this.api.getPendingRequests().subscribe((value) => {
        this.requests = value as Project[];
      });
    });
  }

  goToDeclinedRequests() {
    this.router.navigateByUrl('u/projects/declined-requests');
  }

  // toggleRequests(){
  //   sidenav.toggle()
  // }
}
