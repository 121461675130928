import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ProjectPermissions {
  projectRole: string;
  addSite: boolean;
  editSite: boolean;
  editTitle: boolean;
  shareProject: boolean;
  own: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectPageListener {
  private dataSource: BehaviorSubject<ProjectPageHelper> =
    new BehaviorSubject<ProjectPageHelper>(new ProjectPageHelper());

  data: Observable<ProjectPageHelper> = this.dataSource.asObservable();

  constructor() {}

  setData(data: ProjectPageHelper) {
    this.dataSource.next(data);
  }
}

export class ProjectPageHelper {
  siteAdded?: boolean;
  userAdded?: boolean;
  projectRemoved?: boolean;
  countryChanges?: boolean;
  projectPermissions?: ProjectPermissions;

  constructor() {
    this.siteAdded = false;
    this.userAdded = false;
    this.projectRemoved = false;
    this.countryChanges = false;
  }

  clear() {
    this.siteAdded = false;
    this.userAdded = false;
    this.projectRemoved = false;
    this.countryChanges = false;
  }
}
