import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'modal-ot-info',
  templateUrl: './modal-ot-info.component.html',
  styleUrls: ['./modal-ot-info.component.scss'],
})
export class ModalOtInfoComponent implements OnInit {
  item?: any;

  constructor(
    public modalRef: MdbModalRef<ModalOtInfoComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}
}
