import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss'],
})
export class ModalChangePasswordComponent implements OnInit {
  passwordForm?: UntypedFormGroup;

  error: string = '';

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalChangePasswordComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.passwordForm = new UntypedFormGroup({
      currentPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(5),
      ]),
      password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(5),
      ]),
      confirmPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(5),
      ]),
    });
  }

  get currentPassword() {
    return this.passwordForm?.get('currentPassword');
  }
  get password() {
    return this.passwordForm?.get('password');
  }
  get confirmPassword() {
    return this.passwordForm?.get('confirmPassword');
  }

  updatePassword() {
    if (this.passwordForm?.invalid) {
      this.passwordForm.markAllAsTouched();
    } else {
      this.api.updatePassword(this.passwordForm?.value).subscribe(
        () => {
          this.modalRef.close();
        },
        (error) => {
          if (error.status == 403) {
            this.error = 'Wrong password.';
          } else if (error.status == 412) {
            this.error = 'New passwords do not match.';
          } else {
            this.error = 'Something went wrong';
          }
        }
      );
    }
  }
}
