import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { ModalCleaningDescriptorsComponent } from '../modals/variety-cleaning/modal-cleaning-descriptors/modal-cleaning-descriptors.component';
import { ModalConfirmMarkCleanedComponent } from '../modals/variety-cleaning/modal-confirm-mark-cleaned/modal-confirm-mark-cleaned.component';
import { ModalEditProjectVarietyTypeComponent } from '../modals/variety-cleaning/modal-edit-project-variety-type/modal-edit-project-variety-type.component';
import { ModalHistoryComponent } from '../modals/variety-cleaning/modal-history/modal-history.component';
import { ModalJoinVarietiesComponent } from '../modals/variety-cleaning/modal-join-varieties/modal-join-varieties.component';
import { ModalRenameCleaningComponent } from '../modals/variety-cleaning/modal-rename-cleaning/modal-rename-cleaning.component';
import { ModalSplitVarietiesComponent } from '../modals/variety-cleaning/modal-split-varieties/modal-split-varieties.component';
import { ModalVarietiesInfoComponent } from '../modals/variety-cleaning/modal-varieties-info/modal-varieties-info.component';
import { Crop } from '../models/crop';
import { Site } from '../models/project';
import { VarietyCleaning } from '../models/variety-cleaning.types';
import { ModalShowVarietyImagesComponent } from '../modals/sites/modal-show-variety-images/modal-show-variety-images.component';

@Component({
  selector: 'variety-cleaning',
  templateUrl: './variety-cleaning.component.html',
  styleUrls: ['./variety-cleaning.component.scss'],
})
export class VarietyCleaningComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  state = 0;

  filterForm?: UntypedFormGroup;
  projectID?: string;
  familyID?: string;
  ref?: string;

  crop?: Crop;

  sites?: Site[];

  varietyCleaningList?: VarietyCleaning[];
  varietiesSelected: VarietyCleaning[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.ref = this.route.snapshot.paramMap.get('ref') ?? '';
    this.projectID = this.route.snapshot.paramMap.get('projectID') ?? '';
    this.familyID = this.route.snapshot.paramMap.get('familyID') ?? '';

    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null),
      siteID: new FormControl(null),
    });

    this.getSites();

    // this.apiCall();
    this.checkEditing();

    this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.apiCall();
    });

    this.api.getCrop(this.familyID).subscribe((value) => {
      this.crop = value as Crop;
    });
  }

  ngOnDestroy(): void {
    this.stopCleaning();
  }

  getSites() {
    this.api.getSites(this.projectID ?? '').subscribe((value) => {
      this.sites = value as Site[];
    });
  }

  checkEditing() {
    this.api
      .createVarietiesCleaning(this.projectID!, this.familyID!)
      .subscribe({
        next: (value) => {
          this.loading = false;
          this.state = 1;
        },
        error: (error) => {
          if (error.status == 409) {
            this.loading = false;
            this.state = 2;
          }
        },
      });
  }

  stopCleaning() {
    if (this.state != 2) {
      this.api
        .stopVarietiesCleaning(this.projectID!, this.familyID!)
        .subscribe((value) => {});
    }
  }

  apiCall() {
    this.loading = true;
    this.api
      .getVarietiesCleaning(
        this.projectID!,
        this.familyID!,
        this.filter?.value,
        this.siteID?.value
      )
      .subscribe((value) => {
        this.varietyCleaningList = value as VarietyCleaning[];
        this.state = 3;
        this.loading = false;
      });
  }

  addVariety(index: number) {
    this.varietiesSelected.push(this.varietyCleaningList![index]);
  }

  removeVariety(uid: string) {
    let i = -1;
    for (let index = 0; index < this.varietiesSelected.length; index++) {
      if (this.varietiesSelected[index].projectVarietyID == uid) i = index;
    }
    if (i != -1) this.varietiesSelected.splice(i, 1);
  }

  removeVarietyFromSelected(index: number) {
    this.varietiesSelected.splice(index, 1);
  }

  isSelected(uniqueID: string): boolean {
    let result = false;
    this.varietiesSelected.forEach((e: VarietyCleaning) => {
      if (e.projectVarietyID === uniqueID) result = true;
    });
    return result;
  }

  goBack() {
    this.router.navigateByUrl(
      `u/project/${this.ref}/${this.projectID}/variety-cleaning`
    );
  }

  areAllCleaned(element: any, index: number, array: any[]) {
    return element.cleaned == true;
  }

  openRename() {
    const modalRef = this.modalService.open(ModalRenameCleaningComponent, {
      data: {
        projectID: this.projectID,
        varietiesSelected: this.varietiesSelected,
      },
    });
    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.varietiesSelected = [];
        this.apiCall();
      }
    });
  }

  openHistory(projectVarietyID: string) {
    const modalRef = this.modalService.open(ModalHistoryComponent, {
      data: {
        projectID: this.projectID,
        projectVarietyID: projectVarietyID,
      },
      modalClass: 'modal-lg',
    });
    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.varietiesSelected = [];
        this.apiCall();
      }
    });
  }

  openSplit() {
    const modalRef = this.modalService.open(ModalSplitVarietiesComponent, {
      data: {
        projectID: this.projectID,
        varietySelected: this.varietiesSelected[0],
      },
      modalClass: 'modal-lg',
    });
    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.varietiesSelected = [];
        this.apiCall();
      }
    });
  }

  openJoin() {
    const modalRef = this.modalService.open(ModalJoinVarietiesComponent, {
      data: {
        projectID: this.projectID,
        varietiesSelected: this.varietiesSelected,
        familyID: this.familyID,
      },
      modalClass: 'modal-xl',
    });
    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.varietiesSelected = [];
        this.apiCall();
      }
    });
  }

  openVarietiesInfo() {
    this.modalService.open(ModalVarietiesInfoComponent, {
      data: {
        varietiesSelected: this.varietiesSelected,
      },
      modalClass: 'modal-lg',
    });
  }

  openDescriptors() {
    this.modalService.open(ModalCleaningDescriptorsComponent, {
      modalClass: 'modal-fullscreen',
      data: {
        projectID: this.projectID,
        varietiesSelectedIDs: this.getSelectedVarietiesIDs(),
        familyID: this.familyID,
      },
    });
  }

  openMarkAll() {
    const modalRef = this.modalService.open(ModalConfirmMarkCleanedComponent, {
      data: {
        projectID: this.projectID,
        familyID: this.familyID,
      },
      modalClass: 'modal-lg',
    });
    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.varietiesSelected = [];
        this.apiCall();
      }
    });
  }

  openEditVarietyType(entity: any, index: number) {
    const modalRef = this.modalService.open(
      ModalEditProjectVarietyTypeComponent,
      {
        data: {
          projectID: this.projectID,
          cropID: this.crop?.cropID,
          entity: entity,
          name: entity.name,
          ref: 'CROPS',
        },
        modalClass: 'modal-lg',
      }
    );
    modalRef.onClose.subscribe((message) => {
      if (message != null && this.varietyCleaningList) {
        this.varietyCleaningList[index] = message as VarietyCleaning;
      }
    });
  }

  getSelectedVarietiesIDs(): string[] {
    let list: string[] = [];
    this.varietiesSelected.forEach((e) => {
      list.push(e.projectVarietyID);
    });
    return list;
  }

  openShowImages(appID: string) {
    this.modalService.open(ModalShowVarietyImagesComponent, {
      data: {
        refID: appID,
        projectID: this.projectID,
      },
      modalClass: 'modal-fullscreen',
    });
  }

  get filter() {
    return this.filterForm?.get('filter');
  }
  get siteID() {
    return this.filterForm?.get('siteID');
  }
}
