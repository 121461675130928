<nav aria-label="Page navigation example">
  <ul class="pagination pg-blue justify-content-center">
    <li class="page-item" [class.disabled]="pageable?.first">
      <a class="page-link" (click)="onFirstClick()" mdbWavesEffect>
        {{ "PAGING.FIRST" | translate }}
      </a>
    </li>

    <li
      class="page-item"
      [class.disabled]="pageable?.first"
      style="cursor: pointer"
    >
      <a
        class="page-link"
        aria-label="Previous"
        mdbWavesEffect
        (click)="onPreviousClick()"
        title="{{ 'PAGING.PREVIOUS' | translate }}"
      >
        <!-- <span aria-hidden="true">&laquo;</span> -->
        <i class="bi bi-caret-left-fill h5"></i>
        <span class="sr-only">{{ "PAGING.PREVIOUS" | translate }}</span>
      </a>
    </li>

    <li
      class="page-item"
      [class.active]="page == (pageable?.number || 0) + 1"
      *ngFor="let page of pages"
      style="cursor: pointer"
    >
      <a class="page-link" mdbWavesEffect (click)="onPageClick(page - 1)">
        {{ page }}
      </a>
    </li>

    <li
      class="page-item"
      [class.disabled]="pageable?.last"
      style="cursor: pointer"
    >
      <a
        class="page-link"
        aria-label="Next"
        mdbWavesEffect
        (click)="onNextClick()"
        title="{{ 'PAGING.NEXT' | translate }}"
      >
        <!-- <span aria-hidden="true">&raquo;</span> -->
        <i class="bi bi-caret-right-fill h5"></i>
        <span class="sr-only">{{ "PAGING.NEXT" | translate }}</span>
      </a>
    </li>

    <li
      class="page-item"
      [class.disabled]="pageable?.last"
      style="cursor: pointer"
    >
      <a class="page-link" mdbWavesEffect (click)="onLastClick()">
        {{ "PAGING.LAST" | translate }}
      </a>
    </li>
  </ul>
</nav>
