import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { ModalFileAnalysisComponent } from 'src/app/modals/modal-file-analysis/modal-file-analysis.component';
import { RScript } from 'src/app/models/r-script';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'file-analysis-crop',
  templateUrl: './file-analysis-crop.component.html',
  styleUrls: ['./file-analysis-crop.component.scss'],
})
export class FileAnalysisCropComponent implements OnInit, OnDestroy {
  lang$?: Subscription;

  filterForm?: UntypedFormGroup;
  rScripts?: RScript[];
  ots?: Select[];
  loaded: boolean = false;
  constructor(
    private api: ApiService,
    private modalService: MdbModalService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null, []),
      refID: new UntypedFormControl(null, []),
    });

    this.apiCall();
    this.loaded = true;

    this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      this.apiCall(this.filter?.value, this.refID?.value);
    });

    this.api.getOtScripts('CROPS').subscribe((value) => {
      this.ots = value as Select[];
    });

    this.lang$ = this.translate.onLangChange.subscribe((_) => {
      this.apiCall();
      this.api.getOtScripts('CROPS').subscribe((value) => {
        this.ots = value as Select[];
      });
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  apiCall(filter?: string, refID?: string) {
    this.api.getRScripts('CROPS', filter, refID).subscribe((value) => {
      this.rScripts = value as RScript[];
    });
  }

  getOts(ots: string[]): string {
    let display: string = '';
    ots.forEach((item, index) => {
      if (index != ots.length - 1) {
        display += item + ' - ';
        return;
      }
      display += item;
    });
    return display;
  }

  openFile(script: RScript) {
    this.modalService.open(ModalFileAnalysisComponent, {
      data: { script: script },
    });
  }

  get filter() {
    return this.filterForm?.get('filter');
  }

  get refID() {
    return this.filterForm?.get('refID');
  }
}
