<div class="row mt-3 mb-5 mx-2 gap-3">
  <div
    class="col d-flex bg-white card p-0 m-0"
    style="height: 50vh; overflow-y: auto"
  >
    <list-header
      class="sticky-top p-0 m-0"
      title="{{ 'NAVIGATION.CATEGORY' | translate }}"
    ></list-header>

    <div class="d-flex flex-column p-0 m-0">
      <div
        class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2"
        [ngClass]="type.selected ? 'bg-secondary text-white' : 'hover'"
        *ngFor="let type of types; let i = index"
        (click)="onSelectType(i)"
      >
        <h5 class="px-3 py-2 my-auto">
          {{ type.categoryLang }}
        </h5>
        <i class="bi bi-caret-right-fill h5 px-3 py-2 my-auto"></i>
      </div>
    </div>
  </div>

  <div class="col d-flex bg-white card" style="height: 50vh; overflow-y: auto">
    <list-header
      class="sticky-top p-0 m-0"
      title="{{ 'NAVIGATION.SUB_CATEGORY' | translate }}"
    ></list-header>

    <database-table-placeholder
      *ngIf="subTypes.length == 0"
      class="h-100"
      [image]="getImage()"
      text="DATABASE.SELECT_A_CATEGORY"
    ></database-table-placeholder>

    <div class="d-flex flex-column p-0 m-0">
      <div
        class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2"
        [ngClass]="type.selected ? 'bg-secondary text-white' : 'hover'"
        *ngFor="let type of subTypes; let i = index"
        (click)="loadDescriptors(i)"
      >
        <h5 class="px-3 py-2 my-auto">
          {{ type.categoryLang }}
        </h5>
        <i class="bi bi-caret-right-fill h5 px-3 py-2 my-auto"></i>
      </div>
    </div>
  </div>

  <div class="col d-flex bg-white card" style="height: 50vh; overflow-y: auto">
    <list-header
      class="sticky-top p-0 m-0"
      title="{{ 'NAVIGATION.DESCRIPTORS' | translate }}"
    ></list-header>

    <database-table-placeholder
      *ngIf="descriptors.length == 0"
      class="h-100"
      [image]="getImage()"
      text="DATABASE.SELECT_A_SUB_CATEGORY"
    ></database-table-placeholder>

    <div class="d-flex flex-column p-0 m-0">
      <div
        class="d-flex flex-column justify-content-between py-2 rounded-2 mx-2"
        [ngClass]="descriptor.selected ? 'bg-secondary text-white' : 'hover'"
        *ngFor="let descriptor of descriptors; let i = index"
        (click)="selectDescriptor(i)"
      >
        <h5 class="px-3 py-2 my-auto">
          {{ descriptor.nameLang }}
        </h5>
        <div
          class="d-flex flex-row align-items-center animate info-hover px-2"
          *ngIf="descriptor.selected"
        >
          <i class="bi bi-info-circle-fill p my-auto"></i>
          <p class="p-0 m-0 my-auto mx-2">{{ descriptor.questionLang }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
