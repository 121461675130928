<div class="h-100 d-flex flex-column justify-content-center align-items-center">
  <div style="height: 8rem; width: 8rem">
    <img
      class="w-100 h-100 mb-3"
      style="object-fit: contain"
      src="{{ image }}"
    />
  </div>
  <div
    class="
      d-flex
      flex-row
      justify-content-between
      text-secondary
      align-items-center
    "
  >
    <i class="mt-3 bi mx-1 bi-arrow-left-short h2"></i>
    <h2 class="mt-3 mx-1">{{ text | translate }}</h2>
  </div>
</div>
