<div class="modal-header">
  <div class="d-flex flex-row align-items-center">
    <i class="bi-arrows-expand h4 my-auto"></i>
    <h4 class="modal-title my-auto mx-2">
      {{ "VARIETY_CLEANING.SPLIT" | translate }}
    </h4>
  </div>
</div>
<div class="modal-body d-flex flex-column mx-4" *ngIf="errorLabel">
  <div class="d-flex">
    <i class="bi-exclamation-square-fill h4 me-2 text-danger"></i>
    <h4 class="text-danger">{{ "VARIETY_CLEANING.ERROR" | translate }}</h4>
  </div>
  <h5>
    {{ "VARIETY_CLEANING.ERROR_DISCLAIMER" | translate }}
  </h5>
  <div class="btn btn-primary me-auto" (click)="tryAgain()">
    {{ "VARIETY_CLEANING.TRY_AGAIN" | translate }}
  </div>
</div>
<div
  class="modal-body d-flex flex-column"
  [formGroup]="varietiesForm!"
  *ngIf="!errorLabel"
>
  <h5 class="mb-2">{{ "VARIETY_CLEANING.DIFF" | translate }}</h5>
  <div class="d-flex flex-wrap w-100" *ngIf="varietySelected">
    <div
      class="d-flex align-items-center justify-content-center me-2 mb-2"
      *ngFor="
        let item of [].constructor(varietySelected!.sites.length - 1);
        index as index
      "
      [ngClass]="nVarieties == index + 2 ? 'selected-badge' : 'count-badge'"
      (click)="selectVarietiesNumber(index + 2)"
    >
      <h6 class="my-auto">
        {{ index + 2 }}
      </h6>
    </div>
  </div>
  <hr class="my-1" />
  <mdb-stepper
    [orientation]="'vertical'"
    *ngIf="nVarieties"
    formArrayName="varieties"
    [markAsCompleted]="false"
    [mobile]="true"
  >
    <mdb-step
      [name]="'Variety ' + (index + 1)"
      *ngFor="let step of varieties.controls; index as index"
      [formGroupName]="index"
    >
      <div style="min-height: 15rem">
        <label mdbLabel class="form-label">{{
          "VARIETY_CLEANING.SELECT_FROM" | translate
        }}</label>
        <div class="mb-2">
          <ng-select
            class="select-varieties w-100"
            [multiple]="true"
            formControlName="newSites"
            [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
          >
            <ng-option
              *ngFor="let site of varietySelected?.sites"
              [value]="site.value"
            >
              {{ site.label }}
            </ng-option>
          </ng-select>
        </div>
        <label mdbLabel class="form-label">
          {{ "VARIETY_CLEANING.NEW_NAME" | translate }}
        </label>
        <div>
          <input type="text" class="form-control" formControlName="newName" />
        </div>
      </div>
    </mdb-step>
  </mdb-stepper>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
  <div
    class="btn btn-success"
    [ngClass]="!nVarieties ? 'disabled' : ''"
    (click)="split()"
  >
    {{ "VARIETY_CLEANING.SPLIT" | translate }}
  </div>
</div>
