import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { ModalSiteDataCollectedComponent } from 'src/app/modals/projects/modal-site-activity/modal-site-activity.component';
import { ModalEditActivityComponent } from 'src/app/modals/sites/modal-edit-activity/modal-edit-activity.component';
import { ModalShowActivityImagesComponent } from 'src/app/modals/sites/modal-show-activity-images/modal-show-activity-images.component';

export interface ActivityPreview {
  activityID: string;
  appID: string;
  date: string;
  location: string;
  name: string;
  type: string;
  uuid: string;
  lat: number;
  lng: number;
  alt: number;
  deleted: boolean;
}

@Component({
  selector: 'site-data-collected',
  templateUrl: './site-data-collected.component.html',
  styleUrls: ['./site-data-collected.component.scss'],
})
export class SiteDataCollectedComponent implements OnInit {
  filterForm?: FormGroup;

  refID?: string;
  activities?: ActivityPreview[];

  activitiesList: any[] = [
    { label: 'Focus Group Discussion', value: 'FGD' },
    { label: 'House Hold Survey', value: 'HHS' },
    { label: 'Key Informant Survey', value: 'KIS' },
  ];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.getDatabaseType();

    this.filterForm = new FormGroup({
      refID: new FormControl(this.refID),
      filter: new FormControl(),
      activityType: new FormControl(),
    });

    this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      this.apiCall();
    });

    this.apiCall();
  }

  getDatabaseType() {
    this.refID = this.route.parent?.snapshot.paramMap.get('id') || '';
  }

  apiCall() {
    if (this.refID) {
      this.api
        .getFilteredActivities(this.filterForm?.value)
        .subscribe((value) => {
          this.activities = value as ActivityPreview[];
        });
    }
  }

  show(refID: string, activityType: string) {
    const url = this.router.url.replace('activities', 'activity');
    this.router.navigateByUrl(
      `${url}?refID=${refID}&activityType=${activityType}`
    );
    // this.modalService.open(ModalSiteDataCollectedComponent, {
    //   modalClass: 'modal-lg',
    //   data: {
    //     refID: refID,
    //     activityType: activityType,
    //   },
    // });
  }

  openEditModal(activity: ActivityPreview, index: number) {
    const modalRef = this.modalService.open(ModalEditActivityComponent, {
      data: {
        activity: activity,
      },
    });
    modalRef.onClose.subscribe((newActivity: ActivityPreview) => {
      if (newActivity) {
        this.activities![index] = newActivity;
      }
    });
  }
}
