import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { SupplierDistTo, SupplierDistType } from 'src/app/models/supplier-type';

@Component({
  selector: 'gmp-distribution',
  templateUrl: './gmp-distribution.component.html',
  styleUrls: ['./gmp-distribution.component.scss'],
})
export class GmpDistributionComponent implements OnInit, OnDestroy {
  langSub?: Subscription;

  scopes?: SupplierDistType[];
  types?: SupplierDistTo[];

  constructor(private api: ApiService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.apiCalls();

    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.apiCalls();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  apiCalls() {
    this.api.getSupplierDistTo().subscribe((value) => {
      this.scopes = value as SupplierDistType[];
    });
    this.api.getSupplierDistTypes().subscribe((value) => {
      this.types = value as SupplierDistTo[];
    });
  }
}
