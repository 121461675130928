import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Intervention } from 'src/app/models/intervention';

@Component({
  selector: 'modal-interventions',
  templateUrl: './modal-interventions.component.html',
  styleUrls: ['./modal-interventions.component.scss'],
})
export class ModalInterventionsComponent implements OnInit {
  interventionID?: string;
  databaseType?: string;

  intervention?: Intervention;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalInterventionsComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.api
      .getIntervention(this.databaseType || '', this.interventionID || '')
      .subscribe((value) => {
        this.intervention = value as Intervention;
      });
  }
}
