<div class="modal-body">
  <div class="bg-primary w-100 text-white">
    <h5 class="p-3 my-auto text-center">
      {{ graph.titleLang }}
    </h5>
  </div>
  <div
    class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center"
    style="border: solid 2px"
  >
    <canvas
      mdbChart
      [type]="'pie'"
      [datasets]="dataset == undefined ? datasetPlaceholder : dataset"
      [labels]="
        dataset == undefined ? datasetPlaceholder[0].labels : dataset[0].labels
      "
    ></canvas>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.BACK" | translate }}
  </div>
</div>
