import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'variety-projects',
  templateUrl: './variety-projects.component.html',
  styleUrls: ['./variety-projects.component.scss']
})
export class VarietyProjectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
