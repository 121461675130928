import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'file-analysis-page',
  templateUrl: './file-analysis-page.component.html',
  styleUrls: ['./file-analysis-page.component.scss'],
})
export class FileAnalysisPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
