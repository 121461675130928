<div *ngIf="loading"><loading [glass]="false"></loading></div>
<div class="mx-5 my-4 d-flex flex-column" *ngIf="!loading && dashboard">
  <div
    class="fluid-container row mb-4"
    [formGroup]="filterForm!"
    *ngIf="state == 0"
  >
    <ng-select
      class="w-50"
      placeholder="{{ 'PLACEHOLDER.SPECIES' | translate }}"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      formControlName="specie"
      [clearable]="false"
    >
      <ng-option *ngFor="let select of species" [value]="select.value">
        {{ select.label }}
      </ng-option>
    </ng-select>
  </div>
  <div class="fluid-container row" *ngIf="state == 0">
    <div class="col-4 d-flex flex-column align-items-center">
      <div class="btn btn-info mb-4" (click)="openDiversityBenefit()">
        {{ "ME.DIVERSITY_BENEFIT" | translate }}
      </div>
      <div class="w-100">
        <diversity-benefit-card
          *ngFor="let item of dashboard.periods; index as i; last as last"
          [last]="last"
          [period]="item"
          [i]="i"
          [familyID]="this.specie?.value!"
        ></diversity-benefit-card>
      </div>
    </div>
    <div class="col-4 d-flex flex-column align-items-center">
      <div class="btn btn-info mb-4" (click)="state = 2">
        {{ "ME.DEVELOPMENT_BENEFIT" | translate }}
      </div>
      <div class="w-100">
        <development-benefit-card
          *ngFor="let item of dashboard.benefits; index as i; last as last"
          [last]="last"
          [benefit]="item"
          [i]="i"
        ></development-benefit-card>
      </div>
    </div>
    <div class="col-4 d-flex flex-column align-items-center">
      <div class="btn btn-info mb-4" (click)="state = 3">
        {{ "ME.INTERVENTIONS" | translate }}
      </div>
      <div class="d-flex column-me" style="overflow-x: auto">
        <div
          class="d-flex flex-column-reverse"
          [ngStyle]="{
            height: kHeight * dashboard.periods.length + 'rem'
          }"
          *ngFor="let list of dashboard.interventions"
        >
          <div
            class="d-flex flex-column mx-2"
            *ngFor="let item of list; index as i"
          >
            <div
              class="card pointer"
              style="width: 150px; overflow: hidden"
              [ngStyle]="{
                height: kHeight * (item.end - item.start) + 'rem',
                bottom: 20
              }"
              (click)="
                openInterventionAnswers(
                  item.projectMeInterventionID,
                  item.intervention
                )
              "
            >
              <div class="d-flex flex-column p-1">
                <div class="d-flex justify-content-end">
                  {{ (item.to | date) ?? ". . ." }}
                </div>
                <div
                  style="overflow-y: auto; bottom: 20"
                  [ngStyle]="{
                    height: kHeight * (item.end - item.start - 0.3) + 'rem'
                  }"
                >
                  {{ item.intervention }}
                </div>
                <div class="d-flex justify-content-end">
                  {{ (item.from | date) ?? ". . ." }}
                </div>
              </div>
            </div>
            <div
              class="bg-transparent"
              style="width: 150px"
              [ngStyle]="{ height: kHeight * item.start + 'rem', bottom: 20 }"
              *ngIf="i == 0"
            ></div>
            <div
              class="bg-transparent"
              style="width: 150px"
              [ngStyle]="{
                height: kHeight * (list[i].start - list[i - 1].end) + 'rem',
                bottom: 20
              }"
              *ngIf="i != 0"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <project-development-benefit
    (stateChange)="onStateChange($event)"
    [species]="species"
    [selectedSpecie]="specie?.value"
    *ngIf="state == 2"
  ></project-development-benefit>
  <project-interventions
    (stateChange)="onStateChange($event)"
    [species]="species"
    [selectedSpecie]="specie?.value"
    [projectID]="projectID"
    *ngIf="state == 3"
  ></project-interventions>
</div>
<div style="height: 100px"></div>
