<div class="modal-body" [formGroup]="dateForm!">
  <div class="d-flex flex-column">
    <div class="d-flex flex-row my-2">
      <i class="bi bi-x-lg h4 my-auto"></i>
      <h4 class="my-auto ms-2">{{ "PERIOD.CLOSE_PERIOD" | translate }}</h4>
    </div>
    <label mdbLabel for="date" class="form-label mb-0">
      {{ "PERIOD.SELECT_CLOSING_DATE" | translate }}
    </label>
    <div class="d-flex">
      <input
        [mdbDatepicker]="datepicker"
        type="text"
        id="date"
        class="form-control mx-2"
        formControlName="date"
        (focus)="openCalendar()"
      />
      <i class="bi-calendar-fill mx-2 my-auto"></i>
      <mdb-datepicker #datepicker [inline]="true"></mdb-datepicker>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
  <div class="btn btn-danger" (click)="closePeriod()">
    {{ "PERIOD.CLOSE_PERIOD" | translate }}
  </div>
</div>
