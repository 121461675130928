<div *ngIf="ref != 'c'">
  <work-in-progress></work-in-progress>
</div>
<div *ngIf="ref == 'c'">
  <div class="d-flex mx-5 mt-2" [formGroup]="filterForm!">
    <div class="me-4" style="width: 20rem">
      <ng-select
        class="w-100"
        placeholder="{{ 'PLACEHOLDER.TYPE' | translate }}"
        formControlName="activityType"
        [searchable]="false"
        [clearable]="false"
      >
        <ng-option [value]="'FGD'"> FGD </ng-option>
        <ng-option [value]="'HHS'"> HHS </ng-option>
      </ng-select>
    </div>
    <div class="me-4" style="width: 20rem">
      <ng-select
        class="w-100"
        placeholder="{{ 'PLACEHOLDER.SITE' | translate }}"
        formControlName="siteID"
        [searchable]="false"
      >
        <ng-option [value]="site.siteID" *ngFor="let site of sites">
          {{ site.name }}
        </ng-option>
      </ng-select>
    </div>
    <div style="width: 20rem">
      <input
        type="text"
        id="filter"
        class="form-control"
        placeholder="{{ 'PLACEHOLDER.FILTER' | translate }}"
        formControlName="filter"
      />
    </div>
  </div>

  <div *ngIf="!activities" class="mt-4">
    <loading [glass]="false"></loading>
  </div>

  <div class="card mx-5 my-3 pb-2" *ngIf="activities">
    <div class="mx-4">
      <table class="table text-primary mt-2">
        <thead>
          <tr>
            <th class="h5" scope="col">Type</th>
            <th class="h5" scope="col">UUID</th>
            <th class="h5" scope="col">Name</th>
            <th class="h5" scope="col">Location</th>
            <th class="h5" scope="col">Tablet ID</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let activity of activities"
            class="pointer"
            (click)="goActivity(activity)"
          >
            <th scope="row">{{ activity.type }}</th>
            <td>{{ activity.uuid }}</td>
            <td>{{ activity.name }}</td>
            <td>{{ activity.location }}</td>
            <td>{{ activity.activityID }}</td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="(dataSource?.totalPages || 0) > 1">
        <page-controller
          [pageable]="dataSource"
          (pageClick)="onPageClick($event)"
        ></page-controller>
      </div>
    </div>
  </div>
</div>
