import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ModalConfirmDeleteInterventionComponent } from '../modal-confirm-delete-intervention/modal-confirm-delete-intervention.component';

@Component({
  selector: 'modal-confirm-delete-subgoal-benefit',
  templateUrl: './modal-confirm-delete-subgoal-benefit.component.html',
  styleUrls: ['./modal-confirm-delete-subgoal-benefit.component.scss'],
})
export class ModalConfirmDeleteSubgoalBenefitComponent implements OnInit {
  constructor(
    public modalRef: MdbModalRef<ModalConfirmDeleteInterventionComponent>
  ) {}

  ngOnInit(): void {}
}
