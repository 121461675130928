import { AuthState } from '../store/auth.state';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';

import * as fromAuth from '../store/auth.reducer';

export interface AppState {
  auth: AuthState;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer as ActionReducer<AuthState, Action>,
};

export function clearState(reducer: ActionReducer<AppState>) {
  return (state: AppState | undefined, action: Action): AppState => {
    if (action.type === '[Auth] LOGOUT completed') {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [clearState];
