import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalImageGalleryComponent } from 'src/app/modals/layout/modal-image-gallery/modal-image-gallery.component';
import { Image } from 'src/app/models/image';

@Component({
  selector: 'variety-gallery',
  templateUrl: './variety-gallery.component.html',
  styleUrls: ['./variety-gallery.component.scss'],
})
export class VarietyGalleryComponent implements OnInit {
  images?: Image[];
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    const stringIDs = this.route.parent?.snapshot.paramMap.get('id');
    const IDs = stringIDs?.split('-') || [];
    const varietyID = IDs[1];

    this.api.getImages(varietyID).subscribe((images) => {
      this.images = images as Image[];
    });
  }

  openGallery(i: number) {
    this.modalService.open(ModalImageGalleryComponent, {
      modalClass: 'modal-fullscreen',
      data: { position: i, images: this.images },
    });
  }
}
