import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout/layout.module';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { NavigationCardComponent } from './components/navigation-card/navigation-card.component';
import { MdbModule } from '../mdb/mdb.module';
import { SupportComponent } from './support/support.component';
import { ContributeComponent } from './contribute/contribute.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { RequestTrainingComponent } from './request-training/request-training.component';

@NgModule({
  declarations: [ContactUsPageComponent, NavigationCardComponent, SupportComponent, ContributeComponent, SuggestionsComponent, RequestTrainingComponent],
  imports: [
    AppRoutingModule,
    CommonModule,
    LayoutModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MdbModule,
  ],
  exports: [ContactUsPageComponent],
})
export class ContactUsModule {}
