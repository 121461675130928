import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { ReproductiveMaterial } from 'src/app/models/reproductive-material';

@Component({
  selector: 'gmp-reproductive-materials',
  templateUrl: './gmp-reproductive-materials.component.html',
  styleUrls: ['./gmp-reproductive-materials.component.scss'],
})
export class GmpReproductiveMaterialsComponent implements OnInit, OnDestroy {
  langSub?: Subscription;
  crop?: ReproductiveMaterial[];
  livestock?: ReproductiveMaterial[];
  aquaculture?: ReproductiveMaterial[];

  constructor(private api: ApiService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.apiCalls();

    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.apiCalls();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  apiCalls() {
    this.api.getReproductiveMaterial('CROPS').subscribe((value) => {
      this.crop = value as ReproductiveMaterial[];
    });

    this.api.getReproductiveMaterial('LIVESTOCK').subscribe((value) => {
      this.livestock = value as ReproductiveMaterial[];
    });

    this.api.getReproductiveMaterial('AQUATICS').subscribe((value) => {
      this.aquaculture = value as ReproductiveMaterial[];
    });
  }
}
