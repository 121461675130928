import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FiltersListener {
  private dataSource: BehaviorSubject<FiltersHelper> =
    new BehaviorSubject<FiltersHelper>(new FiltersHelper());

  data: Observable<FiltersHelper> = this.dataSource.asObservable();

  constructor() {}

  setData(data: FiltersHelper) {
    this.dataSource.next(data);
  }
}

export class FiltersHelper {
  filter?: string | null;
  type?: string | null;
  ready: boolean = true;
  notReady: boolean = true;

  varietyCountry: string;

  constructor() {
    this.filter = '';
    this.ready = true;
    this.notReady = true;
    this.type = null;
    this.varietyCountry = '';
  }

  clearFilter() {
    this.filter = '';
    this.ready = true;
    this.notReady = true;
    this.type = null;
    this.varietyCountry = '';
  }
}
