import {
  HttpClient,
  HttpClientModule,
  HttpHeaders,
} from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Injectable,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiService } from './api.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { DatabaseModule } from './database/database.module';
import { LayoutModule } from './layout/layout.module';
import { WebPortalComponent } from './web-portal/web-portal.component';

import { AuthModule } from './auth/auth.module';
import { reducers, metaReducers } from './auth/reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProjectsModule } from './projects/projects.module';
import { ModalsModule } from './modals/modals.module';
import { MdbModule } from './mdb/mdb.module';
import { InstructionsModule } from './instructions/instructions.module';
import { ContactUsModule } from './contact-us/contact-us.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { WorkingAreaModule } from './working-area/working-area.module';
import { FileAnalysisModule } from './file-analysis/file-analysis.module';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgxGaugeModule } from 'ngx-gauge';
import { VarietyCleaningModule } from './variety-cleaning/variety-cleaning.module';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OutputTablesModule } from './output-tables/output-tables.module';

class CustomLoader implements TranslateLoader {
  constructor(private api: ApiService) {}

  getTranslation(lang: string): Observable<any> {
    return this.api.getWebportalTranslation(lang);
  }
}

@Injectable()
export class CustomTranslationService implements TranslateLoader {
  constructor(private httpClient: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    return this.httpClient.post(
      environment.apiUrl + '/getWebportalTranslation',
      { lang: lang.toUpperCase() },
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, WebPortalComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    DashboardModule,
    AuthModule,
    DatabaseModule,
    WorkingAreaModule,
    ProjectsModule,
    VarietyCleaningModule,
    FileAnalysisModule,
    InstructionsModule,
    ContactUsModule,
    OutputTablesModule,
    ModalsModule,
    MdbModule,
    LeafletModule,
    NgxGaugeModule,
    NgxMasonryModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    TranslateModule.forRoot({
      // loader: { provide: TranslateLoader, useClass: CustomLoader },
      loader: {
        provide: TranslateLoader,
        // useFactory: HttpLoaderFactory,
        useClass: CustomTranslationService,
        deps: [HttpClient],
      },
    }),
    NgxQRCodeModule,
  ],
  providers: [ApiService],
  bootstrap: [AppComponent],
  exports: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
