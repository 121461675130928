<div
  class="d-flex justify-content-center w-100 align-items-center mt-5"
  *ngIf="
    rScripts?.length == 0 &&
    this.filter?.value == null &&
    this.refID?.value == null
  "
>
  <div style="height: 11rem; width: 11rem">
    <img src="/assets/images/logo.png" class="w-100 h-100 contain" />
  </div>
  <h1 class="ms-3">{{ "FILE_ANALYSIS.COMING" | translate }}</h1>
</div>
<div
  class="d-flex flex-column w-100 mt-3"
  *ngIf="
    rScripts?.length != 0 ||
    this.filter?.value != null ||
    this.refID?.value != null
  "
>
  <div class="sticky-top bg-light">
    <div class="d-flex flex-row mt-3 align-items-center">
      <div style="height: 5rem; width: 5rem">
        <img
          class="w-100 h-100"
          style="object-fit: contain"
          src="../../assets/images/crop.svg"
        />
      </div>
      <h1 class="fw-bold mx-2 my-auto">{{ "NAVIGATION.CROP" | translate }}</h1>

      <form
        class="d-flex flex-row align-items-center mt-2 mx-4 fade-element"
        [formGroup]="filterForm!"
      >
        <div class="mx-3">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'FILE_ANALYSIS.FILTER' | translate }}"
            formControlName="filter"
          />
        </div>
        <div class="mx-2">
          <ng-select
            class="control"
            placeholder="{{ 'FILE_ANALYSIS.FILTER_BY_OT' | translate }}"
            formControlName="refID"
            [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
          >
            <ng-option
              *ngFor="let ot of ots"
              [value]="ot.value"
              [mdbTooltip]="ot.label"
            >
              {{ ot.label }}
            </ng-option>
          </ng-select>
        </div>
      </form>
    </div>
    <div class="row w-100 my-0 py-0 mt-3" style="font-weight: bold">
      <div class="col-4 my-0 py-0">{{ "FILE_ANALYSIS.TITLE" | translate }}</div>
      <div class="col-4 my-0 py-0">{{ "FILE_ANALYSIS.OTS" | translate }}</div>
      <div class="col-3 my-0 py-0"></div>
    </div>
    <hr class="mt-2 mb-0" />
    <div
      class="d-flex flex-column py-3"
      style="max-height: 30rem; overflow-y: auto"
    >
      <div *ngIf="rScripts?.length != 0">
        <div
          class="row py-2 px-2 w-100 rounded mt-0 align-items-center mb-2"
          [ngClass]="i % 2 != 0 ? 'bg-light' : 'bg-white'"
          *ngFor="let script of rScripts; index as i"
        >
          <div class="col-4 py-0">{{ script.titleLang }}</div>
          <div class="col-4 py-0">{{ getOts(script.outputTablesLang) }}</div>
          <div class="col-3 py-0 d-flex align-items-center">
            <a [href]="script.fileUrl" target="_blank" download>
              <div class="btn btn-primary mx-3">
                {{ "BUTTONS.DOWNLOAD" | translate }}
              </div>
            </a>
            <div class="btn btn-primary" (click)="openFile(script)">
              {{ "BUTTONS.INFOS" | translate }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-row align-items-center mx-2"
        *ngIf="rScripts?.length == 0"
      >
        <i class="h1 my-auto bi bi-search mx-2"></i>
        <h3 class="h4 my-auto mx-2">{{ "VARIETY.ANYTHING" | translate }}</h3>
      </div>
    </div>
    <hr class="mt-0" />
  </div>
</div>
