<div *ngIf="!periods" class="mt-4">
  <loading [glass]="false"></loading>
</div>
<div class="mt-3 mx-5 pb-5" style="width: 68rem">
  <div class="d-flex flex-column-reverse pb-4">
    <div
      class="d-flex"
      *ngFor="let period of periods; index as i; last as last"
      [ngClass]="
        i % 2 == 0
          ? 'justify-content-end flex-row'
          : 'justify-content-end flex-row-reverse'
      "
    >
      <!-- ICON AND SEGMENT -->
      <div class="d-flex flex-column-reverse" style="width: 4rem">
        <div
          class="rounded-circle bg-primary d-flex justify-content-center"
          [ngClass]="period.current ? 'bg-success' : 'bg-primary'"
          style="
            min-width: 4rem;
            max-width: 4rem;
            min-height: 4rem;
            max-height: 4rem;
          "
        >
          <i
            class="bi bi-balloon-fill text-white h2 my-auto"
            *ngIf="i == 0"
          ></i>
          <h2 class="text-center text-white h2 my-auto" *ngIf="i != 0">
            {{ i + 1 }}
          </h2>
        </div>
        <div class="mx-auto bg-primary h-100 pb-4" style="width: 0.25rem"></div>
      </div>
      <!-- /ICON AND SEGMENT -->
      <!-- CARD -->
      <div
        class="card mx-3 d-flex mb-4 flex-column p-2"
        style="min-width: 30rem; max-width: 30rem; margin-top: 6.5rem"
        [ngStyle]="{
          border: period.current ? '2px solid green' : '2px solid transparent'
        }"
      >
        <div class="d-flex flex-row justify-content-between">
          <h4 class="my-auto py-1" *ngIf="period.title">{{ period.title }}</h4>
          <h4 class="my-auto py-1" *ngIf="!period.title">
            Period {{ period.count }}
          </h4>
          <span
            class="rounded px-2 text-white my-auto"
            [ngClass]="period.current ? 'bg-success' : 'bg-primary'"
          >
            {{ period.count }}
          </span>
        </div>
        <div class="w-100 bg-dark" style="height: 2px"></div>
        <h5 class="my-2 mx-auto">
          {{ period.fgd }} FGD(s) - {{ period.hhs }} HHS(s) -
          {{ period.kis }} KI(s) - {{ period.vdm }} VDM(s)
        </h5>
        <h5 class="mx-auto mb-0 text-success">
          {{ "PERIOD.FROM" | translate }}:
          {{
            period.startDate | date : "dd MMM yyyy" : "" : translate.currentLang
          }}
        </h5>
        <h5 class="mx-auto mt-0 text-danger" *ngIf="!period.endDate">
          {{ "PERIOD.TO" | translate }}: &nbsp;. . .
        </h5>
        <h5 class="mx-auto mt-0 text-danger" *ngIf="period.endDate">
          {{ "PERIOD.TO" | translate }}:
          {{
            period.endDate | date : "dd MMM yyyy" : "" : translate.currentLang
          }}
        </h5>
        <div
          class="d-flex flex-row gap-2 my-2 justify-content-around"
          *ngIf="projectPermissions?.projectRole != 'VIEWER'"
        >
          <span
            class="my-auto pointer"
            style="text-decoration: underline"
            (click)="openEditPeriod(i, period)"
          >
            {{ "PERIOD.EDIT_PERIOD" | translate }}
          </span>
          <div
            class="btn btn-danger"
            style="width: 10rem"
            (click)="openClosePeriod(period)"
          >
            {{ "PERIOD.CLOSE_PERIOD_BUTTON" | translate }}
          </div>
          <div
            class="btn btn-primary"
            style="width: 10rem"
            (click)="openPeriod(period.periodID)"
          >
            {{ "PERIOD.ENTER" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
