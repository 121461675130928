<div class="modal-body">
  <h4>
    {{
      (disabled ? "OT_CLEANING.ENABLE_TITLE" : "OT_CLEANING.DISABLE_TITLE")
        | translate
    }}
  </h4>
  <h5>
    {{
      (disabled
        ? "OT_CLEANING.ENABLE_SUBTITLE"
        : "OT_CLEANING.DISABLE_SUBTITLE"
      ) | translate
    }}
  </h5>
  <div class="py-3 bg-error mt-4 rounded text-center" *ngIf="error">
    <h6 class="my-auto">{{ "OT_CLEANING.ERROR" | translate }} [{{ error }}]</h6>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
  <div
    class="btn"
    [ngClass]="disabled ? 'btn-success' : 'btn-danger'"
    (click)="switchState()"
  >
    {{ (disabled ? "BUTTONS.ENABLE" : "BUTTONS.DISABLE") | translate }}
  </div>
</div>
