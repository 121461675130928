import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Coverage, Site, Specie } from 'src/app/models/project';
import { ModalAddGoalComponent } from '../../sites/modal-add-goal/modal-add-goal.component';

@Component({
  selector: 'modal-edit-site',
  templateUrl: './modal-edit-site.component.html',
  styleUrls: ['./modal-edit-site.component.scss'],
})
export class ModalEditSiteComponent implements OnInit {
  site?: Site;
  countryID?: string;
  projectID?: string;
  coverages?: Coverage[];
  ref?: string;
  refIDs: string[] = [];

  loading: boolean = false;

  editSiteForm?: UntypedFormGroup;

  showOtherZone: boolean = false;
  agroZonesList?: any[];

  constructor(
    private api: ApiService,
    private formBuilder: UntypedFormBuilder,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<ModalEditSiteComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.getSiteDetail();

    this.refIDs = this.site?.refIDs ?? [];

    this.editSiteForm = new UntypedFormGroup({
      name: new UntypedFormControl(this.site?.name, [Validators.required]),
      province: new UntypedFormControl(this.site?.province, [
        Validators.required,
      ]),
      agroZone: new UntypedFormControl(this.site?.agroEcoZoneId, [
        Validators.required,
      ]),
      otherZone: new UntypedFormControl(this.site?.agroEcoZoneOther, []),
      minLat: new UntypedFormControl(this.site?.minLat, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      maxLat: new UntypedFormControl(this.site?.maxLat, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      minLng: new UntypedFormControl(this.site?.minLng, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      maxLng: new UntypedFormControl(this.site?.maxLng, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      minAlt: new UntypedFormControl(this.site?.minAlt, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      maxAlt: new UntypedFormControl(this.site?.maxAlt, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      formSpecies: this.formBuilder.array([]),
      siteGoals: this.formBuilder.array([]),
    });

    this.agroZone?.valueChanges.subscribe((value) => {
      this.otherZone?.setValidators(null);
      this.otherZone?.setErrors(null);
      if (value === 'OTHER') {
        this.otherZone?.setValidators([Validators.required]);
      }
    });
  }

  populateForm() {
    this.coverages?.forEach((item: Coverage) => {
      const specieGroup = this.formBuilder.group({
        refID: new UntypedFormControl(item.refID),
        familyName: new UntypedFormControl(item.familyName),
        coverage: new UntypedFormControl(item.coverage, [
          Validators.required,
          Validators.pattern('-?[0-9]+.?[0-9]*$'),
        ]),
        siteCoverageID: new UntypedFormControl(item.siteCoverageID),
        active: new UntypedFormControl(item.active),
      });
      specieGroup.get('active')?.valueChanges.subscribe((value) => {
        if (this.refIDs.includes(item.refID)) {
          const id = this.refIDs.indexOf(item.refID);
          this.refIDs.splice(id, 1);
          specieGroup.get('coverage')?.setErrors(null);
          specieGroup.get('coverage')?.setValidators(null);
        } else {
          this.refIDs.push(item.refID);
          specieGroup
            .get('coverage')
            ?.addValidators([
              Validators.required,
              Validators.pattern('-?[0-9]+.?[0-9]*$'),
            ]);
          specieGroup.get('coverage')?.updateValueAndValidity();
        }
      });
      this.formSpecies.push(specieGroup);
    });
  }

  populateGoals() {
    this.site?.goals?.forEach((value: any) => {
      const group = this.formBuilder.group({
        goalID: new UntypedFormControl(value.goalID),
        goalLabel: new UntypedFormControl(value.goal),
        subgoalID: new UntypedFormControl(value.subgoalID),
        subgoalLabel: new UntypedFormControl(value.subgoal),
        families: new UntypedFormControl(value.familyIDs),
        familyLabels: new UntypedFormControl(value.familiesName),
      });

      this.siteGoals.push(group);
    });
  }

  getSiteDetail() {
    this.api.getSiteDetail(this.site?.siteID ?? '').subscribe({
      next: (value) => {
        this.site = value as Site;
        this.coverages = this.site.coverages;
        this.getAgroZones();
        this.populateForm();
        this.populateGoals();
        this.loading = false;
      },
    });
  }

  getAgroZones() {
    if (this.countryID)
      this.api.getAgroZones(this.countryID).subscribe((value) => {
        const list = value as any[];
        if (list.length === 0) {
          this.agroZonesList = [{ label: 'Other', value: 'OTHER' }];
          this.openOtherZone('OTHER');
        } else {
          this.agroZonesList = value as any[];
          this.agroZonesList.push({ label: 'Other', value: 'OTHER' });
          this.openOtherZone(this.site?.agroEcoZoneId || '');
        }
      });
  }

  openOtherZone(event: any) {
    if (event.value === 'OTHER') this.showOtherZone = true;
  }

  updateSite() {
    if (this.editSiteForm?.valid) {
      this.loading = true;
      const body = {
        agroEcoZoneId: this.agroZone?.value,
        agroEcoZoneOther: this.otherZone?.value,
        maxAlt: this.maxAlt?.value,
        minAlt: this.minAlt?.value,
        minLat: this.minLat?.value,
        maxLat: this.maxLat?.value,
        maxLng: this.maxLng?.value,
        minLng: this.minLng?.value,
        name: this.name?.value,
        projectID: this.projectID,
        province: this.province?.value,
        siteID: this.site?.siteID,
        coverages: this.formSpecies.value,
        goals: this.siteGoals.value,
        refIDs: this.refIDs,
      };

      this.api.updateSite(body).subscribe(
        () => {
          this.loading = false;
          this.modalRef.close(true);
        },
        (error) => {
          this.loading = false;
        }
      );
    } else {
      this.editSiteForm?.markAllAsTouched();
    }
  }

  openAddGoal() {
    const modalRef = this.modalService.open(ModalAddGoalComponent, {
      data: {
        species: this.site?.species,
        ref: this.ref,
        refIDs: this.refIDs,
      },
      modalClass: 'modal-dialog-centered',
    });

    modalRef.onClose.subscribe((value) => {
      if (!value) return;
      const control = this.formBuilder.group({
        goalID: new UntypedFormControl(value.goalID),
        goalLabel: new UntypedFormControl(value.goalLabel),
        subgoalID: new UntypedFormControl(value.subgoalID),
        subgoalLabel: new UntypedFormControl(value.subgoalLabel),
        families: new UntypedFormControl(value.familyIDs),
        familyLabels: new UntypedFormControl(value.familyLabels),
      });
      this.siteGoals.push(control);
    });
  }

  switchDisable() {
    this.api
      .changeSiteActive(this.site?.projectID!, this.site?.siteID!)
      .subscribe({
        next: (value) => {
          this.modalRef.close(true);
        },
        error: (e) => {
          if (e.statusCode == 404) {
            //
          }
        },
      });
  }

  removeGoal(index: number) {
    this.siteGoals.removeAt(index);
  }

  editGoal(index: number) {
    this.siteGoals.removeAt(index);
  }

  get formSpecies(): FormArray {
    return this.editSiteForm?.get('formSpecies') as FormArray;
  }

  get siteGoals(): FormArray {
    return this.editSiteForm?.get('siteGoals') as FormArray;
  }

  get name() {
    return this.editSiteForm?.get('name');
  }

  get province() {
    return this.editSiteForm?.get('province');
  }

  get agroZone() {
    return this.editSiteForm?.get('agroZone');
  }

  get otherZone() {
    return this.editSiteForm?.get('otherZone');
  }

  get minLat() {
    return this.editSiteForm?.get('minLat');
  }
  get maxLat() {
    return this.editSiteForm?.get('maxLat');
  }
  get minLng() {
    return this.editSiteForm?.get('minLng');
  }
  get maxLng() {
    return this.editSiteForm?.get('maxLng');
  }
  get minAlt() {
    return this.editSiteForm?.get('minAlt');
  }
  get maxAlt() {
    return this.editSiteForm?.get('maxAlt');
  }
}
