<div class="d-flex min-vh-100 w-100 bg-light">
  <div class="d-flex align-items-end justify-content-end w-100">
    <img src="../../../../assets/images/logo.png" />
  </div>
  <div class="mask row justify-content-center align-items-center glass">
    <div class="card col-xl-4 col-6" style="overflow-y: auto">
      <div class="mx-2 py-4 px-3">
        <div class="d-flex align-items-center">
          <i class="bi bi-person-fill h1"> </i>
          <h1 class="mx-1 fw-bold">{{ "LOGIN.LOGIN" | translate }}</h1>
        </div>
        <div class="bg-primary w-75 mx-1 mb-2" style="height: 2.5px"></div>
        <div
          class="mx-1 w-75 text-white border-danger rounded-4 d-flex align-items-center justify-content-center login-error"
          style="
            height: 3.2rem;
            background-color: #ee2b2bc4;
            border: solid 3.5px;
          "
          *ngIf="error$ | async as error"
        >
          <p class="mx-2 my-auto">{{ error }}</p>
        </div>
        <form [formGroup]="loginForm!">
          <div class="mx-1 my-2 mt-3">
            <div class="d-flex">
              <i class="bi bi-envelope-fill h5"></i>
              <h5 class="pb-1 m-0 mx-2">{{ "USER.EMAIL" | translate }}</h5>
            </div>
            <div class="d-flex flex-column p-0 m-0">
              <div class="d-flex flex-row">
                <input
                  type="text"
                  class="w-75 form-control"
                  formControlName="email"
                />
                <i
                  *ngIf="email?.invalid && email?.touched"
                  class="bi bi-exclamation-triangle-fill my-auto mx-2 h3 text-danger invalid-icon"
                ></i>
              </div>
              <p
                *ngIf="email?.invalid && email?.touched"
                class="fst-italic p-0 m-0 mx-2 text-danger invalid-text"
              >
                {{ "LOGIN.MAIL_REQUIRED" | translate }}
              </p>
            </div>
          </div>
          <div class="mx-1 my-2 mt-3">
            <div class="d-flex">
              <i class="bi bi-key-fill h5"></i>
              <h5 class="pb-1 m-0 mx-2">{{ "USER.PASSWORD" | translate }}</h5>
            </div>
            <div class="d-flex flex-column p-0 m-0">
              <div class="d-flex flex-row">
                <input
                  [type]="passwordVisibility ? 'text' : 'password'"
                  formControlName="password"
                  class="w-75 form-control"
                />
                <i
                  class="bi my-auto mx-2 h3 text-primary pointer"
                  [ngClass]="
                    passwordVisibility ? 'bi-eye-slash-fill' : 'bi-eye-fill'
                  "
                  (click)="showPassword()"
                ></i>
                <i
                  *ngIf="password?.invalid && password?.touched"
                  class="bi bi-exclamation-triangle-fill my-auto mx-2 h3 text-danger invalid-icon"
                ></i>
              </div>
              <p
                *ngIf="password?.invalid && password?.touched"
                class="fst-italic p-0 m-0 mx-2 text-danger invalid-text"
              >
                {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
              </p>
            </div>
          </div>
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-center mt-3 mb-3 mx-2"
          >
            <div
              class="btn btn-success fw-bold text-white my-2 my-md-0"
              (click)="logIn()"
            >
              {{ "LOGIN.LOGIN" | translate }}
            </div>
            <u class="py-md-0 py-2">
              <p routerLink="/reset-password" class="my-auto hover">
                {{ "LOGIN.FORGOT" | translate }}
              </p>
            </u>
          </div>
        </form>
        <div class="d-flex justify-content-center mt-2">
          <u>
            <p routerLink="/register" class="hover">
              {{ "LOGIN.REGISTER" | translate }}
            </p>
          </u>
        </div>
        <change-lang></change-lang>
      </div>
      <loading class="mask" *ngIf="loading"></loading>
    </div>
  </div>
</div>
