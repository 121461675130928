import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { GeographicalRegistrationStatus } from 'src/app/models/geographical-registration-status';
import { SupplierSubtype, SupplierType } from 'src/app/models/supplier-type';

@Component({
  selector: 'gmp-types',
  templateUrl: './gmp-types.component.html',
  styleUrls: ['./gmp-types.component.scss'],
})
export class GmpTypesComponent implements OnInit, OnDestroy {
  langSub?: Subscription;

  level1?: SupplierType[];
  level2?: SupplierSubtype[];
  geoReg?: GeographicalRegistrationStatus[];

  constructor(private api: ApiService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.apiCalls();

    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.apiCalls();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  apiCalls() {
    this.api.getSupplierTypes().subscribe((types) => {
      this.level1 = types as SupplierType[];
    });
    this.api.getSupplierSubtypes().subscribe((types) => {
      this.level2 = types as SupplierSubtype[];
    });

    this.api.getGeoRegStatus().subscribe((types) => {
      this.geoReg = types as GeographicalRegistrationStatus[];
    });
  }
}
