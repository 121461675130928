export class Partner {
  img?: string;
  link?: string;

  constructor(img: string, link: string) {
    this.img = img;
    this.link = link;
  }

  static getPartners(): Array<Partner> {
    return [
      new Partner('alliance.jpg', 'https://www.bioversityinternational.org/'),
      new Partner('gef.jpg', 'https://www.thegef.org/'),
      new Partner('ifad.jpg', 'https://www.ifad.org/en/'),
      new Partner(
        'resilient.png',
        'https://knowledgecentre.resilientfoodsystems.co/kc'
      ),
      new Partner(
        'sapienza.jpg',
        'https://www.uniroma1.it/it/pagina-strutturale/home'
      ),
      new Partner('sara.png', 'http://www.saraenvimob.com/'),
      new Partner('swiss.jpg', 'https://www.eda.admin.ch/sdc'),
      new Partner('unep.png', 'https://www.unep.org/'),
      new Partner('raffaella.png', 'https://www.raffaellafoundation.org/'),
    ];
  }
}
