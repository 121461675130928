<div class="modal-content">
  <div class="modal-header">
    <h4>Edit Activity</h4>
  </div>
  <div class="modal-body" [formGroup]="editForm!">
    <div class="row justify-content-center pt-3">
      <div class="col-3 d-flex flex-column">
        <h5>{{ "SITE.LAT" | translate }}</h5>
        <div class="my-2">
          <input type="text" class="form-control" formControlName="lat" />
        </div>
      </div>

      <div class="col-3 d-flex flex-column mx-4">
        <h5>{{ "SITE.LNG" | translate }}</h5>
        <div class="my-2">
          <input type="text" class="form-control" formControlName="lng" />
        </div>
      </div>

      <div class="col-3 d-flex flex-column">
        <h5>{{ "SITE.ALT" | translate }}</h5>
        <div class="my-2">
          <input type="text" class="form-control" formControlName="alt" />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer flex justify-content-between">
    <div
      class="btn mr-auto"
      (click)="openSwitchState()"
      [ngClass]="activity?.deleted ? 'btn-success' : 'btn-danger'"
    >
      {{
        (activity?.deleted ? "BUTTONS.ENABLE" : "BUTTONS.DISABLE") | translate
      }}
    </div>
    <div class="flex">
      <div class="btn btn-primary me-2" (click)="modalRef.close()">
        {{ "BUTTONS.CLOSE" | translate }}
      </div>
      <div class="btn btn-success" (click)="save()">
        {{ "BUTTONS.SAVE" | translate }}
      </div>
    </div>
  </div>
</div>
