import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { AppState } from 'src/app/auth/reducer';
import { getUser } from 'src/app/auth/store/auth.selectors';
import { ModalEditSiteComponent } from 'src/app/modals/projects/modal-edit-site/modal-edit-site.component';
import {
  ProjectPageListener,
  ProjectPermissions,
} from 'src/app/models/project-page-helper';
import { Site } from 'src/app/models/project';

@Component({
  selector: 'app-project-sites',
  templateUrl: './project-sites.component.html',
  styleUrls: ['./project-sites.component.scss'],
})
export class ProjectSitesComponent implements OnInit, OnDestroy {
  projectPageHelper$?: Subscription;

  sites?: Site[];
  projectID: string = '';
  ref?: string;
  countryID?: string;

  projectPermissions?: ProjectPermissions;

  constructor(
    private api: ApiService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: MdbModalService,
    private projectPageListener: ProjectPageListener
  ) {}

  ngOnInit(): void {
    this.store.select(getUser).subscribe((user) => {
      this.countryID = user?.countryID as string;
    });
    this.projectID = this.route.parent?.snapshot.paramMap.get('id') || '';
    const r = this.route.parent?.snapshot.paramMap.get('ref') || '';
    this.ref = r == 'c' ? 'CROPS' : r == 'l' ? 'LIVESTOCK' : 'AQUATICS';
    this.getSites();

    this.projectPageHelper$ = this.projectPageListener.data.subscribe(
      (data) => {
        if (data.projectPermissions) {
          this.projectPermissions = data.projectPermissions;
        }
        if (data.siteAdded) {
          this.getSites();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.projectPageHelper$?.unsubscribe();
  }

  getSites() {
    if (this.projectID) {
      this.api.getSites(this.projectID).subscribe((value) => {
        this.sites = value as Array<Site>;
      });
    }
  }

  openEditSite(site: Site) {
    const modalRef = this.modalService.open(ModalEditSiteComponent, {
      data: {
        site: site,
        countryID: site.countryID,
        projectID: this.projectID,
        coverages: site.coverages,
        ref: this.ref,
      },
      modalClass: 'modal-frame modal-bottom',
      ignoreBackdropClick: true,
    });

    modalRef.onClose.subscribe((refresh: boolean) => {
      if (refresh) {
        this.api.getSites(this.projectID).subscribe((value) => {
          this.sites = value as Array<Site>;
        });
      }
    });
  }

  openSite(siteID: String) {
    this.router.navigateByUrl('/u/site/' + siteID);
  }
}
