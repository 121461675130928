import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Constraint } from 'src/app/models/constraint';
import { Site } from 'src/app/models/project';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'modal-manage-interventions',
  templateUrl: './modal-manage-interventions.component.html',
  styleUrls: ['./modal-manage-interventions.component.scss'],
})
export class ModalManageInterventionsComponent implements OnInit {
  refID?: string;
  projectID?: string;
  siteID?: string;
  sites?: Site[];
  species?: Select[];

  filterForm?: FormGroup;

  types: Array<any> = [];
  subTypes: Array<any> = [];
  descriptors: Array<any> = [];

  loading = true;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalManageInterventionsComponent>
  ) {}

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      specie: new FormControl(this.refID),
      site: new FormControl(this.siteID),
    });
    this.getType();

    this.filterForm.valueChanges.subscribe((value) => {
      if (!this.loading) {
        this.getType();
      }
    });
  }

  getType() {
    this.api
      .getMeConstraints(this.projectID!, this.specie?.value, this.site?.value)
      .subscribe((value) => {
        this.types = value as any[];
        this.loading = false;
      });
  }

  onSelectType(i: number) {
    this.types.filter((value) => {
      value.selected = false;
    });
    this.subTypes.filter((value) => {
      value.selected = false;
    });
    this.descriptors.filter((value) => {
      value.selected = false;
    });
    this.types[i].selected = true;
    this.subTypes = this.types[i].subLevel;
    this.descriptors = [];
  }

  loadDescriptors = (i: number) => {
    if (this.subTypes[i].subLevel) {
      this.descriptors = [];
      this.descriptors = this.subTypes[i].subLevel;
    }
    this.subTypes.filter((value) => {
      value.selected = false;
    });
    this.descriptors.filter((value) => {
      value.selected = false;
    });
    this.subTypes[i].selected = true;
  };

  focusDescriptor(i: number) {
    this.descriptors.filter((value) => {
      value.selected = false;
    });
    this.descriptors[i].selected = true;
  }

  selectIntervention(constraint: Constraint) {
    const body = {
      projectID: this.projectID,
      refID: this.specie?.value,
      siteID: this.site?.value,
      constraintID: constraint.constraintID,
      title: constraint.livello,
    };
    this.modalRef.close(body);
  }

  get specie() {
    return this.filterForm?.get('specie');
  }

  get site() {
    return this.filterForm?.get('site');
  }
}
