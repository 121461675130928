import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'database-family-navigator',
  templateUrl: './database-family-navigator.component.html',
  styleUrls: ['./database-family-navigator.component.scss'],
})
export class DatabaseFamilyNavigatorComponent implements OnInit {
  @Input() databaseType?: string;
  @Input() item?: any;
  @Input() variety?: any;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  backToDatabase() {
    switch (this.databaseType) {
      case 'CROP':
        this.router.navigateByUrl('u/database/crop/list');
        break;
      case 'LIVESTOCK':
        this.router.navigateByUrl('u/database/livestock/list');
        break;
      case 'AQUACULTURE':
        this.router.navigateByUrl('u/database/aquaculture/list');
        break;
      default:
        break;
    }
  }

  getName(): string {
    if (this.variety == null) {
      switch (this.databaseType) {
        case 'CROP':
          return this.item?.crop;
        case 'LIVESTOCK':
          return this.item?.livestock;
        case 'AQUACULTURE':
          return this.item?.aquatic;
        default:
          return '';
      }
    } else {
      switch (this.databaseType) {
        case 'CROP':
          return this.variety.crop.crop;
        case 'LIVESTOCK':
          return this.variety.livestock.livestock;
        case 'AQUACULTURE':
          return this.variety.aquatic.aquatic;
        default:
          return '';
      }
    }
  }

  backToItem() {
    if (this.variety == null) return;
    switch (this.databaseType) {
      case 'CROP':
        this.router.navigateByUrl('u/database/c/' + this.variety.crop.cropID);
        break;
      case 'LIVESTOCK':
        this.router.navigateByUrl(
          'u/database/l/' + this.variety.livestock.livestockID
        );
        break;
      case 'AQUACULTURE':
        this.router.navigateByUrl(
          'u/database/a/' + this.variety.aquatic.aquaticID
        );
        break;
      default:
        break;
    }
  }
}
