import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Goal } from 'src/app/models/goal';

@Component({
  selector: 'modal-goal',
  templateUrl: './modal-goal.component.html',
  styleUrls: ['./modal-goal.component.scss'],
})
export class ModalGoalComponent implements OnInit {
  subGoal?: any;
  activeTab: string = 'descriptors';

  descriptors?: any[];
  management?: any[];
  market?: any[];
  policy?: any[];

  constructor(
    private api: ApiService,
    private location: LocationStrategy,
    private modalRef: MdbModalRef<ModalGoalComponent>
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.api
      .getSubgoalDescriptors(this.subGoal.subgoalID)
      .subscribe((value) => {
        this.descriptors = value as any[];
      });
    this.api.getSubgoalManagement(this.subGoal.subgoalID).subscribe((value) => {
      this.management = value as any[];
    });
    this.api.getSubgoalMarket(this.subGoal.subgoalID).subscribe((value) => {
      this.market = value as any[];
    });
  }

  changeTab(tab: string) {
    this.activeTab = tab;
  }
}
