import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-switch-activity-state',
  templateUrl: './modal-switch-activity-state.component.html',
  styleUrls: ['./modal-switch-activity-state.component.scss'],
})
export class ModalSwitchActivityStateComponent implements OnInit {
  refID?: string;
  activityType?: string;
  deleted?: boolean;
  error?: string;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalSwitchActivityStateComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}

  switchState() {
    if (!this.refID || !this.activityType) return;
    this.api.switchEnableActivity(this.refID, this.activityType).subscribe({
      complete: () => {
        this.modalRef.close(true);
      },
      error: (error) => {
        this.error = error.status;
      },
    });
  }
}
