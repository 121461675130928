<div class="d-flex flex-column mx-5 mt-2">
  <section>
    <div class="d-flex flex-column bg-light sticky-top pt-3">
      <div class="d-flex mb-2" [formGroup]="filterForm!">
        <div style="width: 20rem">
          <input
            type="text"
            id="filter"
            class="form-control"
            placeholder="{{ 'PLACEHOLDER.FILTER' | translate }}"
            formControlName="filter"
          />
        </div>
        <div class="ms-4" style="width: 20rem">
          <ng-select
            class="w-100"
            placeholder="{{ 'PLACEHOLDER.ACTIVITY' | translate }}"
            formControlName="activity"
            [searchable]="false"
          >
            <ng-option
              *ngFor="let activity of activitiesList"
              [value]="activity.value"
            >
              {{ activity.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="d-flex" [formGroup]="selectForm!">
        <div style="width: 20rem">
          <ng-select
            class="w-100"
            [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
            [clearable]="false"
            formControlName="familyID"
          >
            <ng-option *ngFor="let select of refIDs" [value]="select.value">
              {{ select.label }}
            </ng-option>
          </ng-select>
        </div>
        <div class="ms-4" style="width: 20rem">
          <ng-select
            class="w-100"
            placeholder="{{ 'PLACEHOLDER.SITE' | translate }}"
            formControlName="site"
            [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
          >
            <ng-option *ngFor="let select of sites" [value]="select.value">
              {{ select.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="bg-primary mt-3" style="height: 3px"></div>
    </div>
    <div
      style="height: 70vh"
      *ngIf="familyID?.value == null || valueChanging == true"
    >
      <loading></loading>
    </div>

    <div *ngIf="stats?.length == 0" class="mt-2">
      <disclaimer text="{{ 'PERIOD.NO_GRAPH' | translate }}"></disclaimer>
    </div>

    <div
      class="d-flex my-5"
      *ngIf="familyID?.value != null && valueChanging == false"
    >
      <ngx-masonry
        class="w-100"
        [options]="{
          gutter: 16
        }"
      >
        <div
          ngxMasonryItem
          class="masonry-item"
          style="width: 500px; margin-bottom: 1rem"
          *ngFor="let stat of stats"
        >
          <div [ngSwitch]="stat.graphType">
            <div *ngSwitchCase="'NUMBER'">
              <graph-number
                [path]="stat.link"
                [periodID]="periodID"
                [familyID]="familyID?.value"
                [siteID]="site?.value"
                [title]="stat.titleLang"
                [hoverText]="stat.descriptionLang"
              ></graph-number>
            </div>
            <div *ngSwitchCase="'PIE'">
              <graph-pie
                [path]="stat.link"
                [periodID]="periodID"
                [familyID]="familyID?.value"
                [siteID]="site?.value"
                [title]="stat.titleLang"
                [showModal]="stat.showModal"
              ></graph-pie>
            </div>
            <div *ngSwitchCase="'DOUGHNUT'">
              <graph-doughnut
                [path]="stat.link"
                [periodID]="periodID"
                [familyID]="familyID?.value"
                [siteID]="site?.value"
                [title]="stat.titleLang"
                [showModal]="stat.showModal"
              ></graph-doughnut>
            </div>
            <div *ngSwitchDefault></div>
          </div>
        </div>
      </ngx-masonry>
    </div>
  </section>
</div>
