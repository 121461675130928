<div style="height: 30rem" *ngIf="!interventions">
  <loading [glass]="false"></loading>
</div>
<div class="d-flex mx-5" [formGroup]="filterForm!" *ngIf="interventions">
  <div style="width: 20rem">
    <ng-select
      class="w-100"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      placeholder="{{ 'PLACEHOLDER.SPECIES' | translate }}"
      formControlName="familyID"
    >
      <ng-option *ngFor="let select of refIDs" [value]="select.value">
        {{ select.label }}
      </ng-option>
    </ng-select>
  </div>
  <div class="ms-4" style="width: 20rem">
    <ng-select
      class="w-100"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      placeholder="{{ 'PLACEHOLDER.SITE' | translate }}"
      formControlName="site"
    >
      <ng-option *ngFor="let select of sites" [value]="select.value">
        {{ select.label }}
      </ng-option>
    </ng-select>
  </div>
</div>

<div class="mx-5 mt-4 row">
  <div class="col-3 d-flex flex-column text-center">
    <div
      class="pt-2 mx-4 mb-2 d-flex align-items-center justify-content-center"
      [ngClass]="i == tabSelected ? 'tab-active' : 'tab'"
      *ngFor="let intervention of interventions; index as i"
      (click)="openTab(i, intervention.value)"
    >
      <h5>{{ intervention.subtitle }}</h5>
    </div>
  </div>
  <div
    class="d-flex flex-column col-9 rounded border-primary"
    style="border: 2px solid; height: 40rem; position: relative"
    *ngIf="interventions"
  >
    <div class="d-flex flex-column text-center">
      <div
        class="py-2 mx-4 d-flex align-items-center justify-content-center border-primary"
        style="border-bottom: 2px solid"
      >
        <h5 class="pt-2">{{ interventions![tabSelected].label }}</h5>
      </div>
    </div>
    <div class="d-flex flex-column py-3 px-4" style="overflow-y: scroll">
      <div
        class="d-flex align-items-center mb-2 rounded px-1 py-1"
        *ngFor="let subIntervention of subInterventions"
        [class.blue-alpha]="subIntervention.fromProject"
      >
        <ngx-gauge
          [type]="'arch'"
          [min]="0"
          [max]="subIntervention.max"
          [value]="subIntervention.count"
          [thick]="15"
          [size]="100"
          [label]="subIntervention.percentage"
          foregroundColor="#006db5"
          [mdbTooltip]="subIntervention.count + ' / ' + subIntervention.max"
        >
        </ngx-gauge>
        <!-- <div
            class="ms-2"
            style="
              min-width: 75px;
              width: 75px;
              min-height: 37.5px;
              transform: translateY(25%);
            "
          >
            <svg
              viewBox="0 0 100 100"
              width="75px"
              [mdbTooltip]="subgoal.count + ' / ' + subgoal.max"
            >
              <circle class="bg" cx="50" cy="50" r="45" />
              <circle
                class="bg progress"
                cx="50"
                cy="50"
                r="45"
                [ngStyle]="{
                  'stroke-dasharray':
                    (150 * subgoal.count) / subgoal.max + ' 300',
                  stroke: '#006db5'
                }"
              />
            </svg>
          </div> -->
        <h5
          class="ms-4 pt-1"
          [ngClass]="subIntervention.count == 0 ? 'text-muted' : 'text-primary'"
        >
          {{ subIntervention.title }}
        </h5>
      </div>
    </div>
    <div
      class="w-100 h-100 position-absolute left-0 top-0"
      *ngIf="loadingInterventions"
    >
      <loading></loading>
    </div>
  </div>
</div>
