import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-confirm-mark-cleaned',
  templateUrl: './modal-confirm-mark-cleaned.component.html',
  styleUrls: ['./modal-confirm-mark-cleaned.component.scss'],
})
export class ModalConfirmMarkCleanedComponent implements OnInit {
  projectID?: string;
  familyID?: string;
  error?: string;

  constructor(
    public modalRef: MdbModalRef<ModalConfirmMarkCleanedComponent>,
    private api: ApiService,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}

  markAllAsCleaned() {
    this.api.doneVarietiesCleaning(this.projectID!, this.familyID!).subscribe({
      next: (value) => {
        this.modalRef.close(true);
      },
      error: (err) => {
        this.error = err.status;
      },
    });
  }
}
