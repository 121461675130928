import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Partner } from 'src/app/models/partner';

@Component({
  selector: 'dashboard-side-bar',
  templateUrl: './dashboard-side-bar.component.html',
  styleUrls: ['./dashboard-side-bar.component.scss'],
})
export class DashboardSideBarComponent implements OnInit {
  partners?: Partner[];

  count: any;

  firstList?: any[] = [];
  secondList?: any[] = [];

  constructor(private api: ApiService, private router: Router) {}

  ngOnInit(): void {
    this.partners = Partner.getPartners();

    this.getLists();

    this.api.getDashboardCount().subscribe((value) => {
      this.count = value;
    });
  }

  getLists() {
    if (this.partners) {
      this.partners = this.shuffle(this.partners);
      this.firstList = this.partners.slice(0, 4);
      this.firstList = this.shuffle(this.firstList);
      this.secondList = this.partners.slice(4, 9);
      this.secondList = this.shuffle(this.secondList);
    }
  }

  shuffle(array: Partner[]) {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  openPartners() {
    this.router.navigateByUrl('u/partners');
  }

  goToCrop() {
    this.router.navigateByUrl('/u/database/crop');
  }

  goToLivestock() {
    this.router.navigateByUrl('/u/database/livestock');
  }

  goToAquaculture() {
    this.router.navigateByUrl('/u/database/aquaculture');
  }
}
