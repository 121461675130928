<div
  class="bg-light modal-body rounded d-flex flex-column p-0"
  style="height: 95vh; overflow-y: auto; overflow-x: hidden"
>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row p-3 pb-0">
      <i class="bi bi-calendar-fill h2 mx-2"></i>
      <h2 class="mx-2">Calendar</h2>
    </div>
    <div class="bg-secondary mt-1 w-100" style="height: 1px"></div>
  </div>
  <div
    class="d-flex flex-column p-2"
    style="overflow-y: auto; overflow-x: hidden"
  >
    <div
      class="d-flex flex-column justify-content-center align-items-center w-100 my-2 card bg-light border-primary"
      style="border: solid 2px"
      *ngFor="let day of days; last as last"
    >
      <div class="my-2 d-flex justify-content-center">
        <h4 class="my-auto">
          {{ day | date: "MMMM" }}
        </h4>
      </div>
      <div class="d-flex flex-row w-100 py-2 mb-4 px-2">
        <h3 class="my-auto">
          {{ day | date: "d" }}
        </h3>
      </div>
      <!-- <div
        *ngIf="!last"
        class="bg-secondary mt-1 w-100"
        style="height: 1px"
      ></div> -->
    </div>
  </div>

  <div class="d-flex flex-column pb-2 bg-light mt-0">
    <div class="bg-primary mb-1" style="height: 2px"></div>
    <h3 class="my-2 mx-auto"><u>Next Event</u></h3>
    <div
      class="mx-4 card my-2 position-relative"
      style="height: 6rem; width: 6rem; overflow: hidden"
    >
      <div
        class="position-absolute bottom-0 left-0 w-100 d-flex flex-row justify-content-center align-items-center"
        style="height: 4rem"
      >
        <h2 class="my-auto mx-auto">
          {{ days[0] | date: "d" }}
        </h2>
      </div>
      <div
        class="mask bg-danger top-0 left-0 text-center text-white d-flex flex-row justify-content-center align-items-center"
        style="height: 2rem"
      >
        <h5 class="my-auto">
          {{ days[0] | date: "MMMM" }}
        </h5>
      </div>
    </div>
  </div>
</div>
