<div
  class="
    d-flex
    flex-column
    w-100
    mt-3
    justify-content-center
    align-items-center
  "
>
  <div class="w-25 h-25 mt-5 pt-5">
    <img
      class="w-100 h-100"
      src="../../../../assets/images/logo.png"
      style="object-fit: contain"
    />
  </div>
  <h1 class="primary-text">Select a Database Above</h1>
</div>
