import { registerLocaleData } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
import localeRu from '@angular/common/locales/ru';
import localeZh from '@angular/common/locales/zh';
import localeEs from '@angular/common/locales/es';
import localeAr from '@angular/common/locales/ar';
import { User } from './auth/models/user.model';
import { AppState } from './auth/reducer';
import { getIsLoggedIn, getUser } from './auth/store/auth.selectors';
import { Lang } from './models/lang.enum';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  textDirection = 'ltr';

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>
  ) {
    const langs = Object.keys(Lang)
      .filter((key) => isNaN(Number(key)))
      .map((value) => value.toLowerCase());

    translate.addLangs(langs);
    this.checkUser();

    this.translate.onLangChange.subscribe((event) => {
      if (event.lang == 'ar') {
        document.dir = 'rtl';
      } else {
        document.dir = 'ltr';
      }
    });
  }

  ngOnInit(): void {
    registerLocaleData(localeIt, 'it');
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeRu, 'ru');
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localeZh, 'zh');
    registerLocaleData(localeAr, 'ar');
  }

  checkUser() {
    this.store.select(getUser).subscribe(
      (value) => {
        if (value) {
          this.translate.use(value.lang.toLowerCase());
        } else {
          this.translate.use('en');
        }
      },
      () => this.translate.use('en')
    );
  }
}
