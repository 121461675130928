<div class="card col mb-3" style="overflow-y: auto; height: 38rem">
  <div class="mb-2 pb-0 sticky-top bg-white">
    <div class="d-flex flex-row mt-3 align-items-center w-100">
      <h3 class="mx-3 my-auto">{{ "COUNTRY.ETHNIC_GROUP" | translate }}</h3>
      <form class="d-flex flex-row my-auto mx-2" [formGroup]="filterForm!">
        <div class="mx-2">
          <input
            type="text"
            class="form-control"
            formControlName="filter"
            placeholder="{{ 'PLACEHOLDER.FILTER' | translate }}"
          />
        </div>
      </form>
    </div>
    <div class="row mx-2 mt-2 pt-1">
      <p class="col-3">{{ "DATABASE.LOCAL_NAME" | translate }}</p>
      <p class="col-3">{{ "DATABASE.NAME" | translate }}</p>
    </div>
    <div class="mx-2 w-90 bg-primary" style="height: 2px"></div>
  </div>

  <div class="d-flex flex-column mx-2 pb-1 pt-2">
    <div
      class="row align-items-center mx-2 rounded py-3 my-1 hover"
      *ngFor="let item of items; index as i"
      [ngClass]="i % 2 == 0 ? 'bg-light' : 'bg-white'"
    >
      <div class="col-3">
        <h5 class="my-auto">
          {{ item.localName }}
        </h5>
      </div>
      <div class="col-3">
        <h5 class="my-auto">
          {{ item.nameLang }}
        </h5>
      </div>
    </div>
  </div>
  <loading class="mask" *ngIf="loading"></loading>
</div>
<page-controller
  class="mt-5"
  *ngIf="items.length > 0"
  [pageable]="dataSource"
  (pageClick)="onPageClick($event)"
></page-controller>
