import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';
import { VarietyCleaning } from 'src/app/models/variety-cleaning.types';

@Component({
  selector: 'modal-split-varieties',
  templateUrl: './modal-split-varieties.component.html',
  styleUrls: ['./modal-split-varieties.component.scss'],
})
export class ModalSplitVarietiesComponent implements OnInit {
  varietiesForm?: UntypedFormGroup;
  varietySelected?: VarietyCleaning;

  errorLabel: boolean = false;

  nVarieties?: number;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalSplitVarietiesComponent>,
    private formBuilder: UntypedFormBuilder,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.varietiesForm = new UntypedFormGroup({
      refID: new UntypedFormControl(this.varietySelected?.projectVarietyID),
      varieties: this.formBuilder.array([]),
    });
  }

  selectVarietiesNumber(i: number) {
    this.nVarieties = i;
    this.generateFormArray();
  }

  generateFormArray() {
    while (this.varieties.length !== 0) {
      this.varieties.removeAt(0);
    }
    for (let i = 0; i < this.nVarieties!; i++) {
      const varietyGroup = this.formBuilder.group({
        newSites: null,
        newName: null,
      });
      this.varieties.push(varietyGroup);
    }
  }

  split() {
    this.api.splitVarieties(this.varietiesForm?.value).subscribe({
      complete: () => {
        this.modalRef.close(true);
      },
      error: (error) => {
        this.errorLabel = true;
        this.nVarieties = undefined;
      },
    });
  }

  tryAgain() {
    for (let i = 0; i <= this.varieties.value.length; i++) {
      this.varieties.removeAt(0);
    }

    this.errorLabel = false;
  }

  get varieties() {
    return this.varietiesForm?.get('varieties') as UntypedFormArray;
  }
}
