<div class="d-flex flex-column mt-1">
  <h4 class="text-center mb-2">
    {{ "VARIETY_CLEANING.ANSWER_DETAILS" | translate }}
  </h4>

  <div class="d-flex flex-column px-3" style="overflow-y: auto">
    <div
      class="rounded p-2 mb-2 shadow-2"
      [ngClass]="
        item.cleaned ? 'cleaned' : item.disabled ? 'disabled' : 'bg-white'
      "
      *ngFor="let item of answerInfo.list; index as i"
    >
      <span>{{ item.name }}</span>
      <h6 class="pb-2">
        {{ "OT_CLEANING.AREA" | translate }}:
        {{ item.quantity + " " + item.measureLang }}
      </h6>
      <div class="d-flex justify-content-around">
        <span
          class="text-decoration-underline pointer"
          (click)="openHistory(item.refID)"
        >
          {{ "OT_CLEANING.HISTORY" | translate }}
        </span>
        <span
          class="text-decoration-underline pointer"
          (click)="openEditInfo(item.quantity, item.measure, item.refID)"
          *ngIf="!item.disabled"
        >
          {{ "OT_CLEANING.EDIT" | translate }}
        </span>
      </div>
    </div>
    <hr class="my-2" />
    <div class="d-flex flex-column mb-2" *ngFor="let item of answerInfo.list">
      <span>
        <strong> {{ "OT_CLEANING.UNIQUE_ID" | translate }}: </strong>
        {{ item.uuid }}
      </span>
      <span>
        <strong> {{ "OT_CLEANING.TABLET_ID" | translate }}: </strong>
        {{ item.tabletID }}
      </span>
      <span *ngIf="item.notes"
        ><strong>{{ "OT_CLEANING.NOTES" | translate }}:</strong>
        {{ item.notes }}</span
      >
      <div
        class="btn btn-danger mb-4 ms-auto"
        *ngIf="!item.disabled"
        (click)="
          openDisableAnswer(
            item.refID,
            item.urlDisable,
            item.disabled,
            item.measure
          )
        "
      >
        {{ "BUTTONS.DISABLE" | translate }}
      </div>
      <div
        class="btn btn-success mb-4 ms-auto"
        *ngIf="item.disabled"
        (click)="
          openDisableAnswer(
            item.refID,
            item.urlDisable,
            item.disabled,
            item.measure
          )
        "
      >
        {{ "BUTTONS.ENABLE" | translate }}
      </div>
    </div>
  </div>
</div>
