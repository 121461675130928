import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { Project, Site } from 'src/app/models/project';

@Component({
  selector: 'site-detailed-page',
  templateUrl: './site-detailed-page.component.html',
  styleUrls: ['./site-detailed-page.component.scss'],
})
export class SiteDetailedPageComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  site?: Site;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.apiCall();

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.apiCall();
    });
  }

  apiCall() {
    const siteID = this.route.snapshot.paramMap.get('id');
    if (siteID) {
      this.api.getSite(siteID).subscribe(
        (data) => {
          this.site = data as Site;
        },
        (error) => {
          if (error.status == 403) {
            this.router.navigateByUrl('/u/projects');
          }
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  shrinkString(string: string | undefined) {
    if (string?.length && string.length <= 40) return string;
    return string?.substring(0, 37) + ' ...';
  }

  getAgroZone(site?: Site): string {
    if (site?.agroEcoZoneId === 'OTHER') {
      return site?.agroEcoZoneOther || '';
    } else {
      return site?.agroEcoZone || '';
    }
  }

  backToProject() {
    let ref;
    switch (this.site!.ref) {
      case 'CROPS':
        ref = 'c';
        break;
      case 'LIVESTOCK':
        ref = 'l';
        break;
      case 'AQUATICS':
        ref = 'a';
        break;
      default:
        break;
    }
    this.router.navigateByUrl('u/project/' + ref + '/' + this.site?.projectID);
  }
}
