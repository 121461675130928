<div class="border-danger card" style="border: 3px solid">
  <div class="modal-body">
    <h4>{{ "VARIETY.DELETE_PROJECT_TITLE" | translate }}</h4>
    <h5>
      {{ "VARIETY.DELETE_PROJECT_SUBTITLE" | translate }}
    </h5>
  </div>
  <loading class="mask" *ngIf="loading"></loading>
  <div class="modal-footer justify-content-center">
    <button
      type="button"
      class="btn btn-primary"
      (click)="modalRef.close(false)"
    >
      {{ "BUTTONS.CLOSE" | translate }}
    </button>
    <button type="button" class="btn btn-danger" (click)="deleteProject()">
      {{ "BUTTONS.DELETE" | translate }}
    </button>
  </div>
</div>
