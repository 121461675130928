<div class="w-100 h-100">
  <div class="row mt-3 mb-5 mx-2 gap-3">
    <div
      class="col d-flex bg-white card p-0 m-0"
      style="height: 50vh; overflow-y: auto"
    >
      <list-header
        class="sticky-top p-0 m-0"
        title="{{ 'NAVIGATION.LEVEL_1' | translate }}"
      ></list-header>

      <div class="d-flex flex-column p-0 m-0">
        <div
          class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2 hover"
          style="cursor: pointer"
          [ngClass]="type.selected ? 'bg-secondary text-white' : 'hover'"
          *ngFor="let type of types; let i = index"
          (click)="onSelectType(i)"
        >
          <div class="px-3 py-1 d-flex flex-column">
            <div class="my-auto">
              <h5 class="">
                {{ type.code + " - " + type.livello }}
              </h5>
              <div
                class="d-flex flex-row align-items-center animate info-hover"
                *ngIf="type.selected"
                (click)="openConstraint(type.constraintID, type.livello)"
              >
                <i class="bi bi-info-circle-fill p my-auto"></i>
                <u>
                  <p class="p-0 m-0 my-auto mx-2">
                    {{ "BUTTONS.VIEW_INTERVENTIONS" | translate }}
                  </p>
                </u>
              </div>
            </div>
          </div>

          <i class="bi bi-caret-right-fill h5 px-3 py-2 my-auto"></i>
        </div>
      </div>
    </div>

    <div
      class="col d-flex bg-white card"
      style="height: 50vh; overflow-y: auto"
    >
      <list-header
        class="sticky-top p-0 m-0"
        title="{{ 'NAVIGATION.LEVEL_2' | translate }}"
      ></list-header>

      <database-table-placeholder
        *ngIf="subTypes.length == 0"
        class="h-100"
        [image]="getImage()"
        text="DATABASE.SELECT_LEVEL_1"
      ></database-table-placeholder>

      <div class="d-flex flex-column p-0 m-0">
        <div
          class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2"
          style="cursor: pointer"
          [ngClass]="type.selected ? 'bg-secondary text-white' : 'hover'"
          *ngFor="let type of subTypes; let i = index"
          (click)="loadDescriptors(i)"
        >
          <div class="px-3 py-1 d-flex flex-column">
            <div class="my-auto">
              <h5>
                {{ type.code + " - " + type.livello }}
              </h5>
              <div
                class="d-flex flex-row align-items-center animate info-hover"
                *ngIf="type.selected"
                (click)="openConstraint(type.constraintID, type.livello)"
              >
                <i class="bi bi-info-circle-fill p my-auto"></i>
                <u>
                  <p class="p-0 m-0 my-auto mx-2">
                    {{ "BUTTONS.VIEW_INTERVENTIONS" | translate }}
                  </p>
                </u>
              </div>
            </div>
          </div>

          <i
            class="bi bi-caret-right-fill h5 px-3 py-2 my-auto"
            *ngIf="type.subLevel"
          ></i>
        </div>
      </div>
    </div>

    <div
      class="col d-flex bg-white card"
      style="height: 50vh; overflow-y: auto"
    >
      <list-header
        class="sticky-top p-0 m-0"
        title="{{ 'NAVIGATION.LEVEL_3' | translate }}"
      ></list-header>

      <database-table-placeholder
        *ngIf="descriptors.length == 0"
        class="h-100"
        [image]="getImage()"
        text="DATABASE.SELECT_LEVEL_2"
      ></database-table-placeholder>

      <div class="d-flex flex-column p-0 m-0">
        <div
          class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2"
          [ngClass]="descriptor.selected ? 'bg-secondary text-white' : 'hover'"
          *ngFor="let descriptor of descriptors; let i = index"
          (click)="focusDescriptor(i)"
        >
          <div class="px-3 py-1 d-flex flex-column">
            <div class="my-auto">
              <h5 class="my-auto">
                {{ descriptor.code + " - " + descriptor.livello }}
              </h5>
              <div
                class="d-flex flex-row align-items-center animate info-hover"
                *ngIf="descriptor.selected"
                (click)="
                  openConstraint(descriptor.constraintID, descriptor.livello)
                "
              >
                <i class="bi bi-info-circle-fill p my-auto"></i>
                <u>
                  <p class="p-0 m-0 my-auto mx-2">
                    {{ "BUTTONS.VIEW_INTERVENTIONS" | translate }}
                  </p>
                </u>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
