import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Site } from 'src/app/models/project';
import { Select } from 'src/app/models/select';
import { ModalMeSelectDescriptorRowComponent } from '../modal-me-select-descriptor-row/modal-me-select-descriptor-row.component';

@Component({
  selector: 'modal-me-show-goals',
  templateUrl: './modal-me-show-goals.component.html',
  styleUrls: ['./modal-me-show-goals.component.scss'],
})
export class ModalMeShowGoalsComponent implements OnInit {
  item?: any;
  familySelected?: string;
  families?: Select[];
  periods?: any[];

  filterForm?: FormGroup;
  ref?: string;
  rows: any[] = [];
  columnHeaders: any[] = [];
  answerInfo: any;

  selectedRow: number = -1;
  selectedRows: number[] = [];
  selectedColumn: number = -1;
  selectedColumns: number[] = [];
  selectedAnswer: any;

  loading: boolean = false;

  constructor(
    public modalRef: MdbModalRef<ModalMeShowGoalsComponent>,
    private location: LocationStrategy,
    private modalService: MdbModalService,
    private api: ApiService
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.getTableStructure();
    this.getPeriodsColumHeaders();
  }

  getTableStructure() {
    this.rows = [];
    this.api
      .getStructureDevelopmentBenefit(this.item.projectDevelopmentBenefitID)
      .subscribe((value) => {
        this.rows = value as any;
        this.loading = false;
      });
  }

  getPeriodsColumHeaders() {
    this.columnHeaders = this.columnHeaders.concat(columnHeaders);
    this.periods?.forEach((e) => {
      this.columnHeaders.splice(3, 0, 'ME.DB_GD_PERIOD');
    });
  }

  addRow() {
    const modalRef = this.modalService.open(
      ModalMeSelectDescriptorRowComponent,
      {
        data: { descriptors: this.item?.descriptors },
      }
    );

    modalRef.onClose.subscribe((value) => {
      if (!value) return;
      this.rows.push(value);
    });
  }

  getBackgroundCell(rowIndex: number, answerIndex: number): string {
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'cell-selected text-white border-primary';
    }
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.indexOf(answerIndex) === -1
    ) {
      return 'full-row';
    }
    if (
      this.selectedRow != rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'full-row';
    }
    return 'bg-white';
  }

  cellClicked(e: any, columnIndex: number) {
    if (!e.clickable) return;
  }
}

const columnHeaders = [
  'ME.DB_GD_COL_1',
  'ME.DB_GD_COL_2',
  'ME.DB_GD_COL_3',
  //PERIODS
  'ME.DB_GD_COL_4',
  'ME.DB_GD_COL_5',
  'ME.DB_GD_COL_6',
  'ME.DB_GD_COL_7',
  //
  'ME.DB_GD_COL_8',
  'ME.DB_GD_COL_9',
  'ME.DB_GD_COL_10',
  'ME.DB_GD_COL_11',
];
