import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalDeclineRequestComponent } from 'src/app/modals/projects/modal-decline-request/modal-decline-request.component';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-requests',
  templateUrl: './project-requests.component.html',
  styleUrls: ['./project-requests.component.scss'],
})
export class ProjectRequestsComponent implements OnInit {
  requests?: Project[];

  constructor(
    private api: ApiService,
    private modalService: MdbModalService,
    private router: Router,
    public modalRef: MdbModalRef<ProjectRequestsComponent>
  ) {}

  ngOnInit(): void {
    this.apiCall();
  }

  apiCall() {
    this.api.getPendingRequests().subscribe((value) => {
      this.requests = value as Project[];
    });
  }

  openDeclineRequest(projectID: string) {
    const modalRef = this.modalService.open(ModalDeclineRequestComponent, {
      data: {
        projectID: projectID,
      },
      modalClass: 'modal-lg',
    });

    modalRef.onClose.subscribe((refresh: boolean) => {
      if (refresh) this.apiCall();
    });
  }

  acceptRequest(projectID: string) {
    this.api.acceptProjectUser(projectID).subscribe(
      () => {
        this.apiCall();
        this.modalRef.close(true);
      },
      (error) => {}
    );
  }

  declineRequest(projectID: string) {
    this.api.rejectProjectUser(projectID).subscribe(() => {
      this.api.getPendingRequests().subscribe((value) => {
        this.requests = value as Project[];
      });
    });
  }

  goToDeclinedRequests() {
    this.router.navigateByUrl('u/projects/declined-requests');
  }

  shrinkRequest(string: string | undefined) {
    if (string?.length && string.length <= 30) return string;
    return string?.substring(0, 27) + ' ...';
  }
}
