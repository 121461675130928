<div class="container-fluid p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-5">
      <!-- Header -->
      <div class="d-flex my-2">
        <div
          class="rounded-circle d-flex align-items-center justify-content-center"
          style="height: 7rem; width: 7rem; overflow: hidden"
        >
          <span
            class="w-100 h-100 fi fis"
            style="object-fit: cover"
            [ngClass]="'fi-' + country?.cnIsoDue?.toLowerCase()"
          ></span>
        </div>
        <div class="d-flex mx-2 p-0 flex-column justify-content-center">
          <h1 class="fw-bold">{{ country?.shortName }}</h1>
          <h5 class="mx-2 fst-italic">{{ country?.officialName }}</h5>
        </div>
      </div>
      <div class="bg-primary w-75 mt-3" style="height: 2.5px"></div>
      <div class="d-flex d-row py-0 my-0 mt-4 mb-2">
        <horizontal-list-button
          name="{{ 'COUNTRY.AGRO_ZONE' | translate }}"
          routerLink="agro-ecological-zone"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'COUNTRY.ETHNIC_GROUP' | translate }}"
          routerLink="social-ethnic-group"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'COUNTRY.CROP_POLICIES' | translate }}"
          routerLink="crop-policies"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'COUNTRY.LIVESTOCK_POLICIES' | translate }}"
          routerLink="livestock-policies"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'COUNTRY.AQUACULTURE_POLICIES' | translate }}"
          routerLink="aquaculture-policies"
        ></horizontal-list-button>
      </div>
      <div class="d-flex flex-row">
        <div class="row w-100 gap-2 px-1">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
