<div class="modal-body">
  <h4>{{ "VARIETY_CLEANING.MARK_ALL_TITLE" | translate }}</h4>
  <h5>
    {{ "VARIETY_CLEANING.MARK_ALL_SUBTITLE" | translate }}
  </h5>
  <div class="py-3 bg-error mt-4 rounded text-center" *ngIf="error">
    <h6 class="my-auto">{{ "OT_CLEANING.ERROR" | translate }} [{{ error }}]</h6>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="markAllAsCleaned()">
    {{ "VARIETY_CLEANING.MARK_ALL" | translate }}
  </button>
</div>
