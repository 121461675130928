import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { User } from 'src/app/auth/models/user.model';
import {
  DashboardHelper,
  DashboardListener,
} from 'src/app/models/global-helper';

@Component({
  selector: 'modal-change-role',
  templateUrl: './modal-change-role.component.html',
  styleUrls: ['./modal-change-role.component.scss'],
})
export class ModalChangeRoleComponent implements OnInit {
  dashboardHelper: DashboardHelper = new DashboardHelper();
  user?: User;

  constructor(
    private api: ApiService,
    private dashboardListener: DashboardListener,
    public modalRef: MdbModalRef<ModalChangeRoleComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}

  changeRole() {
    const role = this.user?.currentRole.role === 'USER' ? 'PARTNERS' : 'USER';
    this.api.changeRoleType(role).subscribe(
      () => {
        this.dashboardHelper.userRole = role;
        this.dashboardListener.setData(this.dashboardHelper);
        this.modalRef.close(true);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
