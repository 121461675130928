<div class="modal-header bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-3">
      <div class="d-flex align-items-center mb-2">
        <!-- TITLE -->
        <div class="d-flex align-items-center mt-2">
          <i
            class="bi bi-arrow-left-square-fill h1 pointer my-auto"
            (click)="modalRef.close()"
          ></i>
          <h1 class="fw-bold my-auto mx-3">
            {{ title }}
          </h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-body bg-light">
  <div class="d-flex flex-row mt-3 mx-5">
    <div
      class="border-primary rounded"
      style="
        width: 75%;
        border: solid 2px;
        position: relative;
        overflow: scroll;
        min-height: 80vh;
        max-height: 80vh;
      "
    >
      <div
        class="d-flex flex-column align-items-center mt-4 text-center"
        *ngIf="state == 1"
      >
        <img
          src="/assets/images/logo.png"
          style="height: 15rem; width: 15rem"
        />
        <h4>{{ title }} {{ "OT_CLEANING.EDITING_POINT_1" | translate }}</h4>
        <h4>{{ "OT_CLEANING.EDITING_POINT_2" | translate }}</h4>
        <h4 class="font-weight-bold mt-3">
          {{ "OT_CLEANING.EDITING_POINT_3" | translate
          }}<i class="bi-arrow-left-square-fill"></i>
        </h4>
        <div class="btn btn-primary mt-3" (click)="getTableStructure()">
          {{ "VARIETY_CLEANING.START_CLEANING" | translate }}
        </div>
      </div>
      <div
        class="d-flex flex-column align-items-center mt-4 text-center"
        *ngIf="state == 2"
      >
        <img
          src="/assets/images/logo.png"
          style="height: 15rem; width: 15rem"
        />
        <h4 class="text-danger">
          {{ title }} {{ "OT_CLEANING.EDITING_POINT_4" | translate }}
        </h4>
        <div class="btn btn-primary mt-3">
          {{ "BUTTONS.CLOSE" | translate }}
        </div>
      </div>

      <table
        *ngIf="
          columnHeaders.length != 0 &&
          rows.length != 0 &&
          !loading &&
          state == 3
        "
      >
        <thead>
          <tr *ngIf="columnHeaders">
            <th
              class="bg-light border-primary rounded-5 shadow-2"
              style="z-index: 20"
            ></th>
            <!-- DESCRIPTOR CLICKED -->
            <th
              class="pointer px-2 py-0 rounded-5 shadow-2"
              style="max-height: 4rem; min-height: 4rem"
              *ngFor="
                let column of getColumnHeaderLivestock();
                index as columnIndex
              "
              [attr.colspan]="column.span"
              [ngClass]="
                selectedColumn == columnIndex
                  ? 'cell-selected text-white'
                  : 'bg-white text-primary'
              "
              (click)="selectColumn(columnIndex)"
            >
              <div
                class="py-2 text-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
              >
                {{ column.name }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="rows">
          <tr *ngFor="let row of rows; index as rowIndex">
            <th
              class="rounded-5 shadow-2 pointer"
              style="z-index: 10"
              [ngClass]="
                selectedRow == rowIndex
                  ? 'cell-selected text-white'
                  : 'bg-white text-primary'
              "
              (click)="getGeneralInfos(row.fgdID, row.refID, rowIndex)"
            >
              <div class="d-flex flex-column justify-content-center">
                <span
                  class="my-auto"
                  [ngClass]="!last ? '' : ''"
                  *ngFor="let name of row.name.split('~'); last as last"
                >
                  {{ name }}
                </span>
              </div>
            </th>
            <!-- CELL CLICKED -->
            <td
              class="text-center pointer m-1 rounded-5 shadow-2"
              [ngClass]="getBackgroundCell(rowIndex, answerIndex)"
              *ngFor="let answer of row.answers; index as answerIndex"
              (click)="getDetail(answer, rowIndex, answerIndex)"
            >
              <div
                class="d-flex align-items-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
              >
                <span class="my-auto w-100 text-center">
                  {{ answer.name }}
                </span>
                <i
                  class="bi bi-chat-left-text-fill my-auto"
                  *ngIf="answer.hasNote"
                ></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="border-primary rounded d-flex flex-column align-items-center justify-content-between mx-2"
      style="width: 25%; border: 2px solid"
    >
      <div class="d-flex flex-column w-100">
        <div
          class="d-flex flex-column"
          *ngIf="!isGeneralInfosOpened && !isLegendOpened && !isDetailOpened"
        >
          <h4 class="mx-auto mt-2">{{ "GRID.INSTRUCTIONS" | translate }}</h4>
          <ul>
            <li class="h5 mt-4">
              {{ "WORKING_AREA.POINT_4" | translate }}
            </li>
            <li class="h5 mt-4">
              {{ "VARIETY_CLEANING.POINT_1" | translate }}
            </li>
            <li class="h5 mt-4">
              {{ "OT_CLEANING.POINT_1" | translate }}
            </li>
            <li class="h5 mt-4">
              {{ "OT_CLEANING.POINT_2" | translate }}
            </li>
            <li class="h5 mt-4">
              {{ "OT_CLEANING.POINT_3" | translate }}
            </li>
          </ul>
        </div>

        <div
          class="d-flex flex-column mt-2 text-center"
          *ngIf="isLegendOpened"
        ></div>

        <div class="w-100 h-100 d-flex flex-column mt-2" *ngIf="isDetailOpened">
          <!-- <loading *ngIf="!selectedColumnData"></loading> -->
          <div>
            <div
              class="mb-2 mx-3 rounded d-flex flex-column bg-secondary shadow text-white text-center"
              *ngIf="selectedColumnData && selectedColumnData.minG && !measure"
            >
              <div class="d-flex justify-content-between px-3 py-1">
                <i class="bi-graph-up opacity-0"></i>
                <span>{{ "OT_CLEANING.G" | translate }}</span>
                <i
                  class="bi-graph-up pointer"
                  (click)="openGaussian(selectedColumnData.gaussianG)"
                ></i>
              </div>
              <hr class="border-white my-0" />
              <div class="d-flex flex-column w-100">
                <div class="d-flex mx-2 mt-2">
                  <div
                    class="d-flex data-container justify-content-center rounded px-2"
                    [class.selected-data-container]="minG"
                    (click)="getMin('g')"
                  >
                    <span class="mx-1">
                      <strong> {{ "OT_CLEANING.MIN" | translate }}: </strong>
                    </span>
                    <span class="mx-1">{{ selectedColumnData.minG }}</span>
                  </div>
                  <div
                    class="d-flex data-container rounded justify-content-center"
                    (click)="getMax('g')"
                    [class.selected-data-container]="maxG"
                  >
                    <span class="mx-1">
                      <strong> {{ "OT_CLEANING.MAX" | translate }}: </strong>
                    </span>
                    <span class="mx-1">{{ selectedColumnData.maxG }}</span>
                  </div>
                </div>
                <div class="d-flex mx-2 mb-2 text-center">
                  <div class="d-flex w-50 justify-content-center">
                    <span class="mx-1">
                      <strong> {{ "OT_CLEANING.AVG" | translate }}: </strong>
                    </span>
                    <span class="mx-1">
                      {{ selectedColumnData.avgG.toFixed(2) }}
                    </span>
                  </div>
                  <div class="d-flex w-50 justify-content-center">
                    <strong>
                      <span class="mx-1">
                        {{ "OT_CLEANING.MD" | translate }}:
                      </span>
                    </strong>
                    <span class="mx-1">
                      {{ selectedColumnData.medianG.toFixed(2) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mb-2 mx-3 rounded d-flex flex-column bg-secondary shadow text-white text-center"
              *ngIf="selectedColumnData && selectedColumnData.minI && !measure"
            >
              <div class="d-flex justify-content-between px-3 py-1">
                <i class="bi-graph-up opacity-0"></i>
                <span>{{ "OT_CLEANING.I" | translate }}</span>
                <i
                  class="bi-graph-up pointer"
                  (click)="openGaussian(selectedColumnData.gaussianI)"
                ></i>
              </div>
              <hr class="border-white my-0" />
              <div class="d-flex flex-column w-100">
                <div class="d-flex mx-2 mt-2">
                  <div
                    class="d-flex data-container justify-content-center rounded px-2"
                    [class.selected-data-container]="minI"
                    (click)="getMin('i')"
                  >
                    <span class="mx-1">
                      <strong> {{ "OT_CLEANING.MIN" | translate }}: </strong>
                    </span>
                    <span class="mx-1">{{ selectedColumnData.minI }}</span>
                  </div>
                  <div
                    class="d-flex data-container rounded justify-content-center"
                    (click)="getMax('i')"
                    [class.selected-data-container]="maxI"
                  >
                    <span class="mx-1">
                      <strong> {{ "OT_CLEANING.MAX" | translate }}: </strong>
                    </span>
                    <span class="mx-1">{{ selectedColumnData.maxI }}</span>
                  </div>
                </div>
                <div class="d-flex mx-2 mb-2 text-center">
                  <div class="d-flex w-50 justify-content-center">
                    <span class="mx-1">
                      <strong> {{ "OT_CLEANING.AVG" | translate }}: </strong>
                    </span>
                    <span class="mx-1">
                      {{ selectedColumnData.avgI.toFixed(2) }}
                    </span>
                  </div>
                  <div class="d-flex w-50 justify-content-center">
                    <strong>
                      <span class="mx-1">
                        {{ "OT_CLEANING.MD" | translate }}:
                      </span>
                    </strong>
                    <span class="mx-1">
                      {{ selectedColumnData.medianI.toFixed(2) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div
              class="mb-2 mx-3 rounded d-flex flex-column bg-secondary shadow text-white text-center"
              *ngIf="selectedColumnData && selectedColumnData.minI"
            >
              <span>{{ "OT_CLEANING.I" | translate }}</span>
              <hr class="border-white my-0" />
              <div class="d-flex justify-content-around m-2">
                <div
                  class="d-flex flex-column data-container rounded px-2"
                  (click)="getMin('i')"
                  [class.selected-data-container]="minI"
                >
                  <span>{{ "OT_CLEANING.MIN" | translate }}</span>
                  <span>{{ selectedColumnData.minI }}</span>
                </div>
                <div
                  class="d-flex flex-column data-container rounded"
                  (click)="getMax('i')"
                  [class.selected-data-container]="maxI"
                >
                  <span>{{ "OT_CLEANING.MAX" | translate }}</span>
                  <span>{{ selectedColumnData.maxI }}</span>
                </div>
                <div class="d-flex flex-column" style="width: 33%">
                  <span>{{ "OT_CLEANING.AVG" | translate }}</span>
                  <span>{{ selectedColumnData.avgI.toFixed(2) }}</span>
                </div>
              </div>
            </div> -->
            <div
              class="mb-2 mx-3 rounded d-flex flex-column bg-secondary shadow text-white text-center"
              *ngIf="selectedColumnData && selectedColumnData.minG && measure"
            >
              <span>
                {{ "OT_CLEANING." + measure.toUpperCase() | translate }}
              </span>
              <hr class="border-white my-0" />
              <div class="d-flex justify-content-around m-2">
                <div
                  class="d-flex flex-column data-container rounded px-2"
                  [class.selected-data-container]="minG"
                  (click)="getMin('g')"
                >
                  <span>{{ "OT_CLEANING.MIN" | translate }}</span>
                  <span>{{ selectedColumnData.minG }}</span>
                </div>
                <div
                  class="d-flex flex-column data-container rounded"
                  (click)="getMax('g')"
                  [class.selected-data-container]="maxG"
                >
                  <span>{{ "OT_CLEANING.MAX" | translate }}</span>
                  <span>{{ selectedColumnData.maxG }}</span>
                </div>
                <div class="d-flex flex-column" style="width: 33%">
                  <span>{{ "OT_CLEANING.AVG" | translate }}</span>
                  <span>{{ selectedColumnData.avgG.toFixed(2) }}</span>
                </div>
              </div>
            </div>
            <hr class="my-2" *ngIf="minMaxes" />
            <div
              class="d-flex flex-column px-3"
              *ngIf="minMaxes"
              [ngStyle]="{
                'overflow-y': 'auto',
                height: selectedColumnData.minI ? '32rem' : '38rem'
              }"
            >
              <div
                class="mb-2"
                *ngFor="let item of minMaxes; index as i; last as last"
              >
                <div
                  class="rounded p-2 mb-2 shadow-2"
                  [ngClass]="item.cleaned ? 'cleaned' : 'bg-white'"
                >
                  <div class="d-flex flex-column">
                    <span>
                      {{ "OT_CLEANING.UNIQUE_ID" | translate }}:
                      {{ item.uuid }}
                    </span>
                    <span>
                      {{ "OT_CLEANING.TABLET_ID" | translate }}:
                      {{ item.tabletID }}
                    </span>
                  </div>
                  <hr class="my-2" />
                  <h6>{{ item.name }}</h6>
                  <h6 class="pb-2">
                    {{ "OT_CLEANING.QUANTITY" | translate }}:
                    {{ item.quantity + " " + item.measure }}
                  </h6>
                  <div class="d-flex justify-content-around">
                    <span
                      class="text-decoration-underline pointer"
                      (click)="openInfo(i)"
                    >
                      {{ "OT_CLEANING.INFO" | translate }}
                    </span>
                    <span
                      class="text-decoration-underline pointer"
                      (click)="openHistory(item.refID, item.urlHistory)"
                    >
                      {{ "OT_CLEANING.HISTORY" | translate }}
                    </span>
                  </div>
                  <div class="d-flex justify-content-around">
                    <span
                      class="text-decoration-underline pointer"
                      (click)="
                        openEditInfo(
                          item.quantity,
                          item.measure,
                          item.refID,
                          item.urlEdit,
                          item.rowIndex
                        )
                      "
                    >
                      {{ "OT_CLEANING.EDIT" | translate }}
                    </span>
                    <span
                      class="text-decoration-underline pointer text-danger"
                      (click)="
                        openDisableAnswer(
                          item.refID,
                          item.urlDisable,
                          item.disabled,
                          item.rowIndex,
                          item.measure
                        )
                      "
                    >
                      {{ "BUTTONS.DISABLE" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div [ngSwitch]="type" *ngIf="answerInfo">
              <div *ngSwitchCase="'FGD_C_OT4'">
                <fdg-ot4
                  [answerInfo]="answerInfo"
                  [type]="type"
                  (changeValue)="outRefresh($event)"
                ></fdg-ot4>
              </div>
              <div *ngSwitchCase="'HHS_C_OT2'">
                <fdg-ot4
                  [answerInfo]="answerInfo"
                  [type]="type"
                  (changeValue)="outRefresh($event)"
                ></fdg-ot4>
              </div>
              <div *ngSwitchCase="'HHS_C_OT4'">
                <fdg-ot4
                  [answerInfo]="answerInfo"
                  [type]="type"
                  (changeValue)="outRefresh($event)"
                ></fdg-ot4>
              </div>
              <div *ngSwitchCase="'HHS_C_OT1'">
                <hhs-ot1
                  [answerInfo]="answerInfo"
                  [type]="type"
                  (changeValue)="outRefresh($event)"
                ></hhs-ot1>
              </div>
              <div *ngSwitchDefault></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mask" style="z-index: 99999" *ngIf="globalLoading">
    <loading></loading>
  </div>
</div>
<div class="modal-footer bg-light">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
</div>
