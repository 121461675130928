<div class="container-fluid p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-5">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex">
          <i class="bi bi-kanban-fill h1 my-auto"></i>
          <h1 class="fw-bold mx-2 my-auto">
            {{ "NAVIGATION.DASHBOARD" | translate }}
          </h1>
          <div
            class="rounded my-auto text-white px-3 mx-1"
            style="background-color: rgba(55, 100, 196, 0.434)"
          >
            {{ "DASHBOARD.APP_VERSION" | translate }}
            {{ appVersion?.versionName }}
          </div>
        </div>

        <div class="d-flex align-items-center">
          <mdb-sidenav-content class="my-auto">
            <div
              class="btn btn-primary mx-1 my-auto"
              (click)="openStatistics()"
            >
              <i class="mx-1 bi bi-grid-1x2-fill"></i>
              <span class="mx-1">{{ "DASHBOARD.STATISTICS" | translate }}</span>
            </div>
          </mdb-sidenav-content>
          <!-- <div class="btn btn-primary mx-1 my-auto" (click)="openCalendar()">
            <i class="mx-1 bi bi-calendar-fill"></i>
            <span class="mx-1">{{ "DASHBOARD.CALENDAR" | translate }}</span>
          </div> -->
        </div>
      </div>
      <div class="d-flex flex-column" *ngIf="dashboardType === 'USER'">
        <div class="row gap-3 my-2 justify-content-center">
          <div
            class="col-2 card pointer"
            style="height: 5rem; overflow: hidden"
            (click)="openPartners()"
          >
            <mdb-carousel [animation]="'fade'">
              <mdb-carousel-item *ngFor="let item of secondList">
                <img
                  src="/assets/sized-partners/{{ item }}"
                  class="w-100"
                  style="object-fit: contain; height: 5rem"
                />
              </mdb-carousel-item>
            </mdb-carousel>
          </div>
          <div
            class="col-2 card pointer"
            style="height: 5rem; overflow: hidden"
            (click)="openPartners()"
          >
            <mdb-carousel [animation]="'fade'">
              <mdb-carousel-item *ngFor="let item of firstList">
                <img
                  src="/assets/sized-partners/{{ item }}"
                  class="w-100"
                  style="object-fit: contain; height: 5rem"
                />
              </mdb-carousel-item>
            </mdb-carousel>
          </div>
        </div>
        <div class="d-flex flex-column mt-3 clamp-margin">
          <div class="d-inline-flex flex-column">
            <div class="d-flex flex-row my-auto justify-content-center">
              <i class="bi bi-globe2 h4 my-auto"></i>
              <h4 class="my-auto mx-2">
                {{ "DASHBOARD.MAP_TITLE" | translate }}
              </h4>
              <div class="d-flex justify-content-center btn-transparent mx-3">
                <a
                  class="btn btn-primary mx-2 my-auto"
                  style="background-color: rgb(55, 94, 176)"
                  (click)="changeField('total')"
                >
                  {{ "DASHBOARD.TOTAL" | translate }}
                </a>
                <a
                  class="btn btn-primary mx-2 my-auto"
                  style="background-color: rgb(220, 120, 53)"
                  (click)="changeField('projectCrops')"
                >
                  {{ "NAVIGATION.CROP" | translate }}
                </a>
                <a
                  class="btn btn-primary mx-2 my-auto"
                  style="background-color: rgb(55, 100, 44)"
                  (click)="changeField('projectLivestock')"
                >
                  {{ "NAVIGATION.LIVESTOCK" | translate }}
                </a>
                <a
                  class="btn btn-primary mx-2 my-auto"
                  style="background-color: rgb(107, 184, 68)"
                  (click)="changeField('projectAquatics')"
                >
                  {{ "NAVIGATION.AQUACULTURE" | translate }}
                </a>
              </div>
            </div>
            <div
              id="map-advanced-legend"
              class="d-flex flex-wrap btn-transparent text-primary mt-4 py-2 mx-auto"
              [ngClass]="isRtl ? 'legend-rtl' : 'legend'"
            >
              <small class="mt-5">{{ legendMin }}</small>
              <div
                *ngFor="let color of colors[activeField]"
                [style.height.px]="30"
                [style.width.px]="30"
                [style.background-color]="color"
              ></div>
              <small class="mt-5">{{ legendMax }}</small>
            </div>
          </div>
          <div class="card shadow-0 bg-transparent">
            <div
              class="text-primary rounded"
              style="border-radius: 0.5rem 0.5rem 0 0; overflow: hidden"
            >
              <mdb-vector-map
                #myMap
                [stroke]="'#37474F'"
                [readonly]="true"
                [hover]="false"
                [btnClass]="'btn-light'"
                [colorMap]="advancedColorMap"
              ></mdb-vector-map>
            </div>
            <!-- <div
                class="bg-primary mx-auto"
                style="height: 2px; width: 95%"
              ></div> -->
          </div>
          <div class="d-flex flex-row justify-content-between pb-5">
            <div style="height: 7rem; width: 18rem">
              <img
                src="/assets/images/par.png"
                style="object-fit: contain"
                class="w-100 h-100"
              />
            </div>
            <div style="height: 7rem; width: 18rem">
              <img
                src="/assets/images/partners/raffaella.png"
                style="object-fit: contain"
                class="w-100 h-100"
              />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="dashboardType === 'PARTNERS'">
        <dashboard-partner></dashboard-partner>
      </div>
    </div>
  </div>
</div>
