import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { LayoutModule } from '../layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkingAreaPageComponent } from './working-area-page/working-area-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';
import { TablePlaceholderComponent } from './components/table-placeholder/table-placeholder.component';
import { ModalsModule } from '../modals/modals.module';
import { MdbModule } from '../mdb/mdb.module';
import { AnsweringPageComponent } from './answering-page/answering-page.component';
import { AnsweringDescriptorLegendComponent } from './components/answering-descriptor-legend/answering-descriptor-legend.component';
import { WaVarietyListPageComponent } from './wa-variety-list-page/wa-variety-list-page.component';

@NgModule({
  declarations: [
    WorkingAreaPageComponent,
    TablePlaceholderComponent,
    AnsweringPageComponent,
    AnsweringDescriptorLegendComponent,
    WaVarietyListPageComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    MdbModalModule,
    ModalsModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MdbModule,
  ],
  exports: [WorkingAreaPageComponent, AnsweringPageComponent],
})
export class WorkingAreaModule {}
