<div class="mx-5 mt-2">
  <div class="w-75 pb-4" style="height: 40rem">
    <div
      class="map w-100 h-100 card"
      leaflet
      [leafletOptions]="options"
      (leafletMapReady)="mapReady($event)"
    >
      <div [leafletLayers]="markers!"></div>
    </div>
  </div>
</div>
