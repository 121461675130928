import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatabasePageComponent } from './database-page/database-page.component';
import { AppRoutingModule } from '../app-routing.module';
import { DatabaseNavigationCardComponent } from './components/database-navigation-card/database-navigation-card.component';
import { DatabasePlaceholderComponent } from './components/database-placeholder/database-placeholder.component';
import { CropComponent } from './crop/crop.component';
import { DatabaseListComponent } from './components/database-list/database-list.component';
import { DatabaseListCardComponent } from './components/database-list-card/database-list-card.component';
import { ApiService } from '../api.service';
import { DatabaseDescriptorsComponent } from './components/database-descriptors/database-descriptors.component';
import { LayoutModule } from '../layout/layout.module';
import { ItemPageComponent } from './item-page/item-page.component';
import { ItemVarietiesComponent } from './components/item-varieties/item-varieties.component';
import { TranslateModule } from '@ngx-translate/core';
import { DatabaseTablePlaceholderComponent } from './components/database-table-placeholder/database-table-placeholder.component';
import { DatabaseManagementComponent } from './components/database-management/database-management.component';
import { DatabaseMarketDescriptorsComponent } from './components/database-market-descriptors/database-market-descriptors.component';
import { DatabaseGoalsComponent } from './components/database-goals/database-goals.component';
import { DatabaseContraintsTreeComponent } from './components/database-contraints-tree/database-contraints-tree.component';
import { DatabaseInterventionComponent } from './components/database-intervention/database-intervention.component';
import { LivestockComponent } from './livestock/livestock.component';
import { AquacultureComponent } from './aquaculture/aquaculture.component';
import { MdbModule } from '../mdb/mdb.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountriesComponent } from './countries/countries.component';
import { ItemDescriptorsComponent } from './components/item-descriptors/item-descriptors.component';
import { ItemManagementComponent } from './components/item-management/item-management.component';
import { ItemMarketDescriptorsComponent } from './components/item-market-descriptors/item-market-descriptors.component';
import { VarietyPageComponent } from './variety-page/variety-page.component';
import { VarietyDescriptorsComponent } from './components/variety-descriptors/variety-descriptors.component';
import { VarietyGmpComponent } from './components/variety-gmp/variety-gmp.component';
import { VarietyProjectsComponent } from './components/variety-projects/variety-projects.component';
import { VarietyGalleryComponent } from './components/variety-gallery/variety-gallery.component';
import { GmpComponent } from './gmp/gmp.component';
import { GmpTypesComponent } from './components/gmp-types/gmp-types.component';
import { GmpSectorsComponent } from './components/gmp-sectors/gmp-sectors.component';
import { GmpDistributionComponent } from './components/gmp-distribution/gmp-distribution.component';
import { GmpQualityGuaranteeComponent } from './components/gmp-quality-guarantee/gmp-quality-guarantee.component';
import { GmpMaterialTypeClassificationComponent } from './components/gmp-material-type-classification/gmp-material-type-classification.component';
import { GmpReproductiveMaterialsComponent } from './components/gmp-reproductive-materials/gmp-reproductive-materials.component';
import { GmpTypesOfPointsOfSellingComponent } from './components/gmp-types-of-points-of-selling/gmp-types-of-points-of-selling.component';
import { CountryPageComponent } from './country-page/country-page.component';
import { CountryAgroEcologicalComponent } from './components/country-agro-ecological/country-agro-ecological.component';
import { CountrySocialEthnicComponent } from './components/country-social-ethnic/country-social-ethnic.component';
import { VarietyBreedDescriptorsComponent } from './components/variety-breed-descriptors/variety-breed-descriptors.component';
import { CountryCropPoliciesComponent } from './components/country-crop-policies/country-crop-policies.component';
import { CountryLivestockPoliciesComponent } from './components/country-livestock-policies/country-livestock-policies.component';
import { CountryAquaculturePoliciesComponent } from './components/country-aquaculture-policies/country-aquaculture-policies.component';
import { PolicyComponent } from './policy/policy.component';
import { NetworkInstitutionComponent } from './network-institution/network-institution.component';
import { PolicyRefComponent } from './components/policy-ref/policy-ref.component';
import { ItemProjectsMapComponent } from './components/item-projects-map/item-projects-map.component';
import { DatabaseCropVarietyTypeComponent } from './components/database-crop-variety-type/database-crop-variety-type.component';
import { DatabaseVarietyTypeClassificationComponent } from './components/database-variety-type-classification/database-variety-type-classification.component';
import { DatabaseBreedTypeClassificationComponent } from './components/database-breed-type-classification/database-breed-type-classification.component';
import { DatabaseFarmedTypeClassificationComponent } from './components/database-farmed-type-classification/database-farmed-type-classification.component';

@NgModule({
  declarations: [
    DatabasePageComponent,
    DatabaseNavigationCardComponent,
    DatabasePlaceholderComponent,
    CropComponent,
    DatabaseListComponent,
    DatabaseListCardComponent,
    DatabaseDescriptorsComponent,
    ItemPageComponent,
    ItemVarietiesComponent,
    DatabaseTablePlaceholderComponent,
    DatabaseManagementComponent,
    DatabaseMarketDescriptorsComponent,
    DatabaseGoalsComponent,
    DatabaseContraintsTreeComponent,
    DatabaseInterventionComponent,
    LivestockComponent,
    AquacultureComponent,
    CountriesComponent,
    ItemDescriptorsComponent,
    ItemManagementComponent,
    ItemMarketDescriptorsComponent,
    VarietyPageComponent,
    VarietyDescriptorsComponent,
    VarietyGmpComponent,
    VarietyProjectsComponent,
    VarietyGalleryComponent,
    GmpComponent,
    GmpTypesComponent,
    GmpSectorsComponent,
    GmpDistributionComponent,
    GmpQualityGuaranteeComponent,
    GmpMaterialTypeClassificationComponent,
    GmpReproductiveMaterialsComponent,
    GmpTypesOfPointsOfSellingComponent,
    CountryPageComponent,
    CountryAgroEcologicalComponent,
    CountrySocialEthnicComponent,
    VarietyBreedDescriptorsComponent,
    CountryCropPoliciesComponent,
    CountryLivestockPoliciesComponent,
    CountryAquaculturePoliciesComponent,
    PolicyComponent,
    NetworkInstitutionComponent,
    PolicyRefComponent,
    ItemProjectsMapComponent,
    DatabaseCropVarietyTypeComponent,
    DatabaseVarietyTypeClassificationComponent,
    DatabaseBreedTypeClassificationComponent,
    DatabaseFarmedTypeClassificationComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    LayoutModule,
    TranslateModule,
    MdbModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    DatabasePageComponent,
    ItemPageComponent,
    DatabaseNavigationCardComponent,
  ],
  providers: [ApiService],
})
export class DatabaseModule {}
