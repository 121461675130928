import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VarietyCleaningComponent } from './variety-cleaning.component';
import { MdbModule } from '../mdb/mdb.module';
import { AppRoutingModule } from '../app-routing.module';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { ModalsModule } from '../modals/modals.module';
import { LayoutModule } from '../layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [VarietyCleaningComponent],
  imports: [
    CommonModule,
    RouterModule,
    AppRoutingModule,
    MdbModalModule,
    ModalsModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MdbModule,
  ],
})
export class VarietyCleaningModule {}
