<div class="modal-header bg-secondary text-white">
  <h5 class="modal-title py-1">
    {{ title }}
  </h5>
  <i
    class="bi bi-x-square-fill h4 my-auto"
    style="cursor: pointer"
    (click)="modalRef.close()"
  ></i>
</div>
<div class="modal-body bg-light p-0">
  <div
    class="row mx-3 gap-3 justify-content-center py-3"
    style="overflow-y: auto; max-height: 45rem"
  >
    <div
      class="col-3 p-0"
      style="height: 40rem; overflow: hidden; overflow-y: auto"
      [class.card]="!inter.empty"
      *ngFor="let inter of interventions; let last = last; let i = index"
    >
      <div class="d-flex flex-column" *ngIf="!inter.empty">
        <div
          class="d-flex align-items-center sticky-top"
          [style]="
            'color: black; min-height: 5rem; background-color:' + colors[i]
          "
        >
          <h5 class="m-3">{{ inter.text }}</h5>
        </div>
        <div class="d-flex flex-column mx-2 py-2">
          <div
            class="d-flex flex-row my-2"
            *ngFor="let item of inter.interventions"
          >
            <i
              class="mx-2"
              [ngClass]="
                item.checked ? 'bi bi-check-square-fill' : 'bi bi-square'
              "
              [ngStyle]="{ color: item.checked ? colors[i] : '' }"
            ></i>
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
