import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'database-variety-type-classification',
  templateUrl: './database-variety-type-classification.component.html',
  styleUrls: ['./database-variety-type-classification.component.scss'],
})
export class DatabaseVarietyTypeClassificationComponent implements OnInit {
  categories?: any[];

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.apiCall();
  }

  apiCall() {
    this.api.getMaterialTypeCategories('CROPS').subscribe((value) => {
      this.categories = value as any;
    });
  }
}
