<div class="modal-header bg-secondary text-white w-100">
  <h5 class="modal-title mx-1 my-auto py-1">
    {{ descriptor?.nameLang }}
  </h5>
  <i
    class="bi bi-x-square-fill h4 my-auto"
    style="cursor: pointer"
    (click)="modalRef.close()"
  ></i>
</div>
<div class="modal-body w-100">
  <div class="d-flex justify-content-center mb-3 text-primary">
    <h4>{{ descriptor?.questionLang }}</h4>
  </div>
  <div
    class="w-100 card shadow-0 bg-secondary text-white mx-2"
    style="height: 200px; overflow-y: auto"
    *ngIf="descriptor?.questions?.length || 0 > 0"
  >
    <ul class="py-2">
      <li *ngFor="let item of descriptor?.questions">
        {{ item.questionLang }}
      </li>
    </ul>
  </div>
</div>
