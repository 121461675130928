import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './components/register/register.component';
import { StoreModule } from '@ngrx/store';
import { AuthService } from './services/auth.service';
import * as fromAuth from './store/auth.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth.effects';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { LayoutModule } from '../layout/layout.module';
import { ForgotComponent } from './components/forgot/forgot.component';
import { MdbModule } from '../mdb/mdb.module';
import { ChangeLangComponent } from './components/change-lang/change-lang.component';

@NgModule({
  declarations: [LoginComponent, RegisterComponent, ForgotComponent, ChangeLangComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('auth', fromAuth.authReducer),
    EffectsModule.forFeature([AuthEffects]),
    LayoutModule,
    MdbModule,
  ],
  exports: [LoginComponent, RegisterComponent],
  providers: [AuthService],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule {}
