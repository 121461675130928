import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { Answer } from 'src/app/models/answer';
import { Crop } from 'src/app/models/crop';
import { Descriptor } from 'src/app/models/descriptor';
import { Project, Specie } from 'src/app/models/project';

export interface VarietyInfo {
  agroZone: string;
  brought: string;
  fgdAge: string;
  fgdGender: string;
  fgdID: string;
  fgdName: string;
  fgdType: string;
  localNames: string;
  siteName: string;
  varietyName: string;
  varietyType: string;
}

export interface AnswerInfo {
  answer: string;
  answerID: string;
  answerType: string;
  boolValue: boolean;
  choiceMultiple: any[];
  choiceValue: any;
  measure: string;
  nValue: number;
  notes: string;
  note: string;
  textValue: string;
  values: any[];
  lastUpdate: Date;
  lastUpdateBy: string;
  age?: string;
  gender?: string;
}

@Component({
  selector: 'grid-system-page',
  templateUrl: './grid-system-page.component.html',
  styleUrls: ['./grid-system-page.component.scss'],
})
export class GridSystemPageComponent implements OnInit, OnDestroy {
  modifyForm?: UntypedFormGroup;
  filterForm?: UntypedFormGroup;

  $lang?: Subscription;
  fileName?: string;
  projectID?: string;

  crops?: Specie[] = [];
  types?: any[];
  cropImg: string = '';

  columnHeaders: any[] = [];
  isHeaderEmpty?: boolean;
  rows: any[] = [];

  varietyInfo?: VarietyInfo;
  answerInfo?: AnswerInfo;

  isGeneralInfosOpened: boolean = false;
  isLegendOpened: boolean = false;
  isModifyOpened: boolean = false;

  selectedRow: number = -1;
  selectedColumn: number = -1;

  loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      crop: new UntypedFormControl(null, []),
      type: new UntypedFormControl(null, []),
    });

    this.modifyForm = new UntypedFormGroup({
      choiceValue: new UntypedFormControl(null, []),
      choiceMultiple: new UntypedFormControl(null, []),
      textValue: new UntypedFormControl(null, []),
      nValue: new UntypedFormControl(null, []),
      boolValue: new UntypedFormControl(null, []),
    });

    this.crop?.valueChanges.subscribe((id: string) => {
      this.api.getCrop(id).subscribe((value: any) => {
        this.cropImg = value.imageUrl;
      });
      this.loading = false;
      this.cleanTable();
      if (this.type?.value) {
        this.loading = true;
        this.getColumnHeaders();
      }
    });

    this.type?.valueChanges.subscribe(() => {
      this.loading = false;
      if (this.crop?.value) {
        this.loading = true;
        this.getColumnHeaders();
      }
    });

    this.projectID = this.route.snapshot.paramMap.get('id') || '';
    this.fileName = this.route.snapshot.paramMap.get('file') || '';

    this.apiCall();

    this.choiceMultiple?.valueChanges.subscribe((value) => {});

    // this.api.getFgdAnswerInfo('616815df0a975a611bd1f72b').subscribe((value) => {
    //   this.answerInfo = value as AnswerInfo;
    //   this.textValue?.setValue(this.answerInfo.textValue);
    // });
  }

  ngOnDestroy(): void {
    this.$lang?.unsubscribe();
  }

  apiCall() {
    this.api.getProject(this.projectID || '').subscribe((value) => {
      const project = value as Project;
      this.crops = project.species;
    });

    this.api.getDescriptors('CROPS').subscribe((value) => {
      this.types = value as Array<any>;
    });
  }

  cleanTable() {
    this.columnHeaders = [];
    this.rows = [];
  }

  getColumnHeaders() {
    this.api
      .getDescriptorsByFamilyAndType(
        this.projectID || '',
        this.crop?.value,
        this.type?.value
      )
      .subscribe((value) => {
        this.columnHeaders = value as any[];
        this.isHeaderEmpty = this.columnHeaders.length == 0;

        if (!this.isHeaderEmpty) this.getRows();
        else this.loading = false;
      });
  }

  getRows() {
    this.api
      .getAnswersByFamilyAndType(
        this.projectID || '',
        this.crop?.value,
        this.type?.value
      )
      .subscribe((value) => {
        this.rows = value as any[];
        this.loading = false;
      });
  }

  backToProject() {
    this.router.navigateByUrl(
      '/u/project/' + this.projectID + '/data-collected-and-analysis'
    );
  }

  getGeneralInfos(projectID: string, refID: string, rowIndex: number) {
    this.selectedColumn = -1;
    this.selectedRow = rowIndex;
    this.cleanTableUtilities();
    this.api.getFgdVarietyInfo(projectID, refID).subscribe((value) => {
      this.varietyInfo = value as VarietyInfo;
      this.isGeneralInfosOpened = true;
    });
  }

  getLegend(columnIndex: number) {
    this.selectedRow = -1;
    this.selectedColumn = columnIndex;
    this.cleanTableUtilities();
    //
    this.isLegendOpened = true;
  }

  getModify(answerID: string, rowIndex: number, answerIndex: number) {
    this.selectedRow = rowIndex;
    this.selectedColumn = answerIndex;
    this.cleanTableUtilities();
    this.api.getFgdAnswerInfo(answerID).subscribe((value) => {
      this.answerInfo = value as AnswerInfo;
      this.setFormValue();
      this.isModifyOpened = true;
    });
  }

  cleanTableUtilities() {
    this.isGeneralInfosOpened = false;
    this.isLegendOpened = false;
    this.isModifyOpened = false;
  }

  getBackgroundCell(rowIndex: number, answerIndex: number): string {
    if (this.selectedRow == rowIndex && this.selectedColumn == answerIndex) {
      return 'cell-selected text-white border-primary';
    }
    if (this.selectedRow == rowIndex && this.selectedColumn != answerIndex) {
      return 'full-row';
    }
    if (this.selectedRow != rowIndex && this.selectedColumn == answerIndex) {
      return 'full-row';
    }
    return 'bg-light';
  }

  setFormValue() {
    Object.keys(this.modifyForm!.controls).forEach((c) =>
      this.modifyForm!.get(c)!.setValue(null)
    );
    switch (this.answerInfo?.answerType) {
      case 'MULTIPLE':
        this.choiceValue?.setValue(this.answerInfo.choiceValue);
        break;
      case 'MULTIPLE_LIST':
        const list: any[] = [];
        this.answerInfo.choiceMultiple.forEach((item) => {
          list.push(item.choiceValue);
        });
        this.choiceMultiple?.setValue(list);
        break;
      case 'NUMBER':
        this.nValue?.setValue(this.answerInfo.nValue);
        break;
      case 'TEXT':
        this.textValue?.setValue(this.answerInfo.textValue);
        break;
      case 'BOOLEAN':
        break;
      default:
        break;
    }
  }

  save() {
    this.api
      .updateFgdAnswer(this.answerInfo!.answerID, this.modifyForm?.value)
      .subscribe((value) => {
        this.rows[this.selectedRow].answers[this.selectedColumn] =
          value as Answer;
      });
  }

  get crop() {
    return this.filterForm?.get('crop');
  }

  get type() {
    return this.filterForm?.get('type');
  }

  get choiceValue() {
    return this.modifyForm?.get('choiceValue');
  }

  get nValue() {
    return this.modifyForm?.get('nValue');
  }

  get choiceMultiple() {
    return this.modifyForm?.get('choiceMultiple');
  }

  get textValue() {
    return this.modifyForm?.get('textValue');
  }

  get boolValue() {
    return this.modifyForm?.get('boolValue');
  }
}
