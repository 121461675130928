<div class="border-danger card" style="border: 3px solid">
  <div class="modal-body">
    <h5>{{ "ME.DELETE_DESCRIPTOR_TITLE" | translate }}</h5>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="modalRef.close(false)"
    >
      {{ "BUTTONS.CLOSE" | translate }}
    </button>
    <button type="button" class="btn btn-danger" (click)="modalRef.close(true)">
      {{ "BUTTONS.DELETE" | translate }}
    </button>
  </div>
</div>
