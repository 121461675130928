<div *ngIf="!crops" class="mt-4">
  <loading [glass]="false"></loading>
</div>
<work-in-progress *ngIf="ref === 'l'"></work-in-progress>
<div class="d-flex flex-wrap mt-3 mb-5 mx-5" *ngIf="ref === 'c'">
  <div
    class="bg-white shadow rounded p-3 me-2 mb-2"
    style="width: 25rem; height: 12rem; position: relative; overflow: hidden"
    *ngFor="let crop of crops"
  >
    <div
      class="w-100 h-100 d-flex position-absolute top-0 flex-row justify-content-end align-items-end p-3"
      style="position: absolute; z-index: 9"
    >
      <img [src]="crop.img" style="height: 9rem; width: 9rem; opacity: 0.3" />
    </div>
    <div class="d-flex flex-column h-100 position-relative" style="z-index: 10">
      <h4>{{ crop.name }}</h4>
      <h6>
        {{ crop.count + " " }} {{ "VARIETY_CLEANING.VARIETIES" | translate }}
      </h6>
      <span>{{ crop.user }}</span>
      <span>{{ crop.date | date : "MMM dd, yyyy HH:mm" }}</span>
      <div
        class="btn btn-primary mt-auto"
        [routerLink]="
          '/cleaning/' + ref + '/' + projectID + '/' + crop.familyID
        "
      >
        {{ "VARIETY_CLEANING.START_CLEANING" | translate }}
      </div>
    </div>
  </div>
</div>
