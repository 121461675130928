import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';
import { Pageable } from 'src/app/models/pageable';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FiltersHelper, FiltersListener } from 'src/app/models/filter-list';
import { ModalLivestockAnswersComponent } from 'src/app/modals/database/modal-livestock-answers/modal-livestock-answers.component';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'variety-breed-descriptors',
  templateUrl: './variety-breed-descriptors.component.html',
  styleUrls: ['./variety-breed-descriptors.component.scss'],
})
export class VarietyBreedDescriptorsComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  filterForm?: UntypedFormGroup;
  refID?: string;
  countryID?: string;
  filterHelper: FiltersHelper = new FiltersHelper();

  loading: boolean = false;

  page: number = 0;
  inputFilter: string = '';
  filterType: string = '';
  filterSubtype: string = '';

  dataSource?: Pageable | null;
  descriptors: any[] = [];
  countries?: Select[];
  types: any[] = [];
  subtypes: any[] = [];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private modalService: MdbModalService,
    private filtersListener: FiltersListener
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      country: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      subtype: new UntypedFormControl(null),
      filter: new UntypedFormControl(null),
    });

    this.getCountries();

    this.filter?.valueChanges.pipe(debounceTime(500)).subscribe((filter) => {
      this.inputFilter = filter;
      this.filterDescriptors();
    });

    this.country?.valueChanges.subscribe((value) => {
      if (value == null) return;
      this.onCountrySelect();
    });

    this.type?.valueChanges.subscribe((value) => {
      this.onTypeSelect();
    });

    this.subtype?.valueChanges.subscribe((value) => {
      this.onSubtypeSelect();
    });

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.getCountries();
      this.types = [];
      this.subtypes = [];
    });
  }

  getCountries() {
    const stringIDs = this.route.parent?.snapshot.paramMap.get('id');
    const IDs = stringIDs?.split('-') || [];
    this.refID = IDs[1];

    this.api
      .getCountriesForVariety('LIVESTOCK', this.refID)
      .subscribe((countries) => {
        this.countries = countries as Select[];
        this.countries.forEach((item) => {
          if (item.selected) {
            this.country?.setValue(item.value);
            this.onCountrySelect();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  filterDescriptors() {
    this.loading = true;

    this.api
      .getBreedDescriptorAnswer(
        this.refID ?? '',
        this.country?.value,
        this.page,
        this.type?.value,
        this.filter?.value,
        this.subtype?.value
      )
      .subscribe(
        (dataSource) => {
          this.dataSource = dataSource as Pageable;
          this.descriptors = this.dataSource.content;
          this.loading = false;
        },
        (error) => {
          this.descriptors = [];
          this.loading = false;
          this.dataSource = null;
        }
      );
  }

  onCountrySelect() {
    this.loading = true;
    if (this.country?.value) {
      this.filterHelper.varietyCountry = this.country.value;
      this.filtersListener.setData(this.filterHelper);
      this.api
        .getBreedDescriptorAnswerTypeFilter(this.refID!, this.country.value)
        .subscribe({
          next: (types) => {
            this.types = types as any[];
          },
          error: (error) => {
            this.types = [];
            this.subtypes = [];
          },
        });
    }
    this.filterDescriptors();
  }

  onTypeSelect() {
    this.loading = true;
    if (this.type?.value) {
      this.api
        .getBreedDescriptorAnswerSubtypeFilter(
          this.refID!,
          this.country?.value,
          this.type?.value
        )
        .subscribe(
          (subtypes) => {
            this.subtypes = subtypes as any[];
          },
          (error) => {
            this.subtypes = [];
          }
        );
    } else {
      this.subtype?.setValue(null);
    }
    this.filterDescriptors();
  }

  openAnswers(descriptor: any) {
    this.modalService.open(ModalLivestockAnswersComponent, {
      modalClass: 'modal-lg',
      data: { descriptor: descriptor },
    });
  }

  onSubtypeSelect() {
    this.loading = true;
    this.filterDescriptors();
  }

  onPageClick(page: number) {
    this.loading = true;
    this.page = page;
    this.filterDescriptors();
  }

  get filter() {
    return this.filterForm?.get('filter');
  }

  get country() {
    return this.filterForm?.get('country');
  }

  get type() {
    return this.filterForm?.get('type');
  }

  get subtype() {
    return this.filterForm?.get('subtype');
  }
}
