import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MdbModalModule,
  MdbModalRef,
  MdbModalService,
} from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ActivityPreview } from 'src/app/projects/components/site-data-collected/site-data-collected.component';
import { ModalSwitchActivityStateComponent } from '../../modal-switch-activity-state/modal-switch-activity-state.component';

@Component({
  selector: 'modal-edit-activity',
  templateUrl: './modal-edit-activity.component.html',
  styleUrls: ['./modal-edit-activity.component.scss'],
})
export class ModalEditActivityComponent implements OnInit {
  editForm?: UntypedFormGroup;
  activity?: ActivityPreview;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalEditActivityComponent>,
    private location: LocationStrategy,
    private modalService: MdbModalService
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.editForm = new UntypedFormGroup({
      lat: new UntypedFormControl(this.activity?.lat),
      lng: new UntypedFormControl(this.activity?.lng),
      alt: new UntypedFormControl(this.activity?.alt),
    });
  }

  save() {
    const body = {
      activityID: this.activity?.activityID,
      type: this.activity?.type,
      lat: this.lat?.value,
      lng: this.lng?.value,
      alt: this.alt?.value,
    };
    this.api.updateActivity(body).subscribe((value) => {
      const newActivity: ActivityPreview = value as ActivityPreview;
      this.modalRef.close(newActivity);
    });
  }

  openSwitchState() {
    const modalRef = this.modalService.open(ModalSwitchActivityStateComponent, {
      data: {
        refID: this.activity?.activityID,
        activityType: this.activity?.type,
        deleted: this.activity?.deleted,
      },
      modalClass: 'modal-lg',
    });

    modalRef.onClose.subscribe((message) => {
      if (message && this.activity) {
        this.activity.deleted = !this.activity?.deleted;
      }
    });
  }

  get lat() {
    return this.editForm?.get('lat');
  }
  get lng() {
    return this.editForm?.get('lng');
  }
  get alt() {
    return this.editForm?.get('alt');
  }
}
