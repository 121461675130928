<div class="mx-4">
  <nav class="navbar navbar-expand-lg navbar-light bg-light shadow-0">
    <div class="container-fluid">
      <nav>
        <ol class="breadcrumb text-primary" style="font-size: 1.1rem">
          <li class="breadcrumb-item b-list">
            <a class="pointer" (click)="backToDatabase()">DATAR DB</a>
          </li>
          <li
            class="breadcrumb-item b-list"
            [class.text-decoration-underline]="variety == null"
          >
            <a class="pointer" (click)="backToItem()" *ngIf="variety">
              {{ getName() }}
            </a>
            <span *ngIf="!variety">
              {{ getName() }}
            </span>
          </li>
          <li
            class="breadcrumb-item b-list active text-decoration-underline"
            *ngIf="variety"
          >
            <span>
              {{ variety?.name }}
            </span>
          </li>
        </ol>
      </nav>
    </div>
  </nav>
</div>
