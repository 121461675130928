<div class="card col mb-3" style="overflow-y: auto; height: 40rem">
  <div class="mb-2 pb-0 sticky-top bg-white">
    <div
      class="d-flex flex-row mt-3 justify-content-between align-items-center w-100"
    >
      <h3 class="mx-3 my-auto">{{ "NAVIGATION.DESCRIPTORS" | translate }}</h3>
    </div>
    <div class="row mx-2 mt-2 pt-1">
      <p class="col-3">{{ "NAVIGATION.CODE" | translate }}</p>
      <p class="col-3">{{ "NAVIGATION.NAME" | translate }}</p>
      <p class="col-3">{{ "NAVIGATION.TYPE" | translate }}</p>
      <p class="col-3">{{ "NAVIGATION.DISTRIBUTING" | translate }}</p>
    </div>
    <div class="mx-2 w-90 bg-primary" style="height: 2px"></div>
  </div>

  <div class="d-flex flex-column mx-2 pb-1 pt-2">
    <disclaimer
      class="mt-4"
      text="VARIETY.EMPTY"
      *ngIf="gmps.length == 0 && !loading"
    ></disclaimer>
    <div
      class="row align-items-center mx-2 rounded py-3 my-1 hover"
      *ngFor="let gmp of gmps; index as i"
      [ngClass]="i % 2 == 0 ? 'bg-light' : 'bg-white'"
    >
      <div class="col-3">
        <h5 class="my-auto">
          {{ gmp.code }}
        </h5>
      </div>
      <div class="col-3">
        <h5 class="my-auto">
          {{ gmp.name }}
        </h5>
      </div>
      <div class="col-3">
        <h5 class="my-auto">
          {{ gmp.supplierType.nameLang }}
        </h5>
      </div>
      <div class="col-3">
        <h5 class="my-auto">
          {{ gmp.supplierDistType.typeLang }}
        </h5>
      </div>
    </div>
  </div>
  <loading class="mask" *ngIf="loading"></loading>
</div>
