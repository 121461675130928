<div class="modal-header">
  <div [ngSwitch]="formState">
    <div *ngSwitchCase="0">
      <h5 class="modal-title">
        {{ "SITE.SELECT_GOAL" | translate }}
      </h5>
    </div>
    <div *ngSwitchCase="1">
      <h5 class="modal-title">
        {{ "SITE.SELECT_SUBGOAL" | translate }}
      </h5>
    </div>
    <div *ngSwitchCase="2">
      <h5 class="modal-title">
        {{ "SITE.SELECT_SPECIES" | translate }}
      </h5>
    </div>
    <div *ngSwitchDefault>
      <h5 class="modal-title">
        {{ "SITE.LOADING" | translate }}
      </h5>
    </div>
  </div>
</div>
<div class="modal-body" [formGroup]="goalForm!">
  <div *ngIf="formState == -1">
    <loading [glass]="false"></loading>
  </div>
  <div class="d-flex flex-column" *ngIf="formState == 0">
    <div class="form-check mb-2" *ngFor="let goal of goals">
      <input
        mdbRadio
        class="form-check-input"
        type="radio"
        name="goalID"
        [id]="goal.goalID"
        formControlName="goalID"
        [value]="goal.goalID"
      />
      <label class="form-check-label" [for]="goal.goalID">
        {{ goal.titolo }}
      </label>
    </div>
  </div>
  <div class="d-flex flex-column" *ngIf="formState == 1">
    <div class="form-check mb-2" *ngFor="let subGoal of subGoals">
      <input
        mdbRadio
        class="form-check-input"
        type="radio"
        name="subgoalID"
        [id]="subGoal.subgoalID"
        formControlName="subgoalID"
        [value]="subGoal.subgoalID"
      />
      <label class="form-check-label" [for]="subGoal.subgoalID">
        {{ subGoal.sottogoal }}
      </label>
    </div>
  </div>
  <div class="d-flex flex-column" *ngIf="formState == 2">
    <div class="form-check mb-2" *ngFor="let specie of species">
      <input
        mdbRadio
        class="form-check-input"
        type="checkbox"
        name="ids"
        [disabled]="!refIDs?.includes(specie.speciesID)"
        [id]="specie.speciesID"
        (click)="addSpecie(specie)"
      />
      <label class="form-check-label" [for]="specie.speciesID">
        {{ specie.species }}
      </label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </button>
  <button
    type="button"
    class="btn btn-success"
    *ngIf="formState == 2"
    (click)="onSave()"
  >
    {{ "BUTTONS.ADD" | translate }}
  </button>
</div>
