<div class="modal-header">{{ name }}</div>
<div class="modal-body d-flex flex-column" [formGroup]="infoForm!">
  <div class="d-flex flex-column mx-5 mt-3">
    <div class="d-flex">
      <h5>{{ "WORKING_AREA.VARIETY_TYPE" | translate }}</h5>
      <p
        class="mx-2 text-danger mb-0"
        *ngIf="material?.invalid && material?.touched"
      >
        {{ "REGISTER.REQUIRED" | translate }}
      </p>
    </div>

    <div class="w-100 mb-5">
      <ng-select
        class="w-100"
        formControlName="material"
        [searchable]="false"
        [clearable]="false"
      >
        <ng-option *ngFor="let m of materials" [value]="m">
          {{ m?.categoryLang }}
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div class="d-flex flex-column mx-5" formArrayName="subcategories">
    <div
      class="d-flex flex-column"
      *ngFor="let sub of subcategories.controls; index as i"
    >
      <div class="d-flex mt-3">
        <h5>{{ sub.get("name")?.value }}</h5>
        <p
          class="mx-2 text-danger mb-0"
          *ngIf="hybrid?.invalid && hybrid?.touched"
        >
          {{ "REGISTER.REQUIRED" | translate }}
        </p>
      </div>
      <div class="w-100" [formGroupName]="i">
        <ng-select
          class="w-100"
          [clearable]="false"
          [searchable]="false"
          formControlName="value"
        >
          <ng-option
            *ngFor="let descriptor of category.subCategories[i].descriptors"
            [value]="descriptor.materialTypeDescriptorID"
          >
            {{ descriptor.descriptorLang }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column mx-5" *ngIf="showVariety">
    <div class="d-flex mt-3">
      <h5>{{ "WORKING_AREA.SELECT_VARIETIES" | translate }}</h5>
      <p
        class="mx-2 text-danger mb-0"
        *ngIf="varieties?.invalid && varieties?.touched"
      >
        {{ "REGISTER.REQUIRED" | translate }}
      </p>
    </div>
    <div class="w-100">
      <ng-select
        class="w-100"
        [multiple]="true"
        formControlName="varieties"
        [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
        [disabled]="showVariety"
      >
        <ng-option *ngFor="let variety of varietyList" [value]="variety.value">
          {{ variety.label }}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="d-flex flex-column mx-5" *ngIf="showVariety && showLocation">
    <h5 class="mx-auto my-4">
      {{ "WORKING_AREA.POPULATION_FORMED" | translate }}
    </h5>
    <div class="row gap-2">
      <div class="d-flex flex-column col">
        <div class="d-flex">
          <h5>{{ "WORKING_AREA.PROVINCE" | translate }}</h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="state?.invalid && state?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div>
          <input
            mdbValidate
            type="text"
            class="form-control"
            formControlName="state"
            [disabled]="showLocation"
          />
        </div>
      </div>
      <div class="d-flex flex-column col">
        <div class="d-flex">
          <h5>{{ "WORKING_AREA.CITY" | translate }}</h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="city?.invalid && city?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div>
          <input
            mdbValidate
            type="text"
            class="form-control"
            formControlName="city"
            [disabled]="showLocation"
          />
        </div>
      </div>
    </div>
    <div class="row mt-3 gap-2">
      <div class="d-flex flex-column col">
        <h5>{{ "WORKING_AREA.LATITUDE" | translate }}</h5>
        <div>
          <input
            mdbValidate
            type="text"
            class="form-control"
            formControlName="lat"
            [disabled]="showLocation"
          />
        </div>
      </div>
      <div class="d-flex flex-column col">
        <h5>{{ "WORKING_AREA.LONGITUDE" | translate }}</h5>
        <div>
          <input
            mdbValidate
            type="text"
            class="form-control"
            formControlName="lng"
            [disabled]="showLocation"
          />
        </div>
      </div>
      <div class="d-flex flex-column col">
        <h5>{{ "WORKING_AREA.ALTITUDE" | translate }}</h5>
        <div>
          <input
            mdbValidate
            type="text"
            class="form-control"
            formControlName="alt"
            [disabled]="showLocation"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" (click)="modalRef.close()">
      {{ "BUTTONS.CLOSE" | translate }}
    </div>
    <div class="btn btn-success" (click)="editType()">
      {{ "BUTTONS.EDIT" | translate }}
    </div>
  </div>
</div>
