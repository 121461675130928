<div class="modal-body">
  <canvas
    mdbChart
    [type]="'bar'"
    [datasets]="datasets"
    [labels]="labels"
    *ngIf="points && labels"
  ></canvas>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
</div>
