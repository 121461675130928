import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'modal-period-data-analysis',
  templateUrl: './modal-period-data-analysis.component.html',
  styleUrls: ['./modal-period-data-analysis.component.scss'],
})
export class ModalPeriodDataAnalysisComponent implements OnInit {
  title?: string;
  subtitle?: string;
  varieties?: Select[];

  constructor(
    public modalRef: MdbModalRef<ModalPeriodDataAnalysisComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}
}
