import { Component, Input, OnInit } from '@angular/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalPeriodDataAnalysisComponent } from 'src/app/modals/modal-period-data-analysis/modal-period-data-analysis.component';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'graph-number',
  templateUrl: './graph-number.component.html',
  styleUrls: ['./graph-number.component.scss'],
})
export class GraphNumberComponent implements OnInit {
  @Input() periodID?: string;
  @Input() familyID?: string;
  @Input() siteID?: string;

  @Input() title?: string;
  @Input() path?: string;

  @Input() hoverText?: string;

  number?: string;
  varieties?: Select[];

  placeholderNumber?: number;

  constructor(private api: ApiService, private modalService: MdbModalService) {}

  ngOnInit(): void {
    this.placeholderNumber = this.randomNumber();
    this.randomize();
    this.apiCall();
  }

  apiCall() {
    this.api
      .getStatsForPeriod(
        this.path!,
        this.periodID || '',
        this.familyID || '',
        this.siteID
      )
      .subscribe((value) => {
        const v = value as any;
        this.number = v.valueString as string;
        this.varieties = v.varieties as Select[];
      });
  }

  randomNumber(): number {
    return Math.floor(Math.random() * 100);
  }

  randomize() {
    if (this.number == undefined) {
      this.placeholderNumber = this.randomNumber();
      setTimeout(() => this.randomize(), 100);
    }
  }

  openModal() {
    if (!this.varieties) return;
    this.modalService.open(ModalPeriodDataAnalysisComponent, {
      data: { varieties: this.varieties, title: this.title },
    });
  }
}
