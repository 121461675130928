import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Specie } from 'src/app/models/project';
import { Select } from 'src/app/models/select';
import { Variety } from 'src/app/models/variety';
import { VarietyCleaning } from 'src/app/models/variety-cleaning.types';

@Component({
  selector: 'modal-edit-project-variety-type',
  templateUrl: './modal-edit-project-variety-type.component.html',
  styleUrls: ['./modal-edit-project-variety-type.component.scss'],
})
export class ModalEditProjectVarietyTypeComponent implements OnInit {
  infoForm?: FormGroup;

  entity?: any;
  cropID?: string;
  ref?: string;

  projectID?: string;
  refID?: string;
  name?: string;

  types?: Select[];

  varietyList?: any[];
  specieList?: any[];
  materials?: any[];

  loading: boolean = false;

  showSpecies: boolean = false;
  showVariety: boolean = false;
  showLocation: boolean = false;

  category?: any;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalEditProjectVarietyTypeComponent>,
    private location: LocationStrategy,
    private formBuilder: FormBuilder
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.infoForm = new FormGroup({
      material: new FormControl(null),
      varietyType: new FormControl(
        this.entity?.projectType.materialTypeCategoryID
      ),
      hybrid: new FormControl(this.entity?.speciesHybridID),
      varieties: new FormControl(this.entity?.projectType.mixtureWith),
      state: new FormControl(this.entity?.projectType.evoPopProvince),
      city: new FormControl(this.entity?.projectType.evoPopCity),
      lat: new FormControl(this.entity?.projectType.evoPopLat),
      lng: new FormControl(this.entity?.projectType.evoPopLng),
      alt: new FormControl(this.entity?.projectType.evoPopAlt),
      subcategories: this.formBuilder.array([]),
    });

    this.getMaterialCategories();
    this.apiCall();

    this.material?.valueChanges.subscribe((value: any) => {
      if (!value) return;
      this.showLocation = false;
      this.showSpecies = false;
      this.showVariety = false;
      this.subcategories.clear();
      this.category = value;
      this.varietyType?.setValue(value.materialTypeCategoryID);
      value.subCategories.forEach((element: any, index: number) => {
        const group = this.formBuilder.group({
          name: new FormControl(element.categoryLang),
          value: new FormControl(null),
        });

        group.get('value')?.valueChanges.subscribe((value: any) => {
          const desc = element.descriptors.filter(
            (e: any) => e.materialTypeDescriptorID == value
          )[0];

          if (!desc) return;

          this.showLocation = desc.needLocation;
          this.showSpecies = desc.needSpeciesList;
          this.showVariety = desc.needVarietyList;
        });

        if (this.entity?.projectType.materialTypeDescriptorIDs) {
          const desc = element.descriptors.filter(
            (e: any) =>
              e.materialTypeDescriptorID ==
              this.entity?.projectType.materialTypeDescriptorIDs[index]
          )[0];
          if (desc) {
            group.get('value')?.setValue(desc.materialTypeDescriptorID);
          }
        }
        this.subcategories.push(group);
      });
    });
  }

  apiCall() {
    if (this.cropID) {
      this.api.getSpeciesSelect(this.cropID).subscribe((value) => {
        this.specieList = value as Specie[];
      });
      this.api.getVarietySelect(this.cropID).subscribe((value) => {
        this.varietyList = value as Variety[];
      });
    }
  }

  setMaterial() {
    const materialCategoryID = this.varietyType?.value;
    const mat = this.materials?.filter(
      (e) => e.materialCategoryID === materialCategoryID
    );
    if (mat) {
      this.material?.setValue(mat[0]);
    }
  }

  getMaterialCategories() {
    this.api.getMaterialTypeCategories(this.ref ?? '').subscribe((value) => {
      this.materials = value as any[];
      if (this.entity) {
        const filter = this.materials?.filter((e) => {
          return e.materialTypeCategoryID == this.varietyType?.value;
        });
        if (filter) {
          this.material?.setValue(filter[0]);
        }
      }
    });
  }

  // getTypes() {
  //   this.api
  //     .getVarietyTypes(this.refID!, this.projectID!)
  //     .subscribe((value) => {
  //       this.types = value as Select[];
  //       this.types.forEach((e) => {
  //         if (e.selected) this.typeID?.setValue(e.value);
  //       });
  //     });
  // }

  editType() {
    const materialDescriptorsIDs = this.subcategories.controls.map(
      (e) => e.value.value
    );
    const body = {
      refID: this.entity?.projectVarietyID,
      projectID: this.projectID,
      materialCategoryID: this.varietyType?.value,
      materialDescriptorIDs: materialDescriptorsIDs,
      hybridWith: this.hybrid?.value,
      hybrid: this.hybrid?.value != null,
      mixtureWith: this.varieties?.value,
      evoPopProvince: this.state?.value,
      evoPopCity: this.city?.value,
      evoPopLat: this.lat?.value,
      evoPopLng: this.lng?.value,
      evoPopAlt: this.alt?.value,
    };
    this.api.setProjectVarietyType(body).subscribe((value) => {
      this.modalRef.close(value as VarietyCleaning);
    });
  }

  get hybrid() {
    return this.infoForm?.get('hybrid');
  }

  get subcategories() {
    return this.infoForm?.get('subcategories') as FormArray;
  }

  get material() {
    return this.infoForm?.get('material');
  }
  get varietyType() {
    return this.infoForm?.get('varietyType');
  }

  get varieties() {
    return this.infoForm?.get('varieties');
  }

  get state() {
    return this.infoForm?.get('state');
  }
  get city() {
    return this.infoForm?.get('city');
  }
  get lat() {
    return this.infoForm?.get('lat');
  }
  get lng() {
    return this.infoForm?.get('lng');
  }
  get alt() {
    return this.infoForm?.get('alt');
  }
}
