import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnInit {
  @Input() name?: string;
  @Input() type?: string;
  @Input() href?: string;
  @Input() refTo?: string;

  constructor() {}

  ngOnInit(): void {}

  getIcon() {
    switch (this.type) {
      case 'word':
        return 'bi bi-file-earmark-word-fill text-danger';
      case 'GUIDELINE':
        return 'bi bi-file-earmark-pdf-fill text-danger';
      case 'APP':
        return 'bi bi-file-earmark-pdf-fill text-danger';
      case 'excel':
        return 'bi bi-file-earmark-spreadsheet-fill';
      case 'VIDEO':
        return 'bi bi-file-earmark-play-fill file-video';
      case 'APP_VIDEO':
        return 'bi bi-file-earmark-play-fill file-video';
      default:
        return 'bi bi-file-earmark-word-fill';
    }
  }

  getType() {
    switch (this.type) {
      case 'word':
        return 'word';
      case 'GUIDELINE':
        return 'PDF';
      case 'APP':
        return 'PDF';
      case 'excel':
        return 'Excel';
      case 'VIDEO':
        return 'Video';
      case 'APP_VIDEO':
        return 'Video';
      default:
        return '';
    }
  }
}
