<div class="modal-header">
  <span class="h5">
    {{ item.subgoal }}
  </span>
</div>
<div class="modal-body">
  <div class="d-flex flex-column mt-2 px-4" *ngIf="!loading">
    <div
      style="
        max-width: 100%;
        position: relative;
        overflow: scroll;
        min-height: 85vh;
        max-height: 85vh;
      "
    >
      <div *ngIf="rows.length == 0">
        <disclaimer [text]="'ADD A ROW TO START'"></disclaimer>
      </div>
      <table *ngIf="columnHeaders.length != 0 && rows.length != 0 && !loading">
        <thead>
          <tr *ngIf="columnHeaders">
            <th
              class="bg-light border-primary rounded-5 shadow-2"
              style="z-index: 20"
            ></th>
            <!-- DESCRIPTOR CLICKED -->
            <th
              class="pointer px-2 py-0 rounded-5 shadow-2 bg-white text-primary"
              style="max-height: 4rem; min-height: 4rem"
              *ngFor="let column of columnHeaders; index as columnIndex"
            >
              <div
                class="py-2 text-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
              >
                {{ column | translate }}
                {{ column.includes("PERIOD") ? columnIndex : "" }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="rows">
          <tr *ngFor="let row of rows; index as rowIndex">
            <th
              class="rounded-5 shadow-2"
              [ngClass]="
                selectedRow == rowIndex
                  ? 'cell-selected text-white'
                  : 'bg-white text-primary'
              "
              style="z-index: 10"
            >
              <div style="max-height: 64px; min-height: 64px; overflow-y: auto">
                <span class="my-auto">{{ row.name }}</span>
              </div>
            </th>
            <!-- CELL CLICKED -->
            <td
              class="text-center m-1 rounded-5 shadow-2"
              *ngFor="let answer of row.answers; index as answerIndex"
              (click)="cellClicked(answer, answerIndex)"
              [ngClass]="getBackgroundCell(rowIndex, answerIndex)"
              [class.pointer]="answer.clickable"
            >
              <div
                class="d-flex align-items-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
                *ngIf="answer.name && !answer.name.includes('~')"
              >
                <span
                  class="my-auto w-100 text-center"
                  *ngIf="answer.rowID != 'GRAPH'"
                >
                  <i
                    *ngIf="answer.hasNote"
                    class="me-2"
                    [ngClass]="
                      answer.cleaned
                        ? 'bi-arrow-up-square-fill'
                        : 'bi-arrow-down-square-fill text-danger'
                    "
                  ></i>
                  {{ answer.name }}
                </span>
                <span
                  class="my-auto w-100 text-center"
                  *ngIf="answer.rowID == 'GRAPH'"
                >
                  {{ "ME.VIEW_GRAPH" | translate }}
                  <i class="bi-pie-chart-fill ms-2"></i>
                </span>
              </div>
              <div
                class="d-flex align-items-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
                *ngIf="answer.name && answer.name.includes('~')"
              >
                <span
                  class="my-auto w-100 text-center"
                  *ngIf="answer.rowID != 'GRAPH'"
                >
                  <div class="h-100 w-100 d-flex flex-column">
                    <span
                      *ngFor="let item of answer.name.split('~')"
                      style="font-size: 12px"
                    >
                      {{ item }}
                    </span>
                  </div>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer d-flex justify-content-between">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.BACK" | translate }}
  </div>
  <div class="btn btn-success" (click)="addRow()">
    {{ "ME.ADD_ROW" | translate }}
  </div>
</div>
