import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { FileCreated } from 'src/app/models/file-created';

export interface LoadingState {
  creating: boolean;
  exists: boolean;
  owner: boolean;
  requestFile: FileCreated;
}

@Component({
  selector: 'modal-period-file-requested',
  templateUrl: './modal-period-file-requested.component.html',
  styleUrls: ['./modal-period-file-requested.component.scss'],
})
export class ModalPeriodFileRequestedComponent implements OnInit {
  loadingState?: LoadingState | null;

  fileExt?: string;
  projectFileTypeID?: string;
  refID?: string;
  speciesID?: string;
  cleaned?: boolean;
  showNote?: boolean;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalPeriodFileRequestedComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.api
        .requestPeriodFile(
          this.refID || '',
          this.projectFileTypeID || '',
          this.fileExt || '',
          this.speciesID || null,
          this.cleaned || false,
          this.showNote || false
        )
        .subscribe((value) => {
          this.loadingState = value as LoadingState;
        });
    }, 1000);
  }

  requestAgain() {
    this.loadingState = null;
    setTimeout(() => {
      this.api
        .requestPeriodFile(
          this.refID || '',
          this.projectFileTypeID || '',
          this.fileExt || '',
          this.speciesID || null,
          this.cleaned || false,
          this.showNote || false,
          true
        )
        .subscribe((value) => {
          this.loadingState = value as LoadingState;
        });
    }, 1000);
  }
}
