<div
  class="card border-3 position-relative rounded-6 animate-200"
  style="
    height: 4.5rem;
    width: 10rem;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    transform: scale(0.9);
  "
>
  <div class="w-100 h-100">
    <img class="w-100 h-100" style="object-fit: cover" src="{{ image }}" />
  </div>
  <div
    class="mask d-flex justify-content-center align-items-center rounded-6 glass text-center"
  >
    <h4 class="text-shadow h4 text-white my-auto">
      {{ title }}
    </h4>
  </div>
</div>
