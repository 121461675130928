import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'database-page',
  templateUrl: './database-page.component.html',
  styleUrls: ['./database-page.component.scss'],
})
export class DatabasePageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
