import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Site } from 'src/app/models/project';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'period-diversity-table',
  templateUrl: './period-diversity-table.component.html',
  styleUrls: ['./period-diversity-table.component.scss'],
})
export class PeriodDiversityTableComponent implements OnInit {
  filterForm?: FormGroup;
  periodID?: string;
  ref?: string;
  sites?: Select[];
  rows: any[] = [];
  columnHeaders: any[] = [];
  answerInfo: any;

  selectedRow: number = -1;
  selectedRows: number[] = [];
  selectedColumn: number = -1;
  selectedColumns: number[] = [];
  selectedAnswer: any;

  loading: boolean = false;
  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.periodID = this.route.parent?.snapshot.paramMap.get('id') as string;
    this.ref = this.ref = this.route.parent?.snapshot.paramMap.get('ref') ?? '';

    this.getSites();
    this.filterForm = new FormGroup({
      siteID: new FormControl(null),
    });
    this.getTableStructure();

    this.siteID?.valueChanges.subscribe((value) => {
      console.log(value);
      this.getTableStructure();
    });
  }

  getSites() {
    this.api.getSitesByPeriod(this.periodID ?? '').subscribe((value) => {
      this.sites = value as Select[];
    });
  }

  getTableStructure() {
    this.loading = true;
    this.columnHeaders = columnHeaders;
    this.api
      .getDiversityTable(this.periodID ?? '', this.siteID?.value)
      .subscribe((value) => {
        this.rows = value as any;
        this.loading = false;
      });

    // this.cleanTable();
    // this.isDetailOpened = false;
    // this.loading = true;

    // this.api
    //   .getDescriptorsByProject(this.selectedType, this.familyID ?? '')
    //   .subscribe((value) => {
    //     this.columnHeaders = value as any;
    //     this.isHeaderEmpty = this.columnHeaders.length == 0;
    //     if (!this.isHeaderEmpty) {
    //       this.api
    //         .getAnswerDescriptorByProject(
    //           this.projectID ?? '',
    //           this.selectedType ?? '',
    //           this.varietiesSelectedIDs ?? [],
    //           this.familyID ?? ''
    //         )
    //         .subscribe((value) => {
    //           this.rows = value as any;
    //           this.loading = false;
    //         });
    //     } else {
    //       this.loading = false;
    //     }
    //   });
  }

  getColumnHeaderLivestock(): any[] {
    let newColumns: any[] = [];
    this.columnHeaders.forEach((item: any, i: number) => {
      if (i > 0) {
        if (item.refID == newColumns[newColumns.length - 1].refID) {
          newColumns[newColumns.length - 1].span += 1;
        } else {
          item.span = 1;
          newColumns.push(item);
        }
      } else {
        item.span = 1;
        newColumns.push(item);
      }
    });
    return newColumns;
  }

  selectColumn(columnIndex: number) {
    this.selectedRow = -1;
    this.selectedColumn = columnIndex;
    this.selectedColumns = [];
    let max = this.getColumnHeaderLivestock()[columnIndex].span;
    let min = 0;
    if (columnIndex > 0) {
      this.getColumnHeaderLivestock().forEach((item, i) => {
        if (i < columnIndex) min += item.span;
      });
    }
    for (let i = 0; i < max; i++) {
      this.selectedColumns.push(min + i);
    }

    this.cleanTableUtilities();
  }

  cleanTableUtilities() {
    // this.isGeneralInfosOpened = false;
    // this.isLegendOpened = false;
    // this.isDetailOpened = false;
  }

  getBackgroundCell(rowIndex: number, answerIndex: number): string {
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'cell-selected text-white border-primary';
    }
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.indexOf(answerIndex) === -1
    ) {
      return 'full-row';
    }
    if (
      this.selectedRow != rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'full-row';
    }
    return 'bg-white';
  }

  get siteID() {
    return this.filterForm?.get('siteID');
  }
}

const columnHeaders = [
  'COL_1',
  'COL_2',
  'COL_3',
  'COL_4',
  'COL_5',
  'COL_6',
  'COL_7',
  'COL_8',
  'COL_9',
  'COL_10',
  'COL_11',
  'COL_12',
];
