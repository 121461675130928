<div class="modal-header">
  <h5>{{ subGoal?.sottogoal }}</h5>
</div>
<div class="modal-body pb-0">
  <div class="d-flex flex-row">
    <h6
      class="btn btn-primary mx-2"
      [ngClass]="activeTab === 'descriptors' ? 'btn-primary' : 'btn-secondary'"
      (click)="changeTab('descriptors')"
    >
      {{ "NAVIGATION.DESCRIPTORS" | translate }}
    </h6>
    <h6
      class="mx-2 btn btn-primary"
      [ngClass]="activeTab === 'management' ? 'btn-primary' : 'btn-secondary'"
      (click)="changeTab('management')"
    >
      {{ "NAVIGATION.MANAGEMENT" | translate }}
    </h6>
    <h6
      class="mx-2 btn btn-primary"
      [ngClass]="activeTab === 'market' ? 'btn-primary' : 'btn-secondary'"
      (click)="changeTab('market')"
    >
      {{ "NAVIGATION.MARKET_DESCRIPTORS" | translate }}
    </h6>
  </div>
  <div class="bg-dark" style="height: 2px; width: 96%"></div>
</div>
<div
  class="d-flecx flex-column px-3 py-2"
  style="overflow-y: auto; height: 20rem"
  *ngIf="activeTab === 'descriptors'"
>
  <ul>
    <li class="my-1" *ngFor="let item of descriptors">{{ item.label }}</li>
  </ul>
</div>

<div
  class="d-flecx flex-column px-3 py-2"
  style="overflow-y: auto; height: 20rem"
  *ngIf="activeTab === 'management'"
>
  <ul>
    <li class="my-1" *ngFor="let item of management">{{ item.label }}</li>
  </ul>
</div>

<div
  class="d-flecx flex-column px-3 py-2"
  style="overflow-y: auto; height: 20rem"
  *ngIf="activeTab === 'market'"
>
  <ul>
    <li class="my-1" *ngFor="let item of market">{{ item.label }}</li>
  </ul>
</div>
