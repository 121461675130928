import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Goal } from 'src/app/models/goal';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'period-goals',
  templateUrl: './period-goals.component.html',
  styleUrls: ['./period-goals.component.scss'],
})
export class PeriodGoalsComponent implements OnInit {
  filterForm?: UntypedFormGroup;

  goals?: Goal[];
  subgoals?: any[];
  loadingSubGoals: boolean = false;

  pathRef?: string;
  periodID?: string;

  refIDs?: Select[];
  sites?: Select[];

  tabSelected: number = 0;

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.pathRef = this.route.parent?.snapshot.paramMap.get('ref') as string;
    this.periodID = this.route.parent?.snapshot.paramMap.get('id') as string;

    this.filterForm = new UntypedFormGroup({
      familyID: new UntypedFormControl(null),
      site: new UntypedFormControl(null),
    });

    this.api.getGoals(this.getRef()).subscribe((value) => {
      this.goals = value as Goal[];
      this.getGoalsSelected(this.goals[0].goalID);
    });

    this.api.getFamiliesByPeriod(this.periodID).subscribe((value) => {
      this.refIDs = value as Select[];
    });

    this.api.getSitesByPeriod(this.periodID).subscribe((value) => {
      this.sites = value as Select[];
    });

    this.filterForm.valueChanges.subscribe((_) => {
      this.api
        .getGoalSelected(
          this.periodID!,
          this.goals![this.tabSelected].goalID,
          this.site?.value,
          this.familyID?.value
        )
        .subscribe(
          (value) => {
            this.subgoals = value as any[];
            this.loadingSubGoals = false;
          },
          (error) => {
            this.loadingSubGoals = false;
          }
        );
    });
  }

  getGoalsSelected(goalID: string) {
    this.api
      .getGoalSelected(
        this.periodID || '',
        goalID,
        this.site?.value,
        this.familyID?.value
      )
      .subscribe(
        (value) => {
          this.subgoals = value as any[];
          this.loadingSubGoals = false;
        },
        (error) => {
          this.loadingSubGoals = false;
        }
      );
  }

  openTab(newTab: number, goalID: string) {
    if (this.tabSelected == newTab) return;
    this.loadingSubGoals = true;
    this.tabSelected = newTab;
    this.getGoalsSelected(goalID);
  }

  getRef(): string {
    switch (this.pathRef) {
      case 'c':
        return 'CROPS';
      case 'l':
        return 'LIVESTOCK';
      case 'a':
        return 'AQUATICS';
      default:
        return '';
    }
  }

  get familyID() {
    return this.filterForm?.get('familyID');
  }

  get site() {
    return this.filterForm?.get('site');
  }
}
