<div class="sticky-top bg-white" style="width: 17.5rem; height: 100vh">
  <div
    class="w-100 h-100 d-flex flex-column justify-content-end"
    style="overflow: hidden"
    *ngIf="user?.currentRole?.role === 'PARTNERS'"
    [ngSwitch]="ref?.value"
  >
    <img
      *ngSwitchCase="'CROPS'"
      class="w-100 fade-in-image"
      src="assets/images/crop.svg"
      style="opacity: 0.2; transform: translateX(-4rem)"
    />
    <img
      *ngSwitchCase="'LIVESTOCK'"
      class="w-100 fade-in-image"
      src="assets/images/livestock.svg"
      style="transform: translateX(-4rem)"
    />
  </div>
  <div class="d-flex flex-column justify-content-between h-100 px-5 py-5 mask">
    <div class="d-flex flex-row align-items-center justify-content-between">
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row align-items-center">
          <div
            class="bg-primary mb-2 mx-1 d-flex justify-content-center align-items-center"
            style="
              height: 3.7rem;
              width: 3.7rem;
              border-radius: 50%;
              border: 2.2px solid rgb(79, 79, 79);
              cursor: pointer;
              overflow: hidden;
            "
            (click)="openChangeCountry()"
          >
            <span class="fi fis w-100 h-100" [ngClass]="flagClass"></span>
          </div>
          <div class="d-flex flex-column mx-1">
            <h5 class="p-0 m-0">{{ user?.name }}</h5>
            <h5 class="p-0 m-0">{{ user?.surname }}</h5>
          </div>
        </div>
        <div
          class="btn btn-secondary mt-2"
          (click)="openChangeRole()"
          [ngClass]="user?.userRoles?.length == 1 ? 'disabled' : ''"
        >
          {{ "ROLES." + user?.currentRole?.role | translate }}
        </div>
        <div *ngIf="user?.currentRole?.role === 'PARTNERS'" class="my-2">
          <div [formGroup]="refForm!">
            <ng-select
              style="width: 100%"
              class="control mt-2"
              formControlName="ref"
              [searchable]="false"
              [clearable]="false"
            >
              <ng-option *ngFor="let type of partnerTypes" [value]="type.ref">
                {{ "NAVIGATION." + type.ref | translate }}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="btn btn-primary mt-2" (click)="openSettings()">
          <i class="bi bi-gear-fill secondary-text my-auto mx-1"></i>
          <span class="mx-1">{{ "SETTINGS.SETTINGS" | translate }}</span>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column text-secondary" style="overflow-y: auto">
      <!-- MENU LIST STAT -->
      <div
        routerLink="dashboard"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="d-flex align-items-center py-2 hover"
        style="cursor: pointer"
        (click)="onClick()"
      >
        <i class="bi bi-kanban-fill h5"></i>
        <h4 class="px-4 h5">{{ "NAVIGATION.DASHBOARD" | translate }}</h4>
      </div>

      <div
        routerLink="projects"
        routerLinkActive="active"
        class="d-flex align-items-center py-2 hover"
        style="cursor: pointer"
        (click)="onClick()"
        *ngIf="user?.currentRole?.role === 'USER'"
      >
        <i class="bi bi-folder-fill h5"></i>
        <h4 class="px-4 h5">{{ "NAVIGATION.PROJECTS" | translate }}</h4>
      </div>

      <div
        routerLink="working-area"
        routerLinkActive="active"
        class="d-flex align-items-center py-2 hover"
        style="cursor: pointer"
        (click)="onClick()"
        *ngIf="user?.currentRole?.role === 'PARTNERS'"
      >
        <i class="bi bi-tools h5"></i>
        <h4 class="px-4 h5">{{ "WORKING_AREA.WORKING_AREA" | translate }}</h4>
      </div>

      <div
        routerLink="database"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="d-flex align-items-center py-2 hover"
        style="cursor: pointer"
        (click)="onClick()"
      >
        <i class="bi bi-archive-fill h5"></i>
        <h4 class="px-4 h5">{{ "NAVIGATION.DATABASE" | translate }}</h4>
      </div>

      <div
        routerLink="file-analysis"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="d-flex align-items-center py-2 hover"
        style="cursor: pointer"
        (click)="onClick()"
      >
        <i class="bi-bar-chart-fill h5"></i>
        <h4 class="px-4 h5">{{ "NAVIGATION.FILE_ANALYSIS" | translate }}</h4>
      </div>

      <div
        routerLink="instructions"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="d-flex align-items-center py-2 hover"
        style="cursor: pointer"
        (click)="onClick()"
      >
        <i class="bi bi-mortarboard-fill h5"></i>
        <h4 class="px-4 h5">{{ "NAVIGATION.INSTRUCTIONS" | translate }}</h4>
      </div>

      <div
        routerLink="contact-us"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        class="d-flex align-items-center py-2 hover"
        style="cursor: pointer"
        (click)="onClick()"
      >
        <i class="bi bi-envelope-fill h5"></i>
        <h4 class="px-4 h5">{{ "NAVIGATION.CONTACT_US" | translate }}</h4>
      </div>

      <!-- MENU LIST END -->
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex flex-row text-primary">
        <div class="mx-1" style="height: 4.5rem; width: 4.5rem">
          <img
            class="img-fluid"
            style="object-fit: contain"
            src="/assets/images/logo.png"
          />
        </div>
        <h2 class="my-auto mx-1">DATAR</h2>
      </div>
      <div
        class="btn btn-primary d-flex flex-row justify-content-center p-1 mt-2 py-2"
        (click)="logOut()"
      >
        <i class="bi bi-door-open-fill mx-1 my-auto"></i>
        <p class="my-auto mx-1">{{ "BUTTONS.LOGOUT" | translate }}</p>
      </div>
    </div>
  </div>

  <!-- <settings
    class="mask"
    *ngIf="showSettings"
    (onClose)="onSettingsClosed($event)"
    (onChangeCountry)="openChangeCountry()"
  ></settings>

  <change-country
    class="mask"
    *ngIf="showChangeCountry"
    (onClose)="onChangeCoutryClosed($event)"
  ></change-country> -->
</div>
