<div class="container-fluid p-0 m-0 bg-light" style="height: 100vh">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-3">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <!-- TITLE -->
        <div class="d-flex align-items-center mt-2">
          <i
            class="bi bi-arrow-left-square-fill h1 pointer my-auto"
            (click)="backToProject()"
          ></i>
          <div class="mx-3" style="height: 5rem; width: 5rem">
            <img
              src="/assets/images/xlsx.png"
              class="w-100 h-100"
              style="object-fit: contain"
            />
          </div>
          <h1 class="fw-bold my-auto">{{ fileName }}</h1>
        </div>
        <!-- TITLE -->
        <!-- FILTERS -->
        <div class="d-flex align-items-center" [formGroup]="filterForm!">
          <div
            class="bg-secondary rounded-circle mx-3 border-primary d-flex align-items-center justify-content-center"
            style="height: 5.5rem; width: 5.5rem; border: solid 2px"
          >
            <i
              *ngIf="cropImg === ''"
              class="bi bi-question-lg h1 text-white my-auto"
            ></i>
            <img
              *ngIf="cropImg !== ''"
              [src]="cropImg"
              class="w-100 h-100"
              style="object-fit: cover"
            />
          </div>
          <div class="d-flex">
            <div class="mx-1">
              <ng-select
                placeholder="Crop"
                formControlName="crop"
                [searchable]="false"
                [clearable]="false"
              >
                <ng-option *ngFor="let crop of crops" [value]="crop.speciesID">
                  {{ crop.species }}
                </ng-option>
              </ng-select>
            </div>
            <div class="mx-1">
              <ng-select
                placeholder="Type"
                formControlName="type"
                [searchable]="false"
                [clearable]="false"
              >
                <ng-option
                  *ngFor="let type of types"
                  [value]="type.traitsTypeID"
                  [mdbTooltip]="type.traitsType"
                >
                  {{ type.traitsType }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <!-- FILTERS -->
      </div>
    </div>
  </div>
  <div class="d-flex flex-row mt-3 mx-5">
    <div
      class="border-primary rounded"
      style="
        height: 50rem;
        width: 75%;
        border: solid 3px;
        position: relative;
        overflow: scroll;
      "
    >
      <div
        class="d-flex w-100 h-100 justify-content-center"
        *ngIf="rows.length == 0 && !loading && !isHeaderEmpty"
      >
        <disclaimer
          class="mt-4"
          text="{{ 'GRID.SELECT_CROP' | translate }}"
        ></disclaimer>
      </div>

      <div
        class="d-flex w-100 h-100 justify-content-center"
        *ngIf="isHeaderEmpty && !loading"
      >
        <disclaimer
          class="mt-4"
          text="{{ 'GRID.EMPTY_GRID' | translate }}"
        ></disclaimer>
      </div>

      <table *ngIf="columnHeaders.length != 0 && rows.length != 0 && !loading">
        <thead>
          <tr *ngIf="columnHeaders">
            <th
              class="bg-secondary border-primary vertical-cell"
              style="z-index: 20"
            ></th>
            <th
              class="horizontal-cell text-white border-primary pointer px-2 py-0"
              *ngFor="let column of columnHeaders; index as columnIndex"
              [ngClass]="
                selectedColumn == columnIndex ? 'cell-selected' : 'bg-secondary'
              "
              (click)="getLegend(columnIndex)"
            >
              <div
                class="py-2"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
              >
                {{ column.descriptorLang }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="rows">
          <tr *ngFor="let row of rows; index as rowIndex">
            <th
              class="text-white vertical-cell border-primary pointer"
              style="z-index: 10"
              [ngClass]="
                selectedRow == rowIndex ? 'cell-selected' : 'bg-secondary'
              "
              (click)="getGeneralInfos(row.fgdID, row.refID, rowIndex)"
            >
              <h6>... {{ row.fgdID.substring(10) }}</h6>
              <h6>{{ row.varietyName }}</h6>
            </th>
            <td
              class="vertical-cell text-center pointer p-0"
              [ngClass]="getBackgroundCell(rowIndex, answerIndex)"
              *ngFor="let answer of row.answers; index as answerIndex"
              (click)="getModify(answer.answerID, rowIndex, answerIndex)"
            >
              <div>
                {{ answer.answer }}
                <i class="bi bi-chat-left-text-fill" *ngIf="answer.notes"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <loading class="w-100 h-100 mask" *ngIf="loading"></loading>
    </div>
    <div
      class="border-primary rounded d-flex flex-column align-items-center px-3 mx-2"
      style="width: 25%; height: 50rem; border: 3px solid"
    >
      <h4 class="mx-auto mt-2">{{ "GRID.TABLE_TITLE" | translate }}</h4>
      <div class="bg-primary w-100 mx-4" style="height: 2px"></div>
      <div
        class="d-flex flex-column"
        *ngIf="!isGeneralInfosOpened && !isLegendOpened && !isModifyOpened"
      >
        <h4 class="mx-auto mt-2">{{ "GRID.INSTRUCTIONS" | translate }}</h4>
        <ul>
          <li class="h5 mt-2">{{ "GRID.POINT_1" | translate }}</li>
          <li class="h5 mt-4">
            {{ "GRID.POINT_2" | translate }}
          </li>
          <li class="h5 mt-4">
            {{ "GRID.POINT_3" | translate }}
          </li>
          <li class="h5 mt-4">
            {{ "GRID.POINT_4" | translate }}
          </li>
          <li class="h5 mt-4">
            {{ "GRID.POINT_5" | translate }}
          </li>
        </ul>
      </div>
      <div
        class="d-flex flex-column mt-2 text-center"
        *ngIf="isGeneralInfosOpened"
      >
        <i class="bi bi-dot h3 mb-0"></i>
        <h6>{{ "GRID.AGRO_ZONE" | translate }}:</h6>
        <h6>{{ varietyInfo?.agroZone }}</h6>
        <i class="bi bi-dot h3 mt-2 mb-0"></i>
        <h6>{{ "GRID.SITE_NAME" | translate }}:</h6>
        <h6>{{ varietyInfo?.siteName }}</h6>
        <i class="bi bi-dot h3 mt-2 mb-0"></i>
        <h6>{{ "GRID.FGD_ID" | translate }}:</h6>
        <h6>{{ varietyInfo?.fgdID }}</h6>
        <i class="bi bi-dot h3 mt-2 mb-0"></i>
        <h6>{{ "GRID.FGD_TYPE" | translate }}:</h6>
        <h6>{{ varietyInfo?.fgdAge }} - {{ varietyInfo?.fgdGender }}</h6>
        <i class="bi bi-dot h3 mt-2 mb-0"></i>
        <h6>{{ varietyInfo?.brought }}</h6>
        <i class="bi bi-dot h3 mt-2 mb-0"></i>
        <h6>{{ varietyInfo?.varietyType }}</h6>
      </div>
      <div class="d-flex flex-column mt-2 text-center" *ngIf="isLegendOpened">
        <i class="bi bi-dot h3 mb-0"></i>
        <h6>Legend PlaceHolder</h6>
      </div>
      <div
        class="w-100 h-100 d-flex flex-column mt-2 text-center"
        *ngIf="isModifyOpened"
        [ngSwitch]="answerInfo?.answerType"
        [formGroup]="modifyForm!"
      >
        <div class="d-flex flex-column mt-2">
          <h4>{{ "GRID.MODIFY_ANSWER" | translate }}</h4>

          <div *ngSwitchCase="'MULTIPLE'">
            <div>
              <ng-select
                formControlName="choiceValue"
                [searchable]="false"
                [clearable]="false"
              >
                <ng-option
                  *ngFor="let value of answerInfo?.values"
                  [value]="value.uuid"
                >
                  {{ value.valueText }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div *ngSwitchCase="'MULTIPLE_LIST'">
            <div>
              <ng-select
                [multiple]="true"
                formControlName="choiceMultiple"
                [searchable]="false"
                [clearable]="false"
              >
                <ng-option
                  *ngFor="let option of answerInfo?.values"
                  [value]="option.uuid"
                >
                  {{ option.valueText }}
                </ng-option>
              </ng-select>
            </div>
          </div>

          <div *ngSwitchCase="'TEXT'">
            <div>
              <input
                type="text"
                class="form-control"
                formControlName="textValue"
              />
            </div>
          </div>

          <div *ngSwitchCase="'NUMBER'">
            <div>
              <input
                type="number"
                class="form-control"
                formControlName="nValue"
              />
            </div>
          </div>

          <div class="d-flex justify-content-center" *ngSwitchCase="'BOOLEAN'">
            <div class="form-check form-check-inline">
              <input
                mdbCheckbox
                class="form-check-input"
                type="radio"
                id="checkYes"
                [value]="true"
              />
              <label class="form-check-label" for="checkYes">
                {{ "GRID.YES" | translate }}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                mdbradio
                class="form-check-input"
                type="radio"
                id="checkNo"
                [value]="false"
              />
              <label class="form-check-label" for="checkNo">
                {{ "GRID.NO" | translate }}
              </label>
            </div>
          </div>
          <p>{{ answerInfo?.measure }}</p>
          <div class="btn btn-success mx-5 my-3" (click)="save()">
            {{ "BUTTONS.SAVE" | translate }}
          </div>
          <div class="px-3">
            <span>{{ answerInfo?.notes }}</span>
          </div>
        </div>
        <div class="bg-primary w-100 mx-2 my-3" style="height: 2px"></div>
        <h6>Legend PlaceHolder</h6>
      </div>
    </div>
  </div>
</div>
