import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { PieDataset } from 'src/app/layout/components/graphs/graph-pie/graph-pie.component';
import { ModalAddInterventionsComponent } from 'src/app/modals/projects/modal-add-interventions/modal-add-interventions.component';
import { ModalAnswerInterventionsComponent } from 'src/app/modals/projects/modal-answer-interventions/modal-answer-interventions.component';
import { ModalManageInterventionsComponent } from 'src/app/modals/projects/modal-manage-interventions/modal-manage-interventions.component';
import { Site } from 'src/app/models/project';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'project-interventions',
  templateUrl: './project-interventions.component.html',
  styleUrls: ['./project-interventions.component.scss'],
})
export class ProjectInterventionsComponent implements OnInit {
  @Input() selectedSpecie?: string;
  @Input() species?: Select[];
  @Input() projectID?: string;
  @Output() stateChange: EventEmitter<number> = new EventEmitter();
  filterForm?: FormGroup;

  sites?: Site[];

  placeholderNumber?: number;
  numberInterventions?: number;
  numberInterventionsProject?: number;
  numberArea?: number;
  numberAreaProject?: number;

  datasetAge?: PieDataset[];
  datasetAgeProject?: PieDataset[];
  datasetGender?: PieDataset[];
  datasetGenderProject?: PieDataset[];
  datasetPlaceholder: PieDataset[] = [
    {
      data: [2112, 2343, 2545],
      backgroundColor: [
        '#123425',
        'rgba(77, 182, 172, 0.5)',
        'rgba(66, 133, 244, 0.5)',
      ],
      labels: ['Monday', 'Tuesday', 'Wednesday'],
      varietiesByData: new Map(),
    },
  ];

  loading = true;

  constructor(private api: ApiService, private modalService: MdbModalService) {}

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      projectID: new FormControl(this.projectID),
      refID: new FormControl(this.selectedSpecie),
      siteID: new FormControl(null),
      agroecoZone: new FormControl(null),
      categoryID: new FormControl(null),
      gender: new FormControl(null),
      age: new FormControl(null),
    });

    this.getSites();

    this.randomizePie();

    this.apiCall();

    this.filterForm.valueChanges.subscribe((value) => {
      this.apiCall();
    });
  }

  randomizePie() {
    if (this.datasetAge == undefined || this.datasetGender == undefined) {
      this.datasetPlaceholder = [
        {
          data: [this.randomNumber(), this.randomNumber(), this.randomNumber()],
          backgroundColor: [
            'rgba(63, 81, 181, 0.5)',
            'rgba(77, 182, 172, 0.5)',
            'rgba(66, 133, 244, 0.5)',
          ],
          labels: [],
          varietiesByData: new Map(),
        },
      ];
      setTimeout(() => this.randomizePie(), 1000);
    }
  }

  randomNumber(): number {
    return Math.floor(Math.random() * 100);
  }

  randomizeGraphNumber() {
    if (this.numberInterventions == undefined || this.numberArea == undefined) {
      this.placeholderNumber = this.randomNumber();
      setTimeout(() => this.randomizeGraphNumber(), 100);
    }
  }

  apiCall() {
    this.api
      .getMeNumberByAgeInterventions(this.filterForm?.value)
      .subscribe((value) => {
        this.datasetAge = [value as PieDataset];
      });
    this.api
      .getMeNumberByGenderInterventions(this.filterForm?.value)
      .subscribe((value) => {
        this.datasetGender = [value as PieDataset];
      });
    this.api
      .getMeNumberInterventions(this.filterForm?.value)
      .subscribe((value: any) => {
        this.numberInterventions = value.valueString;
      });
    this.api
      .getMeAreaCoveredInterventions(this.filterForm?.value)
      .subscribe((value: any) => {
        this.numberArea = value.valueString;
      });

    this.api
      .getMeNumberByAgeInterventionsProject(this.filterForm?.value)
      .subscribe((value) => {
        this.datasetAgeProject = [value as PieDataset];
      });
    this.api
      .getMeNumberByGenderInterventionsProject(this.filterForm?.value)
      .subscribe((value) => {
        this.datasetGenderProject = [value as PieDataset];
      });
    this.api
      .getMeNumberInterventionsProject(this.filterForm?.value)
      .subscribe((value: any) => {
        this.numberInterventionsProject = value.valueString;
      });
    this.api
      .getMeAreaCoveredInterventionsProject(this.filterForm?.value)
      .subscribe((value: any) => {
        this.numberAreaProject = value.valueString;
      });
  }

  getSites() {
    this.api.getSites(this.projectID!).subscribe((value) => {
      this.sites = value as Site[];
      this.site?.setValue(this.sites[0].siteID);
      this.loading = false;
    });
  }

  openManageInterventions() {
    const modalRef = this.modalService.open(ModalManageInterventionsComponent, {
      data: {
        refID: this.selectedSpecie,
        species: this.species,
        projectID: this.projectID,
        siteID: this.site?.value,
        sites: this.sites,
      },
      modalClass: 'modal-fullscreen',
    });
    modalRef.onClose.subscribe((value: any) => {
      if (!value) return;
      this.modalService.open(ModalAddInterventionsComponent, {
        data: value,
        modalClass: 'modal-fullscreen',
      });
    });
  }

  openAnswerInterventions() {
    const modalRef = this.modalService.open(ModalAnswerInterventionsComponent, {
      data: {
        refID: this.selectedSpecie,
        species: this.species,
        projectID: this.projectID,
        siteID: this.site?.value,
        sites: this.sites,
      },
      modalClass: 'modal-fullscreen',
    });
    modalRef.onClose.subscribe((value: any) => {
      if (!value) return;
    });
  }

  back() {
    this.stateChange.emit(0);
  }

  get specie() {
    return this.filterForm?.get('refID');
  }

  get site() {
    return this.filterForm?.get('siteID');
  }
}
