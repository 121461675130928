<div class="d-flex flex-column px-5 py-3" *ngIf="activity">
  <div class="d-flex align-items-center">
    <i
      class="bi bi-arrow-left-square-fill h1 me-2"
      style="cursor: pointer"
      (click)="backToActivities()"
    ></i>
    <h5 class="p-2 bg-primary text-white w-100">
      {{ activityType }} | {{ activity?.uuid }}
    </h5>
  </div>
  <div *ngIf="activityType == 'HHS'">
    <div class="d-flex flex-column mx-2 my-2">
      <h4 class="">{{ "VARIETY.HHS" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="row align-items-center my-auto mx-4">
          <div class="col-6">
            <h5 class="my-auto">
              {{ "SITE.LOCATION" | translate }}:
              {{ activity.location | titlecase }}
            </h5>
          </div>
          <div class="col-6">
            <h5 class="my-auto">
              {{ "SITE.PROVINCE" | translate }}:
              {{ activity.province | titlecase }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center mt-2 mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.VILLAGE" | translate }}:
              {{ activity.village | titlecase }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center my-auto mx-4 mt-2">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LAT" | translate }}: {{ activity.lat }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LNG" | translate }}: {{ activity.lng }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.ALT" | translate }}: {{ activity.alt }}
            </h5>
          </div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.FACILITATOR" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="row align-items-center mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.NAME" | translate }}:
              {{ activity.facilitator.name | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.GENDER" | translate }}:
              {{ getGender(activity.facilitator.gender) }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.BIRTH_YEAR" | translate }}:
              {{ activity.facilitator.yearBirth }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center mt-2 mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.PHONE" | translate }}:
              {{ activity.facilitator.phone | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.ORGANIZATION" | translate }}:
              {{ activity.facilitator.organization | titlecase }}
            </h5>
          </div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.RAPPORTEUR" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="row align-items-center mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.NAME" | translate }}:
              {{ activity.rapporteur.name | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.GENDER" | translate }}:
              {{ getGender(activity.rapporteur.gender) | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.BIRTH_YEAR" | translate }} :
              {{ activity.rapporteur.yearBirth }}
            </h5>
          </div>
        </div>
      </div>
      <h4 class="mt-4">
        {{ "SITE.FARMER" | translate }}: {{ activity.farmer.farmerID }}
      </h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="row align-items-center mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.NAME" | translate }}:
              {{ activity.farmer.name | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.GENDER" | translate }}:
              {{ getGender(activity.farmer.gender) }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.BIRTH_YEAR" | translate }}:
              {{ activity.farmer.yearBirth }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center mt-2 mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.VILLAGE" | translate }}:
              {{ activity.farmer.homeVillage | titlecase }}
            </h5>
          </div>
          <div
            class="col-4"
            *ngIf="activity.farmer.socialEthnoGroup !== 'OTHER'"
          >
            <h5 class="my-auto">
              {{ "SITE.ETHNIC_GROUP" | translate }}:
              {{ activity.farmer.socialEthnoGroup | titlecase }}
            </h5>
          </div>
          <div
            class="col-4"
            *ngIf="activity.farmer.socialEthnoGroup === 'OTHER'"
          >
            <h5 class="my-auto">
              {{ "SITE.SOCIAL_GROUP" | translate }}:
              {{ activity.farmer.socialGroup | titlecase }}
            </h5>
          </div>
        </div>
        <button
          class="btn btn-primary ms-4 me-auto mt-2"
          [disabled]="!activity.farmer.privacy"
        >
          <a
            [href]="activity.farmer.privacy"
            target="_black"
            class="text-white"
          >
            {{ "BUTTONS.DOWNLOAD_PRIVACY" | translate }}
          </a>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="activityType == 'FGD'">
    <div class="d-flex flex-column mx-2 my-2">
      <h4 class="">{{ "VARIETY.FGD" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="row align-items-center my-auto mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LOCATION" | translate }}:
              {{ activity.location | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "NAVIGATION.CATEGORY" | translate }}:
              {{ activity.category | titlecase }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center my-auto mx-4 mt-2">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LAT" | translate }}: {{ activity.lat }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LNG" | translate }}: {{ activity.lng }}
            </h5>
          </div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.FACILITATOR" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="row align-items-center mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.NAME" | translate }}:
              {{ activity.facilitator.name | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.GENDER" | translate }}:
              {{ getGender(activity.facilitator.gender) | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.BIRTH_YEAR" | translate }}:
              {{ activity.facilitator.yearBirth }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center mt-2 mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.PHONE" | translate }}:
              {{ activity.facilitator.phone }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.ORGANIZATION" | translate }}:
              {{ activity.facilitator.organization | titlecase }}
            </h5>
          </div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.RAPPORTEURS" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <h5 class="my-auto" *ngIf="activity.rapporteurs.length == 0">
          {{ "SITE.NO_RAPPORTEURS" | translate }}
        </h5>
        <div
          class="row align-items-center mx-4"
          *ngFor="let rapporteur of activity.rapporteurs"
        >
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.NAME" | translate }}: {{ rapporteur.name | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.GENDER" | translate }}:
              {{ getGender(rapporteur.gender) | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.BIRTH_YEAR" | translate }}:
              {{ rapporteur.yearBirth }}
            </h5>
          </div>
          <div
            class="bg-primary my-2"
            style="height: 2px"
            *ngIf="activity.rapporteurs.length > 1"
          ></div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.FARMERS" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="d-flex flex-column" *ngFor="let farmer of activity.farmers">
          <div class="row align-items-center mx-4">
            <div class="col-4">
              <h5 class="my-auto">
                {{ "USER.NAME" | translate }}: {{ farmer.name | titlecase }}
              </h5>
            </div>
            <div class="col-4">
              <h5 class="my-auto">
                {{ "USER.GENDER" | translate }}:
                {{ getGender(farmer.gender) | titlecase }}
              </h5>
            </div>
            <div class="col-4">
              <h5 class="my-auto">
                {{ "USER.BIRTH_YEAR" | translate }}: {{ farmer.yearBirth }}
              </h5>
            </div>
          </div>
          <div class="row align-items-center mt-2 mx-4">
            <div class="col-4">
              <h5 class="my-auto">
                {{ "SITE.VILLAGE" | translate }}:
                {{ farmer.homeVillage | titlecase }}
              </h5>
            </div>
            <div class="col-4" *ngIf="farmer.socialEthnoGroup !== 'OTHER'">
              <h5 class="my-auto">
                {{ "SITE.ETHNIC_GROUP" | translate }}:
                {{ farmer.socialEthnoGroup | titlecase }}
              </h5>
            </div>
            <div class="col-4" *ngIf="farmer.socialEthnoGroup === 'OTHER'">
              <h5 class="my-auto">
                {{ "SITE.SOCIAL_GROUP" | translate }}:
                {{ farmer.socialGroup | titlecase }}
              </h5>
            </div>
          </div>
          <div class="btn btn-primary ms-4 me-auto mt-2">
            <a
              href="https://webportal.datar-par.org/download-privacy/{{
                activity.uuid.substring(0, 9)
              }}/privacy-{{ activity.farmer?.farmerID }}-signed.pdf"
              class="text-white"
            >
              {{ "BUTTONS.DOWNLOAD_PRIVACY" | translate }}
            </a>
          </div>
          <div
            class="bg-primary mx-4 my-2"
            style="height: 2px"
            *ngIf="activity.farmers.length > 1"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="activityType == 'KIS'">
    <div class="d-flex flex-column mx-2 my-2">
      <h4 class="">{{ "VARIETY.FGD" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="row align-items-center my-auto mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LOCATION" | translate }}:
              {{ activity.location | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "NAVIGATION.CATEGORY" | translate }}:
              {{ activity.category | titlecase }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center my-auto mx-4 mt-2">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LAT" | translate }}: {{ activity.lat }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LNG" | translate }}: {{ activity.lng }}
            </h5>
          </div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.INTERVIEWER" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="row align-items-center mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.NAME" | translate }}:
              {{ activity.facilitator.name | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.GENDER" | translate }}:
              {{ getGender(activity.facilitator.gender) | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.BIRTH_YEAR" | translate }}:
              {{ activity.facilitator.yearBirth }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center mt-2 mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.PHONE" | translate }}:
              {{ activity.facilitator.phone }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.ORGANIZATION" | translate }}:
              {{ activity.facilitator.organization | titlecase }}
            </h5>
          </div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.RAPPORTEUR" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div
          class="row align-items-center mx-4"
          *ngIf="activity.rapporteur.name"
        >
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.NAME" | translate }}:
              {{ activity.rapporteur.name | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.GENDER" | translate }}:
              {{ getGender(activity.rapporteur.gender) | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.BIRTH_YEAR" | translate }} :
              {{ activity.rapporteur.yearBirth }}
            </h5>
          </div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.INTERVIEWEE" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="d-flex flex-column">
          <div class="row align-items-center mx-4">
            <div class="col-4">
              <h5 class="my-auto">
                {{ "USER.NAME" | translate }}:
                {{ activity.interviewed.name | titlecase }}
              </h5>
            </div>
            <div class="col-4">
              <h5 class="my-auto">
                {{ "USER.GENDER" | translate }}:
                {{ getGender(activity.interviewed.gender) | titlecase }}
              </h5>
            </div>
            <div class="col-4">
              <h5 class="my-auto">
                {{ "USER.BIRTH_YEAR" | translate }}:
                {{ activity.interviewed.yearBirth }}
              </h5>
            </div>
          </div>
          <div class="row align-items-center mt-2 mx-4">
            <div class="col-4">
              <h5 class="my-auto">
                {{ "SITE.VILLAGE" | translate }}:
                {{ activity.interviewed.homeVillage | titlecase }}
              </h5>
            </div>
            <div
              class="col-4"
              *ngIf="activity.interviewed.socialEthnoGroup !== 'OTHER'"
            >
              <h5 class="my-auto">
                {{ "SITE.ETHNIC_GROUP" | translate }}:
                {{ activity.interviewed.socialEthnoGroup | titlecase }}
              </h5>
            </div>
            <div
              class="col-4"
              *ngIf="activity.interviewed.socialEthnoGroup === 'OTHER'"
            >
              <h5 class="my-auto">
                {{ "SITE.SOCIAL_GROUP" | translate }}:
                {{ activity.interviewed.socialGroup | titlecase }}
              </h5>
            </div>
          </div>
          <div class="row align-items-center my-2 mx-4">
            <div class="col-4">
              <h5 class="my-auto">
                {{ "SITE.COMPANY_NAME" | translate }}:
                {{ activity.companyName | titlecase }}
              </h5>
            </div>
            <div class="col-4">
              <h5 class="my-auto">
                {{ "SITE.COMPANY_ROLES" | translate }}:
                <span *ngFor="let item of activity.companyRoles; last as last">
                  {{ item | titlecase }}
                  <span *ngIf="!last">, </span>
                </span>
              </h5>
            </div>
            <div class="col-4"></div>
          </div>
          <div class="btn btn-primary ms-4 me-auto mt-2">
            <a
              [href]="activity.interviewed.privacy"
              class="text-white"
              target="_blank"
            >
              {{ "BUTTONS.DOWNLOAD_PRIVACY" | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-body" *ngIf="activityType == 'VDM'">
    <div class="d-flex flex-column mx-2 my-2">
      <h4 class="">{{ "VARIETY.VDM" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="row align-items-center my-auto mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LOCATION" | translate }}:
              {{ activity.location | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.TYPE" | translate }}:
              {{ activity.category | titlecase }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center my-auto mx-4 mt-2">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LAT" | translate }}: {{ activity.lat }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "SITE.LNG" | translate }}: {{ activity.lng }}
            </h5>
          </div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.INTERVIEWER" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="row align-items-center mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.NAME" | translate }}:
              {{ activity.facilitator.name | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.GENDER" | translate }}:
              {{ getGender(activity.facilitator.gender) | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.BIRTH_YEAR" | translate }}:
              {{ activity.facilitator.yearBirth }}
            </h5>
          </div>
        </div>
        <div class="row align-items-center mt-2 mx-4">
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.PHONE" | translate }}:
              {{ activity.facilitator.phone }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.ORGANIZATION" | translate }}:
              {{ activity.facilitator.organization | titlecase }}
            </h5>
          </div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.RAPPORTEUR" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <h5 class="my-auto" *ngIf="activity.rapporteurs.length == 0">
          {{ "SITE.NO_RAPPORTEURS" | translate }}
        </h5>
        <div
          class="row align-items-center mx-4"
          *ngFor="let rapporteur of activity.rapporteurs"
        >
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.NAME" | translate }}: {{ rapporteur.name | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.GENDER" | translate }}:
              {{ getGender(rapporteur.gender) | titlecase }}
            </h5>
          </div>
          <div class="col-4">
            <h5 class="my-auto">
              {{ "USER.BIRTH_YEAR" | translate }}:
              {{ rapporteur.yearBirth }}
            </h5>
          </div>
          <div
            class="bg-primary my-2"
            style="height: 2px"
            *ngIf="activity.rapporteurs.length > 1"
          ></div>
        </div>
      </div>
      <h4 class="mt-4">{{ "SITE.GROUPS_PARTICIPANTS" | translate }}:</h4>
      <div class="d-flex flex-column px-2 py-2 bg-white rounded">
        <div class="d-flex flex-column" *ngFor="let group of activity.groups">
          <div class="row align-items-center mx-4">
            <div class="col-4">
              <h5 class="my-auto">
                {{ "USER.NAME" | translate }}: {{ group.name | titlecase }}
              </h5>
            </div>
            <div class="col-4">
              <h5 class="my-auto">
                {{ "USER.GENDER" | translate }}:
                {{ group.gender | titlecase }}
              </h5>
            </div>
            <div class="col-4">
              <h5 class="my-auto">
                {{ "SITE.AGE" | translate }}: {{ group.age | titlecase }}
              </h5>
            </div>
          </div>
          <div class="row align-items-center mt-2 mx-4">
            <div class="col-4">
              <h5 class="my-auto">
                {{ "SITE.SIZE" | translate }}:
                {{ group.count }}
              </h5>
            </div>
            <div class="col-4">
              <h5 class="my-auto">
                {{ "SITE.NOTES" | translate }}:
                {{ group.notes }}
              </h5>
            </div>
          </div>
          <div class="btn btn-primary ms-4 me-auto mt-2">
            <a [href]="group.privacy" class="text-white" target="_blank">
              {{ "BUTTONS.DOWNLOAD_PRIVACY" | translate }}
            </a>
          </div>
          <div
            class="bg-primary mx-4 my-2"
            style="height: 2px"
            *ngIf="activity.groups.length > 1"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <mdb-lightbox class="lightbox mt-4" *ngIf="images.length > 0">
    <div class="row">
      <div class="col-lg-4" style="height: 20rem" *ngFor="let item of images">
        <img
          mdbLightboxItem
          [src]="item.imageUrl"
          [img]="item.imageUrl"
          style="object-fit: contain"
          class="w-100 shadow-1-strong rounded h-100"
        />
      </div>
    </div>
  </mdb-lightbox>
</div>
