import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { AppState } from 'src/app/auth/reducer';
import { getUser } from 'src/app/auth/store/auth.selectors';
import { ModalEditTitleComponent } from 'src/app/modals/projects/modal-edit-title/modal-edit-title.component';
import { ModalNewSiteComponent } from 'src/app/modals/projects/modal-new-site/modal-new-site.component';
import { ModalShareProjectComponent } from 'src/app/modals/projects/modal-share-project/modal-share-project.component';
import {
  ProjectPageHelper,
  ProjectPageListener,
  ProjectPermissions,
} from 'src/app/models/project-page-helper';
import { CurrentProject, Project, Site } from 'src/app/models/project';
import { ModalChangeProjectPurposeComponent } from 'src/app/modals/projects/modal-change-project-purpose/modal-change-project-purpose.component';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'project-detailed-page',
  templateUrl: './project-detailed-page.component.html',
  styleUrls: ['./project-detailed-page.component.scss'],
})
export class ProjectDetailedComponent implements OnInit, OnDestroy {
  @ViewChild('stickyNav') stickyNav?: ElementRef;
  projectPageHelper: ProjectPageHelper = new ProjectPageHelper();
  projectPageHelper$?: Subscription;

  project?: Project;

  projectID?: string | null;
  countryID?: string;

  scrollNav: boolean = false;

  constructor(
    private api: ApiService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private currentProject: CurrentProject,
    private modalService: MdbModalService,
    private projectPageListener: ProjectPageListener,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectID = this.route.snapshot.paramMap.get('id');

    this.store.select(getUser).subscribe((value) => {
      this.countryID = value?.countryID as string;
    });
    this.projectPageHelper?.clear();
    this.getProject();
    this.currentProject.uuid = this.project?.uuid;
    this.projectPageListener.setData(this.projectPageHelper);

    window.addEventListener('scroll', this.onScroll);

    this.projectPageHelper$ = this.projectPageListener.data.subscribe(
      (data: ProjectPageHelper) => {
        if (data.countryChanges) {
          this.router.navigateByUrl('/u/projects');
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.projectPageHelper$?.unsubscribe();
    window.removeEventListener('scroll', this.onScroll);
  }

  shrinkString(string: string | undefined) {
    if (string?.length && string.length <= 40) return string;
    return string?.substring(0, 37) + ' ...';
  }

  getProject() {
    if (this.projectID) {
      this.api.getProject(this.projectID).subscribe(
        (value) => {
          this.project = value as Project;
          const pp = value as ProjectPermissions;
          this.projectPageHelper.projectPermissions = pp;
          this.projectPageListener.setData(this.projectPageHelper);
        },
        (error) => {
          if (error.status == 403) {
            this.router.navigateByUrl('/u/projects');
          }
        }
      );
    }
  }

  openNewSite() {
    this.projectPageHelper.siteAdded = false;
    this.projectPageListener.setData(this.projectPageHelper);
    const modalRef = this.modalService.open(ModalNewSiteComponent, {
      data: {
        projectID: this.projectID,
        countryID: this.project?.countryID,
        species: this.project?.species,
        ref: this.project?.typeOf,
      },
      modalClass: 'modal-frame modal-bottom',
      ignoreBackdropClick: true,
    });

    modalRef.onClose.subscribe((message: any) => {
      if (message) {
        this.projectPageHelper.siteAdded = true;
        this.projectPageListener.setData(this.projectPageHelper);
      }
    });
  }

  openShareProject() {
    this.projectPageHelper.userAdded = false;
    this.projectPageListener.setData(this.projectPageHelper);
    const modalRef = this.modalService.open(ModalShareProjectComponent, {
      data: {
        projectId: this.projectID,
      },
      modalClass: 'modal-lg',
      ignoreBackdropClick: true,
    });

    modalRef.onClose.subscribe((message: boolean) => {
      if (message) {
        this.projectPageHelper.userAdded = true;
        this.projectPageListener.setData(this.projectPageHelper);
      }
    });
  }

  openEditTitle() {
    const modalRef = this.modalService.open(ModalEditTitleComponent, {
      data: {
        projectID: this.projectID,
        projectTitle: this.project?.title,
      },
      modalClass: 'modal-md',
      ignoreBackdropClick: true,
    });

    modalRef.onClose.subscribe((message: boolean) => {
      if (message) {
        this.getProject();
      }
    });
  }

  openSwitchProjectPurpose() {
    const modalRef = this.modalService.open(
      ModalChangeProjectPurposeComponent,
      {
        containerClass: 'top',
        data: { purpose: this.project?.purpose },
      }
    );

    modalRef.onClose.subscribe((message) => {
      if (message && this.projectID) {
        this.api.changeProjectRealTest(this.projectID).subscribe((value) => {
          this.project = value as Project;
        });
      }
    });
  }

  onScroll = () => {
    if (this.stickyNav?.nativeElement.getBoundingClientRect().top == 0) {
      this.scrollNav = true;
    } else {
      this.scrollNav = false;
    }
  };

  getImage(): string {
    switch (this.project?.typeOf) {
      case 'CROPS':
        return '/assets/images/crop.svg';
      case 'LIVESTOCK':
        return '/assets/images/livestock.svg';
      case 'AQUATICS':
        return '/assets/images/aquaculture.svg';
      default:
        return '';
    }
  }
}
