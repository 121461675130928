import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { RScript } from 'src/app/models/r-script';

@Component({
  selector: 'modal-file-analysis',
  templateUrl: './modal-file-analysis.component.html',
  styleUrls: ['./modal-file-analysis.component.scss'],
})
export class ModalFileAnalysisComponent implements OnInit {
  script?: RScript;

  constructor(
    public modalRef: MdbModalRef<ModalFileAnalysisComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}
}
