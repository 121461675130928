import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { AppState } from 'src/app/auth/reducer';
import { getUser } from 'src/app/auth/store/auth.selectors';
import { ModalNewRequestComponent } from 'src/app/modals/contact-us/modal-new-request/modal-new-request.component';
import { Pageable } from 'src/app/models/pageable';

@Component({
  selector: 'support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatDiv') chatDiv!: ElementRef;
  messageForm?: UntypedFormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  error: boolean = false;
  success: boolean = false;

  selectedRefID: string | null = null;
  pageable?: Pageable;
  page: number = 0;
  requests: any[] = [];
  conversation: any = [];

  isDisabled: boolean = false;

  constructor(
    private api: ApiService,
    private store: Store<AppState>,
    public modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.messageForm = new UntypedFormGroup({
      message: new UntypedFormControl({ value: null, disabled: this.isDisabled }, [
        Validators.required,
      ]),
    });

    this.apiCall();
  }

  ngAfterViewChecked(): void {
    this.chatDiv.nativeElement.scrollTop =
      this.chatDiv.nativeElement.scrollHeight;
  }

  apiCall() {
    this.api.getMySupportRequests().subscribe((value) => {
      this.pageable = value as Pageable;
      this.requests = this.pageable.content;
    });
  }

  onRequestSelect(i: number, refID: string) {
    if (!this.requests[i].selected) {
      this.requests.forEach((item) => {
        item.selected = false;
      });
      this.selectedRefID = null;
    }
    this.selectedRefID = refID;
    this.requests[i].selected = true;
    this.api.getMySupportRequest(refID).subscribe((value) => {
      this.requests[i].userRead = true;
      this.conversation = value as any[];
      if (this.requests[i].closed) {
        this.isDisabled = true;
        this.message?.disable();
      } else {
        this.message?.enable();
      }
    });
  }

  sendMessage() {
    if (this.message?.valid) {
      this.api
        .addMySupportRequest(this.selectedRefID, null, this.message?.value)
        .subscribe(
          (value) => {
            this.conversation.unshift(value as any);
            this.message?.setValue(null);
          },
          (erro) => {}
        );
    }
  }

  onPageClick(page: number) {
    this.page = page;
    this.apiCall();
  }

  openNewRequest() {
    const modalRef = this.modalService.open(ModalNewRequestComponent, {
      modalClass: 'modal-dialog-centered',
    });

    modalRef.onClose.subscribe((value) => {
      if (value) {
        this.requests.unshift(value);
        this.onRequestSelect(0, value.supportID);
      }
    });
  }

  switchIcon(request: any): string {
    if (request.closed) {
      return 'bi-envelope-check-fill';
    }
    if (request.userRead && request.adminAnswered) {
      // red
      return 'bi-envelope-open-fill';
    }
    if (!request.userRead && request.adminAnswered) {
      //new message
      return 'bi-envelope-paper-fill';
    }
    if (!request.adminAnswered) {
      //pending
      return 'bi-envelope-exclamation-fill';
    }
    return 'bi-envelope-open-fill';
  }

  get message() {
    return this.messageForm?.get('message');
  }
}
