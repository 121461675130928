<div class="d-flex flex-column mx-5 mt-2" [formGroup]="filterForm!">
  <!-- <div class="col-2 btn btn-primary my-2 mb-3" style="width: 10rem">
      request file
    </div> -->
  <div class="d-flex flex-row">
    <div
      class="d-flex card p-0 m-0 bg-transparent border-primary"
      style="height: 50vh; width: 50rem; border: 3px solid"
    >
      <div class="pb-0 bg-light sticky-top">
        <div class="d-flex align-items-center pt-3">
          <h3 class="mx-2">
            {{ "DATA_COLLECTED.AVAILABLE_FILES" | translate }}
          </h3>
          <span class="custom-badge ms-1 bg-primary h6">
            {{ fileCreated?.length ?? 0 }}
          </span>
          <div class="ms-auto me-4 my-auto">
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'PLACEHOLDER.FILTER' | translate }}"
              formControlName="filterFile"
            />
          </div>
        </div>
        <div class="mx-2 w-90 bg-primary" style="height: 2px"></div>
      </div>
      <div
        class="d-flex flex-column justify-content-center h-100"
        *ngIf="!fileCreated"
      >
        <loading [glass]="false"></loading>
      </div>
      <disclaimer
        class="my-auto"
        [text]="'DATA_COLLECTED.NO_AV_FILES' | translate"
        *ngIf="fileCreated && fileCreated?.length == 0"
      ></disclaimer>
      <div style="height: 50vh; width: 50rem; overflow-y: scroll">
        <div
          class="d-flex flex-column pb-2 rounded mx-3 shadow"
          *ngFor="
            let file of fileCreated;
            index as i;
            first as first;
            last as last
          "
          [ngClass]="[
            i % 2 != 0 ? 'bg-white' : 'bg-white',
            last ? 'mb-5' : 'mb-3',
            first ? 'mt-3' : 'mt-0'
          ]"
        >
          <div
            class="w-100 rounded-top d-flex justify-content-center mb-2"
            [ngClass]="file.failed ? 'bg-danger' : 'bg-warning'"
            *ngIf="!file.completed"
          >
            <h6
              class="text-white my-auto py-1"
              *ngIf="!file.completed && !file.failed"
            >
              {{ "DATA_COLLECTED.FILE_GENERATING_2" | translate }}
            </h6>
            <h6
              class="text-white my-auto py-1"
              *ngIf="!file.completed && file.failed"
            >
              {{ "DATA_COLLECTED.FILE_FAILED" | translate }}
            </h6>
          </div>
          <div class="d-flex flex-row">
            <div class="mx-2 my-auto" style="height: 3rem; width: 3rem">
              <img
                class="w-100 h-100"
                src="../../../../assets/images/{{ file.fileExt }}.png"
                style="object-fit: contain"
              />
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex flex-row">
                <i
                  class="bi bi-sticky-fill me-1"
                  *ngIf="file.showNote"
                  [title]="'PLACEHOLDER.WITH_NOTES' | translate"
                ></i>
                <h5 class="mx-1">{{ file.name }}</h5>
              </div>
              <h6 class="mx-1">{{ file.description }}</h6>
              <h6 class="mx-1">
                {{ "DATA_COLLECTED.FILE_NAME" | translate }}:
                {{ file.fileName }}
              </h6>
            </div>
            <a
              [href]="file.path"
              [download]="file.fileName"
              class="btn btn-success my-auto ms-auto me-3"
              [ngClass]="!file.completed ? 'disabled' : ''"
              *ngIf="!file.failed"
            >
              {{ "BUTTONS.DOWNLOAD" | translate }}
            </a>
            <div
              class="btn btn-primary my-auto ms-auto me-3"
              *ngIf="file.failed"
              (click)="
                requestAgain(file.projectRequestFileID, i, file.showNote)
              "
            >
              {{ "BUTTONS.REQUEST_AGAIN" | translate }}
            </div>
          </div>
          <h6 class="ms-auto me-3">
            {{ "DATA_COLLECTED.GENERATED_BY" | translate }}:
            {{ file.createdBy }}
            {{ "DATA_COLLECTED.ON" | translate }}
            {{ file.creationDate | date : "short" }}
          </h6>
        </div>
      </div>
    </div>
    <div
      class="d-flex card p-0 m-0 bg-transparent border-primary ms-3"
      style="height: 50vh; width: 25rem; overflow-y: scroll; border: 3px solid"
    >
      <list-header
        color="bg-light"
        class="sticky-top p-0 m-0"
        title="{{ 'Request new File' | translate }}"
      ></list-header>
      <div class="mx-3 mt-2">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'PLACEHOLDER.FILTER' | translate }}"
          formControlName="filter"
        />
      </div>
      <div
        class="mx-4 rounded pt-3 pb-5"
        style="overflow: hidden; overflow-y: auto"
      >
        <mdb-accordion [flush]="true">
          <mdb-accordion-item>
            <ng-template mdbAccordionItemHeader>
              {{ "DATA_COLLECTED.GENERAL" | translate }} 
              <span class="badge h6 ms-2 bg-primary my-auto">
                {{ requesGeneralFiles.length }}
              </span>
            </ng-template>
            <ng-template mdbAccordionItemBody class="d-flex flex-column">
              <div
                class="w-100 d-flex flex-column justify-content-center align-items-center py-3"
                *ngIf="requesGeneralFiles?.length == 0"
              >
                <div class="mb-1" style="height: 4rem; width: 4rem">
                  <img
                    src="../../../../assets/images/logo.png"
                    class="w-100 h-100"
                    style="object-fit: contain"
                  />
                </div>
                <div>
                  <h5 class="mt-1 my-auto">
                    {{ "DATA_COLLECTED.NO_FILES" | translate }}
                  </h5>
                </div>
              </div>
              <div
                class="d-flex flex-column my-3 py-2 rounded mx-3"
                *ngFor="let file of requesGeneralFiles; index as i"
                [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
              >
                <div class="d-flex flex-column mx-2 w-100">
                  <h5 class="mx-1">{{ file.name }}</h5>
                  <h6 class="mx-1">{{ file.description }}</h6>
                  <div class="d-flex flex-row">
                    <div
                      class="d-flex flex-row"
                      *ngFor="let ext of file.extensions"
                    >
                      <i
                        class="bi h6 mx-1 pointer"
                        (click)="selectFileTypeGeneral(i, ext)"
                        [ngClass]="
                          file.selectedExtension === ext
                            ? 'bi-check-square-fill'
                            : 'bi-square'
                        "
                      ></i>
                      <h6 class="mx-1">.{{ ext }}</h6>
                    </div>
                  </div>
                  <div class="d-flex flex-row mt-2">
                    <div
                      class="btn btn-primary me-2"
                      (click)="
                        openRequestedFile(
                          file.selectedExtension,
                          file.projectFileTypeID,
                          periodID!,
                          null,
                          false
                        )
                      "
                    >
                      {{ "BUTTONS.REQUEST" | translate }}
                    </div>
                    <div
                      *ngIf="file.showNote"
                      class="btn btn-primary me-auto"
                      (click)="
                        openRequestedFile(
                          file.selectedExtension,
                          file.projectFileTypeID,
                          periodID!,
                          null,
                          true
                        )
                      "
                    >
                      {{ "BUTTONS.REQUEST_WITH_NOTES" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </mdb-accordion-item>
          <mdb-accordion-item>
            <ng-template mdbAccordionItemHeader>
              {{ "DATA_COLLECTED.FGD" | translate }}
              <span class="badge h6 ms-2 bg-primary my-auto">
                {{ requesFGDFiles.length }}
              </span>
            </ng-template>
            <ng-template mdbAccordionItemBody class="d-flex flex-column">
              <div
                class="w-100 d-flex flex-column justify-content-center align-items-center py-3"
                *ngIf="requesFGDFiles?.length == 0"
              >
                <div class="mb-1" style="height: 4rem; width: 4rem">
                  <img
                    src="../../../../assets/images/logo.png"
                    class="w-100 h-100"
                    style="object-fit: contain"
                  />
                </div>
                <div>
                  <h5 class="mt-1 my-auto">
                    {{ "No files to request" | translate }}
                  </h5>
                </div>
              </div>
              <div
                class="d-flex flex-column my-3 py-2 rounded mx-3"
                *ngFor="let file of requesFGDFiles; index as i"
                [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
              >
                <div
                  class="d-flex flex-column mx-2 w-100"
                  [formGroup]="requestFileForm!"
                >
                  <h5 class="mx-1">{{ file.name }}</h5>
                  <h6 class="mx-1">{{ file.description }}</h6>
                  <div class="d-flex flex-row">
                    <div
                      class="d-flex flex-row"
                      *ngFor="let ext of file.extensions"
                    >
                      <i
                        class="bi h6 mx-1 pointer"
                        (click)="selectFileTypeFGD(i, ext)"
                        [ngClass]="
                          file.selectedExtension === ext
                            ? 'bi-check-square-fill'
                            : 'bi-square'
                        "
                      ></i>
                      <h6 class="mx-1">.{{ ext }}</h6>
                    </div>
                  </div>
                  <div class="me-4" *ngIf="file.selectedExtension === 'csv'">
                    <ng-select
                      formControlName="select"
                      [searchable]="false"
                      [clearable]="false"
                    >
                      <ng-option
                        *ngFor="let specie of file.species"
                        [value]="specie.refID"
                      >
                        {{ specie.name }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="d-flex flex-row mt-2">
                    <div
                      class="btn btn-primary me-2"
                      (click)="
                        openRequestedFile(
                          file.selectedExtension,
                          file.projectFileTypeID,
                          periodID!,
                          null,
                          false
                        )
                      "
                    >
                      {{ "BUTTONS.REQUEST" | translate }}
                    </div>
                    <div
                      *ngIf="file.showNote"
                      class="btn btn-primary me-auto"
                      (click)="
                        openRequestedFile(
                          file.selectedExtension,
                          file.projectFileTypeID,
                          periodID!,
                          null,
                          true
                        )
                      "
                    >
                      {{ "BUTTONS.REQUEST_WITH_NOTES" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </mdb-accordion-item>
          <mdb-accordion-item>
            <ng-template mdbAccordionItemHeader>
              {{ "DATA_COLLECTED.HHS" | translate }}
              <span class="badge h6 ms-2 bg-primary my-auto">
                {{ requesHHSFiles.length }}
              </span>
            </ng-template>
            <ng-template mdbAccordionItemBody class="d-flex flex-column">
              <div
                class="w-100 d-flex flex-column justify-content-center align-items-center py-3"
                *ngIf="requesHHSFiles?.length == 0"
              >
                <div class="mb-1" style="height: 4rem; width: 4rem">
                  <img
                    src="../../../../assets/images/logo.png"
                    class="w-100 h-100"
                    style="object-fit: contain"
                  />
                </div>
                <div>
                  <h5 class="mt-1 my-auto">
                    {{ "No files to request" | translate }}
                  </h5>
                </div>
              </div>
              <div
                class="d-flex flex-column my-3 py-2 rounded mx-3"
                *ngFor="let file of requesHHSFiles; index as i"
                [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
              >
                <div class="d-flex flex-column mx-2 w-100">
                  <h5 class="mx-1">{{ file.name }}</h5>
                  <h6 class="mx-1">{{ file.description }}</h6>
                  <div class="d-flex flex-row">
                    <div
                      class="d-flex flex-row"
                      *ngFor="let ext of file.extensions"
                    >
                      <i
                        class="bi h6 mx-1 pointer"
                        (click)="selectFileTypeHHS(i, ext)"
                        [ngClass]="
                          file.selectedExtension === ext
                            ? 'bi-check-square-fill'
                            : 'bi-square'
                        "
                      ></i>
                      <h6 class="mx-1">.{{ ext }}</h6>
                    </div>
                  </div>
                  <div class="d-flex flex-row mt-2">
                    <div
                      class="btn btn-primary me-2"
                      (click)="
                        openRequestedFile(
                          file.selectedExtension,
                          file.projectFileTypeID,
                          periodID!,
                          null,
                          false
                        )
                      "
                    >
                      {{ "BUTTONS.REQUEST" | translate }}
                    </div>
                    <div
                      *ngIf="file.showNote"
                      class="btn btn-primary me-auto"
                      (click)="
                        openRequestedFile(
                          file.selectedExtension,
                          file.projectFileTypeID,
                          periodID!,
                          null,
                          true
                        )
                      "
                    >
                      {{ "BUTTONS.REQUEST_WITH_NOTES" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </mdb-accordion-item>
          <mdb-accordion-item>
            <ng-template mdbAccordionItemHeader>
              {{ "DATA_COLLECTED.KIS" | translate }}
              <span class="badge h6 ms-2 bg-primary my-auto">
                {{ requesKISFiles.length }}
              </span>
            </ng-template>
            <ng-template mdbAccordionItemBody class="d-flex flex-column">
              <div
                class="w-100 d-flex flex-column justify-content-center align-items-center py-3"
                *ngIf="requesKISFiles?.length == 0"
              >
                <div class="mb-1" style="height: 4rem; width: 4rem">
                  <img
                    src="../../../../assets/images/logo.png"
                    class="w-100 h-100"
                    style="object-fit: contain"
                  />
                </div>
                <div>
                  <h5 class="mt-1 my-auto">
                    {{ "No files to request" | translate }}
                  </h5>
                </div>
              </div>
              <div
                class="d-flex flex-column my-3 py-2 rounded mx-3"
                *ngFor="let file of requesKISFiles; index as i"
                [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
              >
                <div class="d-flex flex-column mx-2 w-100">
                  <h5 class="mx-1">{{ file.name }}</h5>
                  <h6 class="mx-1">{{ file.description }}</h6>
                  <div class="d-flex flex-row">
                    <div
                      class="d-flex flex-row"
                      *ngFor="let ext of file.extensions"
                    >
                      <i
                        class="bi h6 mx-1 pointer"
                        (click)="selectFileTypeHHS(i, ext)"
                        [ngClass]="
                          file.selectedExtension === ext
                            ? 'bi-check-square-fill'
                            : 'bi-square'
                        "
                      ></i>
                      <h6 class="mx-1">.{{ ext }}</h6>
                    </div>
                  </div>
                  <div class="d-flex flex-row mt-2">
                    <div
                      class="btn btn-primary me-2"
                      (click)="
                        openRequestedFile(
                          file.selectedExtension,
                          file.projectFileTypeID,
                          periodID!,
                          null,
                          false
                        )
                      "
                    >
                      {{ "BUTTONS.REQUEST" | translate }}
                    </div>
                    <div
                      *ngIf="file.showNote"
                      class="btn btn-primary me-auto"
                      (click)="
                        openRequestedFile(
                          file.selectedExtension,
                          file.projectFileTypeID,
                          periodID!,
                          null,
                          true
                        )
                      "
                    >
                      {{ "BUTTONS.REQUEST_WITH_NOTES" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </mdb-accordion-item>
        </mdb-accordion>
      </div>
    </div>
  </div>
</div>
