<div class="modal-header">
  {{ titolo }}
</div>
<div class="modal-body">
  <div
    class="d-flex align-items-center py-2"
    style="cursor: pointer"
    *ngFor="let item of subGoals"
    (click)="addRef(item.value)"
  >
    <i
      class="me-2 text-primary"
      [ngClass]="
        refIDs.includes(item.value) ? 'bi-check-square-fill' : 'bi-square'
      "
    ></i>
    <h6>{{ item.label }}</h6>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.BACK" | translate }}
  </div>
  <div class="btn btn-success" (click)="save()">
    {{ "BUTTONS.SAVE" | translate }}
  </div>
</div>
