import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { Country } from 'src/app/auth/models/country.model';

@Component({
  selector: 'country-page',
  templateUrl: './country-page.component.html',
  styleUrls: ['./country-page.component.scss'],
})
export class CountryPageComponent implements OnInit, OnDestroy {
  langSub?: Subscription;
  country?: Country;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.apiCall();
    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.apiCall();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  apiCall() {
    const countryID = this.route.snapshot?.paramMap.get('id') || '';
    this.api.getCountry(countryID).subscribe((country) => {
      this.country = country as Country;
    });
  }
}
