import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'modal-delete-country',
  templateUrl: './modal-delete-country.component.html',
  styleUrls: ['./modal-delete-country.component.scss'],
})
export class ModalDeleteCountryComponent implements OnInit {
  countryName?: string;
  constructor(
    public modalRef: MdbModalRef<ModalDeleteCountryComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}
}
