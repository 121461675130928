<div class="d-flex flex-column py-2 px-2 pointer" (click)="openVarietiesList()">
  <h6 class="mb-2">{{ subGoal }}</h6>
  <div class="rounded bg-light w-100 background-bar position-relative">
    <div
      class="rounded bg-success bar"
      [ngStyle]="{
        width: result?.value ? result?.value + '%' : placeholderValue + '%'
      }"
    ></div>
    <div
      class="position-absolute d-flex justify-content-center align-items-center w-100 top-0 bottom-0"
      style="height: 1.75rem"
    >
      <div class="text-primary px-2 bg-white rounded">
        {{ result?.label }}
      </div>
    </div>
  </div>
  <hr class="my-2" />
</div>
