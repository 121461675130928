import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'modal-new-project',
  templateUrl: './modal-new-project.component.html',
  styleUrls: ['./modal-new-project.component.scss'],
})
export class ModalNewProjectComponent implements OnInit {
  currentCountry?: string;
  projectForm?: UntypedFormGroup;

  showDisclaimer: boolean = true;

  loading: boolean = false;
  importText?: string;

  typeOptions: any = [
    { value: 1, label: 'Crop', disabled: false, ref: 'CROPS' },
    { value: 2, label: 'Livestock', disabled: false, ref: 'LIVESTOCK' },
    { value: 2, label: 'Aquaculture', disabled: true, ref: 'AQUATIC' },
  ];

  itemLabel?: string;
  displayItemsList?: any[];

  countries?: Select[];

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalNewProjectComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.api.getCountries().subscribe((value) => {
      this.countries = value as Select[];
    });

    this.projectForm = new UntypedFormGroup({
      title: new UntypedFormControl(null, [Validators.required]),
      type: new UntypedFormControl(null, [Validators.required]),
      itemsList: new UntypedFormControl(null, [Validators.required]),
      countryVarieties: new UntypedFormControl(null),
      purpose: new UntypedFormControl(null, [Validators.required]),
    });

    this.type?.valueChanges.subscribe((value) => {
      if (value == 1) this.importText = 'VARIETIES';
      if (value == 2) this.importText = 'BREEDS';
    });
  }

  getTypeList(event: any) {
    const ref = this.typeOptions[Number(event.value) - 1].ref;
    switch (ref) {
      case 'CROPS':
        this.itemLabel = 'Crops:';
        this.api.getFamiliesProject(ref).subscribe((value) => {
          this.displayItemsList = value as any[];
        });
        break;
      case 'LIVESTOCK':
        this.itemLabel = 'Livestocks:';
        this.api.getFamiliesProject(ref).subscribe((value) => {
          this.displayItemsList = value as any[];
        });
        break;
      default:
        return;
    }
  }

  createProject() {
    if (this.projectForm?.valid && !this.showDisclaimer) {
      this.loading = false;
      const ref = this.typeOptions[Number(this.type?.value) - 1].ref;
      const body = {
        countryID: this.currentCountry,
        countryIDs: this.countryVarieties?.value,
        refIDs: this.itemsList?.value,
        source: 'SITE',
        title: this.title?.value,
        typeOf: ref,
        purpose: this.purpose?.value,
      };
      this.api.createProject(body).subscribe(
        () => {
          this.loading = false;
          this.modalRef.close(true);
        },
        (error) => {
          this.loading = false;
        }
      );
    } else {
      this.projectForm?.markAllAsTouched();
    }
  }

  hideDisclaimer() {
    if (this.showDisclaimer) {
      this.showDisclaimer = false;
    }
  }

  get title() {
    return this.projectForm?.get('title');
  }
  get type() {
    return this.projectForm?.get('type');
  }
  get itemsList() {
    return this.projectForm?.get('itemsList');
  }
  get countryVarieties() {
    return this.projectForm?.get('countryVarieties');
  }
  get purpose() {
    return this.projectForm?.get('purpose');
  }
}
