<div class="modal-header d-flex justify-content-between">
  <h5>
    {{ title }}
  </h5>
  <div class="btn btn-primary" (click)="openLegend()">
    {{ "BUTTONS.VIEW_LEGEND" | translate }}
  </div>
</div>
<div class="modal-body" style="height: 85vh">
  <div class="d-flex flex-wrap mx-3center py-3">
    <div
      class="mx-2 mb-4"
      style="height: 40rem; width: 25rem; overflow: hidden; overflow-y: auto"
      [class.card]="!inter.empty"
      *ngFor="let inter of interventions; let last = last; let i = index"
    >
      <div class="d-flex flex-column" *ngIf="!inter.empty">
        <div
          class="d-flex align-items-center sticky-top"
          [style]="
            'color: black; min-height: 5rem; background-color:' + colors[i]
          "
        >
          <h5 class="m-3">{{ inter.text }}</h5>
        </div>
        <div class="d-flex flex-column mx-2 py-2">
          <div
            class="d-flex flex-row my-2 rounded"
            style="cursor: pointer"
            *ngFor="let item of inter.interventions"
            (click)="addRef(item.interventionID, inter.unselectable)"
            [ngStyle]="{
              'background-color':
                refIDs.includes(item.interventionID) ||
                inter.unselectable.includes(item.interventionID)
                  ? opacityColors[i]
                  : ''
            }"
          >
            <i
              class="mx-2"
              [ngClass]="
                item.checked
                  ? refIDs.includes(item.interventionID)
                    ? ' bi-check-square-fill'
                    : 'bi-square-fill'
                  : refIDs.includes(item.interventionID)
                  ? ' bi-check-square-fill'
                  : 'bi-square'
              "
              [ngStyle]="{ color: item.checked ? colors[i] : '' }"
            ></i>
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
  <div class="btn btn-success" (click)="addInterventions()">
    {{ "BUTTONS.ADD" | translate }}
  </div>
</div>
