import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subject } from 'rxjs/internal/Subject';
import { ApiService } from 'src/app/api.service';
import { Specie } from 'src/app/models/project';
import { Select } from 'src/app/models/select';
import { ModalNewRequestComponent } from '../../contact-us/modal-new-request/modal-new-request.component';
import { ModalAddGoalComponent } from '../../sites/modal-add-goal/modal-add-goal.component';

@Component({
  selector: 'modal-new-site',
  templateUrl: './modal-new-site.component.html',
  styleUrls: ['./modal-new-site.component.scss'],
})
export class ModalNewSiteComponent implements OnInit {
  projectID?: string;
  species?: Specie[];
  countryID?: string;
  ref?: string;

  refIDs: string[] = [];

  loading: boolean = false;

  newSiteForm?: UntypedFormGroup;

  showOtherZone: boolean = false;
  agroZonesList?: any[];

  constructor(
    private api: ApiService,
    private formBuilder: UntypedFormBuilder,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<ModalNewSiteComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.getAgroZones();

    this.newSiteForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
      province: new UntypedFormControl(null, [Validators.required]),
      agroZone: new UntypedFormControl(null, [Validators.required]),
      otherZone: new UntypedFormControl(null, []),
      minLat: new UntypedFormControl(null, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      maxLat: new UntypedFormControl(null, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      minLng: new UntypedFormControl(null, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      maxLng: new UntypedFormControl(null, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      minAlt: new UntypedFormControl(null, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      maxAlt: new UntypedFormControl(null, [
        Validators.pattern('-?[0-9]+.?[0-9]*$'),
      ]),
      formSpecies: this.formBuilder.array(this.populateForm()),
      siteGoals: this.formBuilder.array([]),
    });

    this.agroZone?.valueChanges.subscribe((value) => {
      this.otherZone?.setValidators(null);
      this.otherZone?.setErrors(null);
      if (value === 'OTHER') {
        this.otherZone?.setValidators([Validators.required]);
      }
    });
  }

  populateForm() {
    let array: any[] = [];
    this.species?.forEach((item: Specie) => {
      this.refIDs.push(item.speciesID);
      const specieGroup = this.formBuilder.group({
        refID: new UntypedFormControl(item.speciesID),
        familyName: new UntypedFormControl(item.species),
        coverage: new UntypedFormControl(null, [
          Validators.required,
          Validators.pattern('-?[0-9]+.?[0-9]*$'),
        ]),
        siteCoverageID: new UntypedFormControl(null),
        active: new UntypedFormControl(true),
      });
      specieGroup.get('active')?.valueChanges.subscribe((value) => {
        if (this.refIDs.includes(item.speciesID)) {
          const id = this.refIDs.indexOf(item.speciesID);
          this.refIDs.splice(id, 1);
          specieGroup.get('coverage')?.setErrors(null);
          specieGroup.get('coverage')?.setValidators(null);
        } else {
          this.refIDs.push(item.speciesID);
          specieGroup
            .get('coverage')
            ?.addValidators([
              Validators.required,
              Validators.pattern('-?[0-9]+.?[0-9]*$'),
            ]);
          specieGroup.get('coverage')?.updateValueAndValidity();
        }
      });
      array.push(specieGroup);
    });
    return array;
  }

  getAgroZones() {
    if (this.countryID)
      this.api.getAgroZones(this.countryID).subscribe((value) => {
        const list = value as any[];
        if (list.length === 0) {
          this.agroZonesList = [{ label: 'Other', value: 'OTHER' }];
        } else {
          this.agroZonesList = value as any[];
          this.agroZonesList.push({ label: 'Other', value: 'OTHER' });
        }
      });
  }

  createNewSite() {
    if (this.newSiteForm?.valid) {
      this.loading = true;
      const body = {
        agroEcoZoneId: this.agroZone?.value,
        agroEcoZoneOther: this.otherZone?.value,
        maxAlt: this.maxAlt?.value,
        minAlt: this.minAlt?.value,
        minLat: this.minLat?.value,
        maxLat: this.maxLat?.value,
        maxLng: this.maxLng?.value,
        minLng: this.minLng?.value,
        name: this.name?.value,
        projectID: this.projectID,
        province: this.province?.value,
        siteID: 'add',
        coverages: this.formSpecies.value,
        goals: this.siteGoals.value,
        refIDs: this.refIDs,
      };

      this.api.updateSite(body).subscribe(
        () => {
          this.loading = false;
          this.modalRef.close(true);
        },
        (error) => {
          this.loading = false;
        }
      );
    } else {
      this.newSiteForm?.markAllAsTouched();
    }
  }

  openAddGoal() {
    const modalRef = this.modalService.open(ModalAddGoalComponent, {
      data: {
        species: this.species,
        ref: this.ref,
        refIDs: this.refIDs,
      },
      modalClass: 'modal-dialog-centered',
    });

    modalRef.onClose.subscribe((value) => {
      if (!value) return;
      const control = this.formBuilder.group({
        goalID: new UntypedFormControl(value.goalID),
        goalLabel: new UntypedFormControl(value.goalLabel),
        subgoalID: new UntypedFormControl(value.subgoalID),
        subgoalLabel: new UntypedFormControl(value.subgoalLabel),
        families: new UntypedFormControl(value.familyIDs),
        familyLabels: new UntypedFormControl(value.familyLabels),
      });
      this.siteGoals.push(control);
    });
  }

  removeGoal(index: number) {
    this.siteGoals.removeAt(index);
  }

  openOtherZone(ref: Select) {
    if (ref.value === 'OTHER') this.showOtherZone = true;
  }

  get name() {
    return this.newSiteForm?.get('name');
  }

  get province() {
    return this.newSiteForm?.get('province');
  }

  get agroZone() {
    return this.newSiteForm?.get('agroZone');
  }

  get otherZone() {
    return this.newSiteForm?.get('otherZone');
  }

  get minLat() {
    return this.newSiteForm?.get('minLat');
  }
  get maxLat() {
    return this.newSiteForm?.get('maxLat');
  }
  get minLng() {
    return this.newSiteForm?.get('minLng');
  }
  get maxLng() {
    return this.newSiteForm?.get('maxLng');
  }
  get minAlt() {
    return this.newSiteForm?.get('minAlt');
  }
  get maxAlt() {
    return this.newSiteForm?.get('maxAlt');
  }

  get formSpecies(): UntypedFormArray {
    return this.newSiteForm?.get('formSpecies') as UntypedFormArray;
  }
  get siteGoals(): UntypedFormArray {
    return this.newSiteForm?.get('siteGoals') as UntypedFormArray;
  }
}
