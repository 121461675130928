<div class="row mt-3 mb-5 mx-2 gap-4">
  <database-list-card
    *ngFor="let item of list"
    class="col-3 m-0 p-0 hover"
    style="cursor: pointer"
    [image]="item.imageUrl"
    [name]="getName(item)"
    [isReady]="item.projectReady"
    (click)="goToItem(item)"
  ></database-list-card>
  <div
    class="position-relative d-flex align-items-center rounded-5 shadow-4 col-6 border-primary"
    style="height: 8.5rem; border: 3px solid"
    *ngIf="list?.length == 0 && loaded"
  >
    <div class="d-flex flex-row align-items-center mx-2">
      <i class="h1 my-auto bi bi-search mx-2"></i>
      <h3 class="h4 my-auto mx-2">{{ "VARIETY.ANYTHING" | translate }}</h3>
    </div>
  </div>
</div>
