<div class="d-flex flex-column my-2">
  <div class="d-flex flex-column mt-2">
    <h3 class="mt-2">{{ "DASHBOARD.RECENT_WA" | translate }}</h3>
    <div class="row gap-3 mx-2">
      <div
        class="position-relative card mb-2 d-flex flex-row align-items-center hover-card"
        style="height: 10rem; width: 25rem; overflow: hidden"
        *ngFor="let area of latestWorkingAreas"
        (click)="openWorkingArea(area.workID)"
      >
        <div
          class="position-absolute bottom-0 right-0 w-100 h-100 d-flex justify-content-end"
        >
          <img
            class="mt-auto mb-1 me-1"
            style="
              opacity: 0.25;
              height: 7.25rem;
              width: 7.25rem;
              transform: translate(0.5rem, 1.5rem);
            "
            [src]="'/assets/images/' + switchCardIcon(area.ref)"
          />
        </div>
        <div class="my-auto" style="width: 6rem; height: 6rem">
          <img
            class="w-100 h-100"
            style="object-fit: contain"
            [src]="area.imageUrl"
          />
        </div>
        <div class="d-flex flex-column ms-3">
          <h4 class="mb-0">{{ area.familyName }}</h4>
          <h5 class="mb-1">
            {{ "DASHBOARD.IN" | translate }} {{ area.country }}
          </h5>
          <h6>
            {{ area.countVarieties }}
            {{ getNumberOf(area.countVarieties, area.ref) | translate }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
