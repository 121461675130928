import { Component, Input, OnInit } from '@angular/core';
import { SubTitle } from 'chart.js';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ModalPeriodDataAnalysisComponent } from 'src/app/modals/modal-period-data-analysis/modal-period-data-analysis.component';

@Component({
  selector: 'development-benefit-card',
  templateUrl: './development-benefit-card.component.html',
  styleUrls: ['./development-benefit-card.component.scss'],
})
export class DevelopmentBenefitCardComponent implements OnInit {
  @Input() last?: boolean;
  @Input() i?: number;
  @Input() benefit?: any;

  constructor() {}

  ngOnInit(): void {}
}
