import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';

export interface ShareUser {
  name: string;
  surname: string;
  email: string;
  role: string;
  userID: string;
}

@Component({
  selector: 'modal-share-project',
  templateUrl: './modal-share-project.component.html',
  styleUrls: ['./modal-share-project.component.scss'],
})
export class ModalShareProjectComponent implements OnInit {
  findUserForm?: UntypedFormGroup;
  roleForm?: UntypedFormGroup;
  loading: boolean = false;
  projectId?: string;

  userToAdd?: ShareUser;

  selectedUser: number = 0;

  error?: string | null;

  roles = [
    {
      value: 'VIEWER',
      label: 'SHARE.VIEWER',
    },
    {
      value: 'DATA_ENTRY',
      label: 'SHARE.DATA_ENTRY',
    },
    {
      value: 'SITE_MANAGER',
      label: 'SHARE.SITE_MANAGER',
    },
    {
      value: 'PROJECT_MANAGER',
      label: 'SHARE.PROJECT_MANAGER',
    },
  ];

  constructor(
    public modalRef: MdbModalRef<ModalShareProjectComponent>,
    private api: ApiService,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.findUserForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.email]),
      uuid: new UntypedFormControl(null),
    });

    this.roleForm = new UntypedFormGroup({
      role: new UntypedFormControl(null),
      addActivity: new UntypedFormControl(true),
      shareProject: new UntypedFormControl(false),
      editTitle: new UntypedFormControl(false),
      editSite: new UntypedFormControl(false),
      addSite: new UntypedFormControl(false),
    });

    this.role?.valueChanges.subscribe((value) => {
      if (value == null) return;
      this.assignRole(value);
    });
  }

  get email() {
    return this.findUserForm?.get('email');
  }
  get uuid() {
    return this.findUserForm?.get('uuid');
  }

  get role() {
    return this.roleForm?.get('role');
  }

  get addActivity() {
    return this.roleForm?.get('addActivity');
  }

  get shareProject() {
    return this.roleForm?.get('shareProject');
  }

  get editTitle() {
    return this.roleForm?.get('editTitle');
  }

  get editSite() {
    return this.roleForm?.get('editSite');
  }

  get addSite() {
    return this.roleForm?.get('addSite');
  }

  getUser() {
    this.loading = true;
    this.api.getUserToShare(this.email?.value, this.uuid?.value).subscribe(
      (user) => {
        this.userToAdd = user as ShareUser;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  changeSelectedUser(i: number) {
    this.selectedUser = i;
  }

  assignRole(role: string) {
    switch (role) {
      case 'VIEWER':
        if (this.userToAdd) this.userToAdd.role = role;
        this.addActivity?.setValue(false);
        this.shareProject?.setValue(false);
        this.editTitle?.setValue(false);
        this.editSite?.setValue(false);
        this.addSite?.setValue(false);
        break;
      case 'DATA_ENTRY':
        if (this.userToAdd) this.userToAdd.role = role;
        this.addActivity?.setValue(true);
        this.shareProject?.setValue(false);
        this.editTitle?.setValue(false);
        this.editSite?.setValue(false);
        this.addSite?.setValue(false);
        break;
      case 'SITE_MANAGER':
        if (this.userToAdd) this.userToAdd.role = role;
        this.addActivity?.setValue(true);
        this.shareProject?.setValue(false);
        this.editTitle?.setValue(false);
        this.editSite?.setValue(true);
        this.addSite?.setValue(true);
        break;
      case 'PROJECT_MANAGER':
        if (this.userToAdd) this.userToAdd.role = role;
        this.addActivity?.setValue(true);
        this.shareProject?.setValue(true);
        this.editTitle?.setValue(true);
        this.editSite?.setValue(true);
        this.addSite?.setValue(true);
        break;
      default:
        break;
    }
  }

  share() {
    this.error = null;
    this.api
      .shareProject(
        this.projectId || '',
        this.userToAdd?.userID || '',
        this.userToAdd?.name || '',
        this.userToAdd?.surname || '',
        this.userToAdd?.email || '',
        this.userToAdd?.role || '',
        this.addActivity?.value,
        this.shareProject?.value,
        this.editTitle?.value,
        this.editSite?.value,
        this.addSite?.value
      )
      .subscribe(
        () => {
          this.loading = false;
          this.modalRef.close(true);
        },
        (error) => {
          this.loading = false;
          if (error.status == 409) {
            this.error = 'PROJECTS.USER_ALREADY_INVITED';
          }
        }
      );
  }
}
