<div class="card col mb-3" style="overflow-y: auto; height: 40rem">
  <div class="mb-2 pb-0 sticky-top bg-white">
    <div
      class="d-flex flex-row mt-3 justify-content-between align-items-center w-100"
    >
      <h3 class="mx-3 my-auto">{{ "NAVIGATION.GALLERY" | translate }}</h3>
    </div>
    <div class="mx-2 mt-2 w-90 bg-primary" style="height: 2px"></div>
  </div>
  <div class="row gap-2 mx-2 py-2 justify-content-center">
    <div
      class="col-5 mb-2 mx-auto rounded shadow-3 p-0 hover-photo"
      style="height: 15rem; overflow: hidden"
      *ngFor="let image of images; index as i"
      (click)="openGallery(i)"
    >
      <img class="w-100 h-100" [src]="image.img" style="object-fit: cover" />
    </div>
    <div
      class="col-5 mb-2 bg-white mx-auto"
      style="height: 15rem"
      *ngIf="(images?.length || 2) % 2 != 0"
    ></div>
  </div>
</div>
