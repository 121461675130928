import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
  circle,
  LatLng,
  latLng,
  latLngBounds,
  Marker,
  marker,
  polygon,
  popup,
  tileLayer,
  Map,
  icon,
} from 'leaflet';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalEditActivityComponent } from 'src/app/modals/sites/modal-edit-activity/modal-edit-activity.component';

export interface Activity {
  activity: string;
  lat: number;
  lng: number;
  alt: number;
  uuid: string;
}

@Component({
  selector: 'project-map',
  templateUrl: './project-map.component.html',
  styleUrls: ['./project-map.component.scss'],
})
export class ProjectMapComponent implements OnInit {
  options = {
    layers: [tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')],
    zoom: 15.5,

    filter: ['grayScale'],
    center: latLng([0, 0]),
  };

  activities: Activity[] = [];

  markers: any[] = [];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {}

  mapReady(map: Map) {
    const id = this.route.parent?.snapshot.paramMap.get('id') as string;
    this.api.getActivitiesPoints(id).subscribe((data) => {
      this.activities = data as Activity[];
      const points: LatLng[] = [];
      this.activities.forEach((item: Activity, index: number) => {
        const m = marker([item.lat, item.lng], {
          icon: icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: '/assets/images/map-icons/' + item.activity + '.png',
          }),
        }).bindPopup(
          `<h6 id='marker-${index}' class="mx-auto">${item.uuid}</h6>`,
          {
            closeButton: false,
          }
        );
        // .on('popupopen', () => {
        //   const doc = document.querySelector(`#marker-${index}`);
        //   doc?.addEventListener('click', (event) => {
        //     const modalRef = this.modalService.open(

        //       ModalEditActivityComponent,
        //       {
        //         data: {
        //           activity: this.activities[index],
        //         },
        //       }
        //     );
        //     modalRef.onClose.subscribe((newActivity: Activity) => {
        //       if (newActivity) {
        //         this.activities[index] = newActivity;
        //       }
        //     });
        //   });
        // });

        const p = latLng(item.lat, item.lng);
        points.push(p);
        this.markers?.push(m);
      });
      const bounds = latLngBounds(points);
      map.fitBounds(bounds);
      // this.addEvents();
    });
  }
}
