import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { ModalDescriptorComponent } from 'src/app/modals/database/modal-descriptor/modal-descriptor.component';

@Component({
  selector: 'item-descriptors',
  templateUrl: './item-descriptors.component.html',
  styleUrls: ['./item-descriptors.component.scss'],
})
export class ItemDescriptorsComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  databaseType?: string;
  descriptors?: any[];
  loading: boolean = true;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: MdbModalService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getDatabaseType();
    this.getDescriptors();

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.getDescriptors();
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  getDatabaseType() {
    const path = this.router.url;
    this.databaseType = path.split('/')[3];
  }

  getDescriptors() {
    const id = this.route.parent?.snapshot.paramMap.get('id');
    if (id)
      switch (this.databaseType) {
        case 'c':
          this.api.getDescriptorsRef('CROPS', id).subscribe(
            (value) => {
              this.descriptors = value as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
          break;
        case 'l':
          this.api.getDescriptorsRef('LIVESTOCK', id).subscribe(
            (value) => {
              this.descriptors = value as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
          break;
        case 'a':
          this.api.getDescriptorsRef('AQUATICS', id).subscribe(
            (value) => {
              this.descriptors = value as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
          break;
        default:
          break;
      }
  }

  openDescriptor(id: string) {
    this.modalService.open(ModalDescriptorComponent, {
      containerClass: 'right',
      modalClass: 'modal-side modal-bottom-right',
      data: { descriptorID: id },
    });
  }
}
