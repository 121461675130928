<div class="d-flex flex-column w-100 mt-3">
  <div class="sticky-top bg-light">
    <div class="d-flex flex-row mt-3 align-items-center">
      <div style="height: 5rem; width: 5rem">
        <img
          class="w-100 h-100"
          style="object-fit: contain"
          src="../../../assets/images/logo.png"
        />
      </div>
      <h1 class="fw-bold mx-2 my-auto">
        {{ "NAVIGATION.NETWORK" | translate }}
      </h1>
    </div>
    <div class="row mt-3 mb-5 mx-2 gap-3">
      <div
        class="col d-flex bg-white card p-0 m-0"
        style="height: 60vh; overflow-y: auto"
      >
        <list-header
          class="sticky-top p-0 m-0"
          title="{{ 'NAVIGATION.CATEGORY' | translate }}"
        ></list-header>

        <div class="d-flex flex-column p-0 m-0">
          <div
            class="d-flex flex-row align-items-center py-2 rounded-2 mx-2"
            *ngFor="let type of types; let i = index"
            (click)="selectCategory(i)"
          >
            <div class="d-inline-flex flex-row align-items-centers">
              <div class="mx-1 my-auto" style="height: 1.5rem; width: 1.5rem">
                <img
                  *ngIf="type.refs.includes('CROPS')"
                  class="w-100 h-100"
                  style="object-fit: contain"
                  src="../../../../assets/images/crop.svg"
                />
              </div>
              <div class="mx-1 my-auto" style="height: 1.5rem; width: 1.5rem">
                <img
                  *ngIf="type.refs.includes('LIVESTOCK')"
                  class="w-100 h-100"
                  style="object-fit: contain"
                  src="../../../../assets/images/livestock.svg"
                />
              </div>
              <div class="mx-1 my-auto" style="height: 1.5rem; width: 1.5rem">
                <img
                  *ngIf="type.refs.includes('AQUATICS')"
                  class="w-100 h-100"
                  style="object-fit: contain"
                  src="../../../../assets/images/aquaculture.svg"
                />
              </div>
            </div>
            <div
              class="d-flex flex-row justify-content-between rounded-2 w-100 py-2"
              [ngClass]="type?.selected ? 'bg-secondary text-white' : 'hover'"
            >
              <h5 class="px-3 py-2 my-auto">
                {{ type.nameLang }}
              </h5>

              <i class="bi bi-caret-right-fill h5 px-3 py-2 my-auto"></i>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col d-flex bg-white card"
        style="height: 60vh; overflow-y: auto"
      >
        <list-header
          class="sticky-top p-0 m-0"
          title="{{ 'NAVIGATION.SUB_CATEGORY' | translate }}"
        ></list-header>

        <database-table-placeholder
          *ngIf="subTypes?.length == 0"
          class="h-100"
          image="assets/images/logo.png"
          text="DATABASE.SELECT_A_GOAL"
        ></database-table-placeholder>

        <div class="d-flex flex-column p-0 m-0">
          <div
            class="d-flex flex-row justify-content-between align-items-center py-2 px-1 rounded-2 mx-2 rounded"
            *ngFor="let type of subTypes; let i = index"
            [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
          >
            <h5 class="px-3 py-2 my-auto">
              {{ type.nameLang }}
            </h5>
            <div class="d-inline-flex flex-row align-items-centers">
              <div
                class="mx-1 my-auto"
                style="height: 1.5rem; width: 1.5rem"
                *ngIf="type.refs.includes('CROPS')"
              >
                <img
                  class="w-100 h-100"
                  style="object-fit: contain"
                  src="../../../../assets/images/crop.svg"
                />
              </div>
              <div
                class="mx-1 my-auto"
                style="height: 1.5rem; width: 1.5rem"
                *ngIf="type.refs.includes('LIVESTOCK')"
              >
                <img
                  class="w-100 h-100"
                  style="object-fit: contain"
                  src="../../../../assets/images/livestock.svg"
                />
              </div>
              <div
                class="mx-1 my-auto"
                style="height: 1.5rem; width: 1.5rem"
                *ngIf="type.refs.includes('AQUATICS')"
              >
                <img
                  class="w-100 h-100"
                  style="object-fit: contain"
                  src="../../../../assets/images/aquaculture.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
