<div
  class="d-flex flex-column justify-content-center align-items-center"
  routerLink="{{ routerLink }}"
  style="cursor: pointer"
>
  <h1
    class="h5 mx-2 pt-2 mb-0 animate-200 hover-list"
    style="white-space: nowrap"
    routerLinkActive="list-active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    {{ name | translate }}
  </h1>
  <div
    class="p-0 m-1 mx-auto animate-200 pointer-not-active"
    style="height: 2px"
    routerLinkActive="pointer-active"
    [routerLinkActiveOptions]="{ exact: true }"
  ></div>
</div>
