<div class="modal-header bg-secondary text-white w-100">
  <h5 class="modal-title mx-1 my-auto">
    {{ descriptor?.descriptorLang }}
  </h5>
  <i
    class="bi bi-x-square-fill h4 my-auto py-1"
    style="cursor: pointer"
    (click)="modalRef.close()"
  ></i>
</div>
<div class="modal-body w-100">
  <div class="d-flex justify-content-center mb-3">
    <h4>{{ "ANSWER_TYPE." + descriptor?.answerType | translate }}</h4>
  </div>
  <div class="d-flex flex-row justify-content-center align-items-center">
    <img
      [src]="descriptor?.thumbUrl"
      alt=""
      style="height: 150px; width: 150px"
      *ngIf="descriptor?.thumbUrl"
    />
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 150px; width: 150px"
      *ngIf="!descriptor?.thumbUrl"
    >
      <i
        class="bi bi-image-fill text-secondary px-3"
        style="font-size: 9rem; opacity: 0.7"
      ></i>
    </div>
    <div
      class="w-100 card shadow-0 bg-secondary text-white mx-2"
      style="height: 200px; overflow-y: auto"
      [ngSwitch]="descriptor?.answerType"
    >
      <p class="h5 m-2" *ngSwitchCase="'TEXT'">
        {{ "DATABASE.INPUT_TEXT" | translate }}
      </p>
      <p class="h5 m-2" *ngSwitchCase="'TIME'">
        {{ "DATABASE.INPUT_TIME" | translate }}
      </p>
      <p class="h5 m-2" *ngSwitchCase="'BOOLEAN'">
        {{ "DATABASE.INPUT_BOOL" | translate }}
      </p>
      <p class="h5 m-2" *ngSwitchCase="'TIME_ONLY_MIN'">
        {{ "DATABASE.INPUT_MINUTES" | translate }}
      </p>
      <p class="h5 m-2" *ngSwitchCase="'NUMBER'">
        <em *ngIf="descriptor?.measureLang">
          {{ "DATABASE.MEASURED_BY" | translate }} {{ descriptor?.measureLang }}
        </em>
        <em *ngIf="!descriptor?.measureLang">Pure number</em>
      </p>

      <ul class="pt-2" *ngSwitchCase="'MULTIPLE'">
        <li *ngFor="let choice of descriptor?.multiValueAnswer">
          <h6 class="h5">
            {{ choice.valueLang }}
          </h6>
        </li>
      </ul>
      <ul class="pt-2" *ngSwitchCase="'MULTIPLE_LIST'">
        <li *ngFor="let choice of descriptor?.multiValueAnswer">
          {{ choice.valueLang }}
        </li>
      </ul>
      <ul class="pt-2" *ngSwitchCase="'MULTIPLE_LIST_SUSCEPTIBILITY'">
        <li *ngFor="let choice of descriptor?.multiValueAnswer">
          {{ choice.valueLang }}
        </li>
      </ul>
      <ul class="pt-2" *ngSwitchCase="'MULTIPLE_LIST_CONTENT'">
        <li *ngFor="let choice of descriptor?.multiValueAnswer">
          {{ choice.valueLang }}
        </li>
      </ul>
    </div>
  </div>
</div>
