<div class="moda-content">
  <div class="modal-header">
    <h4>{{ script?.titleLang }}</h4>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-column">
      <span class="mb-0 font-weight-bold">
        {{ "FILE_ANALYSIS.DESCRIPTION" | translate }}:
      </span>
      <div
        class="border-primary rounded p-2 mb-2"
        style="border: solid 1.5px; max-height: 10rem; overflow-y: scroll"
      >
        {{ script?.descriptionLang }}
      </div>
      <span class="font-weight-bold"
        >{{ "FILE_ANALYSIS.REQUIRED_OTS" | translate }}:</span
      >
      <ul>
        <li *ngFor="let ot of script?.outputTablesLang">{{ ot }}</li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" (click)="modalRef.close()">
      {{ "BUTTONS.CLOSE" | translate }}
    </div>
    <div class="btn btn-success">{{ "BUTTONS.DOWNLOAD" | translate }}</div>
  </div>
</div>
