import { LocationStrategy } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Image } from 'src/app/models/image';

@Component({
  selector: 'modal-show-variety-images',
  templateUrl: './modal-show-variety-images.component.html',
  styleUrls: ['./modal-show-variety-images.component.scss'],
})
export class ModalShowVarietyImagesComponent implements OnInit {
  @Input() refID!: string;
  @Input() projectID!: string;
  images: Image[] = [];

  constructor(
    public modalRef: MdbModalRef<ModalShowVarietyImagesComponent>,
    private api: ApiService,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.api
      .getImagesVarieties(this.projectID, this.refID)
      .subscribe((value) => {
        this.images = value as Image[];
      });
  }
}
