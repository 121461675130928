import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { MaterialTypeClassification } from 'src/app/models/material-type-classification';

@Component({
  selector: 'gmp-material-type-classification',
  templateUrl: './gmp-material-type-classification.component.html',
  styleUrls: ['./gmp-material-type-classification.component.scss'],
})
export class GmpMaterialTypeClassificationComponent
  implements OnInit, OnDestroy
{
  langSub?: Subscription;
  crop?: MaterialTypeClassification[];
  livestock?: MaterialTypeClassification[];
  aquaculture?: MaterialTypeClassification[];

  constructor(private api: ApiService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.apiCalls();
    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.apiCalls();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  apiCalls() {
    this.api.getMaterialTypeClassification('CROPS').subscribe((value) => {
      this.crop = value as MaterialTypeClassification[];
    });
    this.api.getMaterialTypeClassification('LIVESTOCK').subscribe((value) => {
      this.livestock = value as MaterialTypeClassification[];
    });
    this.api.getMaterialTypeClassification('AQUATICS').subscribe((value) => {
      this.aquaculture = value as MaterialTypeClassification[];
    });
  }
}
