<div class="popconfirm-popover shadow-4" style="z-index: 100000">
  <div class="popconfirm">
    <p class="popconfirm-message d-flex flex-column">
      <span class="popconfirm-message-text">
        Are you sure you want to restore this Variety?
      </span>
      <span class="popconfirm-message-text text-danger">
        This action can not be reverted. all the changes will be lose.
      </span>
    </p>
    <div class="popconfirm-buttons-container">
      <div (click)="popconfirmRef.close()" class="btn btn-primary">Cancel</div>
      <div (click)="popconfirmRef.confirm()" class="btn btn-danger">
        Restore
      </div>
    </div>
  </div>
</div>
