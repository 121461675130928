<div class="modal-header">
  <div class="d-flex flex-row">
    <h4 class="h3 mb-0">{{ descriptor.traitsComplete.trait }}</h4>
  </div>
</div>
<div class="modal-body">
  <div class="d-flex flex-column">
    <div
      class="row mx-1 rounded py-1 px-2 text-center mb-2"
      *ngFor="let answer of answers; index as i"
      [ngClass]="i % 2 == 0 ? 'bg-light' : 'bg-transparent'"
    >
      <h5 class="col-3 my-auto">{{ "DATABASE." + answer.age | translate }}</h5>
      <h5 class="col-3 my-auto">
        {{ "DATABASE." + answer.gender | translate }}
      </h5>
      <div class="col-6 d-flex flex-row justify-content-between">
        <h5 class="my-auto">{{ answer.value }}</h5>
        <i
          class="my-auto me-2 bi bi-chat-left-text-fill"
          *ngIf="answer.note"
          [mdbTooltip]="answer.note"
          placement="left"
        ></i>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
</div>
