<h5 class="my-3">
  <u> Folders </u>
</h5>
<div class="d-flex flex-row mx-1 my-1">
  <folder
    name="{{ folder.nameLang }}"
    (click)="go(folder.fullPath)"
    *ngFor="let folder of folders"
  ></folder>
</div>
<div class="d-flex align-items-center">
  <h5 class="my-3">Files</h5>
  <span class="badge bg-primary h6 my-auto mx-1">{{ files?.length }}</span>
</div>
<div class="row gap-3 mx-1 my-1">
  <file
    class="col-2"
    name="{{ file.title }}"
    href="{{ file.path }}"
    *ngFor="let file of files"
  ></file>
</div>
