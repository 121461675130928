<div class="mt-3 mb-5 mx-2 gap-3 h-100 w-100">
  <div
    class="d-flex flex-column bg-white card p-0 m-0"
    style="height: 50vh; overflow-y: auto"
  >
    <list-header
      class="sticky-top p-0 m-0"
      title="{{ 'NAVIGATION.VARIETY_CLASSIFICATION' | translate }}"
    ></list-header>
    <div
      class="d-flex flex-column rounded mb-2 mx-4 px-2 py-2"
      *ngFor="let item of data; index as i"
      [ngClass]="i % 2 == 0 ? '' : 'bg-light'"
    >
      <h4>
        {{ item.categoryLang }}
      </h4>
      <div class="mx-4" *ngFor="let sub of item.subCategories">
        <h5>- {{ sub.categoryLang }}</h5>
        <ul>
          <li *ngFor="let desc of sub.descriptors">
            {{ desc.descriptorLang }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
