import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Descriptor } from 'src/app/models/descriptor';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'modal-answer-descriptor-benefit',
  templateUrl: './modal-answer-descriptor-benefit.component.html',
  styleUrls: ['./modal-answer-descriptor-benefit.component.scss'],
})
export class ModalAnswerDescriptorBenefitComponent implements OnInit {
  answerForm?: FormGroup;
  refID?: string;
  familyID?: string;
  periodID?: string;
  projectDevelopmentBenefitID?: string;
  descriptor?: Select;

  loading: boolean = true;

  answer?: Descriptor;

  minMaxBetween = 'MIN';

  constructor(
    public modalRef: MdbModalRef<ModalAnswerDescriptorBenefitComponent>,
    private api: ApiService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.answerForm = new FormGroup({
      answerBoolean: new FormControl(null),
      answerMin: new FormControl(null),
      answerMax: new FormControl(null),
      answerIntensity: this.formBuilder.array([]),
    });

    this.api.getDescriptor(this.descriptor!.value).subscribe((value) => {
      this.answer = value as Descriptor;
      this.loading = false;
      if (
        this.answer?.answerType == 'MULTIPLE_LIST_SUSCEPTIBILITY' ||
        this.answer?.answerType == 'MULTIPLE_LIST_CONTENT' ||
        this.answer?.answerType == 'MULTIPLE' ||
        this.answer?.answerType == 'MULTIPLE_LIST'
      ) {
        this.populateArray();
      }
    });
  }

  populateArray() {
    this.answer?.multiValueAnswer.forEach((v) => {
      const control = this.formBuilder.group({
        value: new FormControl(v.uuid),
        label: new FormControl(v.valueLang),
        selected: new FormControl(null),
        intensity: new FormControl(null),
      });
      this.answerIntensity.push(control);
    });
  }

  save() {
    this.api
      .addDescriptorDevelopmentBenefit(
        this.projectDevelopmentBenefitID!,
        this.descriptor!.value,
        this.minMaxBetween,
        this.answerForm?.value
      )
      .subscribe((value) => {
        this.modalRef.close(value);
      });
  }

  get answerIntensity() {
    return this.answerForm?.get('answerIntensity') as FormArray;
  }
}
