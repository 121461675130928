<div
  class="d-flex flex-column mx-5 mt-2 px-4"
  style="height: 100vh"
  *ngIf="ref != 'c'"
>
  <work-in-progress></work-in-progress>
</div>

<div class="d-flex w-100 justify-content-center h-100 mt-4" *ngIf="loading">
  <loading [glass]="false"></loading>
</div>

<div class="d-flex flex-column mx-5 mt-2 px-4" *ngIf="ref == 'c' && !loading">
  <div class="d-flex mb-4" [formGroup]="filterForm!">
    <ng-select
      class="w-50"
      placeholder="{{ 'PLACEHOLDER.SITE' | translate }}"
      formControlName="siteID"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
    >
      <ng-option *ngFor="let select of sites" [value]="select.value">
        {{ select.label }}
      </ng-option>
    </ng-select>
  </div>
  <div
    style="
      max-width: 75rem;
      position: relative;
      overflow: scroll;
      min-height: 40rem;
      max-height: 40rem;
    "
  >
    <table *ngIf="columnHeaders.length != 0 && rows.length != 0 && !loading">
      <thead>
        <tr *ngIf="columnHeaders">
          <th
            class="bg-light border-primary rounded-5 shadow-2"
            style="z-index: 20"
          ></th>
          <!-- DESCRIPTOR CLICKED -->
          <th
            class="pointer px-2 py-0 rounded-5 shadow-2 bg-white text-primary"
            style="max-height: 4rem; min-height: 4rem"
            *ngFor="let column of columnHeaders; index as columnIndex"
            (click)="selectColumn(columnIndex)"
          >
            <div
              class="py-2 text-center"
              style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
            >
              {{ "DIVERSITY_TABLE." + column | translate }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="rows">
        <tr *ngFor="let row of rows; index as rowIndex">
          <th
            class="rounded-5 shadow-2 pointer"
            style="z-index: 10"
            [ngClass]="
              selectedRow == rowIndex
                ? 'cell-selected text-white'
                : 'bg-white text-primary'
            "
          >
            <span class="my-auto">{{ row.name }}</span>
          </th>
          <!-- CELL CLICKED -->
          <td
            class="text-center pointer m-1 rounded-5 shadow-2"
            [ngClass]="getBackgroundCell(rowIndex, answerIndex)"
            *ngFor="let answer of row.answers; index as answerIndex"
          >
            <div
              class="d-flex align-items-center"
              style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
            >
              <span class="my-auto w-100 text-center">
                {{ answer.name }}
              </span>
              <i
                class="bi bi-chat-left-text-fill my-auto"
                *ngIf="answer.hasNote"
              ></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
