<div class="modal-header">
  {{ "ME.SELECT_DESCRIPTOR" | translate }}
</div>
<div
  class="modal-body d-flex flex-column"
  *ngFor="let item of descriptors; index as i"
  [formGroup]="selectForm!"
>
  <div class="form-check">
    <input
      mdbRadio
      class="form-check-input"
      type="radio"
      name="select"
      [id]="i"
      [value]="item.projectDevelopmentBenefitGoalID"
      formControlName="select"
    />
    <label class="form-check-label" [for]="i">{{ item.descriptor }} </label>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.BACK" | translate }}
  </div>
  <div class="btn btn-success" (click)="selectDescriptor()">
    {{ "BUTTONS.SELECT" | translate }}
  </div>
</div>
