<div class="border-success card" style="border: 3px solid">
  <div class="modal-header">
    <div class="d-flex flex-row modal-title">
      <i class="bi bi-share-fill h4 my-auto"></i>
      <h4 class="modal-title my-auto mx-2">
        {{ "PROJECTS.SHARE_PROJECT" | translate }}
      </h4>
    </div>
  </div>
  <div class="modal-body">
    <form>
      <div class="d-flex w-100 justify-content-between align-items-center">
        <h5 class="my-auto">{{ "SHARE.FIND_USER" | translate }}</h5>
        <form class="d-flex align-items-center" [formGroup]="findUserForm!">
          <div>
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'PLACEHOLDER.EMAIL' | translate }}"
              formControlName="email"
            />
          </div>
          <h6 class="mx-3 my-auto">{{ "SHARE.OR" | translate }}</h6>
          <div>
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'PLACEHOLDER.USER_ID' | translate }}"
              formControlName="uuid"
            />
          </div>
        </form>
        <div class="btn btn-primary" (click)="getUser()">
          {{ "BUTTONS.SEARCH" | translate }}
        </div>
      </div>
    </form>
    <div class="bg-primary w-75 my-3 mx-auto" style="height: 2px"></div>
    <div class="d-flex flex-column mx-3" *ngIf="userToAdd">
      <!-- NG FOR -->
      <div
        class="d-flex justify-content-between p-2 my-1 animate-250"
        [ngClass]="'border border-primary rounded bg-light shadow-3'"
      >
        <div class="h6 my-auto">{{ userToAdd.name }}</div>
        <div class="h6 my-auto">{{ userToAdd.surname }}</div>
        <div class="h6 my-auto">{{ userToAdd.email }}</div>
        <div class="h6 my-auto fw-bold">
          {{ "SHARE." + (userToAdd.role || "NO_ROLE") | translate }}
        </div>
      </div>
    </div>
    <div
      class="bg-primary w-75 my-3 mx-auto"
      style="height: 2px"
      *ngIf="userToAdd"
    ></div>
    <form *ngIf="userToAdd" [formGroup]="roleForm!">
      <div class="d-flex flex-column align-items-center">
        <div class="d-flex justify-content-center mb-2">
          <i
            class="bi bi-info-circle-fill h5 pointer my-auto"
            [mdbPopover]="$any(popLegend)"
            placement="left"
          ></i>
          <h4 class="mx-2 my-auto">{{ "SHARE.ASSIGN_ROLE" | translate }}</h4>
        </div>

        <div class="w-50">
          <ng-select
            class="w-100"
            placeholder="Select Role"
            formControlName="role"
            [searchable]="false"
            [clearable]="false"
          >
            <ng-option *ngFor="let role of roles" [value]="role.value">
              {{ role.label | translate }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="w-50 mx-auto px-2 mt-2">
        <div
          class="d-flex"
          style="width: 15rem"
          *ngIf="role?.value === 'PROJECT_MANAGER'"
        >
          <div class="form-check my-auto">
            <input
              mdbCheckbox
              class="form-check-input"
              type="checkbox"
              [value]="shareProject?.value"
              formControlName="shareProject"
            />
          </div>
          <div class="h6 my-auto mx-2">
            {{ "SHARE.SHARE_PROJECT" | translate }}
          </div>
        </div>

        <div
          class="d-flex"
          style="width: 15rem"
          *ngIf="role?.value === 'PROJECT_MANAGER'"
        >
          <div class="form-check my-auto">
            <input
              mdbCheckbox
              class="form-check-input"
              type="checkbox"
              [value]="editTitle?.value"
              formControlName="editTitle"
            />
          </div>
          <div class="h6 my-auto mx-2">
            {{ "SHARE.EDIT_TITLE" | translate }}
          </div>
        </div>

        <div
          class="d-flex"
          style="width: 15rem"
          *ngIf="
            role?.value === 'PROJECT_MANAGER' || role?.value === 'SITE_MANAGER'
          "
        >
          <div class="form-check my-auto">
            <input
              mdbCheckbox
              class="form-check-input"
              type="checkbox"
              [value]="editSite?.value"
              formControlName="editSite"
            />
          </div>
          <div class="h6 my-auto mx-2">
            {{ "SHARE.EDIT_SITES" | translate }}
          </div>
        </div>

        <div
          class="d-flex"
          style="width: 15rem"
          *ngIf="
            role?.value === 'PROJECT_MANAGER' || role?.value === 'SITE_MANAGER'
          "
        >
          <div class="form-check my-auto">
            <input
              mdbCheckbox
              class="form-check-input"
              type="checkbox"
              [value]="addSite?.value"
              formControlName="addSite"
            />
          </div>
          <div class="h6 my-auto mx-2">{{ "SHARE.ADD_SITES" | translate }}</div>
        </div>
      </div>
    </form>
    <loading class="mask" *ngIf="loading"></loading>
  </div>

  <div class="modal-footer d-flex flex-row">
    <h6 class="text-danger" *ngIf="error">
      {{ error | translate }}
    </h6>
    <button
      type="button"
      class="btn btn-primary"
      (click)="modalRef.close(false)"
    >
      {{ "BUTTONS.CLOSE" | translate }}
    </button>
    <button type="button" class="btn btn-success" (click)="share()">
      {{ "BUTTONS.SHARE" | translate }}
    </button>
  </div>
</div>

<ng-template #popLegend>
  <ul class="list-group">
    <li class="list-group-item waves-light tab-sort">
      <h6 class="mb-0 fw-bold">
        <i class="bi bi-circle-fill small"></i> {{ "SHARE.VIEWER" | translate }}
      </h6>
      {{ "SHARE.VIEWER_LEGEND" | translate }}
    </li>
    <li class="list-group-item waves-light tab-sort">
      <h6 class="mb-0">
        <i class="bi bi-circle-fill small"></i>
        {{ "SHARE.DATA_ENTRY" | translate }}
      </h6>
      {{ "SHARE.DATA_ENTRY_LEGEND" | translate }}
    </li>
    <li class="list-group-item waves-light tab-sort">
      <h6 class="mb-0">
        <i class="bi bi-circle-fill small"></i>
        {{ "SHARE.SITE_MANAGER" | translate }}
      </h6>
      {{ "SHARE.SITE_MANAGER_LEGEND" | translate }}
    </li>
    <li class="list-group-item waves-light tab-sort">
      <h6 class="mb-0">
        <i class="bi bi-circle-fill small"></i>
        {{ "SHARE.PROJECT_MANAGER" | translate }}
      </h6>
      {{ "SHARE.PROJECT_MANAGER_LEGEND" | translate }}
    </li>
  </ul>
</ng-template>
