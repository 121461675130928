import { LocationStrategy } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbDatepickerComponent } from 'mdb-angular-ui-kit/datepicker';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Period } from 'src/app/models/period';

@Component({
  selector: 'modal-close-period',
  templateUrl: './modal-close-period.component.html',
  styleUrls: ['./modal-close-period.component.scss'],
})
export class ModalClosePeriodComponent implements OnInit {
  @ViewChild('datepicker') datePicker?: MdbDatepickerComponent;
  period?: Period;
  dateForm?: UntypedFormGroup;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalClosePeriodComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    const parsedDate = this.period?.endDate
      ? new Date(this.period?.endDate!)
      : null;

    this.dateForm = new UntypedFormGroup({
      date: new UntypedFormControl(parsedDate, [Validators.required]),
    });
  }

  openCalendar() {
    if (this.datePicker) this.datePicker?.toggle();
  }

  closePeriod() {
    if (this.dateForm?.invalid) return;
    const endDate: Date = this.date?.value;
    this.api
      .closePeriod(this.period?.periodID!, endDate.getTime())
      .subscribe((value) => {
        this.modalRef.close(value as Period[]);
      });
  }

  get date() {
    return this.dateForm?.get('date');
  }
}
