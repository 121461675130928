import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'modal-add-subgoal-benefit',
  templateUrl: './modal-add-subgoal-benefit.component.html',
  styleUrls: ['./modal-add-subgoal-benefit.component.scss'],
})
export class ModalAddSubgoalBenefitComponent implements OnInit {
  titolo?: string;
  refID?: string;
  familyID?: string;
  periodID?: string;

  subGoals?: Select[];

  refIDs: string[] = [];

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalAddSubgoalBenefitComponent>
  ) {}

  ngOnInit(): void {
    this.api
      .getAddSubgoalDevelopmentBenefit(
        this.refID!,
        this.familyID!,
        this.periodID!
      )
      .subscribe((value) => {
        this.subGoals = value as Select[];
      });
  }

  addRef(value: string) {
    if (this.refIDs.includes(value)) {
      const index = this.refIDs.indexOf(value);
      this.refIDs.splice(index, 1);
    } else {
      this.refIDs.push(value);
    }
  }

  save() {
    this.api
      .addSubgoalsDevelopmentBenefit(this.refIDs, this.familyID!, this.periodID!)
      .subscribe((value) => {
        this.modalRef.close(true);
      });
  }
}
