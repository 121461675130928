import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { DashboardSideBarComponent } from './components/dashboard-side-bar/dashbaord-side-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { MdbModule } from '../mdb/mdb.module';
import { CounterComponent } from './components/counter/counter.component';
import { PartnersPageComponent } from './partners-page/partners-page.component';
import { AppRoutingModule } from '../app-routing.module';
import { DashboardV2PageComponent } from './dashboard-v2-page/dashboard-v2-page.component';
import { StatsSideBarComponent } from './components/stats-side-bar/stats-side-bar.component';
import { CalendarSideBarComponent } from './components/calendar-side-bar/calendar-side-bar.component';
import { LayoutModule } from '../layout/layout.module';
import { DashboardPartnerComponent } from './components/dashboard-partner/dashboard-partner.component';
import { StatisticsComponent } from './components/statistics/statistics.component';

@NgModule({
  declarations: [
    DashboardPageComponent,
    DashboardSideBarComponent,
    CounterComponent,
    PartnersPageComponent,
    DashboardV2PageComponent,
    StatsSideBarComponent,
    CalendarSideBarComponent,
    DashboardPartnerComponent,
    StatisticsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MdbModule,
    AppRoutingModule,
    LayoutModule,
  ],
  exports: [
    DashboardPageComponent,
    PartnersPageComponent,
    DashboardV2PageComponent,
  ],
})
export class DashboardModule {}
