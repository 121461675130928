<div class="modal-body" style="background-color: white">
  <div class="d-flex flex-column" style="overflow-y: scroll; height: 80vh">
    <mdb-lightbox class="lightbox">
      <div class="row">
        <div
          class="col-lg-4 mb-2"
          *ngFor="let item of images"
          style="height: 20rem"
        >
          <img
            mdbLightboxItem
            [src]="item.imageUrl"
            [img]="item.imageUrl"
            class="w-100"
          />
        </div>
      </div>
    </mdb-lightbox>
    <div
      class="h-100 d-flex justify-content-center"
      style="height: 100%; width: 100%"
      *ngIf="images.length == 0"
    >
      <h1>{{ "PROJECT.NO_PHOTOS_YET" | translate }}</h1>
    </div>
  </div>
</div>
<div class="modal-footer" style="background-color: white; height: 10vh">
  <button type="button" class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </button>
</div>
