<div
  class="row justify-content-between card mx-2 my-4"
  style="height: 37rem; overflow: hidden"
>
  <div class="col-4 d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center mt-2">
      <h4 class="my-auto">{{ "CONTACT.REQUESTS" | translate }}</h4>
      <div class="btn btn-success my-auto" (click)="openNewRequest()">
        {{ "CONTACT.NEW_REQUEST" | translate }}
      </div>
    </div>
    <div class="w-100 bg-primary mt-2" style="height: 2px"></div>
    <div
      class="py-2 d-flex flex-column"
      style="height: 30rem; overflow-y: scroll"
    >
      <div
        class="py-2 px-2 mb-2 rounded d-flex align-items-center justify-content-between"
        *ngFor="let request of requests; index as i"
        [ngClass]="request.selected ? 'text-white bg-secondary' : 'hover'"
        (click)="onRequestSelect(i, request.supportID)"
      >
        <div class="d-flex">
          <i class="bi h5 me-2 my-auto" [ngClass]="switchIcon(request)"></i>
          <div class="d-flex flex-column">
            <h5 class="my-auto">{{ request.title }}</h5>
            <span>
              {{ request.date | date: "short" }}
            </span>
          </div>
        </div>
        <i class="bi bi-caret-right-fill h5 my-auto"></i>
      </div>
    </div>
    <div class="w-100 bg-primary mb-2" style="height: 2px"></div>
    <div class="mx-auto">
      <page-controller
        [pageable]="pageable"
        (pageClick)="onPageClick($event)"
      ></page-controller>
    </div>
  </div>
  <div class="col-7 d-flex flex-column justify-content-end h-100 pb-2">
    <div
      class="d-flex flex-column-reverse h100 py-4"
      style="overflow-y: scroll"
      [hidden]="selectedRefID == null"
      #chatDiv
    >
      <div
        class="rounded px-3 text-white py-2 mt-2"
        style="width: 20rem"
        *ngFor="let message of conversation"
        [ngClass]="
          message.own ? 'ms-auto bg-success ' : 'me-auto bg-secondary '
        "
      >
        {{ message.msg }}
      </div>
    </div>

    <div
      class="h-100 d-flex flex-column justify-content-center"
      *ngIf="selectedRefID == null"
    >
      <disclaimer text="CONTACT.DISCLAIMER"></disclaimer>
    </div>

    <div class="w-100 bg-primary mb-2" style="height: 2px"></div>
    <div
      class="d-flex w-100 justify-content-between"
      [formGroup]="messageForm!"
    >
      <div class="w-100 mx-2">
        <input
          type="text"
          placeholder="{{ 'PLACEHOLDER.TEXT_MESSAGE' | translate }}"
          class="form-control"
          formControlName="message"
        />
      </div>
      <div
        class="btn btn-success my-auto mx-2"
        [ngClass]="isDisabled ? 'disabled' : ''"
        (click)="sendMessage()"
      >
        {{ "BUTTONS.SEND" | translate }}
      </div>
    </div>
  </div>
</div>
