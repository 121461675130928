<div
  [ngClass]="
    i == 0
      ? 'rounded-top border-bottom border-3 border-primary'
      : last
      ? 'rounded-bottom'
      : 'border-bottom'
  "
  style="max-height: 15rem; min-height: 15rem"
  class="w-100 bg-white shadow d-flex flex-column"
>
  <div class="d-flex flex-row py-2 px-4 justify-content-between">
    <h5>{{ "PLACEHOLDER.PERIOD" | translate }} - {{ period.count }}</h5>
    <h5>{{ (period.to | date) ?? ". . ." }}</h5>
  </div>
  <div class="w-100" style="min-height: 8.5rem; overflow-y: auto">
    <div
      class="d-flex flex-row mx-2 rounded align-items-center"
      *ngFor="let item of period.graphs; index as i"
      [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
    >
      <diversity-benefit-row
        class="w-100 py-1"
        [graph]="item"
        [periodID]="period.periodID"
        [familyID]="familyID"
      ></diversity-benefit-row>
    </div>
  </div>

  <div class="d-flex flex-row py-2 px-4 justify-content-end">
    <h5>{{ (period.from | date) ?? "..." }}</h5>
  </div>
</div>
