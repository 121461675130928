import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Role } from 'src/app/auth/models/role.model';
import { AppState } from 'src/app/auth/reducer';
import { ModalDeleteCountryComponent } from 'src/app/modals/settings/modal-delete-country/modal-delete-country.component';
import * as actions from '../../../auth/store/auth.action';

@Component({
  selector: 'change-country-card',
  templateUrl: './change-country-card.component.html',
  styleUrls: ['./change-country-card.component.scss'],
})
export class ChangeCountryCardComponent implements OnInit {
  @Input() role?: Role;
  @Input() isSelected: boolean = false;

  @Output() onRoleClick: EventEmitter<string> = new EventEmitter();

  flagClass: string = '';

  onSelect() {
    this.onRoleClick.emit(this.role?.uuid);
  }

  constructor(
    private store: Store<AppState>,
    private api: ApiService,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {}

  openDeleteCountry() {
    const modalRef = this.modalService.open(ModalDeleteCountryComponent, {
      data: { countryName: this.role?.country?.shortName },
    });
    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.delete();
      }
    });
  }

  delete() {
    if (this.role?.uuid) {
      this.api.removeRole(this.role?.uuid).subscribe(() => {
        this.store.dispatch(new actions.GetUser());
      });
    }
  }
}
