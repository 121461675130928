import { Component, Input, OnInit } from '@angular/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalPeriodDataAnalysisComponent } from 'src/app/modals/modal-period-data-analysis/modal-period-data-analysis.component';
import { PieDataset } from '../graph-pie/graph-pie.component';

@Component({
  selector: 'graph-doughnut',
  templateUrl: './graph-doughnut.component.html',
  styleUrls: ['./graph-doughnut.component.scss'],
})
export class GraphDoughnutComponent implements OnInit {
  @Input() periodID?: string;
  @Input() familyID?: string;
  @Input() siteID?: string;

  @Input() title?: string;
  @Input() path?: string;

  @Input() hoverText?: string;

  @Input() showModal?: boolean;

  dataset?: PieDataset[];

  datasetPlaceholder: PieDataset[] = [
    {
      data: [2112, 2343, 2545],
      backgroundColor: [
        '#123425',
        'rgba(77, 182, 172, 0.5)',
        'rgba(66, 133, 244, 0.5)',
      ],
      labels: ['Monday', 'Tuesday', 'Wednesday'],
      varietiesByData: new Map(),
    },
  ];

  constructor(private api: ApiService, private modalService: MdbModalService) {}

  options = {
    legend: {
      display: false,
    },
  };

  ngOnInit(): void {
    this.randomize();

    this.api
      .getStatsForPeriod(
        this.path!,
        this.periodID || '',
        this.familyID || '',
        this.siteID
      )
      .subscribe((value) => {
        this.dataset = [value as PieDataset];
      });
  }

  randomNumber(): number {
    return Math.floor(Math.random() * 100);
  }

  openModal(event: any) {
    if (this.showModal == false) return;
    if (event.elements[0] == undefined) return;
    const index: number = event.elements[0].index;
    if (this.dataset![0].varietiesByData[0] == null) return;
    this.modalService.open(ModalPeriodDataAnalysisComponent, {
      data: {
        title: this.title,
        subtitle: this.dataset![0].labels[index],
        varieties: this.dataset![0].varietiesByData[index],
      },
    });
  }

  randomize() {
    if (this.dataset == undefined) {
      this.datasetPlaceholder = [
        {
          data: [this.randomNumber(), this.randomNumber(), this.randomNumber()],
          backgroundColor: [
            'rgba(63, 81, 181, 0.5)',
            'rgba(77, 182, 172, 0.5)',
            'rgba(66, 133, 244, 0.5)',
          ],
          labels: [],
          varietiesByData: new Map(),
        },
      ];
      setTimeout(() => this.randomize(), 1000);
    }
  }
}
