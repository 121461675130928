import { Component, Input, OnInit } from '@angular/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalDeleteProjectComponent } from 'src/app/modals/projects/modal-delete-project/modal-delete-project.component';
import {
  ProjectPageHelper,
  ProjectPageListener,
} from 'src/app/models/project-page-helper';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
  @Input() project?: Project;

  projectPageHelper: ProjectPageHelper = new ProjectPageHelper();

  constructor(
    private modalService: MdbModalService,
    private api: ApiService,
    private projectPageListener: ProjectPageListener
  ) {}

  ngOnInit(): void {}

  shrinkString(string: string | undefined) {
    if (string?.length && string.length <= 60) return string;
    return string?.substring(0, 57) + ' ...';
  }

  deleteProject(project: Project | null) {
    const projectID = project?.projectID;
    const modalRef = this.modalService.open(ModalDeleteProjectComponent, {
      data: {
        projectID: projectID,
      },
      modalClass: 'modal-lg',
    });

    modalRef.onClose.subscribe((refresh: boolean) => {
      if (refresh) {
        this.projectPageHelper.projectRemoved = true;
        this.projectPageListener.setData(this.projectPageHelper);
      }
    });
  }

  getImage(): string {
    switch (this.project?.typeOf) {
      case 'CROPS':
        return '/assets/images/crop.svg';
      case 'LIVESTOCK':
        return '/assets/images/livestock.svg';
      case 'AQUATICS':
        return '/assets/images/aquaculture.svg';
      default:
        return '';
    }
  }

  getRefPath(): string {
    switch (this.project?.typeOf) {
      case 'CROPS':
        return 'c';
      case 'LIVESTOCK':
        return 'l';
      case 'AQUATICS':
        return 'a';
      default:
        return '';
    }
  }
}
