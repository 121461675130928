<div class="row gap-2 mx-auto justify-content-center">
  <div
    class="col-xl-3 col-lg-4 col-md-4 col-sm-6 btn"
    *ngFor="let lang of langs"
    [ngClass]="currentLang === lang ? 'btn-primary' : 'btn-secondary'"
    (click)="changeLang(lang)"
  >
    {{ lang }}
  </div>
</div>
