import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'table-sort-icon',
  templateUrl: './table-sort-icon.component.html',
  styleUrls: ['./table-sort-icon.component.scss'],
})
export class TableSortIconComponent implements OnInit {
  @Input() direction?: string;
  @Input() enable?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
