<div class="container-fluid p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-5">
      <div class="d-flex flex-row justify-content-between my-2">
        <div class="d-flex">
          <i class="bi bi-folder-fill h1"></i>
          <h1 class="fw-bold mx-2">
            {{ "PROJECTS.PROJECTS_IN" | translate }} {{ country }}
          </h1>
          <div
            class="btn btn-success mx-2 my-auto"
            (click)="createNewProject()"
          >
            {{ "PROJECTS.NEW" | translate }}
          </div>
        </div>

        <div
          class="btn btn-primary px-4 justify-content-between d-flex flex-row my-auto"
          (click)="openRequests()"
        >
          <i class="bi bi-send-plus-fill mx-1 h6 my-auto"></i>
          <span class="mx-2 my-auto h6">
            {{ "PROJECTS.REQUESTS" | translate }}
          </span>
          <span class="badge bg-white text-primary mx-1 my-auto">
            {{ requests?.length }}
          </span>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="w-100 h-100">
          <div class="row gap-3 my-2 pb-4">
            <project-card
              class="col-5"
              [project]="project"
              *ngFor="let project of projects"
            ></project-card>
          </div>
        </div>

        <mdb-sidenav-layout class="bg-light" [hidden]="hideRequests">
          <mdb-sidenav
            #sidenav="mdbSidenav"
            [right]="true"
            [width]="400"
            class="bg-light"
          >
            <div
              class="d-flex flex-column justify-content-between w-100 h-100 bg-light"
            >
              <div class="m-3 mb-0">
                <div class="d-flex flex-row">
                  <i class="bi bi-send-plus-fill h2 mx-1"></i>
                  <h2 class="mx-1">{{ "PROJECTS.REQUESTS" | translate }}:</h2>
                </div>
                <div class="bg-primary mx-1" style="height: 2px"></div>
              </div>

              <div class="h-100" style="overflow-y: auto">
                <div
                  class="card mx-4 my-2 p-3 d-flex d-flex-column justify-content-between"
                  style="height: 12rem"
                  *ngFor="let request of requests"
                >
                  <h4 [mdbTooltip]="request.title || ''">
                    {{ shrinkRequest(request.title) }}
                  </h4>
                  <h6>ID: {{ request.uuid }}</h6>
                  <h6>
                    {{ "PROJECTS.FROM" | translate }}: {{ request.sharedBy }}
                  </h6>
                  <h6>
                    {{ "USER.ROLE" | translate }}:
                    {{ "PROJECTS." + request.projectRole | translate }}
                  </h6>
                  <div class="d-flex justify-content-between">
                    <div
                      class="btn btn-danger"
                      (click)="openDeclinRequest(request.projectID || '')"
                    >
                      {{ "BUTTONS.DECLINE" | translate }}
                    </div>
                    <div
                      class="btn btn-success"
                      (click)="acceptRequest(request.projectID || '')"
                    >
                      {{ "BUTTONS.ACCEPT" | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="btn btn-primary mx-2 mb-2 mb-4"
                (click)="goToDeclinedRequests()"
              >
                {{ "PROJECTS.SEE_ALL_DECLINED" | translate }}
              </div>
            </div>
          </mdb-sidenav>
        </mdb-sidenav-layout>
      </div>
    </div>
  </div>
</div>
