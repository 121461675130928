<div class="d-flex flex-column w-100 mt-3">
  <div class="sticky-top bg-light" #stickyNav>
    <div class="d-flex flex-row mt-3 align-items-center">
      <div style="height: 5rem; width: 5rem">
        <img
          class="w-100 h-100"
          style="object-fit: contain"
          src="../../../assets/images/logo.png"
        />
      </div>
      <h1 class="fw-bold mx-2 my-auto">{{ "NAVIGATION.G_M_P" | translate }}</h1>
    </div>
    <div class="d-flex d-row py-0 my-0 mt-4 mb-2">
      <horizontal-list-button
        name="NAVIGATION.TYPES"
        routerLink="types"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.SECTORS"
        routerLink="sectors"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.DISTRIBUTION"
        routerLink="distribution"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.QUALITY_GUARANTEE"
        routerLink="quality-guarantee"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.MATERIAL_TYPE"
        routerLink="material-type-classification"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.REPRODUCTIVE_MATERIALS"
        routerLink="reproductive-materials"
      ></horizontal-list-button>
      <horizontal-list-button
        name="NAVIGATION.POINT_OF_SELLING"
        routerLink="types-of-point-of-selling"
      ></horizontal-list-button>
    </div>
    <div
      class="rounded-6"
      style="height: 3px; width: 82%; transition: 300ms ease-in"
    ></div>
  </div>
  <router-outlet></router-outlet>
</div>
