<div class="d-flex modal-body">
  <div class="w-50 mx-2" style="height: 70vh">
    <div class="d-flex flex-row align-items-center">
      <i class="bi bi-plus-square-fill h4 my-auto"></i>
      <h4 class="modal-title my-auto mx-2">{{ "SITE.ADD" | translate }}</h4>
    </div>
    <form [formGroup]="newSiteForm!">
      <div class="row my-2">
        <div class="col-6">
          <div class="d-flex">
            <h5 class="mb-1">{{ "SITE.NAME" | translate }}</h5>
            <span
              class="text-danger mx-3"
              *ngIf="name?.touched && name?.invalid"
            >
              {{ "REGISTER.REQUIRED" | translate }}
            </span>
          </div>
          <div>
            <input type="text" class="form-control" formControlName="name" />
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex">
            <h5 class="mb-1">{{ "SITE.STATE" | translate }}</h5>
            <span
              class="text-danger mx-2"
              *ngIf="province?.touched && province?.invalid"
            >
              {{ "REGISTER.REQUIRED" | translate }}
            </span>
          </div>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="province"
            />
          </div>
        </div>
      </div>

      <div class="row my-3">
        <div class="col-6">
          <div class="d-flex">
            <h5 class="mb-1">{{ "SITE.AGRO_ZONE" | translate }}</h5>
            <span
              class="text-danger mx-2"
              *ngIf="agroZone?.touched && agroZone?.invalid"
            >
              {{ "REGISTER.REQUIRED" | translate }}
            </span>
          </div>
          <div>
            <ng-select
              class="w-100"
              formControlName="agroZone"
              (change)="openOtherZone($event)"
              [items]="agroZonesList ?? []"
              bindValue="value"
              bindLabel="label"
            >
              <!-- <mdb-option
                *ngFor="let zone of agroZonesList"
                [value]="zone.value"
                >{{ zone.label }}</mdb-option
              > -->
            </ng-select>
          </div>
        </div>
        <div class="col-6" *ngIf="showOtherZone">
          <div class="d-flex">
            <h5 class="mb-1">{{ "SITE.OTHER_ZONE" | translate }}</h5>
            <span
              class="text-danger mx-2"
              *ngIf="otherZone?.touched && otherZone?.invalid"
            >
              {{ "REGISTER.REQUIRED" | translate }}
            </span>
          </div>
          <div>
            <input
              type="text"
              class="form-control"
              formControlName="otherZone"
            />
          </div>
        </div>
      </div>

      <div class="row justify-content-center pt-3">
        <div class="col-3 d-flex flex-column">
          <h5>{{ "SITE.LAT" | translate }}</h5>
          <div class="my-2">
            <span class="label">{{ "PLACEHOLDER.MIN" | translate }}</span>
            <input
              type="text"
              class="form-control"
              formControlName="minLat"
              placeholder="{{ 'PLACEHOLDER.MIN' | translate }}"
            />
          </div>
          <div class="my-2">
            <span class="label">{{ "PLACEHOLDER.MAX" | translate }}</span>
            <input
              type="text"
              class="form-control"
              formControlName="maxLat"
              placeholder="{{ 'PLACEHOLDER.MAX' | translate }}"
            />
          </div>
        </div>

        <div class="col-3 d-flex flex-column mx-4">
          <h5>{{ "SITE.LNG" | translate }}</h5>
          <div class="my-2">
            <span class="label">{{ "PLACEHOLDER.MIN" | translate }}</span>
            <input
              type="text"
              class="form-control"
              formControlName="minLng"
              placeholder="{{ 'PLACEHOLDER.MIN' | translate }}"
            />
          </div>
          <div class="my-2">
            <span class="label">{{ "PLACEHOLDER.MAX" | translate }}</span>
            <input
              type="text"
              class="form-control"
              formControlName="maxLng"
              placeholder="{{ 'PLACEHOLDER.MAX' | translate }}"
            />
          </div>
        </div>

        <div class="col-3 d-flex flex-column">
          <h5>{{ "SITE.ALT" | translate }}</h5>
          <div class="my-2">
            <span class="label">{{ "PLACEHOLDER.MIN" | translate }}</span>
            <input
              type="text"
              class="form-control"
              formControlName="minAlt"
              placeholder="{{ 'PLACEHOLDER.MIN' | translate }}"
            />
          </div>
          <div class="my-2">
            <span class="label">{{ "PLACEHOLDER.MAX" | translate }}</span>
            <input
              type="text"
              class="form-control"
              formControlName="maxAlt"
              placeholder="{{ 'PLACEHOLDER.MAX' | translate }}"
            />
          </div>
        </div>
      </div>
      <h5 class="mt-4 mb-2">{{ "SITE.TOTAL_AREA" | translate }}</h5>
      <div
        class="d-flex flex-wrap justify-content-center"
        formArrayName="formSpecies"
        style="overflow-y: auto; height: 30vh"
      >
        <div
          class="border-primary d-flex flex-column mx-3 align-items-center"
          *ngFor="let specie of formSpecies.controls; index as i"
        >
          <h5 class="pt-3">{{ specie.get("familyName")?.value }}</h5>
          <div
            class="d-flex flex-column align-items-center pb-3"
            [formGroupName]="i"
          >
            <div class="d-flex align-items-center">
              <div class="form-check form-switch">
                <input
                  mdbCheckbox
                  class="form-check-input"
                  type="checkbox"
                  id="active"
                  formControlName="active"
                />
              </div>
              <input
                type="number"
                class="form-control"
                formControlName="coverage"
              />
            </div>
            <span
              class="text-danger mx-2"
              *ngIf="specie?.touched && specie?.invalid"
            >
              {{ "REGISTER.REQUIRED" | translate }}
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="w-50 mx-2" style="height: 70vh; overflow-y: auto">
    <!-- <div class="d-flex flex-row align-items-center opacity-0">
      <i class="bi bi-plus-square-fill h4 my-auto"></i>
      <h4 class="modal-title my-auto mx-2">{{ "SITE.ADD" | translate }}</h4>
    </div> -->
    <form [formGroup]="newSiteForm!">
      <div
        class="d-flex flex-row justify-content-between align-items-center bg-white sticky-top py-1"
      >
        <h5 class="mt-4">{{ "SITE.ADD_GOALS" | translate }}</h5>
        <div class="btn btn-primary" (click)="openAddGoal()">Add Goal</div>
      </div>
      <div
        class="d-flex flex-column mx-2 justify-content-center py-2"
        formArrayName="siteGoals"
      >
        <div
          class="d-flex flex-column mb-2 border-bottom mx-2"
          style="border-bottom: solid 2px"
          *ngFor="let goal of siteGoals.controls; index as i"
          [formGroupName]="i"
        >
          <div class="d-flex flex-column pb-3">
            <h6>{{ goal.get("goalLabel")?.value }}</h6>
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-between w-100">
                <h5>{{ goal.get("subgoalLabel")?.value }}</h5>
                <i
                  class="pointer bi-trash2-fill text-danger h5"
                  (click)="removeGoal(i)"
                ></i>
              </div>
            </div>
            <h6>{{ goal.get("familyLabels")?.value.toString() }}</h6>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<loading class="mask" *ngIf="loading"></loading>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </button>
  <button type="button" class="btn btn-success" (click)="createNewSite()">
    {{ "PROJECTS.NEW_SITE" | translate }}
  </button>
</div>
