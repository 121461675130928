<div class="modal-header">{{ title }}</div>
<div class="modal-body d-flex flex-column">
  <div class="row mb-2" *ngFor="let item of answers; index as i">
    <div class="col-7">
      <strong> {{ "ME.I_COL_" + (i + 1) | translate }} </strong>:
    </div>
    <div class="col-5 d-flex flex-column" *ngIf="item.name">
      <span *ngFor="let split of item?.name.split('~')">
        {{ split }}
      </span>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
</div>
