import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { ModalGoalComponent } from 'src/app/modals/database/modal-goal/modal-goal.component';

@Component({
  selector: 'database-goals',
  templateUrl: './database-goals.component.html',
  styleUrls: ['./database-goals.component.scss'],
})
export class DatabaseGoalsComponent implements OnInit, OnDestroy {
  types: Array<any> = [];
  subTypes: Array<any> = [];
  langSub?: Subscription;

  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.getTypes();
    this.translate.onLangChange.subscribe(() => {
      this.getTypes();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  getTypes() {
    let databaseType: string = this.getCurrentLocation().toUpperCase();
    if (databaseType === 'CROP') databaseType = 'CROPS';
    if (databaseType === 'AQUACULTURE') databaseType = 'AQUATICS';

    this.api.getGoals(databaseType).subscribe((value) => {
      this.types = value as Array<any>;
    });
  }

  getCurrentLocation() {
    const path = this.router.url;
    return path.split('/')[3];
  }

  getImage() {
    const base: string = '../../../assets/images/';
    const location: string = this.getCurrentLocation();
    const image = base + location + '.svg';
    return image;
  }

  onSelectType(i: number) {
    this.types.filter((value) => {
      value.selected = false;
    });
    this.subTypes.filter((value) => {
      value.selected = false;
    });
    this.types[i].selected = true;
    this.subTypes = [];
    this.subTypes = this.types[i].subgoals;
  }

  openGoal(goal: any) {
    this.modalService.open(ModalGoalComponent, {
      modalClass: 'modal-lg',
      data: { subGoal: goal },
    });
  }
}
