import { LocationStrategy } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Image } from 'src/app/models/image';

@Component({
  selector: 'modal-image-gallery',
  templateUrl: './modal-image-gallery.component.html',
  styleUrls: ['./modal-image-gallery.component.scss'],
})
export class ModalImageGalleryComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.modalRef.close(false);
  }

  images: Array<Image> = [];
  position: number = 0;

  constructor(
    public modalRef: MdbModalRef<ModalImageGalleryComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}

  next() {
    if (this.position < this.images.length - 1) {
      this.position = this.position + 1;
    } else {
      this.position = 0;
    }
  }

  prev() {
    if (this.position > 0) {
      this.position = this.position - 1;
    } else {
      this.position = this.images.length - 1;
    }
  }

  getImage(): string {
    if (this.images && this.position) {
      return this.images[this.position].img;
    } else {
      return '';
    }
  }
}
