<div class="container-fluid p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-5">
      <div class="d-flex">
        <i class="bi bi-heart-fill h1"></i>
        <h1 class="fw-bold mx-2">{{ "NAVIGATION.PARTNERS" | translate }}</h1>
      </div>
      <div class="my-4" style="height: 7rem">
        <a href="https://www.agrobiodiversitypar.org/" target="_blank">
          <img
            class="w-100 h-100"
            style="object-fit: contain"
            src="assets/images/par.png"
            alt=""
          />
        </a>
      </div>
      <div class="row gap-5 justify-content-center">
        <div
          class="col-3 card select-hover"
          style="height: 10rem"
          *ngFor="let partner of partners"
          (click)="openLink(partner.link || '')"
        >
          <img
            class="w-100 h-100 p-3"
            style="object-fit: contain"
            [src]="'assets/images/partners/' + partner.img"
          />
        </div>
      </div>
      <div class="d-flex justify-content-center text-center mx-5 px-4 mt-5">
        <h6>
          {{ "VARIETY.PARTNER_DISCLAIMER" | translate }}
        </h6>
      </div>
    </div>
  </div>
</div>
