<div
  class="card d-flex flex-column rounded"
  style="overflow: hidden"
  [mdbTooltip]="hoverText || ''"
>
  <div class="bg-primary w-100 text-white">
    <h5 class="p-3 my-auto text-center">
      {{ title }}
    </h5>
  </div>
  <div
    class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center"
    style="border: solid 2px"
  >
    <canvas
      mdbChart
      [type]="'doughnut'"
      [datasets]="dataset == undefined ? datasetPlaceholder : dataset"
      [labels]="
        dataset == undefined ? datasetPlaceholder[0].labels : dataset[0].labels
      "
      (chartClick)="openModal($event)"
      [options]="options"
    ></canvas>
  </div>
</div>
