import { ContentObserver } from '@angular/cdk/observers';
import { LocationStrategy } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import {
  MdbStepChangeEvent,
  MdbStepperComponent,
} from 'mdb-angular-ui-kit/stepper';
import { ApiService } from 'src/app/api.service';
import { Specie } from 'src/app/models/project';
import { Variety } from 'src/app/models/variety';

@Component({
  selector: 'modal-add-variety',
  templateUrl: './modal-add-variety.component.html',
  styleUrls: ['./modal-add-variety.component.scss'],
})
export class ModalAddVarietyComponent implements OnInit {
  entity?: any;

  cropID?: string;
  ref?: string;

  infoForm?: FormGroup;

  varietyList?: any[];
  specieList?: any[];
  materials?: any[];

  loading: boolean = false;

  showSpecies: boolean = false;
  showVariety: boolean = false;
  showLocation: boolean = false;

  category?: any;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalAddVarietyComponent>,
    private location: LocationStrategy,
    private formBuilder: FormBuilder
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.infoForm = new FormGroup({
      refID: new FormControl(this.cropID, [Validators.required]),
      varietyName: new FormControl(this.entity?.name, [Validators.required]),
      species: new FormControl(this.entity?.speciesID, [Validators.required]),

      material: new FormControl(null),
      varietyType: new FormControl(this.entity?.materialCategoryID),
      hybrid: new FormControl(this.entity?.speciesHybridID),
      varieties: new FormControl(this.entity?.mixtureWith),
      state: new FormControl(this.entity?.evoPopProvince),
      city: new FormControl(this.entity?.evoPopCity),
      lat: new FormControl(this.entity?.evoPopLat),
      lng: new FormControl(this.entity?.evoPopLng),
      alt: new FormControl(this.entity?.evoPopAlt),
      subcategories: this.formBuilder.array([]),
    });

    // this.getMaterialClassification();
    this.getMaterialCategories();
    this.apiCall();

    // this.material?.valueChanges.subscribe((value: any) => {
    //   if (!value) return;
    //   console.log(value);
    //   this.varieties?.removeValidators(Validators.required);
    //   this.varieties?.updateValueAndValidity();
    //   this.hybrid?.removeValidators(Validators.required);
    //   this.hybrid?.updateValueAndValidity();
    //   this.setVarietyType(value);
    // });

    this.material?.valueChanges.subscribe((value: any) => {
      if (!value) return;
      this.showLocation = false;
      this.showSpecies = false;
      this.showVariety = false;
      this.subcategories.clear();
      this.category = value;
      this.varietyType?.setValue(value.materialTypeCategoryID);
      value.subCategories.forEach((element: any, index: number) => {
        const group = this.formBuilder.group({
          name: new FormControl(element.categoryLang),
          value: new FormControl(null),
        });

        group.get('value')?.valueChanges.subscribe((value: any) => {
          const desc = element.descriptors.filter(
            (e: any) => e.materialTypeDescriptorID == value
          )[0];

          if (!desc) return;

          this.showLocation = desc.needLocation;
          this.showSpecies = desc.needSpeciesList;
          this.showVariety = desc.needVarietyList;
        });

        const desc = element.descriptors.filter(
          (e: any) =>
            e.materialTypeDescriptorID ==
            this.entity?.materialDescriptorIDs[index]
        )[0];
        if (desc) {
          group.get('value')?.setValue(desc.materialTypeDescriptorID);
        }
        this.subcategories.push(group);
      });
    });
  }

  apiCall() {
    if (this.cropID) {
      this.api.getSpeciesSelect(this.cropID).subscribe((value) => {
        this.specieList = value as Specie[];
      });
      this.api.getVarietySelect(this.cropID).subscribe((value) => {
        this.varietyList = value as Variety[];
      });
    }
  }

  setMaterial() {
    const materialCategoryID = this.varietyType?.value;
    const mat = this.materials?.filter(
      (e) => e.materialCategoryID === materialCategoryID
    );
    if (mat) {
      this.material?.setValue(mat[0]);
    }
  }

  // getMaterialClassification() {
  //   this.api
  //     .getMaterialTypeClassification(this.ref ?? '')
  //     .subscribe((value) => {
  //       this.materials = value as any[];
  //       if (this.entity) {
  //         const filter = this.materials?.filter(
  //           (e) => e.materialTypeClassificationID == this.entity.varietyTypeID
  //         );
  //         if (filter) {
  //           this.material?.setValue(filter[0]);
  //         }
  //       }
  //     });
  // }

  getMaterialCategories() {
    this.api.getMaterialTypeCategories(this.ref ?? '').subscribe((value) => {
      this.materials = value as any[];
      if (this.entity) {
        const filter = this.materials?.filter((e) => {
          return e.materialTypeCategoryID == this.varietyType?.value;
        });
        if (filter) {
          this.material?.setValue(filter[0]);
        }
      }
    });
  }

  addVariety() {
    if (this.infoForm?.invalid) {
      this.infoForm?.markAllAsTouched();
      return;
    }
    const materialDescriptorsIDs = this.subcategories.controls.map(
      (e) => e.value.value
    );
    const body = {
      varietyID: this.entity?.varietyID,
      refID: this.cropID,
      name: this.varietyName?.value,
      species: this.species?.value,
      materialCategoryID: this.varietyType?.value,
      materialDescriptorIDs: materialDescriptorsIDs,
      hybridWith: this.hybrid?.value,
      hybrid: this.hybrid?.value != null,
      mixtureWith: this.varieties?.value,
      evoPopProvince: this.state?.value,
      evoPopCity: this.city?.value,
      evoPopLat: this.lat?.value,
      evoPopLng: this.lng?.value,
      evoPopAlt: this.alt?.value,
    };
    this.api.addVariety(body).subscribe(() => {
      this.modalRef.close(true);
    });
  }

  get subcategories() {
    return this.infoForm?.get('subcategories') as FormArray;
  }

  get varietyName() {
    return this.infoForm?.get('varietyName');
  }
  get species() {
    return this.infoForm?.get('species');
  }

  get hybrid() {
    return this.infoForm?.get('hybrid');
  }

  get material() {
    return this.infoForm?.get('material');
  }
  get varietyType() {
    return this.infoForm?.get('varietyType');
  }

  get varieties() {
    return this.infoForm?.get('varieties');
  }

  get state() {
    return this.infoForm?.get('state');
  }
  get city() {
    return this.infoForm?.get('city');
  }
  get lat() {
    return this.infoForm?.get('lat');
  }
  get lng() {
    return this.infoForm?.get('lng');
  }
  get alt() {
    return this.infoForm?.get('alt');
  }
}
