import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'item-list',
  templateUrl: './item-varieties.component.html',
  styleUrls: ['./item-varieties.component.scss'],
})
export class ItemVarietiesComponent implements OnInit, OnDestroy {
  filterForm?: UntypedFormGroup;

  lang$?: Subscription;
  databaseType?: string;
  header: string = '';
  filterPlaceholder: string = '';
  cropID: string = '';
  livestockID: string = '';
  aquacultureID: string = '';
  loading: boolean = true;

  varieties: Array<any> = [];

  inputFilter: string | null = null;
  countryID: string | null = null;

  countries?: Select[];

  options = [
    { label: '', value: '' },
    { label: '', value: '' },
  ];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null),
      country: new UntypedFormControl(null),
    });

    this.getDatabaseType();

    this.getVarieties();
    this.getCountries();

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.getVarieties();
      this.getCountries();
    });

    this.filter?.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.inputFilter = value;
      this.getCountries();
      this.getVarieties();
    });

    this.country?.valueChanges.subscribe((value) => {
      this.countryID = value;
      this.getCountries();
      this.getVarieties();
    });
  }

  get filter() {
    return this.filterForm?.get('filter');
  }

  get country() {
    return this.filterForm?.get('country');
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  getDatabaseType() {
    const path = this.router.url;
    this.databaseType = path.split('/')[3];
  }

  getCountries() {
    this.loading = true;
    switch (this.databaseType) {
      case 'c':
        this.api
          .getCountryByCrop(this.cropID, this.inputFilter, this.countryID)
          .subscribe(
            (countries) => {
              this.countries = countries as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
        break;
      case 'l':
        this.api
          .getCountryByLivestock(
            this.livestockID,
            this.inputFilter,
            this.countryID
          )
          .subscribe(
            (countries) => {
              this.countries = countries as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
        break;
      default:
        break;
    }
  }

  getVarieties() {
    switch (this.databaseType) {
      case 'c':
        this.cropID = this.route.parent?.snapshot.paramMap.get('id')!;
        this.header = 'ITEM.VARIETIES';
        this.filterPlaceholder = 'PLACEHOLDER.FILTER_BY_VARIETY';
        this.api
          .getVarieties(this.cropID, this.inputFilter, this.countryID)
          .subscribe(
            (value) => {
              this.varieties = value as Array<any>;
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
        break;
      case 'l':
        this.livestockID = this.route.parent?.snapshot.paramMap.get('id')!;
        this.header = 'ITEM.BREEDS';
        this.filterPlaceholder = 'PLACEHOLDER.FILTER_BY_BREED';
        this.api
          .getBreeds(this.livestockID, this.inputFilter, this.countryID)
          .subscribe(
            (value) => {
              this.varieties = value as Array<any>;
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
        break;
      case 'a':
        this.aquacultureID = this.route.parent?.snapshot.paramMap.get('id')!;
        this.header = 'ITEM.FARMED_TYPES';
        this.api.getBreeds(this.aquacultureID).subscribe(
          (value) => {
            this.varieties = value as Array<any>;
            this.loading = false;
          },
          (error) => {
            this.loading = false;
          }
        );
        break;
      default:
        break;
    }
  }

  selectCountry(countryID: string) {
    this.countryID = countryID;
    this.getCountries();
    this.getVarieties();
  }

  openVariety(variety: any) {
    switch (this.databaseType) {
      case 'c':
        this.router.navigateByUrl(
          '/u/database/v/' + this.cropID + '-' + variety.varietyID
        );
        break;
      case 'l':
        this.router.navigateByUrl(
          '/u/database/b/' + this.livestockID + '-' + variety.breedID
        );
        break;
      case 'a':
        this.router.navigateByUrl(
          '/u/database/f/' + this.aquacultureID + '-' + variety.farmedTypedID
        );
        break;
      default:
        break;
    }
  }
}
