import { Action } from '@ngrx/store';
import { User } from '../models/user.model';

export enum AuthActionTypes {
  LOGIN_REQUESTED = '[Auth] LOGIN Requested',
  LOGIN_SUCCESS = '[Auth] LOGIN Success',
  LOGGED_IN = '[Auth] LOGGED In',
  LOGIN_FAILED = '[Auth] LOGIN Failed',

  LOGOUT_REQUESTED = '[Auth] LOGOUT requested',
  LOGOUT_COMPLETED = '[Auth] LOGOUT completed',

  UPDATE_PROFILE = '[Auth] Update profile',
  UPDATE_PROFILE_SUCCESS = '[Auth] Update profile success',

  UPDATE_ONLINE_STATUS = '[Auth] Update online status',
  SAVE_USER = '[Auth] Save user',
  GET_USER = '[Auth] GET User',
  CHECK_USER_ROLE = '[Auth] Check user role',
  AUTH_ERROR = '[Auth] Error',

  IS_LOADING = '[General] Is Loading',
}

export class LoginRequested implements Action {
  readonly type = AuthActionTypes.LOGIN_REQUESTED;

  constructor(public payload: { email: string; password: string }) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LOGIN_SUCCESS;

  constructor(public payload: { user: User }) {}
}

export class LoggedIn implements Action {
  readonly type = AuthActionTypes.LOGGED_IN;

  constructor(public payload: { user: User }) {}
}

export class LoginFailed implements Action {
  readonly type = AuthActionTypes.LOGIN_FAILED;
}

export class LogoutRequested implements Action {
  readonly type = AuthActionTypes.LOGOUT_REQUESTED;

  constructor(public payload: { user: User }) {}
}

export class LogoutCompleted implements Action {
  readonly type = AuthActionTypes.LOGOUT_COMPLETED;
}

export class UpdateProfile implements Action {
  readonly type = AuthActionTypes.UPDATE_PROFILE;

  constructor(
    public payload: {
      name: string;
      surname: string;
      phone: string;
      email: string;
      country: string;
      birthYear: number;
      gender: string;
    }
  ) {}
}

export class UpdateProfileSuccess implements Action {
  readonly type = AuthActionTypes.UPDATE_PROFILE_SUCCESS;

  constructor(public payload: { user: User }) {}
}

export class UpdateOnlineStatus implements Action {
  readonly type = AuthActionTypes.UPDATE_ONLINE_STATUS;

  constructor(public payload: { uid: string; status: boolean }) {}
}

export class GetUser implements Action {
  readonly type = AuthActionTypes.GET_USER;
}

export class SaveUser implements Action {
  readonly type = AuthActionTypes.SAVE_USER;

  constructor(public payload: { user: User }) {}
}

export class AuthError implements Action {
  readonly type = AuthActionTypes.AUTH_ERROR;

  constructor(public payload: { status: any }) {}
}

export class CheckUserRole implements Action {
  readonly type = AuthActionTypes.CHECK_USER_ROLE;

  constructor(public payload: { uid: string }) {}
}

export class SetLoading implements Action {
  readonly type = AuthActionTypes.IS_LOADING;

  constructor(public payload: { loading: boolean }) {}
}

export type AuthAction =
  | LoginRequested
  | LoginSuccess
  | LoggedIn
  | LoginFailed
  | LogoutRequested
  | LogoutCompleted
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateOnlineStatus
  | GetUser
  | SaveUser
  | AuthError
  | CheckUserRole
  | SetLoading;
