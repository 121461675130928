import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'database-table-placeholder',
  templateUrl: './database-table-placeholder.component.html',
  styleUrls: ['./database-table-placeholder.component.scss'],
})
export class DatabaseTablePlaceholderComponent implements OnInit {
  @Input() text: string = '';
  @Input() image: string = '';

  constructor() {}

  ngOnInit(): void {}
}
