<div class="d-flex flex-column align-items-center justify-content-center">
  <div
    class="card my-2 w-75 d-flex flex-column justify-content-center align-items-center bg-light"
    [ngClass]="isSelected ? 'roleActive' : 'hoverClass'"
    (click)="onSelect()"
  >
    <div
      class="bg-primary mb-2 mt-3 d-flex align-items-center justify-content-center"
      style="
        height: 3.7rem;
        width: 3.7rem;
        border-radius: 50%;
        border: 2.2px solid rgb(79, 79, 79);
        overflow: hidden;
      "
    >
      <span
        class="fi fis w-100 h-100"
        style="object-fit: cover"
        [ngClass]="'fi-' + role?.country?.cnIsoDue?.toLowerCase()"
      ></span>
    </div>
    <div class="d-flex flex-column mb-2 text-center">
      <p class="p-0 m-0 h5">{{ role?.country?.shortName }}</p>
      <p class="p-0 m-0" *ngIf="isSelected">
        {{ "ROLES." + role?.role | translate }}
      </p>
    </div>
  </div>
  <p
    class="p-0 m-0 hover-delete"
    *ngIf="!isSelected && role?.canBeDeleted"
    (click)="openDeleteCountry()"
  >
    <u>
      {{ "BUTTONS.REMOVE" | translate }}
    </u>
  </p>
</div>
