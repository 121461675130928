<div class="border-success card" style="border: 3px solid">
  <div
    class="modal-body d-flex flex-column justify-content-center"
    *ngIf="showDisclaimer"
  >
    <i class="bi bi-exclamation-triangle-fill h1 text-danger mx-auto"></i>
    <p [innerHTML]="'PROJECTS.NEW_DISCLAIMER' | translate"></p>
  </div>
  <div class="modal-body" *ngIf="!showDisclaimer">
    <div class="d-flex flex-row align-items-center">
      <i class="bi bi-folder-fill h4 my-auto"></i>
      <h4 class="modal-title my-auto mx-2">
        {{ "PROJECTS.CREATE_NEW_PROJECT" | translate }}
      </h4>
    </div>
    <form [formGroup]="projectForm!">
      <div class="row my-4">
        <div class="col-6">
          <div class="d-flex">
            <h5 class="mb-1">{{ "PROJECTS.TITLE" | translate }}:</h5>
            <span
              class="text-danger mx-2"
              *ngIf="title?.touched && title?.invalid"
            >
              {{ "REGISTER.REQUIRED" | translate }}
            </span>
          </div>
          <div>
            <input type="text" class="form-control" formControlName="title" />
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex">
            <h5 class="mb-1">{{ "PROJECTS.PROJECT_TYPE" | translate }}:</h5>
            <span
              class="text-danger mx-2"
              *ngIf="type?.touched && type?.invalid"
            >
              {{ "REGISTER.REQUIRED" | translate }}
            </span>
          </div>
          <div>
            <ng-select
              class="control w-100"
              formControlName="type"
              [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
              (change)="getTypeList($event)"
              [items]="typeOptions ?? []"
              bindLabel="label"
              bindValue="value"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row my-4" *ngIf="!title?.invalid && !type?.invalid">
        <div class="col-6">
          <h5 class="mb-1">{{ itemLabel }}</h5>
          <div>
            <ng-select
              class="control w-100"
              formControlName="itemsList"
              [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
              [items]="displayItemsList ?? []"
              bindLabel="label"
              bindValue="value"
              [multiple]="true"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-6">
          <h5 class="mb-1">
            {{ "PROJECTS.IMPORT_" + importText | translate }}:
          </h5>
          <div>
            <ng-select
              class="control w-100"
              formControlName="countryVarieties"
              [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
              [items]="countries ?? []"
              bindLabel="label"
              bindValue="value"
              [multiple]="true"
            >
            </ng-select>
          </div>
        </div>
      </div>

      <div
        class="my-4 d-flex flex-row justify-content-center"
        *ngIf="!title?.invalid && !type?.invalid && !itemsList?.invalid"
      >
        <div class="form-check mx-2" formControlName="purpose">
          <input
            mdbRadio
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="testButton"
            value="TEST"
            formControlName="purpose"
          />
          <label class="form-check-label" for="testButton">
            {{ "PROJECTS.TEST_BUTTON" | translate }}
          </label>
        </div>
        <div class="form-check mx-2">
          <input
            mdbRadio
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="realButton"
            value="REAL"
            formControlName="purpose"
          />
          <label class="form-check-label" for="realButton">
            {{ "PROJECTS.REAL_BUTTON" | translate }}
          </label>
        </div>
        <span class="text-danger mx-2" *ngIf="purpose?.invalid">
          {{ "REGISTER.REQUIRED" | translate }}
        </span>
      </div>
    </form>
  </div>
  <loading class="mask" *ngIf="loading"></loading>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="modalRef.close(false)"
    >
      {{ "BUTTONS.CLOSE" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-success"
      *ngIf="!showDisclaimer"
      (click)="createProject()"
    >
      {{ "BUTTONS.CREATE" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-success"
      *ngIf="showDisclaimer"
      (click)="hideDisclaimer()"
    >
      {{ "BUTTONS.CREATE" | translate }}
    </button>
  </div>
</div>
