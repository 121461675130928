import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-manage-descriptors',
  templateUrl: './modal-manage-descriptors.component.html',
  styleUrls: ['./modal-manage-descriptors.component.scss'],
})
export class ModalManageDescriptorsComponent implements OnInit {
  filterForm?: UntypedFormGroup;
  workID?: string;
  wholeList?: any[];
  selectedList: string[] = [];
  loading: boolean = false;
  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalManageDescriptorsComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null, []),
    });
    this.apiCall();
    this.filter?.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.apiCall();
    });
  }

  apiCall() {
    this.loading = true;
    if (this.workID) {
      this.api
        .getDescriptorsByWorkingArea(
          this.workID,
          this.filter?.value,
          this.selectedList
        )
        .subscribe((value) => {
          this.wholeList = value as any[];
          this.wholeList.forEach((item) => {
            item.descriptors.forEach((desc: any) => {
              if (desc.selected && !this.selectedList.includes(desc.refID)) {
                this.selectedList.push(desc.refID);
              }
            });
          });
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  onSelect(category: any, descriptor: any) {
    if (this.selectedList.includes(descriptor.refID)) {
      const index = this.selectedList.indexOf(descriptor.refID);
      this.selectedList.splice(index, 1);
      category.count--;
      descriptor.selected = false;
    } else {
      category.count++;
      this.selectedList.push(descriptor.refID);
      descriptor.selected = true;
    }
  }

  save() {
    this.api
      .setDescriptorsByWorkingArea(this.workID!, this.selectedList)
      .subscribe(() => {
        this.modalRef.close(true);
      });
  }

  get filter() {
    return this.filterForm?.get('filter');
  }
}
