import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalAddDescriptorBenefitComponent } from 'src/app/modals/projects/modal-add-descriptor-benefit/modal-add-descriptor-benefit.component';
import { ModalAddSubgoalBenefitComponent } from 'src/app/modals/projects/modal-add-subgoal-benefit/modal-add-subgoal-benefit.component';
import { ModalAnswerDescriptorBenefitComponent } from 'src/app/modals/projects/modal-answer-descriptor-benefit/modal-answer-descriptor-benefit.component';
import { ModalConfirmDeleteDescriptorBenefitComponent } from 'src/app/modals/projects/modal-confirm-delete-descriptor-benefit/modal-confirm-delete-descriptor-benefit.component';
import { ModalConfirmDeleteSubgoalBenefitComponent } from 'src/app/modals/projects/modal-confirm-delete-subgoal-benefit/modal-confirm-delete-subgoal-benefit.component';
import { ModalMeShowGoalsComponent } from 'src/app/modals/projects/modal-me-show-goals/modal-me-show-goals.component';
import { Goal } from 'src/app/models/goal';
import { Period } from 'src/app/models/period';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'project-development-benefit',
  templateUrl: './project-development-benefit.component.html',
  styleUrls: ['./project-development-benefit.component.scss'],
})
export class ProjectDevelopmentBenefitComponent implements OnInit {
  @Output() stateChange: EventEmitter<number> = new EventEmitter();
  @Input() species?: Select[];
  @Input() selectedSpecie?: Select;
  filterForm?: FormGroup;
  projectID?: string;

  loading: boolean = true;

  periods?: Period[];
  goals?: Select[];
  subGoals?: any[];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.projectID = this.route.parent?.snapshot?.paramMap.get('id') ?? '';
    this.filterForm = new FormGroup({
      species: new FormControl(this.selectedSpecie),
      period: new FormControl(null),
      goal: new FormControl(null),
    });
    this.getPeriods();

    this.filterForm.valueChanges.subscribe((value) => {
      if (!this.loading) {
        this.apiCall();
      }
    });
  }

  getPeriods() {
    this.api.getPeriods(this.projectID!).subscribe((value) => {
      this.periods = value as Period[];
      this.period?.setValue(this.periods[0].periodID);
      this.getGoals();
    });
  }

  getGoals() {
    this.api.getGoalDevelopmentBenefit('CROPS').subscribe((value) => {
      this.goals = value as Select[];
      this.goal?.setValue(this.goals[0].value);
      this.apiCall();
    });
  }

  apiCall() {
    this.loading = true;
    if (this.goal?.value && this.specie?.value && this.period?.value) {
      this.api
        .getSubgoalDevelopmentBenefit(
          this.goal?.value,
          this.specie?.value,
          this.period?.value
        )
        .subscribe((value) => {
          this.subGoals = value as any;
          this.loading = false;
        });
    }
  }

  getGoalTitle(): string {
    let v = '';
    this.goals?.forEach((e) => {
      if (this.goal?.value == e.value) {
        v = e.subtitle!;
      }
    });
    return v;
  }

  openAddSubGoal() {
    const modalRef = this.modalService.open(ModalAddSubgoalBenefitComponent, {
      data: {
        titolo: this.getGoalTitle(),
        refID: this.goal?.value,
        familyID: this.specie?.value,
        periodID: this.period?.value,
      },
      modalClass: 'modal-lg',
    });
    modalRef.onClose.subscribe((value) => {
      if (value) this.apiCall();
    });
  }

  openAddDescriptor(subgoal: any) {
    const modalRef = this.modalService.open(
      ModalAddDescriptorBenefitComponent,
      {
        data: {
          titolo: subgoal.subgoal,
          refID: subgoal.subgoalID,
          familyID: this.specie?.value,
          periodID: this.period?.value,
        },
        modalClass: 'modal-lg',
      }
    );
    modalRef.onClose.subscribe((value) => {
      if (value)
        this.openDescriptorsAnswers(
          value,
          subgoal.subgoalID,
          subgoal.projectDevelopmentBenefitID
        );
    });
  }

  openShowGoalDescriptors(item: any) {
    const modalRef = this.modalService.open(ModalMeShowGoalsComponent, {
      data: {
        periods: this.periods,
        item: item,
      },
      modalClass: 'modal-fullscreen',
    });
  }

  openDescriptorsAnswers(
    descriptor: Select[],
    refID: string,
    projectDevelopmentBenefitID: string
  ) {
    const modalRef = this.modalService.open(
      ModalAnswerDescriptorBenefitComponent,
      {
        data: {
          refID: refID,
          familyID: this.specie?.value,
          periodID: this.period?.value,
          descriptor: descriptor,
          projectDevelopmentBenefitID: projectDevelopmentBenefitID,
        },
        modalClass: 'modal-lg',
      }
    );
    modalRef.onClose.subscribe((value) => {
      if (value) this.apiCall();
    });
  }

  confirmDeleteSubgoal(item: any) {
    const modalRef = this.modalService.open(
      ModalConfirmDeleteSubgoalBenefitComponent
    );
    modalRef.onClose.subscribe((value) => {
      if (value == true) {
        this.deleteSubgoal(item);
      }
    });
  }

  confirmDeleteAnswer(item: any) {
    const modalRef = this.modalService.open(
      ModalConfirmDeleteDescriptorBenefitComponent
    );
    modalRef.onClose.subscribe((value) => {
      if (value == true) {
        this.deleteAnswer(item);
      }
    });
  }

  deleteSubgoal(item: any) {
    this.api
      .deleteSubgoalDevelopmentBenefit(item.projectDevelopmentBenefitID)
      .subscribe((value) => {
        this.apiCall();
      });
  }

  deleteAnswer(item: any) {
    this.api
      .deleteDescriptorDevelopmentBenefit(item.projectDevelopmentBenefitGoalID)
      .subscribe((value) => {
        this.apiCall();
      });
  }

  back() {
    this.stateChange.emit(0);
  }

  get period() {
    return this.filterForm?.get('period');
  }

  get specie() {
    return this.filterForm?.get('species');
  }

  get goal() {
    return this.filterForm?.get('goal');
  }
}
