import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'item-market-descriptors',
  templateUrl: './item-market-descriptors.component.html',
  styleUrls: ['./item-market-descriptors.component.scss'],
})
export class ItemMarketDescriptorsComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  marketDescriptors?: any[];
  databaseType?: string;
  loading: boolean = true;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getDatabaseType();
    this.getMarket();
    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.getMarket();
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  getMarket() {
    const id = this.route.parent?.snapshot.paramMap.get('id');
    if (id)
      switch (this.databaseType) {
        case 'c':
          this.api.getMarketTable('CROPS', id).subscribe(
            (value) => {
              this.marketDescriptors = value as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
          break;
        case 'l':
          this.api.getMarketTable('LIVESTOCK', id).subscribe(
            (value) => {
              this.marketDescriptors = value as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
          break;
        case 'a':
          this.api.getMarketTable('AQUATICS', id).subscribe(
            (value) => {
              this.marketDescriptors = value as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
          break;
        default:
          break;
      }
  }

  getDatabaseType() {
    const path = this.router.url;
    this.databaseType = path.split('/')[3];
  }
}
