<div
  class="text-primary rounded mt-2"
  style="border-radius: 0.5rem 0.5rem 0 0; overflow: hidden"
>
  <mdb-vector-map
    #myMap
    [stroke]="'#37474F'"
    [readonly]="true"
    [hover]="false"
    [btnClass]="'btn-light'"
    [colorMap]="advancedColorMap"
  ></mdb-vector-map>
</div>
