import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'database-navigation-card',
  templateUrl: './database-navigation-card.component.html',
  styleUrls: ['./database-navigation-card.component.scss'],
})
export class DatabaseNavigationCardComponent implements OnInit {
  @Input() title: string = '';
  @Input() image: string = '';

  constructor() {}

  ngOnInit(): void {}
}
