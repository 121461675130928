import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { ApiService } from 'src/app/api.service';
import { AppState } from 'src/app/auth/reducer';
import { getUser } from 'src/app/auth/store/auth.selectors';
import { ModalAddBreedComponent } from 'src/app/modals/working-area/modal-add-breed/modal-add-breed.component';
import { ModalAddVarietyComponent } from 'src/app/modals/working-area/modal-add-variety/modal-add-variety.component';
import { ModalChangeLocalNamesComponent } from 'src/app/modals/working-area/modal-change-local-names/modal-change-local-names.component';
import { ModalChangePhotosComponent } from 'src/app/modals/working-area/modal-change-photos/modal-change-photos.component';
import { ModalDeleteVarietyComponent } from 'src/app/modals/working-area/modal-delete-variety/modal-delete-variety.component';
import { ModalImportVarietyComponent } from 'src/app/modals/working-area/modal-import-variety/modal-import-variety.component';
import { Crop } from 'src/app/models/crop';
import { SidenavListener } from 'src/app/models/global-helper';
import { Image } from 'src/app/models/image';
import { Variety } from 'src/app/models/variety';

export interface WorkingAreaEntity {
  name: string;
  refID: string;
  refType: string;
  imageUrl: string;
  thumbUrl?: string;
  ref: string;
}

export interface PartnerRefItems {
  title: string;
  icon: string;
  itemType: string;
  itemTypes: string;
  addButton: string;
  importButton: string;
  filterPhase0: string;
  filterPhase1: string;
  selectItem: string;
  noItem: string;
  backButton: string;
}

@Component({
  selector: 'working-area-page',
  templateUrl: './working-area-page.component.html',
  styleUrls: ['./working-area-page.component.scss'],
})
export class WorkingAreaPageComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  sidenavHelper$?: Subscription;
  editingForm?: UntypedFormGroup;
  filterForm?: UntypedFormGroup;
  entities: WorkingAreaEntity[] = [];
  varieties?: any[] | null;
  country?: string;
  selectedCrop?: WorkingAreaEntity | null;
  selectedVarieties: any[] = [];
  selectedVariety?: Variety | null;
  phase: string = 'phase0';
  partnerRef?: string;

  partnerRefItems?: PartnerRefItems;

  title: string = '';
  icon: string = '';
  addButton: string = '';
  filterPhase0: string = '';
  filterPhase1: string = '';

  materials?: any[];

  constructor(
    private api: ApiService,
    private store: Store<AppState>,
    private router: Router,
    private modalService: MdbModalService,
    private translate: TranslateService,
    private sidenavListener: SidenavListener
  ) {}

  ngOnInit(): void {
    this.editingForm = new UntypedFormGroup({
      editVariety: new UntypedFormControl(this.selectedVarieties, []),
      varietyName: new UntypedFormControl(null, []),
      varietyType: new UntypedFormControl(null, []),
    });

    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null, []),
    });

    this.apiCall();

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.apiCall();
      this.varieties = [];
      this.selectedCrop = null;
      this.selectedVarieties = [];
      this.selectedVariety = null;
      this.phase = 'phase0';
    });

    this.sidenavHelper$ = this.sidenavListener.data.subscribe((data) => {
      if (data.userRef) {
        this.partnerRef = data.userRef;
        this.switchPartnerRefItems(data.userRef);
        this.apiCall();
        this.varieties = [];
        this.selectedCrop = null;
        this.selectedVarieties = [];
        this.selectedVariety = null;
        this.phase = 'phase0';
      }
    });

    this.filter?.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (this.selectedCrop) {
        this.api
          .getVarietiesPartner(this.selectedCrop.refID, value)
          .subscribe((value) => {
            this.varieties = value as Variety[];
            this.refreshSelected();
          });
      } else {
        this.api.getFamilyPartner(value).subscribe((value) => {
          this.entities = value as WorkingAreaEntity[];
        });
      }
    });

    this.getMaterialClassification();
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
    this.sidenavHelper$?.unsubscribe();
  }

  apiCall() {
    this.api.getFamilyPartner().subscribe((value) => {
      this.entities = value as any[];
    });
    this.store.select(getUser).subscribe((value) => {
      this.country = value?.currentRole.country?.countryID;
    });
  }

  checkAnimation() {
    this.phase = 'phase1';
  }

  getAnimationClass(): string {
    switch (this.phase) {
      case 'phase0':
        return '';
      case 'phase1':
        return 'translate-obj-left';
      case 'phase2':
        return 'translate-obj-right';
      default:
        return '';
    }
  }

  getMaterialClassification() {
    this.api
      .getMaterialTypeClassification(this.partnerRef ?? '')
      .subscribe((value) => {
        this.materials = value as any[];
      });
  }

  backToCrop() {
    this.phase = 'phase2';
    this.filter?.setValue(null);
    this.selectedCrop = null;
    this.varieties = null;
    this.selectedVarieties = [];
  }

  mergeWorkingArea(oldVarieties: string[]) {
    this.selectedVarieties = [];
    this.varieties?.forEach((variety) => {
      if (oldVarieties.indexOf(variety.varietyID) > -1) {
        variety.selected = true;
        this.selectedVarieties.push(variety);
      }
    });
  }

  refreshSelected() {
    let selectedIds = this.selectedVarieties.map((item) => {
      return item.varietyID;
    });
    this.varieties?.forEach((variety) => {
      if (selectedIds.indexOf(variety.varietyID) > -1) {
        variety.selected = true;
      }
    });
  }

  onCropSelected(crop: any, i: number) {
    this.selectedCrop = null;
    this.filter?.setValue(null);
    this.phase = 'phase1';
    if (this.country) {
      this.selectedCrop = crop;
      this.api.getVarietiesPartner(crop.refID, null).subscribe((value) => {
        this.varieties = value as Variety[];
        this.api.getMyWorkingAreaByFamily(crop.refID).subscribe((value) => {
          this.mergeWorkingArea(value as string[]);
        });
      });
    }
  }

  onVarietySelect(variety: any, i: number) {
    if (this.varieties && this.varieties[i].selected) {
      if (this.editVariety?.value == variety.varietyID) {
        this.editVariety?.setValue(null);
        this.varietyName?.setValue(null);
        this.selectedVariety = null;
      }
      let selectedIDs = this.selectedVarieties.map((item) => {
        return item.varietyID;
      });
      let index: number = -1;
      this.selectedVarieties.forEach((item, i) => {
        if (item.varietyID == variety.varietyID) {
          index = i;
        }
      });
      this.selectedVarieties.splice(index, 1);
    } else {
      if (this.selectedVarieties.length == 0) {
        this.editVariety?.setValue(variety.varietyID);
        this.varietyName?.setValue(variety.name);
      }
      this.selectedVarieties.push(variety);
    }
    this.varieties![i].selected = !this.varieties![i].selected;
  }

  editSelectedVariety(variety: Variety) {
    this.editVariety?.setValue(variety.varietyID);
    this.varietyName?.setValue(variety.name);
    this.selectedVariety = variety;
  }

  editName() {
    if (this.selectedCrop && this.selectedVariety) {
      this.api
        .editVarietyName(this.editVariety?.value, this.varietyName?.value)
        .subscribe(() => {
          this.api
            .getVarieties(this.selectedCrop!.refID, null, this.country)
            .subscribe((value) => {
              this.varieties = value as Variety[];
              this.api
                .getMyWorkingAreaByFamily(this.selectedCrop!.refID)
                .subscribe((value) => {
                  this.mergeWorkingArea(value as string[]);
                });
            });
        });
    }
  }

  removeVariety(variety: Variety, i: number) {
    this.api.removeVariety(variety.varietyID).subscribe(() => {
      this.varieties?.splice(i, 1);
      let selectedIds = this.selectedVarieties.map((item) => {
        return item.varietyID;
      });
      let indexToRemove = -1;
      this.selectedVarieties?.forEach((variety, i) => {
        if (selectedIds.indexOf(variety.varietyID) > -1) {
          indexToRemove = i;
        }
      });
      this.selectedVarieties.splice(i, 1);
    });
  }

  startAnswering() {
    let refs: string[] = [];
    this.selectedVarieties.forEach((item) => {
      refs.push(item.varietyID);
    });
    if (this.selectedCrop) {
      this.api
        .createWorkingArea(this.selectedCrop?.refID, refs)
        .subscribe((value: any) => {
          const workID = value.workID as any;
          this.router.navigateByUrl('/working-area/' + workID);
        });
    }
  }

  openImportVariety() {
    const modalRef = this.modalService.open(ModalImportVarietyComponent, {
      modalClass: 'modal-dialog-centered modal-xl',
      ignoreBackdropClick: true,
      data: {
        refID: this.selectedCrop?.refID,
        partnerRef: this.partnerRef,
      },
    });
    modalRef.onClose.subscribe((message) => {
      if (message) {
        if (this.country && this.selectedCrop?.refID) {
          this.api
            .getVarietiesPartner(this.selectedCrop?.refID, null)
            .subscribe((value) => {
              this.varieties = value as Variety[];
              this.api
                .getMyWorkingAreaByFamily(this.selectedCrop!.refID)
                .subscribe((value) => {
                  this.mergeWorkingArea(value as string[]);
                });
            });
        }
      }
    });
  }

  openAddVariety(cropID: string) {
    let modalRef;
    switch (this.partnerRef) {
      case 'CROPS':
        modalRef = this.modalService.open(ModalAddVarietyComponent, {
          modalClass: 'modal-dialog-centered modal-lg',
          ignoreBackdropClick: true,
          data: { cropID: cropID, ref: 'CROPS' },
        });
        break;
      case 'LIVESTOCK':
        modalRef = this.modalService.open(ModalAddBreedComponent, {
          modalClass: 'modal-dialog-centered modal-lg',
          ignoreBackdropClick: true,
          data: { breedID: cropID, ref: 'LIVESTOCK' },
        });
        break;
      default:
        break;
    }

    modalRef?.onClose.subscribe((message) => {
      if (message && this.selectedCrop) {
        this.api
          .getVarietiesPartner(this.selectedCrop.refID, null)
          .subscribe((value) => {
            this.varieties = value as Variety[];
            this.api
              .getMyWorkingAreaByFamily(this.selectedCrop!.refID)
              .subscribe((value) => {
                this.mergeWorkingArea(value as string[]);
              });
          });
      }
    });
  }

  openChangeLocalNames() {
    if (this.selectedVariety) {
      const modalRef = this.modalService.open(ModalChangeLocalNamesComponent, {
        modalClass: 'modal-dialog-centered modal-lg',
        ignoreBackdropClick: true,
        data: { variety: this.selectedVariety },
      });
    }
  }

  openChangePhotos() {
    if (this.selectedVariety) {
      this.api.getImages(this.selectedVariety.varietyID).subscribe((value) => {
        const images = value as Image[];
        const modalRef = this.modalService.open(ModalChangePhotosComponent, {
          modalClass: 'modal-fullscreen modal-dialog-scrollable',
          data: {
            varietyID: this.selectedVariety?.varietyID,
            photos: images,
            partnerRef: this.partnerRef,
          },
        });
      });
    }
  }

  openDeleteVariety(variety: Variety, i: number) {
    const modalRef = this.modalService.open(ModalDeleteVarietyComponent, {
      modalClass: 'modal-dialog-centered',
      data: { varietyName: variety.name },
    });

    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.removeVariety(variety, i);
      }
    });
  }

  // switchTitle(): string {
  //   switch (this.partnerRef) {
  //     case 'CROPS':
  //       return '';
  //     case 'LIVESTOCK':
  //       return '';
  //     default:
  //       return '';
  //   }
  // }

  switchItemEditName(): string {
    switch (this.partnerRef) {
      case 'CROPS':
        return 'WORKING_AREA.VARIETY_NAME';
      case 'LIVESTOCK':
        return 'WORKING_AREA.BREED_NAME';
      default:
        return '';
    }
  }

  switchItemEditType(): string {
    switch (this.partnerRef) {
      case 'CROPS':
        return 'WORKING_AREA.VARIETY_TYPE';
      case 'LIVESTOCK':
        return 'WORKING_AREA.BREED_TYPE';
      default:
        return '';
    }
  }

  switchPartnerRefItems(partnerRef: string) {
    switch (partnerRef) {
      case 'CROPS':
        this.partnerRefItems = {
          title: 'NAVIGATION.CROP',
          icon: 'crop.svg',
          itemType: 'WORKING_AREA.VARIETY',
          itemTypes: 'WORKING_AREA.VARIETIES',
          addButton: 'WORKING_AREA.ADD_VARIETY',
          importButton: 'WORKING_AREA.IMPORT_VARIETY',
          filterPhase0: 'WORKING_AREA.FILTER_C',
          filterPhase1: 'WORKING_AREA.FILTER_V',
          backButton: 'WORKING_AREA.SHOW_CROP',
          noItem: 'WORKING_AREA.NO_VARIETIES',
          selectItem: 'WORKING_AREA.SELECT_CROP',
        };

        break;
      case 'LIVESTOCK':
        this.partnerRefItems = {
          title: 'NAVIGATION.LIVESTOCK',
          icon: 'livestock.svg',
          itemType: 'WORKING_AREA.BREED',
          itemTypes: 'WORKING_AREA.BREEDS',
          addButton: 'WORKING_AREA.ADD_BREED',
          importButton: 'WORKING_AREA.IMPORT_BREED',
          filterPhase0: 'WORKING_AREA.FILTER_L',
          filterPhase1: 'WORKING_AREA.FILTER_B',
          backButton: 'WORKING_AREA.SHOW_LIVESTOCK',
          noItem: 'WORKING_AREA.NO_BREEDS',
          selectItem: 'WORKING_AREA.SELECT_LIVESTOCK',
        };
        break;
      default:
        this.addButton = 'WORKING_AREA.ADD_VARIETY';
        break;
    }
  }

  get editVariety() {
    return this.editingForm?.get('editVariety');
  }

  get varietyName() {
    return this.editingForm?.get('varietyName');
  }

  get filter() {
    return this.filterForm?.get('filter');
  }
}
