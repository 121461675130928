<div class="modal-header">
  <div class="d-flex flex-row align-items-center">
    <h4 class="modal-title my-auto mx-2">
      {{ "VARIETY_CLEANING.VARIETY_INFO" | translate }}
    </h4>
  </div>
</div>
<div class="modal-body" style="height: 20rem; overflow-y: scroll">
  <div class="d-flex flex-column">
    <div
      class="d-flex flex-column p-2 rounded mb-2"
      [ngClass]="i % 2 == 0 ? 'bg-light' : 'bg-white'"
      [class.hover]="clickable"
      *ngFor="let item of varietiesSelected; index as i"
      (click)="choseInfo(item.projectVarietyID)"
    >
      <h5>{{ item.name + " - " + item.projectVarietyID }}</h5>
      <hr class="my-1" />
      <h6>{{ item.species }}</h6>
      <div class="d-flex" *ngFor="let class of item.classifications">
        <span style="font-weight: bold">
          {{ class.classification ?? " -" }}
        </span>
        <span class="mx-2">by</span>
        <span>
          {{ class.activityUuid ?? " -" }}
        </span>
      </div>
    </div>
    <!-- <div
      class="d-flex flex-column p-2 rounded mb-2"
      [ngClass]="i % 2 == 0 ? 'bg-light' : 'bg-white'"
      [class.hover]="clickable"
      *ngFor="let item of varietiesSelected; index as i"
      (click)="choseInfo(item.projectVarietyID)"
    >
      <h5>{{ item.name + " - " + item.projectVarietyID }}</h5>
      <hr class="my-1" />
      <h6>{{ item.species }}</h6>
      <div class="d-flex" *ngIf="item.hybrid">
        <h6>
          {{ "VARIETY_CLEANING.HYBRID" | translate }}
          {{ item.hybridWith ?? " -" }}
        </h6>
      </div>
      <div class="d-flex" *ngIf="item.mixture">
        <h6>
          {{ "VARIETY_CLEANING.MIXTURE" | translate }}
          {{ item.mixtureWith ?? " -" }}
        </h6>
      </div>
      <div class="d-flex flex-column" *ngIf="item.evolutionary">
        <h6 class="mb-2">
          {{ "VARIETY_CLEANING.EVOLUTIONARY" | translate }}
          {{ item.evolutionaryWith ?? " -" }}
        </h6>
        <div class="d-flex">
          <h6 class="me-4">
            {{ "VARIETY_CLEANING.PROVINCE" | translate }}
            {{ item.evolutionaryProvince ?? " -" }}
          </h6>
          <h6>
            {{ "VARIETY_CLEANING.CITY" | translate }}
            {{ item.evolutionaryCity ?? " -" }}
          </h6>
        </div>
        <div class="d-flex">
          <h6 class="me-4">
            {{ "VARIETY_CLEANING.LAT" | translate }}
            {{ item.evolutionaryLat ?? " -" }}
          </h6>
          <h6 class="me-4">
            {{ "VARIETY_CLEANING.LNG" | translate }}
            {{ item.evolutionaryLng ?? " -" }}
          </h6>
          <h6>
            {{ "VARIETY_CLEANING.ALT" | translate }}
            {{ item.evolutionaryAlt ?? " -" }}
          </h6>
        </div>
      </div>
    </div> -->
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </button>
</div>
