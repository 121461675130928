import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbCheckboxChange } from 'mdb-angular-ui-kit/checkbox';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalChangeLocalNamesComponent } from 'src/app/modals/working-area/modal-change-local-names/modal-change-local-names.component';
import { ModalChangePhotosComponent } from 'src/app/modals/working-area/modal-change-photos/modal-change-photos.component';
import { ModalDeleteVarietyComponent } from 'src/app/modals/working-area/modal-delete-variety/modal-delete-variety.component';
import { Crop } from 'src/app/models/crop';
import { Livestock } from 'src/app/models/livestock';
import { Variety } from 'src/app/models/variety';
import { WorkingAreaEntity } from '../working-area-page/working-area-page.component';
import { Image } from 'src/app/models/image';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ModalAddVarietyComponent } from 'src/app/modals/working-area/modal-add-variety/modal-add-variety.component';
import { ModalAddBreedComponent } from 'src/app/modals/working-area/modal-add-breed/modal-add-breed.component';
import { ModalImportVarietyComponent } from 'src/app/modals/working-area/modal-import-variety/modal-import-variety.component';

@Component({
  selector: 'wa-variety-list-page',
  templateUrl: './wa-variety-list-page.component.html',
  styleUrls: ['./wa-variety-list-page.component.scss'],
})
export class WaVarietyListPageComponent implements OnInit {
  ref?: string;
  id?: string;

  filterForm?: FormGroup;

  crop?: Crop;
  livestock?: Livestock;
  varieties?: any[];
  selectedVarieties = new Set<any>();

  translations: any;

  headers: string[] = [];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.ref = this.route.snapshot.paramMap.get('ref') ?? '';
    this.id = this.route.snapshot.paramMap.get('id') ?? '';
    this.getTranslations();
    this.headers.push(this.translations.name, this.translations.type);

    this.filterForm = new FormGroup({
      filter: new FormControl(null),
    });

    this.getEntity();

    this.filterForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.apiCall();
    });
  }

  getEntity() {
    switch (this.ref) {
      case 'c':
        this.api.getCrop(this.id!).subscribe((value) => {
          this.crop = value as Crop;
          this.getVarieties(this.crop.cropID);
        });
        break;
      case 'l':
        this.api.getLivestock(this.id!).subscribe((value) => {
          this.livestock = value as Livestock;
          this.getVarieties(this.livestock.livestockID);
        });
        break;
      default:
        return;
    }
  }

  getVarieties(refID: string) {
    this.api
      .getVarietiesPartner(refID, this.filter?.value)
      .subscribe((value) => {
        this.varieties = value as Variety[];
        this.api.getMyWorkingAreaByFamily(refID).subscribe((value) => {
          this.mergeWorkingArea(value as string[]);
        });
      });
  }

  apiCall() {
    this.api
      .getVarietiesPartner(
        this.crop ? this.crop!.cropID : this.livestock!.livestockID,
        this.filter?.value
      )
      .subscribe((value) => {
        this.varieties = value as Variety[];
      });
  }

  mergeWorkingArea(oldVarieties: string[]) {
    this.varieties?.forEach((variety) => {
      if (oldVarieties.indexOf(variety.varietyID) > -1) {
        variety.selected = true;
        this.selectedVarieties.add(variety);
      }
    });
  }

  allRowsSelected(): boolean {
    const selectedVarietiesLength = this.selectedVarieties.size;
    const dataLength = this.varieties!.length;
    return selectedVarietiesLength === dataLength;
  }

  toggleSelection(event: MdbCheckboxChange, value: any): void {
    if (event.checked) {
      this.select(value);
    } else {
      this.deselect(value);
    }
  }

  toggleAll(event: MdbCheckboxChange): void {
    if (event.checked) {
      this.varieties!.forEach((row: any) => {
        this.select(row);
      });
    } else {
      this.varieties!.forEach((row: any) => {
        this.deselect(row);
      });
    }
  }

  select(value: any): void {
    if (!this.selectedVarieties.has(value)) {
      this.selectedVarieties.add(value);
    }
  }

  deselect(value: any): void {
    if (this.selectedVarieties.has(value)) {
      this.selectedVarieties.delete(value);
    }
  }

  openDeleteVariety(varietyID: string, name: string, i: number) {
    const modalRef = this.modalService.open(ModalDeleteVarietyComponent, {
      modalClass: 'modal-dialog-centered',
      data: { varietyName: name },
    });

    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.removeVariety(varietyID, i);
      }
    });
  }

  removeVariety(varietyID: string, i: number) {
    this.api.removeVariety(varietyID).subscribe(() => {
      this.varieties?.splice(i, 1);
      this.selectedVarieties.delete(varietyID);
    });
  }

  openChangeLocalNames(id: string) {
    const modalRef = this.modalService.open(ModalChangeLocalNamesComponent, {
      modalClass: 'modal-dialog-centered modal-lg',
      ignoreBackdropClick: true,
      data: { variety: id },
    });
  }

  openChangePhotos(varietyID: string) {
    this.api.getImages(varietyID).subscribe((value) => {
      const images = value as Image[];
      const modalRef = this.modalService.open(ModalChangePhotosComponent, {
        modalClass: 'modal-fullscreen modal-dialog-scrollable',
        data: {
          varietyID: varietyID,
          photos: images,
          partnerRef: this.ref,
        },
      });
    });
  }

  openAddVariety(entity?: any) {
    let modalRef;
    switch (this.ref) {
      case 'c':
        modalRef = this.modalService.open(ModalAddVarietyComponent, {
          modalClass: 'modal-dialog-centered modal-lg',
          ignoreBackdropClick: true,
          data: { cropID: this.crop?.cropID, ref: 'CROPS', entity: entity },
        });
        break;
      case 'l':
        modalRef = this.modalService.open(ModalAddBreedComponent, {
          modalClass: 'modal-dialog-centered modal-lg',
          ignoreBackdropClick: true,
          data: {
            breedID: this.livestock?.livestockID,
            ref: 'LIVESTOCK',
            entity: entity,
          },
        });
        break;
      default:
        break;
    }

    modalRef?.onClose.subscribe((message) => {
      if (!message) return;
      this.apiCall();
    });
  }

  openImportVariety() {
    if (this.crop) {
      const modalRef = this.modalService.open(ModalImportVarietyComponent, {
        modalClass: 'modal-dialog-centered modal-xl',
        ignoreBackdropClick: true,
        data: {
          refID: this.crop?.cropID,
          partnerRef: this.ref,
        },
      });
      modalRef.onClose.subscribe((message) => {
        if (!message) return;
        this.apiCall();
      });
    }
    if (this.livestock) {
      const modalRef = this.modalService.open(ModalImportVarietyComponent, {
        modalClass: 'modal-dialog-centered modal-xl',
        ignoreBackdropClick: true,
        data: {
          refID: this.livestock?.livestockID,
          partnerRef: this.ref,
        },
      });
      modalRef.onClose.subscribe((message) => {
        if (!message) return;
        this.apiCall();
      });
    }
  }

  startAnswering() {
    let refs: string[] = [];
    this.selectedVarieties.forEach((item) => {
      refs.push(item.varietyID);
    });
    if (this.crop) {
      this.api
        .createWorkingArea(this.crop.cropID, refs)
        .subscribe((value: any) => {
          const workID = value.workID as any;
          this.router.navigateByUrl('/working-area/' + workID);
        });
    }
  }

  getTranslations() {
    switch (this.ref) {
      case 'c':
        this.translations = {
          title: 'NAVIGATION.CROP',
          icon: 'crop.svg',
          itemType: 'WORKING_AREA.VARIETY',
          itemTypes: 'WORKING_AREA.VARIETIES',
          addButton: 'WORKING_AREA.ADD_VARIETY',
          importButton: 'WORKING_AREA.IMPORT_VARIETY',
          filterPhase0: 'WORKING_AREA.FILTER_C',
          filterPhase1: 'WORKING_AREA.FILTER_V',
          backButton: 'WORKING_AREA.SHOW_CROP',
          noItem: 'WORKING_AREA.NO_VARIETIES',
          selectItem: 'WORKING_AREA.SELECT_CROP',
          name: 'WORKING_AREA.VARIETY_NAME',
          type: 'WORKING_AREA.VARIETY_TYPE',
        };

        break;
      case 'l':
        this.translations = {
          title: 'NAVIGATION.LIVESTOCK',
          icon: 'livestock.svg',
          itemType: 'WORKING_AREA.BREED',
          itemTypes: 'WORKING_AREA.BREEDS',
          addButton: 'WORKING_AREA.ADD_BREED',
          importButton: 'WORKING_AREA.IMPORT_BREED',
          filterPhase0: 'WORKING_AREA.FILTER_L',
          filterPhase1: 'WORKING_AREA.FILTER_B',
          backButton: 'WORKING_AREA.SHOW_LIVESTOCK',
          noItem: 'WORKING_AREA.NO_BREEDS',
          selectItem: 'WORKING_AREA.SELECT_LIVESTOCK',
          name: 'WORKING_AREA.BREED_NAME',
          type: 'WORKING_AREA.BREED_TYPE',
        };
        break;
      default:
        return;
    }
  }

  get filter() {
    return this.filterForm?.get('filter');
  }
}
