<div class="modal-body" [formGroup]="editForm!">
  <div class="d-flex flex-column">
    <div class="d-flex flex-row my-2">
      <i class="bi bi-pencil-fill h4 my-auto"></i>
      <h4 class="my-auto ms-2">{{ "PERIOD.EDIT_PERIOD_TITLE" | translate }}</h4>
    </div>
    <label mdbLabel for="title" class="form-label mb-0">
      {{ "PERIOD.PERIOD_TITLE" | translate }}
    </label>
    <div>
      <input type="text" class="form-control" formControlName="title" />
    </div>
    <div class="mt-2">
      <label mdbLabel for="date" class="form-label mb-0">
        {{ "PERIOD.EDIT_CLOSING_DATE" | translate }}
      </label>
      <div class="d-flex">
        <input
          mdbInput
          [mdbDatepicker]="datepicker"
          type="text"
          id="date"
          class="form-control mx-2"
          formControlName="date"
          (focus)="openCalendar()"
        />
        <i class="bi-calendar-fill mx-2 my-auto"></i>
        <mdb-datepicker #datepicker [inline]="true"></mdb-datepicker>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
  <div class="btn btn-success" (click)="editPeriod()">
    {{ "BUTTONS.SAVE" | translate }}
  </div>
</div>
