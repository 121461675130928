<div class="modal-header">{{ descriptor?.label }}</div>
<div class="modal-body">
  <loading [glass]="false" *ngIf="loading"></loading>
  <div>
    {{ answer?.answerType }}
  </div>
  <div
    style="overflow-y: auto"
    [ngSwitch]="answer?.answerType"
    [formGroup]="answerForm!"
  >
    <div class="h5 m-2 d-flex" *ngSwitchCase="'BOOLEAN'">
      <div class="form-check mx-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="answerBoolean"
          id="yes"
          formControlName="answerBoolean"
          [value]="true"
        />
        <label class="form-check-label" for="yes"> Yes </label>
      </div>

      <div class="form-check mx-2">
        <input
          mdbRadio
          class="form-check-input"
          type="radio"
          name="answerBoolean"
          id="no"
          formControlName="answerBoolean"
          [value]="false"
        />
        <label class="form-check-label" for="no"> No </label>
      </div>
    </div>
    <div class="h5 m-2 d-flex flex-column" *ngSwitchCase="'NUMBER'">
      <div class="btn-group">
        <a
          class="btn btn-primary"
          [class.active]="minMaxBetween == 'MIN'"
          (click)="minMaxBetween = 'MIN'"
          >Less Than
        </a>
        <a
          class="btn btn-primary"
          [class.active]="minMaxBetween == 'BET'"
          (click)="minMaxBetween = 'BET'"
        >
          Between
        </a>
        <a
          class="btn btn-primary"
          [class.active]="minMaxBetween == 'MAX'"
          (click)="minMaxBetween = 'MAX'"
          >Greater Than
        </a>
      </div>
      <div class="d-flex">
        <div
          class="w-50 px-2 d-flex flex-column"
          *ngIf="minMaxBetween != 'MIN'"
        >
          <span>Min</span>
          <input
            type="number"
            class="form-control"
            formControlName="answerMin"
          />
        </div>
        <div
          class="w-50 px-2 d-flex flex-column"
          *ngIf="minMaxBetween != 'MAX'"
        >
          <span>Max</span>
          <input
            type="number"
            class="form-control"
            formControlName="answerMax"
          />
        </div>
      </div>
    </div>
    <div class="h5 m-2 d-flex flex-column" *ngSwitchCase="'TIME_ONLY_MIN'">
      <div class="btn-group">
        <a
          href="#!"
          class="btn btn-primary"
          [class.active]="minMaxBetween == 'MIN'"
          (click)="minMaxBetween = 'MIN'"
          >Less Than
        </a>
        <a
          href="#!"
          class="btn btn-primary"
          [class.active]="minMaxBetween == 'BET'"
          (click)="minMaxBetween = 'BET'"
        >
          Between
        </a>
        <a
          href="#!"
          class="btn btn-primary"
          [class.active]="minMaxBetween == 'MAX'"
          (click)="minMaxBetween = 'MAX'"
          >Greater Than
        </a>
      </div>
      <div class="d-flex">
        <div
          class="w-50 px-2 d-flex flex-column"
          *ngIf="minMaxBetween != 'MIN'"
        >
          <span>Min</span>
          <input type="text" class="form-control" formControlName="answerMin" />
        </div>
        <div
          class="w-50 px-2 d-flex flex-column"
          *ngIf="minMaxBetween != 'MAX'"
        >
          <span>Max</span>
          <input type="text" class="form-control" formControlName="answerMax" />
        </div>
      </div>
    </div>
    <div
      class="pt-2"
      *ngSwitchCase="'MULTIPLE_LIST'"
      formArrayName="answerIntensity"
    >
      <div
        class="d-flex justify-content-between align-items-center mb-2"
        *ngFor="let choice of answerIntensity.controls; index as i"
        [formGroupName]="i"
      >
        <div class="form-check">
          <input
            mdbCheckbox
            class="form-check-input"
            type="checkbox"
            [value]="choice.get('value')?.value"
            [id]="choice.get('value')?.value"
            formControlName="selected"
          />
          <label class="form-check-label" [for]="choice.get('value')?.value">
            {{ choice.get("label")?.value }}
          </label>
        </div>
      </div>
    </div>
    <div
      class="pt-2"
      *ngSwitchCase="'MULTIPLE'"
      formArrayName="answerIntensity"
    >
      <div
        class="d-flex justify-content-between align-items-center mb-2"
        *ngFor="let choice of answerIntensity.controls; index as i"
        [formGroupName]="i"
      >
        <div class="form-check">
          <input
            mdbCheckbox
            class="form-check-input"
            type="checkbox"
            [value]="choice.get('value')?.value"
            [id]="choice.get('value')?.value"
            formControlName="selected"
          />
          <label class="form-check-label" [for]="choice.get('value')?.value">
            {{ choice.get("label")?.value }}
          </label>
        </div>
      </div>
    </div>

    <div
      class="pt-2"
      *ngSwitchCase="'MULTIPLE_LIST_SUSCEPTIBILITY'"
      formArrayName="answerIntensity"
    >
      <div
        class="d-flex justify-content-between align-items-center mb-2"
        *ngFor="let choice of answerIntensity.controls; index as i"
        [formGroupName]="i"
      >
        <div class="form-check">
          <input
            mdbCheckbox
            class="form-check-input"
            type="checkbox"
            [value]="choice.get('value')?.value"
            [id]="choice.get('value')?.value"
            formControlName="selected"
          />
          <label class="form-check-label" [for]="choice.get('value')?.value">
            {{ choice.get("label")?.value }}
          </label>
        </div>

        <ng-select [multiple]="true" formControlName="intensity">
          <ng-option value="LOW">Low</ng-option>
          <ng-option value="MEDIUM">Medium</ng-option>
          <ng-option value="HIGH">High</ng-option>
        </ng-select>
      </div>
      <div style="height: 10rem"></div>
    </div>

    <div
      class="pt-2"
      *ngSwitchCase="'MULTIPLE_LIST_CONTENT'"
      formArrayName="answerIntensity"
    >
      <div
        class="d-flex justify-content-between align-items-center mb-2"
        *ngFor="let choice of answerIntensity.controls; index as i"
        [formGroupName]="i"
      >
        <div class="form-check" *ngFor="let choice of answer?.multiValueAnswer">
          <input
            mdbCheckbox
            class="form-check-input"
            type="checkbox"
            [value]="choice.uuid"
            [id]="choice.uuid"
            formControlName="selected"
          />
          <label class="form-check-label" [for]="choice.uuid">
            {{ choice.valueLang }}
          </label>
        </div>

        <ng-select [multiple]="true" formControlName="intensity">
          <ng-option value="NO_LOW">No or Low Level</ng-option>
          <ng-option value="GOOD">Good Content</ng-option>
        </ng-select>
      </div>
      <div style="height: 10rem"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.BACK" | translate }}
  </div>
  <div class="btn btn-success" (click)="save()">
    {{ "BUTTONS.SAVE" | translate }}
  </div>
</div>
