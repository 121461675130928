<div class="container-fluid p-0 m-0 bg-light">
  <div class="d-flex">
    <div class="d-flex flex-column w-100 mx-5 mt-5">
      <div class="d-flex my-2">
        <i class="bi bi-mortarboard-fill h1"></i>
        <h1 class="fw-bold mx-2">
          {{ "NAVIGATION.INSTRUCTIONS" | translate }}
        </h1>
      </div>
      <nav class="navbar navbar-light bg-white rounded-4 sticky-top">
        <div class="container-fluid">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li
                class="breadcrumb-item"
                (click)="navigateHome()"
                style="cursor: pointer"
              >
                <i class="bi bi-house-fill mx-1"></i>
                <span>{{ "INSTRUCTIONS.HOME" | translate }}</span>
                <span class="mx-2" *ngIf="foldersPath?.length != 0"> > </span>
              </li>
              <li
                class="breadcrumb-item"
                *ngFor="let folder of foldersPath; index as i; last as last"
                style="cursor: pointer"
              >
                <a (click)="openFolder(folder.fullPath)">
                  {{ folder.nameLang }}
                  <span class="mx-2" *ngIf="!last"> > </span>
                  <!-- <span class="ms-2" *ngIf="!last"> > </span> -->
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </nav>
    </div>
  </div>

  <div class="d-flex flex-column mx-4 px-4" *ngIf="folders.length > 0">
    <h5 class="my-3">
      <u> {{ "INSTRUCTIONS.FOLDERS" | translate }} </u>
    </h5>
    <div class="d-flex flex-row mx-1 my-1">
      <folder
        name="{{ folder.nameLang }}"
        (click)="openFolder(folder.fullPath)"
        *ngFor="let folder of folders"
      ></folder>
    </div>
  </div>

  <div class="row mt-2 mx-4 px-4">
    <div class="col-7">
      <div
        class="d-flex flex-row mt-3"
        [ngClass]="
          showFilter ? 'justify-content-between' : 'justify-content-start'
        "
      >
        <div class="d-flex">
          <i class="h4 my-auto bi bi-file-earmark-pdf-fill"></i>
          <h4 class="mx-1 my-auto">
            {{ "INSTRUCTIONS.FILE_PDF" | translate }}
          </h4>
          <span class="badge bg-primary h6 my-auto mx-1">
            {{ pdfFiles?.length }}
          </span>
        </div>
        <div class="my-auto" *ngIf="showFilter" [formGroup]="filterForm!">
          <input
            type="text"
            placeholder="{{ 'PLACEHOLDER.FILTER' | translate }}"
            class="form-control"
            formControlName="filter"
          />
        </div>
      </div>
      <div class="bg-primary my-1 w-100" style="height: 2px"></div>
      <div
        class="pb-3"
        style="overflow-y: auto; overflow-x: hidden; height: 35rem"
      >
        <div class="row pt-4 pb-3">
          <file
            class="col-4 mb-3"
            type="{{ pdf.type }}"
            name="{{ pdf.title }}"
            href="{{ pdf.path }}"
            refTo="{{ pdf.refTo }}"
            *ngFor="let pdf of pdfFiles"
          ></file>
        </div>
      </div>
      <div class="bg-primary my-1 w-100" style="height: 2px"></div>
    </div>

    <div class="col mx-2">
      <div
        class="d-flex flex-row mt-3"
        [ngClass]="showFilter ? 'mt-4' : 'mt-3'"
      >
        <i class="h4 my-auto bi bi-file-earmark-play-fill"></i>
        <h4 class="mx-1 my-auto">
          {{ "INSTRUCTIONS.FILE_VIDEO" | translate }}
        </h4>
        <span class="badge bg-primary h6 my-auto mx-1">
          {{ videoFiles?.length }}
        </span>
      </div>
      <div class="bg-primary my-1 w-100" style="height: 2px"></div>
      <div
        class="pb-3"
        style="overflow-y: auto; overflow-x: hidden; height: 35rem"
      >
        <div class="row pt-4 pb-3">
          <file
            class="col-5 mb-3"
            type="{{ video.type }}"
            name="{{ video.title }}"
            href="{{ video.path }}"
            *ngFor="let video of videoFiles"
          ></file>
        </div>
      </div>
      <div class="bg-primary my-1 w-100" style="height: 2px"></div>
    </div>
  </div>
</div>
