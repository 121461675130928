import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'instructions-component',
  templateUrl: './instructions-component.component.html',
  styleUrls: ['./instructions-component.component.scss'],
})
export class InstructionsComponentComponent implements OnInit {
  folders?: any[];
  files?: any[];
  ref?: string;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    // this.route.queryParams.subscribe((value) => {
    //   this.api.getFolders(value['q']).subscribe((value) => {
    //     this.folders = value as any[];
    //   });
  
    //   this.api.getFilesByFolder(value['q']).subscribe((value) => {
    //     this.files = value as any[];
    //   });
    // })
    
  }

  go(path: string) {
    this.ref = path;
    this.router.navigateByUrl('u/instructions/home?q=' + path);
  }
}
