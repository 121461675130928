<div class="modal-header w-100">
  <div class="d-flex flex-row w-100 justify-content-between">
    <div class="d-flex">
      <i class="bi bi-images h3 my-auto"></i>
      <h4 class="mx-1 h3 mb-0 my-auto">
        {{ "WORKING_AREA.CHANGE_PHOTO" | translate }}
      </h4>
    </div>
    <div class="btn btn-primary my-auto" (click)="modalRef.close()">
      {{ "BUTTONS.CLOSE" | translate }}
    </div>
  </div>
</div>
<div class="modal-body row">
  <div class="d-flex col-4 py-2 sticky-top">
    <div class="d-flex flex-column me-2">
      <div class="d-flex justify-content-between mb-2">
        <h4 class="my-auto">{{ "WORKING_AREA.NEW_PHOTO" | translate }}</h4>
        <div class="btn btn-success my-auto" (click)="startUploading()">
          {{ "BUTTONS.UPLOAD" | translate }}
        </div>
      </div>
      <input
        class="form-control"
        type="file"
        [accept]="allowedExtensions"
        multiple
        (change)="onFileSelected($event)"
      />
      <div class="d-flex flex-column py-3" style="overflow-y: scroll">
        <div
          class="d-flex justify-content-between align-items-center mb-2 py-2 px-2 rounded border-primary"
          style="border: solid 2px"
          *ngFor="let file of files; index as i"
        >
          <i
            class="bi bi-trash2-fill h5 my-auto hover-trash"
            (click)="removeFromUploadList(i)"
          ></i>
          <div class="ms-2 w-100">
            <h5 class="my-auto">
              {{ formatString(file.fileName) }}
            </h5>
          </div>
          <div
            class="ms-1 position-relative rounded shadow-3 my-auto bg-light"
            style="height: 0.8rem; width: 13rem; overflow: hidden"
          >
            <div
              class="h-100 position-absolute left-0 bottom-0 bg-success"
              [ngStyle]="{ width: file.progress + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-8 row py-2 h-100">
    <div
      class="col-6"
      style="overflow: hidden"
      *ngFor="let photo of photos; index as i"
    >
      <div
        class="card w-100 position-relative mb-4"
        style="height: 15rem; overflow: hidden"
      >
        <img
          class="w-100 h-100 position-relative"
          style="object-fit: cover; overflow: hidden"
          [src]="photo.img"
        />
        <div
          class="position-absolute left-0 bottom-0 w-100 h-100 gradient d-flex flex-colum align-items-end"
          style="z-index: 10"
        >
          <div
            class="d-flex justify-content-between align-items-center text-white w-100 mx-3 mb-3"
          >
            <h5 class="my-auto">{{ photo?.owner }}</h5>
            <div class="d-flex">
              <div
                class="btn btn-primary my-auto me-1"
                (click)="openImageGallery(i)"
              >
                {{ "BUTTONS.VIEW" | translate }}
              </div>
              <div
                class="btn btn-danger my-auto ms-1"
                (click)="$event.preventDefault(); deletePhoto(photo, i)"
              >
                {{ "BUTTONS.REMOVE" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- class="btn btn-primary mt-2 mx-auto" -->

  <!-- <div class="d-flex flex-column py-2" style="height: 30rem; overflow-y: auto">
    <div class="row justify-content-center my-2">
      <div class="col-6">
        <h4 class="my-auto">Upload a new Photo</h4>
      </div>
      <div class="col-6">
        <input
          class="form-control my-auto"
          type="file"
          accept="image/*"
          (change)="onFileSelected($event)"
        />
      </div>
    </div>
    <div class="bg-primary w-100" style="height: 2px"></div>
    <div class="row pb-2">
      <div
        class="col-6 d-flex flex-column mt-3 align-items-center"
        *ngIf="imgSrc"
      >
        <div class="w-100 rounded mx-auto" style="height: 10rem">
          <img
            class="w-100 h-100"
            style="object-fit: cover; overflow: hidden"
            [src]="imgSrc"
          />
        </div>
        <div
          class="btn btn-primary mt-2 mx-auto"
          (click)="uploadFile()"
          *ngIf="!isUploading"
        >
          {{ "UPLOAD_PHOTO" | translate }}
        </div>

        <div
          class="datatable-loader bg-light w-50 my-auto"
          *ngIf="isUploading"
          style="height: 0.5rem"
        >
          <span class="datatable-loader-inner" style="height: 0.5rem">
            <span
              class="datatable-progress bg-primary"
              style="height: 0.5rem"
            ></span>
          </span>
        </div>
      </div>
      <div
        class="col-6 d-flex flex-column mt-3"
        *ngFor="let photo of photos; index as i"
      >
        <div
          class="w-100 rounded mx-auto"
          style="height: 10rem; overflow: hidden"
        >
          <img
            class="w-100 h-100"
            style="object-fit: cover"
            [src]="photo.img"
          />
        </div>
        <div class="d-flex justify-content-center mt-2">
          <div class="btn btn-danger my-auto" (click)="deletePhoto(photo, i)">
            {{ "WORKING_AREA.DELETE_PHOTO" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
