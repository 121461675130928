<div
  [ngClass]="
    i == 0
      ? 'rounded-top border-bottom border-3 border-primary'
      : last
      ? 'rounded-bottom'
      : 'border-bottom'
  "
  style="max-height: 15rem; min-height: 15rem; overflow-y: scroll"
  class="w-100 bg-white shadow d-flex flex-column"
>
  <div
    class="d-flex flex-row mx-2 rounded align-items-center"
    *ngFor="let item of benefit.goals; index as i"
  >
    <development-benefit-row
      class="w-100"
      [subGoal]="item.subGoal"
      [refID]="item.refID"
      [periodID]="benefit.periodID"
      [link]="item.url"
    ></development-benefit-row>
  </div>
</div>
