<div class="card mt-3 mx-1 pb-3" style="width: 50vw; overflow: hidden">
  <mdb-accordion flush="true">
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>
        {{ "NAVIGATION.CROP" | translate }}
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="pb-2" style="height: 17rem; overflow-y: auto">
          <div class="row pt-2 mb-2 px-2 mx-2 sticky-top bg-white">
            <div class="col">
              {{ "NAVIGATION.REPRODUCTIVE_MATERIALS" | translate }}
            </div>
            <div class="col">
              {{ "GMP.MEASURED_BY" | translate }}
            </div>
            <div class="col">
              {{ "GMP.RANGES" | translate }}
            </div>
            <div class="bg-primary" style="height: 2px"></div>
          </div>
          <div
            class="row py-2 px-2 mx-4 rounded"
            *ngFor="let material of crop; index as i"
            [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
          >
            <div class="col">{{ material.typeLang }}</div>
            <div class="col">{{ material.measureLang }}</div>
            <div class="col">
              <ul>
                <li *ngFor="let range of material.ranges">
                  {{ range.min }} - {{ range.max }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>

    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>
        {{ "NAVIGATION.LIVESTOCK" | translate }}
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="pb-2" style="height: 17rem; overflow-y: auto">
          <div class="row pt-2 mb-2 px-2 mx-2 sticky-top bg-white">
            <div class="col">
              {{ "NAVIGATION.REPRODUCTIVE_MATERIALS" | translate }}
            </div>
            <div class="col">
              {{ "GMP.MEASURED_BY" | translate }}
            </div>
            <div class="col">
              {{ "GMP.RANGES" | translate }}
            </div>
            <div class="bg-primary" style="height: 2px"></div>
          </div>
          <div
            class="row py-2 px-2 mx-4 rounded"
            *ngFor="let material of livestock; index as i"
            [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
          >
            <div class="col">{{ material.typeLang }}</div>
            <div class="col">{{ material.measureLang }}</div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>

    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>
        {{ "NAVIGATION.AQUACULTURE" | translate }}
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="pb-2" style="height: 17rem; overflow-y: auto">
          <div class="row pt-2 mb-2 px-2 mx-2 sticky-top bg-white">
            <div class="col">
              {{ "NAVIGATION.REPRODUCTIVE_MATERIALS" | translate }}
            </div>
            <div class="col">
              {{ "GMP.MEASURED_BY" | translate }}
            </div>
            <div class="col">
              {{ "GMP.RANGES" | translate }}
            </div>
            <div class="bg-primary" style="height: 2px"></div>
          </div>
          <div
            class="row py-2 px-2 mx-4 rounded"
            *ngFor="let material of aquaculture; index as i"
            [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
          >
            <div class="col">{{ material.typeLang }}</div>
            <div class="col">{{ material.measureLang }}</div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
