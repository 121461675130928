<div class="modal-header bg-secondary text-white w-100">
  <h5 class="modal-title mx-1 my-auto py-1">
    {{ intervention?.actionLang }}
  </h5>
  <i
    class="bi bi-x-square-fill h4 my-auto"
    style="cursor: pointer"
    (click)="modalRef.close()"
  ></i>
</div>
<div class="modal-body w-100">
  <div class="d-flex justify-content-center mb-3 text-primary">
    <h5>{{ intervention?.descriptionLang }}</h5>
  </div>
  <div
    class="w-100 card shadow-0 bg-secondary text-white mx-2"
    style="height: 200px; overflow-y: auto"
  >
    <p class="p-2">{{ intervention?.commentsLang }}</p>
  </div>
  <div class="d-flex flex-row justify-content-center my-3">
    <a
      class="btn btn-primary"
      href="{{ intervention?.pathsLang }}"
      target="_blank"
      *ngIf="intervention?.pathsLang"
      >Download PDF</a
    >
  </div>
</div>
