<div class="container-fluid p-0 m-0 h-100 bg-light">
  <database-family-navigator
    [item]="item"
    [databaseType]="getDatabaseNavigatorName()"
    [variety]="null"
  ></database-family-navigator>
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5">
      <!-- Header -->
      <div class="d-flex my-2">
        <div style="height: 7rem; width: 7rem">
          <img
            class="w-100 h-100"
            style="object-fit: contain"
            src="{{ item?.imageUrl }}"
            *ngIf="item?.imageUrl"
          />
          <div
            class="w-100 h-100 rounded-circle bg-secondary"
            style="opacity: 0.5"
            *ngIf="!item?.imageUrl"
          ></div>
        </div>
        <div class="d-flex mx-2 p-0 flex-column justify-content-center">
          <h1 class="fw-bold">{{ getName() }}</h1>
          <h5 class="mx-2 fst-italic">
            {{ item?.latinName }} -
            {{ item?.refType ? item?.refType.typeLang : "" }}
          </h5>
        </div>
      </div>
      <div class="bg-primary w-75 mt-3" style="height: 2.5px"></div>
      <div class="d-flex d-row py-0 my-0 mt-4 mb-2">
        <horizontal-list-button
          name="{{ header | translate }}"
          routerLink="varieties"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'NAVIGATION.DESCRIPTORS' | translate }}"
          routerLink="descriptors"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'NAVIGATION.MANAGEMENT' | translate }}"
          routerLink="management"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'NAVIGATION.MARKET_DESCRIPTORS' | translate }}"
          routerLink="market-descriptors"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'NAVIGATION.PROJECTS' | translate }}"
          routerLink="projects"
        ></horizontal-list-button>
      </div>
      <div class="d-flex flex-row">
        <div class="row w-100 gap-2 px-1">
          <router-outlet></router-outlet>
        </div>
        <div class="d-inline-flex flex-row" style="width: 25rem">
          <div class="bg-primary mx-2" style="width: 1.5px"></div>
          <div class="d-flex flex-column">
            <h5>Genus - Species - Subspecies</h5>
            <p class="fst-italic" *ngFor="let specie of species">
              {{ specie.specie }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
