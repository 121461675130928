import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { PointSellingType } from 'src/app/models/point-selling';

@Component({
  selector: 'gmp-types-of-points-of-selling',
  templateUrl: './gmp-types-of-points-of-selling.component.html',
  styleUrls: ['./gmp-types-of-points-of-selling.component.scss'],
})
export class GmpTypesOfPointsOfSellingComponent implements OnInit, OnDestroy {
  langSub?: Subscription;
  points?: PointSellingType[];

  constructor(private api: ApiService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.apiCalls();

    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.apiCalls();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  apiCalls() {
    this.api.getPointSellingType().subscribe((value) => {
      this.points = value as PointSellingType[];
    });
  }
}
