<div class="d-flex mx-5" [formGroup]="filterForm!">
  <div style="width: 20rem">
    <ng-select
      class="w-100"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      placeholder="{{ 'PLACEHOLDER.SPECIES' | translate }}"
      formControlName="familyID"
    >
      <ng-option *ngFor="let select of refIDs" [value]="select.value">
        {{ select.label }}
      </ng-option>
    </ng-select>
  </div>
  <div class="ms-4" style="width: 20rem">
    <ng-select
      class="w-100"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      placeholder="{{ 'PLACEHOLDER.SITE' | translate }}"
      formControlName="site"
    >
      <ng-option *ngFor="let select of sites" [value]="select.value">
        {{ select.label }}
      </ng-option>
    </ng-select>
  </div>
</div>
<div class="h-100 mx-4">
  <div class="row mt-3 mb-5 mx-2 gap-3">
    <div
      class="col d-flex bg-white card p-0 m-0"
      style="height: 50vh; overflow-y: auto"
    >
      <list-header
        class="sticky-top p-0 m-0"
        title="{{ 'NAVIGATION.LEVEL_1' | translate }}"
      ></list-header>

      <div class="d-flex flex-column p-0 m-0">
        <div
          class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2 hover"
          style="cursor: pointer"
          [ngClass]="type.selected ? 'bg-secondary-opaque text-white' : 'hover'"
          *ngFor="let type of level1; let i = index"
          (click)="onSelectLevel1(i)"
        >
          <div class="px-3 py-1 d-flex align-items-center">
            <ngx-gauge
              [type]="'arch'"
              [min]="0"
              [max]="type.max"
              [value]="type.count"
              [thick]="15"
              [size]="100"
              [label]="type.percentage"
              foregroundColor="#006db5"
              [mdbTooltip]="type.count + ' / ' + type.max"
            >
            </ngx-gauge>

            <h6 class="ms-2">
              {{ type.title }}
            </h6>
          </div>

          <i class="bi bi-caret-right-fill h5 px-3 py-2 my-auto"></i>
        </div>
      </div>
    </div>

    <div
      class="col d-flex bg-white card"
      style="height: 50vh; overflow-y: auto"
    >
      <list-header
        class="sticky-top p-0 m-0"
        title="{{ 'NAVIGATION.LEVEL_2' | translate }}"
      ></list-header>

      <div class="d-flex flex-column p-0 m-0">
        <div
          class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2"
          style="cursor: pointer"
          [ngClass]="type.selected ? 'bg-secondary-opaque text-white' : 'hover'"
          *ngFor="let type of level2; let i = index"
          (click)="loadDescriptors(i)"
        >
          <div class="px-3 py-1 d-flex align-items-center">
            <ngx-gauge
              [type]="'arch'"
              [min]="0"
              [max]="type.max"
              [value]="type.count"
              [thick]="15"
              [size]="100"
              [label]="type.percentage"
              foregroundColor="#006db5"
              [mdbTooltip]="type.count + ' / ' + type.max"
            >
            </ngx-gauge>
            <h6 class="ms-2">
              {{ type.title }}
            </h6>
          </div>

          <i
            class="bi bi-caret-right-fill h5 px-3 py-2 my-auto"
            *ngIf="type.children"
          ></i>
        </div>
      </div>
    </div>

    <div
      class="col d-flex bg-white card"
      style="height: 50vh; overflow-y: auto"
    >
      <list-header
        class="sticky-top p-0 m-0"
        title="{{ 'NAVIGATION.LEVEL_3' | translate }}"
      ></list-header>

      <div class="d-flex flex-column p-0 m-0">
        <div
          class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2"
          [ngClass]="type.selected ? 'bg-secondary-opaque text-white' : 'hover'"
          *ngFor="let type of level3; let i = index"
          (click)="focusDescriptor(i)"
        >
          <div class="px-3 py-1 d-flex align-items-center">
            <ngx-gauge
              [type]="'arch'"
              [min]="0"
              [max]="type.max"
              [value]="type.count"
              [thick]="15"
              [size]="100"
              [label]="type.percentage"
              foregroundColor="#006db5"
              [mdbTooltip]="type.count + ' / ' + type.max"
            >
            </ngx-gauge>
            <h6 class="ms-2">
              {{ type.title }}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
