<div class="modal-body">
  <div class="d-flex flex-row">
    <i class="bi bi-plus-lg h3"></i>
    <h4 class="mx-1 h3 mb-0">{{ "WORKING_AREA.ADD_BREED" | translate }}</h4>
  </div>
  <div class="bg-primary w-100" style="height: 2px"></div>

  <div class="py-3">
    <div [formGroup]="infoForm!">
      <div class="d-flex flex-column mx-5">
        <div class="d-flex">
          <h5>{{ "WORKING_AREA.BREED_NAME" | translate }}</h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="varietyName?.invalid && varietyName?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div>
          <input
            mdbValidate
            type="text"
            class="form-control"
            formControlName="varietyName"
          />
        </div>
      </div>
      <div class="d-flex flex-column my-3 mx-5">
        <div class="d-flex">
          <h5>{{ "WORKING_AREA.GENUS" | translate }}</h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="species?.invalid && species?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div class="w-100">
          <ng-select
            class="w-100"
            formControlName="species"
            [searchable]="false"
            [clearable]="false"
          >
            <ng-option *ngFor="let specie of specieList" [value]="specie.value">
              {{ specie.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="d-flex flex-column mx-5">
        <div class="d-flex">
          <h5>{{ "WORKING_AREA.VARIETY_TYPE" | translate }}</h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="material?.invalid && material?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div class="w-100 mb-5">
          <ng-select
            class="w-100"
            formControlName="material"
            [searchable]="false"
            [clearable]="false"
          >
            <ng-option *ngFor="let material of materials" [value]="material">
              {{ material.typeLang }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="d-flex flex-column mx-5" *ngIf="showSpecies">
        <div class="d-flex mt-3">
          <h5>{{ "WORKING_AREA.SELECT_HYBRID" | translate }}</h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="hybrid?.invalid && hybrid?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div class="w-100">
          <ng-select
            class="w-100"
            formControlName="hybrid"
            [clearable]="false"
            [searchable]="false"
          >
            <ng-option *ngFor="let specie of specieList" [value]="specie.value">
              {{ specie.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="d-flex flex-column mx-5" *ngIf="showVariety">
        <div class="d-flex mt-3">
          <h5>{{ "WORKING_AREA.SELECT_VARIETIES" | translate }}</h5>
          <p
            class="mx-2 text-danger mb-0"
            *ngIf="varieties?.invalid && varieties?.touched"
          >
            {{ "REGISTER.REQUIRED" | translate }}
          </p>
        </div>
        <div class="w-100">
          <ng-select
            class="w-100"
            [multiple]="true"
            formControlName="varieties"
            [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
          >
            <ng-option
              *ngFor="let variety of varietyList"
              [value]="variety.value"
            >
              {{ variety.label }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modalRef.close()">
      {{ "BUTTONS.CLOSE" | translate }}
    </button>
    <button type="button" class="btn btn-success" (click)="addBreed()">
      {{ "BUTTONS.ADD" | translate }}
    </button>
  </div>
</div>
