import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, withDebugTracing } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Image } from 'src/app/models/image';

@Component({
  selector: 'activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss'],
})
export class ActivityDetailsComponent implements OnInit {
  refID?: string;
  activityType?: string;
  activity: any;
  images: Image[] = [];

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.refID = this.route.snapshot.queryParams.refID;
    this.activityType = this.route.snapshot.queryParams.activityType;

    this.apiCall();
  }

  apiCall() {
    if (this.refID && this.activityType) {
      this.api.getActivity(this.refID, this.activityType).subscribe((value) => {
        this.activity = value as any;
        this.getImages();
      });
    }
  }

  getImages() {
    this.api
      .getImagesActivity(this.activity[`${this.activityType?.toLowerCase()}ID`])
      .subscribe((value) => {
        this.images = value as Image[];
      });
  }

  backToActivities() {
    window.history.back();
  }

  getGender(gender: string): string {
    switch (gender) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      default:
        return 'Diverse';
    }
  }
}
