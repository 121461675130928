import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { PieDataset } from 'src/app/layout/components/graphs/graph-pie/graph-pie.component';

@Component({
  selector: 'modal-me-pie',
  templateUrl: './modal-me-pie.component.html',
  styleUrls: ['./modal-me-pie.component.scss'],
})
export class ModalMePieComponent implements OnInit {
  graph?: any;
  familyID?: string;
  periodID?: string;

  dataset?: PieDataset[];

  datasetPlaceholder: PieDataset[] = [
    {
      data: [2112, 2343, 2545],
      backgroundColor: [
        '#123425',
        'rgba(77, 182, 172, 0.5)',
        'rgba(66, 133, 244, 0.5)',
      ],
      labels: ['Monday', 'Tuesday', 'Wednesday'],
      varietiesByData: new Map(),
    },
  ];

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalMePieComponent>
  ) {}

  ngOnInit(): void {
    this.randomize();
    this.api
      .getPeriodGraphValue(this.graph.link, this.periodID!, this.familyID!)
      .subscribe((value) => {
        this.dataset = [value as PieDataset];
      });
  }

  randomNumber(): number {
    return Math.floor(Math.random() * 100);
  }

  randomize() {
    if (this.dataset == undefined) {
      this.datasetPlaceholder = [
        {
          data: [this.randomNumber(), this.randomNumber(), this.randomNumber()],
          backgroundColor: [
            'rgba(63, 81, 181, 0.5)',
            'rgba(77, 182, 172, 0.5)',
            'rgba(66, 133, 244, 0.5)',
          ],
          labels: [],
          varietiesByData: new Map(),
        },
      ];
      setTimeout(() => this.randomize(), 1000);
    }
  }
}
