import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { AppState } from '../../reducer';

@Component({
  selector: 'forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  resetForm?: UntypedFormGroup;

  success?: string;
  error?: string = undefined;

  loading$?: Subscription;
  loading: boolean = false;

  constructor(private store: Store<AppState>, private api: ApiService) {
    this.resetForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnInit(): void {}

  get email() {
    return this.resetForm?.get('email');
  }

  resetPassword() {
    this.error = undefined;
    this.success = undefined;

    if (this.resetForm?.invalid) {
      this.resetForm?.markAllAsTouched();
      return;
    }
    this.loading = true;
    this.api.resetPassword(this.email?.value).subscribe(
      () => {
        this.success = 'You will receive an email with your new password';
        this.loading = false;
      },
      (error) => {
        this.loading = false;

        if (error.status === 404) {
          this.error = 'User not found';
        } else {
          this.error = error.status;
        }
      }
    );
  }
}
