import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-disable-answer',
  templateUrl: './modal-disable-answer.component.html',
  styleUrls: ['./modal-disable-answer.component.scss'],
})
export class ModalDisableAnswerComponent implements OnInit {
  disabled?: boolean;
  refID?: string;
  link?: string;
  error?: string;
  measure?: string;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalDisableAnswerComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}

  switchState() {
    this.api
      .switchDisableAnswer(this.link!, this.refID!, this.measure)
      .subscribe({
        next: (value) => {
          this.modalRef.close(value);
        },
        error: (error) => {
          this.error = error.status;
        },
      });
  }
}
