import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'file-analysis-livestock',
  templateUrl: './file-analysis-livestock.component.html',
  styleUrls: ['./file-analysis-livestock.component.scss']
})
export class FileAnalysisLivestockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
