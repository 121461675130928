<div class="container-fluid p-0 m-0 h-100 bg-light">
  <database-family-navigator
    [item]="getItemName()"
    [databaseType]="getDatabaseNavigatorName()"
    [variety]="variety"
  ></database-family-navigator>
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5">
      <div class="d-flex my-2 justify-content-between w-75">
        <div class="d-flex flex-row">
          <div style="height: 7rem; width: 7rem">
            <img
              class="w-100 h-100"
              style="object-fit: contain"
              [src]="getImage()"
              *ngIf="variety"
            />
            <div
              class="w-100 h-100 rounded-circle bg-secondary"
              style="opacity: 0.5"
              *ngIf="!variety"
            ></div>
          </div>
          <div class="d-flex mx-2 p-0 flex-column justify-content-center">
            <div class="d-flex flex-row">
              <h1 class="fw-bold">{{ variety?.name }}</h1>
              <!-- <p class="p-0 mx-2 my-auto">
                <u class="pointer" (click)="goTo()">
                  {{ "DATABASE" + getBackLabel() | translate }}
                </u>
              </p> -->
            </div>
            <h5 class="mx-2 fst-italic">
              {{ variety?.species?.specie }}
            </h5>
          </div>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center card px-3 my-2"
          *ngIf="varietyInfo?.showCountryOrigin"
        >
          <h5>{{ varietyInfo?.datarID }}</h5>
          <h6>
            {{ "DATABASE.COUNTRY_NAME" | translate }}:
            {{ varietyInfo?.countryName }}
          </h6>
          <h6>
            {{ "DATABASE.ORIGIN_COUNTRY" | translate }}:
            {{ varietyInfo?.countryOrigin }}
          </h6>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center card px-3 my-2"
          *ngIf="!varietyInfo?.showCountryOrigin"
        >
          <h5>{{ varietyInfo?.datarID }}</h5>
          <h6>
            {{ "DATABASE.CREATED" | translate }}:
            {{ varietyInfo?.creationDate | date }}
          </h6>
        </div>
      </div>
      <div class="bg-primary w-75 mt-3" style="height: 2.5px"></div>
      <div class="d-flex d-row py-0 my-0 mt-4 mb-2">
        <horizontal-list-button
          name="{{ 'NAVIGATION.DESCRIPTORS' | translate }}"
          routerLink="descriptors"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'NAVIGATION.G_M_P' | translate }}"
          routerLink="genetic-material-provider"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'NAVIGATION.PROJECTS' | translate }}"
          routerLink="projects"
        ></horizontal-list-button>

        <horizontal-list-button
          name="{{ 'NAVIGATION.GALLERY' | translate }}"
          routerLink="gallery"
        ></horizontal-list-button>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
