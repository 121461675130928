import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ManagementDescriptor } from 'src/app/models/management-category';

@Component({
  selector: 'modal-management',
  templateUrl: './modal-management.component.html',
  styleUrls: ['./modal-management.component.scss'],
})
export class ModalManagementComponent implements OnInit {
  descriptorID?: string;
  descriptor?: ManagementDescriptor;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalManagementComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.api
      .getManagementDescriptor(this.descriptorID || '')
      .subscribe((value) => {
        this.descriptor = value as ManagementDescriptor;
      });
  }
}
