<div class="d-flex flex-column w-100 mt-3">
  <div class="sticky-top bg-light">
    <div
      class="d-flex flex-row mt-3 align-items-center justify-content-between"
    >
      <div class="d-flex flex-row my-auto">
        <div style="height: 5rem; width: 5rem">
          <img
            class="w-100 h-100"
            style="object-fit: contain"
            src="../../../assets/images/logo.png"
          />
        </div>
        <h1 class="fw-bold mx-2 my-auto">
          {{ "NAVIGATION.COUNTRIES" | translate }}
        </h1>
      </div>

      <div class="my-auto">
        <ng-select
          class="control"
          placeholder="{{ 'PLACEHOLDER.SEARCH_COUNTRY' | translate }}"
          [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
          (change)="countrySelected($event)"
          [items]="countries!"
          bindLabel="shortName"
          bindValue="countryID"
          *ngIf="countries"
        >
        </ng-select>
        <!-- <mdb-select
          [filter]="true"
          placeholder="{{ 'PLACEHOLDER.SEARCH_COUNTRY' | translate }}"
        >
          <mdb-option
            *ngFor="let country of countries; index as i"
            [value]="i"
            (mouseenter)="onMouseEnter(myMap, country)"
            (mouseleave)="onMouseLeave(myMap, country)"
            (click)="countrySelected(country.shortName || '')"
          >
            {{ country.shortName }}
          </mdb-option>
        </mdb-select> -->
      </div>
    </div>

    <div class="pb-4 d-flex flex-column">
      <div class="row justify-content-center mt-5">
        <div class="col-6" style="overflow: hidden">
          <mdb-vector-map
            #myMap
            [zoomEvents]="false"
            (selected)="countryMapSelected($event)"
            hoverFill="rgb(79, 79, 79)"
            selectFill="rgb(79, 79, 79)"
            class="position-relative"
          ></mdb-vector-map>
        </div>
      </div>
    </div>
  </div>
</div>
