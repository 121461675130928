import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-delete-project',
  templateUrl: './modal-delete-project.component.html',
  styleUrls: ['./modal-delete-project.component.scss'],
})
export class ModalDeleteProjectComponent implements OnInit {
  projectID?: string;

  loading: boolean = false;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalDeleteProjectComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}

  deleteProject() {
    if (this.projectID) {
      this.loading = true;
      this.api.deleteProjectUser(this.projectID).subscribe(
        () => {
          this.modalRef.close(true);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    }
  }
}
