<div class="modal-header bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-3">
      <div class="d-flex align-items-center mb-2">
        <!-- TITLE -->
        <div class="d-flex align-items-center mt-2" [formGroup]="filterForm!">
          <i
            class="bi bi-arrow-left-square-fill h1 pointer my-auto"
            (click)="modalRef.close()"
          ></i>
          <h1 class="fw-bold my-auto mx-3">
            {{ "VARIETY_CLEANING.VIEW_DESCRIPTORS" | translate }}
          </h1>

          <div class="mx-1 my-auto">
            <ng-select
              placeholder="{{ 'PLACEHOLDER.TYPE' | translate }}"
              formControlName="type"
              [searchable]="false"
              [clearable]="false"
            >
              <ng-option
                *ngFor="let type of types"
                [value]="type.value"
                [mdbTooltip]="type.label"
              >
                {{ type.label }}
              </ng-option>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-body bg-light">
  <div class="d-flex flex-row mt-3 mx-5 h-100">
    <div
      class="rounded border-primary"
      style="
        border: 2px solid;
        width: 75%;
        position: relative;
        overflow: scroll;
        min-height: 80vh;
        max-height: 80vh;
      "
    >
      <div
        class="d-flex w-100 justify-content-center"
        *ngIf="rows.length == 0 && !loading && !isHeaderEmpty"
      >
        <disclaimer
          class="mt-4"
          text="{{ 'WORKING_AREA.SELECT_A_TYPE' | translate }}"
        ></disclaimer>
      </div>

      <div
        class="d-flex w-100 justify-content-center"
        *ngIf="isHeaderEmpty && !loading"
      >
        <disclaimer class="mt-4" text="WORKING_AREA.DISCLAIMER_1"></disclaimer>
      </div>

      <table *ngIf="columnHeaders.length != 0 && rows.length != 0 && !loading">
        <thead>
          <tr *ngIf="columnHeaders">
            <th
              class="bg-light border-primary rounded-5 shadow-2"
              style="z-index: 20"
            ></th>
            <!-- DESCRIPTOR CLICKED -->
            <th
              class="pointer px-2 py-0 rounded-5 shadow-2"
              style="max-height: 4rem; min-height: 4rem"
              *ngFor="
                let column of getColumnHeaderLivestock();
                index as columnIndex
              "
              [attr.colspan]="column.span"
              [ngClass]="
                selectedColumn == columnIndex
                  ? 'cell-selected text-white'
                  : 'bg-white text-primary'
              "
              (click)="selectColumn(columnIndex)"
            >
              <div
                class="py-2 text-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
              >
                {{ column.name }}
              </div>
            </th>
          </tr>
          <!-- DESCRIPTOR LIVESTOCK CLICKED -->
          <tr
            *ngIf="
              columnHeaders &&
              workingArea?.ref === 'LIVESTOCK' &&
              columnHeaders[0].gender
            "
          >
            <th
              class="th-ga rounded-5 shadow-2 bg-white"
              style="z-index: 20"
            ></th>
            <th
              class="th-ga text-white border-primary pointer px-2 py-0"
              *ngFor="let column of columnHeaders; index as columnIndex"
              [ngClass]="
                selectedColumns.includes(columnIndex)
                  ? 'cell-selected text-white'
                  : switchGenderColor(column.gender)
              "
              (click)="getDescriptorByGA(columnIndex, column.refID)"
            >
              <div
                class="py-2 text-center d-flex align-items-center justify-content-center"
                style="max-height: 2rem; min-height: 2rem"
              >
                <span
                  class="my-auto"
                  [mdbTooltip]="getToolTip(column) | translate"
                >
                  {{
                    column.age === "A"
                      ? ("WORKING_AREA.ADULT" | translate)
                      : ("WORKING_AREA.JUVENILE" | translate)
                  }}
                  <i
                    class="bi"
                    [ngClass]="
                      column.gender === 'F'
                        ? 'bi-gender-female'
                        : 'bi-gender-male'
                    "
                  ></i>
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="rows">
          <tr *ngFor="let row of rows; index as rowIndex">
            <th
              class="rounded-5 shadow-2 pointer"
              style="z-index: 10"
              [ngClass]="
                selectedRow == rowIndex
                  ? 'cell-selected text-white'
                  : 'bg-white text-primary'
              "
              (click)="getGeneralInfos(row.fgdID, row.refID, rowIndex)"
            >
              <span class="my-auto">{{ row.name }}</span>
            </th>
            <!-- CELL CLICKED -->
            <td
              class="text-center pointer m-1 rounded-5 shadow-2"
              [ngClass]="getBackgroundCell(rowIndex, answerIndex)"
              *ngFor="let answer of row.answers; index as answerIndex"
              (click)="getDetail(answer, rowIndex, answerIndex)"
            >
              <div
                class="d-flex align-items-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
              >
                <span class="my-auto w-100 text-center">
                  {{ answer.answer }}
                </span>
                <i
                  class="bi bi-chat-left-text-fill my-auto"
                  *ngIf="answer.hasNote"
                ></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <loading
        [glass]="false"
        class="w-100 h-100 mask"
        *ngIf="loading"
      ></loading>
    </div>
    <div
      class="border-primary rounded d-flex flex-column align-items-center justify-content-between mx-2"
      style="
        width: 25%;
        border: 2px solid;
        min-height: 80vh;
        max-height: 80vh;
        overflow-y: auto;
      "
    >
      <div class="d-flex flex-column w-100">
        <h4 class="mx-auto mt-2">{{ "GRID.TABLE_TITLE" | translate }}</h4>
        <div class="bg-primary w-100" style="height: 2px"></div>
        <div
          class="d-flex flex-column"
          *ngIf="!isGeneralInfosOpened && !isLegendOpened && !isDetailOpened"
        >
          <h4 class="mx-auto mt-2">{{ "GRID.INSTRUCTIONS" | translate }}</h4>
          <ul>
            <li class="h5 mt-4">
              {{ "WORKING_AREA.POINT_3" | translate }}
            </li>
            <li class="h5 mt-4">
              {{ "WORKING_AREA.POINT_4" | translate }}
            </li>
            <li class="h5 mt-4">
              {{ "VARIETY_CLEANING.POINT_1" | translate }}
            </li>
          </ul>
        </div>

        <div
          class="d-flex flex-column mt-2 text-center"
          *ngIf="isLegendOpened"
        ></div>

        <div class="w-100 h-100 d-flex flex-column mt-2" *ngIf="isDetailOpened">
          <div class="d-flex flex-column mt-1">
            <h4 class="text-center mb-2">
              {{ "VARIETY_CLEANING.ANSWER_DETAILS" | translate }}
            </h4>

            <div class="d-flex flex-column px-3" style="overflow-y: auto">
              <div
                class="rounded p-2 mb-2 bg-white shadow-2 d-flex flex-column"
                *ngFor="let item of answerInfo; index as i"
              >
                <span>
                  {{ "PLACEHOLDER.SITE" | translate }}:
                  {{ item.site }}
                </span>
                <span *ngIf="item.fgd">FGD ID: {{ item.fgd }}</span>
                <span *ngIf="item.hhs">HHS ID: {{ item.hhs }}</span>
                <span>
                  {{ "VARIETY_CLEANING.NAME" | translate }}:
                  {{ item.name }}
                </span>
                <hr class="my-2" />
                <h6 *ngIf="item.answer">
                  {{ item.answer != "" ? item.answer : "No Answer" }}
                </h6>
                <hr class="my-2" *ngIf="item.note" />
                <div class="d-flex align-items-start" *ngIf="item.note">
                  <i class="bi-chat-left-text-fill h5 me-2 pt-1"></i>
                  <div class="px-2 w-100">
                    <span>{{ item.note }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="d-flex flex-column mt-auto">
              <span>Notes:</span>
              <div class="rounded border-2"></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer bg-light">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
</div>
