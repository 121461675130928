<div class="pb-4 mb-4">
  <div class="d-flex w-100 mb-4" [formGroup]="filterForm!" *ngIf="!loading">
    <div class="d-flex w-100 justify-content-between">
      <div class="d-flex">
        <div class="btn btn-primary mx-2" (click)="back()">Back</div>
        <ng-select
          [placeholder]="'PLACEHOLDER.SPECIES' | translate"
          class="control mx-2"
          [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
          formControlName="species"
          [clearable]="false"
        >
          <ng-option *ngFor="let option of species" [value]="option.value">
            {{ option.label }}
          </ng-option>
        </ng-select>

        <ng-select
          [placeholder]="'PLACEHOLDER.GOAL' | translate"
          class="control mx-2"
          [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
          formControlName="goal"
          [clearable]="false"
        >
          <ng-option *ngFor="let option of goals" [value]="option.value">
            {{ option.label }}
          </ng-option>
        </ng-select>
      </div>

      <ng-select
        [placeholder]="'PLACEHOLDER.PERIOD' | translate"
        class="control mx-2"
        [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
        formControlName="period"
        [clearable]="false"
      >
        <ng-option
          *ngFor="let option of periods; index as i"
          [value]="option.periodID"
        >
          {{
            option.title ?? ("PLACEHOLDER.PERIOD" | translate) + " " + (i + 1)
          }}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <div class="mt-4" *ngIf="loading">
    <loading [glass]="false"></loading>
  </div>
  <div class="d-flex flex-column" *ngIf="goal?.value && !loading">
    <div
      class="bg-secondary text-white rounded p-3 mb-4 d-flex justify-content-between align-items-center"
    >
      <div class="w-100">
        <h5 class="my-auto">
          {{ getGoalTitle() }}
        </h5>
      </div>
      <div class="btn btn-primary" (click)="openAddSubGoal()">
        <i class="fas fa-plus"></i>
      </div>
    </div>
    <mdb-accordion>
      <mdb-accordion-item *ngFor="let item of subGoals; index as i">
        <ng-template mdbAccordionItemHeader>
          <div
            class="d-flex flex-row w-100 justify-content-between align-items-center"
          >
            <div class="d-flex flex-column">
              <h5>
                {{ item.subgoal }}
              </h5>
              <div class="d-flex">
                <span
                  class="me-2"
                  *ngFor="let survey of item.surveys; last as last"
                >
                  <strong>{{ survey.value + ": " }}</strong>
                  <span> {{ survey.label }} </span>
                  <span *ngIf="!last"> - </span>
                </span>
              </div>
            </div>
            <div class="d-flex">
              <!-- <div
                class="btn btn-primary me-2"
                (click)="openShowGoalDescriptors(item)"
                *ngIf="item.descriptors.length > 0"
              >
                {{ "BUTTONS.SHOW" | translate }}
              </div> -->
              <div
                class="btn btn-danger me-2"
                (click)="confirmDeleteSubgoal(item)"
              >
                {{ "BUTTONS.DELETE" | translate }}
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template mdbAccordionItemBody>
          <div class="d-flex flex-column px-4 mt-4">
            <div class="w-100 d-flex">
              <div
                class="btn btn-primary mb-2"
                (click)="openAddDescriptor(item)"
              >
                {{ "ME.ADD_DESCRIPTOR" | translate }}
              </div>
            </div>
            <div
              *ngFor="let item of item.descriptors; index as i"
              class="d-flex justify-content-between align-items-center p-3 rounded my-2"
              [ngClass]="i % 2 == 0 ? 'bg-light' : ''"
            >
              <div class="d-flex flex-column">
                <h6>{{ item.descriptor }}</h6>
                <div [ngSwitch]="item.answerType">
                  <div class="d-flex" *ngSwitchCase="'MULTIPLE'">
                    <strong class="me-2">Answers:</strong>
                    <span
                      *ngFor="
                        let answer of item.answerIntensity;
                        last as lastAnswer
                      "
                    >
                      {{ answer.label }}
                      <span class="mx-1" *ngIf="answer.intensity">
                        (<span
                          *ngFor="
                            let intensity of answer.intensity;
                            last as lastIntensity
                          "
                        >
                          {{ "WORKING_AREA." + intensity | translate }}
                          {{ lastIntensity ? "" : ", " }}
                        </span>
                        )
                      </span>
                      <span *ngIf="!lastAnswer" class="mx-2"> - </span>
                    </span>
                  </div>
                  <div class="d-flex" *ngSwitchCase="'BOOLEAN'">
                    <strong class="me-2">Answer:</strong>
                    <span>{{ item.answerYesNo == true ? "Yes" : "No" }}</span>
                  </div>
                  <div class="d-flex" *ngSwitchCase="'NUMBER'">
                    <strong class="me-2">Answer:</strong>
                    <span *ngIf="item.minMaxBetween == 'MIN'">
                      {{ "ME.LT" | translate }} {{ item.answerMax }}
                    </span>
                    <span *ngIf="item.minMaxBetween == 'MAX'">
                      {{ "ME.GT" | translate }} {{ item.answerMin }}
                    </span>
                    <span *ngIf="item.minMaxBetween == 'BETWEEN'">
                      {{ "ME.BT" | translate }} {{ item.answerMin }} and
                      {{ item.answerMax }}
                    </span>
                  </div>
                  <div *ngSwitchDefault></div>
                </div>
              </div>
              <i
                class="bi-trash2-fill text-danger pointer"
                (click)="confirmDeleteAnswer(item)"
              ></i>
            </div>
            <div
              class="py-3 w-100 d-flex justify-content-center"
              *ngIf="item.descriptors.length == 0"
            >
              <h5>{{ "ME.NO_DESCRIPTORS" | translate }}</h5>
            </div>
          </div>
        </ng-template>
      </mdb-accordion-item>
    </mdb-accordion>
  </div>
</div>
<div style="height: 100px"></div>
