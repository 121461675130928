import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { ModalClosePeriodComponent } from 'src/app/modals/projects/modal-close-period/modal-close-period.component';
import { ModalEditPeriodTitleComponent } from 'src/app/modals/projects/modal-edit-period-title/modal-edit-period-title.component';
import { Period } from 'src/app/models/period';
import {
  ProjectPageListener,
  ProjectPermissions,
} from 'src/app/models/project-page-helper';

@Component({
  selector: 'project-timeline',
  templateUrl: './project-timeline.component.html',
  styleUrls: ['./project-timeline.component.scss'],
})
export class ProjectTimelineComponent implements OnInit, OnDestroy {
  projectID?: string;
  ref?: string;
  periods?: Period[];

  projectPageHelper$?: Subscription;
  projectPermissions?: ProjectPermissions;

  constructor(
    private api: ApiService,
    private modalService: MdbModalService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private projectPageListener: ProjectPageListener
  ) {}

  ngOnInit(): void {
    this.projectID = this.route.parent?.snapshot.paramMap.get('id') || '';
    this.ref = this.route.parent?.snapshot.paramMap.get('ref') || '';
    this.api.getPeriods(this.projectID).subscribe((value) => {
      this.periods = value as Period[];
    });

    this.projectPageHelper$ = this.projectPageListener.data.subscribe(
      (data) => {
        if (data.projectPermissions) {
          this.projectPermissions = data.projectPermissions;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.projectPageHelper$?.unsubscribe();
  }

  openEditPeriod(i: number, period: Period) {
    const modalRef = this.modalService.open(ModalEditPeriodTitleComponent, {
      data: { period: period },
    });
    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.periods = message;
      }
    });
  }

  openClosePeriod(period: Period) {
    const modalRef = this.modalService.open(ModalClosePeriodComponent, {
      data: { period: period },
    });

    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.api.getPeriods(this.projectID!).subscribe((value) => {
          this.periods = value as Period[];
        });
      }
    });
  }

  openPeriod(periodID: String) {
    this.router.navigateByUrl('/u/period/' + this.ref + '/' + periodID);
  }
}
