<div class="modal-content">
  <div class="modal-header">
    <h4>{{ title }}</h4>
  </div>
  <div class="modal-body d-flex flex-column">
    <h5 *ngIf="subtitle">{{ subtitle }}</h5>
    <ol>
      <li *ngFor="let variety of varieties">{{ variety.label }}</li>
    </ol>
  </div>
  <div class="modal-footer">
    <div class="btn btn-primary" (click)="modalRef.close()">
      {{ "BUTTONS.CLOSE" | translate }}
    </div>
  </div>
</div>
