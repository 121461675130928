import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MdbTableDirective } from 'mdb-angular-ui-kit/table';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { ProjectPageListener } from 'src/app/models/project-page-helper';
import { Pageable } from 'src/app/models/pageable';
import { ProjectUser } from 'src/app/models/project';

@Component({
  selector: 'project-users',
  templateUrl: './project-users.component.html',
  styleUrls: ['./project-users.component.scss'],
})
export class ProjectUsersComponent implements OnInit, OnDestroy {
  @ViewChild('tableSearch') searchTable?: MdbTableDirective<any>;

  projectPageHelper$?: Subscription;

  page: number = 0;
  filter?: string;
  sort: string = 'name';
  direction: string = 'ASC';
  role?: string | null;

  filterForm?: UntypedFormGroup;

  entries: number = 10;
  entriesOption: number[] = [];

  loading: boolean = true;

  users?: ProjectUser[];

  headers = ['Name', 'Surname', 'E-Mail', 'Role', 'Status'];

  dataSource?: Pageable;

  loaded = false;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private projectPageListener: ProjectPageListener
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      inputFilter: new UntypedFormControl(null),
    });

    this.projectPageHelper$ = this.projectPageListener.data.subscribe(
      (data) => {
        if (data.userAdded) this.loadUsers();
      }
    );

    this.inputFilter?.valueChanges
      .pipe(debounceTime(500))
      .subscribe((filter) => {
        this.filter = filter;
        this.loadUsers();
      });

    this.loadUsers();
  }

  ngOnDestroy(): void {
    this.projectPageHelper$?.unsubscribe();
  }

  get inputFilter() {
    return this.filterForm?.get('inputFilter');
  }

  loadUsers() {
    const id = this.route.parent?.snapshot.paramMap.get('id') as string;
    this.api
      .getProjectUsers(
        id,
        this.page,
        this.filter,
        this.sort,
        this.direction,
        this.role
      )
      .subscribe((value) => {
        this.dataSource = value as Pageable;
        this.loaded = true;
        this.users = this.dataSource.content as ProjectUser[];
        if (this.dataSource?.totalPages < 2 && this.page > 0)
          this.onPageClick(0);
      });
  }

  search(event: string) {
    this.filter = event;
    setTimeout(() => {
      this.loadUsers();
    }, 500);
  }

  onPageClick(page: number) {
    this.page = page;
    this.loadUsers();
  }

  getStatus(user: ProjectUser) {
    if (user.accepted) {
      return 'ACCEPTED';
    } else if (user.rejected) {
      return 'DECLINED';
    } else {
      return 'PENDING';
    }
  }

  onSortClick(sortBy: string) {
    if (this.sort === sortBy) {
      this.direction = this.direction === 'ASC' ? 'DESC' : 'ASC';
    } else {
      this.sort = sortBy;
      this.direction = 'ASC';
    }
    this.loadUsers();
  }

  onRoleClick(role: string) {
    if (this.role === role) {
      this.role = null;
    } else {
      this.role = role;
    }
    this.loadUsers();
  }
}
