import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'database-market-descriptors',
  templateUrl: './database-market-descriptors.component.html',
  styleUrls: ['./database-market-descriptors.component.scss'],
})
export class DatabaseMarketDescriptorsComponent implements OnInit, OnDestroy {
  types: any[] = [];
  subTypes: any[] = [];
  descriptors: any[] = [];
  langSub?: Subscription;

  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getTypes();

    this.translate.onLangChange.subscribe(() => {
      this.getTypes();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  getTypes() {
    let databaseType: string = this.getCurrentLocation().toUpperCase();
    if (databaseType === 'CROP') databaseType = 'CROPS';
    if (databaseType === 'AQUACULTURE') databaseType = 'AQUATICS';

    this.api.getMarketCategories(databaseType).subscribe((value) => {
      this.types = value as Array<any>;
    });
  }

  getCurrentLocation() {
    const path = this.router.url;
    return path.split('/')[3];
  }

  getImage() {
    const base: string = '../../../assets/images/';
    const location: string = this.getCurrentLocation();
    const image = base + location + '.svg';
    return image;
  }

  onSelectType(i: number) {
    this.types?.filter((value) => {
      value.selected = false;
    });
    this.subTypes?.filter((value) => {
      value.selected = false;
    });
    this.descriptors?.filter((value) => {
      value.selected = false;
    });
    this.types[i].selected = true;
    this.descriptors = [];
    this.subTypes = this.types[i]?.subcategories;
  }

  loadDescriptors(i: number) {
    this.subTypes?.filter((value) => {
      value.selected = false;
    });
    this.descriptors?.filter((value) => {
      value.selected = false;
    });
    this.descriptors = [];
    this.descriptors = this.subTypes[i]?.descriptors;
    this.subTypes[i].selected = true;
  }

  selectDescriptor(i: number) {
    this.descriptors?.filter((value) => {
      value.selected = false;
    });
    this.descriptors[i].selected = true;
  }
}
