import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { Role } from 'src/app/auth/models/role.model';
import { User } from 'src/app/auth/models/user.model';
import { AppState } from 'src/app/auth/reducer';
import { getUser } from 'src/app/auth/store/auth.selectors';
import {
  ProjectPageHelper,
  ProjectPageListener,
} from 'src/app/models/project-page-helper';
import * as actions from '../../auth/store/auth.action';

@Component({
  selector: 'change-country',
  templateUrl: './change-country.component.html',
  styleUrls: ['./change-country.component.scss'],
})
export class ChangeCountryComponent implements OnInit, OnDestroy {
  user$?: Subscription;
  projectPageHelper: ProjectPageHelper = new ProjectPageHelper();

  user?: User;
  roles?: Array<Role>;

  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  changeCountryClass: string = 'panel';

  constructor(
    private store: Store<AppState>,
    private api: ApiService,
    private projectPageListener: ProjectPageListener,
    public modalRef: MdbModalRef<ChangeCountryComponent>
  ) {}

  ngOnInit(): void {
    this.user$ = this.store.select(getUser).subscribe((user) => {
      this.user = user as User;
      this.roles = user?.roles.filter((item) => {
        return item.role === this.user?.currentRole.role;
      });
    });
  }

  ngOnDestroy(): void {
    this.user$?.unsubscribe;
  }

  roleChanged(uuid: string) {
    this.api.changeRole(uuid).subscribe({
      complete: () => {
        this.projectPageHelper.countryChanges = true;
        this.projectPageListener.setData(this.projectPageHelper);
        this.modalRef.close();
      },
    });
  }
}
