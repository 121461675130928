import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'modal-confirm-delete-intervention',
  templateUrl: './modal-confirm-delete-intervention.component.html',
  styleUrls: ['./modal-confirm-delete-intervention.component.scss'],
})
export class ModalConfirmDeleteInterventionComponent implements OnInit {
  constructor(
    public modalRef: MdbModalRef<ModalConfirmDeleteInterventionComponent>
  ) {}

  ngOnInit(): void {}
}
