import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { FiltersHelper, FiltersListener } from 'src/app/models/filter-list';

@Component({
  selector: 'livestock',
  templateUrl: './livestock.component.html',
  styleUrls: ['./livestock.component.scss'],
})
export class LivestockComponent implements OnInit {
  @ViewChild('stickyNav') stickyNav?: ElementRef;

  lang$?: Subscription;
  filterForm?: UntypedFormGroup;
  dividerColor: String = 'bg-transparent';
  pointerClass: string = 'bg-primary w-75';
  filterCrop: FiltersHelper = new FiltersHelper();
  isListOpen?: boolean = true;

  types?: any[];

  constructor(
    private router: Router,
    private filterList: FiltersListener,
    private api: ApiService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
    });

    window.addEventListener('scroll', this.onScroll);
    this.filterCrop.clearFilter();
    this.filterList.setData(this.filterCrop);

    this.getTypes();

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.getTypes();
    });

    this.type?.valueChanges.subscribe((value) => {
      this.filterCrop.type = value;
      this.filterList.setData(this.filterCrop);
    });

    this.filter?.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.filterCrop.filter = value;
      this.filterList.setData(this.filterCrop);
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScroll);
    this.lang$?.unsubscribe;
  }

  getTypes() {
    this.api.getRefTypes('LIVESTOCK').subscribe((data) => {
      this.types = data as any[];
    });
  }

  get filter() {
    return this.filterForm?.get('filter');
  }

  get type() {
    return this.filterForm?.get('type');
  }

  onScroll = () => {
    if (this.stickyNav?.nativeElement.getBoundingClientRect().top == 0) {
      this.dividerColor = 'bg-secondary';
    } else {
      this.dividerColor = 'bg-transparent';
    }
  };

  getCurrentLocation() {
    const path = this.router.url;
    this.isListOpen = path.split('/')[4] === 'list' ? true : false;
  }

  selectNotReady() {
    if (!this.filterCrop.ready && this.filterCrop.notReady) return;
    this.filterCrop.notReady = !this.filterCrop.notReady;
    this.filterList.setData(this.filterCrop);
  }

  selectReady() {
    if (!this.filterCrop.notReady && this.filterCrop.ready) return;
    this.filterCrop.ready = !this.filterCrop.ready;
    this.filterList.setData(this.filterCrop);
  }
}
