import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbVectorMapComponent } from 'mdb-angular-vector-maps';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'item-projects-map',
  templateUrl: './item-projects-map.component.html',
  styleUrls: ['./item-projects-map.component.scss'],
})
export class ItemProjectsMapComponent implements OnInit {
  @ViewChild('myMap') myMap?: MdbVectorMapComponent;

  advancedColorMap: any[] = [];

  countries: any[] = [];

  legendMin: string | null = null;
  legendMax: string | null = null;

  activeField: any;

  colors: any = {
    total: [
      '#80B6DA',
      '#60A4D1',
      '#4092C8',
      '#207FBE',
      '#006DB5',
      '#005F9E',
      '#005288',
      '#004471',
      '#00375A',
    ],
    projectCrops: [
      '#F6B993',
      '#F4A777',
      '#F2955C',
      '#EF8441',
      '#ED7226',
      '#CF6421',
      '#B2561D',
      '#944718',
      '#763913',
    ],
    projectAquatics: [
      '#C1DDAB',
      '#B2D495',
      '#A2CC80',
      '#93C36B',
      '#83BB56',
      '#73A44B',
      '#628C41',
      '#527536',
      '#425D2B',
    ],
    projectLivestock: [
      '#A3B59B',
      '#8CA381',
      '#759168',
      '#5E7E4F',
      '#476C36',
      '#3E5F2F',
      '#355129',
      '#2C4422',
      '#24361B',
    ],
  };

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const id = this.route.parent?.snapshot.paramMap.get('id') || '';
    this.api.getStatsCountriesByFamily(this.getRef(), id).subscribe((value) => {
      this.countries = value as any[];
      this.advancedColorMap = [...this.getColorMap()];
    });
  }

  getRef(): string {
    const path = this.router.url;
    const id = path.split('/')[3];
    switch (id) {
      case 'c':
        this.activeField = 'projectCrops';
        return 'CROPS';
      case 'l':
        this.activeField = 'projectLivestock';
        return 'LIVESTOCK';
      case 'a':
        this.activeField = 'projectAquatics';
        return 'ACQUATICS';
      default:
        return '';
    }
  }

  private getColorMap() {
    const countriesEnable = this.countries.filter(
      (value) => value[this.activeField] > 0
    );
    const values = countriesEnable.map((entry: any) => entry[this.activeField]);

    let max = Math.max(...values);
    let min = Math.min(...values);

    if (min === -max) {
      min = 1;
      max = 10;
    }
    if (min < 9) min = 1;

    if (max - min < 9) {
      max = min + 9;
    }

    this.legendMin = `${min}`;
    this.legendMax = `${max}`;

    const step = Math.floor(
      (max - min) / (this.colors[this.activeField].length - 1)
    );

    const colorMap: any[] = this.colors[this.activeField].map(
      (color: string, i: number) => {
        return {
          fill: color,
          regions: [],
        };
      }
    );

    values.forEach((value, i) => {
      const color = Math.floor((value - min) / 9);

      colorMap[color].regions?.push({
        id: countriesEnable[i].cnIso2,
        tooltip: value + (value == 1 ? ' Project' : ' Projects'),
        ...countriesEnable[i],
      });
    });

    return colorMap;
  }
}
