import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionsPageComponent } from './instructions-page/instructions-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FolderComponent } from './components/folder/folder.component';
import { FileComponent } from './components/file/file.component';
import { InstructionsComponentComponent } from './instructions-component/instructions-component.component';
import { MdbModule } from '../mdb/mdb.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    InstructionsPageComponent,
    FolderComponent,
    FileComponent,
    InstructionsComponentComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MdbModule,
    ReactiveFormsModule,
  ],
  exports: [InstructionsPageComponent],
})
export class InstructionsModule {}
