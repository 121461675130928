<div class="d-flex flex-row align-items-center w-100">
  <div class="py-2 px-2 border-end border-primary flex-grow-1">
    <span>
      {{ graph.titleLang }}
    </span>
  </div>
  <div style="max-width: 25%; min-width: 25%">
    <div
      class="py-2 px-2 mx-2 bg-success text-white rounded d-flex justify-content-center align-items-center"
      *ngIf="graph.graphType == 'NUMBER'"
    >
      <span>
        {{ number ?? placeholderNumber }}
      </span>
    </div>
    <div
      class="py-2 px-2 mx-2 bg-info text-white rounded d-flex justify-content-center align-items-center"
      style="cursor: pointer"
      (click)="openGraphModal()"
      *ngIf="graph.graphType != 'NUMBER'"
    >
      <i class="bi-pie-chart-fill"></i>
    </div>
  </div>
</div>
