import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/api.service';
import { Period } from 'src/app/models/period';

@Component({
  selector: 'period-detailed-page',
  templateUrl: './period-detailed-page.component.html',
  styleUrls: ['./period-detailed-page.component.scss'],
})
export class PeriodDetailedPageComponent implements OnInit {
  period?: Period;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.apiCall();
  }

  apiCall() {
    const periodID = this.route.snapshot.paramMap.get('id');

    if (periodID) {
      this.api.getPeriod(periodID).subscribe(
        (data) => {
          this.period = data as Period;
        },
        (error) => {
          if (error.status == 403) {
            this.router.navigateByUrl('/u/projects');
          }
        }
      );
    }
  }

  shrinkString(string: string | undefined) {
    if (string?.length && string.length <= 40) return string;
    return string?.substring(0, 37) + ' ...';
  }

  backToProject() {
    const ref = this.route.snapshot.paramMap.get('ref');
    this.router.navigateByUrl(
      'u/project/' + ref + '/' + this.period?.projectID + '/periods'
    );
  }
}
