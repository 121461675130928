import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AppState } from '../reducer';
import { AuthService } from '../services/auth.service';
import { getUser } from '../store/auth.selectors';
import * as actions from '../store/auth.action';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private route: Router,
    private authService: AuthService,
    private store: Store<AppState>
  ) {}

  getUser(): Observable<any> {
    return this.authService.getAuthState();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.getUser().pipe(
      take(1),
      switchMap((user) => {
        if (!user) {
          this.route.navigateByUrl('/');
          return of(false);
        }
        return of(true);
      }),
      catchError(() => {
        this.route.navigateByUrl('/');
        // let user: User;
        // this.store.select(getUser).subscribe((next) => {
        //   user = next as User;
        //   this.store.dispatch(new actions.LogoutRequested({ user }));
        // });
        return of(false);
      })
    );
  }
}
