import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MdbDatepickerComponent } from 'mdb-angular-ui-kit/datepicker';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-answer-interventions-cell',
  templateUrl: './modal-answer-interventions-cell.component.html',
  styleUrls: ['./modal-answer-interventions-cell.component.scss'],
})
export class ModalAnswerInterventionsCellComponent implements OnInit {
  @ViewChild('datepickerStart') datePickerStart?: MdbDatepickerComponent;
  @ViewChild('datepickerEnd') datePickerEnd?: MdbDatepickerComponent;

  cell: any;
  title?: string;
  refID?: string;
  colID?: string;
  value?: string;

  valueForm?: FormGroup;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalAnswerInterventionsCellComponent>
  ) {}

  ngOnInit(): void {
    this.valueForm = new FormGroup({
      refID: new FormControl(this.refID),
      colID: new FormControl(this.colID),
      value: new FormControl(this.cell.sorterName),
      numericValue: new FormControl(this.cell.avgG),
      dateValue: new FormControl(
        this.cell.dateValue ? new Date(this.cell.dateValue) : null
      ),
      womenLo: new FormControl(this.cell.womenLo),
      womenIn: new FormControl(this.cell.womenIn),
      womenGt: new FormControl(this.cell.womenGt),
      womenNk: new FormControl(this.cell.womenNk),
      menLo: new FormControl(this.cell.menLo),
      menIn: new FormControl(this.cell.menIn),
      menGt: new FormControl(this.cell.menGt),
      menNk: new FormControl(this.cell.menNk),
      otherLo: new FormControl(this.cell.otherLo),
      otherIn: new FormControl(this.cell.otherIn),
      otherGt: new FormControl(this.cell.otherGt),
      otherNk: new FormControl(this.cell.otherNk),
    });

    this.valueForm?.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      if (
        this.colID === 'directBeneficiariesGender' ||
        this.colID === 'indirectBeneficiariesGender'
      ) {
        this.cell.women =
          this.womenGt?.value +
          this.womenIn?.value +
          this.womenLo?.value +
          this.womenNk?.value;
        this.cell.men =
          this.menGt?.value +
          this.menIn?.value +
          this.menLo?.value +
          this.menNk?.value;
        this.cell.other =
          this.otherGt?.value +
          this.otherIn?.value +
          this.otherLo?.value +
          this.otherNk?.value;
      }
    });
  }

  save() {
    this.api.editMeIntervention(this.valueForm?.value).subscribe((value) => {
      this.modalRef.close(true);
    });
  }

  get womenLo() {
    return this.valueForm?.get('womenLo');
  }
  get womenIn() {
    return this.valueForm?.get('womenIn');
  }
  get womenGt() {
    return this.valueForm?.get('womenGt');
  }
  get womenNk() {
    return this.valueForm?.get('womenNk');
  }
  get menLo() {
    return this.valueForm?.get('menLo');
  }
  get menIn() {
    return this.valueForm?.get('menIn');
  }
  get menGt() {
    return this.valueForm?.get('menGt');
  }
  get menNk() {
    return this.valueForm?.get('menNk');
  }
  get otherLo() {
    return this.valueForm?.get('otherLo');
  }
  get otherIn() {
    return this.valueForm?.get('otherIn');
  }
  get otherGt() {
    return this.valueForm?.get('otherGt');
  }
  get otherNk() {
    return this.valueForm?.get('otherNk');
  }
}
