<div class="container-fluid p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-5">
      <div class="d-flex flex-row my-2">
        <i class="bi bi-folder-fill h1"></i>
        <h1 class="fw-bold mx-2">
          {{ "PERIOD.PERIOD_IN" | translate }}
          <span
            class="my-auto"
            *ngIf="(period?.projectTitle?.length || '') <= 40"
          >
            {{ shrinkString(period?.projectTitle) }}
          </span>
          <span
            class="my-auto"
            [mdbTooltip]="period?.projectTitle || ''"
            *ngIf="(period?.projectTitle?.length || '') >= 40"
          >
            {{ shrinkString(period?.projectTitle) }}
          </span>
        </h1>
      </div>
      <div class="d-flex flex-row mt-4">
        <div style="height: 6rem; width: 6rem" *ngIf="period">
          <img
            class="w-100 h-100"
            [src]="
              '../../../assets/images/' + period.ref.toLowerCase() + '.svg'
            "
            style="object-fit: contain"
          />
        </div>
        <div class="mx-2 d-flex flex-column justify-content-center">
          <div class="d-flex flex-row align-items-center">
            <span
              class="h3 px-2 me-2 rounded text-white"
              [ngClass]="period?.current ? 'bg-success' : 'bg-primary'"
            >
              {{ period?.count }}
            </span>
            <h3 *ngIf="!period?.title">
              {{ "PERIOD.PERIOD" | translate }} {{ " " + period?.count }}
            </h3>
            <h3
              class="my-auto"
              *ngIf="period?.title && (period?.title?.length || '') <= 40"
            >
              {{ shrinkString(period?.title) }}
            </h3>
            <h3
              class="my-auto"
              [mdbTooltip]="period?.title || ''"
              *ngIf="period?.title && (period?.title?.length || '') >= 40"
            >
              {{ shrinkString(period?.title) }}
            </h3>
            <span
              class="mx-3 my-auto"
              style="cursor: pointer"
              (click)="backToProject()"
            >
              <u> {{ "SITE.BACK_TO_PROJECT" | translate }}</u>
            </span>
          </div>
          <h5 class="mb-0 text-success">
            {{ "PERIOD.FROM" | translate }}:
            {{
              period?.startDate | date: "dd MMM yyyy":"":translate.currentLang
            }}
          </h5>
          <h5 class="mt-0 text-danger" *ngIf="period?.endDate">
            {{ "PERIOD.TO" | translate }}:
            {{ period?.endDate | date: "dd MMM yyyy":"":translate.currentLang }}
          </h5>
          <h5 class="mt-0 text-danger" *ngIf="!period?.endDate">
            {{ "PERIOD.TO" | translate }}: &nbsp;. . .
          </h5>
        </div>
      </div>
      <div class="d-flex d-row py-0 my-0 mt-3 mb-2">
        <horizontal-list-button
          name="PERIOD.RAW_DATA_COLLECTED"
          routerLink="raw-data-collected"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PERIOD.DATA_CLEANING"
          routerLink="data-cleaning"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PERIOD.DATA_DOWNLOAD"
          routerLink="data-download"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PERIOD.DATA_ANALYSIS"
          routerLink="data-analysis"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PERIOD.DIVERSITY_TABLE"
          routerLink="diversity-table"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PERIOD.GOALS"
          routerLink="goals"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PERIOD.CONSTRAINTS"
          routerLink="constraints"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PERIOD.INTERVENTIONS"
          routerLink="interventions"
        ></horizontal-list-button>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
