<div class="row mt-3 mb-5 mx-2 gap-3">
  <div
    class="col d-flex bg-white card p-0 m-0"
    style="height: 50vh; overflow-y: auto"
  >
    <list-header
      class="sticky-top p-0 m-0"
      title="{{ 'NAVIGATION.TYPE' | translate }}"
    ></list-header>

    <div class="d-flex flex-column p-0 m-0">
      <div
        class="
          d-flex
          flex-row
          justify-content-between
          align-items-center
          py-2
          rounded-2
          mx-2
          hover
        "
        style="cursor: pointer"
        [ngClass]="type.selected ? 'bg-secondary text-white' : 'text-secondary'"
        *ngFor="let type of types; let i = index"
        (click)="onSelectType(i)"
      >
        <h5 class="px-3 py-2 my-auto">
          {{ type.code + " - " + type.categoria }}
        </h5>
        <i class="bi bi-caret-right-fill h5 px-3 py-2 my-auto"></i>
      </div>
    </div>
  </div>

  <div class="col d-flex bg-white card" style="height: 50vh; overflow-y: auto">
    <list-header
      class="sticky-top p-0 m-0"
      title="{{ 'NAVIGATION.SUB_TYPE' | translate }}"
    ></list-header>

    <database-table-placeholder
      *ngIf="descriptors.length == 0"
      class="h-100"
      [image]="getImage()"
      text="DATABASE.SELECT_A_TYPE"
    ></database-table-placeholder>

    <div class="d-flex flex-column p-0 m-0">
      <div
        class="
          d-flex
          flex-row
          justify-content-between
          align-items-center
          py-2
          rounded-2
          mx-2
          hiver
          hover
        "
        style="cursor: pointer"
        [ngClass]="type.selected ? 'bg-secondary text-white' : 'text-secondary'"
        *ngFor="let type of descriptors; let i = index"
        (click)="openSubType(type.interventionID)"
      >
        <h5 class="px-3 py-2 my-auto">
          {{ type.code + " - " + type.azione }}
        </h5>
        <i class="bi bi-info-circle-fill h5 px-3 py-2 my-auto"></i>
      </div>
    </div>
  </div>
</div>
