import { Injectable } from '@angular/core';
import { Goal } from './goal';

export interface Project {
  projectID: string;
  creationDate: number;
  downloaded: boolean;
  uploaded: boolean;
  sentToNfp: boolean;
  confirmed: boolean;
  title?: string;
  description?: string;
  typeOf?: string;
  image?: string;
  uuid?: string;
  editTitle?: boolean;
  shareProject?: boolean;
  editSite?: boolean;
  addSite?: boolean;
  addActivity?: boolean;
  projectRole?: string;
  own?: boolean;
  sharedBy?: string;
  pending?: boolean;
  accepted?: boolean;
  rejected?: boolean;
  projectUserId?: string;
  country?: string;
  countryID?: string;
  species: Specie[];
  purpose: string;
}

export interface Site {
  agroEcoZone?: string;
  agroEcoZoneId?: string;
  agroEcoZoneOther?: string;
  alt?: number;
  appID?: string;
  country: string;

  fgds: number;
  hhs: number;
  kis: number;
  vdm: number;
  lat?: number;
  lng?: number;
  maxAlt?: number;
  maxLat?: number;
  maxLng?: number;
  minLat?: number;
  minLng?: number;
  minAlt?: number;
  name?: string;
  projectID?: string;
  projectName: string;
  projectUuid: string;
  province?: string;
  siteID?: string;
  species: Specie[];
  coverages: Coverage[];
  countryID?: string;
  ref?: string;
  goals: SiteGoal[];
  deleted: boolean;
  refIDs: string[];
}

export interface Specie {
  speciesID: string;
  species: string;
  refID: string;
}

export interface Coverage {
  coverage: number;
  familyName: string;
  refID: string;
  siteCoverageID: string | null;
  active: boolean;
}

export interface SiteGoal {
  siteGoalID?: string;
  projectID?: string;
  siteID?: string;
  goalID?: string;
  goal?: string;
  subgoalID?: string;
  subgoal?: string;
  families?: string[];
  familiesName?: string;
  synched?: boolean;
}

export class SiteGoalImp implements SiteGoal {}

export interface ProjectUser {
  name?: string;
  surname?: string;
  email?: string;
  role?: string;
  accepted?: boolean;
  rejected?: boolean;
}

export interface FileAws {
  projectFileAwsId?: string;
  date?: Date;
  path?: string;
  fileName?: string;
}

@Injectable()
export class CurrentProject {
  projectID?: string;
  uuid?: string;

  constructor() {}
}
