import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'period-constraints',
  templateUrl: './period-constraints.component.html',
  styleUrls: ['./period-constraints.component.scss'],
})
export class PeriodConstraintsComponent implements OnInit {
  filterForm?: FormGroup;
  level1: Array<any> = [];
  level2: Array<any> = [];
  level3: Array<any> = [];
  langSub?: Subscription;

  pathRef?: string;
  periodID?: string;

  refIDs?: Select[];
  sites?: Select[];

  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.pathRef = this.route.parent?.snapshot.paramMap.get('ref') as string;
    this.periodID = this.route.parent?.snapshot.paramMap.get('id') as string;

    this.filterForm = new FormGroup({
      familyID: new FormControl(null),
      site: new FormControl(null),
    });

    this.apiCall();
    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.apiCall();
    });

    this.api.getFamiliesByPeriod(this.periodID).subscribe((value) => {
      this.refIDs = value as Select[];
    });

    this.api.getSitesByPeriod(this.periodID).subscribe((value) => {
      this.sites = value as Select[];
    });

    this.filterForm.valueChanges.subscribe((_) => {
      this.apiCall();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  apiCall(goalID: string | null = null) {
    this.api
      .getVdmConstraints(
        this.periodID ?? '',
        this.site?.value,
        this.familyID?.value,
        goalID
      )
      .subscribe((value) => {
        this.level2 = [];
        this.level3 = [];
        this.level1 = value as Array<any>;
      });
  }

  getCurrentLocation() {
    const path = this.router.url;
    return path.split('/')[3];
  }

  getImage() {
    const base: string = '../../../assets/images/';
    const location: string = this.getCurrentLocation();
    const image = base + location + '.svg';
    return image;
  }

  onSelectLevel1(i: number) {
    this.level1.filter((value) => {
      value.selected = false;
    });
    this.level2.filter((value) => {
      value.selected = false;
    });
    this.level3.filter((value) => {
      value.selected = false;
    });
    this.level1[i].selected = true;
    this.getLevel2(this.level1[i].goalID);
    this.level3 = [];
  }

  getLevel2(goalID: string) {
    this.api
      .getVdmConstraints(
        this.periodID ?? '',
        this.familyID?.value,
        this.site?.value,
        goalID
      )
      .subscribe((value) => {
        this.level2 = value as any[];
      });
  }

  loadDescriptors = (i: number) => {
    if (this.level2[i].children) {
      this.level3 = [];
      this.getLevel3(this.level2[i].goalID);
    }
    this.level2.filter((value) => {
      value.selected = false;
    });
    this.level3.filter((value) => {
      value.selected = false;
    });
    this.level2[i].selected = true;
  };

  getLevel3(goalID: string) {
    this.api
      .getVdmConstraints(
        this.periodID ?? '',
        this.familyID?.value,
        this.site?.value,
        goalID
      )
      .subscribe((value) => {
        this.level3 = value as any[];
      });
  }

  focusDescriptor(i: number) {
    this.level3.filter((value) => {
      value.selected = false;
    });
    this.level3[i].selected = true;
  }

  get familyID() {
    return this.filterForm?.get('familyID');
  }
  get site() {
    return this.filterForm?.get('site');
  }
}
