<div class="card mx-5 my-3 pb-2" style="max-height: 50rem">
  <div class="mx-4 pt-4 mb-1">
    <h3>{{ "PROJECTS.FILTER" | translate }}</h3>
    <div class="w-50" [formGroup]="filterForm!">
      <input
        type="text"
        class="form-control"
        id="search-input"
        formControlName="inputFilter"
      />
    </div>
  </div>
  <div class="mx-4">
    <table class="table text-primary mt-2">
      <thead>
        <tr>
          <th class="h5" scope="col" (click)="onSortClick('name')">
            <table-sort-icon
              [enable]="sort == 'name'"
              [direction]="direction"
            ></table-sort-icon>
            {{ "TABLE.TABLE_NAME" | translate }}
          </th>
          <th class="h5" scope="col" (click)="onSortClick('surname')">
            <table-sort-icon
              [enable]="sort == 'surname'"
              [direction]="direction"
            ></table-sort-icon>
            {{ "TABLE.TABLE_SURNAME" | translate }}
          </th>
          <th class="h5" scope="col" (click)="onSortClick('email')">
            <table-sort-icon
              [enable]="sort == 'email'"
              [direction]="direction"
            ></table-sort-icon>
            {{ "TABLE.TABLE_EMAIL" | translate }}
          </th>
          <th
            class="h5"
            scope="col"
            [mdbPopover]="$any(popRole)"
            placement="left"
            style="cursor: pointer"
          >
            <i class="bi bi-funnel-fill"></i>
            {{ "TABLE.TABLE_ROLE" | translate }}
          </th>
          <th class="h5" scope="col">
            {{ "TABLE.TABLE_STATUS" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users">
          <th scope="row">{{ user.name }}</th>
          <td>{{ user.surname }}</td>
          <td>{{ user.email }}</td>
          <td>{{ "PROJECTS." + user.role | translate }}</td>
          <td>{{ "PROJECTS.INVITE_" + getStatus(user) | translate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="(dataSource?.totalPages || 0) > 1">
    <page-controller
      [pageable]="dataSource"
      (pageClick)="onPageClick($event)"
    ></page-controller>
  </div>
</div>

<ng-template #popRole>
  <ul class="list-group">
    <li
      class="list-group-item waves-light tab-sort"
      [class.active]="role == 'OWNER'"
      (click)="onRoleClick('OWNER')"
    >
      {{ "PROJECTS.OWNER" | translate }}
    </li>
    <li
      class="list-group-item waves-light tab-sort"
      [class.active]="role == 'PROJECT_MANAGER'"
      (click)="onRoleClick('PROJECT_MANAGER')"
    >
      {{ "PROJECTS.PROJECT_MANAGER" | translate }}
    </li>
    <li
      class="list-group-item waves-light tab-sort"
      [class.active]="role == 'SITE_MANAGER'"
      (click)="onRoleClick('SITE_MANAGER')"
    >
      {{ "PROJECTS.SITE_MANAGER" | translate }}
    </li>
    <li
      class="list-group-item waves-light tab-sort"
      [class.active]="role == 'DATA_ENTRY'"
      (click)="onRoleClick('DATA_ENTRY')"
    >
      {{ "PROJECTS.DATA_ENTRY" | translate }}
    </li>
    <li
      class="list-group-item waves-light tab-sort"
      [class.active]="role == 'VIEWER'"
      (click)="onRoleClick('VIEWER')"
    >
      {{ "PROJECTS.VIEWER" | translate }}
    </li>
  </ul>
</ng-template>
