import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalModule, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { ModalManagementComponent } from 'src/app/modals/database/modal-management/modal-management.component';

@Component({
  selector: 'database-management',
  templateUrl: './database-management.component.html',
  styleUrls: ['./database-management.component.scss'],
})
export class DatabaseManagementComponent implements OnInit, OnDestroy {
  types: Array<any> = [];
  subTypes: Array<any> = [];
  descriptors: Array<any> = [];

  langSub?: Subscription;

  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.getType();
    this.translate.onLangChange.subscribe(() => {
      this.getType();
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  getType() {
    let databaseType: string = this.getCurrentLocation().toUpperCase();
    if (databaseType === 'CROP') databaseType = 'CROPS';
    if (databaseType === 'AQUACULTURE') databaseType = 'AQUATICS';

    this.api.getManagementCategories(databaseType).subscribe((value) => {
      this.types = value as Array<any>;
    });
  }

  getCurrentLocation() {
    const path = this.router.url;
    return path.split('/')[3];
  }

  getImage() {
    const base: string = '../../../assets/images/';
    const location: string = this.getCurrentLocation();
    const image = base + location + '.svg';
    return image;
  }

  onSelectType = (i: number) => {
    this.types.filter((value) => {
      value.selected = false;
    });
    this.subTypes.filter((value) => {
      value.selected = false;
    });
    this.types[i].selected = true;
    this.subTypes = this.types[i].subcategories;
    this.descriptors = [];
  };

  loadDescriptors = (i: number) => {
    this.descriptors = [];
    this.descriptors = this.subTypes[i].descriptors;
    this.subTypes.filter((value) => {
      value.selected = false;
    });
    this.subTypes[i].selected = true;
  };

  openDescriptor(descriptorID: string) {
    this.modalService.open(ModalManagementComponent, {
      containerClass: 'right',
      modalClass: 'modal-side modal-bottom-right',
      data: { descriptorID },
    });
  }
}
