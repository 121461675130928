<div class="modal-body vh-100 p-0 m-0">
  <div class="d-flex flex-column justify-content-between w-100 h-100 bg-light">
    <div class="m-3 mb-0">
      <div class="d-flex flex-row">
        <h2 class="mx-1">{{ "LEGEND.LEGEND" | translate }}:</h2>
      </div>
      <div class="bg-primary mx-1" style="height: 2px"></div>
    </div>

    <div class="h-100 py-4" style="overflow-y: scroll">
      <div class="d-flex flex-column mb-4 mx-4" *ngFor="let legend of legend">
        <h5>{{ legend.title | translate }}</h5>
        <span class="mb-2">{{ legend.desc | translate }}</span>
        <img [src]="legend.src" class="img-fluid rounded" />
        <hr />
      </div>
    </div>

    <div class="d-flex felex-row justify-content-end">
      <div class="btn btn-primary mx-4 mb-4" (click)="modalRef.close()">
        {{ "BUTTONS.CLOSE" | translate }}
      </div>
    </div>
  </div>
</div>
