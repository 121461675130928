<div class="container-fluid p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-5">
      <div class="d-flex my-2">
        <i class="bi-bar-chart-fill h1"></i>
        <h1 class="fw-bold mx-2">
          {{ "NAVIGATION.FILE_ANALYSIS" | translate }}
        </h1>
      </div>
      <div class="d-flex flex-column h-100">
        <a
          class="text-success text-weight-bold h6"
          href="https://cran.r-project.org/manuals.html"
          target="_blank"
          [innerHTML]="'FILE_ANALYSIS.LEARN' | translate"
        >
        </a>
        <h6 class="text-danger">{{ "FILE_ANALYSIS.REMEMBER" | translate }}</h6>
        <!-- Database Button List -->
        <div class="d-flex flex-row">
          <database-navigation-card
            class="database-hover mx-1"
            image="../../../assets/images/crop.jpeg"
            title="{{ 'NAVIGATION.CROP' | translate }}"
            routerLink="crop"
            routerLinkActive="database-on"
          ></database-navigation-card>

          <database-navigation-card
            class="database-hover mx-1"
            image="../../../assets/images/livestock.jpeg"
            title="{{ 'NAVIGATION.LIVESTOCK' | translate }}"
            routerLink="livestock"
            routerLinkActive="database-on"
          ></database-navigation-card>

          <database-navigation-card
            class="database-hover mx-1"
            image="../../../assets/images/aquaculture.jpeg"
            title="{{ 'NAVIGATION.AQUACULTURE' | translate }}"
            routerLink="aquaculture"
            routerLinkActive="database-on"
          ></database-navigation-card>

          <database-navigation-card
            class="database-hover mx-1"
            image="../../../assets/images/contribute.jpeg"
            title="{{ 'FILE_ANALYSIS.CONTRIBUTE' | translate }}"
            routerLink="contribute"
            routerLinkActive="database-on"
          ></database-navigation-card>
        </div>

        <div class="mt-3 bg-primary" style="height: 4px"></div>

        <!-- Database Button List -->

        <router-outlet></router-outlet>

        <!-- <crops></crops> -->
      </div>
    </div>
  </div>
</div>
