<div class="modal-header" *ngIf="title">
  <h4>{{ title | translate }}</h4>
</div>
<div class="modal-body" [formGroup]="valueForm!">
  <div>
    <input
      type="number"
      class="form-control"
      placeholder="{{ 'PLACEHOLDER.TARGET' | translate }}"
      formControlName="value"
    />
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.BACK" | translate }}
  </div>
  <div class="btn btn-success" (click)="saveValue()">
    {{ "BUTTONS.SAVE" | translate }}
  </div>
</div>
