<div class="modal-header">{{ "ME.VIEW_CONSTRAINTS" | translate }}</div>
<div class="modal-body d-flex flex-column modal-body-height">
  <div class="d-flex" [formGroup]="filterForm!" *ngIf="!loading">
    <ng-select
      [placeholder]="'Species'"
      class="control mx-2 mb-2"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      formControlName="specie"
      [clearable]="false"
    >
      <ng-option *ngFor="let option of species" [value]="option.value">
        {{ option.label }}
      </ng-option>
    </ng-select>
    <ng-select
      [placeholder]="'Site'"
      class="control mx-2 mb-2"
      [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
      formControlName="site"
      [clearable]="false"
    >
      <ng-option *ngFor="let option of sites" [value]="option.siteID">
        {{ option.name }}
      </ng-option>
    </ng-select>
  </div>
  <div class="mt-4" *ngIf="loading">
    <loading></loading>
  </div>
  <div class="w-100 h-100" *ngIf="!loading">
    <div class="row mt-3 mb-5 mx-2 gap-3">
      <div
        class="col d-flex bg-white card p-0 m-0"
        style="height: 50vh; overflow-y: auto"
      >
        <list-header
          class="sticky-top p-0 m-0"
          title="{{ 'NAVIGATION.LEVEL_1' | translate }}"
        ></list-header>

        <div class="d-flex flex-column p-0 m-0">
          <div
            class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2 hover"
            style="cursor: pointer"
            [ngClass]="type.selected ? 'bg-secondary text-white' : 'hover'"
            *ngFor="let type of types; let i = index"
            (click)="onSelectType(i)"
          >
            <div class="px-3 py-1 d-flex flex-column">
              <div class="my-auto">
                <h5>
                  {{ type.livello }}
                </h5>
                <h6>
                  {{ type.percent + "%" }} {{ "ME.SELECT_DURING" | translate }}
                </h6>
                <div
                  class="d-flex flex-row align-items-center animate info-hover"
                  *ngIf="type.selected"
                  (click)="selectIntervention(type)"
                >
                  <i class="bi bi-info-circle-fill p my-auto"></i>
                  <u>
                    <p class="p-0 m-0 my-auto mx-2">
                      {{ "ME.SELECT_INTERVENTIONS" | translate }}
                    </p>
                  </u>
                </div>
              </div>
            </div>

            <i class="bi bi-caret-right-fill h5 px-3 py-2 my-auto"></i>
          </div>
        </div>
      </div>

      <div
        class="col d-flex bg-white card"
        style="height: 50vh; overflow-y: auto"
      >
        <list-header
          class="sticky-top p-0 m-0"
          title="{{ 'NAVIGATION.LEVEL_2' | translate }}"
        ></list-header>

        <div class="d-flex flex-column p-0 m-0">
          <div
            class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2"
            style="cursor: pointer"
            [ngClass]="type.selected ? 'bg-secondary text-white' : 'hover'"
            *ngFor="let type of subTypes; let i = index"
            (click)="loadDescriptors(i)"
          >
            <div class="px-3 py-1 d-flex flex-column">
              <div class="my-auto">
                <h5>
                  {{ type.livello }}
                </h5>
                <h6>
                  {{ type.percent + "%" }} {{ "ME.SELECT_DURING" | translate }}
                </h6>
                <div
                  class="d-flex flex-row align-items-center animate info-hover"
                  *ngIf="type.selected"
                  (click)="selectIntervention(type)"
                >
                  <i class="bi bi-info-circle-fill p my-auto"></i>
                  <u>
                    <p class="p-0 m-0 my-auto mx-2">
                      {{ "ME.SELECT_INTERVENTIONS" | translate }}
                    </p>
                  </u>
                </div>
              </div>
            </div>

            <i
              class="bi bi-caret-right-fill h5 px-3 py-2 my-auto"
              *ngIf="type.subLevel"
            ></i>
          </div>
        </div>
      </div>

      <div
        class="col d-flex bg-white card"
        style="height: 50vh; overflow-y: auto"
      >
        <list-header
          class="sticky-top p-0 m-0"
          title="{{ 'NAVIGATION.LEVEL_3' | translate }}"
        ></list-header>

        <div class="d-flex flex-column p-0 m-0">
          <div
            class="d-flex flex-row justify-content-between align-items-center py-2 rounded-2 mx-2"
            [ngClass]="
              descriptor.selected ? 'bg-secondary text-white' : 'hover'
            "
            *ngFor="let descriptor of descriptors; let i = index"
            (click)="focusDescriptor(i)"
          >
            <div class="px-3 py-1 d-flex flex-column">
              <div class="my-auto">
                <h5>
                  {{ descriptor.livello }}
                </h5>
                <h6>
                  {{ descriptor.percent + "%" }}
                  {{ "ME.SELECT_DURING" | translate }}
                </h6>
                <div
                  class="d-flex flex-row align-items-center animate info-hover"
                  *ngIf="descriptor.selected"
                  (click)="selectIntervention(descriptor)"
                >
                  <i class="bi bi-info-circle-fill p my-auto"></i>
                  <u>
                    <p class="p-0 m-0 my-auto mx-2">
                      {{ "ME.SELECT_INTERVENTIONS" | translate }}
                    </p>
                  </u>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
</div>
