<div class="container-fluid p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-5">
      <div class="d-flex my-2">
        <i class="bi bi-envelope-fill h1"></i>
        <h1 class="fw-bold mx-2">{{ "NAVIGATION.CONTACT_US" | translate }}</h1>
      </div>
      <div class="d-flex flex-column h-100">
        <div class="row">
          <navigation-card
            class="col-2 hover"
            image="../../../assets/images/support.jpeg"
            title="{{ 'CONTACT.SUPPORT' | translate }}"
            routerLink="support"
            routerLinkActive="card-on"
          ></navigation-card>

          <navigation-card
            class="col-2 hover"
            image="../../../assets/images/contribute.jpeg"
            title="{{ 'CONTACT.CONTRIBUTE' | translate }}"
            routerLink="contribute"
            routerLinkActive="card-on"
          ></navigation-card>

          <navigation-card
            class="col-2 hover"
            image="../../../assets/images/suggestions.jpeg"
            title="{{ 'CONTACT.SUGGESTIONS' | translate }}"
            routerLink="suggestions"
            routerLinkActive="card-on"
          ></navigation-card>

          <navigation-card
            class="col-2 hover"
            image="../../../assets/images/training.jpeg"
            title="{{ 'CONTACT.TRAINING' | translate }}"
            routerLink="request-training"
            routerLinkActive="card-on"
          ></navigation-card>
        </div>
        <div class="mt-3 bg-primary" style="height: 4px"></div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
