import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-me-select-descriptor-row',
  templateUrl: './modal-me-select-descriptor-row.component.html',
  styleUrls: ['./modal-me-select-descriptor-row.component.scss'],
})
export class ModalMeSelectDescriptorRowComponent implements OnInit {
  selectForm?: FormGroup;
  descriptors?: any;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalMeSelectDescriptorRowComponent>
  ) {}

  ngOnInit(): void {
    this.selectForm = new FormGroup({
      select: new FormControl(null, Validators.required),
    });
  }

  selectDescriptor() {
    this.api
      .addDevelopmentBenefitAnalysis(this.select?.value)
      .subscribe((value) => {
        this.modalRef.close(value as any);
      });
  }

  get select() {
    return this.selectForm?.get('select');
  }
}
