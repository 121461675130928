<div class="modal-header">
  <h4>{{ "DATA_COLLECTED.REQUESTED_FILE" | translate }}</h4>
</div>
<div class="modal-body text-center" *ngIf="!loadingState">
  <div class="mx-auto" style="height: 4rem; width: 4rem">
    <img
      class="w-100 h-100"
      src="../../../../assets/images/{{ fileExt }}.png"
      style="object-fit: contain"
    />
  </div>
  <div class="py-1"></div>
  <h4>{{ "DATA_COLLECTED.FILE_LOADING" | translate }}</h4>
  <div class="py-2"></div>
  <div class="datatable-loader bg-light w-50 mx-auto" style="height: 0.5rem">
    <span class="datatable-loader-inner" style="height: 0.5rem">
      <span class="datatable-progress bg-success" style="height: 0.5rem"></span>
    </span>
  </div>
</div>

<div class="modal-body text-center" *ngIf="loadingState?.exists == false">
  <i class="bi bi-check-square-fill text-success" style="font-size: 4rem"></i>
  <div class="py-1"></div>
  <h4>{{ "DATA_COLLECTED.FILE_READY_1" | translate }}</h4>
  <h4>{{ "DATA_COLLECTED.FILE_READY_2" | translate }}</h4>
</div>

<div
  class="modal-body text-center"
  *ngIf="loadingState?.exists == true && loadingState?.creating == true"
>
  <i
    class="bi bi-excalmation-square-fill text-primary"
    style="font-size: 4rem"
  ></i>
  <div class="py-1"></div>
  <h4>{{ "DATA_COLLECTED.FILE_GENERATING_1" | translate }}</h4>
  <h4>{{ "DATA_COLLECTED.FILE_GENERATING_2" | translate }}</h4>
</div>

<div
  class="modal-body text-center"
  *ngIf="loadingState?.exists == true && loadingState?.creating == false"
>
  <i
    class="bi bi-exclamation-square-fill text-primary"
    style="font-size: 4rem"
  ></i>

  <h4>{{ "DATA_COLLECTED.FILE_AVAILABLE" | translate }}</h4>
  <div class="py-1"></div>
  <div class="d-flex flex-column my-3 py-2 rounded mx-3 bg-light">
    <div class="d-flex flex-row">
      <div class="mx-2 my-auto" style="height: 3rem; width: 3rem">
        <img
          class="w-100 h-100"
          src="../../../../assets/images/{{
            loadingState?.requestFile?.fileExt
          }}.png"
          style="object-fit: contain"
        />
      </div>
      <div class="d-flex flex-column align-items-start">
        <h5 class="mx-1">{{ loadingState?.requestFile?.name }}</h5>
        <h6 class="mx-1">{{ loadingState?.requestFile?.description }}</h6>
        <h6 class="mx-1">
          {{ "DATA_COLLECTED.FILE_NAME" | translate }}:
          {{ loadingState?.requestFile?.fileName }}
        </h6>
      </div>
      <a
        [href]="loadingState?.requestFile?.path"
        [download]="loadingState?.requestFile?.fileName"
        class="btn btn-success my-auto ms-auto me-3"
      >
        {{ "BUTTONS.DOWNLOAD" | translate }}
      </a>
    </div>
    <h6 class="ms-auto me-3">
      {{ "DATA_COLLECTED.GENERATED_BY" | translate }}:
      {{ loadingState?.requestFile?.createdBy }}
      {{ "DATA_COLLECTED.ON" | translate }}
      {{ loadingState?.requestFile?.creationDate | date: "short" }}
    </h6>
  </div>
  <h4 class="mb-2">{{ "DATA_COLLECTED.FILE_REQUEST_AGAIN" | translate }}</h4>
  <div class="btn btn-success mx-auto" (click)="requestAgain()">
    {{ "DATA_COLLECTED.REQUEST_AGAIN" | translate }}
  </div>
</div>

<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
</div>
