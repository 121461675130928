import { LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalDiversityBenefitAnswerComponent } from 'src/app/modals/projects/modal-diversity-benefit-answer/modal-diversity-benefit-answer.component';
import { ModalMePieComponent } from 'src/app/modals/projects/modal-me-pie/modal-me-pie.component';
import { Site } from 'src/app/models/project';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'project-diversity-benefit',
  templateUrl: './project-diversity-benefit.component.html',
  styleUrls: ['./project-diversity-benefit.component.scss'],
})
export class ProjectDiversityBenefitComponent implements OnInit {
  familySelected?: string;
  families?: Select[];
  periods?: any[];

  projectID?: string;
  filterForm?: FormGroup;
  ref?: string;
  sites?: Select[];
  rows: any[] = [];
  columnHeaders: any[] = [];
  answerInfo: any;

  selectedRow: number = -1;
  selectedRows: number[] = [];
  selectedColumn: number = -1;
  selectedColumns: number[] = [];
  selectedAnswer: any;

  map: Map<string, any> = new Map();

  loading: boolean = false;

  lastCall = 0;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modalService: MdbModalService,
    private location: LocationStrategy,
    public modalRef: MdbModalRef<ProjectDiversityBenefitComponent>
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    // this.projectID = this.route.snapshot.parent?.paramMap.get('id') ?? '';
    this.filterForm = new FormGroup({
      specie: new FormControl(null),
    });
    this.specie?.setValue(this.familySelected);

    this.getTableStructure();
    this.getPeriodsColumHeaders();

    this.specie?.valueChanges.subscribe((value) => {
      this.lastCall = 0;
      this.getTableStructure();
    });
  }

  getPeriodsColumHeaders() {
    this.columnHeaders = this.columnHeaders.concat(columnHeaders);
    this.periods?.forEach((e) => {
      this.columnHeaders.splice(1, 0, 'ME.PERIOD./.ME.COL_N');
    });
  }

  getNPeriodData() {
    let max = 0;
    this.rows.forEach((r) => {
      if (!this.map.has(r.refID + r.answers[0].refID)) {
        this.map.set(r.refID + r.answers[0].refID, {});
      }
      r.answers.forEach((e: any, i: number) => {
        if (e.rowID == 'NUMBER') {
          max += 1;
          this.api
            .getPeriodGraphValue(e.urlView, e.colID, e.refID)
            .subscribe((value: any) => {
              this.lastCall += 1;
              e.name = value.valueString;
              if (i == 1) {
                this.map.get(r.refID + r.answers[0].refID).base =
                  value.valueString;
              } else if (i < (this.periods?.length ?? 1) + 2) {
                if (value.valueString) {
                  this.map.get(r.refID + r.answers[0].refID).last =
                    value.valueString;
                }
              }
              if (this.lastCall == max) {
                this.calcola();
              }
            });
        } else if (!e.clickable && e.urlView) {
          r.disabled = true;
          e.rowID = 'GRAPH';
        }
      });
    });
  }

  calcola() {
    this.rows.forEach((r) => {
      if (!r.disabled) {
        const index = 3 + (this.periods?.length ?? 0);
        const midIndex = r.answers.length - 4;
        const endIndex = r.answers.length - 2;
        const l = this.map.get(r.refID + r.answers[0].refID).last as number;
        const b = this.map.get(r.refID + r.answers[0].refID).base as number;
        const v = ((l - b) / b) * 100;
        r.answers[index].name = v.toFixed(2) + ' %';
        r.answers[index].hasNote = true;
        r.answers[index].cleaned = v >= 0;

        if (r.answers[midIndex].name) {
          const lm = r.answers[midIndex].name as number;
          const bm = this.map.get(r.refID + r.answers[0].refID).last as number;
          const vm = (bm / lm) * 100;
          r.answers[midIndex + 1].name = vm.toFixed(2) + ' %';
        }

        if (r.answers[endIndex].name) {
          const lm = r.answers[endIndex].name as number;
          const bm = this.map.get(r.refID + r.answers[0].refID).last as number;
          const vm = (bm / lm) * 100;
          r.answers[endIndex + 1].name = vm.toFixed(2) + ' %';
        }
      }
    });
  }

  getTableStructure() {
    this.loading = true;

    this.api
      .getStructureDiversityBenefit(this.projectID!, this.specie?.value)
      .subscribe((value) => {
        this.rows = value as any;
        this.loading = false;
        this.getNPeriodData();
      });
  }

  getColumnHeaderLivestock(): any[] {
    let newColumns: any[] = [];
    this.columnHeaders.forEach((item: any, i: number) => {
      if (i > 0) {
        if (item.refID == newColumns[newColumns.length - 1].refID) {
          newColumns[newColumns.length - 1].span += 1;
        } else {
          item.span = 1;
          newColumns.push(item);
        }
      } else {
        item.span = 1;
        newColumns.push(item);
      }
    });
    return newColumns;
  }

  selectColumn(columnIndex: number) {
    this.selectedRow = -1;
    this.selectedColumn = columnIndex;
    this.selectedColumns = [];
    let max = this.getColumnHeaderLivestock()[columnIndex].span;
    let min = 0;
    if (columnIndex > 0) {
      this.getColumnHeaderLivestock().forEach((item, i) => {
        if (i < columnIndex) min += item.span;
      });
    }
    for (let i = 0; i < max; i++) {
      this.selectedColumns.push(min + i);
    }

    this.cleanTableUtilities();
  }

  cleanTableUtilities() {
    // this.isGeneralInfosOpened = false;
    // this.isLegendOpened = false;
    // this.isDetailOpened = false;
  }

  cellClicked(e: any, rowIndex: number, columnIndex: number) {
    if (!e.clickable || this.rows[rowIndex].disabled) return;
    this.openModalInsertAnswer(e, rowIndex, columnIndex);
  }

  openModalInsertAnswer(e: any, rowIndex: number, columnIndex: number) {
    const modalRef = this.modalService.open(
      ModalDiversityBenefitAnswerComponent,
      {
        data: {
          url: e.urlView,
          refID: e.refID,
          title: columnHeaders[columnIndex - 2],
          value: e.name,
        },
      }
    );
    modalRef.onClose.subscribe((value) => {
      if (!value) return;
      this.rows[rowIndex].answers[columnIndex].name = value as number;
      if (e.calculate) {
        this.calcola();
      }
    });
  }

  openGraph(answer: any, rowIndex: number) {
    this.modalService.open(ModalMePieComponent, {
      data: {
        graph: {
          link: answer.urlView,
          titleLang: this.rows[rowIndex].answers[0].name,
        },
        periodID: answer.colID,
        familyID: answer.refID,
      },
    });
  }

  getBackgroundCell(rowIndex: number, answerIndex: number): string {
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'cell-selected text-white border-primary';
    }
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.indexOf(answerIndex) === -1
    ) {
      return 'full-row';
    }
    if (
      this.selectedRow != rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'full-row';
    }
    return 'bg-white';
  }

  get specie() {
    return this.filterForm?.get('specie');
  }
}

const columnHeaders = [
  'ME.DB_COL_1',
  //nPeriods
  'ME.DB_COL_2',
  'ME.DB_COL_3',
  'ME.DB_COL_4',
  'ME.EMPTY',
  'ME.DB_COL_5',
  'ME.DB_COL_6',
  'ME.DB_COL_7',
  'ME.DB_COL_8',
  'ME.DB_COL_9',
];
