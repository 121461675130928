import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'variety-gmp',
  templateUrl: './variety-gmp.component.html',
  styleUrls: ['./variety-gmp.component.scss'],
})
export class VarietyGmpComponent implements OnInit {
  gmps: any[] = [];
  loading?: boolean = true;
  varietyID?: string;

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const stringIDs = this.route.parent?.snapshot.paramMap.get('id');
    const IDs = stringIDs?.split('-') || [];
    this.varietyID = IDs[1];

    this.api.getVarietySuppliers(this.varietyID).subscribe((value) => {
      this.gmps = value as any[];
      this.loading = false;
    });
  }
}
