import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'output-tables',
  templateUrl: './output-tables.component.html',
  styleUrls: ['./output-tables.component.scss'],
})
export class OutputTablesComponent implements OnInit {
  loading: boolean = false;
  state = 0;

  projectID?: string;
  activityID?: string;
  type?: string;

  info?: any;

  selectedOt: number = -1;

  columnHeaders: any[] = [];
  rows: any[] = [];

  selectedRow = -1;
  selectedColumn = -1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type') ?? '';
    this.projectID = this.route.snapshot.paramMap.get('projectID') ?? '';
    this.activityID = this.route.snapshot.paramMap.get('otID') ?? '';

    this.getInfo();
  }

  getInfo() {
    this.api
      .getInfoActivity(this.activityID ?? '', this.type ?? '')
      .subscribe((value) => {
        this.info = value as any;
      });
  }

  ngOnDestroy(): void {}

  selectOt(value: number) {
    this.selectedOt = value;
  }

  getTableStructure() {
    this.loading = true;
    this.api
      .getActivityOtHeaders(
        this.activityID ?? '',
        this.type ?? '',
        this.selectedOt
      )
      .subscribe({
        next: (value) => {
          this.columnHeaders = value as any[];
        },
        error: (e) => {
          this.loading = false;
        },
      });
    this.api
      .getActivityOtStructure(
        this.activityID ?? '',
        this.type ?? '',
        this.selectedOt
      )
      .subscribe({
        next: (value) => {
          this.rows = value as any[];
          this.loading = false;
        },
        error: (e) => {
          this.loading = false;
        },
      });
  }

  goBack() {
    this.router.navigateByUrl(`u/project/c/${this.projectID}/ot`);
  }
}
