import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { WorkInProgressComponent } from 'src/app/layout/components/work-in-progress/work-in-progress.component';
import { ModalSwitchActivityStateComponent } from 'src/app/modals/modal-switch-activity-state/modal-switch-activity-state.component';
import { ModalPeriodOtCleaningComponent } from 'src/app/modals/periods/modal-period-ot-cleaning/modal-period-ot-cleaning.component';
import { Select } from 'src/app/models/select';
import { ActivityPreview } from '../components/site-data-collected/site-data-collected.component';

@Component({
  selector: 'period-data-cleaning',
  templateUrl: './period-data-cleaning.component.html',
  styleUrls: ['./period-data-cleaning.component.scss'],
})
export class PeriodDataCleaningComponent implements OnInit {
  filterForm?: UntypedFormGroup;
  periodID?: string;
  ref?: string;
  loading?: boolean = false;
  activities?: ActivityPreview[];
  sites?: Select[];
  farmers?: Select[];

  crops?: Select[];
  cropImg: string = '';

  state: number = 0;

  activitiesList: Select[] = [
    { label: 'Focus Group Discussion', value: 'FGD' },
    { label: 'House Hold Survey', value: 'HHS' },
    { label: 'Key Informant Survey', value: 'KIS' },
  ];

  otsFamily?: any[];
  otsFarmer?: any[];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modalService: MdbModalService,
    private location: LocationStrategy
  ) {
    this.location.onPopState((event: any) => {
      if (this.state > 0) this.state = 0;
    });
  }

  ngOnInit(): void {
    this.periodID = this.route.parent?.snapshot.paramMap.get('id') ?? '';
    this.ref = this.route.parent?.snapshot.paramMap.get('ref') ?? '';
    this.filterForm = new UntypedFormGroup({
      siteID: new UntypedFormControl(null),
      activityType: new UntypedFormControl(null),
      crop: new UntypedFormControl(null),
      otSite: new UntypedFormControl(null),
      farmer: new UntypedFormControl(null),
      measure: new UntypedFormControl('ha'),
    });

    this.siteID?.valueChanges.subscribe(() => {
      this.apiCall();
    });

    this.otSite?.valueChanges.subscribe(() => {
      this.getActivities();
    });

    this.activityType?.valueChanges.subscribe(() => {
      this.apiCall();
    });

    this.crop?.valueChanges.subscribe((value) => {
      this.getCropImg();
      this.getOtsFamily();
    });

    this.farmer?.valueChanges.subscribe((value) => {
      this.getOtsFarmer();
    });
  }

  changeState(i: number) {
    this.state = i;
    if (i == 1) {
      history.pushState(null, '', window.location.href);
      this.apiCall();
    }
    if (i == 2) {
      history.pushState(null, '', window.location.href);
      this.getCrops();
      this.getSites();
    }
  }

  getCropImg() {
    this.crops?.forEach((e) => {
      if (e.value == this.crop?.value) {
        this.cropImg = e.subtitle!;
      }
    });
  }

  getOtsFamily() {
    this.api
      .getOtCleaning(this.periodID!, this.crop?.value, 'FAMILY')
      .subscribe((value) => {
        this.otsFamily = value as any[];
      });
  }
  getOtsFarmer() {
    this.api
      .getOtCleaning(this.periodID!, this.farmer?.value, 'FARMER')
      .subscribe((value) => {
        this.otsFarmer = value as any[];
      });
  }

  getSites() {
    this.api.getSitesByPeriod(this.periodID!).subscribe((value) => {
      this.sites = value as Select[];
      this.otSite?.setValue(this.sites[0].value);
    });
  }
  getActivities() {
    this.farmers = [];
    this.farmer?.setValue(null);
    this.api
      .getActivitiesBySiteSelect(this.otSite?.value)
      .subscribe((value) => {
        this.farmers = value as Select[];
        this.farmer?.setValue(this.farmers[0].value);
      });
  }

  getCrops() {
    this.api.getFamiliesByPeriod(this.periodID!).subscribe((value) => {
      this.crops = value as Select[];
      this.crop?.setValue(this.crops[0].value);
    });
  }

  apiCall() {
    if (!this.periodID) return;
    this.loading = true;

    this.api.getSitesByPeriod(this.periodID).subscribe((value) => {
      this.sites = value as Select[];
    });
    this.api
      .getActivitiesByPeriod(
        this.periodID,
        this.siteID?.value,
        this.activityType?.value
      )
      .subscribe((value) => {
        this.activities = value as ActivityPreview[];
        this.loading = false;
      });
  }

  openSwitchState(
    refID: string,
    activityType: string,
    deleted: boolean,
    i: number
  ) {
    const modalRef = this.modalService.open(ModalSwitchActivityStateComponent, {
      data: {
        refID: refID,
        activityType: activityType,
        deleted: deleted,
      },
      modalClass: 'modal-lg',
    });

    modalRef.onClose.subscribe((message) => {
      if (message) {
        this.activities![i].deleted = !this.activities![i].deleted;
      }
    });
  }

  openOtCleaning(link: string, otID: string, type: string, title: string) {
    const modalRef = this.modalService.open(ModalPeriodOtCleaningComponent, {
      data: {
        link: link,
        refID: this.periodID,
        familyID: this.crop?.value,
        type: type,
        title: title,
        otID: otID,
      },
      modalClass: 'modal-fullscreen',
    });

    modalRef.onClose.subscribe((value) => {
      this.getOtsFamily();
    });
  }

  openOtCleaningFarmer(
    link: string,
    otID: string,
    type: string,
    title: string
  ) {
    const modalRef = this.modalService.open(ModalPeriodOtCleaningComponent, {
      data: {
        link: link,
        refID: this.periodID,
        familyID: this.farmer?.value,
        type: type,
        title: title,
        measure: this.measure?.value,
        otID: otID,
      },
      modalClass: 'modal-fullscreen',
    });

    modalRef.onClose.subscribe((value) => {
      this.getOtsFarmer();
    });
  }

  get siteID() {
    return this.filterForm?.get('siteID');
  }

  get otSite() {
    return this.filterForm?.get('otSite');
  }

  get farmer() {
    return this.filterForm?.get('farmer');
  }

  get measure() {
    return this.filterForm?.get('measure');
  }
  get activityType() {
    return this.filterForm?.get('activityType');
  }

  get crop() {
    return this.filterForm?.get('crop');
  }
}
