import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Period } from 'src/app/models/period';

@Component({
  selector: 'modal-edit-period-title',
  templateUrl: './modal-edit-period-title.component.html',
  styleUrls: ['./modal-edit-period-title.component.scss'],
})
export class ModalEditPeriodTitleComponent implements OnInit {
  period?: Period;
  editForm?: UntypedFormGroup;
  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalEditPeriodTitleComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    const parsedDate = this.period?.endDate
      ? new Date(this.period?.endDate!)
      : null;

    this.editForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.period?.title, []),
      date: new UntypedFormControl(parsedDate, []),
    });
  }

  save() {
    this.modalRef.close();
  }

  editPeriod() {
    if (!this.period?.periodID) return;
    let timestamp = null;
    if (this.date?.value != null) {
      timestamp = this.date?.value.getTime();
    }
    this.api
      .editPeriod(this.period?.periodID, timestamp, this.title?.value)
      .subscribe((value) => {
        this.modalRef.close(value as Period[]);
      });
  }

  openCalendar() {}

  get title() {
    return this.editForm?.get('title');
  }

  get date() {
    return this.editForm?.get('date');
  }
}
