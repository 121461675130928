import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'project-cleaning',
  templateUrl: './project-cleaning.component.html',
  styleUrls: ['./project-cleaning.component.scss'],
})
export class ProjectCleaningComponent implements OnInit {
  projectID?: string;
  ref?: string;
  crops?: any[];

  constructor(private route: ActivatedRoute, private api: ApiService) {}

  ngOnInit(): void {
    this.projectID = this.route.parent?.snapshot.paramMap.get('id') ?? '';
    this.ref = this.route.parent?.snapshot.paramMap.get('ref') ?? '';

    this.api.getFamiliesCleaning(this.projectID).subscribe((value) => {
      this.crops = value as any[];
    });
  }
}
