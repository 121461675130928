<div class="modal-header">
  <div class="d-flex flex-row align-items-center">
    <i class="bi-arrows-collapse h4 my-auto"></i>
    <h4 class="modal-title my-auto mx-2">
      {{ "VARIETY_CLEANING.JOIN" | translate }}
    </h4>
  </div>
</div>
<div class="modal-body">
  <div class="d-flex flex-column" *ngIf="step == 0">
    <div class="d-flex justify-content-center">
      <h5>{{ "VARIETY_CLEANING.CONFIRM_SELECTION" | translate }}</h5>
    </div>
    <hr class="mb-3 mt-1" />
    <table class="table mt-2">
      <thead>
        <tr>
          <th scope="col">{{ "VARIETY_CLEANING.NAME" | translate }}</th>
          <th scope="col">{{ "VARIETY_CLEANING.UNIQUE" | translate }}</th>
          <th scope="col">{{ "VARIETY_CLEANING.SPECIE" | translate }}</th>
          <th scope="col"></th>
          <th scope="col">{{ "VARIETY_CLEANING.CONFIRM" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of varietiesSelected">
          <th scope="row">{{ item.name }}</th>
          <td>{{ item.projectVarietyID }}</td>
          <td>{{ item.species }}</td>
          <td>
            <span *ngIf="item.hybrid"
              >{{ "VARIETY_CLEANING.HYBRID" | translate }}
              {{ item.hybridWith ?? " -" }}</span
            >
            <span *ngIf="item.mixture">
              {{ "VARIETY_CLEANING.MIXTURE" | translate }}
              {{ item.mixtureWith ?? " -" }}
            </span>
            <span *ngIf="item.evolutionary">
              {{ "VARIETY_CLEANING.EVOLUTIONARY" | translate }}
              {{ item.evolutionaryWith ?? " -" }}
            </span>
          </td>
          <td>
            <div class="form-check" *ngIf="varietiesIDs">
              <input
                mdbCheckbox
                class="form-check-input"
                type="checkbox"
                [checked]="varietiesIDs.includes(item.projectVarietyID)"
                (click)="confirmation(item.projectVarietyID)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex flex-column" *ngIf="step == 1" [formGroup]="formName!">
    <div class="d-flex justify-content-center">
      <h5>{{ "VARIETY_CLEANING.CHOOSE_NAME" | translate }}</h5>
    </div>
    <hr class="mb-3 mt-1" />
    <table class="table mt-2">
      <thead>
        <tr>
          <th scope="col">{{ "VARIETY_CLEANING.VARIETY" | translate }}</th>
          <th scope="col">{{ "VARIETY_CLEANING.UNIQUE" | translate }}</th>
          <th scope="col">{{ "VARIETY_CLEANING.NEW_NAME" | translate }}</th>
          <th scope="col">{{ "VARIETY_CLEANING.LOCAL" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of varietiesSelected; index as i">
          <th scope="row">{{ item.name }}</th>
          <td scope="row">{{ item.projectVarietyID }}</td>
          <td>
            <div class="form-check">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                formControlName="name"
                [value]="i"
              />
            </div>
          </td>
          <td>
            <div class="form-check">
              <input
                mdbCheckbox
                class="form-check-input"
                type="checkbox"
                (click)="addLocalName(item.projectVarietyID)"
              />
            </div>
          </td>
        </tr>
        <tr>
          <th scope="row"></th>
          <td>
            <div>
              <input
                type="text"
                class="form-control"
                formControlName="nameField"
              />
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex flex-column" *ngIf="step == 2" [formGroup]="formName!">
    <div class="d-flex justify-content-center">
      <h5>{{ "VARIETY_CLEANING.COPY" | translate }}</h5>
    </div>
    <hr class="mb-3 mt-1" />
    <div class="d-flex align-items-center justify-content-between">
      <h6>
        {{ "VARIETY_CLEANING.COPY_DISCLAIMER" | translate }}
      </h6>
      <div class="btn btn-primary" (click)="openVarietiesInfo()">
        {{ "VARIETY_CLEANING.VIEW_INFO" | translate }}
      </div>
    </div>
    <table class="table mt-2">
      <thead>
        <tr>
          <th scope="col">{{ "VARIETY_CLEANING.VARIETY" | translate }}</th>
          <th scope="col">{{ "VARIETY_CLEANING.UNIQUE" | translate }}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of varietiesSelected">
          <th scope="row">{{ item.name }}</th>
          <td scope="row">{{ item.projectVarietyID }}</td>
          <td>
            <div class="form-check">
              <input
                mdbRadio
                class="form-check-input"
                type="radio"
                formControlName="copyFrom"
                [value]="item.projectVarietyID"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="py-3 bg-error my-4 rounded text-center" *ngIf="error">
      <h6 class="my-auto">
        {{ "OT_CLEANING.ERROR" | translate }} [{{ error }}]
      </h6>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <div class="btn btn-primary" *ngIf="step != 0" (click)="changeStep('-')">
      {{ "BUTTONS.BACK" | translate }}
    </div>
    <div class="px-2" *ngIf="step > 0 || step < 2"></div>
    <div
      class="btn btn-success"
      [ngClass]="isConfirmDisabled() ? 'disabled' : ''"
      *ngIf="step != 2"
      (click)="changeStep('+')"
    >
      {{ "BUTTONS.CONFIRM" | translate }}
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
  <div
    class="btn btn-success"
    [ngClass]="step == 2 && copyFrom?.value != null ? '' : 'disabled'"
    (click)="join()"
  >
    {{ "VARIETY_CLEANING.JOIN" | translate }}
  </div>
</div>
