import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotComponent } from './auth/components/forgot/forgot.component';
import { LoginComponent } from './auth/components/login/login.component';
import { RegisterComponent } from './auth/components/register/register.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { ContactUsPageComponent } from './contact-us/contact-us-page/contact-us-page.component';
import { ContactUsModule } from './contact-us/contact-us.module';
import { ContributeComponent } from './contact-us/contribute/contribute.component';
import { RequestTrainingComponent } from './contact-us/request-training/request-training.component';
import { SuggestionsComponent } from './contact-us/suggestions/suggestions.component';
import { SupportComponent } from './contact-us/support/support.component';
import { DashboardPageComponent } from './dashboard/dashboard-page/dashboard-page.component';
import { AquacultureComponent } from './database/aquaculture/aquaculture.component';
import { CountryAgroEcologicalComponent } from './database/components/country-agro-ecological/country-agro-ecological.component';
import { CountrySocialEthnicComponent } from './database/components/country-social-ethnic/country-social-ethnic.component';
import { DatabaseContraintsTreeComponent } from './database/components/database-contraints-tree/database-contraints-tree.component';
import { DatabaseDescriptorsComponent } from './database/components/database-descriptors/database-descriptors.component';
import { DatabaseGoalsComponent } from './database/components/database-goals/database-goals.component';
import { DatabaseInterventionComponent } from './database/components/database-intervention/database-intervention.component';
import { DatabaseListComponent } from './database/components/database-list/database-list.component';
import { DatabaseManagementComponent } from './database/components/database-management/database-management.component';
import { DatabaseMarketDescriptorsComponent } from './database/components/database-market-descriptors/database-market-descriptors.component';
import { DatabasePlaceholderComponent } from './database/components/database-placeholder/database-placeholder.component';
import { GmpDistributionComponent } from './database/components/gmp-distribution/gmp-distribution.component';
import { GmpMaterialTypeClassificationComponent } from './database/components/gmp-material-type-classification/gmp-material-type-classification.component';
import { GmpQualityGuaranteeComponent } from './database/components/gmp-quality-guarantee/gmp-quality-guarantee.component';
import { GmpReproductiveMaterialsComponent } from './database/components/gmp-reproductive-materials/gmp-reproductive-materials.component';
import { GmpSectorsComponent } from './database/components/gmp-sectors/gmp-sectors.component';
import { GmpTypesOfPointsOfSellingComponent } from './database/components/gmp-types-of-points-of-selling/gmp-types-of-points-of-selling.component';
import { GmpTypesComponent } from './database/components/gmp-types/gmp-types.component';
import { ItemDescriptorsComponent } from './database/components/item-descriptors/item-descriptors.component';
import { ItemManagementComponent } from './database/components/item-management/item-management.component';
import { ItemMarketDescriptorsComponent } from './database/components/item-market-descriptors/item-market-descriptors.component';
import { ItemVarietiesComponent } from './database/components/item-varieties/item-varieties.component';
import { VarietyBreedDescriptorsComponent } from './database/components/variety-breed-descriptors/variety-breed-descriptors.component';
import { VarietyDescriptorsComponent } from './database/components/variety-descriptors/variety-descriptors.component';
import { VarietyGalleryComponent } from './database/components/variety-gallery/variety-gallery.component';
import { VarietyGmpComponent } from './database/components/variety-gmp/variety-gmp.component';
import { VarietyProjectsComponent } from './database/components/variety-projects/variety-projects.component';
import { CountriesComponent } from './database/countries/countries.component';
import { CountryPageComponent } from './database/country-page/country-page.component';
import { CropComponent } from './database/crop/crop.component';
import { DatabasePageComponent } from './database/database-page/database-page.component';
import { GmpComponent } from './database/gmp/gmp.component';
import { ItemPageComponent } from './database/item-page/item-page.component';
import { LivestockComponent } from './database/livestock/livestock.component';
import { VarietyPageComponent } from './database/variety-page/variety-page.component';
import { InstructionsComponentComponent } from './instructions/instructions-component/instructions-component.component';
import { InstructionsPageComponent } from './instructions/instructions-page/instructions-page.component';
import { DisclaimerComponent } from './layout/components/disclaimer/disclaimer.component';
import { SiteDetailedPageComponent } from './projects/site-detailed-page/site-detailed-page.component';
import { ProjectDataCollectedComponent } from './projects/components/project-data-collected/project-data-collected.component';
import { ProjectSitesComponent } from './projects/components/project-sites/project-sites.component';
import { ProjectUsersComponent } from './projects/components/project-users/project-users.component';
import { MyProjectsPageComponent } from './projects/projects-page/projects-page.component';
import { ProjectDetailedComponent } from './projects/project-detailed-page/project-detailed-page.component';
import { WebPortalComponent } from './web-portal/web-portal.component';
import { PartnersPageComponent } from './dashboard/partners-page/partners-page.component';
import { ProjectMapComponent } from './projects/project-map/project-map.component';
import { SiteDataCollectedComponent } from './projects/components/site-data-collected/site-data-collected.component';
import { ProjectConstraintsComponent } from './projects/components/project-constraints/project-constraints.component';
import { ProjectInterventionsComponent } from './projects/components/project-interventions/project-interventions.component';
import { PeriodGoalsComponent } from './projects/components/period-goals/period-goals.component';
import { ProjectImpactComponent } from './projects/components/project-impact/project-impact.component';
import { SiteMapComponent } from './projects/components/site-map/site-map.component';
import { CountryCropPoliciesComponent } from './database/components/country-crop-policies/country-crop-policies.component';
import { CountryLivestockPoliciesComponent } from './database/components/country-livestock-policies/country-livestock-policies.component';
import { CountryAquaculturePoliciesComponent } from './database/components/country-aquaculture-policies/country-aquaculture-policies.component';
import { PolicyComponent } from './database/policy/policy.component';
import { PolicyRefComponent } from './database/components/policy-ref/policy-ref.component';
import { NetworkInstitutionComponent } from './database/network-institution/network-institution.component';
import { DeclinedRequestsPageComponent } from './projects/declined-requests-page/declined-requests-page.component';
import { DashboardV2PageComponent } from './dashboard/dashboard-v2-page/dashboard-v2-page.component';
import { GridSystemPageComponent } from './projects/grid-system-page/grid-system-page.component';
import { WorkingAreaPageComponent } from './working-area/working-area-page/working-area-page.component';
import { AnsweringPageComponent } from './working-area/answering-page/answering-page.component';
import { ProjectTimelineComponent } from './projects/project-timeline/project-timeline.component';
import { ProjectDataCollectedListComponent } from './projects/components/project-data-collected-list/project-data-collected-list.component';
import { PeriodDetailedPageComponent } from './projects/period-detailed-page/period-detailed-page.component';
import { PeriodDataCollectedComponent } from './projects/components/period-data-collected/period-data-collected.component';
import { FileAnalysisPageComponent } from './file-analysis/file-analysis-page/file-analysis-page.component';
import { FileAnalysisTypeComponent } from './file-analysis/components/file-analysis-type/file-analysis-type.component';
import { FileAnalysisCropComponent } from './file-analysis/components/file-analysis-crop/file-analysis-crop.component';
import { FileAnalysisLivestockComponent } from './file-analysis/components/file-analysis-livestock/file-analysis-livestock.component';
import { FileAnalysisAquacultureComponent } from './file-analysis/components/file-analysis-aquaculture/file-analysis-aquaculture.component';
import { ItemProjectsMapComponent } from './database/components/item-projects-map/item-projects-map.component';
import { PeriodDataAnalysisComponent } from './projects/components/period-data-analysis/period-data-analysis.component';
import { WorkInProgressComponent } from './layout/components/work-in-progress/work-in-progress.component';
import { ProjectCleaningComponent } from './projects/project-cleaning/project-cleaning.component';
import { VarietyCleaningComponent } from './variety-cleaning/variety-cleaning.component';
import { PeriodDataCleaningComponent } from './projects/period-data-cleaning/period-data-cleaning.component';
import { PeriodCleanedDataDownloadComponent } from './projects/components/period-cleaned-data-download/period-cleaned-data-download.component';
import { ProjectMeComponent } from './projects/project-me/project-me.component';
import { PeriodDiversityTableComponent } from './projects/components/period-diversity-table/period-diversity-table.component';
import { ProjectSurveysComponent } from './projects/components/project-surveys/project-surveys.component';
import { PeriodInterventionsComponent } from './projects/components/period-interventions/period-interventions.component';
import { PeriodConstraintsComponent } from './projects/components/period-constraints/period-constraints.component';
import { WaVarietyListPageComponent } from './working-area/wa-variety-list-page/wa-variety-list-page.component';
import { DatabaseCropVarietyTypeComponent } from './database/components/database-crop-variety-type/database-crop-variety-type.component';
import { ProjectOtComponent } from './projects/project-ot/project-ot.component';
import { OutputTablesComponent } from './output-tables/output-tables.component';
import { DatabaseVarietyTypeClassificationComponent } from './database/components/database-variety-type-classification/database-variety-type-classification.component';
import { DatabaseBreedTypeClassificationComponent } from './database/components/database-breed-type-classification/database-breed-type-classification.component';
import { DatabaseFarmedTypeClassificationComponent } from './database/components/database-farmed-type-classification/database-farmed-type-classification.component';
import { ActivityDetailsComponent } from './projects/components/activity-details/activity-details.component';

const cropChildren: Routes = [
  { path: 'list', component: DatabaseListComponent },
  {
    path: 'variety-type',
    component: DatabaseVarietyTypeClassificationComponent,
  },
  {
    path: 'descriptors',
    component: DatabaseDescriptorsComponent,
  },
  { path: 'management', component: DatabaseManagementComponent },
  { path: 'market-descriptors', component: DatabaseMarketDescriptorsComponent },
  { path: 'goals', component: DatabaseGoalsComponent },
  { path: 'constraints-tree', component: DatabaseContraintsTreeComponent },
  { path: 'interventions', component: DatabaseInterventionComponent },
  {
    path: 'variety-classification',
    component: DatabaseCropVarietyTypeComponent,
  },
  { path: 'constraints-to-access-material', component: DatabaseListComponent },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
];

const livestockChildren: Routes = [
  { path: 'list', component: DatabaseListComponent },
  {
    path: 'breed-type',
    component: DatabaseBreedTypeClassificationComponent,
  },
  { path: 'descriptors', component: DatabaseDescriptorsComponent },
  { path: 'management', component: DatabaseManagementComponent },
  { path: 'market-descriptors', component: DatabaseMarketDescriptorsComponent },
  { path: 'goals', component: DatabaseGoalsComponent },
  { path: 'constraints-tree', component: DatabaseContraintsTreeComponent },
  { path: 'interventions', component: DatabaseInterventionComponent },
  { path: 'constraints-to-access-material', component: DatabaseListComponent },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
];

const aquacultureChildren: Routes = [
  { path: 'list', component: DatabaseListComponent },
  {
    path: 'farmed-type',
    component: DatabaseFarmedTypeClassificationComponent,
  },
  { path: 'descriptors', component: DatabaseDescriptorsComponent },
  { path: 'management', component: DatabaseManagementComponent },
  { path: 'market-descriptors', component: DatabaseMarketDescriptorsComponent },
  { path: 'goals', component: DatabaseGoalsComponent },
  { path: 'constraints-tree', component: DatabaseContraintsTreeComponent },
  { path: 'interventions', component: DatabaseInterventionComponent },
  { path: 'constraints-to-access-material', component: DatabaseListComponent },
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
];

const gmpChildren: Routes = [
  { path: 'types', component: GmpTypesComponent },
  { path: 'sectors', component: GmpSectorsComponent },
  { path: 'distribution', component: GmpDistributionComponent },
  { path: 'quality-guarantee', component: GmpQualityGuaranteeComponent },
  {
    path: 'material-type-classification',
    component: GmpMaterialTypeClassificationComponent,
  },
  {
    path: 'reproductive-materials',
    component: GmpReproductiveMaterialsComponent,
  },
  {
    path: 'types-of-point-of-selling',
    component: GmpTypesOfPointsOfSellingComponent,
  },
  { path: '', redirectTo: 'types', pathMatch: 'full' },
];

const policyChildren: Routes = [
  { path: 'crop', component: PolicyRefComponent },
  { path: 'livestock', component: PolicyRefComponent },
  { path: 'aquaculture', component: PolicyRefComponent },
  { path: '', redirectTo: 'crop', pathMatch: 'full' },
];

const databaseChildren: Routes = [
  {
    path: 'crop',
    component: CropComponent,
    children: cropChildren,
  },
  {
    path: 'livestock',
    component: LivestockComponent,
    children: livestockChildren,
  },
  {
    path: 'aquaculture',
    component: AquacultureComponent,
    children: aquacultureChildren,
  },
  {
    path: 'genetic-providers',
    component: GmpComponent,
    children: gmpChildren,
  },
  {
    path: 'policy',
    component: PolicyComponent,
    children: policyChildren,
  },
  {
    path: 'network-institution',
    component: NetworkInstitutionComponent,
  },
  {
    path: 'countries',
    component: CountriesComponent,
  },
  {
    path: '',
    component: DatabasePlaceholderComponent,
    pathMatch: 'full',
  },
];

const workingAreaChildren: Routes = [];

const cropItemChildren: Routes = [
  {
    path: ':id',
    component: ItemPageComponent,
    children: [
      {
        path: 'varieties',
        component: ItemVarietiesComponent,
      },
      {
        path: 'descriptors',
        component: ItemDescriptorsComponent,
      },
      {
        path: 'management',
        component: ItemManagementComponent,
      },
      {
        path: 'market-descriptors',
        component: ItemMarketDescriptorsComponent,
      },
      {
        path: 'projects',
        component: ItemProjectsMapComponent,
      },
      {
        path: '',
        redirectTo: 'varieties',
        pathMatch: 'full',
      },
    ],
  },
  { path: '', redirectTo: '/database', pathMatch: 'full' },
];

const countryChildren: Routes = [
  {
    path: ':id',
    component: CountryPageComponent,
    children: [
      {
        path: 'agro-ecological-zone',
        component: CountryAgroEcologicalComponent,
      },
      {
        path: 'social-ethnic-group',
        component: CountrySocialEthnicComponent,
      },
      {
        path: 'crop-policies',
        component: CountryCropPoliciesComponent,
      },
      {
        path: 'livestock-policies',
        component: CountryLivestockPoliciesComponent,
      },
      {
        path: 'aquaculture-policies',
        component: CountryAquaculturePoliciesComponent,
      },
      {
        path: '',
        redirectTo: 'agro-ecological-zone',
        pathMatch: 'full',
      },
    ],
  },
  { path: '', redirectTo: '/database', pathMatch: 'full' },
];

const varietiesItemChildren: Routes = [
  {
    path: ':id',
    component: VarietyPageComponent,
    children: [
      {
        path: 'descriptors',
        component: VarietyDescriptorsComponent,
      },
      {
        path: 'genetic-material-provider',
        component: VarietyGmpComponent,
      },
      {
        path: 'projects',
        component: VarietyProjectsComponent,
      },
      {
        path: 'gallery',
        component: VarietyGalleryComponent,
      },
      {
        path: '',
        redirectTo: 'descriptors',
        pathMatch: 'full',
      },
    ],
  },
  { path: '', redirectTo: '/database', pathMatch: 'full' },
];

const breedsItemChildren: Routes = [
  {
    path: ':id',
    component: VarietyPageComponent,
    children: [
      {
        path: 'descriptors',
        component: VarietyBreedDescriptorsComponent,
      },
      {
        path: 'genetic-material-provider',
        component: VarietyGmpComponent,
      },
      {
        path: 'projects',
        component: VarietyProjectsComponent,
      },
      {
        path: 'gallery',
        component: VarietyGalleryComponent,
      },
      {
        path: '',
        redirectTo: 'descriptors',
        pathMatch: 'full',
      },
    ],
  },
  { path: '', redirectTo: '/database', pathMatch: 'full' },
];

const farmedItemChildren: Routes = [
  {
    path: ':id',
    component: VarietyPageComponent,
    children: [
      {
        path: 'descriptors',
        component: VarietyDescriptorsComponent,
      },
      {
        path: 'genetic-material-provider',
        component: VarietyGmpComponent,
      },
      {
        path: 'projects',
        component: VarietyProjectsComponent,
      },
      {
        path: 'gallery',
        component: VarietyGalleryComponent,
      },
      {
        path: '',
        redirectTo: 'descriptors',
        pathMatch: 'full',
      },
    ],
  },
  { path: '', redirectTo: '/database', pathMatch: 'full' },
];

const projectChildren: Routes = [
  {
    path: 'sites',
    component: ProjectSitesComponent,
  },
  {
    path: 'surveys',
    component: ProjectSurveysComponent,
  },
  {
    path: 'periods',
    component: ProjectTimelineComponent,
  },
  {
    path: 'users',
    component: ProjectUsersComponent,
  },
  {
    path: 'data-collected-and-analysis',
    component: ProjectDataCollectedListComponent,
  },
  {
    path: 'variety-cleaning',
    component: ProjectCleaningComponent,
  },
  {
    path: 'impact',
    component: ProjectMeComponent,
  },
  {
    path: 'map',
    component: ProjectMapComponent,
  },
  {
    path: 'ot',
    component: ProjectOtComponent,
  },
  {
    path: '',
    redirectTo: 'sites',
    pathMatch: 'full',
  },
];

const siteChildren: Routes = [
  {
    path: 'activities',
    component: SiteDataCollectedComponent,
  },
  {
    path: 'activity',
    component: ActivityDetailsComponent,
  },
  {
    path: 'map',
    component: ProjectMapComponent,
  },
  {
    path: '',
    redirectTo: 'activities',
    pathMatch: 'full',
  },
];

const periodChildren: Routes = [
  {
    path: 'constraints',
    component: PeriodConstraintsComponent,
  },
  {
    path: 'interventions',
    component: PeriodInterventionsComponent,
  },
  {
    path: 'goals',
    component: PeriodGoalsComponent,
  },
  {
    path: 'raw-data-collected',
    component: PeriodDataCollectedComponent,
  },
  {
    path: 'data-download',
    component: PeriodCleanedDataDownloadComponent,
  },
  {
    path: 'data-analysis',
    component: PeriodDataAnalysisComponent,
  },
  {
    path: 'data-cleaning',
    component: PeriodDataCleaningComponent,
  },
  {
    path: 'diversity-table',
    component: PeriodDiversityTableComponent,
  },
  {
    path: '',
    redirectTo: 'raw-data-collected',
    pathMatch: 'full',
  },
];

const fileAnalysisChildren: Routes = [
  {
    path: 'crop',
    component: FileAnalysisCropComponent,
  },
  {
    path: 'livestock',
    component: FileAnalysisLivestockComponent,
  },
  {
    path: 'aquaculture',
    component: FileAnalysisAquacultureComponent,
  },
  {
    path: 'contribute',
    component: FileAnalysisAquacultureComponent,
  },
];

const instructionsChildren: Routes = [
  {
    path: 'home',
    component: InstructionsComponentComponent,
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

const contactChildren: Routes = [
  { path: 'support', component: SupportComponent },
  { path: 'contribute', component: ContributeComponent },
  { path: 'suggestions', component: SuggestionsComponent },
  { path: 'request-training', component: RequestTrainingComponent },
  { path: '', redirectTo: 'support', pathMatch: 'full' },
];

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'reset-password', component: ForgotComponent },
  {
    path: 'data/project/:id/:file',
    canActivate: [AuthGuard],
    component: GridSystemPageComponent,
  },
  {
    path: 'working-area/:id',
    component: AnsweringPageComponent,
  },
  {
    path: 'cleaning/:ref/:projectID/:familyID',
    component: VarietyCleaningComponent,
  },
  {
    path: 'ot/:projectID/:type/:otID',
    component: OutputTablesComponent,
  },
  {
    path: 'u',
    component: WebPortalComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardV2PageComponent,
      },
      {
        path: 'dashboardV2',
        component: DashboardPageComponent,
      },
      {
        path: 'working-area',
        component: WorkingAreaPageComponent,
        children: workingAreaChildren,
      },
      {
        path: 'wa/:ref/:id',
        component: WaVarietyListPageComponent,
      },
      {
        path: 'partners',
        component: PartnersPageComponent,
      },
      {
        path: 'database/c',
        children: cropItemChildren,
      },
      {
        path: 'database/l',
        children: cropItemChildren,
      },
      {
        path: 'database/a',
        children: cropItemChildren,
      },
      {
        path: 'database/v',
        children: varietiesItemChildren,
      },
      {
        path: 'database/b',
        children: breedsItemChildren,
      },
      {
        path: 'database/f',
        children: farmedItemChildren,
      },
      {
        path: 'database/country',
        children: countryChildren,
      },
      {
        path: 'projects',
        component: MyProjectsPageComponent,
      },
      {
        path: 'projects/declined-requests',
        component: DeclinedRequestsPageComponent,
      },
      {
        path: 'project/:ref/:id',
        component: ProjectDetailedComponent,
        children: projectChildren,
      },
      {
        path: 'site/:id',
        component: SiteDetailedPageComponent,
        children: siteChildren,
      },
      {
        path: 'period/:ref/:id',
        component: PeriodDetailedPageComponent,
        children: periodChildren,
      },
      {
        path: 'database',
        component: DatabasePageComponent,
        children: databaseChildren,
      },
      {
        path: 'file-analysis',
        component: FileAnalysisPageComponent,
        children: fileAnalysisChildren,
      },
      {
        path: 'instructions',
        component: InstructionsPageComponent,
        children: instructionsChildren,
      },
      {
        path: 'contact-us',
        component: ContactUsPageComponent,
        children: contactChildren,
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },

  { path: 'log-in', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
