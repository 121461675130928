import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalDisableAnswerComponent } from 'src/app/modals/periods/modal-disable-answer/modal-disable-answer.component';
import { ModalEditOtInfoComponent } from 'src/app/modals/periods/modal-edit-ot-info/modal-edit-ot-info.component';
import { ModalOtHistoryComponent } from 'src/app/modals/periods/modal-ot-history/modal-ot-history.component';

@Component({
  selector: 'hhs-ot1',
  templateUrl: './hhs-ot1.component.html',
  styleUrls: ['./hhs-ot1.component.scss'],
})
export class HhsOt1Component implements OnInit {
  @Input() answerInfo: any;
  @Input() type: any;
  @Output() changeValue = new EventEmitter();

  constructor(private api: ApiService, private modalService: MdbModalService) {}

  ngOnInit(): void {}

  openEditInfo(quantity: number, measure: string, refID: string) {
    const modalRef = this.modalService.open(ModalEditOtInfoComponent, {
      data: {
        quantity: quantity,
        measure: measure,
        type: this.type,
        link: this.answerInfo.urlEdit,
        refID: refID,
      },
    });
    modalRef.onClose.subscribe((value) => {
      if (value) {
        this.changeValue.emit(value);
      }
    });
  }

  openHistory(refID: string) {
    this.modalService.open(ModalOtHistoryComponent, {
      data: {
        link: this.answerInfo.urlHistory,
        refID: refID,
      },
    });
  }

  openDisableAnswer(
    refID: string,
    urlDisable: string,
    disabled: boolean,
    measure: string
  ) {
    const modalRef = this.modalService.open(ModalDisableAnswerComponent, {
      data: {
        link: urlDisable,
        refID: refID,
        disabled: disabled,
        measure: measure,
      },
    });
    modalRef.onClose.subscribe((value) => {
      if (value) this.changeValue.emit(value);
    });
  }
}
