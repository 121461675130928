<div
  class="card d-flex flex-column rounded"
  style="overflow: hidden"
  [ngClass]="varieties ? 'pointer' : ''"
  [mdbTooltip]="hoverText || ''"
  (click)="openModal()"
>
  <div class="bg-primary w-100 text-white">
    <h5 class="p-3 my-auto text-center">
      {{ title }}
    </h5>
  </div>
  <div
    class="p-3 border-primary rounded-bottom d-flex justify-content-around align-items-center"
    style="border: solid 2px"
  >
    <h1 *ngIf="number != undefined">{{ number }}</h1>
    <h1 *ngIf="number == undefined">{{ placeholderNumber }}</h1>
  </div>
</div>
