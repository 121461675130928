<div class="p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-column mt-5">
    <div class="d-flex flex-row my-2 px-5">
      <i class="bi bi-folder-fill h1"></i>
      <h1 class="fw-bold mx-2">
        {{ "PROJECTS.PROJECT_IN" | translate }} {{ project?.country }}
      </h1>
    </div>
    <div class="d-flex flex-column sticky-top bg-light px-5" #stickyNav>
      <div class="d-flex flex-row mt-4">
        <div style="height: 6rem; width: 6rem">
          <img
            *ngIf="project"
            class="w-100 h-100"
            [src]="getImage()"
            style="object-fit: contain"
          />
        </div>
        <div class="mx-2 d-flex flex-column justify-content-center">
          <div class="d-flex d-row">
            <div class="d-flex flex-row align-items-center">
              <h3 class="my-auto" *ngIf="(project?.title?.length || '') <= 40">
                {{ shrinkString(project?.title) }}
              </h3>
              <h3
                class="my-auto"
                [mdbTooltip]="project?.title || ''"
                *ngIf="(project?.title?.length || '') >= 40"
              >
                {{ shrinkString(project?.title) }}
              </h3>
              <span
                *ngIf="project?.editTitle"
                class="mx-3 my-auto"
                style="cursor: pointer"
                (click)="openEditTitle()"
              >
                <u> {{ "PROJECTS.EDIT" | translate }}</u>
              </span>
            </div>
          </div>
          <h5 class="my-auto">
            {{ "PROJECTS." + project?.projectRole | translate }}
            |
            <span *ngIf="!project?.own">{{
              "PROJECTS.SHARE_PROJECT" | translate
            }}</span>
            ID: {{ project?.uuid }}
          </h5>
          <h6 class="my-auto">
            {{ project?.description }}
          </h6>
        </div>
      </div>
      <div class="mt-3 d-flex mx-2" *ngIf="!scrollNav">
        <a
          class="btn btn-success mx-1"
          (click)="openNewSite()"
          *ngIf="project?.addSite"
        >
          {{ "PROJECTS.NEW_SITE" | translate }}
        </a>
        <a
          class="btn btn-primary mx-1"
          (click)="openShareProject()"
          *ngIf="project?.shareProject"
        >
          {{ "PROJECTS.SHARE_PROJECT" | translate }}
        </a>
        <div
          class="btn btn-primary"
          *ngIf="project?.editTitle && project?.purpose == 'REAL'"
          (click)="openSwitchProjectPurpose()"
        >
          {{ "PROJECTS.SWITCH_TEST" | translate }}
        </div>
        <div
          class="btn btn-primary"
          *ngIf="project?.editTitle && project?.purpose == 'TEST'"
          (click)="openSwitchProjectPurpose()"
        >
          {{ "PROJECTS.SWITCH_REAL" | translate }}
        </div>
      </div>
      <div
        class="d-flex d-row py-0 my-0 mt-3 mb-2 w-100"
        style="overflow-x: scroll"
      >
        <!-- Buttons -->
        <horizontal-list-button
          name="PROJECTS.SITES"
          routerLink="sites"
        ></horizontal-list-button>

        <horizontal-list-button
          name="Project Level Surveys"
          routerLink="surveys"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PROJECTS.USERS"
          routerLink="users"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PROJECTS.TIMELINE"
          routerLink="periods"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PROJECTS.DATA_COLLECTED"
          routerLink="data-collected-and-analysis"
        ></horizontal-list-button>

        <horizontal-list-button
          [name]="
            project?.typeOf === 'CROPS'
              ? 'PROJECTS.VARIETY_CLEANING'
              : 'PROJECTS.BREED_CLEANING'
          "
          routerLink="variety-cleaning"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PROJECTS.ME"
          routerLink="impact"
        ></horizontal-list-button>

        <horizontal-list-button
          name="PROJECTS.MAP"
          routerLink="map"
        ></horizontal-list-button>

        <horizontal-list-button
          name="OT"
          routerLink="ot"
        ></horizontal-list-button>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
