import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/api.service';
import { Intervention } from 'src/app/models/intervention';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'period-interventions',
  templateUrl: './period-interventions.component.html',
  styleUrls: ['./period-interventions.component.scss'],
})
export class PeriodInterventionsComponent implements OnInit {
  filterForm?: FormGroup;

  interventions?: Select[];
  subInterventions?: any[];
  loadingInterventions: boolean = false;

  pathRef?: string;
  periodID?: string;

  refIDs?: Select[];
  sites?: Select[];

  tabSelected: number = 0;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.pathRef = this.route.parent?.snapshot.paramMap.get('ref') as string;
    this.periodID = this.route.parent?.snapshot.paramMap.get('id') as string;

    this.filterForm = new FormGroup({
      familyID: new FormControl(null),
      site: new FormControl(null),
    });

    this.translate.onLangChange.subscribe(() => {
      this.getCategories();
    });

    this.getCategories();

    this.api.getFamiliesByPeriod(this.periodID).subscribe((value) => {
      this.refIDs = value as Select[];
    });

    this.api.getSitesByPeriod(this.periodID).subscribe((value) => {
      this.sites = value as Select[];
    });

    this.filterForm.valueChanges.subscribe((_) => {
      this.getInterventionsSelected();
    });
  }

  getCategories() {
    this.api
      .getVdmInterventionCategories(this.periodID ?? '')
      .subscribe((value) => {
        this.interventions = value as Select[];

        this.getInterventionsSelected();
      });
  }

  getInterventionsSelected() {
    this.api
      .getVdmInterventions(
        this.periodID!,
        this.interventions![this.tabSelected].value,
        this.site?.value,
        this.familyID?.value
      )
      .subscribe(
        (value) => {
          this.subInterventions = value as any[];
          this.loadingInterventions = false;
        },
        (error) => {
          this.loadingInterventions = false;
        }
      );
  }

  openTab(newTab: number, interventionID: string) {
    if (this.tabSelected == newTab) return;
    this.loadingInterventions = true;
    this.tabSelected = newTab;
    this.getInterventionsSelected();
  }

  getRef(): string {
    switch (this.pathRef) {
      case 'c':
        return 'CROPS';
      case 'l':
        return 'LIVESTOCK';
      case 'a':
        return 'AQUATICS';
      default:
        return '';
    }
  }

  get familyID() {
    return this.filterForm?.get('familyID');
  }

  get site() {
    return this.filterForm?.get('site');
  }
}
