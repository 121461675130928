import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pageable } from 'src/app/models/pageable';

@Component({
  selector: 'page-controller',
  templateUrl: './page-controller.component.html',
  styleUrls: ['./page-controller.component.scss'],
})
export class PageControllerComponent implements OnInit {
  @Input() pageable?: Pageable | null;
  @Output() pageClick = new EventEmitter<number>();

  pages?: Array<number>;

  constructor() {}

  ngOnInit(): void {
    this.pages = new Array<number>();
    this.createPagination(this.pageable?.number || 0);
  }

  createPagination(page: number) {
    this.pages?.splice(0, this.pages?.length);
    let startPage = page + 1 - 2;
    let endPage = page + 1 + 2;

    if (startPage <= 0) {
      endPage -= startPage - 1;
      startPage = 1;
    }

    if (endPage > (this.pageable?.totalPages || 0)) {
      endPage = this.pageable?.totalPages || 0;
      startPage = endPage - 4;
      if (startPage < 1) {
        startPage = 1;
      }
      if (endPage < startPage) {
        endPage = startPage;
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      this.pages?.push(i);
    }
  }

  onPageClick(page: number) {
    this.pageClick.emit(page);
    this.createPagination(page);
  }

  onFirstClick() {
    this.pageClick.emit(0);
    this.createPagination(0);
  }

  onLastClick() {
    let page = this.pageable?.totalPages || 0;
    page -= 1;
    this.pageClick.emit(page);
    this.createPagination(page);
  }

  onNextClick() {
    let page = this.pageable!.number;
    page += 1;
    this.pageClick.emit(page);
    this.createPagination(page);
  }

  onPreviousClick() {
    let page = this.pageable!.number;
    page -= 1;
    this.pageClick.emit(page);
    this.createPagination(page);
  }
}
