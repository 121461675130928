<a href="{{ href }}" target="_blank" *ngIf="type !== 'DOCUMENT'">
  <div
    class="d-inline-flex flex-column card hover align-items-center pt-2"
    style="height: 11rem; width: 13.5rem"
  >
    <i class="h1 mb-0" [ngClass]="getIcon()"></i>
    <h6 class="p p-0 m-0 mb-1">- File {{ getType() }} -</h6>
    <div class="bg-dark w-75" style="height: 2px"></div>
    <div class="p-1 pb-2 pt-2 text-center m-0" style="overflow-y: auto">
      <h6>{{ name }}</h6>
    </div>
  </div>
</a>

<a
  href="{{ href }}"
  download="{{ name!.trim().split(' ')?.join('_') }}.docx"
  *ngIf="type === 'DOCUMENT'"
>
  <div
    class="d-inline-flex flex-column card hover align-items-center pt-2"
    style="height: 11rem; width: 13.5rem"
  >
    <div style="height: 64px; width: 64px">
      <img class="w-100 h-100" style="object-fit: cover" [src]="refTo" />
    </div>
    <div class="bg-dark w-75 mt-2" style="height: 2px"></div>
    <div class="p-1 pb-2 pt-2 text-center m-0" style="overflow-y: auto">
      <h6>{{ name }}</h6>
    </div>
  </div>
</a>
