<div class="border-danger card" style="border: 3px solid">
  <div class="modal-body d-flex flex-column align-items-center">
    <!-- <h4>{{ "WORKING_AREA.DELETE_VARIETY_TITLE" | translate }}</h4> -->
    <h5>{{ "SETTINGS.DELETE_COUNTRY_TITLE" | translate }}:</h5>
    <h5>{{ countryName }}?</h5>
  </div>
  <div class="modal-footer justify-content-center">
    <button
      type="button"
      class="btn btn-primary"
      (click)="modalRef.close(false)"
    >
      {{ "BUTTONS.CLOSE" | translate }}
    </button>
    <button type="button" class="btn btn-danger" (click)="modalRef.close(true)">
      {{ "BUTTONS.REMOVE" | translate }}
    </button>
  </div>
</div>
