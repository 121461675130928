import { Component, Input, OnInit } from '@angular/core';
import { Descriptor } from 'src/app/models/descriptor';

@Component({
  selector: 'answering-descriptor-legend',
  templateUrl: './answering-descriptor-legend.component.html',
  styleUrls: ['./answering-descriptor-legend.component.scss'],
})
export class AnsweringDescriptorLegendComponent implements OnInit {
  @Input() descriptor?: Descriptor;

  constructor() {}

  ngOnInit(): void {}
}
