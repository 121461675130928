import { AppState } from '../reducer';

import { createSelector } from '@ngrx/store';

export const getAuthState = (state: AppState) => state.auth;

export const getUser = createSelector(getAuthState, (auth) => auth.user);

export const getIsLoggedIn = createSelector(
  getAuthState,
  (auth) => auth.isLoggedIn
);

export const getError = createSelector(getAuthState, (auth) => auth.error);

export const getIsLoading = createSelector(
  getAuthState,
  (auth) => auth.isLoading
);
