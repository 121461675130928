import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { ApiService } from 'src/app/api.service';
import { CleaningHistory } from 'src/app/models/history.types';
import { PopconfirmRestoreComponent } from '../popconfirm-restore/popconfirm-restore.component';

@Component({
  selector: 'modal-history',
  templateUrl: './modal-history.component.html',
  styleUrls: ['./modal-history.component.scss'],
})
export class ModalHistoryComponent implements OnInit {
  projectID?: string;
  projectVarietyID?: string;

  history?: CleaningHistory;

  confirm: boolean = false;

  constructor(
    private api: ApiService,
    private popConfirmService: MdbPopconfirmService,
    public modalRef: MdbModalRef<ModalHistoryComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.api
      .getVarietyHistory(this.projectID!, this.projectVarietyID!)
      .subscribe((value) => {
        this.history = value as CleaningHistory;
      });
  }

  restore() {
    this.api
      .restoreVarietiesFromHistory(this.history?.projectCleaningHistoryID!)
      .subscribe({
        complete: () => {
          this.modalRef.close(true);
        },
      });
  }

  openPopconfirm() {
    const popRef = this.popConfirmService.open(PopconfirmRestoreComponent);
    popRef.onConfirm.subscribe((value) => {
      if (value) this.restore();
      return;
    });
  }
}
