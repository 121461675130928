import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
})
export class FolderComponent implements OnInit {
  @Input() name?: string;

  constructor() {}

  ngOnInit(): void {}
}
