import { Component, OnInit } from '@angular/core';
import {
  MdbPopconfirmContainerComponent,
  MdbPopconfirmRef,
} from 'mdb-angular-ui-kit/popconfirm';

@Component({
  selector: 'popconfirm-restore',
  templateUrl: './popconfirm-restore.component.html',
  styleUrls: ['./popconfirm-restore.component.scss'],
})
export class PopconfirmRestoreComponent implements OnInit {
  constructor(
    public popconfirmRef: MdbPopconfirmRef<PopconfirmRestoreComponent>
  ) {}

  ngOnInit(): void {}
}
