<div class="container-fluid p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column w-100 mx-5 mt-5">
      <div class="d-flex flex-row my-2">
        <i class="bi bi-send-x-fill h1"></i>
        <h1 class="fw-bold mx-2">
          {{ "PROJECTS.DECLINED_REQUESTS" | translate }}
        </h1>
        <p class="my-auto mx-2 hover" (click)="backToProjects()">
          <u> back to projects </u>
        </p>
      </div>
      <div class="row">
        <div *ngIf="requests?.length == 0">
          <div
            class="card mx-2 my-2 p-3 d-flex d-flex-column justify-content-center col-3 align-items-center border-primary"
            style="height: 12rem; border: solid 2px"
          >
            <i class="bi bi-send-x-fill h1"></i>
            <h3>{{ "PROJECTS.NO_DECLINED_REQUESTS" | translate }}</h3>
          </div>
        </div>
        <div
          class="card mx-2 my-2 p-3 d-flex d-flex-column justify-content-between col-3"
          style="height: 12rem"
          *ngFor="let request of requests"
        >
          <h4 [mdbTooltip]="request.title || ''">
            {{ shrinkRequest(request.title) }}
          </h4>
          <h6>ID: {{ request.uuid }}</h6>
          <h6>{{ "PROJECTS.FROM" | translate }}: {{ request.sharedBy }}</h6>
          <h6>
            {{ "USER.ROLE" | translate }}:
            {{ "PROJECTS." + request.projectRole | translate }}
          </h6>
          <div class="d-flex justify-content-end">
            <div
              class="btn btn-success"
              (click)="acceptRequest(request.projectID)"
            >
              {{ "BUTTONS.ACCEPT" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
