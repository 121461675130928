import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';
import { takeWhile } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';
import { User } from '../../models/user.model';
import { AppState } from '../../reducer';
import * as actions from '../../store/auth.action';
import { getIsLoading } from '../../store/auth.selectors';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm?: UntypedFormGroup;
  displayError?: String;
  countrySelect?: Array<Select>;

  loading: boolean = true;
  CountriesLoaded: boolean = false;
  loading$?: Subscription;

  btnDisable: boolean = false;

  constructor(
    private api: ApiService,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loadCountries();
    this.translate.onLangChange.subscribe(() => {
      this.loading = true;
      this.loadCountries();
    });

    this.registerForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
      surname: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      country: new UntypedFormControl(null, [Validators.required]),
      gender: new UntypedFormControl(null, [Validators.required]),
      privacy: new UntypedFormControl(null, [Validators.requiredTrue]),
      terms: new UntypedFormControl(null, [Validators.requiredTrue]),
      yearBirth: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]{4}'),
      ]),
    });
  }

  ngOnDestroy(): void {
    this.loading$?.unsubscribe();
  }

  loadCountries() {
    this.api.getCountries().subscribe(
      (next) => {
        this.countrySelect = next as Array<Select>;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  get name() {
    return this.registerForm?.get('name');
  }
  get surname() {
    return this.registerForm?.get('surname');
  }
  get email() {
    return this.registerForm?.get('email');
  }
  get country() {
    return this.registerForm?.get('country');
  }
  get yearBirth() {
    return this.registerForm?.get('yearBirth');
  }
  get gender() {
    return this.registerForm?.get('gender');
  }
  get privacy() {
    return this.registerForm?.get('privacy');
  }
  get terms() {
    return this.registerForm?.get('terms');
  }

  register = () => {
    if (!this.btnDisable) {
      this.btnDisable = true;
      if (this.registerForm!.invalid) {
        this.registerForm!.markAllAsTouched();
        this.btnDisable = false;
      } else {
        this.store.dispatch(new actions.SetLoading({ loading: true }));
        this.api.signin(this.registerForm?.value).subscribe(
          (next) => {
            const user = next as User;
            this.btnDisable = false;
            // this.store.dispatch(new actions.SetLoading({ loading: false }));
            this.store.dispatch(new actions.LoginSuccess({ user }));
          },
          (error) => {
            this.btnDisable = false;
            this.store.dispatch(new actions.SetLoading({ loading: false }));
            if (error.status === 400) {
              this.displayError = 'Email already in use.';
            } else {
              this.displayError = error.statusText;
            }
          },
          () => {
            this.store.dispatch(new actions.SetLoading({ loading: false }));
          }
        );
      }
    }
  };

  goToPrivacy(): string {
    switch (this.translate.currentLang) {
      case 'en':
        return 'https://www.datar-par.org/privacy-policy';
      case 'fr':
        return 'https://www.datar-par.org/fr/politique-de-confidentialite';
      case 'es':
        return 'https://www.datar-par.org/es/politica-de-privacidad';
      default:
        return 'https://www.datar-par.org/privacy-policy';
    }
  }

  goToTerms(): string {
    switch (this.translate.currentLang) {
      case 'en':
        return 'https://www.datar-par.org/privacy-policy/terms-and-conditions';
      case 'fr':
        return 'https://www.datar-par.org/fr/politique-de-confidentialite/termes-et-conditions';
      case 'es':
        return 'https://www.datar-par.org/es/politica-de-privacidad/terminos-y-condiciones';
      default:
        return 'https://www.datar-par.org/privacy-policy/terms-and-conditions';
    }
  }
}
