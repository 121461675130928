import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';
import { VarietyCleaning } from 'src/app/models/variety-cleaning.types';
import { ModalCleaningDescriptorsComponent } from '../modal-cleaning-descriptors/modal-cleaning-descriptors.component';
import { ModalVarietiesInfoComponent } from '../modal-varieties-info/modal-varieties-info.component';

@Component({
  selector: 'modal-join-varieties',
  templateUrl: './modal-join-varieties.component.html',
  styleUrls: ['./modal-join-varieties.component.scss'],
})
export class ModalJoinVarietiesComponent implements OnInit {
  formName?: UntypedFormGroup;

  varietiesSelected?: VarietyCleaning[];
  projectID?: string;
  familyID?: string;

  varietiesIDs: string[] = [];
  localNameIDs: string[] = [];
  species?: Select[];

  step: number = 0;

  error?: string;

  constructor(
    private api: ApiService,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<ModalJoinVarietiesComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.getIDs();
    this.formName = new UntypedFormGroup({
      projectID: new UntypedFormControl(this.projectID),
      name: new UntypedFormControl(null),
      nameField: new UntypedFormControl(null),
      copyFrom: new UntypedFormControl(null),
    });

    this.api.getSpeciesByVarieties(this.varietiesIDs).subscribe((value) => {
      this.species = value as Select[];
    });

    this.name?.valueChanges.subscribe((value) => {
      if (!value) return;
      this.nameField?.setValue(null);
    });

    this.nameField?.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      if (!value) return;
      this.name?.setValue(null);
    });
  }

  changeStep(direction: string) {
    if (direction === '+') {
      this.step += 1;
      return;
    }
    this.step -= 1;
  }

  getIDs() {
    this.varietiesSelected?.forEach((e) => {
      this.varietiesIDs?.push(e.projectVarietyID);
    });
  }

  confirmation(id: string) {
    if (this.varietiesIDs?.includes(id)) {
      const index = this.varietiesIDs.indexOf(id);
      this.varietiesIDs.splice(index, 1);
      return;
    }
    this.varietiesIDs?.push(id);
  }

  addLocalName(id: string) {
    if (this.localNameIDs?.includes(id)) {
      const index = this.localNameIDs.indexOf(id);
      this.localNameIDs.splice(index, 1);
      return;
    }
    this.localNameIDs?.push(id);
  }

  join() {
    const newName =
      this.name?.value != null
        ? this.varietiesSelected![this.name?.value].name
        : this.nameField?.value;

    const body = {
      projectID: this.projectID,
      newName: newName,
      copyFrom: this.copyFrom?.value,
      localNames: this.localNameIDs,
      refIDs: this.varietiesIDs,
    };

    this.api.joinVarieties(body).subscribe({
      next: (value) => {
        this.modalRef.close(true);
      },
      error: (error) => {
        this.error = error.status;
      },
    });
  }

  isConfirmDisabled() {
    switch (this.step) {
      case 0:
        return this.varietiesIDs.length <= 1;
      case 1:
        return (
          this.name?.value == null &&
          (this.nameField?.value == null ||
            this.nameField.value.replace(' ', '') == '')
        );
      case 2:
        return this.copyFrom?.value == null;
      default:
        return false;
    }
  }

  openVarietiesInfo() {
    const modalRef = this.modalService.open(ModalVarietiesInfoComponent, {
      data: {
        varietiesSelected: this.varietiesSelected,
        clickable: true,
      },
      modalClass: 'modal-lg',
    });

    modalRef.onClose.subscribe((value) => {
      if (value) {
        this.copyFrom?.setValue(value);
      }
    });
  }

  get name() {
    return this.formName?.get('name');
  }
  get nameField() {
    return this.formName?.get('nameField');
  }

  get copyFrom() {
    return this.formName?.get('copyFrom');
  }
}
