<div class="card mt-3 mx-1 pb-3" style="width: 50vw; overflow: hidden">
  <mdb-accordion flush="true">
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>
        {{ "NAVIGATION.SCOPE_OF_DISTRIBUTION" | translate }}
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="pb-2" style="height: 17rem; overflow-y: auto">
          <div
            class="row py-2 px-2 mx-4 rounded"
            *ngFor="let scope of scopes; index as i"
            [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
          >
            <div class="col">{{ scope.typeLang }}</div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>

    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>
        {{ "NAVIGATION.DISTRIBUTION_TYPES" | translate }}
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="pb-2" style="height: 17rem; overflow-y: auto">
          <div
            class="row py-2 px-2 mx-4 rounded"
            *ngFor="let type of types; index as i"
            [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light '"
          >
            <div class="col">{{ type.typeLang }}</div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
