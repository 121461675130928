import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { LocalName } from 'src/app/models/localName';
import { Variety } from 'src/app/models/variety';

@Component({
  selector: 'modal-change-local-names',
  templateUrl: './modal-change-local-names.component.html',
  styleUrls: ['./modal-change-local-names.component.scss'],
})
export class ModalChangeLocalNamesComponent implements OnInit {
  variety?: Variety;
  formEdit?: UntypedFormGroup;

  button: string = 'ADD';
  editIndex: number = -1;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalChangeLocalNamesComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.formEdit = new UntypedFormGroup({
      localNameID: new UntypedFormControl(null, []),
      locality: new UntypedFormControl(null, [Validators.required]),
      value: new UntypedFormControl(null, [Validators.required]),
      varietyID: new UntypedFormControl(this.variety?.varietyID, [
        Validators.required,
      ]),
    });

    this.localNameID?.valueChanges.subscribe((value) => {
      if (value == null) {
        this.button = 'ADD';
      } else {
        this.button = 'EDIT';
      }
    });
  }

  addName() {
    if (this.formEdit?.invalid) {
      this.formEdit.markAllAsTouched();
      return;
    }
    this.api.editLocalName(this.formEdit?.value).subscribe((value) => {
      this.formEdit?.reset();
      this.varietyID?.setValue(this.variety?.varietyID);
      if (this.editIndex == -1) {
        this.variety?.localNames.push(value as LocalName);
      } else {
        this.variety!.localNames[this.editIndex] = value as LocalName;
      }
    });
  }

  removeName(name: LocalName, i: number) {
    if (this.variety) {
      this.api
        .deleteVarietyName(name.uuid, this.variety?.varietyID)
        .subscribe(() => {
          this.variety?.localNames.splice(i, 1);
        });
    }
  }

  editLocalName(name: LocalName, i: number) {
    this.localNameID?.setValue(name.uuid);
    this.locality?.setValue(name.locality);
    this.value?.setValue(name.value);
    this.editIndex = i;
  }

  get localNameID() {
    return this.formEdit?.get('localNameID');
  }

  get locality() {
    return this.formEdit?.get('locality');
  }

  get value() {
    return this.formEdit?.get('value');
  }

  get varietyID() {
    return this.formEdit?.get('varietyID');
  }
}
