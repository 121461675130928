<div class="d-flex flex-column w-100 mt-3">
  <div class="sticky-top bg-light" #stickyNav>
    <div class="d-flex flex-row mt-3 align-items-center">
      <div style="height: 5rem; width: 5rem">
        <img
          class="w-100 h-100"
          style="object-fit: contain"
          src="../../../assets/images/logo.png"
        />
      </div>
      <h1 class="fw-bold mx-2 my-auto">
        {{ "NAVIGATION.POLICY" | translate }}
      </h1>
    </div>
    <div class="d-flex d-row py-0 my-0 mt-4 mb-2">
      <horizontal-list-button
        name="NAVIGATION.CROP"
        routerLink="crop"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.LIVESTOCK"
        routerLink="livestock"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.AQUACULTURE"
        routerLink="aquaculture"
      ></horizontal-list-button>

      <div
        class="rounded-6"
        style="height: 3px; width: 82%; transition: 300ms ease-in"
      ></div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
