import { Component, Input, OnInit } from '@angular/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { GraphPieComponent } from 'src/app/layout/components/graphs/graph-pie/graph-pie.component';
import { ModalMePieComponent } from 'src/app/modals/projects/modal-me-pie/modal-me-pie.component';

@Component({
  selector: 'diversity-benefit-row',
  templateUrl: './diversity-benefit-row.component.html',
  styleUrls: ['./diversity-benefit-row.component.scss'],
})
export class DiversityBenefitRowComponent implements OnInit {
  @Input() graph?: any;
  @Input() periodID?: string;
  @Input() familyID?: string;
  number?: string;
  placeholderNumber?: number;

  constructor(private api: ApiService, private modalService: MdbModalService) {}

  ngOnInit(): void {
    this.placeholderNumber = this.randomNumber();
    this.randomize();
    this.api
      .getPeriodGraphValue(this.graph.link, this.periodID!, this.familyID!)
      .subscribe((value: any) => {
        this.number = value.valueString;
      });
  }

  randomNumber(): number {
    return Math.floor(Math.random() * 100);
  }

  randomize() {
    if (this.number == undefined) {
      this.placeholderNumber = this.randomNumber();
      setTimeout(() => this.randomize(), 100);
    }
  }

  openGraphModal() {
    this.modalService.open(ModalMePieComponent, {
      data: {
        graph: this.graph,
        periodID: this.periodID,
        familyID: this.familyID,
      },
    });
  }
}
