<div class="modal-header">
  <h4>{{ "CONTACT.SUBJECT" | translate }}</h4>
</div>
<div class="modal-body" [formGroup]="formRequest!">
  <div>
    <input type="text" class="form-control" formControlName="subject" />
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
  <div class="btn btn-success" (click)="send()">
    {{ "BUTTONS.SEND" | translate }}
  </div>
</div>
