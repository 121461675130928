<div class="card col mb-3" style="overflow-y: auto; height: 38rem">
  <div class="mb-2 pb-0 sticky-top bg-white">
    <div
      class="d-flex flex-row mt-3 justify-content-between align-items-center w-100"
    >
      <h3 class="mx-3 my-auto">{{ "NAVIGATION.DESCRIPTORS" | translate }}</h3>
      <form class="d-flex flex-row my-auto mx-2" [formGroup]="filterForm!">
        <div class="mx-2">
          <ng-select
            class="control"
            formControlName="country"
            [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
            placeholder="{{ 'PLACEHOLDER.COUNTRY' | translate }}"
            [clearable]="false"
          >
            <ng-option
              *ngFor="let country of countries"
              [value]="country.value"
            >
              {{ country.label }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mx-2">
          <ng-select
            class="control"
            formControlName="type"
            placeholder="{{ 'PLACEHOLDER.TYPE' | translate }}"
            [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
          >
            <ng-option *ngFor="let type of types" [value]="type.value">
              {{ type.label }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mx-2">
          <ng-select
            class="control"
            formControlName="subtype"
            placeholder="{{ 'PLACEHOLDER.SUB_TYPE' | translate }}"
            [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
          >
            <ng-option *ngFor="let subtype of subtypes" [value]="subtype.value">
              {{ subtype.label }}
            </ng-option>
          </ng-select>
        </div>
        <div class="mx-2">
          <input
            type="text"
            class="form-control"
            formControlName="filter"
            placeholder="{{ 'PLACEHOLDER.FILTER_DESCRIPTORS' | translate }}"
          />
        </div>
      </form>
    </div>
    <div class="row mx-2 mt-2 pt-1">
      <p class="col-3">{{ "NAVIGATION.TYPE" | translate }}</p>
      <p class="col-3">{{ "NAVIGATION.SUB_TYPE" | translate }}</p>
      <p class="col-3">{{ "NAVIGATION.DESCRIPTOR" | translate }}</p>
      <p class="col-3">{{ "NAVIGATION.DESCRIPTORS_VALUE" | translate }}</p>
    </div>
    <div class="mx-2 w-90 bg-primary" style="height: 2px"></div>
  </div>

  <div class="d-flex flex-column mx-2 pb-1 pt-2">
    <disclaimer
      class="mt-4"
      text="VARIETY.NO_ANSWER"
      *ngIf="
        (!descriptors || descriptors.length == 0) && !loading && !inputFilter
      "
    ></disclaimer>
    <div
      class="row align-items-center mx-2 rounded py-3 my-1 hover"
      *ngFor="let descriptor of descriptors; index as i"
      [ngClass]="i % 2 == 0 ? 'bg-light' : 'bg-white'"
    >
      <div class="col-3">
        <h5 class="my-auto">
          {{ descriptor.traitsComplete.traitType }}
        </h5>
      </div>
      <div class="col-3">
        <h5 class="my-auto">
          {{ descriptor.traitsComplete.traitSubtype }}
        </h5>
      </div>
      <div class="col-3">
        <h5 class="my-auto">
          {{ descriptor.traitsComplete.trait }}
        </h5>
      </div>
      <div class="col-3">
        <div
          class="d-flex flex-row justify-content-between"
          *ngIf="descriptor.sameAnswer"
        >
          <h5 class="my-auto">
            {{ descriptor.answerText }}
          </h5>
          <i
            class="my-auto me-2 bi bi-chat-left-text-fill"
            *ngIf="descriptor.note"
            [mdbTooltip]="descriptor.note"
            placement="left"
          ></i>
        </div>
        <h5
          class="my-auto answers"
          style="cursor: pointer"
          *ngIf="!descriptor.sameAnswer"
          (click)="openAnswers(descriptor)"
        >
          {{ "DATABASE.CLICK_VIEW" | translate }}
        </h5>
      </div>
    </div>
  </div>
  <loading class="mask" *ngIf="loading"></loading>
</div>
<page-controller
  class="mt-5"
  *ngIf="!loading && descriptors.length > 0"
  [pageable]="dataSource"
  (pageClick)="onPageClick($event)"
></page-controller>
