<div class="modal-body">
  <div class="d-flex flex-column mt-2 px-4" *ngIf="!loading">
    <div class="d-flex mb-4" [formGroup]="filterForm!">
      <ng-select
        class="w-50"
        placeholder="{{ 'PLACEHOLDER.SPECIES' | translate }}"
        formControlName="specie"
        [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
        [clearable]="false"
      >
        <ng-option *ngFor="let select of families" [value]="select.value">
          {{ select.label }}
        </ng-option>
      </ng-select>
    </div>
    <div
      class="modal-body-height"
      style="max-width: 100%; position: relative; overflow: scroll"
    >
      <table *ngIf="columnHeaders.length != 0 && rows.length != 0 && !loading">
        <thead>
          <tr *ngIf="columnHeaders">
            <th
              class="bg-light border-primary rounded-5 shadow-2"
              style="z-index: 20"
            ></th>
            <!-- DESCRIPTOR CLICKED -->
            <th
              class="pointer px-2 py-0 rounded-5 shadow-2 bg-white text-primary"
              style="max-height: 4rem; min-height: 4rem"
              *ngFor="let column of columnHeaders; index as columnIndex"
              (click)="selectColumn(columnIndex)"
            >
              <div
                class="py-2 text-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
              >
                {{ column.split("./.")[0] | translate }}
                {{ column.includes("./.") ? columnIndex + ":" : "" }}
                {{ column.split("./.")[1] | translate }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="rows">
          <tr *ngFor="let row of rows; index as rowIndex">
            <th
              class="rounded-5 shadow-2"
              [ngClass]="
                selectedRow == rowIndex
                  ? 'cell-selected text-white'
                  : 'bg-white text-primary'
              "
              style="z-index: 10"
            >
              <div style="max-height: 64px; min-height: 64px; overflow-y: auto">
                <span class="my-auto">{{ row.name }}</span>
              </div>
            </th>
            <!-- CELL CLICKED -->
            <td
              class="text-center pointer m-1 rounded-5 shadow-2"
              [ngClass]="getBackgroundCell(rowIndex, answerIndex)"
              *ngFor="let answer of row.answers; index as answerIndex"
              (click)="cellClicked(answer, rowIndex, answerIndex)"
            >
              <div
                class="d-flex align-items-center"
                style="max-height: 4rem; min-height: 4rem; overflow-y: auto"
              >
                <span
                  class="my-auto w-100 text-center"
                  *ngIf="answer.rowID != 'GRAPH'"
                >
                  <i
                    *ngIf="answer.hasNote"
                    class="me-2"
                    [ngClass]="
                      answer.cleaned
                        ? 'bi-arrow-up-square-fill'
                        : 'bi-arrow-down-square-fill text-danger'
                    "
                  ></i>
                  {{ answer.name }}
                </span>
                <span
                  class="my-auto w-100 text-center"
                  *ngIf="answer.rowID == 'GRAPH'"
                  (click)="openGraph(answer, rowIndex)"
                >
                  {{ "ME.VIEW_GRAPH" | translate }}
                  <i class="bi-pie-chart-fill ms-2"></i>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.BACK" | translate }}
  </div>
</div>
