import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-edit-title',
  templateUrl: './modal-edit-title.component.html',
  styleUrls: ['./modal-edit-title.component.scss'],
})
export class ModalEditTitleComponent implements OnInit {
  editTitleForm?: UntypedFormGroup;

  projectID?: string;
  projectTitle?: string;
  loading: boolean = false;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalEditTitleComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.editTitleForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.projectTitle, [Validators.required]),
    });
  }

  get title() {
    return this.editTitleForm?.get('title');
  }

  updateTitle() {
    if (this.projectID) {
      if (this.editTitleForm?.invalid) {
        this.editTitleForm.markAllAsTouched();
      } else {
        this.loading = true;
        this.api.editTitle(this.projectID, this.title?.value).subscribe(
          () => {
            this.loading = false;
            this.modalRef.close(true);
          },
          (error) => {
            this.loading = false;
            //error
          }
        );
      }
    }
  }
}
