<div
  class="position-relative d-flex align-items-center rounded-5 shadow-4"
  style="height: 8.5rem"
  [ngClass]="isReady ? 'color-ready' : 'color-not-ready'"
>
  <!-- [ngClass]="isReady ? 'white-ready' : 'white-not-ready'" -->
  <div
    class="position-absolute w-100 h-100 d-flex align-items-end justify-content-end px-2"
  >
    <p class="mb-1">
      {{
        isReady
          ? ("DATABASE.READY_FOR" | translate)
          : ("DATABASE.NOT_READY_FOR" | translate)
      }}
    </p>
  </div>

  <div class="d-flex flex-row">
    <div class="mx-3" style="height: 6rem; width: 6rem">
      <img class="img-fluid" src="{{ image }}" />
    </div>
    <h3 class="h4 my-auto">{{ name }}</h3>
  </div>
</div>
