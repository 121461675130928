import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { ModalManagementComponent } from 'src/app/modals/database/modal-management/modal-management.component';
import { ManagementTable } from 'src/app/models/management-category';

@Component({
  selector: 'item-management',
  templateUrl: './item-management.component.html',
  styleUrls: ['./item-management.component.scss'],
})
export class ItemManagementComponent implements OnInit, OnDestroy {
  lang$?: Subscription;
  databaseType?: string;
  loading: boolean = true;
  managements?: ManagementTable[];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private modalService: MdbModalService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getDatabaseType();

    this.getManagement();
    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.getManagement;
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  getDatabaseType() {
    const path = this.router.url;
    this.databaseType = path.split('/')[3];
  }

  getManagement() {
    const id = this.route.parent?.snapshot.paramMap.get('id');
    if (id)
      switch (this.databaseType) {
        case 'c':
          this.api.getManagementTable('CROPS', id).subscribe(
            (value) => {
              this.managements = value as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
          break;
        case 'l':
          this.api.getManagementTable('LIVESTOCK', id).subscribe(
            (value) => {
              this.managements = value as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
          break;
        case 'a':
          this.api.getManagementTable('AQUATICS', id).subscribe(
            (value) => {
              this.managements = value as any[];
              this.loading = false;
            },
            (error) => {
              this.loading = false;
            }
          );
          break;
        default:
          break;
      }
  }

  openDescriptor(descriptorID: string) {
    this.modalService.open(ModalManagementComponent, {
      containerClass: 'right',
      modalClass: 'modal-side modal-bottom-right',
      data: { descriptorID },
    });
  }
}
