import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdbVectorMapComponent } from 'mdb-angular-vector-maps';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { Country } from 'src/app/auth/models/country.model';

@Component({
  selector: 'country',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss'],
})
export class CountriesComponent implements OnInit, OnDestroy, AfterViewInit {
  langSub?: Subscription;
  countries?: Array<Country>;
  mapCountries?: Array<Country>;

  @ViewChild('myMap') myMap?: MdbVectorMapComponent;

  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getCountries();

    this.api.getMapCountries().subscribe((value) => {
      this.mapCountries = value as Array<Country>;
    });

    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.getCountries();
    });
  }

  ngAfterViewInit(): void {
    this.myMap!._mapUnits.forEach((value) => {
      if (value.id === 'MK') value.title = 'north ' + value.title;
      if (value.id !== 'EH') {
        value.title = value.title.toUpperCase();
      } else {
        value.fill = 'rgb(220, 219, 220)';
      }
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }

  getCountries() {
    this.api.getAllCountries().subscribe((value) => {
      this.countries = value as Array<Country>;
    });
  }

  countryMapSelected(event: any) {
    const selectedCountry = event.id;
    let countryID = '';
    this.mapCountries?.filter((item: Country) => {
      if (item.cnIsoDue === selectedCountry && item.countryID) {
        countryID = item.countryID;
      }
    });
    if (selectedCountry === 'EH') {
      this.myMap!.selectFill = 'rgb(220, 219, 220)';
      return;
    }
    this.router.navigateByUrl('/u/database/country/' + countryID);
  }

  countrySelected(event: any) {
    this.router.navigateByUrl('/u/database/country/' + event.countryID);
  }

  onMouseEnter(map: MdbVectorMapComponent, country: Country) {
    document
      .getElementById(country.cnIsoDue!)
      ?.setAttribute('fill', 'rgb(79, 79, 79)');
  }

  onMouseLeave(map: MdbVectorMapComponent, country: Country) {
    document
      .getElementById(country.cnIsoDue!)
      ?.setAttribute('fill', 'rgb(220, 219, 220)');
  }
}
