<div class="modal-header">
  <div class="d-flex flex-row align-items-center">
    <i class="bi-pencil-fill h4 my-auto"></i>
    <h4 class="modal-title my-auto mx-2">
      {{ "VARIETY_CLEANING.RENAME" | translate }}
    </h4>
  </div>
</div>
<div class="modal-body" [formGroup]="renameForm!">
  <div class="d-flex flex-column" formArrayName="rename">
    <div
      class="d-flex flex-column mb-2"
      *ngFor="let item of rename.controls; index as i; last as last"
      [formGroupName]="i"
    >
      <h6>
        {{ "VARIETY_CLEANING.EDIT" | translate }}
        {{ item.get("name")?.value }}
      </h6>
      <div class="mb-2">
        <input
          type="text"
          id="form1"
          class="form-control"
          formControlName="label"
        />
      </div>
      <hr class="my-2" *ngIf="!last" />
    </div>
  </div>
  <div class="py-3 bg-error mt-4 rounded text-center" *ngIf="error">
    <h6 class="my-auto">{{ "OT_CLEANING.ERROR" | translate }} [{{ error }}]</h6>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </button>
  <button type="button" class="btn btn-success" (click)="save()">
    {{ "BUTTONS.SAVE" | translate }}
  </button>
</div>
