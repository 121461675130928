import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { VarietyCleaning } from 'src/app/models/variety-cleaning.types';

@Component({
  selector: 'modal-varieties-info',
  templateUrl: './modal-varieties-info.component.html',
  styleUrls: ['./modal-varieties-info.component.scss'],
})
export class ModalVarietiesInfoComponent implements OnInit {
  varietiesSelected?: VarietyCleaning[];
  clickable = false;

  constructor(
    public modalRef: MdbModalRef<ModalVarietiesInfoComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {}

  choseInfo(id: string) {
    if (this.clickable) this.modalRef.close(id);
  }
}
