<div
  class="container-fluid p-0 m-0 bg-light row"
  style="height: 100vh"
  *ngIf="ref != 'c'"
>
  <work-in-progress></work-in-progress>
</div>
<div
  class="container-fluid p-0 m-0 bg-light row"
  style="height: 100vh"
  *ngIf="ref == 'c'"
>
  <div class="col-9 d-flex flex-row bg-light sticky-top">
    <div class="d-flex flex-column w-100 mx-5 mt-3">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <!-- TITLE -->
        <div class="d-flex align-items-center mt-2 w-100">
          <i
            class="bi bi-arrow-left-square-fill h1 pointer my-auto"
            (click)="goBack()"
          ></i>
          <div class="mx-3" style="height: 5rem; width: 5rem">
            <img
              [src]="crop?.imageUrl"
              class="w-100 h-100"
              style="object-fit: contain"
            />
          </div>
          <h1 class="fw-bold my-auto">
            {{ "VARIETY_CLEANING.CLEANING" | translate }} {{ crop?.crop }}
            <span class="badge bg-primary" *ngIf="varietyCleaningList">
              {{ varietyCleaningList.length }}
            </span>
          </h1>
          <div class="ms-auto btn btn-primary" (click)="openMarkAll()">
            {{ "VARIETY_CLEANING.MARK_ALL" | translate }}
          </div>
        </div>
      </div>
      <div class="mt-2 mb-4 w-50 d-flex" [formGroup]="filterForm!">
        <div class="w-75">
          <input
            type="text"
            class="form-control"
            [placeholder]="'VARIETY_CLEANING.FILTER_BY_NAME' | translate"
            formControlName="filter"
          />
        </div>
        <ng-select
          class="control ms-4"
          formControlName="siteID"
          placeholder="{{ 'PLACEHOLDER.SITE' | translate }}"
          [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
        >
          <ng-option *ngFor="let site of sites" [value]="site?.siteID">
            {{ site?.name }}
          </ng-option>
        </ng-select>
      </div>
      <div
        class="table-responsive-lg px-2"
        style="height: 80vh; min-width: 800px; overflow-y: auto"
      >
        <loading class="h-100" [glass]="false" *ngIf="loading"></loading>
        <div
          class="d-flex flex-column align-items-center mt-4 text-center"
          *ngIf="state == 1"
        >
          <img
            src="/assets/images/logo.png"
            style="height: 15rem; width: 15rem"
          />
          <h4>
            <h4>{{ "VARIETY_CLEANING.EDITING_POINT_1" | translate }}</h4>
          </h4>
          <h4>{{ "VARIETY_CLEANING.EDITING_POINT_2" | translate }}</h4>
          <h4 class="font-weight-bold mt-3">
            {{ "VARIETY_CLEANING.EDITING_POINT_3" | translate
            }}<i class="bi-arrow-left-square-fill"></i>
          </h4>
          <div class="btn btn-primary mt-3" (click)="apiCall()">
            {{ "VARIETY_CLEANING.START_CLEANING" | translate }}
          </div>
        </div>
        <div
          class="d-flex flex-column align-items-center mt-4 text-center"
          *ngIf="state == 2"
        >
          <img
            src="/assets/images/logo.png"
            style="height: 15rem; width: 15rem"
          />
          <h4 class="text-danger">
            {{ "VARIETY_CLEANING.EDITING_POINT_4" | translate }}
          </h4>
          <div class="btn btn-primary mt-3">Close</div>
        </div>
        <table
          class="table align-middle mb-0 bg-light"
          *ngIf="!loading && state == 3"
        >
          <thead class="bg-light sticky-top">
            <tr>
              <th>{{ "VARIETY_CLEANING.CLEANED" | translate }}</th>
              <th>{{ "VARIETY_CLEANING.NAME" | translate }}</th>
              <th>{{ "VARIETY_CLEANING.CREATED" | translate }}</th>
              <th>{{ "VARIETY_CLEANING.SITES" | translate }}</th>
              <th>{{ "VARIETY_CLEANING.SURVEY" | translate }}</th>
              <th>{{ "VARIETY_CLEANING.VARIETY_TYPE" | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of varietyCleaningList; index as i"
              [ngClass]="isSelected(item.projectVarietyID) ? 'select' : ''"
              (click)="
                isSelected(item.projectVarietyID)
                  ? removeVariety(item.projectVarietyID)
                  : addVariety(i)
              "
              class="pointer rounded"
            >
              <td>
                <div class="d-flex align-items-center">
                  <i
                    class="h4 mx-1"
                    [ngClass]="
                      item.cleaned ? 'bi-check-square-fill' : 'bi-square'
                    "
                    [mdbTooltip]="
                      (item.cleaned
                        ? 'VARIETY_CLEANING.CLEANED'
                        : 'VARIETY_CLEANING.NOT_CLEANED'
                      ) | translate
                    "
                  ></i>
                  <i
                    class="bi-archive-fill h4 mx-1"
                    *ngIf="item.varietyID"
                    [mdbTooltip]="'VARIETY_CLEANING.DATABASE' | translate"
                  ></i>
                  <i
                    class="bi-archive-fill h4 mx-1 opacity-0"
                    *ngIf="!item.varietyID"
                  ></i>
                </div>
              </td>
              <td>
                <p class="fw-normal mb-1">{{ item.name }}</p>
                <p class="text-muted mb-0">{{ item.projectVarietyID }}</p>
              </td>
              <td>
                <p
                  class="fw-normal mb-1"
                  *ngIf="!item.varietyID || item.editBy"
                >
                  {{
                    item.editBy
                      ? ("VARIETY_CLEANING.EDITED_BY" | translate) + item.editBy
                      : ("VARIETY_CLEANING.CREATED_BY" | translate) +
                        item.addedBy
                  }}
                </p>
                <p
                  class="text-muted mb-0"
                  *ngIf="!item.varietyID || item.editBy"
                >
                  {{
                    item.editBy
                      ? ("VARIETY_CLEANING.DATE" | translate) +
                        (item.editOn | date)
                      : ("VARIETY_CLEANING.DATE" | translate) +
                        (item.addedOn | date)
                  }}
                </p>
              </td>
              <td>
                <div
                  class="d-flex flex-column"
                  style="height: 50px; overflow-y: auto"
                >
                  <p class="fw-normal mb-1" *ngFor="let site of item.sites">
                    {{ site.label }}
                  </p>
                </div>
              </td>
              <td>
                <p class="fw-normal mb-1">FGD: {{ item.countFgd }}</p>
                <p class="text-muted mb-0">HHS: {{ item.countHhs }}</p>
                <p class="text-muted mb-0">KIS: {{ item.countKis }}</p>
              </td>
              <td>
                <div
                  class="d-flex align-items-center"
                  *ngIf="(varietyCleaningList ?? []).every(areAllCleaned)"
                >
                  <i
                    class="h4 my-auto bi-check-square-fill"
                    *ngIf="item.projectVarietyType"
                    mdbTooltip="
                    <div class='d-flex flex-column'>
                    <h6>{{ item.projectVarietyType }}</h6>
                    <span>{{ item.projectVarietyTypeUser }}</span>
                    <span>{{ item.projectVarietyTypeDate | date }}</span>
                    </div>
                    "
                    [html]="true"
                  ></i>
                  <i class="h4 my-auto bi-square" *ngIf="!item.projectType"></i>
                  <p
                    class="fw-normal my-auto mx-2"
                    style="text-decoration: underline"
                    (click)="openEditVarietyType(item, i)"
                  >
                    {{ "VARIETY_CLEANING.EDIT_TYPE" | translate }}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr class="my-0 pt-1 text-primary bg-primary" style="border: none" />
      <!-- <div class="px-2">
        <div class="row justify-content-between align-items-center">
          <div class="col-2">
            <h6 class="my-auto">{{ "VARIETY_CLEANING.NAME" | translate }}</h6>
          </div>
          <div class="col-3">
            <h6 class="my-auto">
              {{ "VARIETY_CLEANING.CREATED" | translate }}
            </h6>
          </div>

          <div class="col-2">
            <h6 class="my-auto">
              {{ "VARIETY_CLEANING.DATE" | translate }}
            </h6>
          </div>
          <div class="col-2" style="max-height: 4rem; overflow-y: auto">
            <h6 class="my-auto">
              {{ "VARIETY_CLEANING.SITES" | translate }}
            </h6>
          </div>
          <div class="col-1 d-flex flex-row justify-content-between">
            {{ "VARIETY_CLEANING.CLEANED" | translate }}
          </div>
        </div>
      </div>
      <hr class="my-0" />
      <loading class="h-100" [glass]="false" *ngIf="loading"></loading>
      <div
        class="d-flex flex-column py-3 container-fluid"
        style="overflow-y: auto; height: 80vh"
        *ngIf="!loading"
      >
        <div
          class="d-flex px-2 py-2 rounded mb-2 align-items-center"
          style="cursor: pointer"
          *ngFor="let item of varietyCleaningList; index as index; last as last"
          [ngClass]="isSelected(item.projectVarietyID) ? 'select' : ''"
          (click)="
            isSelected(item.projectVarietyID)
              ? removeVariety(item.projectVarietyID)
              : addVariety(index)
          "
        >
          <div class="d-flex flex-column w-100 justify-content-between">
            <div class="row justify-content-between align-items-center">
              <div class="col-2">
                <h6 class="my-auto">{{ item.name }}</h6>
              </div>
              <div class="col-3" *ngIf="!item.editBy">
                <h6 class="my-auto" *ngIf="item.addedBy">
                  {{ "VARIETY_CLEANING.CREATED_BY" | translate }}
                  {{ item.addedBy }}
                </h6>
              </div>
              <div class="col-3" *ngIf="item.editBy">
                <h6 class="my-auto">
                  {{ "VARIETY_CLEANING.EDITED_BY" | translate }}
                  {{ item.editBy }}
                </h6>
              </div>
              <div class="col-2" *ngIf="!item.editOn">
                <h6 class="my-auto">{{ item.addedOn | date }}</h6>
              </div>
              <div class="col-2" *ngIf="item.editOn">
                <h6 class="my-auto">{{ item.editOn | date }}</h6>
              </div>
              <div class="col-2" style="max-height: 4rem; overflow-y: auto">
                <h6
                  class="my-auto"
                  *ngFor="let site of item.sites; last as last"
                >
                  {{ site.label }}<span *ngIf="!last">,</span>
                </h6>
              </div>
              <div class="col-1 d-flex flex-row justify-content-between">
                <i
                  class="h5 bi-archive-fill"
                  [ngClass]="item.varietyID ? '' : 'opacity-0'"
                ></i>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
              <h6>
                {{ "VARIETY_CLEANING.UNIQUE" | translate }}
                {{ item.projectVarietyID }}
              </h6>
              <h6 class="ms-3" *ngIf="item.varietyID">
                {{ "VARIETY_CLEANING.ACTIVITY" | translate }} -
              </h6>
              <h6 class="ms-3" *ngIf="!item.varietyID">
                {{ "VARIETY_CLEANING.ACTIVITY" | translate }}
                {{
                  item.activityID
                    ? item.activityType + " - " + item.activityID
                    : item.cleaned
                    ? ("VARIETY_CLEANING.VARIETY_CLEANING" | translate)
                    : "-"
                }}
              </h6>
            </div>
            <hr class="my-0 mb-2" *ngIf="!last" />
          </div>
          <i
            class="h4 my-auto ms-4"
            [ngClass]="item.cleaned ? 'bi-check-square-fill' : 'bi-square'"
          ></i>
        </div>
      </div>
      <hr class="my-0 pt-1 text-primary bg-primary" style="border: none" /> -->
    </div>
  </div>

  <div
    class="col-3 h-100 border-start border-primary d-flex flex-column p-3 sticky-top"
  >
    <h4>{{ "VARIETY_CLEANING.SELECTED_VARIETIES" | translate }}</h4>
    <hr class="my-0" />
    <div
      class="h-100 d-flex flex-column py-2"
      style="overflow-y: scroll"
      *ngIf="varietiesSelected.length > 0"
    >
      <div
        class="d-flex flex-row px-2 pt-3 rounded mb-2"
        *ngFor="let item of varietiesSelected; index as index"
      >
        <div class="d-flex flex-column w-100">
          <h6>{{ item.name }}</h6>
          <h6>{{ item.projectVarietyID }}</h6>
          <span
            style="text-decoration: underline; cursor: pointer"
            (click)="openHistory(item.projectVarietyID)"
            *ngIf="item.editBy"
          >
            <i class="bi-list-nested"></i> View History
          </span>
          <span
            style="text-decoration: underline; cursor: pointer"
            (click)="openShowImages(item.appID)"
          >
            <i class="bi bi-image"></i>
            {{ "VARIETY_CLEANING.SHOW_IMAGES" | translate }}
          </span>
          <hr class="my-0" />
        </div>
        <i
          class="h4 my-auto ms-4 bi-dash-square-fill"
          style="cursor: pointer"
          (click)="removeVarietyFromSelected(index)"
        ></i>
      </div>
    </div>
    <div
      class="h-100 d-flex flex-column align-items-center justify-content-center"
      *ngIf="varietiesSelected.length == 0"
    >
      <div class="w-50">
        <img src="/assets/images/logo.png" class="img-fluid" />
      </div>
      <h4 class="px-2 text-center">
        {{ "VARIETY_CLEANING.ADD_ONE" | translate }}
      </h4>
    </div>
    <div
      class="btn btn-primary mb-2"
      [ngClass]="varietiesSelected.length > 0 ? '' : 'disabled'"
      (click)="openVarietiesInfo()"
    >
      {{ "VARIETY_CLEANING.VARIETIES_INFO" | translate }}
    </div>
    <div
      class="btn btn-primary mb-2"
      [ngClass]="varietiesSelected.length > 0 ? '' : 'disabled'"
      (click)="openDescriptors()"
    >
      {{ "VARIETY_CLEANING.VIEW_DESCRIPTORS" | translate }}
    </div>
    <div
      class="btn btn-primary mb-2"
      [ngClass]="varietiesSelected.length > 0 ? '' : 'disabled'"
      (click)="openRename()"
    >
      {{ "VARIETY_CLEANING.RENAME" | translate }}
    </div>
    <div
      class="btn btn-primary mb-2"
      [ngClass]="
        varietiesSelected.length == 1 && varietiesSelected[0].sites.length > 1
          ? ''
          : 'disabled'
      "
      (click)="openSplit()"
    >
      {{ "VARIETY_CLEANING.SPLIT" | translate }}
    </div>
    <div
      class="btn btn-primary"
      [ngClass]="varietiesSelected.length > 1 ? '' : 'disabled'"
      (click)="openJoin()"
    >
      {{ "VARIETY_CLEANING.JOIN" | translate }} {{ varietiesSelected.length }}
    </div>
  </div>
</div>
