import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-site-activity',
  templateUrl: './modal-site-activity.component.html',
  styleUrls: ['./modal-site-activity.component.scss'],
})
export class ModalSiteDataCollectedComponent implements OnInit {
  refID?: string;
  activityType?: string;
  activity: any;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalSiteDataCollectedComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.apiCall();
  }

  apiCall() {
    if (this.refID && this.activityType) {
      this.api.getActivity(this.refID, this.activityType).subscribe((value) => {
        this.activity = value as any;
      });
    }
  }

  getGender(gender: string): string {
    switch (gender) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      default:
        return 'Diverse';
    }
  }
}
