import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'modal-new-request',
  templateUrl: './modal-new-request.component.html',
  styleUrls: ['./modal-new-request.component.scss'],
})
export class ModalNewRequestComponent implements OnInit {
  formRequest?: UntypedFormGroup;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalNewRequestComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.formRequest = new UntypedFormGroup({
      subject: new UntypedFormControl(null, [Validators.required]),
    });
  }

  send() {
    this.api
      .addMySupportRequest(null, this.subject?.value, null)
      .subscribe((value) => {
        this.modalRef.close(value as any);
      });
  }

  get subject() {
    return this.formRequest?.get('subject');
  }
}
