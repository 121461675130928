import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDescriptorComponent } from './database/modal-descriptor/modal-descriptor.component';
import { TranslateModule } from '@ngx-translate/core';
import { ModalConstraintTreeComponent } from './database/modal-constraint-tree/modal-constraint-tree.component';
import { ModalNewProjectComponent } from './projects/modal-new-project/modal-new-project.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdbModule } from '../mdb/mdb.module';
import { ModalGoalComponent } from './database/modal-goal/modal-goal.component';
import { ModalManagementComponent } from './database/modal-management/modal-management.component';
import { ModalInterventionsComponent } from './database/modal-interventions/modal-interventions.component';
import { LayoutModule } from '../layout/layout.module';
import { ModalNewSiteComponent } from './projects/modal-new-site/modal-new-site.component';
import { ModalEditSiteComponent } from './projects/modal-edit-site/modal-edit-site.component';
import { ModalDeleteProjectComponent } from './projects/modal-delete-project/modal-delete-project.component';
import { ModalEditProfileComponent } from './settings/modal-edit-profile/modal-edit-profile.component';
import { ModalChangePasswordComponent } from './settings/modal-change-password/modal-change-password.component';
import { ModalShareProjectComponent } from './projects/modal-share-project/modal-share-project.component';
import { ModalEditTitleComponent } from './projects/modal-edit-title/modal-edit-title.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ModalQrCodeComponent } from './settings/modal-qr-code/modal-qr-code.component';
import { ModalDeclineRequestComponent } from './projects/modal-decline-request/modal-decline-request.component';
import { ModalSiteDataCollectedComponent } from './projects/modal-site-activity/modal-site-activity.component';
import { ModalChangeRoleComponent } from './settings/modal-change-role/modal-change-role.component';
import { ModalImportVarietyComponent } from './working-area/modal-import-variety/modal-import-variety.component';
import { ModalAddVarietyComponent } from './working-area/modal-add-variety/modal-add-variety.component';
import { ModalChangeLocalNamesComponent } from './working-area/modal-change-local-names/modal-change-local-names.component';
import { ModalChangePhotosComponent } from './working-area/modal-change-photos/modal-change-photos.component';
import { ModalChangeProjectPurposeComponent } from './projects/modal-change-project-purpose/modal-change-project-purpose.component';
import { ModalManageDescriptorsComponent } from './working-area/modal-manage-descriptors/modal-manage-descriptors.component';
import { ModalDeleteVarietyComponent } from './working-area/modal-delete-variety/modal-delete-variety.component';
import { ModalDeleteCountryComponent } from './settings/modal-delete-country/modal-delete-country.component';
import { ModalImageGalleryComponent } from './layout/modal-image-gallery/modal-image-gallery.component';
import { ModalManageVarietiesComponent } from './working-area/modal-manage-varieties/modal-manage-varieties.component';
import { ModalAddBreedComponent } from './working-area/modal-add-breed/modal-add-breed.component';
import { ModalNewRequestComponent } from './contact-us/modal-new-request/modal-new-request.component';
import { ModalLivestockAnswersComponent } from './database/modal-livestock-answers/modal-livestock-answers.component';
import { ModalEditPeriodTitleComponent } from './projects/modal-edit-period-title/modal-edit-period-title.component';
import { ModalFileRequestedComponent } from './projetcs/modal-file-requested/modal-file-requested.component';
import { ModalClosePeriodComponent } from './projects/modal-close-period/modal-close-period.component';
import { ModalPeriodFileRequestedComponent } from './projects/modal-period-file-requested/modal-period-file-requested.component';
import { ModalFileAnalysisComponent } from './modal-file-analysis/modal-file-analysis.component';
import { ModalPeriodDataAnalysisComponent } from './modal-period-data-analysis/modal-period-data-analysis.component';
import { ModalAddGoalComponent } from './sites/modal-add-goal/modal-add-goal.component';
import { ModalEditActivityComponent } from './sites/modal-edit-activity/modal-edit-activity.component';
import { ModalRenameCleaningComponent } from './variety-cleaning/modal-rename-cleaning/modal-rename-cleaning.component';
import { ModalCleaningDescriptorsComponent } from './variety-cleaning/modal-cleaning-descriptors/modal-cleaning-descriptors.component';
import { ModalSplitVarietiesComponent } from './variety-cleaning/modal-split-varieties/modal-split-varieties.component';
import { ModalJoinVarietiesComponent } from './variety-cleaning/modal-join-varieties/modal-join-varieties.component';
import { ModalSwitchActivityStateComponent } from './modal-switch-activity-state/modal-switch-activity-state.component';
import { ModalVarietiesInfoComponent } from './variety-cleaning/modal-varieties-info/modal-varieties-info.component';
import { ModalHistoryComponent } from './variety-cleaning/modal-history/modal-history.component';
import { PopconfirmRestoreComponent } from './variety-cleaning/popconfirm-restore/popconfirm-restore.component';
import { ModalConfirmMarkCleanedComponent } from './variety-cleaning/modal-confirm-mark-cleaned/modal-confirm-mark-cleaned.component';
import { ProjectRequestsComponent } from './projects/components/project-requests/project-requests.component';
import { ModalEditProjectVarietyTypeComponent } from './variety-cleaning/modal-edit-project-variety-type/modal-edit-project-variety-type.component';
import { ModalPeriodOtCleaningComponent } from './periods/modal-period-ot-cleaning/modal-period-ot-cleaning.component';
import { ModalEditOtInfoComponent } from './periods/modal-edit-ot-info/modal-edit-ot-info.component';
import { ModalOtHistoryComponent } from './periods/modal-ot-history/modal-ot-history.component';
import { ProjectsModule } from '../projects/projects.module';
import { ModalOtInfoComponent } from './periods/modal-ot-info/modal-ot-info.component';
import { ModalDisableAnswerComponent } from './periods/modal-disable-answer/modal-disable-answer.component';
import { ModalGaussianComponent } from './periods/modal-gaussian/modal-gaussian.component';
import { ModalMePieComponent } from './projects/modal-me-pie/modal-me-pie.component';
import { ModalDiversityBenefitAnswerComponent } from './projects/modal-diversity-benefit-answer/modal-diversity-benefit-answer.component';
import { ModalAddSubgoalBenefitComponent } from './projects/modal-add-subgoal-benefit/modal-add-subgoal-benefit.component';
import { ModalAddDescriptorBenefitComponent } from './projects/modal-add-descriptor-benefit/modal-add-descriptor-benefit.component';
import { ModalAnswerDescriptorBenefitComponent } from './projects/modal-answer-descriptor-benefit/modal-answer-descriptor-benefit.component';
import { ModalManageInterventionsComponent } from './projects/modal-manage-interventions/modal-manage-interventions.component';
import { ModalAddInterventionsComponent } from './projects/modal-add-interventions/modal-add-interventions.component';
import { ModalAnswerInterventionsComponent } from './projects/modal-answer-interventions/modal-answer-interventions.component';
import { ModalAnswerInterventionsCellComponent } from './projects/modal-answer-interventions-cell/modal-answer-interventions-cell.component';
import { ModalConfirmDeleteInterventionComponent } from './projects/modal-confirm-delete-intervention/modal-confirm-delete-intervention.component';
import { ModalConfirmDeleteSubgoalBenefitComponent } from './projects/modal-confirm-delete-subgoal-benefit/modal-confirm-delete-subgoal-benefit.component';
import { ModalConfirmDeleteDescriptorBenefitComponent } from './projects/modal-confirm-delete-descriptor-benefit/modal-confirm-delete-descriptor-benefit.component';
import { ModalMeInterventionAnswersComponent } from './projects/modal-me-intervention-answers/modal-me-intervention-answers.component';
import { ModalMeShowGoalsComponent } from './projects/modal-me-show-goals/modal-me-show-goals.component';
import { ModalMeSelectDescriptorRowComponent } from './projects/modal-me-select-descriptor-row/modal-me-select-descriptor-row.component';
import { ModalAddIntervensionsLegendComponent } from './projects/modal-add-intervensions-legend/modal-add-intervensions-legend.component';
import { ModalShowActivityImagesComponent } from './sites/modal-show-activity-images/modal-show-activity-images.component';
import { ModalShowVarietyImagesComponent } from './sites/modal-show-variety-images/modal-show-variety-images.component';

@NgModule({
  declarations: [
    ModalDescriptorComponent,
    ModalConstraintTreeComponent,
    ModalNewProjectComponent,
    ModalGoalComponent,
    ModalManagementComponent,
    ModalInterventionsComponent,
    ModalNewSiteComponent,
    ModalEditSiteComponent,
    ModalDeleteProjectComponent,
    ModalEditProfileComponent,
    ModalChangePasswordComponent,
    ModalShareProjectComponent,
    ModalEditTitleComponent,
    ModalQrCodeComponent,
    ModalDeclineRequestComponent,
    ModalSiteDataCollectedComponent,
    ModalChangeRoleComponent,
    ModalImportVarietyComponent,
    ModalAddVarietyComponent,
    ModalChangeLocalNamesComponent,
    ModalChangePhotosComponent,
    ModalChangeProjectPurposeComponent,
    ModalManageDescriptorsComponent,
    ModalDeleteVarietyComponent,
    ModalDeleteCountryComponent,
    ModalImageGalleryComponent,
    ModalManageVarietiesComponent,
    ModalAddBreedComponent,
    ModalNewRequestComponent,
    ModalLivestockAnswersComponent,
    ModalEditPeriodTitleComponent,
    ModalFileRequestedComponent,
    ModalClosePeriodComponent,
    ModalPeriodFileRequestedComponent,
    ModalFileAnalysisComponent,
    ModalPeriodDataAnalysisComponent,
    ModalAddGoalComponent,
    ModalEditActivityComponent,
    ModalRenameCleaningComponent,
    ModalCleaningDescriptorsComponent,
    ModalSplitVarietiesComponent,
    ModalJoinVarietiesComponent,
    ModalSwitchActivityStateComponent,
    ModalVarietiesInfoComponent,
    ModalHistoryComponent,
    PopconfirmRestoreComponent,
    ModalConfirmMarkCleanedComponent,
    ProjectRequestsComponent,
    ModalEditProjectVarietyTypeComponent,
    ModalPeriodOtCleaningComponent,
    ModalEditOtInfoComponent,
    ModalOtHistoryComponent,
    ModalOtInfoComponent,
    ModalDisableAnswerComponent,
    ModalGaussianComponent,
    ModalMePieComponent,
    ModalDiversityBenefitAnswerComponent,
    ModalAddSubgoalBenefitComponent,
    ModalAddDescriptorBenefitComponent,
    ModalAnswerDescriptorBenefitComponent,
    ModalManageInterventionsComponent,
    ModalAddInterventionsComponent,
    ModalAnswerInterventionsComponent,
    ModalAnswerInterventionsCellComponent,
    ModalConfirmDeleteInterventionComponent,
    ModalConfirmDeleteSubgoalBenefitComponent,
    ModalConfirmDeleteDescriptorBenefitComponent,
    ModalMeInterventionAnswersComponent,
    ModalMeShowGoalsComponent,
    ModalMeSelectDescriptorRowComponent,
    ModalAddIntervensionsLegendComponent,
    ModalShowActivityImagesComponent,
    ModalShowVarietyImagesComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MdbModule,
    LayoutModule,
    NgxQRCodeModule,
    ProjectsModule,
  ],
  exports: [
    ModalDescriptorComponent,
    ModalConstraintTreeComponent,
    ModalNewProjectComponent,
    ModalManagementComponent,
    ModalEditProfileComponent,
    ModalChangePasswordComponent,
    ModalShareProjectComponent,
    ModalEditTitleComponent,
    ModalQrCodeComponent,
    ModalChangeRoleComponent,
    ModalImportVarietyComponent,
    ModalAddVarietyComponent,
    ModalChangeProjectPurposeComponent,
    ModalManageDescriptorsComponent,
    ModalImageGalleryComponent,
    ModalManageVarietiesComponent,
    ModalFileAnalysisComponent,
    ModalPeriodDataAnalysisComponent,
    ModalAddGoalComponent,
    ModalEditActivityComponent,
    ModalAnswerInterventionsComponent,
  ],
})
export class ModalsModule {}
