import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'modal-add-intervensions-legend',
  templateUrl: './modal-add-intervensions-legend.component.html',
  styleUrls: ['./modal-add-intervensions-legend.component.scss'],
})
export class ModalAddIntervensionsLegendComponent implements OnInit {
  constructor(
    public modalRef: MdbModalRef<ModalAddIntervensionsLegendComponent>
  ) {}
  legend = [
    {
      title: 'LEGEND.SUGGESTED_TITLE',
      desc: 'LEGEND.SUGGESTED_DESC',
      src: '/assets/images/legend/legend_1.png',
    },
    {
      title: 'LEGEND.SUGGESTED_PREV_SELECTED_TITLE',
      desc: 'LEGEND.SUGGESTED_PREV_SELECTED_DESC',
      src: '/assets/images/legend/legend_2.png',
    },
    {
      title: 'LEGEND.NOT_SUGGESTED_CUR_SELECTED_TITLE',
      desc: 'LEGEND.NOT_SUGGESTED_CUR_SELECTED_DESC',
      src: '/assets/images/legend/legend_3.png',
    },
    {
      title: 'LEGEND.NOT_SUGGESTED_CUR_SELECTED_TITLE',
      desc: 'LEGEND.NOT_SUGGESTED_CUR_SELECTED_DESC',
      src: '/assets/images/legend/legend_4.png',
    },
    {
      title: 'LEGEND.NOT_SELECTED_TITLE',
      desc: 'LEGEND.NOT_SELECTED_DESC',
      src: '/assets/images/legend/legend_5.png',
    },
  ];

  ngOnInit(): void {}
}
