<div
  class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
  [ngClass]="glass ? 'glass' : ''"
>
  <div class="d-flex justify-content-center">
    <div
      class="spinner-grow mx-1 orange"
      role="status"
      style="width: 3rem; height: 3rem"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <div
      class="spinner-grow mx-1 green"
      role="status"
      style="width: 3rem; height: 3rem"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <div
      class="spinner-grow mx-1 green-dark"
      role="status"
      style="width: 3rem; height: 3rem"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <div
      class="spinner-grow mx-1 blue"
      role="status"
      style="width: 3rem; height: 3rem"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <div
      class="spinner-grow mx-1 orange"
      role="status"
      style="width: 3rem; height: 3rem"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
