<div class="modal-body d-flex flex-column">
  <span>
    <strong>{{ "OT_CLEANING.TOS" | translate }}:</strong>
    {{ item.typeOfSource }}
  </span>
  <span>
    <strong>{{ "OT_CLEANING.TOM" | translate }}:</strong>
    {{ item.reproductiveMaterial }}
  </span>
  <span>
    <strong>{{ "OT_CLEANING.QUANTITY" | translate }}:</strong>
    {{ item.quantity + " " + item.measureLang }}
  </span>
  <span>
    <strong>
      {{ item.purchased }}
    </strong>
  </span>
  <span>
    <strong>{{ "OT_CLEANING.CONSTRAINTS" | translate }}:</strong>
    {{ item.constraints }}
  </span>
  <span *ngIf="item.notes">
    <strong>{{ "OT_CLEANING.NOTES" | translate }}:</strong>
    {{ item.notes }}
  </span>
</div>
<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close()">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
</div>
