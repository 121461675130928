import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'horizontal-list-button',
  templateUrl: './horizontal-list-button.component.html',
  styleUrls: ['./horizontal-list-button.component.scss'],
})
export class HorizontalListButtonComponent implements OnInit {
  @Input() routerLink: string = '';
  @Input() name: string = '';
  // @Input() activeClass: string = '';

  constructor() {}

  ngOnInit(): void {}
}
