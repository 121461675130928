<div class="card col mb-3" style="overflow-y: auto; height: 40rem">
  <div class="mb-2 pb-0 sticky-top bg-white">
    <div
      class="d-flex flex-row mt-3 justify-content-between align-items-center w-100"
    >
      <h3 class="mx-3 my-auto">{{ "NAVIGATION.PROJECTS" | translate }}</h3>
    </div>
    <div class="mx-2 mt-2 w-90 bg-primary" style="height: 2px"></div>
  </div>

  <div class="d-flex flex-column mx-2 pb-1 pt-2">
    <disclaimer
      class="mt-4"
      text="VARIETY.WORK_IN_PROGRESS"
    ></disclaimer>
  </div>
</div>
