import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'modal-edit-ot-info',
  templateUrl: './modal-edit-ot-info.component.html',
  styleUrls: ['./modal-edit-ot-info.component.scss'],
})
export class ModalEditOtInfoComponent implements OnInit {
  quantity?: number;
  measure?: string;
  type?: string;
  link?: string;
  refID?: string;
  error?: string;

  editForm?: UntypedFormGroup;

  measureList?: Select[];

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalEditOtInfoComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.api.getMeasureOt(this.type!).subscribe((value) => {
      this.measureList = value as Select[];
    });
    this.editForm = new UntypedFormGroup({
      quantityForm: new UntypedFormControl(this.quantity),
      measureForm: new UntypedFormControl(this.measure),
    });
  }

  edit() {
    this.api
      .editOT(
        this.link!,
        this.refID!,
        this.quantityForm?.value,
        this.measureForm?.value
      )
      .subscribe({
        next: (value) => {
          this.modalRef.close(value);
        },
        error: (error) => {
          this.error = error.status;
        },
      });
  }

  get quantityForm() {
    return this.editForm?.get('quantityForm');
  }

  get measureForm() {
    return this.editForm?.get('measureForm');
  }
}
