import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'database-crop-variety-type',
  templateUrl: './database-crop-variety-type.component.html',
  styleUrls: ['./database-crop-variety-type.component.scss'],
})
export class DatabaseCropVarietyTypeComponent implements OnInit {
  data?: any;

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.apiCall();
  }

  apiCall() {
    this.api.getMaterialTypeCategories('CROPS').subscribe((value) => {
      this.data = value as any;
    });
  }
}
