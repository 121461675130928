<div class="modal-header">
  <div class="d-flex flex-row">
    <i class="bi bi-pencil-fill h3"></i>
    <h4 class="mx-1 h3 mb-0">{{ "WORKING_AREA.CHANGE_LOCAL" | translate }}</h4>
  </div>
</div>
<div class="modal-body py-0">
  <div class="row my-3" [formGroup]="formEdit!">
    <div class="col-5">
      <div>
        <input
          type="text"
          id="form1"
          class="form-control"
          placeholder="{{ 'PLACEHOLDER.LOCALITY' | translate }}"
          formControlName="locality"
        />
      </div>
    </div>
    <div class="col-5">
      <div>
        <input
          type="text"
          id="form1"
          class="form-control"
          placeholder="{{ 'PLACEHOLDER.NAME' | translate }}"
          formControlName="value"
        />
      </div>
    </div>
    <div class="col-2">
      <div class="btn btn-success my-auto mx-auto" (click)="addName()">
        {{ "BUTTONS." + button | translate }}
      </div>
    </div>
  </div>
  <div class="bg-primary w-100 pb-1" style="height: 1px"></div>
  <div class="d-flex flex-column py-2" style="height: 30rem; overflow-y: auto">
    <div
      class="row py-2 my-1 mx-2 rounded align-items-center text-black"
      [ngClass]="i % 2 == 0 ? 'bg-light' : 'bg-transparent '"
      *ngFor="let name of variety?.localNames; index as i"
    >
      <div class="col-5">
        <h5 class="my-auto">{{ name.country }}-{{ name.locality }}</h5>
      </div>
      <div class="col-5">
        <h5 class="my-auto">{{ name.value }}</h5>
      </div>
      <div class="col-2 d-flex justify-content-center">
        <i
          class="bi bi-pencil-fill h5 me-1"
          (click)="editLocalName(name, i)"
        ></i>
        <i class="bi bi-trash2-fill h5 ms-1" (click)="removeName(name, i)"></i>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-0">
    <div class="btn btn-primary" (click)="modalRef.close()">
      {{ "BUTTONS.CLOSE" | translate }}
    </div>
  </div>
</div>
