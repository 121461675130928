import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { SupplierCategory } from 'src/app/models/supplier-type';

@Component({
  selector: 'gmp-sectors',
  templateUrl: './gmp-sectors.component.html',
  styleUrls: ['./gmp-sectors.component.scss'],
})
export class GmpSectorsComponent implements OnInit, OnDestroy {
  langSub?: Subscription;

  sectors?: SupplierCategory[];

  constructor(private api: ApiService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.apiCalls();

    this.langSub = this.translate.onLangChange.subscribe(() => {
      this.apiCalls();
    });
  }

  apiCalls() {
    this.api.getSupplierCategory().subscribe((value) => {
      this.sectors = value as SupplierCategory[];
    });
  }

  ngOnDestroy(): void {
    this.langSub?.unsubscribe();
  }
}
