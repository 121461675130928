<div class="card mt-3 mx-1 pb-3" style="width: 50vw; overflow: hidden">
  <mdb-accordion flush="true">
    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>
        {{ "GMP.TYPES_LV_1" | translate }}
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="pb-2" style="height: 17rem; overflow-y: auto">
          <div class="row pt-2 mb-2 px-2 mx-2 sticky-top bg-white">
            <div class="col-3">Code</div>
            <div class="col-9">Name</div>
            <div class="bg-primary" style="height: 2px"></div>
          </div>
          <div
            class="row py-2 px-2 mx-4 rounded"
            *ngFor="let type of level1; index as i"
            [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
          >
            <div class="col-3">{{ type.code }}</div>
            <div class="col-9">{{ type.nameLang }}</div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>

    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>
        {{ "GMP.TYPES_LV_2" | translate }}
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="pb-2" style="height: 17rem; overflow-y: auto">
          <div class="row pt-2 mb-2 px-2 mx-2 sticky-top bg-white">
            <div class="col-3">Code</div>
            <div class="col-9">Name</div>
            <div class="bg-primary" style="height: 2px"></div>
          </div>
          <div
            class="row py-2 px-2 mx-4 rounded"
            *ngFor="let subType of level2; index as i"
            [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
          >
            <div class="col-3">{{ subType.code }}</div>
            <div class="col-9">{{ subType.nameLang }}</div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>

    <mdb-accordion-item>
      <ng-template mdbAccordionItemHeader>
        {{ "GMP.GEOGRAPHICAL" | translate }}
      </ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="pb-2" style="height: 17rem; overflow-y: auto">
          <div class="row pt-2 mb-2 px-2 mx-2 sticky-top bg-white">
            <div class="col-3">Code</div>
            <div class="col-9">Name</div>
            <div class="bg-primary" style="height: 2px"></div>
          </div>
          <div
            class="row py-2 px-2 mx-4 rounded"
            *ngFor="let geo of geoReg; index as i"
            [ngClass]="i % 2 == 0 ? 'bg-white' : 'bg-light'"
          >
            <div class="col-3">{{ geo.code }}</div>
            <div class="col-9">{{ geo.nameLang }}</div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
