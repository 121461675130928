import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import {
  PolicyCategory,
  PolicyDescriptor,
} from 'src/app/models/policy-category';

@Component({
  selector: 'policy-ref',
  templateUrl: './policy-ref.component.html',
  styleUrls: ['./policy-ref.component.scss'],
})
export class PolicyRefComponent implements OnInit, OnDestroy {
  lang$?: Subscription;

  policies: any[] = [];
  subCategories: any[] = [];
  descriptors: any[] = [];

  constructor(
    private api: ApiService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.apiCall();

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.apiCall();
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  getCurrentLocation() {
    const path = this.router.url;
    return path.split('/')[4];
  }

  apiCall() {
    let databaseType: string = this.getCurrentLocation().toUpperCase();
    if (databaseType === 'CROP') databaseType = 'CROPS';
    if (databaseType === 'AQUACULTURE') databaseType = 'AQUATICS';

    this.api.getPolicyCategories(databaseType).subscribe((data) => {
      this.policies = data as any[];
    });
  }

  onSelectType(i: number) {
    this.policies?.filter((value) => {
      value.selected = false;
    });
    this.subCategories?.filter((value) => {
      value.selected = false;
    });
    this.descriptors.filter((value) => {
      value.selected = false;
    });
    this.policies[i].selected = true;
    this.descriptors = [];
    this.subCategories = this.policies[i].subcategories;
  }

  loadDescriptors(i: number) {
    this.subCategories?.filter((value) => {
      value.selected = false;
    });
    this.descriptors.filter((value) => {
      value.selected = false;
    });
    this.descriptors = this.subCategories[i].descriptors;
    this.subCategories[i].selected = true;
  }

  selectDescriptor(i: number) {
    this.descriptors.filter((value) => {
      value.selected = false;
    });
    this.descriptors[i].selected = true;
  }
}
