import { LocationStrategy } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MdbStepperComponent } from 'mdb-angular-ui-kit/stepper/stepper.component';
import { ApiService } from 'src/app/api.service';
import { Breed } from 'src/app/models/breed';
import { Specie } from 'src/app/models/project';

@Component({
  selector: 'modal-add-breed',
  templateUrl: './modal-add-breed.component.html',
  styleUrls: ['./modal-add-breed.component.scss'],
})
export class ModalAddBreedComponent implements OnInit {
  entity?: any;

  breedID?: string;
  ref?: string;

  infoForm?: FormGroup;

  specieList?: any[];
  varietyList?: any[];
  materials?: any[];

  showSpecies: boolean = false;
  showVariety: boolean = false;

  constructor(
    private api: ApiService,
    public modalRef: MdbModalRef<ModalAddBreedComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.infoForm = new FormGroup({
      varietyName: new FormControl(this.entity?.name, [Validators.required]),
      species: new FormControl(this.entity?.speciesID, [Validators.required]),
      material: new FormControl(null),
      varietyType: new FormControl(this.entity?.varietyType),
      hybrid: new FormControl(this.entity?.speciesHybridID),
      varieties: new FormControl(this.entity?.mixtureWith),
    });

    this.getMaterialClassification();
    this.apiCall();

    this.material?.valueChanges.subscribe((value: any) => {
      this.varieties?.removeValidators(Validators.required);
      this.varieties?.updateValueAndValidity();
      this.varieties?.removeValidators(Validators.required);
      this.varieties?.updateValueAndValidity();
      this.setVarietyType(value);
    });

    this.api.getSpeciesSelect(null, this.breedID, null).subscribe((value) => {
      this.specieList = value as any[];
    });
  }

  apiCall() {
    if (this.breedID) {
      this.api.getSpeciesSelect(this.breedID).subscribe((value) => {
        this.specieList = value as Specie[];
      });
      this.api.getBreedSelect(this.breedID).subscribe((value) => {
        this.varietyList = value as Breed[];
      });
    }
  }

  setVarietyType(material: any) {
    this.showSpecies = material.needSpeciesList;
    this.showVariety = material.needVarietyList;
    this.varietyType?.setValue(material.materialTypeClassificationID);
    if (this.showSpecies) {
      this.hybrid?.setValidators(Validators.required);
      this.hybrid?.updateValueAndValidity();
    }
    if (this.showVariety) {
      this.varieties?.setValidators(Validators.required);
      this.varieties?.updateValueAndValidity();
    }
  }

  getMaterialClassification() {
    this.api
      .getMaterialTypeClassification(this.ref ?? '')
      .subscribe((value) => {
        this.materials = value as any[];
        if (this.entity) {
          const filter = this.materials?.filter(
            (e) => e.materialTypeClassificationID == this.entity.varietyTypeID
          );
          if (filter) {
            this.material?.setValue(filter[0]);
          }
        }
      });
  }

  addBreed() {
    if (this.infoForm?.invalid) {
      this.infoForm?.markAllAsTouched();
      return;
    }
    const body = {
      breedID: this.entity?.varietyID,
      refID: this.breedID,
      name: this.varietyName?.value,
      species: this.species?.value,
      hybridWith: this.hybrid?.value,
      varietyType: this.varietyType?.value,
      mixtureWith: this.varieties?.value,
    };

    this.api.addBreed(body).subscribe(() => {
      this.modalRef.close(true);
    });
  }

  get varietyName() {
    return this.infoForm?.get('varietyName');
  }
  get species() {
    return this.infoForm?.get('species');
  }
  get hybrid() {
    return this.infoForm?.get('hybrid');
  }
  get material() {
    return this.infoForm?.get('material');
  }
  get varietyType() {
    return this.infoForm?.get('varietyType');
  }
  get varieties() {
    return this.infoForm?.get('varieties');
  }
}
