<div class="d-flex min-vh-100 w-100 bg-light">
  <div class="d-flex align-items-end justify-content-end w-100">
    <img src="../../../../assets/images/logo.png" />
  </div>
  <div
    class="mask row justify-content-center align-items-center glass"
    style="overflow-y: auto"
  >
    <div class="card col-xl-5 col-md-7 col-9" style="overflow-y: auto">
      <div class="mx-2 py-4 px-3">
        <div class="d-flex align-items-center">
          <i class="bi bi-door-open-fill h1"> </i>
          <h1 class="mx-1 fw-bold">{{ "REGISTER.REGISTER" | translate }}</h1>
        </div>
        <div class="bg-primary w-75 mx-1 mb-2" style="height: 2.5px"></div>
        <div
          class="mx-1 w-75 text-white border-danger rounded-4 d-flex align-items-center justify-content-center login-error"
          style="
            height: 3.2rem;
            background-color: #ee2b2bc4;
            border: solid 3.5px;
          "
          *ngIf="displayError"
        >
          <p class="mx-2 my-auto">{{ displayError }}</p>
        </div>
        <form [formGroup]="registerForm!">
          <!-- NAME -->
          <div class="mx-1 my-2 mt-3">
            <div class="d-flex">
              <i class="bi bi-person-fill h5"></i>
              <h5 class="pb-1 m-0 mx-2">{{ "USER.NAME" | translate }}</h5>
            </div>
            <div class="d-flex flex-column p-0 m-0">
              <div class="d-flex flex-row">
                <input
                  type="text"
                  class="w-75 form-control"
                  formControlName="name"
                />
                <i
                  *ngIf="name?.invalid && name?.touched"
                  class="bi bi-exclamation-triangle-fill my-auto mx-2 h3 text-danger invalid-icon"
                ></i>
              </div>
              <p
                *ngIf="name?.invalid && name?.touched"
                class="fst-italic p-0 m-0 mx-2 text-danger invalid-text"
              >
                {{ "REGISTER.REQUIRED" | translate }}
              </p>
            </div>
          </div>
          <!-- SURNAME -->
          <div class="mx-1 my-2 mt-3">
            <div class="d-flex">
              <i class="bi bi-person-fill h5"></i>
              <h5 class="pb-1 m-0 mx-2">
                {{ "USER.SURNAME" | translate }}
              </h5>
            </div>
            <div class="d-flex flex-column p-0 m-0">
              <div class="d-flex flex-row">
                <input
                  type="text"
                  class="w-75 form-control"
                  formControlName="surname"
                />
                <i
                  *ngIf="surname?.invalid && surname?.touched"
                  class="bi bi-exclamation-triangle-fill my-auto mx-2 h3 text-danger invalid-icon"
                ></i>
              </div>
              <p
                *ngIf="surname?.invalid && surname?.touched"
                class="fst-italic p-0 m-0 mx-2 text-danger invalid-text"
              >
                {{ "REGISTER.REQUIRED" | translate }}
              </p>
            </div>
          </div>
          <!-- EMAIL -->
          <div class="mx-1 my-2 mt-3">
            <div class="d-flex">
              <i class="bi bi-envelope-fill h5"></i>
              <h5 class="pb-1 m-0 mx-2">{{ "USER.EMAIL" | translate }}</h5>
            </div>
            <div class="d-flex flex-column p-0 m-0">
              <div class="d-flex flex-row">
                <input
                  type="text"
                  class="w-75 form-control"
                  formControlName="email"
                />
                <i
                  *ngIf="email?.invalid && email?.touched"
                  class="bi bi-exclamation-triangle-fill my-auto mx-2 h3 text-danger invalid-icon"
                ></i>
              </div>
              <p
                *ngIf="email?.invalid && email?.touched"
                class="fst-italic p-0 m-0 mx-2 text-danger invalid-text"
              >
                {{ "REGISTER.REQUIRED" | translate }}
              </p>
            </div>
          </div>
          <!-- COUNTRY -->
          <div class="mx-1 my-2 mt-3">
            <div class="d-flex">
              <i class="bi bi-geo-fill h5"></i>
              <h5 class="pb-1 m-0 mx-2">
                {{ "USER.COUNTRY" | translate }}
              </h5>
            </div>
            <div class="d-flex flex-column p-0 m-0">
              <div class="d-flex">
                <ng-select
                  class="w-75 control"
                  formControlName="country"
                  [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
                >
                  <ng-option
                    *ngFor="let country of countrySelect"
                    [value]="country.value"
                  >
                    {{ country.label }}
                  </ng-option>
                </ng-select>
                <i
                  *ngIf="country?.invalid && country?.touched"
                  class="bi bi-exclamation-triangle-fill my-auto mx-2 h3 text-danger invalid-icon"
                ></i>
              </div>

              <p
                *ngIf="country?.invalid && country?.touched"
                class="fst-italic p-0 m-0 mx-2 text-danger invalid-text"
              >
                {{ "REGISTER.REQUIRED" | translate }}
              </p>
            </div>
          </div>
          <!-- BIRTHDAY -->
          <div class="mx-1 my-2 mt-3">
            <div class="d-flex">
              <i class="bi bi-calendar-fill h5"></i>
              <h5 class="pb-1 m-0 mx-2">
                {{ "USER.BIRTH_YEAR" | translate }}
              </h5>
            </div>
            <div class="d-flex flex-column p-0 m-0">
              <div class="d-flex flex-row">
                <input
                  type="text"
                  class="w-25 form-control"
                  formControlName="yearBirth"
                />
                <i
                  *ngIf="yearBirth?.invalid && yearBirth?.touched"
                  class="bi bi-exclamation-triangle-fill my-auto mx-2 h3 text-danger invalid-icon"
                ></i>
              </div>
              <p
                *ngIf="yearBirth?.invalid && yearBirth?.touched"
                class="fst-italic p-0 m-0 mx-2 text-danger invalid-text"
              >
                {{ "REGISTER.REQUIRED" | translate }}
              </p>
            </div>
          </div>
          <!-- GENDER -->
          <div class="d-flex flex-column">
            <div class="d-flex flex-row w-100 pt-2 align-items-center">
              <h5 class="text-primary">{{ "USER.GENDER" | translate }}:</h5>
              <div class="d-flex w-50">
                <div class="form-check">
                  <input
                    class="form-check-input mx-2"
                    type="radio"
                    id="male"
                    value="M"
                    formControlName="gender"
                  />
                  <label class="form-check-label" for="Male">
                    {{ "REGISTER.MALE" | translate }}
                  </label>
                </div>
                <div class="form-check mx-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="female"
                    value="F"
                    formControlName="gender"
                  />
                  <label class="form-check-label" for="female">
                    {{ "REGISTER.FEMALE" | translate }}
                  </label>
                </div>
                <div class="form-check mx-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="fluid"
                    value="O"
                    formControlName="gender"
                  />
                  <label class="form-check-label" for="fluid">
                    {{ "REGISTER.DIVERSE" | translate }}
                  </label>
                </div>
              </div>
              <i
                *ngIf="gender?.invalid && gender?.touched"
                class="bi bi-exclamation-triangle-fill my-auto px-4 h3 text-danger invalid-icon"
              ></i>
            </div>
            <p
              *ngIf="gender?.invalid && gender?.touched"
              class="fst-italic p-0 m-0 mx-2 text-danger invalid-text"
            >
              {{ "REGISTER.REQUIRED" | translate }}
            </p>
          </div>
          <!-- TERMS & PRIVACY -->
          <div class="d-flex flex-row pt-4 p-0 align-items-center py-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              formControlName="terms"
            />
            <a
              [href]="goToTerms()"
              target="_blank"
              style="text-decoration: underline"
            >
              <p class="my-auto">{{ "REGISTER.TERMS" | translate }}</p>
            </a>
            <i
              *ngIf="terms?.invalid && terms?.touched"
              class="bi bi-exclamation-triangle-fill my-auto mx-2 h3 text-danger invalid-icon"
            ></i>
          </div>
          <!-- TERMS & PRIVACY -->
          <div class="d-flex flex-row p-0 m-0 align-items-center py-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              formControlName="privacy"
            />
            <a
              [href]="goToPrivacy()"
              target="_blank"
              style="text-decoration: underline"
            >
              <p class="my-auto">{{ "REGISTER.PRIVACY" | translate }}</p>
            </a>
            <i
              *ngIf="privacy?.invalid && privacy?.touched"
              class="bi bi-exclamation-triangle-fill my-auto mx-2 h3 text-danger invalid-icon"
            ></i>
          </div>

          <div
            class="d-flex flex-lg-row flex-column justify-content-between mt-3 mb-3 mx-2"
          >
            <div
              class="btn btn-success fw-bold text-white"
              (click)="register()"
            >
              {{ "REGISTER.CREATE_ACCOUNT" | translate }}
            </div>
            <u class="py-lg-0 py-2 mx-auto mx-lg-0">
              <p routerLink="/log-in" class="my-auto hover">
                {{ "REGISTER.BACK_TO_LOG_IN" | translate }}
              </p>
            </u>
          </div>
        </form>
        <change-lang></change-lang>
      </div>
    </div>
    <loading class="mask" *ngIf="loading"></loading>
  </div>
</div>
