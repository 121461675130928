import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Image } from 'src/app/models/image';
import { Buffer } from 'buffer';
import { HttpEventType } from '@angular/common/http';
import { fromEvent, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ModalImageGalleryComponent } from '../../layout/modal-image-gallery/modal-image-gallery.component';
import { LocationStrategy } from '@angular/common';

export interface FileToUpload {
  file: File;
  fileName: string;
  progress: number;
}

@Component({
  selector: 'modal-change-photos',
  templateUrl: './modal-change-photos.component.html',
  styleUrls: ['./modal-change-photos.component.scss'],
})
export class ModalChangePhotosComponent implements OnInit, OnDestroy {
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.modalRef.close(false);
  }

  partnerRef?: string;
  varietyID?: string;
  photos?: Image[];

  imgSrc?: any;
  file?: File;
  files: FileToUpload[] = [];
  isUploading: boolean = false;
  uploadingN?: number;
  uploadProgress?: number;
  uploadSub?: Subscription;

  allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg'];

  constructor(
    private api: ApiService,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<ModalChangePhotosComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    // fromEvent(window, 'popstate').subscribe((event) => {
    //   event.stopImmediatePropagation();
    //   this.modalRef.close(false);
    // });
  }

  ngOnDestroy(): void {
    this.uploadSub?.unsubscribe();
  }

  onBack = () => {};

  onFileSelected(event: any) {
    // this.files = event.target.files;
    Array.from(event.target.files).forEach((file: any) => {
      let fileToUpload: FileToUpload = {
        file: file as File,
        fileName: file.name as string,
        progress: 0,
      };
      if (
        fileToUpload.file instanceof File &&
        this.allowedExtensions.includes(fileToUpload.file.type)
      ) {
        this.files.push(fileToUpload);
      }
    });
    // for (let i =0; i < event.target.files.lenght; i++) {
    // this.files.push(event.target.files[i])
    // }
    // this.files?.push(event.target.files);
    // console.log(event.target.files instanceof File);

    // const files: File[] = event.target.files;
    // if (files) {
    // this.uploadingN = 0;
    // files.forEach((file) => {
    //
    // });
    // }

    //
    // if (file) {
    // this.file = file;
    // const reader = new FileReader();
    // reader.onload = (e) => {
    // this.imgSrc = e.target?.result;
    // };
    // reader.readAsDataURL(file);
    // }
  }

  // readBuffer(img: string): any {
  //   const matches = img.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
  //   if (matches?.length !== 3) {
  //     return null;
  //   }
  //   const buffer = Buffer.from(matches[2], 'base64');
  //   console.log(buffer)
  //   return buffer;
  // }

  startUploading() {
    if (this.files) {
      this.uploadFile(0);
    }
  }

  uploadFile(i: number) {
    if (i <= this.files!.length - 1) {
      const fileToUpload = this.files![i];
      if (this.varietyID) {
        const formData = new FormData();
        formData.append('file', fileToUpload.file, fileToUpload.fileName);
        formData.append('refID', this.varietyID);
        formData.append('folder', this.getFolder());
        formData.append('ref', this.partnerRef!);
        formData.append('type', fileToUpload.file.type);
        formData.append('fileName', fileToUpload.fileName);
        formData.append('fileSize', '' + fileToUpload.file.size);

        const upload$ = this.api.updateGalleryTest(formData).pipe(
          finalize(() => {
            this.uploadFile(++i);
          })
        );

        upload$.subscribe((event) => {
          if (event.type == HttpEventType.Response) {
            this.photos!.push(event.body as Image);
          }
          if (event.type == HttpEventType.UploadProgress) {
            this.files![i].progress = Math.round(
              100 * (event.loaded / event.total!)
            );
          }
        });
      }
    } else {
      this.cleanUploads();
    }
  }

  getFolder(): string {
    switch (this.partnerRef) {
      case 'CROPS':
        return 'varieties';
      case 'LIVESTOCK':
        return 'breeds';
      default:
        return '';
    }
  }
  // nextFile() {
  //   this.uploadProgress = 0;
  //   if(this.uploadingN <= this.files.lenght-1){}

  // }

  removeFromUploadList(i: number) {
    this.files.splice(i, 1);
  }

  cleanUploads() {
    this.files = [];
  }

  deletePhoto(image: Image, i: number) {
    this.api.deleteImage(image.imageGalleryID, 'test-gallery').subscribe(() => {
      this.photos?.splice(i, 1);
    });
  }

  formatString(string?: string): string {
    if (!string) return 'vuoto';
    if (string.length > 20) {
      return string.substring(0, 17) + '...';
    }
    return string;
  }

  openImageGallery(i: number) {
    this.modalService.open(ModalImageGalleryComponent, {
      modalClass: 'modal-fullscreen',
      data: { position: i, images: this.photos },
    });
  }
}
