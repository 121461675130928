import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api.service';
import { Project } from 'src/app/models/project';

@Component({
  selector: 'declined-requests-page',
  templateUrl: './declined-requests-page.component.html',
  styleUrls: ['./declined-requests-page.component.scss'],
})
export class DeclinedRequestsPageComponent implements OnInit {
  requests?: Project[];
  constructor(private api: ApiService, private router: Router) {}

  ngOnInit(): void {
    this.apiCall();
  }

  apiCall() {
    this.api.getProjectsDeclined().subscribe((value) => {
      this.requests = value as Project[];
    });
  }

  shrinkRequest(string: string | undefined) {
    if (string?.length && string.length <= 30) return string;
    return string?.substring(0, 27) + ' ...';
  }

  acceptRequest(projectID: string) {
    this.api.acceptProjectUser(projectID).subscribe(() => {
      this.apiCall();
    });
  }

  backToProjects() {
    this.router.navigateByUrl('u/projects');
  }
}
