import { authInitialState } from './auth.state';
import { AuthAction, AuthActionTypes } from './auth.action';

export function authReducer(state = authInitialState, action: AuthAction) {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        user: action.payload.user,
        isLoggedIn: true,
        isLoading: false,
        error: null,
      });
    }

    case AuthActionTypes.LOGGED_IN: {
      return Object.assign({}, state, {
        user: action.payload.user,
        isLoggedIn: true,
        isLoading: false,
        error: null,
      });
    }

    case AuthActionTypes.UPDATE_PROFILE_SUCCESS: {
      return Object.assign({}, state, {
        user: action.payload.user,
      });
    }

    case AuthActionTypes.LOGIN_FAILED: {
      return Object.assign({}, state, {
        user: null,
        isLoading: false,
        isLoggedIn: false,
      });
    }

    case AuthActionTypes.AUTH_ERROR: {
      return Object.assign({}, state, {
        error: action.payload.status,
      });
    }

    case AuthActionTypes.LOGOUT_COMPLETED: {
      return Object.assign({}, state, {
        user: null,
        isLoading: false,
        isLoggedIn: false,
      });
    }

    case AuthActionTypes.IS_LOADING: {
      return Object.assign({}, state, {
        isLoading: action.payload.loading,
      });
    }

    case AuthActionTypes.SAVE_USER: {
      return Object.assign({}, state, {
        user: action.payload.user,
      });
    }

    default:
      return state;
  }
}
