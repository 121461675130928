import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { Descriptor } from 'src/app/models/descriptor';
import { VarietyInfo } from 'src/app/projects/grid-system-page/grid-system-page.component';
import { ModalDisableAnswerComponent } from '../modal-disable-answer/modal-disable-answer.component';
import { ModalEditOtInfoComponent } from '../modal-edit-ot-info/modal-edit-ot-info.component';
import { ModalGaussianComponent } from '../modal-gaussian/modal-gaussian.component';
import { ModalOtHistoryComponent } from '../modal-ot-history/modal-ot-history.component';
import { ModalOtInfoComponent } from '../modal-ot-info/modal-ot-info.component';

@Component({
  selector: 'modal-period-ot-cleaning',
  templateUrl: './modal-period-ot-cleaning.component.html',
  styleUrls: ['./modal-period-ot-cleaning.component.scss'],
})
export class ModalPeriodOtCleaningComponent implements OnInit, OnDestroy {
  title?: string;
  type?: string;
  link?: string;
  refID?: string;
  familyID?: string;
  otID?: string;

  measure?: string;

  state = 1;

  // modifyForm?: UntypedFormGroup;

  projectID?: string;
  varietiesSelectedIDs?: string[];

  types?: any[];

  columnHeaders: any[] = [];
  isHeaderEmpty?: boolean;

  rows: any[] = [];

  globalLoading?: boolean = true;
  loading?: boolean;

  varietyInfo?: VarietyInfo;
  answerInfo?: any;

  selectedRow: number = -1;
  selectedRows: number[] = [];
  selectedColumn: number = -1;
  selectedColumns: number[] = [];
  selectedAnswer: any;
  selectedColumnData: any;
  minG: boolean = false;
  maxG: boolean = false;
  minI: boolean = false;
  maxI: boolean = false;
  // loadingMinMax: boolean = false;
  minMaxes?: any[];

  isGeneralInfosOpened: boolean = false;
  isLegendOpened: boolean = false;
  isDetailOpened: boolean = false;

  descriptor?: Descriptor;
  selectedType?: string;

  constructor(
    private api: ApiService,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<ModalPeriodOtCleaningComponent>,
    private location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);

    this.location.onPopState((event: any) => {
      this.modalRef.close();
    });
  }

  ngOnInit(): void {
    this.checkEditing();
  }

  ngOnDestroy(): void {
    this.stopCleaning();
  }

  checkEditing() {
    this.api
      .createOtCleaning(this.refID!, this.otID!, this.familyID!)
      .subscribe({
        next: (value) => {
          this.globalLoading = false;
          this.state = 1;
        },
        error: (error) => {
          if (error.status == 409) {
            this.globalLoading = false;
            this.state = 2;
          }
        },
      });
  }

  stopCleaning() {
    if (this.state != 2) {
      this.api
        .stopOtCleaning(this.refID!, this.otID!, this.familyID!)
        .subscribe((value) => {});
    }
  }

  getTableStructure() {
    this.cleanTable();
    this.isDetailOpened = false;
    this.globalLoading = true;
    this.api.getOtStructureHeaders(this.link!).subscribe((value) => {
      this.columnHeaders = value as any;
      this.isHeaderEmpty = this.columnHeaders.length == 0;
      if (!this.isHeaderEmpty) {
        this.api
          .getOtStructure(this.link!, this.refID!, this.familyID!, this.measure)
          .subscribe((value) => {
            this.rows = value as any;
            this.globalLoading = false;
            this.state = 3;
          });
      } else {
        this.globalLoading = false;
      }
    });
  }

  getColumnHeaderLivestock(): any[] {
    let newColumns: any[] = [];
    this.columnHeaders.forEach((item, i) => {
      if (i > 0) {
        if (item.refID == newColumns[newColumns.length - 1].refID) {
          newColumns[newColumns.length - 1].span += 1;
        } else {
          item.span = 1;
          newColumns.push(item);
        }
      } else {
        item.span = 1;
        newColumns.push(item);
      }
    });
    return newColumns;
  }

  selectColumn(columnIndex: number) {
    this.selectedRow = -1;
    this.selectedColumn = columnIndex;
    this.answerInfo = null;
    this.selectedAnswer = null;
    // this.selectedColumns = [];
    // let max = this.getColumnHeaderLivestock()[columnIndex].span;
    // let min = 0;
    // if (columnIndex > 0) {
    //   this.getColumnHeaderLivestock().forEach((item, i) => {
    //     if (i < columnIndex) min += item.span;
    //   });
    // }
    // for (let i = 0; i < max; i++) {
    //   this.selectedColumns.push(min + i);
    // }

    this.getHeaderData(columnIndex);

    this.cleanTableUtilities();
  }

  getHeaderData(columnIndex: number) {
    this.globalLoading = true;
    this.api
      .getViewOT(
        this.columnHeaders[columnIndex].urlView,
        this.refID!,
        this.familyID!,
        undefined,
        this.columnHeaders[columnIndex].colID,
        undefined,
        this.measure
      )
      .subscribe({
        next: (value) => {
          this.selectedColumnData = value as any;
          this.globalLoading = false;
          this.isDetailOpened = true;
          if (this.minG) {
            this.getMin('g');
          } else if (this.maxG) {
            this.getMax('g');
          } else if (this.minI) {
            this.getMin('i');
          } else if (this.maxI) {
            this.getMax('i');
          }
        },
        error: (e) => {
          this.globalLoading = true;
        },
      });
  }

  cleanTableUtilities() {
    this.cleanMinMax();
    this.isGeneralInfosOpened = false;
    this.isLegendOpened = false;
    this.isDetailOpened = false;
  }

  cleanTable() {
    this.columnHeaders = [];
    this.rows = [];
    this.selectedRow = -1;
    this.selectedRows = [];
    this.selectedColumn = -1;
    this.selectedColumns = [];
  }

  getDetail(answer: any, rowIndex: number, answerIndex: number) {
    if (answer.clickable) {
      this.minI = false;
      this.maxI = false;
      this.minG = false;
      this.maxG = false;
      this.globalLoading = true;
      this.minMaxes = undefined;
      this.selectedRow = rowIndex;
      this.selectedAnswer = answer;
      this.selectedColumns = [];
      this.selectedColumns.push(answerIndex);
      this.selectedColumn = answerIndex;
      this.cleanTableUtilities();
      this.api
        .getViewOT(
          answer.urlView,
          this.refID!,
          this.familyID!,
          this.rows[rowIndex].rowID,
          this.columnHeaders[answerIndex].colID,
          answer.refID,
          this.measure
        )
        .subscribe({
          next: (value) => {
            this.answerInfo = value as any[];
            this.globalLoading = false;
            this.isDetailOpened = true;
            this.getHeaderData(this.selectedColumn);
          },
          error: (e) => {
            console.log(e);
            this.globalLoading = false;
          },
        });
    }
  }

  outRefresh(event: any) {
    this.rows[this.selectedRow].answers[this.selectedColumn].name = event.name;
    this.rows[this.selectedRow].answers[this.selectedColumn].disabled =
      event.disabled;
    this.rows[this.selectedRow].answers[this.selectedColumn].refID =
      event.refID;
    this.selectedAnswer.refID = event.refID;
    this.getDetail(this.selectedAnswer, this.selectedRow, this.selectedColumn);
  }

  getGeneralInfos(projectID: string, refID: string, rowIndex: number) {
    this.selectedColumn = -1;
    this.selectedAnswer = null;
    this.selectedRow = rowIndex;
    this.selectedColumns = [];
    this.cleanTableUtilities();
  }

  getBackgroundCell(rowIndex: number, answerIndex: number): string {
    if (this.rows[rowIndex].answers[answerIndex].disabled == true) {
      if (
        this.selectedRow == rowIndex &&
        this.selectedColumns.includes(answerIndex)
      ) {
        return 'cell-disabled-selected text-white';
      }
      return 'cell-disabled';
    }
    if (
      this.selectedRow == rowIndex &&
      this.selectedColumns.includes(answerIndex)
    ) {
      return 'cell-selected text-white';
    }
    if (this.rows[rowIndex].answers[answerIndex].selectedMinMax) {
      return 'minMax-bg';
    }
    if (this.rows[rowIndex].answers[answerIndex].cleaned) {
      return 'cell-cleaned';
    }
    // if (
    //   this.selectedRow == rowIndex &&
    //   this.selectedColumns.indexOf(answerIndex) === -1
    // ) {
    //   return 'full-row';
    // }
    // if (
    //   this.selectedRow != rowIndex &&
    //   this.selectedColumns.includes(answerIndex)
    // ) {
    //   return 'full-row';
    // }
    return 'bg-white';
  }

  cleanMinMax() {
    this.minMaxes = undefined;
    this.minG = false;
    this.minI = false;
    this.maxG = false;
    this.maxI = false;
    this.rows.forEach((row) =>
      row.answers.forEach((ans: any) => (ans.selectedMinMax = false))
    );
  }

  getMax(type: string) {
    this.minMaxes = undefined;
    // this.loadingMinMax = true;
    this.globalLoading = true;
    this.answerInfo = null;
    this.selectedAnswer = null;
    this.rows.forEach((row) =>
      row.answers.forEach((ans: any) => (ans.selectedMinMax = false))
    );
    this.selectedRow = -1;
    if (type == 'g') {
      this.maxG = true;
      this.minG = false;
      this.maxI = false;
      this.minI = false;
    } else {
      this.maxI = true;
      this.minI = false;
      this.maxG = false;
      this.minG = false;
    }
    this.api
      .getOtMinMax(
        this.selectedColumnData.urlMax,
        this.refID!,
        this.familyID!,
        this.selectedColumnData.colID,
        type,
        this.measure
      )
      .subscribe({
        next: (value) => {
          this.minMaxes = value as any[];
          // this.loadingMinMax = false;
          this.globalLoading = false;
          this.minMaxes.forEach((mm) => {
            if (mm.rowIndex >= 0) {
              this.rows[mm.rowIndex].answers[
                this.measure ? 0 : this.selectedColumn
              ].selectedMinMax = true;
            }
          });
        },
        error: (err) => {
          // this.loadingMinMax = false;
          this.globalLoading = false;
        },
      });
  }

  getMin(type: string) {
    this.minMaxes = undefined;
    // this.loadingMinMax = true;
    this.globalLoading = true;
    this.selectedRow = -1;
    this.answerInfo = null;
    this.selectedAnswer = null;
    this.rows.forEach((row) =>
      row.answers.forEach((ans: any) => (ans.selectedMinMax = false))
    );
    if (type == 'g') {
      this.maxG = false;
      this.minG = true;
      this.maxI = false;
      this.minI = false;
    } else {
      this.maxI = false;
      this.minI = true;
      this.maxG = false;
      this.minG = false;
    }
    this.api
      .getOtMinMax(
        this.selectedColumnData.urlMin,
        this.refID!,
        this.familyID!,
        this.selectedColumnData.colID,
        type,
        this.measure
      )
      .subscribe({
        next: (value) => {
          this.minMaxes = value as any[];
          // this.loadingMinMax = false;
          this.globalLoading = false;
          this.minMaxes.forEach((mm) => {
            if (mm.rowIndex >= 0) {
              this.rows[mm.rowIndex].answers[
                this.measure ? 0 : this.selectedColumn
              ].selectedMinMax = true;
            }
          });
        },
        error: (err) => {
          // this.loadingMinMax = false;
          this.globalLoading = false;
        },
      });
  }

  openEditInfo(
    quantity: number,
    measure: string,
    refID: string,
    urlEdit: string,
    rowIndex: number
  ) {
    const modalRef = this.modalService.open(ModalEditOtInfoComponent, {
      data: {
        quantity: quantity,
        measure: measure,
        type: this.type,
        link: urlEdit,
        refID: refID,
      },
    });
    modalRef.onClose.subscribe((value: any) => {
      if (value && rowIndex >= 0) {
        this.rows[rowIndex].answers[this.selectedColumn].name = value.name;
        this.rows[rowIndex].answers[this.selectedColumn].refID = value.refID;
        this.getHeaderData(this.selectedColumn);
      }
    });
  }

  openHistory(refID: string, urlHistory: string) {
    const modalRef = this.modalService.open(ModalOtHistoryComponent, {
      data: {
        link: urlHistory,
        refID: refID,
      },
    });
  }

  openInfo(i: number) {
    const modalRef = this.modalService.open(ModalOtInfoComponent, {
      data: {
        item: this.minMaxes![i],
      },
    });
  }

  openDisableAnswer(
    refID: string,
    urlDisable: string,
    disabled: boolean,
    rowIndex: number,
    measure?: string
  ) {
    const modalRef = this.modalService.open(ModalDisableAnswerComponent, {
      data: {
        link: urlDisable,
        refID: refID,
        disabled: disabled,
        measure: measure,
      },
    });
    modalRef.onClose.subscribe((value) => {
      if (value && rowIndex >= 0) {
        this.rows[rowIndex].answers[this.selectedColumn].disabled =
          value.disabled;
        this.getHeaderData(this.selectedColumn);
        this.minMaxes = undefined;
      }
    });
  }

  openGaussian(points: number[]) {
    this.modalService.open(ModalGaussianComponent, {
      data: {
        points: points,
      },
      modalClass: 'modal-lg',
    });
  }

  goBack() {
    window.history.back();
  }
}
