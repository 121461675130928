import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gmp',
  templateUrl: './gmp.component.html',
  styleUrls: ['./gmp.component.scss'],
})
export class GmpComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
