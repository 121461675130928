import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../services/auth.service';

import * as auth from '../store/auth.action';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { defer, Observable, of } from 'rxjs';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {}

  loginAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auth.AuthActionTypes.LOGIN_REQUESTED),
      map((action: auth.LoginRequested) => action.payload),
      switchMap((payload) =>
        this.authService.login(payload.email, payload.password).pipe(
          map((res: any) => {
            return new auth.LoginSuccess({ user: res });
          }),
          tap(() => this.router.navigateByUrl('')),
          catchError((err) => of(new auth.AuthError(err)))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auth.AuthActionTypes.LOGIN_SUCCESS),
      map((action: auth.SaveUser) => action.payload),
      tap((next) => {
        this.router.navigateByUrl('u/dashboard');
      }),
      switchMap((payload: any) => {
        return [new auth.CheckUserRole({ uid: payload.user.adminID })];
      })
    )
  );

  loggedIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auth.AuthActionTypes.LOGGED_IN),
      map((action: auth.SaveUser) => action.payload),
      switchMap((payload: any) => {
        return [new auth.CheckUserRole({ uid: payload.user.adminID })];
      })
    )
  );

  logoutAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auth.AuthActionTypes.LOGOUT_REQUESTED),
      map((action: auth.LogoutRequested) => action.payload),
      switchMap((payload: any) =>
        this.authService.logout().pipe(
          map(() => new auth.LogoutCompleted()),
          tap(() => this.router.navigateByUrl('/')),
          catchError((error) => {
            this.router.navigateByUrl('/');
            return of(new auth.AuthError({ status: error.status }));
          })
        )
      )
    )
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auth.AuthActionTypes.GET_USER),
      switchMap(() =>
        this.authService.getAuthState().pipe(
          take(1),
          map((authData: any) => {
            if (authData) {
              return new auth.LoggedIn({ user: authData });
            } else {
              return new auth.LoginFailed();
            }
          }),
          catchError((error) => of(new auth.AuthError({ status })))
        )
      )
    )
  );

  saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(auth.AuthActionTypes.SAVE_USER),
      map((action: auth.SaveUser) => action.payload),
      switchMap((payload: any) => {
        this.authService.saveUser(payload.user).subscribe();
        return of(new auth.GetUser());
      })
    )
  );

  init$ = createEffect(() => {
    return of(new auth.GetUser());
  });
}
