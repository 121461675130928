import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalPeriodDataAnalysisComponent } from 'src/app/modals/modal-period-data-analysis/modal-period-data-analysis.component';
import { Select } from 'src/app/models/select';

@Component({
  selector: 'development-benefit-row',
  templateUrl: './development-benefit-row.component.html',
  styleUrls: ['./development-benefit-row.component.scss'],
})
export class DevelopmentBenefitRowComponent implements OnInit, OnDestroy {
  @Input() refID?: string;
  @Input() periodID?: string;
  @Input() subGoal?: string;
  @Input() link?: string;
  placeholderValue: number = 0;
  interval: any;
  result?: Select;
  constructor(private api: ApiService, private modalService: MdbModalService) {}

  ngOnInit(): void {
    this.startLoading();
    this.api
      .getStatProjectDevelopmentBenefit(this.link!, this.refID!, this.periodID!)
      .subscribe((value) => {
        this.result = value as Select;
        clearInterval(this.interval);
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  startLoading() {
    this.interval = setInterval(() => {
      if (this.placeholderValue < 100) {
        this.placeholderValue += 5;
      } else {
        this.placeholderValue = 0;
      }
    }, 150);
  }

  openVarietiesList() {
    this.modalService.open(ModalPeriodDataAnalysisComponent, {
      data: {
        title: this.subGoal,
        subTitle: '',
        varieties: this.result?.subselect,
      },
    });
  }
}
