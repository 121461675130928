<div class="d-flex flex-column p-5">
  <div class="d-flex mb-3" *ngIf="crop">
    <div style="height: 7rem; width: 7rem">
      <img
        class="w-100 h-100"
        style="object-fit: contain"
        src="{{ crop.imageUrl }}"
      />
    </div>
    <div class="d-flex mx-2 p-0 flex-column justify-content-center">
      <h1 class="fw-bold">{{ crop.crop }}</h1>
      <h5 class="mx-2 fst-italic">
        {{ crop.latinName }} -
        {{ crop.refType ? crop.refType.typeLang : "" }}
      </h5>
    </div>
  </div>
  <div class="d-flex mb-3" *ngIf="livestock">
    <div style="height: 7rem; width: 7rem">
      <img
        class="w-100 h-100"
        style="object-fit: contain"
        src="{{ livestock.imageUrl }}"
      />
    </div>
    <div class="d-flex mx-2 p-0 flex-column justify-content-center">
      <h1 class="fw-bold">{{ livestock.livestock }}</h1>
      <h5 class="mx-2 fst-italic">
        {{ livestock.latinName }} -
        {{ livestock.refType ? livestock.refType.typeLang : "" }}
      </h5>
    </div>
  </div>
  <div class="d-flex" [formGroup]="filterForm!">
    <div class="btn btn-primary me-2" (click)="openImportVariety()">
      {{ translations.importButton | translate }}
    </div>
    <div class="btn btn-success me-2" (click)="openAddVariety()">
      {{ translations.addButton | translate }}
    </div>
    <div class="my-auto">
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'PLACEHOLDER.FILTER' | translate }}"
        formControlName="filter"
      />
    </div>
    <h6 class="ms-auto my-auto me-2">
      {{ "WORKING_AREA.SELECTED" | translate }}
      {{ selectedVarieties.size }}
      {{
        selectedVarieties.size == 1
          ? (translations?.itemType | translate)
          : (translations?.itemTypes | translate)
      }}
    </h6>
    <div class="btn btn-success" (click)="startAnswering()">
      {{ "WORKING_AREA.ANSWERING" | translate }}
    </div>
  </div>
  <div
    class="datatable mt-4 rounded-4 shadow overflow-hidden"
    *ngIf="varieties"
  >
    <table
      class="table datatable-table"
      mdbTable
      mdbTableSort
      #table="mdbTable"
      #sort="mdbTableSort"
      [dataSource]="varieties"
      [sort]="sort"
    >
      <thead class="datatable-header">
        <tr>
          <th scope="col">
            <div class="form-check d-flex align-items-center mb-0">
              <input
                mdbCheckbox
                class="datatable-header-checkbox form-check-input"
                type="checkbox"
                [checked]="allRowsSelected()"
                (checkboxChange)="toggleAll($event)"
              />
            </div>
          </th>
          <th
            *ngFor="let header of headers"
            [mdbTableSortHeader]="header"
            scope="col"
          >
            {{ header | translate }}
          </th>
        </tr>
      </thead>
      <tbody class="datatable-body">
        <tr
          *ngFor="let data of table.data; index as i"
          scope="row"
          [class.active]="selectedVarieties.has(data)"
        >
          <td>
            <div class="form-check">
              <input
                mdbCheckbox
                (click)="$event.stopPropagation()"
                (checkboxChange)="toggleSelection($event, data)"
                [checked]="selectedVarieties.has(data)"
                class="datatable-row-checkbox form-check-input"
                type="checkbox"
              />
            </div>
          </td>
          <td>
            {{ data.name }}
          </td>
          <td>
            {{ data.classification }}
          </td>
          <td>
            <div class="d-flex justify-content-center">
              <button
                class="btn btn-outline-primary btn-floating btn-sm"
                (click)="openAddVariety(data)"
              >
                <i class="bi bi-pencil-fill"></i>
              </button>
              <button
                class="btn ms-2 btn-outline-primary btn-floating btn-sm"
                (click)="openChangeLocalNames(data.varietyID)"
                [mdbTooltip]="'WORKING_AREA.CHANGE_LOCAL' | translate"
              >
                <i class="bi bi-map-fill"></i>
              </button>
              <button
                class="btn ms-2 btn-outline-primary btn-floating btn-sm"
                (click)="openChangePhotos(data.varietyID)"
                [mdbTooltip]="'WORKING_AREA.CHANGE_PHOTO' | translate"
              >
                <i class="bi bi-camera-fill"></i>
              </button>
              <button
                class="btn ms-2 btn-outline-danger btn-floating btn-sm"
                (click)="openDeleteVariety(data.varietyID, data.name, i)"
              >
                <i class="bi bi-trash2-fill text-danger"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
