import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { ModalAddIntervensionsLegendComponent } from '../modal-add-intervensions-legend/modal-add-intervensions-legend.component';

@Component({
  selector: 'modal-add-interventions',
  templateUrl: './modal-add-interventions.component.html',
  styleUrls: ['./modal-add-interventions.component.scss'],
})
export class ModalAddInterventionsComponent implements OnInit {
  projectID?: string;
  refID?: string;
  siteID?: string;
  constraintID?: string;
  title?: string;

  interventions?: any[];

  refIDs: string[] = [];

  colors = [
    '#ebc000',
    '#ed7d31',
    '#4270bf',
    '#f9f906',
    '#c55a11',
    '#00b0f0',
    '#92d050',
    '#f4b0a4',
    '#f71152',
    '#1deb57',
  ];

  opacityColors = [
    '#ebc00035',
    '#ed7d3135',
    '#4270bf35',
    '#f9f90635',
    '#c55a1135',
    '#00b0f035',
    '#92d05035',
    '#f4b0a435',
    '#f7115235',
    '#1deb5735',
  ];

  constructor(
    private api: ApiService,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<ModalAddInterventionsComponent>
  ) {}

  ngOnInit(): void {
    this.apiCall();
  }

  addRef(refID: string, unselectable?: string[]) {
    if (unselectable?.includes(refID)) return;

    if (this.refIDs.includes(refID)) {
      const index = this.refIDs.indexOf(refID);
      this.refIDs.splice(index, 1);
    } else {
      this.refIDs.push(refID);
    }
  }

  apiCall() {
    this.api
      .getMeInterventions(
        this.projectID!,
        this.refID!,
        this.constraintID!,
        this.siteID
      )
      .subscribe((value) => {
        this.interventions = value as any[];
      });
  }

  addInterventions() {
    this.api
      .addMeInterventions(
        this.projectID!,
        this.refID!,
        this.refIDs,
        this.siteID
      )
      .subscribe((value) => {
        const body = {};
        this.modalRef.close(body);
      });
  }

  openLegend() {
    this.modalService.open(ModalAddIntervensionsLegendComponent, {
      containerClass: '',
      modalClass: 'modal-side end-0 top-0',
    });
  }
}
