import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { SideNavComponent } from './side-nav/side-nav.component';
import { ListHeaderComponent } from './components/list-header/list-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { HorizontalListButtonComponent } from './components/horizontal-list-button/horizontal-list-button.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SettingsComponent } from './settings/settings.component';
import { ChangeCountryComponent } from './change-country/change-country.component';
import { ChangeCountryCardComponent } from './components/change-country-card/change-country-card.component';
import { AddCountryCardComponent } from './components/add-country/add-country-card.component';
import { MdbModule } from '../mdb/mdb.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageControllerComponent } from './components/page-controller/page-controller.component';
import { TableSortIconComponent } from './components/table-sort-icon/table-sort-icon.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { WorkInProgressComponent } from './components/work-in-progress/work-in-progress.component';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { DatabaseFamilyNavigatorComponent } from './database-family-navigator/database-family-navigator.component';
import { GraphNumberComponent } from './components/graphs/graph-number/graph-number.component';
import { GraphPieComponent } from './components/graphs/graph-pie/graph-pie.component';
import { GraphDoughnutComponent } from './components/graphs/graph-doughnut/graph-doughnut.component';

@NgModule({
  declarations: [
    SideNavComponent,
    ListHeaderComponent,
    HorizontalListButtonComponent,
    LoadingComponent,
    SettingsComponent,
    ChangeCountryComponent,
    ChangeCountryCardComponent,
    AddCountryCardComponent,
    PageControllerComponent,
    TableSortIconComponent,
    DisclaimerComponent,
    WorkInProgressComponent,
    DatabaseFamilyNavigatorComponent,
    GraphNumberComponent,
    GraphPieComponent,
    GraphDoughnutComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MdbModule,
    MdbModalModule,
  ],
  exports: [
    SideNavComponent,
    ListHeaderComponent,
    HorizontalListButtonComponent,
    LoadingComponent,
    SettingsComponent,
    ChangeCountryComponent,
    AddCountryCardComponent,
    PageControllerComponent,
    TableSortIconComponent,
    DisclaimerComponent,
    WorkInProgressComponent,
    DatabaseFamilyNavigatorComponent,
    GraphNumberComponent,
    GraphPieComponent,
    GraphDoughnutComponent,
  ],
})
export class LayoutModule {}
