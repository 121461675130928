import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { ApiService } from 'src/app/api.service';
import { User } from 'src/app/auth/models/user.model';
import { AppState } from 'src/app/auth/reducer';
import { getUser } from 'src/app/auth/store/auth.selectors';
import { ModalChangePasswordComponent } from 'src/app/modals/settings/modal-change-password/modal-change-password.component';
import { ModalEditProfileComponent } from 'src/app/modals/settings/modal-edit-profile/modal-edit-profile.component';
import { ModalQrCodeComponent } from 'src/app/modals/settings/modal-qr-code/modal-qr-code.component';
import { rtlLangs } from 'src/app/models/global-variables';
import { Lang } from 'src/app/models/lang.enum';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onChangeCountry: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  isLangOpen: boolean = false;
  currentLang?: string;
  langs?: Array<string>;

  user?: User;

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
    private api: ApiService,
    private modalService: MdbModalService,
    public modalRef: MdbModalRef<SettingsComponent>
  ) {}

  ngOnInit(): void {
    this.langs = Object.keys(Lang)
      .filter((key) => isNaN(Number(key)))
      .map((value) => value.toLowerCase());

    this.currentLang = this.translate.currentLang;

    this.store.select(getUser).subscribe((value) => {
      this.user = value as User;
    });
  }

  onSelect(lan: string) {
    const pastLang = this.translate.currentLang;
    this.translate.use(lan);
    this.currentLang = lan;
    const upper = lan.toUpperCase();
    this.api.updateInfo(upper).subscribe();

    if (
      (rtlLangs.includes(lan) && !rtlLangs.includes(pastLang)) ||
      (!rtlLangs.includes(lan) && rtlLangs.includes(pastLang))
    ) {
      // window.location.reload();
    }
  }

  openCountries() {
    this.modalRef.close('CHANGE_COUNTRY');
  }

  openQrCode() {
    this.modalService.open(ModalQrCodeComponent, {
      modalClass: 'modal-dialog-centered',
      ignoreBackdropClick: true,
    });
  }

  openEditProfile() {
    this.modalService.open(ModalEditProfileComponent, {
      modalClass: 'modal-dialog-centered modal-lg',
      ignoreBackdropClick: true,
    });
  }

  openChangePassword() {
    this.modalService.open(ModalChangePasswordComponent, {
      modalClass: 'modal-dialog-centered modal-lg',
      ignoreBackdropClick: true,
    });
  }

  openLang() {
    this.isLangOpen = true;
  }

  goToPrivacy(): string {
    switch (this.translate.currentLang) {
      case 'en':
        return 'https://www.datar-par.org/privacy-policy';
      case 'fr':
        return 'https://www.datar-par.org/fr/politique-de-confidentialite';
      case 'es':
        return 'https://www.datar-par.org/es/politica-de-privacidad';
      default:
        return 'https://www.datar-par.org/privacy-policy';
    }
  }

  goToTerms(): string {
    switch (this.translate.currentLang) {
      case 'en':
        return 'https://www.datar-par.org/privacy-policy/terms-and-conditions';
      case 'fr':
        return 'https://www.datar-par.org/fr/politique-de-confidentialite/termes-et-conditions';
      case 'es':
        return 'https://www.datar-par.org/es/politica-de-privacidad/terminos-y-condiciones';
      default:
        return 'https://www.datar-par.org/privacy-policy/terms-and-conditions';
    }
  }
}
