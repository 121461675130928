<div class="modal-body p-0 m-0 vh-100">
  <div class="bg-light h-100 w-100">
    <div class="d-flex flex-row p-3">
      <i class="bi bi-graph-up h2 mx-2"></i>
      <h2 class="mx-2">{{ "DASHBOARD.STATISTICS" | translate }}</h2>
    </div>
    <div class="d-flex flex-column bg-light">
      <div class="d-flex flex-column">
        <h5 class="text-primary mx-auto">
          {{ "DASHBOARD.COUNT" | translate }}:
        </h5>
        <div class="d-flex flex-row justify-content-center mt-1">
          <counter
            class="select-hover"
            [counter]="count?.crops"
            image="/assets/images/crop.svg"
            (click)="goToCrop()"
          ></counter>
          <counter
            class="mx-5 select-hover"
            [counter]="count?.livestock"
            image="/assets/images/livestock.svg"
            (click)="goToLivestock()"
          ></counter>
          <counter
            class="select-hover"
            [counter]="count?.aquatics"
            image="/assets/images/aquaculture.svg"
            (click)="goToAquaculture()"
          ></counter>
        </div>
      </div>

      <div
        class="w-100 mt-5 d-flex flex-column justify-content-center align-items-center"
      >
        <div class="mx-2" style="height: 8rem; width: 8rem">
          <img
            src="../../../../assets/images/logo.png"
            class="w-100 h-100"
            style="object-fit: contain"
          />
        </div>
        <div>
          <h4 class="mx-2 my-auto">
            {{ "DASHBOARD.STATS_CS" | translate }}
          </h4>
        </div>
      </div>

      <!-- <div class="d-flex flex-column mt-3">
      <h5 class="mx-auto">Projects Stats:</h5>
      <canvas
        class="mx-3 my-auto"
        mdbChart
        [type]="'polarArea'"
        [datasets]="datasets"
        [labels]="labels"
      ></canvas>
    </div>

    <div class="d-flex flex-column mt-2">
      <h5 class="mx-auto">Something Else Stats:</h5>
      <canvas
        class="mx-3 my-auto"
        mdbChart
        [type]="'radar'"
        [datasets]="datasets"
        [labels]="labels"
      ></canvas>
    </div>

    <div class="d-flex flex-column mt-2 pb-4">
      <h5 class="mx-auto">Last Stats:</h5>
      <canvas
        class="mx-3 my-auto"
        mdbChart
        [type]="'bar'"
        [datasets]="datasets"
        [labels]="labels"
      ></canvas>
    </div> -->
    </div>
  </div>
</div>
