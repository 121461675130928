<div class="d-flex flex-column mx-5 mt-2" [formGroup]="filterForm!">
  <div
    #filter
    class="w-100 d-flex align-items-center py-3 mb-4 rounded"
    style="background-color: rgba(119, 124, 153, 0.5)"
  >
    <h5 class="px-2 my-auto">
      {{ "DATA_COLLECTED.TO_REQUEST" | translate }}
      <span
        class="pointer"
        style="text-decoration: underline; font-weight: bold"
        [routerLink]="'../periods'"
      >
        {{ "PROJECTS.TIMELINE" | translate }}
      </span>
    </h5>
  </div>
  <div class="d-flex flex-row">
    <div class="d-flex flex-column p-0 m-0 bg-transparent w-100">
      <div class="pt-0 bg-light">
        <div class="d-flex justify-content-between align-items-center pt-3">
          <h3 class="mx-2">
            {{ "DATA_COLLECTED.AVAILABLE_FILES" | translate }}
          </h3>
          <span class="custom-badge ms-1 bg-primary h6">
            {{ pageable?.totalElements ?? "---" }}
          </span>
          <div class="me-4 my-auto">
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'PLACEHOLDER.FILTER' | translate }}"
              formControlName="filterFile"
            />
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column justify-content-center h-100"
        *ngIf="!fileCreated"
      >
        <loading [glass]="false"></loading>
      </div>

      <disclaimer
        class="my-auto py-5"
        [text]="'DATA_COLLECTED.NO_AV_FILES' | translate"
        *ngIf="fileCreated && fileCreated?.length == 0"
      ></disclaimer>

      <div
        class="w-100 d-flex flex-column pb-2 rounded mx-3 shadow"
        *ngFor="
          let file of fileCreated;
          index as i;
          first as first;
          last as last
        "
        [ngClass]="[
          i % 2 != 0 ? 'bg-white' : 'bg-white',
          last ? 'mb-5' : 'mb-3',
          first ? 'mt-3' : 'mt-0'
        ]"
      >
        <div
          class="w-100 rounded-top d-flex justify-content-center mb-2"
          [ngClass]="file.failed ? 'bg-danger' : 'bg-warning'"
        >
          <h6
            class="text-white my-auto py-1"
            *ngIf="!file.completed && !file.failed"
          >
            {{ "DATA_COLLECTED.FILE_GENERATING_2" | translate }}
          </h6>
          <h6
            class="text-white my-auto py-1"
            *ngIf="!file.completed && file.failed"
          >
            {{ "DATA_COLLECTED.FILE_FAILED" | translate }}
          </h6>
        </div>
        <div class="d-flex flex-row">
          <div class="mx-2 my-auto" style="height: 3rem; width: 3rem">
            <img
              class="w-100 h-100"
              src="../../../../assets/images/{{ file.fileExt }}.png"
              style="object-fit: contain"
            />
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex flex-row">
              <i
                class="bi bi-sticky-fill me-1"
                *ngIf="file.showNote"
                [title]="'PLACEHOLDER.WITH_NOTES' | translate"
              ></i>
              <h5 class="mx-1">{{ file.name }}</h5>
            </div>
            <h6 class="mx-1">{{ file.description }}</h6>
            <h6 class="mx-1">
              {{ "DATA_COLLECTED.FILE_NAME" | translate }}:
              {{ file.fileName }}
            </h6>
          </div>
          <a
            [href]="file.path"
            [download]="file.fileName"
            class="btn btn-success my-auto ms-auto me-3"
            [ngClass]="
              !file.completed ? 'disabled' : file.cleaned ? 'bg-ready' : ''
            "
            *ngIf="projectPermissions?.projectRole != 'VIEWER' && !file.failed"
          >
            {{ "BUTTONS.DOWNLOAD" | translate }}
          </a>
          <div
            class="btn btn-primary my-auto ms-auto me-3"
            *ngIf="projectPermissions?.projectRole != 'VIEWER' && file.failed"
            (click)="
              requestAgain(
                file.projectRequestFileID,
                i,
                file.cleaned,
                file.showNote
              )
            "
          >
            {{ "BUTTONS.REQUEST_AGAIN" | translate }}
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mx-3">
          <span class="badge rounded-pill bg-ready" *ngIf="file.cleaned">
            {{ "VARIETY.CLEANED" | translate }}
          </span>
          <h6>
            {{ "DATA_COLLECTED.GENERATED_BY" | translate }}:
            {{ file.createdBy }}
            {{ "DATA_COLLECTED.ON" | translate }}
            {{ file.creationDate | date : "short" }}
          </h6>
        </div>
      </div>
      <page-controller
        [pageable]="pageable"
        (pageClick)="onPageClick($event, filter)"
        *ngIf="pageable && fileCreated?.length != 0"
      ></page-controller>
    </div>
  </div>
</div>
