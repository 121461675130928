<div class="modal-header">
  <div class="d-flex flex-row">
    <i class="bi bi-gear-fill h3"></i>
    <h4 class="mx-1 h3 mb-0">
      {{ title ?? "" | translate }}
    </h4>
  </div>
</div>

<div class="modal-body" style="height: 80vh; overflow-y: scroll">
  <div
    class="d-flex py-2 mx-2 rounded hover"
    [ngClass]="i % 2 ? 'bg-light' : 'bg-transparent'"
    *ngFor="let variety of varieties; index as i"
    (click)="onSelect(i, variety.value)"
  >
    <i
      class="bi mx-2"
      [ngClass]="variety.selected ? 'bi-check-square-fill' : 'bi-square'"
    ></i>
    <span class="ms-1">{{ variety.label }}</span>
  </div>
  <loading class="mask w-100 h-100" *ngIf="loading"></loading>
</div>

<div class="modal-footer">
  <div class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </div>
  <div class="btn btn-success" (click)="save()">
    {{ "BUTTONS.SAVE" | translate }}
  </div>
</div>
