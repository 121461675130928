<div class="modal-header rounded-start">
  <div class="d-flex flex-row align-items-center">
    <i class="bi-list-nested h4 my-auto"></i>
    <h4 class="modal-title my-auto mx-2">
      {{ "VARIETY_CLEANING.HISTORY" | translate }}
    </h4>
  </div>
</div>
<div class="modal-body">
  <div class="d-flex align-items-center justify-content-around">
    <div class="d-flex flex-column w-50">
      <div
        class="p-2 bg-light d-flex flex-column rounded"
        style="max-height: 15rem; overflow-y: auto"
      >
        <div
          class="d-flex flex-column"
          *ngFor="let origin of history?.origins; last as last"
        >
          <span>{{ origin.name }}</span>
          <span>{{ origin.projectVarietyID }}</span>
          <hr class="my-2" *ngIf="!last" />
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column mx-4 justify-content-between"
      style="height: 15rem"
    >
      <div class="my-auto">
        <span class="verticaltext m-0 p-0">
          <i class="bi-arrow-right-square-fill h3 mb-auto"></i>
          {{ history?.operation }}
          <i class="bi-arrow-right-square-fill h3 mt-auto mb-0"></i>
        </span>
      </div>
    </div>
    <div class="d-flex flex-column w-50">
      <div
        class="p-2 bg-light d-flex flex-column rounded"
        style="max-height: 15rem; overflow-y: auto"
      >
        <div
          class="d-flex flex-column"
          *ngFor="let destination of history?.destinations; last as last"
        >
          <span>{{ destination.name }}</span>
          <span>{{ destination.projectVarietyID }}</span>
          <hr class="my-2" *ngIf="!last" />
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column mt-2">
    <span>
      {{ "VARIETY_CLEANING.EDITED_BY" | translate }} {{ history?.user }}
    </span>
    <span>
      {{ "VARIETY_CLEANING.DATE" | translate }}:
      {{ history?.date | date: "short" }}
    </span>
  </div>
</div>

<div class="modal-footer" *ngIf="!confirm">
  <button type="button" class="btn btn-primary" (click)="modalRef.close(false)">
    {{ "BUTTONS.CLOSE" | translate }}
  </button>
  <button type="button" class="btn btn-danger" (click)="confirm = true">
    {{ "VARIETY_CLEANING.RESTORE" | translate }}
  </button>
</div>

<div
  class="modal-footer d-flex flex-column"
  style="background-color: rgba(209, 40, 40, 0.3)"
  *ngIf="confirm"
>
  <span class="popconfirm-message-text">
    <strong> {{ "VARIETY_CLEANING.RESTORE_TITLE" | translate }} </strong>
  </span>
  <span class="popconfirm-message-text text-danger">
    <strong>
      {{ "VARIETY_CLEANING.RESTORE_SUBTITLE" | translate }}
    </strong>
  </span>
  <div class="btn btn-danger" (click)="restore()">
    {{ "VARIETY_CLEANING.RESTORE" | translate }}
  </div>
</div>
