<div class="w-100 d-flex mt-4">
  <div class="d-flex flex-column mt-4 text-center mx-auto">
    <div class="mx-auto" style="width: 20vw; height: 20vw">
      <img
        class="w-100 h-100"
        style="object-fit: contain"
        src="../../../assets/images/logo.png"
      />
    </div>
    <h1>{{ "VARIETY.W_I_P" | translate }}</h1>
    <h4>{{ "VARIETY.WIP_DISCLAIMER" | translate }}</h4>
  </div>
</div>
