<div class="d-flex flex-column w-100 mt-3">
  <!-- Crop Logo -->
  <div class="sticky-top bg-light" #stickyNav>
    <div class="d-flex mt-3">
      <div style="height: 5rem; width: 5rem">
        <img
          class="w-100 h-100"
          style="object-fit: contain"
          src="../../assets/images/livestock.svg"
        />
      </div>
      <h1 class="fw-bold mx-2 my-auto">
        {{ "NAVIGATION.LIVESTOCK" | translate }}
      </h1>
      <div
        class="d-flex flex-row align-items-center mt-2 mx-4 fade-element"
        *ngIf="isListOpen"
        [formGroup]="filterForm!"
      >
        <div class="mx-3">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'PLACEHOLDER.FILTER_BY_NAME' | translate }}"
            formControlName="filter"
          />
        </div>
        <div class="mx-2">
          <ng-select
            class="control"
            placeholder="{{ 'DATABASE.FILTER_BY_TYPE' | translate }}"
            formControlName="type"
            [notFoundText]="'PLACEHOLDER.NOT_FOUND' | translate"
          >
            <ng-option *ngFor="let type of types" [value]="type.refTypeID">
              {{ type.typeLang }}
            </ng-option>
          </ng-select>
        </div>
        <h5 class="my-auto mx-2">Filter by:</h5>
        <div
          class="btn btn-ready mx-1 d-flex align-items-center"
          [class.active]="filterCrop.ready"
          (click)="selectReady()"
        >
          <i
            class="bi my-auto h5"
            [ngClass]="
              filterCrop.ready ? 'bi-check-square-fill' : 'bi-square-fill'
            "
          ></i>
          <span class="mx-2 my-auto p-0">Ready</span>
        </div>
        <div
          class="btn btn-notReady mx-1 d-flex align-items-center"
          (click)="selectNotReady()"
          [class.active]="filterCrop.notReady"
        >
          <i
            class="bi my-auto h5"
            [ngClass]="
              filterCrop.notReady ? 'bi-check-square-fill' : 'bi-square-fill'
            "
          ></i>
          <span class="mx-2 my-auto p-0">Not Ready</span>
        </div>
      </div>
    </div>
    <!-- Crop Logo -->
    <!-- Button List -->
    <div class="d-flex d-row py-0 my-0 mt-4 mb-2">
      <!-- Buttons -->

      <horizontal-list-button
        name="NAVIGATION.LIST"
        routerLink="list"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.BREED_TYPE_CLASSIFICATION"
        routerLink="breed-type"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.DESCRIPTORS"
        routerLink="descriptors"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.MANAGEMENT"
        routerLink="management"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.MARKET_DESCRIPTORS"
        routerLink="market-descriptors"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.GOALS"
        routerLink="goals"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.CONSTRAINTS_TREE"
        routerLink="constraints-tree"
      ></horizontal-list-button>

      <horizontal-list-button
        name="NAVIGATION.INTERVENTIONS"
        routerLink="interventions"
      ></horizontal-list-button>

      <!-- <horizontal-list-button -->
      <!-- name="NAVIGATION.CONSTRAINTS_TO_ACCESS_MATERIAL" -->
      <!-- routerLink="constraints-to-access-material" -->
      <!-- ></horizontal-list-button> -->
    </div>
    <div
      class="rounded-6"
      style="height: 3px; width: 82%; transition: 300ms ease-in"
      [ngClass]="dividerColor"
    ></div>
  </div>
  <!-- Button List -->
  <router-outlet></router-outlet>
</div>
